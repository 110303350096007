
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentActions = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onShowCommentsDiv(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");    

    return (
        <div>
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            {colr == 1?
            <button onClick={handleRouteClick} className={"custom-btn label label-success "} title="View Comments">
                <i className="fa fa-comment f15" style={{color:"green"}}></i>
            </button>
            :<button onClick={handleRouteClick} className={"custom-btn label label-success "} title="View Comments">
                <i className="fa fa-comment f15" style={{color:"gray"}} ></i>
            </button>
            }
            
       
        </div>
    );
};

export default CommentActions;
