import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
// import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
// import DrawMap from "../common/drawmap";
// import Map from '../common/drawmap';

import redirectURL from '../redirectURL';

// import MapComponent from './mapLocationComponent';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import Modal from 'react-responsive-modal';
// import { getTicketHistoryDetailsById } from '../../../../api/models/tmsModel';
// import ArrivalMap from "./arrivalMap";
// import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var consigners=[];

export default class TransitTimeSummary extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            oldTruckNo:"",
            truckNumList:"",
            loadshow:'show-n',
            overly : "show-n",
            truckLocation:"",
            replaced_date_time:"",
            transhipment_done_by:"",
            route_details:"",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            // frameworkComponents : {
            //     ArrivalMap : ArrivalMap,
            // },
            maptruckno:"",
            sliderRouteTranslate:"",
            mapinfo:"",
            routeTruck:	"",
            rownode:"",
            consigner:[{"value":"all", "label":"All"}],
            startDate : "",
            endDate : "",
            exitWithoutLoads : [],
            totalRecords : "",
            plantOptions : "",
            cityOptions : "",
            tnpcityOptions:[],
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            sliderTruckNo : "",
            destination : "",
            source : "",
			usermanualmodal:false

        };
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        // this.getData = this.getData.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        try{
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch(e){
    
        }
        
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })

        var dept = ""
        if(this.props.match.path == "/sndtransittimesummary")
        {
            dept="SNDG"
        }
        if(this.props.match.path == "/prttransittimesummary")
        {
            dept="LOG-PRT"
        }
        if(this.props.match.path == "/tnptransittimesummary")
        {
            dept="LOG-TNP"
        }
        this.setState({
            dept_code:dept
        })
        // var reqparams = {
        //     "consigner_code" : ["all"],
        //     "startdate" : "",
        //     "enddate" : "" ,

        // }


        var from_date = "2020-05-01";
        var to_date = moment.parseZone().format("YYYY-MM-DD");
        var cityOptions= [];
        if(dept == "SNDG")
        {
            cityOptions = [{label: "TVP Nagpur", value: "TVP Nagpur"},
                            {label: "TVP Bangalore", value: "TVP Bangalore"},
                            {label: "TVP Siliguri", value: "TVP Siliguri"},]
        }
        if(dept != "LOG-TNP")
        {
            var reqparams = {
                "dept_code" : encode(dept)
            } 
            redirectURL.post("/consignments/getDistinctCities",reqparams).then((response)=>{
                console.log(response.data)
                response.data.map(function(e){
                    if(e != "")
                    {
                        cityOptions.push({"label":e,"value":e});
                    }
                    
                })
            })
        }
        else{

        }
        this.setState({
            cityOptions : cityOptions,
            loadshow : "show-n",
            overly : "show-n",
            startDate : from_date,
            endDate : to_date
        });

        //console.log(reqparams);
        // redirectURL.post("/consignments/getTransitTimeData", reqparams).then((response)=>{
        //    console.log(response.data);
        //     var records = response.data;
        //     let cityOptions= [];
        //     let tnpcityOptions= [];
        //     try{
        //         var transitdata = JSON.parse(records.transit_data);
        //         console.log(transitdata);
        //         //var cities = response.data.options[0].consignee_city.sort();
        //         transitdata.map(function(e){
        //             //console.log(e)
        //             if(e != "")
        //             {
        //                 cityOptions.push({"label":e.consignee_city,"value":e.consignee_city});
        //                 tnpcityOptions.push({"label":e.plant_location,"value":e.plant_location});
                  
        //             }
                    
        //         })
               
        //     }
        //     catch(e){
                
        //         var transitdata = records.transit_data;
        //         //var cities = response.data.options[0].consignee_city.sort();
        //         transitdata.map(function(e){
        //             //console.log(e)
        //             if(e != "")
        //             {
        //                 cityOptions.push({"label":e.consignee_city,"value":e.consignee_city});
        //                 tnpcityOptions.push({"label":e.plant_location,"value":e.plant_location});
        //             }
                    
        //         })
        //     }

        //     let newArray = []; 
        
        //     // Declare an empty object 
        //     let uniqueObject = {}; 
        //     var objTitle;
        //     // Loop for the array elements 
        //     for (let i in cityOptions) { 
    
        //     	// Extract the title 
        //     	objTitle = cityOptions[i]['value']; 
    
        //     	// Use the title as the index 
        //     	uniqueObject[objTitle] = cityOptions[i]; 
        //     } 
            
        //     // Loop to push unique object into array 
        //     for (var a in uniqueObject) { 
        //     	newArray.push(uniqueObject[a]); 
        //     } 
        //    // console.log("newArray ", newArray)

        //    let tnpnewArray = []; 
        
        //    // Declare an empty object 
        //    let tnpuniqueObject = {}; 
        //    var tnpobjTitle;
        //    // Loop for the array elements 
        //    for (let i1 in tnpcityOptions) { 
   
        //        // Extract the title 
        //        tnpobjTitle = tnpcityOptions[i1]['value']; 
   
        //        // Use the title as the index 
        //        tnpuniqueObject[tnpobjTitle] = tnpcityOptions[i1]; 
        //    } 
           
        //    // Loop to push unique object into array 
        //    for (var a1 in tnpuniqueObject) { 
        //     tnpnewArray.push(tnpuniqueObject[a1]); 
        //    } 
        //     this.setState({
        //         rowData : transitdata,
        //         cityOptions : newArray,
        //         tnpcityOptions : tnpnewArray,
        //         loadshow : "show-n",
        //         overly : "show-n",
        //         startDate : from_date,
        //         endDate : to_date
        //     })
            
        // }).catch((e)=>{
        //     console.log(e);
        // })
    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    onCloseRouteDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
			overly:'show-n'
		});
    }
    onClickCounterShowData(params){
		console.log(" params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		
		if(params == "exitloads")
		{
			this.gridApi.setRowData(this.state.exitWithoutLoads);
			this.gridApi.onFilterChanged();
        }
        if(params == "all")
        {
            this.gridApi.setRowData(this.state.totalRecords);
			this.gridApi.onFilterChanged();
        }
    }
    onShowRouteDiv = (e) => {
        console.log("E ",e)
        this.setState({
            overly : "show-m",
            loadshow : "show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var sdate = moment.parseZone(new Date(e.arrival_marrked_on)).format("YYYY-MM-DD HH:mm:ss");
        var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        var parameters = {
            first_instance : sdate,
            recent_instance : edate,
            truck_no : e.truck_no,
            
        }
        if(e.truck_no != "" && e.truck_no != null)
        {
            redirectURL.post("/reports/nightdriveroutes",parameters)
            .then((response) => {
                console.log("Map Routes ", response.data)
                var records = response.data;
                this.setState({
				    loadshow:'show-m'
			    });
                if(records.coords.length == 0)
                {
                    //console.log("records", records.coords);
                    this.setState({
                        show: true,
                        basicTitle:'No Route data available',
                        basicType:"danger",
                        loadshow:'show-n'
                    });
                }
                else
                {
                    if(records != '')
                    {
                        
                        this.setState({
                            sliderRouteTranslate:"slider-translate-60p",
                            showDiv:'show-m',
                            mapinfo:records,
                            dealer:e.consignee_code,
                            consignment_code:"Consignment : "+e.consignment_code,
                            sliderTruckNo : e.truck_no,
                            maptruckno:e.truck_no,
                            routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
                            loadshow:'show-n',
                            overly:'show-n',
                            rownode:e,
                            leg_no:0,
                        });
                        // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                        this.renderMap();
                    }
                }
               
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
        else{
            this.setState({
				show:true,
				basicTitle:"No data available",
                basicType:"danger",
                overly:'show-n',
                loadshow:'show-n'
			});
        }
        
		
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setSourceData(event){
        // this.setState({
        //     source : event.value
        // });
        // console.log(event.value);
        var src=[];
        try{
            this.setState({
                source : event
            });
        }
        catch(e){
            this.setState({
                source : []
            });
        }
    }
    setDestinationData(event){
        var dest=[];
        try{
            this.setState({
                destination : event
            });
 
        }
        catch(e){
            this.setState({
                destination : []
            });
        }
        
    }
    changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	var month = (d.getMonth() +1);
    	var startdate = d.getFullYear()+"-"+(month < 10?"0"+month:month)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	var month = (d.getMonth() +1)
        var edate = d.getFullYear()+"-"+(month < 10?"0"+month:month)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onSubmitFilter = async () => {
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        });
        if(this.state.source != null && this.state.source != "" && this.state.destination != null && this.state.destination != "")
        {
            if(this.state.source.length > 0 && this.state.destination.length > 0)
            {
                var flag=0;
                if(this.state.startDate != "" && this.state.endDate != "" && this.state.startDate != "NaN-NaN-NaN" && this.state.endDate != "NaN-NaN-NaN")
                {
                    var from_date = this.state.startDate+" 00:00:00";
                    var to_date = this.state.endDate+" 23:59:59";
                }
                else
                {
                    flag = 1;
                }
                var timeFlag= 0;
                if(flag == 0)
                {
                    var from_date1 = moment.parseZone(new Date(from_date)).utcOffset("+05:30")._d;
                    var to_date1 = moment.parseZone(new Date(to_date)).utcOffset("+05:30")._d;
                    console.log(from_date1.getTime(),to_date1.getTime());
                    if(from_date1.getTime() > to_date1.getTime())
                    {
                        timeFlag = 1;
                    }
                }
                var consignercodes = [];
                this.state.source.map(function(e){
                    consignercodes.push(e.value)
                })
                var cities = [];
                this.state.destination.map(function(e){
                    cities.push(e.value)
                })
                console.log(consignercodes, cities)
                if(flag == 0 && timeFlag == 0)
                {
                    var reqparams = {
                        "from_date" : from_date,
                        "to_date" : to_date,
                        "consigner_code" : consignercodes,
                        "consignee_cities" : cities,
                        "dept_code" : this.state.dept_code
                    } 
                    redirectURL.post("/consignments/getTransitTimeData",reqparams).then((response)=>{
                        var records = response.data;
                        var transitdata = JSON.parse(records.transit_data);
                        if(this.state.dept_code == "SNDG")
                        {
                            var plant_names = {"B": "TVP BANGALORE", "F": "GURGAON","G": "GUJARAT", "I": "SILIGURI","M": "MANESAR","N": "TVP NAGPUR"};
                        }
                        else if(this.state.dept_code == "LOG-PRT")
                        {
                            var plant_names = {"MSILDM": "MSIL DOMESTIC",
                                                "RPDC-BAN": "RPDC BANGALORE",
                                                 "MSILG": "GURGAON",
                                                 "RPDC-GUJ": "RPDC GUJARAT",
                                                  "RPDC-SIL": "RPDC SILIGURI",
                                                  "MSILM": "MANESAR",
                                                  "RPDC-NAG": "RPDC NAGPUR"};
			
                        }
                        else
                        {
                            var plant_names = {
                                                 "MSIL-G": "GURGAON",
                                                 "SMG": "SMG GUJARAT",
                                                  "MSIL-M": "MANESAR",
                                                  "MSILMC": "CASTING",
                                                  "MSILME": "POWER TRAIN"
                                                  };
                        }
                        var plant_codes = []
                        consignercodes.map(function(e){
                            plant_codes.push(plant_names[e])
                        })
                        console.log(plant_codes,"consignercodes");
                        var citiesList = [];
                        this.state.cityOptions.map((e)=>{
                            console.log(e.value)
                                citiesList.push(e.value)
                        
                        })
                        // transitdata = transitdata.filter(function(e){
                        //     return plant_codes.indexOf(e.plant_location) >= 0
                        // })
                        if(this.state.dept_code == "LOG-TNP")
                        {
                            var filtercities = [];
                            cities.map(function(e){
                                filtercities.push(plant_names[e])
                            })
                        }
                        else
                        {
                            var filtercities = cities;
                        }
                        console.log(citiesList,"citiesList")
                        // transitdata = transitdata.filter(function(e){
                        //     return  filtercities.indexOf(e.consignee_city) >= 0
                        // })
                        //console.log("records filer ",records)
                        if(transitdata.length > 0)
                        {
                            this.setState({
                                rowData : transitdata,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                        else{
                            this.setState({
                                rowData : [],
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                        
                    }).catch((e)=>{
                        console.log(e);
                    })
                }
                else
                {
                    if(flag == 1)
                    {
                        this.setState({
                            loadshow : "show-n",
                            overly : "show-n",
                            show : true,
                            basicTitle  : "From Date or To Date Should not be Empty.",
                            basicType : "warning"
                        })
                    }
                    if(timeFlag == 1)
                    {
                        this.setState({
                            loadshow : "show-n",
                            overly : "show-n",
                            show : true,
                            basicTitle  : "To Date Must be greater than From Date.",
                            basicType : "warning"
                        })
                    }
                    
                }
            }
            else
            {
                this.setState({
                    loadshow : "show-n",
                    overly : "show-n",
                    show : true,
                    basicTitle  : "Source and Destination Should not be empty",
                    basicType : "warning"
                })
            }
        }
        else{
            this.setState({
                basicTitle: "Source or Destination Must not be Empty.",
                basicType : "warning",
                show: true
            })
        }
        
        
        
    }
    selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		consigners=[];
        // var keys = ["B", "F", "G", "I","M", "N"]; 
    
        // // An array of values 
        // var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
        
        // // Object created 
        // var obj = {}; 
        
        // // Using loop to insert key 
        // // value in Object 
        // for(var i = 0; i < keys.length; i++){ 
        //     consigners.push({"code":keys[i],"value":values[i]}); 
        // } 

        if(this.state.dept_code == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
        }
        
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
        return items;
    }
    onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
        var notate = (this.state.dept_code == 'LOG-TNP')?" (Hrs)":"(Days)";
        var hideTnp = (this.state.dept_code == 'LOG-TNP')?true:false;
        const columnwithDefs = [
            // {
            //     headerName : "",
            //     field : "_id",
            //     cellRenderer : "ArrivalMap",
            //     width : 60
            // },
            {
                headerName : "Plant Location",
                field : "plant_location",
                filter : true,
                resizable :true,
                width:140,
                editable:false,
            },
            {
                headerName : "Consignee City",
                field : "consignee_city",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            
            {
                headerName : "No. of Trips",
                field : "no_of_trips",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
            },

            // {
            //     headerName : "Modified Date",
            //     field : "modified_date",
            //     resizable :true,
            //     // width:150,
            //     valueGetter : function(params)
            //     {
            //         if(params.data.modified_date != "" && params.data.modified_date != undefined && params.data.modified_date != null)
            //         {
            //             return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
            //         }
            //     },
            //     filter: "agDateColumnFilter",
            //     comparator: dateComparator,
            //     filterParams: {
            //         browserDatePicker: true,
            //         filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            //         // provide comparator function
            //         comparator: function(filteredDate,cellValue,secondDate) {
            //             cellValue = cellValue.replace(/\//g,"-")
            //             if(cellValue != "" && cellValue != " "){
            //                 cellValue = cellValue.split(" ")[0].split("-");
            //                 cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            //                 var cellDate = new Date(cellValue);
            //                 if(filteredDate.getMonth() < 10){
            //                 var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //                 }
            //                 else{
            //                     var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //                 }
            //                 if(filterValue.split("-")[2].length < 2)
            //                 {
            //                     var temp = filterValue
            //                     filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            //                 }
            //                 var filteredDate = new Date(filterValue);
            //                 cellDate = cellDate.getTime();
            //                 filteredDate = filteredDate.getTime();
            //                 // console.log(cellDate,filteredDate);
            //                 if(cellDate === filteredDate)
            //                 {
            //                     return 0;
            //                 }
            //                 if(cellDate < filteredDate){
            //                     return -1;
            //                 }
            //                 if(cellDate > filteredDate)
            //                 {
            //                     return 1;
            //                 }
            //             }
            //         }
            //     },
            // },

            // {
            //     headerName : "Max. Travel Days",
            //     field : "max_travel_days",
            //     filter : true,
            //     resizable :true,
            //     // width:130,
            // },
            // {
            //     headerName : "Min. Travel Days",
            //     field : "min_travel_days",
            //     filter : true,
            //     resizable :true,
            //     // width:130,
            // },
            {
                headerName : "Transit Time ",
                headerClass:["cellstylegridB","cellTopHeader"],
                children  : [
                    {
                        headerName : "Standard Transit Time "+notate,
                        field : "standard_travel_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:140,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Avg Transit Time "+notate,
                        field : "mean_travel_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:140,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    
                    {
                        headerName : "Transit Difference "+notate,
                        field : "standard_travel_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        valueGetter:function(params){
                            var std = params.data.standard_travel_days;
                            var avg = params.data.mean_travel_days;
                            var diff = std-avg
                            if(diff >= 0)
                            {
                                return diff;
                            }
                            else
                            {
                                return 0;
                            }
                        },
                        width:150,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    // {
                    //     headerName : "CI Lower Boundary "+notate,
                    //     field : "lower_CI_days",
                    //     filter : true,
                    //     editable:false,
                    //     resizable :true,
                    //     width:140,
                    //     headerClass:"cellstylegridB",
                    //     cellClass:'cellstylegridB'
                    // },
                    // {
                    //     headerName : "CI Upper Boundary "+notate,
                    //     field : "upper_CI_days",
                    //     filter : true,
                    //     editable:false,
                    //     resizable :true,
                    //     width:140,
                    //     headerClass:"cellstylegridB",
                    //     cellClass:'cellstylegridB'
                    // },
                    {
                        headerName : "Min. Transit Time "+notate,
                        field : "min_travel_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:120,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Max. Transit Time"+notate,
                        field : "max_travel_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:120,
                        headerClass:"cellstylegridB",
                        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Most Frequent Transit "+notate,
                        field : "first_mode_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:190,
                        hide : hideTnp,
                        headerClass:"cellstylegridB",
                        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Second Most Frequent Transit "+notate,
                        field : "second_mode_days",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:190,
                        hide : hideTnp,
                        headerClass:"cellstylegridB",
                        cellClass:'cellstylegridB'
                    },
                    // {
                    //     headerName : "Frequent Travel Days Trip"+notate,
                    //     field : "first_mode_trips",
                    //     filter : true,
                    //     editable:false,
                    //     resizable :true,
                    //     width:120,
                    //     headerClass:"cellstylegridB",
                    //     cellClass:'cellstylegridB'
                    // },
                    // {
                    //     headerName : " Second Most Frequent Travel Days Trip"+notate,
                    //     field : "second_mode_trips",
                    //     filter : true,
                    //     editable:false,
                    //     resizable :true,
                    //     width:120,
                    //     headerClass:"cellstylegridB",
                    //     cellClass:'cellstylegridB'
                    // },
                ]
            },
            {
                headerName : "Transit Distance",
                headerClass:["cellstylegridG","cellTopHeader"],	
                children : [
                    {
                        headerName : "Standard Distance (KM)",
                        field : "standard_travel_distance",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:130,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                    {
                        headerName : "Avg. Distance Travelled (KM)",
                        field : "mean_dist_travelled",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:130,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                    {
                        headerName : "Min. Distance Travelled (KM)",
                        field : "min_dist_travelled",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:130,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                        valueGetter : function(params)
                        {
                            if(params.data.min_dist_travelled != 0 && params.data.min_dist_travelled != "")
                            {
                                return Number(params.data.min_dist_travelled.toFixed(0))
                            }
                            else{
                                return params.data.min_dist_travelled;
                            }
                        }
                    },
                    {
                        headerName : "Max. Distance Travelled (KM)",
                        field : "max_dist_travelled",
                        filter : true,
                        editable:false,
                        resizable :true,
                        width:130,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                        valueGetter : function(params)
                        {
                            if(params.data.max_dist_travelled != 0 && params.data.max_dist_travelled != "")
                            {
                                return Number(params.data.max_dist_travelled.toFixed(0))
                            }
                            else{
                                return params.data.max_dist_travelled;
                            }
                        }
                    },
                    
                ]
            },
              

        ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-3 col-lg-4">
                        <label>Select Source: </label>
                        <Select 
                            closeMenuOnSelect={false} 
                            // value={this.state.consigner} 
                            isMulti={true}
                            className="border-radius-0" 
                            onChange={this.setSourceData.bind(this)} 
                            style={{borderRadius:"0px"}} 
                            options={this.selectConsignerOptionsItems()} 
                            required 
                        />
                                    
                    </div>
               
                   {(this.state.dept_code != "LOG-TNP")?
                    <div className="form-group col-xl-3 col-lg-4">
                    <label>Select Destination: </label>
                    <Select 
                        closeMenuOnSelect={false} 
                        // value={this.state.consigner} 
                        isMulti={true} 
                        className="border-radius-0" 
                        onChange={this.setDestinationData.bind(this)} 
                        style={{borderRadius:"0px"}} 
                        options={this.state.cityOptions} 
                        required 
                    />
                                
                </div>: 
                    <div className="form-group col-xl-3 col-lg-4">
                        <label>Select Destination: </label>
                        <Select 
                            closeMenuOnSelect={false} 
                            // value={this.state.consigner} 
                            isMulti={true} 
                            className="border-radius-0" 
                            onChange={this.setDestinationData.bind(this)} 
                            style={{borderRadius:"0px"}} 
                            options={this.selectConsignerOptionsItems()} 
                            required 
                        />
                                    
                    </div>
                    }
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>From Date</label>
                        <Datetime 
                            value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ 
                                placeholder: 'From', 
                                name: 'startDate', 
                                autoComplete:'off' 
                            }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} 
                        />
                    </div>
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>To Date</label>
                        <Datetime 
                        value={this.state.endDate} 
                        disableCloseOnClickOutside={false}
                            closeOnSelect={true} 
                            inputProps={{ 
                                placeholder: 'To', 
                                name: 'endDate', 
                                autoComplete:'off'
                        }} 
                            dateFormat="YYYY-MM-DD" 
                            onChange={this.handlerEndDateTime.bind(this)} />
                            
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onSubmitFilter.bind(this)}>Submit</button>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="float-left">
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Avg. Transit Time</span>
                                </h5>
                                <span className="" style={{color: "red", fontSize: "12", float:"right"}}>* Incase of TVP's, Please select respective TVP alone as destination</span>
                            </div>	
                            <div className="card-body pt-15px" >
                                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        // stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        // rowSelection={this.state.rowSelection}
                                        // suppressRowClickSelection={true}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>`
                    </div>
                </div>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Average Transit Time</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
                                This utility provides data pertaining to average travel time taken by various trips from selected Origin and destination in selected time frame. This utility service provides comparison from the MSIL standards to the actual time taken by various vehicles
                            </div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>
            
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}