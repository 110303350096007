'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import $ from 'jquery';
import MarkerClusterer from "@google/markerclusterer";
import { convertSecondsToHHMM } from '../common/utils';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var googleAnalytics = require("../common/googleanalytics"); 
var moment = require('moment');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=16;
var geoFencingArea;
var eventcoords;
var coordinates=[];
var geoFencingAreaArr = [];
var selectedRowData="";
var map;

window.jQuery = $;
window.$ = $;
export default class NoParkingZones extends Component {
  constructor(props) {
    super(props);
      this.state = {
        coordinates : [],
        trucksData : [],
    }
    
    this.showMap = this.showMap.bind(this);
  }

  

componentDidMount(){
    this.onloadPageData();
}
componentWillReceiveProps(){
    this.onloadPageData();
    this.setState({
        loadshow:"show-n",
        overlay:"show-n",
    });
}
onloadPageData(){
    var params = {
        "dept_code" : this.props.dept_code
    }
    redirectURL.post("/consignments/getTruckLocationbydept",params).then((response)=>{
        var rowData = JSON.parse(response.data.details.truck_location);  
        console.log("rowData",rowData)      
        this.setState({
            coordinates : this.props.coordinates,
            trucksData : rowData,
        })
        setTimeout(async () => {
            await this.showMap();
		},2000)
    }).catch((e)=>{
        console.log(e);
    })
    
}

showMap =() =>{
    this.renderMap();
}
renderMap = () => {
    
    loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
       window.initMap = this.initMap
}

initMap = () => {
    console.log("coordinates ", this.state.coordinates)
    // var coordsvalues;
    var geoFencingObj;

    var allcords=[];

    if(this.state.coords != '')
    {
        
    }
    geoFencingObj = this.state.coordinates[0];
    console.log("here coordinates",geoFencingObj);
     map = new window.google.maps.Map(document.getElementById('map_canvas'), {
        zoom: mapZoom,
        center: new window.google.maps.LatLng(28.497236,77.062363),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_RIGHT
        },            
        disableDefaultUI: false,
        zoomControl: true
    });
    if(geoFencingObj.length > 0)
    {
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: mapZoom,
            center: new window.google.maps.LatLng(geoFencingObj[0].lat,geoFencingObj[0].lng),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },            
            disableDefaultUI: false,
            //draggingCursor: 'pointer',
            //drawingMode:null,
            //drawingControl : false,
            zoomControl: true
        });
        
        this.setState({
            loadshow:"show-n",
            overlay:"show-n",
        });
    }
    else{
        this.setState({
            loadshow:"show-n",
            overlay:"show-n",
        });
    }
    
    this.state.trucksData.map(function(td){
        var location = new window.google.maps.LatLng(td.latitude,td.longitude);
        //console.log("location", location)
        var marker = new window.google.maps.Marker({
            position: location,
            map: map,
            title:td.truck_no,
            icon: require('../../assets/icons/truck-end.png')
        });	
        var mapContent = "<div style='padding:7% 0 0 8%;width:190px'>Truck No: "+td.truck_no+"<br>Duration(HH:MM): "+td.elaped_time_from_last_moved_seconds+"<br>Status: "+td.truck_type_mode+"</div>";
        var infowindow = new window.google.maps.InfoWindow({
            content: mapContent,
        });
        marker.addListener('click', function() {
            infowindow.open(map, marker);
          });
    })

            
      var markers = allcords.map((marker) => {
        // console.log("marker ",marker);
        if(marker.status == 1)
        {
            var truckText =  "Inside Plant";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_inside_24.png');
        }
        else if(marker.status == 2)
        {
            var truckText =  "Going to Dealer";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_going_24.png');
        }
        else if(marker.status == 3 || marker.status == 4) 
        {
            var truckText =  "At Dealer Location";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_atdealer_24.png');
        }
        else if(marker.status == 5)
        {
            var truckText =  "Left Destination Location";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_leftdealer_24.png');
        }
        else if(marker.status == 6)
        {
            var truckText =  "Return to Plant";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_return_24.png');
        }
        else if(marker.status == 7)
        {
            var truckText =  "Empty Truck Outside Plant";
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_empty_24.png');
        }
        else{
            var truckText =  marker.truck_no;
            var color =  "#333333";
            var image =  require('../../assets/icons/truck_na_24.png');
        }
        var image =  require('../../assets/icons/truck_na_24.png');
        var contentarr = []
        var headerTitle = truckText
        //contentarr.push({"key":"Truck No", "value":marker.truck_no});
        contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
        //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
        contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
        contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
        contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
        
        var contentString = infoBox(image, headerTitle, contentarr, '')


        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });
        
        var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
        
        if(marker.status == 1)
        {
            var image =  require('../../assets/icons/truck_inside_24.png');
        }
        else if(marker.status == 2)
        {
            var image =  require('../../assets/icons/truck_going_24.png');
        }
        else if(marker.status == 3 || marker.status == 4) 
        {
            var image =  require('../../assets/icons/truck_atdealer_24.png');
        }
        else if(marker.status == 5)
        {
            var image =  require('../../assets/icons/truck_leftdealer_24.png');
        }
        else if(marker.status == 6)
        {
            var image =  require('../../assets/icons/truck_return_24.png');
        }
        else if(marker.status == 7)
        {
            var image =  require('../../assets/icons/truck_empty_24.png');
        }
        else{
            var image =  require('../../assets/icons/truck_na_24.png');
        }
        var image =  require('../../assets/icons/truck_na_24.png');
        
        
        var mark = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title:"Status: "+truckText+" #"+marker.truck_no,
            icon: image
        });
        mark.addListener('click', function() {
            infowindow.open(map, mark);
          });
        return mark
        mark.setMap(map);
    });
    var eventtrigger = document.getElementsByClassName('truck');
    var markerCluster = new MarkerClusterer(map, markers,
        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
      for(var i=0;i<geoFencingObj.length;i++)
      {
            geoFencingArea = new window.google.maps.Polygon({
                paths: geoFencingObj,
                editable: false,
                strokeColor: '#1D9567',
                strokeOpacity: 0.70,
                strokeWeight: 0,
                fillColor: '#1D9567',
                fillOpacity: 0.35,
                draggable:false,
                draggingCursor:null,
                
                });
                geoFencingAreaArr[i] = new window.google.maps.Polygon({
                paths: geoFencingObj,
                editable: false,
                strokeColor: '#1D9567',
                strokeOpacity: 0.70,
                strokeWeight: 0,
                fillColor: '#1D9567',
                fillOpacity: 0.35,
                draggable:false,
                draggingCursor:null,
                });
            console.log("geoFencingArea[i] ",geoFencingAreaArr[i]);
            //geoFencingArea.setMap(map);
            geoFencingAreaArr[i].setMap(map);
            fencingLength=geoFencingObj;
            console.log("test ",geoFencingArea.getPath());
            // geoFencingAreaArr.push(geoFencingArea);
            console.log("fencingLength ",fencingLength)
            window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'insert_at',getPolygonCoords);
            window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'set_at', getPolygonCoords);
        
         
    
      }
         
      var polyOptions = {
        strokeWeight: 0,
        fillOpacity: 0.70,
        editable: true
      };
      // Creates a drawing manager attached to the map that allows the user to draw
      // markers, lines, and shapes.
      drawingManager = new window.google.maps.drawing.DrawingManager({
        
        drawingMode: null,
        drawingControlOptions: {
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON
          ],	
          
        },
        markerOptions: {
          draggable: true
        },
        polylineOptions: {
          editable: true
        },
        rectangleOptions: polyOptions,
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
        map: map
      });

      window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
          if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
        //   drawingManager.setDrawingMode(null);
          // To hide:
        //   drawingManager.setOptions({drawingMode: null, drawingControl: false});
          drawingManager.setOptions({
            drawingControl: true
          });

          // Add an event listener that selects the newly-drawn shape when the user
          // mouses down on it.
          var newShape = e.overlay;
          newShape.type = e.type;
          window.google.maps.event.addListener(newShape, 'click', function() {
            setSelection(newShape);
          });
          setSelection(newShape);
          console.log("selectedShape",newShape);
            fencingLength = selectedShape.getPath().getLength();
            //console.log("fencingLength ", fencingLength)
              var latlngObj=[];
              var htmlStr = "";
              for (var i = 0; i < fencingLength; i++) {
                  var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
                  var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
                  latlngObj.push(in_latlng_array);
            }
            coordinates.push(latlngObj);  
              
        }
          
      });
      
    var bounds = new window.google.maps.LatLngBounds();
    var i1;
    for(i1=0;i1<geoFencingObj.length;i1++){
        bounds.extend(new window.google.maps.LatLng(geoFencingObj[i1].lat,geoFencingObj[i1].lng));
    }
    try{
        if(bounds.Za.i != 1 && bounds.Ua.i !== 180 )
        {
            map.fitBounds(bounds);
        }
    }catch(e){console.log(e)}
    
    
    
      window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
          clearSelection()
      });
      window.google.maps.event.addListener(map, 'click', function(){
          clearSelection()
      });
    
    
      map.addListener('zoom_changed', function() {
        mapZoom=map.getZoom();
      });
      
      
      console.log("coordinates", coordinates);

}

render() {
    const dStyles={
        width:'96%',
        height:'550px',
        paddingLeft: "0px",
        marginLeft: "0px",
    }
    return (
        <div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
    );
  }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}


    function loadDateTimeScript(){
        // alert("timeDate");
        $('.datetimepicker_mask').datetimepicker({
            //mask:'39-19-9999 29:59',
            format:'d-m-Y H:i',
        });
        $('.datetimepicker_date').datetimepicker({
            //mask:'39-19-9999',
            format:'d-m-Y',
            timepicker:false
        });
        var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
        //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
    }

$(document).ready(function(){
    $(document).on("keyup","#notifyFirst",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

	});
	$(document).on("keyup","#notifyFirstcc",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

	});
	$("#addGeoFence").click(function(){
		$(".weather-grid").css("display","none");
		$(".weather-alert-card").css("display","flex");
		$(this).css("display","none");
	});
	$(document).on("keyup","#notify-duration-id",function(){
		if($(this).val() < 1)
		{
			$(this).val("");
		}
	})
});

function getPolygonCoords()
{
	// console.log("function ", geoFencingAreaArr);
	if(geoFencingAreaArr.length > 0)
	{
		coordinates = [];
		for(var g=0; g<geoFencingAreaArr.length;g++)
		{

			var len = geoFencingAreaArr[g].getPath().getLength();
			console.log(len);
			var htmlStr = "";
			var newcoordinates=[];
			for (var i = 0; i < len; i++) {
				// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
				// coordinates.push()
				var strlatlng = geoFencingAreaArr[g].getPath().getAt(i).toUrlValue(5);
				var spliting = strlatlng.split(",");
				var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
				newcoordinates.push(latlngd);
			// htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
			//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
			//htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
			}
			coordinates.push(newcoordinates)
		}
		console.log(coordinates);
	}
	// var len = geoFencingAreaArr.getPath().getLength();
	// console.log(len);
	// var htmlStr = "";
	// var newcoordinates=[];
	// for (var i = 0; i < len; i++) {
	// 	// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	// 	// coordinates.push()
	// 	var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
	// 	var spliting = strlatlng.split(",");
	// 	var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
	// 	newcoordinates.push(latlngd);
	// // htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
	// //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	// //htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
	// }
	// coordinates.push(newcoordinates)
	// console.log("htmlStr", coordinates);
	
}

function getnewPolygonCoords()
{
	console.log("function");
	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	// 	var htmlStr = "";
	var newcoordinates=[];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		newcoordinates.push(latlngd);
	// htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
	//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	//htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
	}
	coordinates.push(newcoordinates)
	// console.log("htmlStr", coordinates);
	
}
