import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import KpiTransitDelayActions from '../manage/transitdelay.js';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            min_duration_nogps:'',
            dept_code:"",
            sendEmail:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
            rowData : [],
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            components : {emailRendererComp:getEmail()}
        };
        
		
    }
    componentDidMount(){
        // var dept_code = "SNDG";
        var dept_code = this.props.deptCode;
        var formdata = {
            config_type:'nogpsdata',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            // console.log(response.data);
            if(response.data.length > 0)
            {
                this.setState({
                    min_duration_nogps:response.data[0].min_duration_nogps,
                    sendEmail : response.data[0].email_to_service_provider,
                });
            }
            this.setState({
                dept_code : dept_code
            });

        });
        var reqParams = {
            dept_code : dept_code
        }
        console.log("GPSDAta");
        redirectURL.post("/manage/gpsprovider",formdata).then((response)=> {
            console.log(response);
            this.setState({
                rowData : response.data,
            }) ;
        });
        console.log("GPSDAta1");
    }
    
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
     }
     onBtStartEditing = (key, char, pinned) => {
         console.log(key);
         console.log(char);
        // var focusedCell = gridOptions.api.getFocusedCell();
        // console.log(focusedCell);
        this.gridApi.setFocusedCell(key.rowIndex, 'emails_provider', pinned);
        this.gridApi.startEditingCell({
          rowIndex: key.rowIndex,
          colKey: 'lastNemails_providerame',
          rowPinned: pinned,
          keyPress: key,
          charPress: char,
        });
      };
     
      editStarted = (event)=>{
        console.log(event);
      }
	formHandler = (event) => {
        event.preventDefault();
        var duration = document.getElementById("durationID").value;
        var e2sp = document.getElementById("e2spID").value;
        var gps_provider_data = [];
        var updatedGpsProviderArr = [];
        if(this.state.dept_code == "SNDG"){
            this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
                updatedGpsProviderArr.push(rowNode.data);
                var data = rowNode.data;
                var emails_provider_arr= [];
                var sndg_emails_arr = [];
                if(data.emails_provider != null){
                    if(data.emails_provider.indexOf(",") >= 0){
                        emails_provider_arr =  data.emails_provider.split(",")
                    }
                    if(data.emails_provider.indexOf(",") < 0 && data.emails_provider.indexOf(" ") >= 0 ){
                        emails_provider_arr =  data.emails_provider.split(" ")
                    }
                    if(emails_provider_arr.length == 0){
                        emails_provider_arr.push(data.emails_provider);
                    }
                }
                
                if(data.sndg_emails != null){
                    if(data.sndg_emails.indexOf(" ") >= 0 && data.sndg_emails.indexOf(",") < 0){
                         sndg_emails_arr = data.sndg_emails.split(" ");
                    }
                    if(data.sndg_emails.indexOf(",") >= 0){
                         sndg_emails_arr = data.sndg_emails.split(",");
                    }
                    if(sndg_emails_arr.length == 0){
                        sndg_emails_arr.push(data.sndg_emails)
                    }
                }
                
                
                var temp = {
                    "service_provider" : data.gps_provider,
                    "service_provider_email" : emails_provider_arr,
                    "dept_emails" : sndg_emails_arr
                }
                gps_provider_data.push(temp);
            });
        }
        if(this.state.dept_code == "LOG-PRT"){
            this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
                var data = rowNode.data;
                var emails_provider_arr= [];
                var prt_emails_arr = [];
                if(data.emails_provider != null){
                    if(data.emails_provider.indexOf(",") >= 0){
                        emails_provider_arr =  data.emails_provider.split(",")
                    }
                    if(data.emails_provider.indexOf(",") < 0 && data.emails_provider.indexOf(" ") >= 0 ){
                        emails_provider_arr =  data.emails_provider.split(" ")
                    }
                    if(emails_provider_arr.length == 0){
                        emails_provider_arr.push(data.emails_provider);
                    }
                }
                if(data.prt_emails != null)
                {
                    if(data.prt_emails.indexOf(" ") >= 0 && data.prt_emails.indexOf(",") < 0)
                    {
                         prt_emails_arr = data.prt_emails.split(" ");
                    }
                    if(data.prt_emails.indexOf(",") >= 0)
                    {
                         prt_emails_arr = data.prt_emails.split(",");
                    }
                    if(prt_emails_arr.length == 0){
                        prt_emails_arr.push(data.prt_emails);
                    }
                }
              
                var temp = {
                    "service_provider" : data.gps_provider,
                    "service_provider_email" : emails_provider_arr,
                    "dept_emails" : prt_emails_arr
                }
                gps_provider_data.push(temp);
            });
        }
        if(this.state.dept_code == "LOG-TNP"){
            this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
                var data = rowNode.data;
                var emails_provider_arr= [];
                var tnp_emails_arr = [];
                if(data.emails_provider != null){
                    if(data.emails_provider.indexOf(",") >= 0){
                        emails_provider_arr =  data.emails_provider.split(",")
                    }
                    if(data.emails_provider.indexOf(",") < 0 && data.emails_provider.indexOf(" ") >= 0 ){
                        emails_provider_arr =  data.emails_provider.split(" ")
                    }
                    if(emails_provider_arr.length == 0){
                        emails_provider_arr.push(data.emails_provider);
                    }
                }
                if(data.tnp_emails != null){
                    if(data.tnp_emails.indexOf(" ") >= 0 && data.tnp_emails.indexOf(",") < 0){
                         tnp_emails_arr = data.tnp_emails.split(" ");
                    }
                    if(data.tnp_emails.indexOf(",") >= 0){
                         tnp_emails_arr = data.tnp_emails.split(",");
                    }
                    if(tnp_emails_arr.length == 0){
                        tnp_emails_arr.push(data.tnp_emails);
                    }
                 }
                var temp = {
                    "service_provider" : data.gps_provider,
                    "service_provider_email" : emails_provider_arr,
                    "dept_emails" : tnp_emails_arr
                }
                gps_provider_data.push(temp);
            });
        }
        if(this.state.dept_code == "LOG-SC"){
            this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
                var data = rowNode.data;
                var emails_provider_arr= [];
                var sc_emails_arr = [];
                if(data.emails_provider != null){
                    if(data.emails_provider.indexOf(",") >= 0){
                        emails_provider_arr =  data.emails_provider.split(",")
                    }
                    if(data.emails_provider.indexOf(",") < 0 && data.emails_provider.indexOf(" ") >= 0 ){
                        emails_provider_arr =  data.emails_provider.split(" ")
                    }
                    if(emails_provider_arr.length == 0){
                        emails_provider_arr.push(data.emails_provider);
                    }
                }
                if(data.sc_emails != null){
                    if(data.sc_emails.indexOf(" ") >= 0 && data.sc_emails.indexOf(",") < 0){
                         sc_emails_arr = data.sc_emails.split(" ");
                    }
                    if(data.sc_emails.indexOf(",") >= 0){
                         sc_emails_arr = data.sc_emails.split(",");
                    }
                    if(sc_emails_arr.length == 0){
                        sc_emails_arr.push(data.sc_emails)
                    }
                }
               

                var temp = {
                    "service_provider" : data.gps_provider,
                    "service_provider_email" : emails_provider_arr,
                    "dept_emails" : sc_emails_arr
                }
                gps_provider_data.push(temp);
            });
        }
        // console.log(itemsToUpdate);
        var formdata = {
            userid:this.state.userid,
            user_email:localStorage.getItem('email'),
            dept_code:this.state.dept_code,
            config_type:"nogpsdata",
            e2sp : e2sp,
            min_duration : duration,
            // service_provider_data : gps_provider_data
            updatedGpsProviderArr : updatedGpsProviderArr,

        }
        console.log(formdata)
        redirectURL.post('/manage/saveNGDConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }

    durationhandler = (event) => {
        console.log(event.target.value);
        this.setState({
            min_duration_nogps : event.target.value
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }
    


    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         var dept_field_name = "";
         var header = "";
         if(this.state.dept_code == "SNDG")
         {
            header = "Sales and Dispatch Emails";
           dept_field_name = "sndg_emails"; 
         }
         if(this.state.dept_code == "LOG-PRT")
         {
            header = "Spare Parts Emails";
             dept_field_name = "prt_emails";
         }
         if(this.state.dept_code == "LOG-TNP")
         {
            header = "Train and Production Emails";
            dept_field_name = "tnp_emails";
         }
          const consigneecols = [
              {
                headerName: "Service Provider",
                field: "gps_provider",
                // colId: "overspeeding_exception",
                // width: 40,
                // pinned: 'left',
                pivot: false,
                resizable : true
              },
              {
                  headerName : "Service Provider Email",
                  field : "emails_provider",
                  width:200,
                  pivot : false,
                  editable : true,
                  resizable : true,
                  colKey : "emails_provider",
                  valueGetter : function(params){
                      if(params.data.emails_provider != " " && params.data.emails_provider != null){
                        var emailProviders = params.data.emails_provider;
                        var result =  addCommas(emailProviders);
                        return result;
                      }
                      else{
                          return params.data.emails_provider;
                      }
                  }
              },
              {
                headerName : header,
                field : dept_field_name,
                width:300,
                pivot : false,
                editable : true,
                resizable : true,
                valueGetter : function(params){
                    if(params.data[dept_field_name] != " " && params.data[dept_field_name] != null){
                      var emailProviders = params.data[dept_field_name];
                      var result =  addCommas(emailProviders);
                      return result;
                    }
                    else{
                        return params.data.dept_field_name;
                    }
                }

              },
              
          ]
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>No Gps Data Settings</h5>
                </div>
                <form className=" theme-form col-xl-12 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-3 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    <div className="col-xl-6 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Duration of No Gps Data (Minutes)*:</label>
                            <input type="number" autoComplete="off" min="60" className="form-control validateNumber" name="duration" id="durationID" value={this.state.min_duration_nogps} onChange={this.durationhandler.bind(this)} placeholder="Duration" required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Send Email to Service Provider * : </label>
                             {(this.state.sendEmail != "" && this.state.sendEmail != "no" )?
                              <span>
                                <input type="radio" name="e2sp" id="e2spID" value="yes" checked/> Yes 
                                <input type="radio" name="e2sp" id="e2spID" value="no" /> No
                              </span>
                             :
                             <span>
                                <input type="radio" name="e2sp" id="e2spID" value="yes" /> Yes 
                                <input type="radio" name="e2sp" id="e2spID" value="no" checked/> No
                            </span>
                            }
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                    <div id="myGrid" style={{ height: "320px",width: "100%"}}  className="ag-theme-balham">
                    <AgGridReact 
                        modules={this.state.modules}
                        columnDefs={consigneecols}
                        // frameworkComponents={this.state.frameworkComponents}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        enableRangeSelection={false}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        onCellEditingStarted = {this.editStarted}
                        components = {this.state.components}                    
                        />
                    </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).on("keyup",".validateEmail",function(e){
    if(e.which === 32){
        $(this).val($(this).val()+", ");
    }
});

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
})


function getEmail(){
function emailRenderer(){}
    emailRenderer.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        alert("clciekd");
    });
}
}

function addCommas(string){
    if(string.indexOf(",") < 0 && string.indexOf(" ") >= 0){
        var splittedString = string.split(" ");
        var concatenateString = "";
        splittedString.map(function(e){
            concatenateString = concatenateString + "," + e;
        });
        concatenateString = concatenateString.substr(1);
        return concatenateString;
    }
    else{
        return string;
    }
}