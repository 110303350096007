'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';

import {verifyext} from "../common/verifyext";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmLoadManager extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : "",
        transactionId : "",
        droppedList : [],
        statusCode : "",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        //components : {datepicker:getDatePicker()}
    }
    this.onLoadPageData = this.onLoadPageData.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

componentDidMount(){
    loadDateTimeScript();
    this.pageAccess();
}

async pageAccess(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var currentUrl = this.props.match.path;
    var is_dept_admin = localStorage.getItem("is_department_admin");
    var role = localStorage.getItem("roles");
    if(role.indexOf("lm_site_admin") >= 0 || is_dept_admin == 1 || role.indexOf("lm_admin") >= 0)
    {
        this.setState({
            pageAccess : 3,
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then(async (response2)=>{
            //console.log(response1.data,"LLPN");
            var lpData = response2.data;
            var lpNames = [];
            if(lpData.length > 0)
            {
                lpData.map((item) => {
                    if(item.comp_short_name != '' && item.comp_short_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.comp_short_name,
                        })
                    }
                })
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = lpData.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    var loading_port_code = lpData[0].loading_port_code;
                    comp_code = lpData[0].comp_code;
                }
                this.setState({
                    comp_code : comp_code,
                    loading_port_code : loading_port_code,
                    loadshow:'show-n',
                    overly:'show-n',
                    lpNames:lpNames
                });
                console.log(lpData[0].loading_port_code,"lpData[0].loading_port_code")
                await this.onLoadPageData();
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        var query = {
            "userid" : localStorage.getItem("userid"),
            "menu_url" : currentUrl,
        }
        redirectURL.post("/lmconsignees/checkUserCurrentPageAccess",query).then(async (response)=>{
            var result = response.data;
            if(result.length > 0)
            {                
                var pageAccess = 2;
                var lpNames = [];
                await result.map(async function(item){
                    if(item.loading_port_name != '' && item.loading_port_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.loading_port_name,
                        })
                    }
                })                
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = result.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {                        
                        if(result1[0].access !='' && result1[0].access !=undefined)
                        {
                            var pageAccess = result1[0].access;                    
                        }
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    if(result[0].access !='' && result[0].access !=undefined)
                    {
                        var pageAccess = result[0].access;                    
                    }
                    var loading_port_code = result[0].loading_port_code;
                    comp_code = result[0].comp_code;
                }
                //console.log(lpNames,"lpNames")
                this.setState({
                    pageAccess : pageAccess,
                    loading_port_code : loading_port_code,
                    comp_code : comp_code,
                    lpNames : lpNames,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(loading_port_code,"loading_port_code")
                await this.onLoadPageData();
            }
            else
            {
                alert("You Don't have access to this screen, Please request access")
                window.location.href="/lmpageaccess";
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}

onLoadPageData(){
    var loading_port_code = this.state.loading_port_code;
    var params = {
        loading_port_code:loading_port_code
    }
    redirectURL.post("/lmconsignees/getLmVehicleCapacity",params).then((response)=>{
        //console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
    window.location.href="/lmloadsummary";
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
}
updateLmStates(params)
{
    //console.log(params);
    if(params.vehicle_cap !='' && params.vehicle_cap !=undefined)
    {
        redirectURL.post("/lmconsignees/savelmvehiclecapacity",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{vehicle_cap:"",min_capacity_cmt:"",max_capacity_cmt:"",min_courier:"",nodes_per_truck:"",allowed_distance:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-40p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
ignoreProcessError = () => {
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    });
    var transactionId = this.state.transactionId;
    var loading_port_code = this.state.loading_port_code;
    console.log("transactionId",transactionId)
    var params = {
        transactionId : transactionId,
        loading_port_code : loading_port_code,
    }
    redirectURL.post("/lmconsignees/generateLoadByTransactionId",params).then((response)=>{
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if(statusCode == 0 || statusCode == 1)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                processErrMsg : "",
                processErrShow : 0,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
        }
        else
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
        //window.location.reload();
    });
}

changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });  
    
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var flag = 0;
    let saveData = new FormData();
    if(flag == 0)
    {      
        saveData.append("loadFile", this.state.loadFile);
        var notify_email = $("#notify_email").val();
        saveData.append("notify_email", notify_email);
        var loading_port_code = this.state.loading_port_code;
        saveData.append("loading_port_code", loading_port_code)
        saveData.append("comp_code", this.state.comp_code)
        saveData.append("user_name", localStorage.getItem('username'))
        saveData.append("email", localStorage.getItem('email'))
        saveData.append("staff_id", localStorage.getItem('staff_id'))
        saveData.append("user_department", localStorage.getItem('lm_department'))
        saveData.append("designation", localStorage.getItem('designation'))
        saveData.append("user_role", localStorage.getItem('roles'))
        console.log(saveData,saveData);
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        redirectURL.post("/lmconsignees/generateLoad",saveData).then((response)=>{
            console.log(response,"responseFromProcess")
            var statusCode = response.data.status;
            if(statusCode == 8)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                });
            }
            else if(statusCode > 3 && statusCode != 8)
            {
                if(statusCode == 11)
                {
                    var processMsg = "Use the standard input data file template for uploading loads";
                }
                else
                {
                    var processMsg = response.data.message;
                }
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    statusCode : statusCode,
                    loadshow:'show-n',
                    overly:'show-n',
                });
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    bulkslide:"",
                });
            }
            //window.location.reload();
        });
    }
    else
    {
        this.setState({
            basicTitle : "Please upload the file",
            basicType : "warning",
            show : true,
        })
    }
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        this.pageAccess();
    })
    .catch(function(e){
        console.log("Error ",e)
    })
} 
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Vehicle Type",
            field: "vehicle_cap",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Min Capacity (CMT)",
            field: "min_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Capacity (CMT)",
            field: "max_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Min CMT per Consignee",
            field: "min_courier",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Maximum Nodes",
            field: "nodes_per_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Distance",
            field: "allowed_distance",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        // {
        //     headerName: "Deviation Distance (%)",
        //     field: "deviation_allowed",
        //     width:180,
        //     filter: true,
        //     resizable: true,
        //     editable:false
        // },
        {
            headerName: "Telescopic Route",
            field: "telescopic_route",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter : function(params){
                if(params.data.telescopic_route == 1)
                {
                    return "Enabled";
                }
                else
                {
                    return "Disabled";
                }
            }
        },
        // {
        //     pinned:"left",
        //     headerName : "Update",
        //     field:"_id",
        //     width:150,
        //     resizable : true,
        //     editable : false,
        //     cellRendererSelector:function(params){       
        //         var rendComponent = {
        //             component: 'UpdateButton'
        //         };
        //         return rendComponent;
        //     },
        // }
    ]
    
    var columnwithDefsForDropped = [      
        {
            headerName: "Dealer",
            field: "dealer_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Demand",
            field: "demand",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Remarks",
            field: "remarks",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Configuration</span>
                    {(this.state.pageAccess != 2) ?
                    <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Plan New Dispatch</button>
                    : ""}
                    {(this.state.pageAccess != 2) ?
                    <a href="/lmvehiclecapacity" style={{float:"right",marginRight:"10px"}} className="btn btn-danger" >Edit Config</a>
                    : ""}
                </h5>
            </div>
            {(this.state.lpNames.length > 0) ? 
                <div className="row col-xl-12 col-lg-12" style={{marginTop:"2%"}}>
                    <div className="col-xl-12 col-lg-12">
                    {this.state.lpNames.length == 1 ?
                    <span style={{fontSize:"16px"}}>Loading Plant : {(this.state.lpNames[0].loading_port)}</span>
                    :
                    <span style={{fontSize:"16px"}}>Loading Plant : 
                        <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                            {(this.state.lpNames).map(function(obj){
                                return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                            })}                                           
                        </select>
                    </span>
                    }
                    </div>
                </div>
            :""}
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    //onCellClicked={this.onCellClicked}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Imports Orders Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                <div className="container-fluid">
                    {this.state.processErrShow == 0 ?
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                        <div className="form-group">
                            {(this.state.loading_port_code == "RPDCGUR") ?
                            <a className="btn btn-primary" href={require('../../assets/json/input_data_template_pick_ticket.csv')} target="_blank">Download Sample Template</a>
                            :
                            <a className="btn btn-primary" href={require('../../assets/json/input_data_template.csv')} target="_blank">Download Sample Template</a>                            
                            }
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">Upload File</label> 
                            <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                        </div>                        
                        <div className="form-group mt-20p">
                            <label className="">Notify Email Users After Process (comma seperated)</label> 
                            <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                        </div>
                        <p style={{color:"red"}}>Note: Do not change the column names in template file</p>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success">Generate Load</button>
                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                        </form>
                    :
                    <div className="" style={{marginTop:"1em"}}>
                        <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                        {this.state.droppedList.length > 0 ? 
                            <div id="myGrid" style={{width:"100%",height:"300px",marginBottom:"1em"}} className={"ag-theme-balham "+this.state.showGridData}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForDropped}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.droppedList}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                //onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        :
                        ""
                        }
                        <button type="button"  onClick={this.fixProcess.bind(this)} className="btn btn-success">Fix and Reupload</button>
                        {this.state.statusCode == 11 ?
                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                        :
                            <button type="button" onClick={this.ignoreProcessError.bind(this)} className="btn btn-default">Ignore</button>
                        }
                        
                    </div>
                    }
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};