import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class DCTSparesContaierUpdates extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		pageTitle: "DCT Spares Exports Container Updates",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
            highseescnt:0,
            highseesarr:[],
            icdtoport:0,
            icdtoportarr:[],
            insideicdcnt:0,
            insideicdarr:[],
            insideportcnt:0,
            insideportarr:[],
            msilicdcnt:0,
            msilicdarr:[],
            originalData:[],
            polList:[],
            cargo_type:[],
            shippinglinecodes:[],
            userType:1,
            chanames:[],
            chaUsers:[],
            containerSizes:[],
            bulkslide:"",
            sliderTitle:"Force Closure",
            overly:"show-m",
            loadshow:"show-m",
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
		this.resetState = this.resetState.bind(this);
    }
   
    componentDidMount(){
        loadDateTimeScript();
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        redirectURL.post("/consignments/getSparesDestinationPorts").then((response)=>{
            var pols = response.data;
            console.log(pols,"pols")
            var polList = [];
            if(pols.length > 0)
            {
                pols.map((item) =>{
                    polList.push(item.port_of_discharge+"("+item.country+")")
                })
            }
            this.setState({
                polList : polList,
            })
        }).catch((e)=>{
            console.log(e);
        })

        this.onLoadDashboardData()
	};
    
    onLoadDashboardData()
    {
		redirectURL.post("/consignments/getSparesExportsContainersForDCT")
		.then((response) => {
            console.log("Respons ", response.data)
            var records = response.data;
            if(records.length > 0)
            {
                records.map(function(e){
                    if(e.export_to == '' || e.export_to ==undefined)
                    {
                        e.export_to = e.manual_searates_pod;
                    }
                    if(e.status == 7)
                    {
                        e.discharged_from_loading_port = "Yes";
                    }
                })
            }
            this.setState({
                rowData:response.data,
                overly:"show-n",
                loadshow:"show-n",
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        sliderTitle:""
        });
        
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Exports Container Tracking';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
            if(params == 1)
            {
                var statusText = "MSIL to ICD";
            }
            if(params == 2)
            {
                var statusText = "Inside ICD";
            }
            if(params == 3)
            {
                var statusText = "ICD to Port";
            }
            if(params == 4)
            {
                var statusText = "Inside Port";
            }
            if(params == 5)
            {
                var statusText = "Highseess";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
    }
    
    onCellRowClicked(e){
        if(e.colDef.field == "update")
        {
            var rowId = e.data._id;
            var status = e.data.status;
            var container_no = e.data.container_no;
            if(container_no !=undefined && container_no !='')
            {
                var chk = 1;
                var manual_loading_port = e.data.manual_loading_port;
                var manual_loading_vessel = e.data.manual_loading_vessel;
                var manual_pol_etd = e.data.manual_pol_etd;
                var manual_pol_atd = e.data.manual_pol_atd;
                var discharged_from_loading_port = e.data.discharged_from_loading_port;
                var export_to = e.data.export_to;
                var msil_gate_out = e.data.msil_gate_out;
                var manual_transshipment_port = e.data.manual_transshipment_port;
                var manual_trans_eta = e.data.manual_trans_eta;
                var manual_trans_ata = e.data.manual_trans_ata;
                var manual_transshipment_vessel = e.data.manual_transshipment_vessel;
                var manual_trans_etd = e.data.manual_trans_etd;
                var manual_trans_atd = e.data.manual_trans_atd;
                var manual_pod_eta = e.data.manual_pod_eta;
                var manual_pod_ata = e.data.manual_pod_ata;
                var icd_source = e.data.icd_source;
                var icd_arrival_date = e.data.icd_arrival_date;
                var railout_date = e.data.railout_date;
                var port_entry_date = e.data.port_entry_date;
                var is_delivered = e.data.is_delivered;
                var recent_updated_by = localStorage.getItem("userid")
                if(is_delivered == "Yes")
                {
                    if(manual_pod_ata !='' && manual_pod_ata !=undefined)
                    {
                        if(discharged_from_loading_port == "Yes")
                        {
                            var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                            var s2 = moment.parseZone(manual_pod_ata).format("x");
                            var s3 = moment.parseZone(currentDate).format("x");
                            if(s2 <= s3)
                            {
                                var chk = 1;
                            }
                            else
                            {
                                var chk = 2;
                            }
                        }
                        else
                        {
                            var chk = 0;
                        }
                    }
                    else
                    {
                        var chk = 2;
                    }
                }
                if(chk == 1)
                {
                    var reqparams = {
                        rowId : rowId,
                        status : status,
                        container_no : container_no,
                        msil_gate_out : msil_gate_out,
                        manual_loading_port : manual_loading_port,
                        manual_loading_vessel : manual_loading_vessel,
                        manual_pol_etd : manual_pol_etd,
                        manual_pol_atd : manual_pol_atd,
                        export_to : export_to,
                        discharged_from_loading_port : discharged_from_loading_port,
                        manual_transshipment_port : manual_transshipment_port,
                        manual_trans_eta : manual_trans_eta,
                        manual_trans_ata : manual_trans_ata,
                        manual_transshipment_vessel : manual_transshipment_vessel,
                        manual_trans_etd : manual_trans_etd,
                        manual_trans_atd : manual_trans_atd,
                        manual_pod_eta : manual_pod_eta,
                        manual_pod_ata : manual_pod_ata,
                        icd_source : icd_source,
                        icd_arrival_date : icd_arrival_date,
                        railout_date : railout_date,
                        port_entry_date : port_entry_date,
                        is_delivered : is_delivered,
                        recent_updated_by : recent_updated_by
                    }
                    console.log(reqparams,"reqparams");
                    redirectURL.post("/consignments/updateDCTExports", reqparams)
                    .then((response) => {
                        if(response.data.message == "Success")
                        {
                            this.setState({
                                basicTitle:"Successfully Updated",
                                basicType : "success",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n",
                            })
                            //window.location.reload();
                            this.onLoadDashboardData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:"Failed to update.",
                                basicType : "warning",
                                show : true
                            })
                        }
                    })
                }
                else if(chk == 2)
                {
                    this.setState({
                        basicTitle:"Revised / Actual arrival at destination port should be less than or equal to Current Date",
                        basicType : "warning",
                        show : true
                    })
                }
                else
                {
                    this.setState({
                        basicTitle:"Discharged from loading port should be Yes to mark as Delivered.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            else
            {
                this.setState({
                    basicTitle:"Something went wrong.",
                    basicType : "warning",
                    show : true
                })
            }
        }
        if(e.colDef.field == "forceclose")
        {
            this.setState({
                overly :"show-m",
                bulkslide : "slider-translate",
                sliderTitle : "#"+e.data.container_no+" Force Closure"
            })
            $("#rowId").val("");
            $("#rowId").val(e.data._id);
        }
    }

    resetUpload = () => {
        this.setState({
            bulkslide:'',
            sliderTitle:'',
            overly:'show-n',
        });
        document.getElementById("upform").reset();
    }

    uploadBulkFormHandler(event){
        event.preventDefault();
        var reason = $("#reason").val();
        var delivered_date = $("#delivered_date").val();
        var rowId = $("#rowId").val();
        var flag=0;
        
        if(flag == 0)
        {
            if(reason !="" && reason !=undefined && delivered_date !='' && delivered_date !=undefined)
            {
                this.setState({
                    overly:"show-m",
                    loadshow:"show-m",
                })
                var reqParams = {
                    rowId : rowId,
                    reason : reason,
                    delivered_date : delivered_date,
                    recent_updated_by : localStorage.getItem("userid")
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/consignments/forcloseLogSCcontainers",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        loadshow:"show-n",
                        overly:'show-n',
                    })
                    //window.location.reload();
                    this.onLoadDashboardData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    
    render(){
        var hideCHA = true;
        var editCHA = false;
        if(this.state.userType == 1)
        {
            var hideCHA = false;
            var editCHA = true;
        }
        var columnwithDefs = [
            {
                headerName : "",
                field : "update",
                width:100,
                editable : false,
                pinned:"left",
                cellRenderer: function(params){
                    return "<button class='btn btn-info' style='padding:0px;width:76px'>Update</button>"
                }
            },
            // {
            //     headerName : "",
            //     field : "forceclose",
            //     width:110,
            //     editable : false,
            //     pinned:"left",
            //     cellRenderer: function(params){
            //         return "<button class='btn btn-danger' style='padding:0px;width:80px'>Force Close</button>"
            //     }
            // },
            {
                headerName : "Container No",
                field : "container_no",
                width:150,
                editable : false,
                pinned:"left",
                resizable:true
            },
            {
                headerName:"Export To",
                field:"export_to",
                width:160,
                pinned:"left",
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.polList,
                },
            },
            {
                headerName:"Dispatch Date From MSIL",
                field:"msil_gate_out",
                width:180,
                editable:true,
                resizable:true,
                cellEditor: DateEditor,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                valueGetter:function(params){
                    if(params.data.msil_gate_out != '' && params.data.msil_gate_out != undefined)
                    {
                        return moment.parseZone(params.data.msil_gate_out).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName : "ICD Source",
                field : "icd_source",
                width:150,
                hide:hideCHA,
                editable : true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["ADANI","GRFL"],
                },
            },
            {
                headerName:"ICD Arrival Date",
                field:"icd_arrival_date",
                width:180,
                editable:true,
                resizable:true,
                cellEditor: DateEditor,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                valueGetter:function(params){
                    if(params.data.icd_arrival_date != '' && params.data.icd_arrival_date != undefined)
                    {
                        return moment.parseZone(params.data.icd_arrival_date).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Railout Date",
                field:"railout_date",
                width:180,
                editable:true,
                resizable:true,
                cellEditor: DateEditor,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                valueGetter:function(params){
                    if(params.data.railout_date != '' && params.data.railout_date != undefined)
                    {
                        return moment.parseZone(params.data.railout_date).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Port Entry Date",
                field:"port_entry_date",
                width:180,
                editable:true,
                resizable:true,
                cellEditor: DateEditor,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                valueGetter:function(params){
                    if(params.data.port_entry_date != '' && params.data.port_entry_date != undefined)
                    {
                        return moment.parseZone(params.data.port_entry_date).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Loading Port",
                field:"manual_loading_port",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Mundra", "Nhava Sheva", "Pipavav", "Jawaharlal Nehru", "Rajula"],
                },
            },
            {
                headerName : "Loading Vessel",
                field : "manual_loading_vessel",
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                width:160,
                editable : true,
                resizable:true
            },
            {
                headerName:"Planned dispatch from indian port",
                field:"manual_pol_etd",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_pol_etd != '' && params.data.manual_pol_etd != undefined)
                    {
                        return moment.parseZone(params.data.manual_pol_etd).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Revised / Actual dispatch from indian port",
                field:"manual_pol_atd",
                width:180,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_pol_atd != '' && params.data.manual_pol_atd != undefined)
                    {
                        return moment.parseZone(params.data.manual_pol_atd).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Discharged from loading port",
                field:"discharged_from_loading_port",
                width:200,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes", "No"],
                },
            },
            {
                headerName:"Transhipment Port",
                field:"manual_transshipment_port",
                width:130,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
            },
            {
                headerName:"Planned arrival at transshipment port",
                field:"manual_trans_eta",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_trans_eta != '' && params.data.manual_trans_eta != undefined)
                    {
                        return moment.parseZone(params.data.manual_trans_eta).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Revised / Actual at transshipment port",
                field:"manual_trans_ata",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_trans_ata != '' && params.data.manual_trans_ata != undefined)
                    {
                        return moment.parseZone(params.data.manual_trans_ata).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName : "Transhipment Vessel",
                field : "manual_transshipment_vessel",
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                width:180,
                editable : true,
                resizable:true
            },
            {
                headerName:"Planned ETD transshipment port",
                field:"manual_trans_etd",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_trans_etd != '' && params.data.manual_trans_etd != undefined)
                    {
                        return moment.parseZone(params.data.manual_trans_etd).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Revised / Actual ETD transshipment port",
                field:"manual_trans_atd",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_trans_atd != '' && params.data.manual_trans_atd != undefined)
                    {
                        return moment.parseZone(params.data.manual_trans_atd).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Planned arrival at destination port",
                field:"manual_pod_eta",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_pod_eta != '' && params.data.manual_pod_eta != undefined)
                    {
                        return moment.parseZone(params.data.manual_pod_eta).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Revised / Actual arrival at destination port",
                field:"manual_pod_ata",
                width:160,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if(params.data.manual_pod_ata != '' && params.data.manual_pod_ata != undefined)
                    {
                        return moment.parseZone(params.data.manual_pod_ata).format('DD-MM-YYYY');
                    }
                    else
                    {
                        return ""
                    }
                }
            },
            {
                headerName:"Is Delivered",
                field:"is_delivered",
                width:130,
                editable:true,
                resizable:true,
                headerClass:"cellstylegridY",
                cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["No", "Yes"],
                },
            },
        ]
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>DCT Exports Container Updates (Spares)</span>                                    
                            </h5>
                        </div>				   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">{this.state.sliderTitle}</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                            
                            <div className="form-group mt-20p">
                                <label className="">Force Closure Reason</label> 
                                <input type="text" name="reason" id="reason" className="form-control" required  />
                            </div>
                            <div className="form-group mt-20p">
                                <label className="">Delivered Date</label> 
                                <input type="text" name="delivered_date" id="delivered_date" className="form-control datetimepicker_date" required  />
                            </div>
                            <div className="form-group">
                                <input type="hidden" name="rowId" id="rowId" />
                                <button type="submit" className="btn btn-success">Submit</button>
                                <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};




