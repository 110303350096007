import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var map;
var dept_code;
var infoBox = require('../common/google-infowindow');

export default class GetLoadOptimizationReport extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            rowData:"",
            detailCellRendererParams:{},
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            dept_code: "",
            detailCellRendererParams: {},
            breakCount: "",
            id: "",
            plants_coords : [],
            selectedTrain: {},
            udHeight: "1220",
            bogiAdjustment1: "50",
            bogiAdjustment2: "25",
            paddingValue: {"label": 50,"value": 50},
            sidePaddingValue: {"label": 50,"value": 50},
            maxUpperDeckHeight: "0",
            minUpperDeckHeight: "0",
            train_name: "",
            trains: [],
            trainOptions: [],
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            no_of_bogis_a: 6,
            no_of_bogis_b: 21,
            reportColumns: [],
            reportData: [],
            showGetReports: "show-m",
            showReports: "show-n",
            showPossibleOptimizations : "show-n",
            showImpossibleOptimizations: "show-n",
            possibleLoadRowData: [],
            defaultReportData: []
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/dashboard/getInitialRailLoadData").then((response)=>{
            var trainOptions = [];
            console.log(response.data.traindimensions)
            response.data.traindimensions.map(function(e){
                trainOptions.push({"label": e.train_name,"value": e.train_name})
            })
            var loadfactors = [];
            response.data.loadfactors.map(function(e){
                e['min'] = 0;
                e['max'] = 999;
                loadfactors.push(e)
            })
            this.setState({
                rowData: loadfactors,
                trains: response.data.traindimensions,
                trainOptions: trainOptions,
                // maxUpperDeckHeight: selectedTrain[0]['upper_deck_height'].max.toString(),
                // minUpperDeckHeight: selectedTrain[0]['upper_deck_height'].min.toString(),
                loadshow : "show-n",
                overly : "show-n"
            })
        })
    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_breaks',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
        // window.location.href = "/railloadoptimization"
    }
    getOptimizedLoadReport(){
        let selectedNodes = this.gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data);
        var flag = 0;
        var cars_info = [];
        selectedData.map(function(e){
            var temp = {};
            temp["sbvh"] = e.sbvh;
            temp["min"] = Number(e.min);
            temp["max"] = Number(e.max);
            cars_info.push(temp)
            if((Number(e.min) > Number(e.max)) || (Number(e.min) == Number(e.max)))
            {
                flag = 1;
            }
        });
        var minValues = [];
        cars_info.map(function(e){
            if(e.min == 0)
            {
                minValues.push(e.min)
            }
        })
        var min_flag = 0;
        if(minValues.length == cars_info.length)
        {
            min_flag = 1;
        }
        if(flag == 0 && min_flag == 0)
        {
            console.log(cars_info);
            console.log(selectedData);
            var height_padding = Number(this.state.paddingValue.value);
            var side_padding = Number(this.state.sidePaddingValue.value);
            // var heightAdjustmentTypeA = Number(this.state.bogiAdjustment1);
            // var heightAdjustmentTypeB = Number(this.state.bogiAdjustment2);
            var no_of_bogis_a = Number(this.state.no_of_bogis_a);
            var no_of_bogis_b = Number(this.state.no_of_bogis_b)
            var formdata = {
                cars_info: cars_info,
                height_padding: height_padding,
                side_padding: side_padding,
                no_of_bogis_a: no_of_bogis_a,
                no_of_bogis_b: no_of_bogis_b
            }
            console.log(this.state.selectedTrain)
            if(this.state.selectedTrain.value != "" && this.state.selectedTrain.value != undefined 
            && formdata.height_padding != "" && formdata.height_padding != undefined
            && formdata.side_padding != "" && formdata.side_padding != undefined
            && formdata.no_of_bogis_a != "" && formdata.no_of_bogis_a != null
            && formdata.no_of_bogis_b != "" && formdata.no_of_bogis_b != null){
                var reqdata = {
                    "params": formdata,
                    "processed": 0,
                    "train_name": this.state.selectedTrain.value
                }
                redirectURL.post("/dashboard/getOptmialRailLoadReport",reqdata
                ).then((response)=>{
                    console.log(response.data);
                    this.setState({
                        basicTitle: "Optimized Solution will be Generated Shortly.",
                        basicType: "success",
                        show: true
                    })

                }).catch((e)=>{})
            }
            else
            {
                this.setState({
                    basicTitle: "Inputs Should not be Empty",
                    basicType: "warning",
                    show: true
                })
            }
        }else{
            if(flag == 1)
            {
                this.setState({
                    basicTitle: "Min. Car Count Must Be Less than Max. Car Count.",
                    basicType: "warning",
                    show: true
                })
            }
            else
            {
                this.setState({
                    basicTitle: "Minimun  no. of cars must be greater than 0",
                    basicType: "warning",
                    show: true
                })   
            }
            
        }
        
        
    }
    async showReportLegs(cell)
    {
        console.log(cell.colDef.field)
        if(cell.colDef.field == "reportlegs")
        {
            console.log(cell.node.expanded,"node.expanded")
            var detailedCols = [{
                headerName: "Total Count",
                field: "total_count",
                width: 110,
                resizable: true
            },
            {
                headerName: "Load Factor",
                field: "load_factor",
                width: 110,
                resizable: true
            },
            {
                headerName: "Upper Deck Height (mm)",
                field: "upper_deck_height",
                width: 110,
                resizable: true
            },
            {
                headerName: "Lower Deck Height (mm)",
                field: "lower_deck_height",
                width: 110,
                resizable: true
            },];
            var defaultReportData = this.state.defaultReportData
            console.log(defaultReportData)
            this.state.rowData.map(function(e){
                detailedCols.push({
                    headerName: e.sbvh,
                    field: e.sbvh,
                    width: 110,
                    resizable: true,
                    valueGetter: function(params){
                        if(params.data[e.sbvh] != undefined){
                            return params.data[e.sbvh]
                        }else{return "-"}
                    }
                })
            });
            console.log(detailedCols,"detailedCols")
            await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: detailedCols,
						overlayNoRowsTemplate: 'No data available',
												
					},
					getDetailRowData: function(param) {
                        // console.log("param ", param)
                        var filtered = defaultReportData.filter(function(e){
                            return e.created_date == param.data.created_date && param.data.optimized_solution.length > 0
                        })
                        console.log(filtered)
						param.successCallback(filtered);
                    }
                    
				}
            });
            cell.node.setExpanded(!cell.node.expanded);
        }
    }
    onClickShowReports(){
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var ag = this.gridApi;
        redirectURL.post("/dashboard/viewOptimizedReports").then((response)=>{
            var reportData = [];
            var check = [];

            response.data.map(function(e){
                var tempdict = {};
                if(e.optimized_solution != undefined)
                {
                    if(e.optimized_solution.length > 0)
                    {
                        e.optimized_solution.map(function(k){
                            k['train_name'] = e.train_name;
                            k['created_date'] = e.created_date;
                            k['processed'] = e.processed;
                            k['height_padding'] = e.params.height_padding;
                            k['side_padding'] = e.params.side_padding;
                            k['optimized_solution'] = e.optimized_solution;
                            tempdict = k
                            reportData.push(k)

                        })

                    }
                    else
                    {
                        var temp={"train_name": e.train_name, "created_date": e.created_date, "processed":e.processed}
                        
                        temp['height_padding'] = e.params.height_padding;
                        temp['side_padding'] = e.params.side_padding;
                        temp['optimized_solution'] = [];
                        reportData.push(temp)
                    }
                    
                }
                else
                {
                    var temp={"train_name": e.train_name, "created_date": e.created_date, "processed":e.processed}
                    temp['optimized_solution'] = [];
                    temp['height_padding'] = e.params.height_padding;
                    temp['side_padding'] = e.params.side_padding;
                    reportData.push(temp)
                }
                
                // reportData.push(tempdict)
            })
            console.log(reportData)
            var reportColumns = [
                {
                    headerName: "",
                    field: "reportlegs",
                    width: 60,
                    cellRenderer: function(e){
                        return "<i class='icofont icofont-list f16'></i>"
                    }
                },
                {
                    headerName: "Train Name",
                    field: "train_name",
                    width: 110,
                    resizable: true,
                },
                {
                    headerName: "Optimized Time",
                    field: "created_date",
                    width: 130,
                    resizable: true,
                    valueGetter: function(params){
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date)
                    },
                    
                },
                {
                    headerName: "Status",
                    field: "processed",
                    width: 110,
                    resizable: true,
                    valueGetter: function(params){
                        if(params.data.processed == 1){return "Completed"}else{return "pending"}
                    }
                },
                
                {
                    headerName: "Height Padding (mm)",
                    field: "height_padding",
                    width: 160,
                    resizable: true
                },
                {
                    headerName: "Side Padding (mm)",
                    field: "side_padding",
                    width: 160,
                    resizable: true
                }];

            console.log(reportColumns)
            
            console.log(reportData)
            var defaultReportData = reportData;
            var reportRowdata = [];
            reportData.map(function(e){
                if(reportRowdata.length == 0)
                {
                    reportRowdata.push(e)
                }
                else
                {
                    var filtereddata = reportRowdata.filter(function(k){
                        return k.created_date == e.created_date
                    })
                    console.log(filtereddata)
                    if(filtereddata.length == 0)
                    {
                        reportRowdata.push(e)
                    }
                }
            })
            console.log(reportRowdata)
            this.setState({
                reportData: reportRowdata,
                defaultReportData: defaultReportData,
                reportColumns: reportColumns,
                loadshow: "show-n",
                overly: "show-n",
                showGetReports:"show-n",showReports:"show-m",showPossibleOptimizations:"show-n",
                showImpossibleOptimizations:"show-n"
            })
            
        }).catch((e)=>{console.log(e)})
    }
    // getOptimizedLoadReport(){

    // }
    render(){
        const hidebreakname = (this.state.dept_code == "SNDG")?true:false;
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                checkboxSelection: true,
                headerCheckboxSelection: true,
                width: 40
            },
            {
                headerName : "Car Name",
                field : "car_name",
                colId: "car_name",
                filter : true,
                resizable :true,
                width:140,
                editable:false,
            },
            {
                headerName : "SBVH",
                field : "sbvh",
                colId: "sbvh",
                filter : true,
                resizable :true,
                width:140,
                editable:false,
            },
            // {
            //     headerName : "SBVH",
            //     field : "sbvh",
            //     colId: "sbvh",
            //     filter : true,
            //     resizable :true,
            //     width:120,
            //     editable:false,
            // },
            // {
            //     headerName : "Length (mm)",
            //     field : "length",
            //     colId: "length",
            //     filter : true,
            //     resizable :true,
            //     width:120,
            //     editable:false,
            // },
            // {
            //     headerName : "Width (mm)",
            //     field : "width",
            //     colId: "width",
            //     filter : true,
            //     resizable :true,
            //     width:120,
            //     editable:false,
            // },
            // {
            //     headerName : "Height (mm)",
            //     field : "height",
            //     colId: "height",
            //     filter : true,
            //     resizable :true,
            //     width:120,
            //     editable:false,
            // },
            // {
            //     headerName : "Load Factor",
            //     field : "load_factor",
            //     colId: "load_factor",
            //     filter : true,
            //     resizable :true,
            //     width:120,
            //     editable:false,
            // },
            {
                headerName : "Min. of Cars",
                field : "min",
                colId: "min",
                filter : true,
                resizable :true,
                width:120,
                editable:true,
                valueGetter: function(params){
                    if(params.data.min == undefined){return 0}else{return params.data.min}
                }
            },
            {
                headerName : "Max. of Cars",
                field : "max",
                colId: "max",
                filter : true,
                resizable :true,
                width:120,
                editable:true,
                valueGetter: function(params){
                    if(params.data.max == undefined){return 50}else{return params.data.max}
                }
            },
            
        ]
        const possibleLoadColumns= [
            {
                headerName : "Upper Deck Height (mm)",
                field : "upper_deck_height",
                colId: "upper_deck_height",
                filter : true,
                resizable :true,
                width:120,
                editable:false,
            },
            {
                headerName : "Lower Deck Height (mm)",
                field : "lower_deck_height",
                colId: "lower_deck_height",
                filter : true,
                resizable :true,
                width:120,
                editable:false,
            },
            {
                headerName : "Upper Deck Possible Cars",
                field : "upper_deck_possible_cars",
                colId: "upper_deck_possible_cars",
                filter : true,
                resizable :true,
                width:450,
                editable:false,
            },
            {
                headerName : "Lower Deck Possible Cars",
                field : "lower_deck_possible_cars",
                colId: "lower_deck_possible_cars",
                filter : true,
                resizable :true,
                width:450,
                editable:false,
            },
        ]
        const impossibleLoadColumns=[{
            headerName : "Upper Deck Height (mm)",
            field : "upper_deck_height",
            colId: "upper_deck_height",
            filter : true,
            resizable :true,
            width:120,
            editable:false,
        },
        {
            headerName : "Lower Deck Height (mm)",
            field : "lower_deck_height",
            colId: "lower_deck_height",
            filter : true,
            resizable :true,
            width:120,
            editable:false,
        },
        {
            headerName : "Upper Deck Imossible Cars",
            field : "upper_deck_impossible_cars",
            colId: "upper_deck_impossible_cars",
            filter : true,
            resizable :true,
            width:450,
            editable:false,
        },
        {
            headerName : "Lower Deck Imossible Cars",
            field : "lower_deck_impossible_cars",
            colId: "lower_deck_impossible_cars",
            filter : true,
            resizable :true,
            width:450,
            editable:false,
        },]
        const impossibleLoadRowData=[{'upper_deck_height': 1220, 'lower_deck_height': 2000, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': []},
            {'upper_deck_height': 1270, 'lower_deck_height': 1950, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': []}, 
            {'upper_deck_height': 1320, 'lower_deck_height': 1900, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['Super Carry']},
            {'upper_deck_height': 1370, 'lower_deck_height': 1850, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'Super Carry']},
            {'upper_deck_height': 1420, 'lower_deck_height': 1800, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'Super Carry']},
            {'upper_deck_height': 1470, 'lower_deck_height': 1750, 'upper_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'XL6', 'Super Carry']},
            {'upper_deck_height': 1520, 'lower_deck_height': 1700, 'upper_deck_impossible_cars': ['ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'WAGON-R', 'Ertiga', 'XL6', 'Super Carry']},
            {'upper_deck_height': 1570, 'lower_deck_height': 1650, 'upper_deck_impossible_cars': ['EECO', 'WAGON-R', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Brezza', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'WAGON-R', 'Ertiga', 'Brezza', 'XL6', 'S Cross', 'Super Carry']},
            {'upper_deck_height': 1620, 'lower_deck_height': 1600, 'upper_deck_impossible_cars': ['EECO', 'WAGON-R', 'Ertiga', 'Brezza', 'XL6', 'S Cross', 'Super Carry', 'IGNIS'], 'lower_deck_impossible_cars': ['EECO', 'WAGON-R', 'DZIRE- Tour', 'Ertiga', 'Brezza', 'XL6', 'Celerio', 'S Cross', 'Super Carry', 'IGNIS']},
            {'upper_deck_height': 1670, 'lower_deck_height': 1550, 'upper_deck_impossible_cars': ['EECO', 'WAGON-R', 'Ertiga', 'Brezza', 'XL6', 'S Cross', 'Super Carry'], 'lower_deck_impossible_cars': ['EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Brezza', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS']},
            {'upper_deck_height': 1720, 'lower_deck_height': 1500, 'upper_deck_impossible_cars': ['EECO', 'WAGON-R', 'Ertiga', 'XL6', 'Super Carry'], 'lower_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS']},
            {'upper_deck_height': 1770, 'lower_deck_height': 1450, 'upper_deck_impossible_cars': ['EECO', 'Super Carry'], 'lower_deck_impossible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS']}]
        const possibleLoadRowData=[{'upper_deck_height': 1220, 'lower_deck_height': 2000, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS']},
            {'upper_deck_height': 1270, 'lower_deck_height': 1950, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'Super Carry', 'IGNIS']},
            {'upper_deck_height': 1320, 'lower_deck_height': 1900, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'EECO', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1370, 'lower_deck_height': 1850, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1420, 'lower_deck_height': 1800, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1470, 'lower_deck_height': 1750, 'upper_deck_possible_cars': [], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1520, 'lower_deck_height': 1700, 'upper_deck_possible_cars': ['ALTO K-10'], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Baleno', 'Brezza', 'CIAZ', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1570, 'lower_deck_height': 1650, 'upper_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'Baleno', 'CIAZ'], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Baleno', 'CIAZ', 'Celerio', 'S-Presso', 'IGNIS']},
            {'upper_deck_height': 1620, 'lower_deck_height': 1600, 'upper_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Baleno', 'CIAZ', 'Celerio', 'S-Presso'], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'Baleno', 'CIAZ', 'S-Presso']},
            {'upper_deck_height': 1670, 'lower_deck_height': 1550, 'upper_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Baleno', 'CIAZ', 'Celerio', 'S-Presso', 'IGNIS'], 'lower_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'Baleno', 'CIAZ']},
            {'upper_deck_height': 1720, 'lower_deck_height': 1500, 'upper_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Baleno', 'Brezza', 'CIAZ', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS'], 'lower_deck_possible_cars': []},
            {'upper_deck_height': 1770, 'lower_deck_height': 1450, 'upper_deck_possible_cars': ['ALTO K-10', 'ALTO 800', 'WAGON-R', 'New Dzire', 'SWIFT', 'DZIRE- Tour', 'Ertiga', 'Baleno', 'Brezza', 'CIAZ', 'XL6', 'Celerio', 'S Cross', 'S-Presso', 'IGNIS'], 'lower_deck_possible_cars': []}
        ]
        const dStyles={
            width:'100%',
            height:'550px'
        }
        return(
            <div class="container-fluid" style={{paddingBottom:"3em"}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {/* <div className="col-xl-12 col-lg-12">
                    <h5>Optimized Rail Load Report</h5>
                </div> */}
                <button className="btn btn-primary" style={{marginLeft: "1em"}} onClick={()=>{this.setState({showGetReports:"show-m",
                                                                                                            showReports:"show-n",
                                                                                                            showPossibleOptimizations: "show-n",
                                                                                                            showImpossibleOptimizations:"show-n"})}}>New Load Optimization</button>
                <button className="btn btn-success" onClick={this.onClickShowReports.bind(this)}>Load Optimization Reports</button>
                <button className="btn btn-info" onClick={()=>{this.setState({showGetReports:"show-n",
                                                                                showReports:"show-n",
                                                                                showPossibleOptimizations: "show-m",
                                                                                showImpossibleOptimizations:"show-n"})}}>Possible Load Optimizations</button>
                <button className="btn btn-danger" onClick={()=>{this.setState({showGetReports:"show-n",
                                                                                showReports:"show-n",
                                                                                showPossibleOptimizations: "show-n",
                                                                                showImpossibleOptimizations:"show-m"})}}>Impossible Load Optimizations</button>
                <div className={" "+this.state.showGetReports}>
                    <form className="row col-xl-12 col-lg-12" method="post" style={{paddingTop:"2em"}}>
                        <div className="form-group col-lg-2"> 
                            <label className="c-lbl">Train Name *:</label>
                            <Select 
                                placeholder={"Train Name"} 
                                closeMenuOnSelect={true} 
                                value={this.state.selectedTrain} 
                                className="border-radius-0" 
                                onChange={((e)=>{this.setState({selectedTrain: e})})} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.trainOptions} 
                            />
                        </div>
                        <div className="form-group col-lg-2"> 
                            <label className="c-lbl">Height Clearance *:</label>
                            <Select 
                                placeholder={"Padding"} 
                                closeMenuOnSelect={true} 
                                value={this.state.paddingValue} 
                                className="border-radius-0" 
                                onChange={((e)=>{this.setState({paddingValue: e})})} 
                                style={{borderRadius:"0px"}} 
                                options={[{"label": 50, value: 50}, {"label": 100, value: 100}]} 
                            />
                        </div>
                        <div className="form-group col-lg-2"> 
                            <label className="c-lbl">Bumper Clearance *:</label>
                            <Select 
                                placeholder={"Padding"} 
                                closeMenuOnSelect={true} 
                                value={this.state.sidePaddingValue} 
                                className="border-radius-0" 
                                onChange={((e)=>{this.setState({sidePaddingValue: e})})} 
                                style={{borderRadius:"0px"}} 
                                options={[{"label": 50, value: 50}, {"label": 100, value: 100}]} 
                            />
                        </div>
                        
                        {/* <div className="form-group col-xl-2 col-lg-2"> 
                            <label className="c-lbl">Bogi A Height Adjustment *:</label>
                            <input type="text" value={this.state.bogiAdjustment1} className="form-control" 
                            onChange={((e)=>{this.setState({"bogiAdjustment1": e.value})})}/>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2"> 
                            <label className="c-lbl">Bogi B Height Adjustment *:</label>
                            <input type="text" value={this.state.bogiAdjustment2} className="form-control" 
                            onChange={((e)=>{this.setState({"bogiAdjustment2": e.value})})}/>
                        </div> */}
                        <div className="form-group col-xl-2 col-lg-2"> 
                            <label className="c-lbl">No. of Bogi's - Type A *</label>
                            <input type="number" value={this.state.no_of_bogis_a} className="form-control" 
                            onChange={((e)=>{this.setState({"no_of_bogis_a": e.value})})}/>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2"> 
                            <label className="c-lbl">No. of Bogi's - Type B *</label>
                            <input type="number" value={this.state.no_of_bogis_b} className="form-control" 
                            onChange={((e)=>{this.setState({"no_of_bogis_b": e.value})})}/>
                        </div>
                    </form>
                    <div className="card col-xl-12 col-lg-12" >
                        {/* <button className="btn btn-success" onClick={this.getOptimizedLoadReport.bind(this)}>Get optimized Load Report</button> */}
                        <div className="card-body">
                            <div id="myGrid" style={{width:"100%",height:"380px"}} className="ag-theme-balham">    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    enableRangeSelection={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={50}
                                    pagination={true}
                                    rowSelection={"multiple"}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection={true}
                                    required />
                            </div>
                            <button className="btn btn-success col-xl-2 col-lg-2" onClick={this.getOptimizedLoadReport.bind(this)}>Get Report</button>
                        </div>
                        
                    </div>
                </div>
                <div className={"col-xl-12 col-lg-12 "+this.state.showReports}>
                    <div className="card" >
                        <div className="card-header">
                            <h5><i className="icofont icofont-train-steam f25"></i> Load Optimizations Report</h5>
                        </div>
                        <div className="card-body">
                            <div id="myGrid" style={{width:"100%",height:"480px"}} className="ag-theme-balham">    
                                <AgGridReact
                                masterDetail={true}
                                    modules={this.state.modules}
                                    columnDefs={this.state.reportColumns}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.reportData}
                                    enableCharts={false}
                                    enableRangeSelection={false}
                                    // onGridReady={this.onGridReady}
                                    // onGridState={this.onGridState}
                                    onCellClicked={this.showReportLegs.bind(this)}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    suppressRowTransform={false}
                                    paginationPageSize={50}
                                    pagination={true}
                                    rowSelection={"multiple"}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection={true}
                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                    required />
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className={"card col-xl-12 col-lg-12 "+this.state.showPossibleOptimizations}>
                    <div className="card-header">
                        <h5><i className="icofont icofont-train-steam f25"></i> Possible Load Optimizations</h5>
                    </div>
                    <div id="myGrid" style={{width:"100%",height:"550px"}} className="card-body ag-theme-balham">    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={possibleLoadColumns}
                            defaultColDef={this.state.defaultColDef}
                            rowData={possibleLoadRowData}
                            enableCharts={false}
                            enableRangeSelection={false}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={50}
                            pagination={true}
                            rowSelection={"multiple"}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            enableRangeSelection={true}
                            required />
                    </div>
                </div>
                <div className={"card col-xl-12 col-lg-12 "+this.state.showImpossibleOptimizations}>
                    <div className="card-header">
                        <h5><i className="icofont icofont-train-steam f25"></i> Impossible Load Optimizations</h5>
                    </div>
                    <div id="myGrid" style={{width:"100%",height:"550px"}} className="card-body ag-theme-balham">    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={impossibleLoadColumns}
                            defaultColDef={this.state.defaultColDef}
                            rowData={impossibleLoadRowData}
                            enableCharts={false}
                            enableRangeSelection={false}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={50}
                            pagination={true}
                            rowSelection={"multiple"}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            enableRangeSelection={true}
                            required />
                    </div>
                </div>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function groupBy(list, keyGetter) {
    const maps = new Map();
    list.forEach((item) => {
         const keys = keyGetter(item);
         const collection = maps.get(keys);
         if (!collection) {
            maps.set(keys, [item]);
         } else {
             collection.push(item);
         }
    });
    return maps;
}