import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';

class ConsignmentsTodayCounter extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		totalDeliveriesTodayCount: 0,
			earlyDeliveriesTodayCount: 0,
			delayedDeliveriesTodayCount: 0,
			onTimeDeliveriesTodayCount: 0,
    	}
    
    }
    componentDidMount(){
    	//console.log("Box ",localStorage.getItem('token'));
    	//console.log(this.props);
		const layoutdata={
			token:localStorage.getItem('token'),
		}
    		//console.log("Trucks", this.props.tottrucks);
    	//	//console.log("trucklocations", this.props.truckgps);
    		//console.log("notrans", this.props.notruckgps);
		 this.setState({
            totalDeliveriesTodayCount:this.props.totalDeliveriesTodayCount,
            earlyDeliveriesTodayCount:this.props.earlyDeliveriesTodayCount,
            delayedDeliveriesTodayCount:this.props.delayedDeliveriesTodayCount,
            onTimeDeliveriesTodayCount:this.props.onTimeDeliveriesTodayCount,
  		});
    }

    render(){

        return (

                <div className="crm-numbers">
					<div className="row">
						<div className="col">
							<span>Total</span>
							<h4 className="txt-info fnt2"><span className="counter"><CountUp end={this.props.totalDeliveriesTodayCount}/></span></h4>
						</div>
						<div className="col">
							<span>Early</span>
							<h4 className="txt-success-dark fnt2"><span className="counter"><CountUp end={this.props.earlyDeliveriesTodayCount}/></span></h4>
						</div>                        
						<div className="col">
							<span>On Time</span>
							<h4 className="txt-success-medium fnt2"><span className="counter"><CountUp end={this.props.onTimeDeliveriesTodayCount}/></span></h4>
						</div>
						<div className="col">
							<span>Delay</span>
							<h4 className="txt-danger fnt2"><span className="counter"><CountUp end={this.props.delayedDeliveriesTodayCount}/></span></h4>
						</div>
					</div>
				</div>
                

        );
    }
}

export default ConsignmentsTodayCounter