/* eslint-disable no-unused-vars */

import React from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const EditButton = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.editForm(props.data);
    };

    return (
        <div className="map-icon-component">
            <button onClick={handleRouteClick} className="custom-btn label label-success"><i className="icofont icofont-edit f15"></i> &nbsp;Edit </button>
        </div>
    );
};

export default EditButton;
