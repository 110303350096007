'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM} from '../common/utils'; 
import * as Datetime from 'react-datetime';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class SobCompleteData extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
            // currentAddAccidentProneZone: null,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        deviation: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        showGridData : "show-m",
        vechicleTypes : [],
        coordinates : [],
        month : null,
        select_month : "",
        plan_month : "2021-07",
        // plan_month : moment.parseZone().format("YYYY-MM"),
        //components : {datepicker:getDatePicker()}
        
    }
  }

  

async componentDidMount(){
    this.setState({
        rowData : this.props.completeData
    })
    //await this.onloadPageData();
}

async componentWillReceiveProps(){
    console.log("this.props", this.props)
    this.setState({
        rowData : this.props.completeData
    })
    //await this.onloadPageData();
}

onloadPageData(){    
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var month = this.state.plan_month
    const firstdate = moment(month).startOf('month').format('YYYY-MM-DD HH:mm:ss');        
    const lastdate=moment(month).endOf('month').format("YYYY-MM-DD HH:mm:ss"); 

    var params = {
        month: month,
        startDate: firstdate,
        endDate:lastdate
    }
    console.log("params", params)
    var rowData = [];
    redirectURL.post("/consignments/getSobCompleteData",params)
    .then((response) => {
        console.log("response.data", response.data)

        response.data.map((item)=> {
            rowData.push({
                truck_no: item.truck_no,
                consignment_code : item.consignment_code,
                gate_out_time : getHyphenDDMMMYYYYHHMM(item.gate_out_time),
                consigner_code: item.consigner_code,
                consignee_code: item.consignee_code,
                transporter_code:item.transporter_code,
                transporter_name: item.transporter_name,
                items:item.items,
                tnp_user_dept_code: item.tnp_user_dept_code
            })
        });        
        this.setState({
            month: month,
            rowData: rowData,
            loadshow:'show-n',
            overly:'show-n',
        })
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

filterDataByMonth = async() => {
    await this.setState({
        plan_month : this.state.plan_month
    });
    await this.getApiData();
}

handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var plan_month = d.getFullYear()+"-"+(d.getMonth() +1);
    
    this.setState({
        plan_month:plan_month
    });
}
 
  render() {
    var columnwithDefs = [      
        {
            headerName: "Truck No",
            field: "truck_no",
            width:120,
            filter: true,
            resizable: true,
            editable:false,   
        },
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false,     
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Items",
            field: "items",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "TNP User Dept Code",
            field: "tnp_user_dept_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
    ]
    
    return (
      
            <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}                   
                    />
                </div>
           


    );
  }
}
