
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const RecentConsent = (props) => {
    
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onHitRecentConsentApi(props);
    };
    

    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-success custom-btn f12 label label-success" 
            title="Recent Consent" >Resend Consent</button>    
        </div>
    );
};

export default RecentConsent;
