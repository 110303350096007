import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import UpdateItem from './updatetransporteritem';
import DeleteItem from './deleteItem';

import axios from 'axios';

import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class MLLGPSDevices extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: null,

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            transporter:"",
            device_code:"",
            device_owner:"",
            device_owner_type:"",
            plants:[],
            deviceowners:[]
		};
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
		
		this.setState({
            loadshow:"show-n",
            overly:"show-n"
		});
        this.onLoadData();
		
       
	};
    onLoadData(){
        redirectURL.post('/mllconsignments/mllgpsdevicespage')    
		  .then((response) => {
            var devices = response.data.devices;
            var transporters = response.data.transporters;
            var plants = response.data.plants;
            var devicesarr= []
            if(devices.length > 0)
            {
                devices.map((item) => {
                    // var latlng = JSON.parse(item.plant_coordinates)
                    // console.log("latlng",latlng)
                    devicesarr.push({
                        "_id" : item._id,
                        "device_code" : item.device_code,
                        "device_owner_type" : item.device_owner_type,
                        "device_owner" : item.device_owner,
                        device_type: item.device_type
                    })
                })
            }
            var transportersarr = []
            if(transporters.length > 0)
            {
                transporters.map((item) => {
                    transportersarr.push(item.transporter_name)
                })
            }
            var plantsarr = []
            if(plants.length > 0)
            {
                plants.map((item) => {
                    plantsarr.push(item.plant_name)
                })
            }
            console.log("plantsarr ",plantsarr)
            console.log("transportersarr ",transportersarr)
            this.setState({
                rowData:devicesarr,
                transporters:transportersarr,
                plants:plantsarr
            });
		  })
		  .catch(function (error) {
		    console.log(error);
        });
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    updateRowHandler(cell)
	{
         if (googleAnalytics.page.enableGA) {
			let pageTitle = "GPS Device";
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        // var params = {
        //     truck_no:this.state.truck_no,
        //     truck_type:this.state.trucktype.value,
        //     transporter_code:this.state.transporter.value,
        //     dept_code:this.state.deptcode.value,
        // }
		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/mllconsignments/updatemlldevice", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }

    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let pageTitle = "GPS Devices";
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        var params = {
            "device_code" : this.state.device_code,
            "device_owner_type" : this.state.device_owner_type.value,
            "device_owner" : this.state.device_owner.value,
            "device_type" : this.state.miletype.value
        }
		var fdata = {
	    		rownode:params
	    }
	    redirectURL.post("/mllconsignments/updatemlldevice", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added item',
                        basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                        
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
        
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRso:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    onChangeMileType(miletype){
		this.setState(
			{ miletype },
			() => console.log(`Option selected:`, this.state.miletype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let pageTitle = "GPS Device";
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/mllconsignments/deletemlldevice", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
    onChangeOwnerType =async (device_owner_type) =>{
		this.setState(
            { device_owner_type },
            async () => {
                console.log("device_owner_type ", device_owner_type)
                if(device_owner_type.value == "Plant")
                {
                    var plnts = this.state.plants
                    var owners = []
                    if(plnts.length > 0)
                    {
                         plnts.map((item) => {
                            owners.push({
                                value:item,
                                label:item
                            })
                        })
                    }
                }
                
                if(device_owner_type.value == "BA")
                {
                    var trns = this.state.transporters
                    var owners = []
                    if(trns.length > 0)
                    {
                         trns.map((item) => {
                            owners.push({
                                value:item,
                                label:item
                            })
                        })
                    }
                }
                await this.setState({
                    deviceowners:owners,
                    // errorMessage:'',
                    // successMessage:''
                })
            }
          );
      
	}
    onChangeOwner(device_owner){
		this.setState(
			{ device_owner }
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    getCategoryArray(cell){
        console.log("cell ",cell)
        var params = cell.data
        if(params.device_owner_type == "Plant")
        {
            return {
                values: this.state.plants
            }
        }
        else
        {
            return {
                values: this.state.transporters
            }
        }
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var plantsdata = this.state.plants;
        console.log("plants ", plantsdata)
        var transporters = this.state.transporters;
        // if(trans.length > 0)
        // {
        //     trans.map((item) => {
        //         transporters.push(item)
        //     })
        // }
		const {usermanualmodal} = this.state;
		
		const columnwithDefs = [
	  	        {
	  	          headerName: "Device Code",
	  	          field: "device_code",
	  	          width: 140,
                  editable:true,
	    	      filter: "agSetColumnFilter"
		        },
		        {
                    headerName: "Device Owner Type",
                    field: "device_owner_type",
                    width: 150,
                    editable : true,
                    filter: "agSetColumnFilter",
                    cellEditor: 'agSelectCellEditor',   
                    cellEditorParams: {
                        values: ["","Plant","BA"],
                    },	    	          
                },
                
		        {
                    headerName: "Device Owner",
                    field: "device_owner",
                    width: 180,
                    editable : true,
                    filter: "agSetColumnFilter",
                    cellEditor: 'agSelectCellEditor',   
                    cellEditorParams: this.getCategoryArray.bind(this)
                    // cellEditorParams: function(params){
                    //     if(params.data.device_owner_type == "Plant")
                    //     {
                    //         console.log("plantsdata ", plantsdata)
                    //         return {
                    //             params: {
                    //                 values: plantsdata
                    //             }
                    //         };
                    //     }
                    //     else
                    //     {
                    //         console.log("transporters ", transporters)
                    //         return {
                    //             params: {
                    //                 values: transporters
                    //             }
                    //         };
                    //     }
                    // }

                },
                
		        {
                    headerName: "Device Type",
                    field: "device_type",
                    width: 180,
                    editable : true,
                    filter: "agSetColumnFilter",
                    cellEditor: 'agSelectCellEditor',   
                    cellEditorParams: {
                        values: ["","First Mile","Last Mile"],
                    },
                    valueGetter:function(params){
                        try{
                            if(params.data.device_type != "" && params.data.device_type != undefined)
                            {
                                if(params.data.device_type == "First Mile")
                                {
                                    return "First Mile/Middle Mile";
                                }
                                else
                                {
                                    return "Last Mile";
                                }
                                 
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }

                },
                
                
		        {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'updateItem',
                    editable:false
		        },
		        // {
                //     headerName: "Action",
                //     field: "_id",
                //     width: 100,
                //     filter: false,
                //     cellRenderer:'deleteItem',
                //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>GPS Devices</span>
                                       <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add GPS Device
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Device ID</label>
							    <input type="text" placeholder="Device Code" value={this.state.device_code} onChange={this.changeHandler.bind(this)} id="device_code" name="device_code" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Owner Type</label>
                                <Select 
									placeholder={"Select Owner Type"}
									closeMenuOnSelect={true}
									onChange={this.onChangeOwnerType.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.device_owner_type}
									options={[
                                        {value:"",label:""},
                                        {value:"Plant",label:"Plant"},
                                        {value:"BA",label:"BA"}]} 
								/> 
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Owner</label>
                                <Select 
									placeholder={"Select Owner"}
									closeMenuOnSelect={true}
									onChange={this.onChangeOwner.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.device_owner}
									options={this.state.deviceowners} 
								/> 
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Type</label>
                                <Select 
									placeholder={"Select Type"}
									closeMenuOnSelect={true}
									onChange={this.onChangeMileType.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.miletype}
									options={[
                                        {"value":"First Mile","label":"First Mile"},
                                        {"value":"Last Mile","label":"Last Mile"},
                                    ]} 
								/> 
                            </div>
                            
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}