import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { isNumber } from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import RailFinancialColumnLineChart from "./railfinancialcolumlinechart";
import RailBRMColumnLineChart from "./railbrmcolumnchart"
import $ from "jquery";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class BRMDashboardMgmt extends Component{
        constructor(props){
        super(props);
        this.state = {
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            dept_code:"",
            pageTitle:"Rail",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            open:false,
            financialyear:'',
            finyear:'',
            raildispatchdata:[],
            raildispatchdataavg:[],
            raildispatchthird:[],
            raildispatchfourth:[],
            raildispatchfifth:[],
            raildispatchsixth:[],
            raildispatchseventh:[],
            raildispatcheigth:[],
            sourceoption:[],
            clusters:"",
            transporters:[],
            clusterdropdown:[],
            transdropdown:[],
            sourcedropdown:[],
            olderfyplot:[],
            rowData: [],
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true

            },              
          rowSelection:'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
          },

            animateRows: true,
            debug: true,
            showToolPanel: false,
          uppressAggFuncInHeader: true,
          target_fy: "",
          target_amount: "",
          targetFY: "",
          targetFYValue: "",
          childconfs: [],
          childrow:[],
          rowModelType: "serverSide",
          paginationPageSize:50,
          cacheBlockSize: 10,
          modules: AllModules,
          maxBlocksInCache: 1,
          userType: "",
          statusBar: {
              statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
              ]
          },
          sideBar: {
              toolPanels: [
                  {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel"
                  },
                  {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel"
                  }
              ]
          },
          showFirstChart: "show-m",
          showSecondChart: "show-n",
          showThirdChart: "show-n",
          secondChartArr: [],
          firstChartArr: [],
          thirdChartArr: [],
          fourthChartArr : [],
          fifthChartArr: [],
          secondChartRowData: [],
          yearOptions: [],
          selectedFY: {},
          defaultSelectedFy: {},
          screenName: "Rail Mode Dispatches",
          onTimeActive: "",
          railModeActive: "activet",
          onTimetarget: "",
          trailerDispatchesTarget: "",
          railModeSalesData: [],
          railModeSalesColumns: [],
          sixthLogsColumns: [],
          sixthLogsRowData: [],
          damagesLimit: "",
          logs_one_approved_date: "",
          logs_two_approved_date: "",
          logs_three_approved_date: "",
          logs_four_approved_date: "",
          logs_five_approved_date: "",
          logs_six_approved_date: "",
          remove_fy: "",
          dispatches_target_fy: "",
          ontime_target_fy: "",
          damages_target_fy: ""
        }

        this.onLoadOlderFys = this.onLoadOlderFys.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                //console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async () => {
        var fy = ""
        var currMonth = moment.parseZone(new Date()).utcOffset("+05:30").format("MM-YYYY");
        var month = moment.parseZone(new Date()).utcOffset("+05:30").format("MM");
        var year = moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY");
        var fy;
        if(month == 4)
        {
            this.setState({
                remove_fy: year+" - "+(parseInt(year)+1).toString()
            })
        }
        if(month > 4)
        {
            fy = year+" - "+(parseInt(year)+1).toString()
        }
        else
        {
            fy = (parseInt(year) - 1).toString()+" - "+year
        }
        console.log(fy, "fy")
        await this.onLoadOlderFys(fy);
        await this.onLoadSecondChart(fy);
        await this.onLoadThirdChart(fy);
        await this.onLoadFourthChart(fy);
        await this.onLoadFifthChart(fy);
        await this.onLoadSixthLogs(fy)
        if(this.props.match.path == "/brmdashboardmgmt" || this.props.match.path == "/mgmtbrmdashboard")
        {
            var userType = "management"
        }
        else
        {
            var userType = "user"
        }
        redirectURL.post("/dashboard/getMonthlyDataYears",{"collection": "brmanalyticsdata"}).then((response)=>{
            var yearOptions = [];
            response.data.map(function(e){
                yearOptions.push({"label": e, "value": e})
            })
            this.setState({
                yearOptions: yearOptions,
                selectedFY: {"label": fy, "value": fy},
                defaultSelectedFy: {"label": fy, "value": fy}
            })
        })
        this.setState({
            "userType": userType
        })
    }

    onLoadOlderFys(fy){
        redirectURL.post("/dashboard/brmdashboarddata",{"fy": fy}).then((response)=>{
            var records = response.data.nodeData;
            var apiData = JSON.parse(JSON.parse(response.data.apiData).railSalesData);
            var railModeSalesColumns = []
            // railmodelcolumns = Object.keys(apiData[0])
            for(var i in apiData[0])
            {
                var header_name = i;
                if(i != "Change Percent" && i != "Mode")
                {
                    //  header_name = i.split("-")[0]+" "+records[0]['from_month']+" - "+i.split("-")[1]+" "+records[0]['to_month']
                    header_name = i+" (Till "+records[0]['from_month']+")"

                }
                var railfyrowdata={
                    headerName: header_name,
                    field: i,
                    width: 150
                }
                if(i == "Change Percent")
                {
                    railfyrowdata['headerName'] = "% Change"
                    railfyrowdata["cellClass"] = ["highlitRow"];
                    railfyrowdata["headerClass"] = ["highlitRow"];
                    
                }
                railModeSalesColumns.push(railfyrowdata)
            }

            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))

            records = prevFy.concat(currFY)

            this.setState({
                rowData: records
            })
            var raildispatcharr = [];
            var exports = [];
            var domestic = [];
            var categories = [];
            var no_of_cars = [];
            records.map(function(e){
                exports.push(e.exports)
                domestic.push(e.domestic);
                no_of_cars.push(e.no_of_cars);
                categories.push(e.month);
            })
            var series = [
                {
                    name: 'Domestic',
                    type: 'column',
                    data: domestic,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'Exports',
                    type: 'column',
                    data: exports,
                    color: "#ff0000",
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'No. of Cars',
                    type: 'spline',
                   // yAxis: 1,
                    data: no_of_cars,
                    color: "#84a7d0",
                    dataLabels: {
                        enabled: true,
                    }
                }
                
            ]

            raildispatcharr.push({
                series : series,
                categories : categories,
                subtitle: "Target For "+response.data.nodeData[0].target_fy+": "+response.data.nodeData[0].target_amount,
                xtitle:"",
                ytitle:"",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            console.log("raildispatcharr ", apiData)
            this.setState({
                raildispatchdata: raildispatcharr,
                firstChartArr: raildispatcharr,
                targetFY: response.data.nodeData[0].target_fy,
                targetFYValue: response.data.nodeData[0].target_amount,
                logs_four_approved_date: response.data.nodeData[0]['last_approved_on'],
                railModeSalesData: apiData,
                railModeSalesColumns: railModeSalesColumns
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadSecondChart(fy){
        redirectURL.post("/dashboard/brmOnTime").then((response)=>{
            var records = response.data;
            this.setState({
                onTimetarget: records[0].target,
                logs_two_approved_date: records[0]['last_approved_on'],
                ontime_target_fy: records[0].target_fy
            })
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            if(this.state.remove_fy == "")
            {
                currFY.pop()
                currFY.pop()
            }
            else
            {
                currFY.pop()
            }
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))
            records = prevFy.concat(currFY)
            this.setState({
                secondChartRowData: records
            })
            var secondChartArr = [];
            var onTimePercentageColumn = [];
            var onTimePercentageLine = [];
            var categories = [];
            records.map(function(e){
                if(e.month_flag == 1 && e.month.includes("Apr") == false)
                {
                    onTimePercentageLine.push({y: e.on_time_percentage, "total_trips": e.total_trips,dataLabels: { x: 0, y: -10 },
                     "on_time_trips": e.on_time_trips})
                }
                else{
                    onTimePercentageLine.push({y: null, "total_trips": 0, "on_time_trips": 0})

                }
                categories.push(e.month);
            })
            prevFy.map(function(e){
                onTimePercentageColumn.push({y: e.on_time_percentage, dataLabels: { x: 0, y: -100 },
                                            "total_trips": e.total_trips, "on_time_trips": e.on_time_trips})

            })
            var series = [
                {
                    name: '% On Time',
                    type: 'column',
                    data: onTimePercentageColumn,
                    color : "#9bbb59",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: '% On Time month-wise',
                    type: 'spline',
                    color : "#8064a2",
                    marker: {
                        fillColor: '#8064a2',
                    },
                   // yAxis: 1,
                    data: onTimePercentageLine,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                }
                
            ]

            secondChartArr.push({
                series : series,
                categories : categories,
                subtitle: "",
                xtitle:"",
                ytitle:"On Time %",
                yaxistitle:"",
                chartNo: 2
            })
            this.setState({
                secondChartArr: secondChartArr,
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadThirdChart(fy){
        redirectURL.post("/dashboard/brmDispatchedTrailers").then((response)=>{
            var records = response.data.nodedata;
            console.log(records, "dispatch trailers reponse")
            this.setState({
                trailerDispatchesTarget: records[0].target,
                logs_one_approved_date: records[0].last_approved_on,
                dispatches_target_fy: records[0].target_fy
            })
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            if(this.state.remove_fy == "")
            {
                currFY.pop()
            }
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))

            records = prevFy.concat(currFY)
            this.setState({
                thirdChartRowData: records
            });
            var thirdChartArr = [];
            var prevFyDataTotals = [];
            var prevFyDataDispatched = [];
            var currFyDataTotals = [];
            var currFyDataDispatched = [];
            var categories = [];
            prevFy.map(function(e){
                prevFyDataTotals.push(e.total_trailers)
                prevFyDataDispatched.push(e.total_trailers_dispatched)
                currFyDataTotals.push(null) //adding nulls to hide data point in chart
                currFyDataDispatched.push(null) //    adding nulls
            })
            records.map(function(e){
                if(e.month_flag == 1)
                {
                    currFyDataTotals.push(e.total_trailers)
                    currFyDataDispatched.push(e.total_trailers_dispatched)
                }
                
                categories.push(e.month);
            })
            
            var series = [
                {
                    name: '% of Trailers in fleet',
                    type: 'column',
                    data: prevFyDataTotals,
                    color: "#000000",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Disp. % by Trailers',
                    type: 'column',
                    color: "#ff8080",
                    data: prevFyDataDispatched,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: '% of Trailers in fleet (month-wise)',
                    type: 'spline',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: currFyDataTotals,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Disp. % by Trailers (month-wise)',
                    type: 'spline',
                   // yAxis: 1,
                   color: "#5b5b5b",
                    data: currFyDataDispatched,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]

            thirdChartArr.push({
                series : series,
                categories : categories,
                subtitle: "",
                xtitle:"",
                ytitle:"On Time %",
                yaxistitle:"",
                chartNo: 2
            })
            console.log(thirdChartArr, "thirdChartArr")
            this.setState({
                thirdChartArr: thirdChartArr,
            })
        })
    }
    onLoadFourthChart(fy)
    {
        redirectURL.post("/dashboard/brminterstockyarddata").then((response)=>{
            var records = response.data.fyData;
            this.setState({
                logs_five_approved_date: records[0]['last_approved_on'],
            })
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            records = prevFy.concat(currFY)

            var fourthChartArr = [];
            var moves = [];
            var avg_stock = [];
            var max_stock = [];

            var categories = [];
            records.map(function(e){
                moves.push(Math.round(e.moves, 2))
                avg_stock.push(Math.round(e.average_stock_at_gurgoan, 2));
                max_stock.push(Math.round(e.maximum_stock_at_gurgoan, 2));
                categories.push(e.month);
            })
            var series = [
                {
                    name: 'Moves',
                    type: 'column',
                    data: moves,
                    color: "#92d050",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                {
                    name: 'Average Stock at GGN',
                    type: 'column',
                    data: avg_stock,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                {
                    name: 'Maximum Stock at GGN',
                    type: 'column',
                    color: "#0D233A",
                    data: max_stock,
                    color: "#000000",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                
            ]

            fourthChartArr.push({
                series : series,
                categories : categories,
                xtitle:"",
                ytitle:"Total",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            console.log("fourthChartArr", fourthChartArr)
            this.setState({
                fourthChartArr: fourthChartArr
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadFifthChart(fy)
    {
        redirectURL.post("/dashboard/brmdamagesreport").then((response)=>{
            var records = response.data.nodedata;
            this.setState({
                damagesLimit: records[0]['fy_limit'],
                logs_three_approved_date: records[0]['last_approved_on'],
                damages_target_fy: records[0].target_fy
            })
            console.log(records[0]['last_approved_on'], "records[0]['last_approved_on']")
            var damageRowData = response.data.damageData;
            var damage_report_api_data = JSON.parse(JSON.parse(response.data.apidata).damage_data) ;
            var fifthChartColumns = []
            for(var key in damage_report_api_data[0])
            {
                var cellwidth = 100;
                var cellclass = "";
                var pinned = ""
                if(key.split(" -")[0].length == 2)
                {
                    console.log("safetygrd")
                    cellclass = "cellstylegridG";
                }
                if(key == "Classification" || key == "Category of Damage")
                {
                    cellwidth = 130;
                    pinned = "left"
                }
                fifthChartColumns.push({
                    headerName: key,
                    field: key,
                    pinned: pinned,
                    width: cellwidth,
                    headerClass: cellclass,
                    cellClass: cellclass,
                    editable: false
                })
            }
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            // getting n-1 months
            currFY.pop()
            var prevFy = records.filter(function(e){
                return e.month_flag == 0
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))

            records = prevFy.concat(currFY)
            this.setState({
                fifthChartRowData: records,
                fifthChartColumns: fifthChartColumns,
                damageRowData: damageRowData
            })
            var fifthChartArr = [];
            var cat_a = [];
            var cat_b = [];
            var cat_c = [];
            var returns = [];
            var cost_per_vehicle = []
            var categories = [];
            records.map(function(e){
                cat_a.push(e.no_of_vehicles['A'])
                cat_b.push(e.no_of_vehicles['B'])
                cat_c.push(e.no_of_vehicles['C'])
                returns.push(e.return_vehicles)
                cost_per_vehicle.push(e.cost_per_vehicle_dispatch)
                categories.push(e.month);
            })
            var series = [
                {
                    name: 'A Cat',
                    type: 'column',
                    data: cat_a,
                    color: "#c88e60",
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'B Cat',
                    type: 'column',
                    data: cat_b,
                    color: "#e2d9cb",
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'C Cat',
                    type: 'column',
                    color: "#3f0c67",
                    data: cat_c,
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'Return Veh. / Lakh (Dispatches)',
                    type: 'spline',
                    color: "#64b8ce",
                    data: returns,
                    dataLabels: {
                        enabled: true,
                    }
                },
                {
                    name: 'Cost (Rs) - per vehicle dispatch',
                    type: 'scatter',
                    data: cost_per_vehicle,
                    color: '#000',
                    // borderColor: "#000",
                    borderWidth: 2,
                    dataLabels: {
                        enabled: true,
                    },
                    marker: {
                        symbol: 'square'
                    },
                    tooltip: {
                        crosshairs: true,
                        // headerFormat: '<b>{point.x}</b>',
                        pointFormat: '<br /><b>{point.name} : </b> {point.y}'
                    }
                },
                
            ]

            fifthChartArr.push({
                series : series,
                categories : categories,
                xtitle:"",
                ytitle:"Total",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            console.log("fifthChartArr", fifthChartArr)
            this.setState({
                fifthChartArr: fifthChartArr
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadSixthLogs(fy){
        redirectURL.post("/dashboard/brmCarrierDriverReport").then((response)=>{
            console.log(response.data, "sixthLogsRowData")
            var sixthLogsRowData = eval(JSON.parse(response.data.apidata).final_data);
            var nodedata = response.data.nodedata
            var sixthLogsColumns = []
            sixthLogsRowData.push({
                "Training Center": "* IDTR - Institute of Driving Training and Research, New Delhi ( 1 day training at Loni, Delhi) - Training started from 1st Feb'21 & Taget is revised to 1,000\
                due to pandemic."
            })
            sixthLogsRowData.push({
                "Training Center": "** DEC - Driver Education Center (2 hrs session at Gurgaon, Manesar, Siliguri, Gujarat, TVP-N & TVP-B)- Training started from Aug'20 & Taget is revised\
                to 53,000 due to pandemic."
            })
            console.log(sixthLogsRowData,"sixthLogsRowData")
            var sixthLogsColumns = []
            for(var key in sixthLogsRowData[0])
            {
                if(key != "last_approved_on")
                {
                    var keyname = key
                    if(key.includes("Target") || key.includes("Acheived"))
                    {
                        var count = 0
                        // var headerSplit = "";
                        // key.split(" ").map(function(e){
                        //     console.log(e, e.includes("'"))
                        //     if(e.includes("'"))
                        //     {
                        //         console.log(e.split("'")[0])
                        //         if(count == 0){
                        //             e = e.replace(e.split("'")[0], nodedata[0]['from_month'])
                        //             count = count + 1
                        //         }
                        //         else
                        //         {
                        //             e = e.replace(e.split("'")[0], nodedata[0]['to_month'])
                        //         }
                        //     }
                        //     if(headerSplit == "")
                        //     {
                        //         headerSplit = e
                        //     }
                        //     else
                        //     {
                        //         headerSplit = headerSplit+" "+e
                        //     }
                        // })
                        keyname = key+" "+nodedata[0]['from_month']
                    }
                    var rowd = {
                        headerName: keyname,
                        field: key,
                        width: 140
                    }
                    if(key.includes("Acheived"))
                    {
                        rowd['width'] = 200
                    }
                    if(key == "Training Center")
                    {
                        rowd['colSpan'] = function(params){
                            if(params.data['Training Center'] != "IDTR" && params.data['Training Center'] != "DEC")
                            {
                                return 6
                            }
                            else
                            {
                                return 1
                            }
                        }
                    }
                    sixthLogsColumns.push(rowd)
                }
            }
            this.setState({
                sixthLogsColumns: sixthLogsColumns,
                sixthLogsRowData: sixthLogsRowData,
                logs_six_approved_date: sixthLogsRowData[0]['last_approved_on'],
            })
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    
    
    

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
    }
    
    onRowSelected(event) {

    }
    closeAlert = async() => {
        await this.setState({
            show: false
        });
        $("#targetform")[0].reset();
    }


    onCellUpdatingStopped(cell)
    {   
        try{
            cell.value = eval(cell.value);
        }catch(e){
            cell.value = 0
        }
        
        var no_of_cars = cell.value;

        if(cell.colDef.field == "exports")
        {
            no_of_cars = no_of_cars + cell.data.domestic;
        }
        if(cell.colDef.field == "domestic")
        {
            no_of_cars = no_of_cars + cell.data.exports;
        }
        var params = {
            "_id": cell.data._id,
            "field": cell.colDef.field,
            "no_of_cars": no_of_cars       
        }
        params["value"] = parseInt(cell.value)

        redirectURL.post("/dashboard/updateBrmData", params).then((resp)=>{
            this.onLoadOlderFys("2020 - 2021");
        })
    }
    onCellOnTimeUpdatingStopped(cell)
    {
        try{
            cell.value = eval(cell.value);
        }catch(e){
            cell.value = 0
        }
        var inputValue = cell.value;
        var on_time_percentage;
        if(cell.colDef.field == "total_trips")
        {
            on_time_percentage = ((( cell.data.on_time_trips / inputValue)*100).toFixed(2));
            console.log(cell.value, "total_trips")
        }
        if(cell.colDef.field == "on_time_trips")
        {
            on_time_percentage = (((inputValue / cell.data.total_trips)*100).toFixed(2));
            console.log(cell.value, "on_time")

        }
        var params = {
            on_time_percentage: on_time_percentage,
            _id: cell.data._id,
            field: cell.colDef.field,
            value: cell.value
        }
        redirectURL.post("/dashboard/updateBrmOnTimeData", params).then((resp)=>{
            this.onLoadSecondChart()
        })
    }
    onclicksaveTargetData(e){
        e.preventDefault();
        var params = {
            target_fy : this.state.target_fy,
            target_amount: this.state.target_amount
        }
        console.log(params);
        redirectURL.post("/dashboard/updateTargetFy",params).then((resp)=>{
            this.setState({
                basicTitle: "updated",
                basicType: "success",
                show: true,
                targetFY: params.target_fy,
                targetFYValue: params.target_amount
            })
        })
    }
    showTab(tabno)
    {
       var tabs = {1: "showFirstChart", 2: "showSecondChart"}
       var collections = {1: "brmanalyticsdata", 2: "brmontime"}
       var screenNames = {1: "Rail Mode Dispatches", 2: "On Time Trips"}
       var activeTabs = {1: "railModeActive",2: "onTimeActive"}
       for(var key in tabs)
       {
           this.setState({
               [tabs[key]] : "show-n"
           })
       }
       for(var key in activeTabs)
       {
           this.setState({
               [activeTabs[key]] : ""
           })
       }     
       this.setState({
        [activeTabs[tabno]]:"activet"
       })
       this.setState({
           [tabs[tabno]] : "",
           screenName: screenNames[tabno] 
       }) 

       redirectURL.post("/dashboard/getMonthlyDataYears",{"collection": collections[tabno]}).then((response)=>{
            var yearOptions = [];
            response.data.map(function(e){
                yearOptions.push({"label": e, "value": e})
            })
            this.setState({
                yearOptions: yearOptions
            })
        })
    }
    onClickSubmit(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        if(this.state.defaultSelectedFy.value != this.state.selectedFY)
        {
            this.setState({
                defaultSelectedFy: this.state.selectedFY
            })
            this.onLoadOlderFys(this.state.selectedFY.value);
            this.onLoadSecondChart(this.state.selectedFY.value);
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }
        else
        {
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }
    }
    render(){
       const columnwithDefs = [
            {
               headerName: "FY / Month",
               field: "month",
               width: 145,
               editable: false,
            },
            {
                headerName: "Exports",
                field: "exports",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Domestic",
                field: "domestic",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total",
                field: "no_of_cars",
                width: 130,
                editable: false,
                enableValue: true,
                enableRowGroup: true,
            },
       ]
       const secondChartColumns = [
        {
            headerName: "FY / Month",
            field: "month",
            width: 145,
            editable: false,
         },
         {
             headerName: "Total Trips",
             field: "total_trips",
             width: 130,
             editable: true,
             enableValue: true,
            enableRowGroup: true,
         },
         {
             headerName: "On Time Trips",
             field: "on_time_trips",
             width: 130,
             editable: true,
             enableValue: true,
            enableRowGroup: true,
         },
         {
             headerName: "On Time %",
             field: "on_time_percentage",
             width: 130,
             editable: false,
             enableValue: true,
            enableRowGroup: true,
         },
       ]
        return(
            <div class="container-fluid" style={{padding:"0"}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-pie-chart cus-i"></i> <span>Logistics - BRM Report</span>
                                </h5>
                            </div> */}
                            <div className="brm-chart row" style={{padding:"5px 30px"}}>
                                
                                {/* <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-3 col-lg-3">
                                        <label>Select Financial Year : </label>
                                        <Select 
                                            closeMenuOnSelect={true} 
                                            value={this.state.selectedFY} 
                                            className="border-radius-0" 
                                            onChange={(e)=>{this.setState({selectedFY: e})}} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.state.yearOptions} required
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-2">
                                        <br />
                                        <button className="btn btn-success" onClick={this.onClickSubmit.bind(this)}>Submit</button>
                                    </div>
                                </div> */}
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                <div className="col-xl-6 col-lg-6 col-sm-6">
                                    <div className="col-xl-2 col-lg-2">
                                        <span style={{textDecoration:"underline"}}>Logs - 1</span>
                                    </div>
                                    <div className="col-xl-5 col-lg-5" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_one_approved_date)}</span>
                                    </div>
                                    <div className="col-xl-5 col-lg-5" style={{border: "1px solid",background: "#ccffcc",paddingTop:"5px",marginTop: "3%"}}>
                                        <h6 style={{ fontSize:"11px"}} ><b style={{textDecoration:"underline"}}>Target For {this.state.dispatches_target_fy} :-</b><br /> {this.state.trailerDispatchesTarget}</h6>
                                    </div>
                                    {(this.state.thirdChartArr.length > 0)?
                                            ((this.state.thirdChartArr[0].message != "no data")?
                                                (this.state.thirdChartArr.map(item => 
                                                    <RailFinancialColumnLineChart 
                                                        title={"% Trailer Dispatches & % Dispatches By Trailers"}
                                                        subtitle={item.subtitle}
                                                        context={this}
                                                        series={item.series}
                                                        categories={item.categories}
                                                        xtitle={item.xtitle}
                                                        ytitle={item.ytitle}
                                                        yaxistitle={item.yaxistitle}
                                                    />))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Trailer Dispatches  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-6">
                                    <div className="col-xl-2 col-lg-2">
                                        <span style={{textDecoration:"underline"}}>Logs - 2</span>
                                    </div>
                                    <div className="col-xl-5 col-lg-5" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_two_approved_date)}</span>
                                    </div>
                                    <div className="col-xl-5 col-lg-5" style={{border: "1px solid",background: "#ccffcc",paddingTop:"5px",marginTop: "3%"}}>
                                        <h6 style={{textDecoration:"underline", fontSize:"11px"}} >Target For {this.state.ontime_target_fy} :- {this.state.onTimetarget}</h6>
                                    </div>
                                    {(this.state.secondChartArr.length > 0)?
                                            ((this.state.secondChartArr[0].message != "no data")?
                                                (this.state.secondChartArr.map(item => 
                                                    <RailBRMColumnLineChart 
                                                        title={"% On Time (Domestic)"}
                                                        subtitle={item.subtitle}
                                                        context={this}
                                                        series={item.series}
                                                        categories={item.categories}
                                                        xtitle={item.xtitle}
                                                        ytitle={item.ytitle}
                                                        yaxistitle={item.yaxistitle}
                                                    />))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5> On Time {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5> On time  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-4 col-lg-4">
                                        <span style={{textDecoration:"underline"}}>Logs - 3</span>
                                    </div>
                                    <div className="col-xl-4 col-lg-4" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_three_approved_date)}</span>
                                    </div>
                                    <div className="col-xl-4 col-lg-4" style={{}}>
                                        <h6 style={{ border: "1px solid",background: "#ccffcc",padding:"8px",fontSize:"11px",marginTop:"3%"}} ><b style={{textDecoration:"underline"}}>Target For {this.state.damages_target_fy} :-</b> {this.state.damagesLimit}</h6>
                                    </div>
                                    {(this.state.fifthChartArr.length > 0)?
                                            ((this.state.fifthChartArr[0].message != "no data")?
                                                (this.state.fifthChartArr.map(item => 
                                                    <div className="col-xl-12 col-lg-12">
                                                        <RailFinancialColumnLineChart 
                                                            title={"Damages(External) - per lakh dispatches"}
                                                            subtitle={""}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />
                                                    </div>
                                                    
                                                    ))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Damages Report FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Damages Report  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                </div>
                                <div className="row col-xl-6 col-lg-6 col-sm-6">
                                    <div className="col-xl-6 col-lg-6">
                                        <span style={{textDecoration:"underline"}}>Logs - 4</span>
                                    </div>
                                    <div className="col-xl-6 col-lg-6" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_four_approved_date)}</span>
                                    </div>
                                    {(this.state.raildispatchdata.length > 0)?
                                            ((this.state.raildispatchdata[0].message != "no data")?
                                                (this.state.raildispatchdata.map(item => 
                                                    <div className="col-xl-12 col-lg-12">
                                                        <RailFinancialColumnLineChart 
                                                            title={"Rail Mode Dispatches"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />
                                                    </div>
                                                   
                                                    ))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Rail Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Rail Mode Dispatches  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                </div>
                                <div className="row col-xl-6 col-lg-6 col-sm-6">
                                    <div className="col-xl-6 col-lg-6">
                                        <span style={{textDecoration:"underline"}}>Logs - 5</span>
                                    </div>
                                    <div className="col-xl-6 col-lg-6" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_five_approved_date)}</span>
                                    </div>
                                    {(this.state.fourthChartArr.length > 0)?
                                            ((this.state.fourthChartArr[0].message != "no data")?
                                                (this.state.fourthChartArr.map(item => 
                                                    <div className="col-xl-12 col-lg-12">
                                                    <RailFinancialColumnLineChart 
                                                        title={"Inter-Stockyard Moves"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.series}
                                                        categories={item.categories}
                                                        xtitle={item.xtitle}
                                                        ytitle={item.ytitle}
                                                        yaxistitle={item.yaxistitle}
                                                    />
                                                    </div>
                                                    ))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Inter-Stockyard Moves  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                    <div className="col-xl-12 col-lg-12" >
                                        <h6>* Inter-Stockyard move data excludes D21 project volumes wef Oct'20</h6>
                                    </div>
                                    {/* <div className={"row "+this.state.showSixthLogs}> */}
                                    {/* </div> */}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-6">
                                    <div id="myGrid" style={{ height: "200px",width: "90%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.railModeSalesColumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.railModeSalesData}
                                                enableCharts={false}
                                                modules={this.state.modules}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                rowClassRules={{
                                                    "highlitRow": "data.Mode == 'Rail Contribution'"
                                                }}
                                                // suppressRowClickSelection={true}
                                                // onRowSelected={this.onRowSelected.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                // onCellEditingStopped={this.onCellUpdatingRailModeFyData.bind(this)}
                                            />
                                        </div>
                                </div>
                                <div className="row col-xl-6 col-lg-6 col-sm-6">
                                    <div className="col-xl-6 col-lg-6" >
                                        <span style={{textDecoration:"underline"}}>Logs - 6</span>
                                    </div>
                                    <div className="col-xl-6 col-lg-6" style={{fontSize:"13px"}}>
                                        <span style={{textDecoration:"underline"}}>Last Approved on {getHyphenDDMMMYYYYHHMM(this.state.logs_six_approved_date)}</span>
                                    </div>
                                    <div id="myGrid" style={{ height: "250px",width: "100%"}}  className="ag-theme-balham col-xl-12 col-lg-12">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.sixthLogsColumns}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.sixthLogsRowData}
                                            modules={this.state.modules}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            components={this.state.components}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            // onRowSelected={this.onRowSelected.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            // onCellEditingStopped={this.onCellBrmDamageData.bind(this)}
                                        />
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 


function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 