'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class SndRegionWiseReport extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            width : 70,
            enableRowGroup: true,
            enableValue: true,
            enablePivot: true
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        scheduled : null,
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        rowClassRules: {
            "subtotalrow": "data.region.includes('Total') || data.region.includes('Return')"
        },
        modelrowClassRules : {
            "subtotalrow": "data.sbvh.includes('Total') || data.sbvh.includes('Return')"
        },
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        consigner_coordinates:[],
        consignee_coordinates:[],
        showSelectDept:"show-m",
        usergridstate:[],
        screenurl:"",
        screentitle:"",
        pagetitle:"",
        eventGridAction: "gridAction",
        tolls : [],
        startDate : "",
        endDate : "",
        selectedConsginers : {"label" : "ALL", "value" : "ALL"},
        selectedTransporters : {"label" : "ALL", "value" : "ALL"},
        transporterOptions : [],
        consignerOptions : [],
        defaultStartDate : "",
        defaultEndDate : "",
        modelwisedata : [],
        regionwisedata : [],
        pinnedBottomRowData: [],
        pinnedRegionBottomRowData : [],
        pinnedSbvhRowData: [],
        modelwisesbvhdata: [],
        type : {label: "ALL",value:"ALL"},
        summary: "activet",
        complete:"",
        summaryData:"",
        completeData:"show-n",
        intransitData: "show-n",
        completeRowData:[],
        totalInvoiced:0,
        inTransit:0,
        carsDelivered:0,
        returns_data: [],
        returnsData: "show-n",
        returns: "",
        intransittab: "",
        intransitRowData: [],
        defaultCompleteRowData: [],
        trucks_count: 0,
        vins_count : 0,
        fetched_recs_count: 0,
        total_recs_count: 0,
        //components : {datepicker:getDatePicker()}
    }
    this.getDisptachTotals = this.getDisptachTotals.bind(this);
  }

getDisptachTotals(dispatchdata,type)
{
    var total_gurgoan_count = 0
    var total_manesar_count = 0
    var total_gujarat_count = 0
    var total_siliguri_count = 0;
    var  monthly_total = 0
    var total_daily_gurgoan_count = 0
    var total_daily_manesar_count = 0
    var total_daily_gujarat_count = 0
    var total_daily_siliguri_count = 0;
    var daily_total = 0
    for(var i=0;i<dispatchdata.length;i++)
    {
        if(type == "region")
        {
            if(dispatchdata[i]["region"] != "Total North" && dispatchdata[i]["region"] != "Total South"
            && dispatchdata[i]["region"] != "Total West" && dispatchdata[i]["region"] != "Total East"
            && dispatchdata[i]["region"] != "Total Central" && dispatchdata[i]["region"] != "Total South East")
            {
                total_gurgoan_count = total_gurgoan_count+ dispatchdata[i]['monthly_gurgoan_count']
                total_manesar_count = total_manesar_count+ dispatchdata[i]['monthly_manesar_count']
                total_gujarat_count = total_gujarat_count+ dispatchdata[i]['monthly_gujarat_count']
                total_siliguri_count = total_siliguri_count+ dispatchdata[i]['monthly_siliguri_count']
                monthly_total = monthly_total + dispatchdata[i]['monthly_total']
                total_daily_gurgoan_count = total_daily_gurgoan_count+ dispatchdata[i]['daily_gurgoan_count']
                total_daily_manesar_count = total_daily_manesar_count+ dispatchdata[i]['daily_manesar_count']
                total_daily_gujarat_count = total_daily_gujarat_count+ dispatchdata[i]['daily_gujarat_count']
                total_daily_siliguri_count = total_daily_siliguri_count+ dispatchdata[i]['daily_siliguri_count']
                daily_total = daily_total + dispatchdata[i]['daily_total']
            }
        }
        else{
            total_gurgoan_count = total_gurgoan_count+ dispatchdata[i]['monthly_gurgoan_count']
            total_manesar_count = total_manesar_count+ dispatchdata[i]['monthly_manesar_count']
            total_gujarat_count = total_gujarat_count+ dispatchdata[i]['monthly_gujarat_count']
            total_siliguri_count = total_siliguri_count+ dispatchdata[i]['monthly_siliguri_count']
            monthly_total = monthly_total + dispatchdata[i]['monthly_total']
            total_daily_gurgoan_count = total_daily_gurgoan_count+ dispatchdata[i]['daily_gurgoan_count']
            total_daily_manesar_count = total_daily_manesar_count+ dispatchdata[i]['daily_manesar_count']
            total_daily_gujarat_count = total_daily_gujarat_count+ dispatchdata[i]['daily_gujarat_count']
            total_daily_siliguri_count = total_daily_siliguri_count+ dispatchdata[i]['daily_siliguri_count']
            daily_total = daily_total + dispatchdata[i]['daily_total']
        }
        
    }
    var rowTotal=[{
        monthly_gurgoan_count : total_gurgoan_count,
        monthly_manesar_count : total_manesar_count,
        monthly_gujarat_count : total_gujarat_count,
        monthly_siliguri_count : total_siliguri_count,
        monthly_total : monthly_total,
        daily_gurgoan_count : total_daily_gurgoan_count,
        daily_manesar_count : total_daily_manesar_count,
        daily_gujarat_count : total_daily_gujarat_count,
        daily_siliguri_count : total_daily_siliguri_count,
        daily_total : daily_total
    }]
    
    if(type == "sbvh")
    {
        rowTotal[0]['sbvh']="Total";
    }
    else
    {
        rowTotal[0]['region']="Total";   
    }

    
    return rowTotal
}

componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    loadDateTimeScript();
    
    this.logPageView(); // Log page view to GA
    let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    this.setState({eventAction: eventAction});

    redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    .then( async (resp) => {
        //console.log("resp ", resp)
        await this.setState({
            usergridstate:resp.data
        });
        await this.restoreGridStates();
    });

    var currentmonth = new Date();
    currentmonth = (currentmonth.getMonth()+1).toString()+"-"+currentmonth.getFullYear().toString()
    $(".selected_date").val(currentmonth);
    redirectURL.post("/vicinity/getsndgregionwisereport",{"selectedmonth":currentmonth,"type":"ALL"}).then((response)=>{
        try{
            var month_wise_total_keys = ['monthly_gurgoan_count' ,"monthly_manesar_count", "monthly_gujarat_count", "monthly_siliguri_count"]
            var daily_wise_total_keys = ['daily_gurgoan_count' ,"daily_manesar_count", "daily_gujarat_count", "daily_siliguri_count"]
            // console.log(JSON.parse(response.data.sbvh_data))
            var modelwisedata = JSON.parse(response.data.model_wise);
            // var pinnedBottomRowData = modelwisedata.slice(Math.max(modelwisedata.length - 3, 0))
            // for(var i=0;i<3;i++){
            //     modelwisedata.pop()
            // }
            // modelwisedata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });  
            //     e['monthly_total'] = e.monthly_gurgoan_count+e.monthly_manesar_count+e.monthly_gujarat_count+e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count+e.daily_manesar_count+e.daily_gujarat_count+e.daily_siliguri_count
            // })
            // var modelTotal = this.getDisptachTotals(modelwisedata,"sbvh");
            
            var modelwisesbvhdata = JSON.parse(response.data.sbvh_data);
            // var pinnedSbvhRowData = modelwisesbvhdata.slice(Math.max(modelwisesbvhdata.length - 3, 0))
            // for(var i=0;i<3;i++){
            //     modelwisesbvhdata.pop()
            // }
            // modelwisesbvhdata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     }); 
            //     e['monthly_total'] = e.monthly_gurgoan_count+e.monthly_manesar_count+e.monthly_gujarat_count+e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count+e.daily_manesar_count+e.daily_gujarat_count+e.daily_siliguri_count
            // })
            // var sbvhTotal = this.getDisptachTotals(modelwisesbvhdata,"sbvh");

            var regionTotal=[]
            var regionwisedata = JSON.parse(response.data.region_wise);
            // var pinnedRegionBottomRowData = regionwisedata.slice(Math.max(regionwisedata.length - 3, 0))
            // for(var i=0;i<3;i++){
            //     regionwisedata.pop()
            // }
            // regionwisedata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });  
            //     e['monthly_total'] = e.monthly_gurgoan_count + e.monthly_manesar_count + e.monthly_gujarat_count + e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count + e.daily_manesar_count + e.daily_gujarat_count + e.daily_siliguri_count
            // })
            // var regionTotal = this.getDisptachTotals(regionwisedata,"region");
            // modelwisedata.push(modelTotal[0]);

            console.log(modelwisesbvhdata,"modelwisesbvhdata")
            this.setState({
                regionwisedata : regionwisedata,
                modelwisedata : modelwisedata,
                modelwisesbvhdata: modelwisesbvhdata,
                loadshow : "show-n",
                overly : "show-n",
                // pinnedBottomRowData : pinnedBottomRowData,
                // pinnedRegionBottomRowData : pinnedRegionBottomRowData,
                // pinnedSbvhRowData: pinnedSbvhRowData
            })
        }
        catch(e){
            console.log(e,"")
            this.setState({
                regionwisedata : [],
                modelwisedata : [],
                loadshow : "show-n",
                overly : "show-n"
            })
        }
        
    }).catch((e)=>{
        console.log(e)
    })
    
    this.getCompleteData(0, null);
}

getCompleteData(fetched_recs_count, total_recs_count){
    // this.setState({
    //     loadshow : "show-m",
    //     overly : "show-m"
    // })
    var selectedmonth = $(".selected_date").val();
    var type = this.state.type.value;
    var query = {
        "selectedmonth" : selectedmonth,
        "type" : type,
    }
    redirectURL.post("/vicinity/getSndRegionWiseCompleteData",query).then(async (response)=>{
        var completeRowData = response.data.dispatched_data;
        var totalInvoiced = completeRowData.length;
        this.setState({
            completeRowData : completeRowData,
            defaultCompleteRowData: completeRowData,
            totalInvoiced : totalInvoiced,
            loadshow : "show-n",
            overly : "show-n"
        })
        redirectURL.post("/vicinity/getDispatchesReturnData",query).then((resp)=>{
            var returns_data = resp.data.returns_data; 
            this.setState({
                returns_data: returns_data,
            })
        })
        
        // var recent_object_id = null;
        var fetched_recs_count = 0;
        var total_recs_count= null;
        var i = 0;
        // for(var i=0;i<3;i++)
        // {
        console.log("start time", new Date());
        while(true){
            var flag = 0;
            if(i >= 0)
            {
                // query["recent_object_id"] = recent_object_id;
                query["fetched_recs_count"] = fetched_recs_count;
                query["total_recs_count"] = total_recs_count;
                query['first_hit'] = i;
                await redirectURL.post("/vicinity/getDispatchesData",query).then((resp)=>{
                    total_recs_count = resp.data.total_recs_count;
                    fetched_recs_count = resp.data.fetched_recs_count;
                    // recent_object_id = resp.data.recent_object_id;

                    this.setState({
                        total_recs_count: total_recs_count,
                        fetched_recs_count: fetched_recs_count
                    })
                    var fetched_all_intranist = 0;
                    if(fetched_recs_count == total_recs_count)
                    {
                        fetched_all_intranist = 1;
                    }
                    this.setState({
                        "fetched_all_intranist": fetched_all_intranist
                    })

                    if(total_recs_count > 0)
                    {
                        if(i == 0)
                        {
                            this.inTransitGridApi.setRowData(resp.data.intransit_data)
                        }
                        else
                        {
                            this.inTransitGridApi.updateRowData({
                                add: resp.data.intransit_data
                            });
                        }
                        // var intransitRowData = this.state.intransitRowData.concat(resp.data.intransit_data);
                        var trucks_count =  _.groupBy(resp.data.intransit_data, function(car) {
                                                        return car.truck_no;
                                                    });
                        if(Object.keys(trucks_count).length)
                        {
                            trucks_count = Object.keys(trucks_count).length
                        }
                        else
                        {
                            trucks_count = 0;
                        }
                        this.setState({
                            // intransitRowData: intransitRowData,
                            vins_count: resp.data.intransit_data.length + this.state.vins_count,
                            trucks_count: trucks_count + this.state.trucks_count,
                        })
                        if(total_recs_count == fetched_recs_count)
                        {
                            flag = 1;
                        }
                    }
                    else
                    {
                        flag = 1;
                    }
                }).catch((e)=>{
                    flag =1
                    console.log(e)
                })
            }
            if(flag == 1)
            {
                console.log("break");
                break;
            }
            i++
        }
        console.log("end time", new Date());

        redirectURL.post("/vicinity/getSndRegionWiseCountersData",query).then((response)=>{
            var carsDelivered = response.data.length;
            var inTransit = parseInt(totalInvoiced)-parseInt(carsDelivered);
            this.setState({
                inTransit:inTransit,
                carsDelivered:carsDelivered,
            }) 
        }).catch((e)=>{
            console.log(e);
        })
    }).catch((e)=>{
        console.log(e);
    })
}

   
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}
onInTransitGridReady = params => {
    this.inTransitGridApi = params.api;  
    this.inTransitGridColumnApi = params.columnApi;
}
onInTransitGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.inTransitGridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.inTransitGridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.inTransitGridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.inTransitGridApi.getFilterModel();
    this.inTransitGridApi.setFilterModel(window.filterState);
}


closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}



 
onClickSaveGridState(){
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Save Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    window.colState = this.gridColumnApi.getColumnState();
    window.groupState = this.gridColumnApi.getColumnGroupState();
    window.sortState = this.gridApi.getSortModel();
    window.filterState = this.gridApi.getFilterModel();
    
    let screenpage = '';

    if(this.props.match.path == "/sndPossibletransshipments")
    {
        screenpage='snd possible transhipment';
    }
    else if(this.props.match.path == "/prtPossibletransshipments")
    {
        screenpage='prt possible transhipment';
    }
    else if(this.props.match.path == "/tnpPossibletransshipments")
    {
        screenpage='tnp possible transhipment';
    }

    let reqparams = {
        gridcolumns:window.colState,
        gridgroup:window.groupState,
        gridcolsort:window.sortState,
        gridcolfilter:window.filterState,
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:screenpage
    }
    //console.log("reqparams ", reqparams)
    redirectURL.post("/consignments/saveGridStates", reqparams)
    .then((response) => {
        //console.log("State response ",response.data)
        this.setState({
            show:true,
            basicTitle:"Successfully saved grid layout",
            basicType:"success",
            screenurl:window.location.pathname,
            screentitle:screenpage
        })
    })
    .catch(function(e){
        console.log("Error ", e)
    })
}

restoreGridStates()
{

    if(this.state.usergridstate.length > 0)
    {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        
    }
}

resetState = () => {
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Reset Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    //console.log('column state reset');
    var reqparams = {
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:this.state.screenpage
    }

    redirectURL.post("/consignments/removeUserGridState", reqparams)
    .then((response) => {
        this.setState({
            show:true,
            basicTitle:"Successfully reset default grid layout",
            basicType:"success"
        })
    })

};


handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+((d.getMonth() +1) >= 10?(d.getMonth() +1):"0"+(d.getMonth() +1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    
    this.setState({
        startDate:startdate
    });
}



onClickSubmit(event)
{   
    event.preventDefault();
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    var selectedmonth = $(".selected_date").val();
    redirectURL.post("/vicinity/getsndgregionwisereport",{"selectedmonth":selectedmonth,"type":this.state.type.value}).then((response)=>{
        try{
            var month_wise_total_keys = ['monthly_gurgoan_count' ,"monthly_manesar_count", "monthly_gujarat_count", "monthly_siliguri_count"]
            var daily_wise_total_keys = ['daily_gurgoan_count' ,"daily_manesar_count", "daily_gujarat_count", "daily_siliguri_count"]
            console.log(JSON.parse(response.data.sbvh_data))
            var modelwisedata = JSON.parse(response.data.model_wise);
            // modelwisedata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });  
            //     e['monthly_total'] = e.monthly_gurgoan_count+e.monthly_manesar_count+e.monthly_gujarat_count+e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count+e.daily_manesar_count+e.daily_gujarat_count+e.daily_siliguri_count
            // })
            // var modelTotal = this.getDisptachTotals(modelwisedata,"sbvh");
            
            var modelwisesbvhdata = JSON.parse(response.data.sbvh_data);
            // modelwisesbvhdata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     }); 
            //     e['monthly_total'] = e.monthly_gurgoan_count+e.monthly_manesar_count+e.monthly_gujarat_count+e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count+e.daily_manesar_count+e.daily_gujarat_count+e.daily_siliguri_count
            // })
            // var sbvhTotal = this.getDisptachTotals(modelwisesbvhdata,"sbvh");

            var regionTotal=[]
            var regionwisedata = JSON.parse(response.data.region_wise);
            // regionwisedata.map(function(e){
            //     month_wise_total_keys.map(function(k){
            //         console.log(e[k], e['region'], k)
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });
            //     daily_wise_total_keys.map(function(k){
            //         if(e[k] == undefined || e[k] == "" )
            //         {
            //             e[k] = 0
            //         }
            //     });  
            //     e['monthly_total'] = e.monthly_gurgoan_count + e.monthly_manesar_count + e.monthly_gujarat_count + e.monthly_siliguri_count
            //     e['daily_total'] = e.daily_gurgoan_count + e.daily_manesar_count + e.daily_gujarat_count + e.daily_siliguri_count
            // })
            // var regionTotal = this.getDisptachTotals(regionwisedata,"region");
            // modelwisedata.push(modelTotal[0]);
            this.setState({
                regionwisedata : regionwisedata,
                modelwisedata : modelwisedata,
                loadshow : "show-n",
                overly : "show-n",
                // pinnedBottomRowData : modelTotal,
                // pinnedRegionBottomRowData : regionTotal,
                // pinnedSbvhRowData: sbvhTotal,
                modelwisesbvhdata: modelwisesbvhdata,

            })
        }
        catch(e){
            this.setState({
                regionwisedata : [],
                modelwisedata : [],
                loadshow : "show-n",
                overly : "show-n"
            })
        }
        
    }).catch((e)=>{
        console.log(e)
    })
    this.getCompleteData(0, null);
}

onClickTab(pageType){
    if(pageType == "summary")
    {
        var summary = "activet";
        var complete = "";
        var returns = "";
        var intransittab = "";
        var summaryData = "";
        var completeData = "show-n";
        var intransitData = "show-n";
        var returnsData = "show-n";
    }
    if(pageType == "complete")
    {
        var summary = "";
        var complete = "activet";
        var returns = "";

        var intransittab = "";
        var summaryData = "show-n";
        var completeData = "";
        var intransitData = "show-n";
        var returnsData = "show-n";
        this.setState({
            completeRowData:  this.state.defaultCompleteRowData
        })
        //this.getCompleteData();
    }
    if(pageType == "returns")
    {
        var summary = "";
        var complete = "";
        var intransittab = "";
        var returns = "activet";
        var summaryData = "show-n";
        var completeData = "show-n";
        var intransitData = "show-n";
        var returnsData = ""
    }
    if(pageType == "intransit")
    {
        var summary = "";
        var complete = "";
        var returns = "";
        var intransittab = "activet";
        var summaryData = "show-n";
        var completeData = "show-n";
        var intransitData = "";
        var returnsData = "show-n";
    }
    this.setState({
        summary: summary,
        complete: complete,
        returns: returns,
        summaryData: summaryData,
        completeData: completeData,
        intransitData: intransitData,
        returnsData: returnsData,
        intransittab: intransittab,

    })
}

render() {
    const listyle = {
        borderRight: "1px solid black !important",
        border: "none !important",
        paddingLeft: "1em",
        paddingRight: "1em"
    }
    var hidedistances = (this.props.match.path == "/prtuniquetrucktrips")?false:true;
    var regionwisecolumns = [     
        
        {
            headerName: "Region",
            field: "region",
            filter: true,
            resizable: true,
            editable:false,
            width : 120,
            pinned: "left",
            valueGetter: function(params){
                if(params.data.region == "Total")
                {
                    return "Dispatched Cars"
                }
                else if(params.data.region == "Return Trucks")
                {
                    return "Return Cars"
                }
                else
                {
                    return params.data.region
                }
            }
            // valueGetter : function(params)
            // {
            //     if(params.data.region == "T1")
            //     {
            //         return "South East 1"
            //     }
            //     if(params.data.region == "T2")
            //     {
            //         return "South East 2"
            //     }
            //     else
            //     {
            //         return params.data.region
            //     }
            // }
        },
        {
            headerName : "Month",
            headerClass:["cellstylegridB","cellTopHeader"],
            children : [
                {
                    headerName : "F",
                    field : "monthly_gurgoan_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "M",
                    field : "monthly_manesar_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "G",
                    field : "monthly_gujarat_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "I",
                    field : "monthly_siliguri_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "Total",
                    field : "monthly_total",
                    resizable : true,
                    editable : false,
                    headerClass:"subtotalrow",
                    cellClass:'subtotalrow',
                }
            ]
        },
        {
            headerName : "Today",
            headerClass:["cellstylegridG","cellTopHeader"],
            children : [
                {
                    headerName : "F",
                    field : "daily_gurgoan_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "M",
                    field : "daily_manesar_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "G",
                    field : "daily_gujarat_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "I",
                    field : "daily_siliguri_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "Total",
                    field : "daily_total",
                    resizable : true,
                    editable : false,
                    headerClass:"subtotalrow",
                    cellClass:'subtotalrow',
                    // valueGetter : function(params)
                    // {
                    //     return params.data.daily_gurgoan_count+params.data.daily_manesar_count+params.data.daily_gujarat_count
                    // }
                },
        
            ]
        },
    ]
    var modelwisecolumns = [     
        
        {
            headerName: "Model",
            field: "sbvh",
            filter: true,
            resizable: true,
            editable:false,
            pinned: "left",
            width:120,
            valueGetter: function(params){
                if(params.data.sbvh == "Total")
                {
                    return "Dispatched Cars"
                }
                else if(params.data.sbvh == "Return Trucks")
                {
                    return "Return Cars"
                }
                else
                {
                    return params.data.sbvh
                }
            }
        },
        {
            headerName : "Month",
            headerClass:["cellstylegridB","cellTopHeader"],
            children : [
                {
                    headerName : "F",
                    field : "monthly_gurgoan_count",
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB',
                    resizable : true,
                    editable : false,
                },
                {
                    headerName : "M",
                    field : "monthly_manesar_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "G",
                    field : "monthly_gujarat_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "I",
                    field : "monthly_siliguri_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridB",
                    cellClass:'cellstylegridB'
                },
                {
                    headerName : "Total",
                    field : "monthly_total",
                    resizable : true,
                    editable : false,
                    headerClass:"subtotalrow",
                    cellClass:'subtotalrow',
                    // valueGetter : function(params)
                    // {
                    //     return params.data.monthly_gurgoan_count+params.data.monthly_manesar_count+params.data.monthly_gujarat_count
                    // }
                }
            ]
        },
        {
            headerName : "Today",
            headerClass:["cellstylegridG","cellTopHeader"],
            children : [
                {
                    headerName : "F",
                    field : "daily_gurgoan_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "M",
                    field : "daily_manesar_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "G",
                    field : "daily_gujarat_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
				    cellClass:'cellstylegridG',
                },
                {
                    headerName : "I",
                    field : "daily_siliguri_count",
                    resizable : true,
                    editable : false,
                    headerClass:"cellstylegridG",
                    cellClass:'cellstylegridG'
                },
                {
                    headerName : "Total",
                    field : "daily_total",
                    resizable : true,
                    editable : false,
                    headerClass:"subtotalrow",
                    cellClass:'subtotalrow',
                    // valueGetter : function(params)
                    // {
                    //     return params.data.daily_gurgoan_count+params.data.daily_manesar_count+params.data.daily_gujarat_count
                    // }
                }
            ]
        },
    ]
    const pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    }
    var columnwithDefs = [
        {
            headerName: "Load No",
            field: "load_no",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "VIN",
            field: "vin",
            width:150,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            width:160,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Truck No",
            field: "truck_no",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:160,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:220,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consignee City",
            field: "consignee_city",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignee State",
            field: "consignee_state",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Region",
            field: "region",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "SBVH",
            field: "sbvh",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Gate In Time",
            field: "gate_in_time",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_in_time != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Retrival Start Date",
            field: "retrival_start_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.retrival_start_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.retrival_start_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Retrival End Date",
            field: "retrival_end_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.retrival_end_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.retrival_end_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "LPD Date",
            field: "lpd_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.lpd_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.lpd_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Accessory Issuance Date",
            field: "acc_issu_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.acc_issu_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.acc_issu_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Load Attach Date",
            field: "load_attach_date",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.load_attach_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.load_attach_date);
                }
                else
                {
                    return ""
                }

            },           
        },        
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.invoice_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                }
                else
                {
                    return ""
                }

            },
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_out_time != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Month End Flag",
            field: "month_end_flag",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Rail Consignment",
            field: "rail_consignment",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.rail_consignment == 0)
                {
                    return "No";
                }
                else
                {
                    return "Yes";
                }

            },
        },
        {
            headerName: "Cluster",
            field: "cluster",
            width:80,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Rail Cluster",
            field: "rail_cluster",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Route Id",
            field: "route_id",
            width:100,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "TVP Gate In",
            field: "tvp_gate_in",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.tvp_gate_in != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in);
                }
                else
                {
                    return ""
                }

            },
        },
        {
            headerName: "Pod Received",
            field: "pod_received",
            width:200,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                if(params.data.pod_received == 1)
                {
                    return "Yes";
                }
                else
                {
                    return "No";
                }
            }
        },
        {
            headerName: "Trip Delivery Date",
            field: "trip_delivery_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.trip_delivery_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_delivery_date);
                }
                else
                {
                    return ""
                }

            },           
        }
    ]
    var returnColumns = [
        {
            headerName: "Load No",
            field: "load_no",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "VIN",
            field: "vin",
            width:150,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            width:160,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Return Date",
            field: "rtgp_dt",
            width:160,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter : function(params){
                return getHyphenDDMMMYYYYHHMM(params.data.rtgp_dt)
            }
        },
        {
            headerName: "Truck No",
            field: "truck_no",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width:160,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:220,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Consignee City",
            field: "consignee_city",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Consignee State",
            field: "consignee_state",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Region",
            field: "region",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "SBVH",
            field: "sbvh",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "Gate In Time",
            field: "gate_in_time",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_in_time != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Retrival Start Date",
            field: "retrival_start_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.retrival_start_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.retrival_start_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Retrival End Date",
            field: "retrival_end_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.retrival_end_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.retrival_end_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "LPD Date",
            field: "lpd_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.lpd_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.lpd_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Accessory Issuance Date",
            field: "acc_issu_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.acc_issu_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.acc_issu_date);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Load Attach Date",
            field: "load_attach_date",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.load_attach_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.load_attach_date);
                }
                else
                {
                    return ""
                }

            },           
        },        
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.invoice_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                }
                else
                {
                    return ""
                }

            },
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.gate_out_time != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                }
                else
                {
                    return ""
                }

            },           
        },
        {
            headerName: "Month End Flag",
            field: "month_end_flag",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Rail Consignment",
            field: "rail_consignment",
            width:140,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.rail_consignment == 0)
                {
                    return "No";
                }
                else
                {
                    return "Yes";
                }

            },
        },
        {
            headerName: "Cluster",
            field: "cluster",
            width:80,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Rail Cluster",
            field: "rail_cluster",
            width:120,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,            
        },
        {
            headerName: "Route Id",
            field: "route_id",
            width:100,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false
        },
        {
            headerName: "TVP Gate In",
            field: "tvp_gate_in",
            width:180,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.tvp_gate_in != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in);
                }
                else
                {
                    return ""
                }

            },
        },
        {
            headerName: "Pod Received",
            field: "pod_received",
            width:200,
            filter: true,
            resizable: true,
            sortable:true,
            editable:false,
            valueGetter:function(params){
                if(params.data.pod_received == 1)
                {
                    return "Yes";
                }
                else
                {
                    return "No";
                }
            }
        },
        {
            headerName: "Trip Delivery Date",
            field: "trip_delivery_date",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
            sortable:true,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.trip_delivery_date != null)
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_delivery_date);
                }
                else
                {
                    return ""
                }

            },           
        }
    ]
    return (
    <div className="container-fluid">
        <div className="row col-xl-12 col-lg-12">
            
            {/* <div className="row col-xl-12 col-lg-12"> */}
                <form method="post" className='row col-xl-12 col-lg-12' onSubmit={this.onClickSubmit.bind(this)}>
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Select Month</label>
                        <input type="text" className="selected_date form-control" id="dispatch_dashboard_date" required />
                    </div>
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Select Dispatch Type</label>
                        <Select 
                        className="border-radius-0"
                        value={this.state.type}
                        isMulti={false}
                        id="selectedFilter"
                        onChange={((e)=>{this.setState({type:e})})}
                        style={{borderRadius:"0px"}} 
                        options={[{label:"ALL",vaue:"ALL"},{label:"Exports",value:"Exports"},{label:"Domestic",value:"Domestic"}]} required />

                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="submit" className="btn btn-success" autoComplete="off">Submit</button>
                    </div>
                </form>    
            {/* </div> */}
        
        </div>
        <div className="row">
            <div className="col-xl-8 col-lg-8">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">
                        <li onClick={this.onClickTab.bind(this,"summary")} className={"ttabs "+(this.state.summary)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"config")}>Summary Data</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"complete")} className={"ttabs "+(this.state.complete)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"complete")}>Dispatched Data</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"returns")} className={"ttabs "+(this.state.returns)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"complete")}>Returns Data</button>
                        </li>
                        <li onClick={this.onClickTab.bind(this,"intransit")} className={"ttabs "+(this.state.intransittab)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"intransit")}>Intransit Data</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
            <div className="col-xl-6 col-lg-6">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs-li">
                        <li style={listyle}><b style={{color:"red"}}>F</b> : <b>Gurgoan</b></li>
                        <li style={listyle}><b style={{color:"red"}}>M</b> : <b>Manesar</b></li>
                        <li style={listyle}><b style={{color:"red"}}>G</b> : <b>Gujarat</b></li>
                        <li style={listyle}><b style={{color:"red"}}>I</b> : <b>Siliguri</b></li>
                    </ul>
                </div>
            </div>
            {(this.state.intransittab == "")?
                <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "+this.state.completeData}>
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13">
                                            {/* <i className="icofont icofont-ui-browser f24 greenfont"></i><br />  */}
                                            Total invoiced
                                        </span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalInvoiced != '')?this.state.totalInvoiced:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cursorPointer">
                                        <span className="f13">
                                            {/* <i className="icofont icofont-clock-time f22 redfont"></i><br />  */}
                                            No. of cars in transit</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.inTransit != '')?this.state.inTransit:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cursorPointer">
                                        <span className="f13">
                                            {/* <i className="fa fa-tachometer f22 txt-success"></i><br />  */}                                        
                                            No. of cars delivered
                                        </span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.carsDelivered != '')?this.state.carsDelivered:0}/></span></h4>
                                    
                                    </div>
                                    
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            :
            <div className={"col-xl-12 col-lg-12 col-sm-12 beffect"}>
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer">
                                    <span className="f13">
                                        {/* <i className="icofont icofont-ui-browser f24 greenfont"></i><br />  */}
                                        Total Trucks
                                    </span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.trucks_count != '')?this.state.trucks_count:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer">
                                    <span className="f13">
                                        {/* <i className="icofont icofont-clock-time f22 redfont"></i><br />  */}
                                        Total Vins</span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.vins_count != '')?this.state.vins_count:0}/></span></h4>
                                    
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            }
            
        </div>
    <div class="container-fluid card">
            
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        
        <div className={"row col-xl-12 col-lg-12"+" "+this.state.summaryData}>
            <div className="col-xl-6 col-lg-6" style={{paddingRight:"3em"}}>
                <div className="card-header">
                    <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Region Wise Dispatch Summary </span>
                    </h5>
                </div>				   			
                <div className="pt-15px" >

                    <div className="row">
                        {/* <div className="col-xl-12">

                            <span className="layoutbtns float-right">
                                <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                    <i className="icofont icofont-save"></i> Save Grid Layout
                                </button>
                                <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                    <i className="icofont icofont-refresh"></i> Reset Default Layout
                                </button>
                            </span>
                        </div> */}


                        <div id="myGrid" style={{width:"100%",height:"902px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={regionwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.regionwisedata}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                rowClassRules={this.state.rowClassRules}
                                // statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6">
                <div className="card-header">
                    <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Model Wise Dispatch Summary </span>
                    </h5>
                </div>				   			
                <div className="pt-15px" >

                    <div className="row">
                        {/* <div className="col-xl-12">

                            <span className="layoutbtns float-right">
                                <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                    <i className="icofont icofont-save"></i> Save Grid Layout
                                </button>
                                <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                    <i className="icofont icofont-refresh"></i> Reset Default Layout
                                </button>
                            </span>
                        </div> */}


                        <div id="myGrid" style={{width:"100%",height:"902px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={modelwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.modelwisedata}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                // statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                // pinnedBottomRowData={this.state.pinnedBottomRowData}
                                pagination={true}
                                rowClassRules={this.state.modelrowClassRules}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
            <div className="col-xl-6 col-lg-6">
                <div className="card-header">
                    <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Other OEM Sales </span>
                    </h5>
                </div>				   			
                <div className="pt-15px" >

                    <div className="row">
                        {/* <div className="col-xl-12">

                            <span className="layoutbtns float-right">
                                <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                    <i className="icofont icofont-save"></i> Save Grid Layout
                                </button>
                                <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                    <i className="icofont icofont-refresh"></i> Reset Default Layout
                                </button>
                            </span>
                        </div> */}


                        <div id="myGrid" style={{width:"100%",height:"400px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={modelwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.modelwisesbvhdata}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                // statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pinnedBottomRowData={this.state.pinnedSbvhRowData}
                                pagination={true}
                                rowClassRules={this.state.modelrowClassRules}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>

        
        <div className={"row"+" "+this.state.completeData}>
            
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5 style={{float: "left", "width": "85%"}}>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Dispatched Data</span>
                    </h5>
                    <span>{(this.state.fetched_all_intranist == 1)?"Loaded Completed data":"Loading...."}</span>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.completeRowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
                </div>
            </div>
        </div>
        <div className={"row"+" "+this.state.intransitData}>
            
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5 style={{float: "left", "width": "85%"}}>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>In Transit Data</span>
                    </h5>
                    <span>
                        {(this.state.fetched_all_intranist == 1)?<span>Loaded Completed data. <i className='fa fa-check-circle'></i></span>:
                            <span>
                                    <div className="loader-box dataLoadpageimg" style={{top: "-4.25em", left: "95.5%"}}>
                                        <div className="loader-box">
                                            <div className="rotate-dispatch-loader dashed colored"></div>
                                        </div>
                                    </div>
                                Loaded {this.state.fetched_recs_count} of {this.state.total_recs_count}
                            </span>}
                    </span>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.intransitRowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onInTransitGridReady}
                        onGridState={this.onInTransitGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
                </div>
            </div>
        </div>
        <div className={"row"+" "+this.state.returnsData}>
            
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Returns Data</span>
                    </h5>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={returnColumns}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.returns_data}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
                </div>
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
    </div>
    </div>
    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// $('.datetimepicker_mask').datetimepicker({
	// 	mask:'39-19-9999 29:59',
	// 	format:'m-Y'
	// });
	// $('.datetimepicker_date').datetimepicker({
	// 	mask:'39-19-9999',
    //     format:'m-Y',
    //     validateOnBlur: false,
    //     timepicker:false,
    //     monthpicker:true,
	// });
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	// //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	// script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)

    $("#dispatch_dashboard_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("dispatch_dashboard_date");
        }
    });
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};