import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./editmllconsignment";
import CountUp from 'react-countup';

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLAllConsignmentsComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                editMLLConsignment:EditMLLConsignment
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
			sourceopt:'',
			fnr_no:"",
			gpsdevice:"",
			sources:[],
			totalvinsarr:[],
			totalvins:0,
			atplantarr:[],
			atplant:0,
			intransitloadarr:[],
			intransitload:0,
			atloadarr:[],
			atload:0,
			rakeassignarr:[],
			rakeassign:0,
			intransitdestarr:[],
			intransitdest:0,
			atdestinationarr:[],
			atdestination:0,
			intransitdealerarr:[],
			intransitdealer:0,
			intransitrsoarr:[],
			intransitrso:0,
			atrsoarr:[],
			atrso:0,
			neardealerarr:[],
			neardealer:0,
			podreceiptarr:[],
            podreceipt:0,
            lastmiledestination:"",
			desttype:"",
			destypeopt:"",
			consignees:[],
			rsos:[],
            destopts:[],
            sliderRso:"",
            sliderForceLastMile:""
			
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onClickEditBlock = this.onClickEditBlock.bind(this);
		this.changeLastMileFileHandler = this.changeLastMileFileHandler.bind(this);
		this.onChangeLastMilDestinationItem = this.onChangeLastMilDestinationItem.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript();
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        // console.log("Match props ", this.props.match.params.refno)
        // if(this.props.match.params.refno != undefined)
        // {
        //     var refno = this.props.match.params.refno
        // }
        // else{
        //     var refno = "";
        // }
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate,
            // refno:refno
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        
			dpt='SNDG';
        
        this.setState({
			dept_code:dpt,
        });

        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadRakePlanningData(parameters)

	}
    
    onLoadRakePlanningData(parameters)
    {
		redirectURL.post("/consignments/mllloaddestpoints")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				sources:records
			});
		})
      
        
	   redirectURL.post("/consignments/mllsndconsignees")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				consignees:records
			});
		})
       
	   redirectURL.post("/consignments/mllrsos")
	   .then((response) => {
		   var records = response.data.records;
		   this.setState({
			   rsos:records
		   });
	   })
		redirectURL.post("/consignments/mllallconsignments",parameters)
		.then((response) => {
            var records = response.data.records;
            var totalvinsarr=[];
			var totalvins=0;
			var atplantarr=[];
			var atplant=0;
			var intransitloadarr=[];
			var intransitload=0;
			var atloadarr=[];
			var atload=0;
			var rakeassignarr=[];
			var rakeassign=0;
			var intransitdestarr=[];
			var intransitdest=0;
			var atdestinationarr=[];
			var atdestination=0;
			var intransitdealerarr=[];
			var intransitdealer=0;
			var intransitrsoarr=[];
			var intransitrso=0;
			var atrsoarr=[];
			var atrso=0;
			var neardealerarr=[];
			var neardealer=0;
			var podreceiptarr=[];
			var podreceipt=0;
			if(records.length > 0)
			{
				records.map((item) => {
					if(parseInt(item.status) == 1)
					{
						atplantarr.push(item)
					}
					if(parseInt(item.status) == 2)
					{
						intransitloadarr.push(item)
					}
					if(parseInt(item.status) == 3)
					{
						atloadarr.push(item)
					}
					if(parseInt(item.status) == 4)
					{
						rakeassignarr.push(item)
					}
					if(parseInt(item.status) == 5)
					{
						intransitdestarr.push(item)
					}
					if(parseInt(item.status) == 6)
					{
						atdestinationarr.push(item)
					}
					if(parseInt(item.status) == 7)
					{
						intransitdealerarr.push(item)
					}
					if(parseInt(item.status) == 8)
					{
						intransitrsoarr.push(item)
					}
					if(parseInt(item.status) == 9)
					{
						atrsoarr.push(item)
					}
					if(parseInt(item.status) == 10)
					{
						neardealerarr.push(item)
					}
					if(parseInt(item.status) == 11)
					{
						podreceiptarr.push(item)
					}
				})
			}
			this.setState({
				rowData:records,
				totalvinsarr:records,
				totalvins:records.length,
				atplantarr:atplantarr,
				atplant:atplantarr.length,
				intransitloadarr:intransitloadarr,
				intransitload:intransitloadarr.length,
				atloadarr:atloadarr,
				atload:atloadarr.length,
				rakeassignarr:rakeassignarr,
				rakeassign:rakeassignarr.length,
				intransitdestarr:intransitdestarr,
				intransitdest:intransitdestarr.length,
				atdestinationarr:atdestinationarr,
				atdestination:atdestinationarr.length,
				intransitdealerarr:intransitdealerarr,
				intransitdealer:intransitdealerarr.length,
				intransitrsoarr:intransitrsoarr,
				intransitrso:intransitrsoarr.length,
				atrsoarr:atrsoarr,
				atrso:atrsoarr.length,
				neardealerarr:neardealerarr,
				neardealer:neardealerarr.length,
				podreceiptarr:podreceiptarr,
				podreceipt:podreceiptarr.length
			});
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='MLL Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
    }
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
			sliderRso:""
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	
    selectdestinations(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
        
    gpsdevices(){
		let items = [];    
		items.push({"value":"GPSDEVICE001","label":"GPSDEVICE001"})
		items.push({"value":"GPSDEVICE002","label":"GPSDEVICE002"})
		
		return items;
	}
    
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationItem(destopt){
		this.setState(
			{ destopt },
			() => console.log(`Option selected:`, this.state.destopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			sliderRso:""
		});
	}
	onClickShowMap = (params) =>{
       
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formRakeSchedule = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
		// console.log("csvcontent ", this.state.csvcontent);
		var fnr_no = this.state.fnr_no;
		if(this.state.gpsdevice != "")
		{
			var gpsdevice = this.state.gpsdevice.value
        }
		else
		{
			var gpsdevice = ""
		}
		var dest_yard_reachedon = $("#dest_yard_reachedon").val()
		var reqparams = {
			refno:this.state.refno,
			csvcontent:this.state.csvcontent,
			source:this.state.sourceopt.value,
			source_name:this.state.sourceopt.label,
			destination:this.state.destopt.value,
			destination_name:this.state.destopt.label,
			fnr_no:fnr_no,
			gpsdevice:gpsdevice,
			dest_yard_reachedon:dest_yard_reachedon,
			userid:localStorage.getItem("userid"),
			username:localStorage.getItem("username"),
			useremail:localStorage.getItem("email")
		}
		
		// console.log("reqparams ", reqparams)
		if(this.state.sourceopt.value != "" && fnr_no != "" && gpsdevice != "" && dest_yard_reachedon != "" && this.state.csvcontent != "" && this.state.csvcontent.length != 0)
		{
			redirectURL.post("/consignments/updaterakeschedulevins", reqparams)
			.then((response) => {
				if(response.data.status == "success" && response.data.notvalid.length == 0)
				{
					$("#dest_yard_reachedon").val("")
					this.setState({
						sliderRakeTranslate:"",
						overly:'show-n',
						sourceopt:"",
						gpsdevice:"",
						csvcontent:[],
						fnr_no:"",
						loadshow:'show-n',
						uploadFile:'',
						file:'',
						show:true,
						basicTitle:"Successfully update items",
						basicType:"success"
					});
					var parameters = {
						startdate:this.state.startDate,
						enddate:this.state.endDate,
						refno:this.state.refno
					}
					this.onLoadRakePlanningData(parameters)
				}
				else
				{
					if( response.data.notvalid.length > 0)
					{
						this.setState({
							show:true,
							basicTitle:"Vins are not maked arrival at loading yard. Vins : "+response.data.notvalid,
							basicType:"danger"
						})
					}
					else
					{
						this.setState({
							show:true,
							basicTitle:"Failed to update items",
							basicType:"danger"
						})
					}
					
				}
			})
			.catch(function(e){
				console.log("Error ", e)
			})
			
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"All * fields are mandatory",
				basicType:"danger"
			})
		}
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
    
    onClickEditBlock(params){
        console.log("params ",params)
	}
	
	
    
    onChangeDeviceItem(gpsdevice){
		this.setState(
			{ gpsdevice },
			() => console.log(`Option selected:`, this.state.gpsdevice)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onClickForceMoveDestinationYard(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceDestination:'slider-translate',
			overly:"show-m"
		});
	}
	formForceMoveDestinationYardHandler = async (event) =>{
		event.preventDefault();

		var dest_yard_reachedon = document.getElementById("dest_yard_reached").value;
		var dest_yard = dest_yard_reachedon.split(" ");
		var spyard = dest_yard[0].split("-");
		var destyardreachedon = spyard[2]+"-"+spyard[1]+"-"+spyard[0]+" "+dest_yard[1]+":00"
		
		var reqparams = {
			dest_yard_reachedon:dest_yard_reachedon,
			refno:this.state.refno,
			userisd:localStorage.getItem('userid'),
			username:localStorage.getItem('username'),
			email:localStorage.getItem('email'),
			csvcontent:this.state.csvcontent
		}

		redirectURL.post("/consignments/mllmovedestinationyardarrival", reqparams)
		.then((response) => {
			// console.log("Respone ", response.data)
			var rec = response.data;
			if(rec.notvalid.length == 0 && rec.notexists.length == 0)
			{
				this.setState({
					show: true, 
					basicType:'success', 
					basicTitle:"Successfully Uploaded.",
					uploadDivWidth:'0%',
					sliderForceDestination:'',
					overly:'show-n',
					uploadFile:'',
					loadshow:'show-n',
					file:''
				});
				var parameters = {
					startdate:this.state.startDate,
					enddate:this.state.endDate,
					refno:this.state.refno
				}
				this.onLoadRakePlanningData(parameters)
			}
			else if(rec.notvalid.length > 0)
			{
				this.setState({
					show: true, 
					basicType:'danger', 
					basicTitle:"Destination yard reached on should be greater than Dispatch date. "+rec.notvalid,
					uploadDivWidth:'0%',
					sliderForceDestination:'',
					overly:'show-n',
					uploadFile:'',
					loadshow:'show-n',
					file:''
				});
			}
			else if(rec.notexists.length > 0)
			{
				this.setState({
					show: true, 
					basicType:'danger', 
					basicTitle:rec.notexists+" are not exists",
					uploadDivWidth:'0%',
					sliderForceDestination:'',
					overly:'show-n',
					uploadFile:'',
					loadshow:'show-n',
					file:''
				});
			}
			else{
				
				
				
			}
			
		})
	
    }
    onClickCounter(data)
	{
		if(data == "all")
		{
			this.setState({
				rowData:this.state.totalvinsarr
			})
		}
		if(data == "atplant")
		{
			this.setState({
				rowData:this.state.atplantarr
			})
		}
		if(data == "intransitload")
		{
			this.setState({
				rowData:this.state.intransitloadarr
			})
		}
		if(data == "atload")
		{
			this.setState({
				rowData:this.state.atloadarr
			})
		}
		if(data == "rakeassign")
		{
			this.setState({
				rowData:this.state.rakeassignarr
			})
		}
		if(data == "intransitdest")
		{
			this.setState({
				rowData:this.state.intransitdestarr
			})
		}
		if(data == "atdestination")
		{
			this.setState({
				rowData:this.state.atdestinationarr
			})
		}
		if(data == "intransitrso")
		{
			this.setState({
				rowData:this.state.intransitrsoarr
			})
		}
		if(data == "atrso")
		{
			this.setState({
				rowData:this.state.atrsoarr
			})
		}
		if(data == "intransitdealer")
		{
			this.setState({
				rowData:this.state.intransitdealerarr
			})
		}
		if(data == "neardealer")
		{
			this.setState({
				rowData:this.state.neardealerarr
			})
		}
		if(data == "podreceipt")
		{
			this.setState({
				rowData:this.state.podreceiptarr
			})
		}
    }
    
    
	onClickLastMileTransport(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceLastMile:'slider-translate',
			overly:"show-m"
		});
	}
	
	changeLastMileFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	onChangeLastMilDestinationItem(lastmiledestination){
		this.setState(
			{ lastmiledestination },
			async () => {
				var items = []
				// console.log("lastmiledestination.value ", lastmiledestination.value)
				if(lastmiledestination.value != "")
				{
					var desttype = "";
					if(this.state.lastmiledestination.value == "RSO")
					{
						var rso = this.state.rsos;
						if(rso.length > 0)
						{
							rso.map((item) => {
								items.push({
									value:item.rso,
									label:item.rso
								});
							})
						}
						desttype = "Select RSO";
					}
					if(lastmiledestination.value == "Dealer")
					{
						var consignees = this.state.consignees;
						console.log("consignees", consignees)
						if(consignees.length > 0)
						{
							consignees.map((item) => {
								items.push({
									value:item.consignee_code,
									label:item.consignee_name
								});
							})
						}
						
						console.log("consignees items ", items)
						desttype = "Select Dealer";
					}
					
				}
				// console.log("items ", items)
				await this.setState({
					destopts:items,
					desttype:desttype
				})
			}
          );
		  
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			//   destopts:items
		  })
	}

	onChangeDestType(destypeopt){
		this.setState(
			{ destypeopt },
			() => console.log(`Option selected:`, this.state.destypeopt)
		  );
		  
		 
	}
	desttype(){
		var items = []
		if(this.state.destypeopt != "")
		{
			if(this.state.destypeopt.value == "RSO")
			{
				var rso = this.state.rsos;
				if(rso.length > 0)
				{
					rso.map((item) => {
						item.push({
							value:item.rso,
							label:item.rso
						});
					})
				}
			}
			if(this.state.destypeopt.value == "Dealer")
			{
				var consignees = this.state.consignees;
				if(consignees.length > 0)
				{
					consignees.map((item) => {
						item.push({
							value:item.consignee_code,
							label:item.consignee_name
						});
					})
				}
			}
		}
		return items;
	}

	formLastMileData(event)
	{
		event.preventDefault();
		var destypeopt = this.state.destypeopt.value;
		var destypeoptname = this.state.destypeopt.label;
		var lastmiledestination = this.state.lastmiledestination.value;
		var csvcontent = this.state.csvcontent;
		var parameters = {
			destypeopt:destypeopt,
			destypeoptname:destypeoptname,
			lastmiledestination:lastmiledestination,
			csvcontent:csvcontent,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		console.log("parameters ", parameters)

		redirectURL.post("/consignments/verifymlllastmile",{csvcontent:this.state.csvcontent})
		.then((response) => {
			console.log("resposnedata ", response.data)
			if(response.data.records.length == 0)
			{
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins destination not exists",
					basicType:"danger"
				});
			}
			else
			{
				
				redirectURL.post("/consignments/mlllasmileupdate", parameters)
				.then((response) => {
					// console.log("Respone ", response.data)
					var rec = response.data;
					if(rec.notvalid.length == 0)
					{
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully updated.",
							uploadDivWidth:'0%',
							sliderForceLastMile:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:'',
							csvcontent:""
						});
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:this.state.refno
						}
						this.onLoadRakePlanningData(parameters)
					}
					else if(rec.notvalid.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:"Destination yard not exists. ",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else if(rec.notexists.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:rec.notexists+" are not exists",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else{
						
						
						
					}
					
				})
			}
		})
	

	}
    
    
	onClickRSOData(){
		this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
	}

	formRSOData(event){
		event.preventDefault();
		var rsoreachedon = $("#rso_reached").val();
		var csvcontent = this.state.csvcontent;
		var parameters = {
			rsoreachedon:rsoreachedon,
			csvcontent:csvcontent,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		// console.log("parameters ", parameters)

		redirectURL.post("/consignments/verifymllrso",{csvcontent:this.state.csvcontent})
		.then((response) => {
			// console.log("resposnedata ", response.data)
			if(response.data.records.length == 0)
			{
				
				$("#uploadFile").val("")
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins in transit RSO not exists",
					basicType:"danger"
				});
			}
			else
			{				
				redirectURL.post("/consignments/mllrsoupdate", parameters)
				.then((response) => {
					// console.log("Respone ", response.data)
					var rec = response.data;
					$("#uploadFile").val("")
					if(rec.notvalid.length == 0)
					{
						$("#rso_reached").val("");
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully updated.",
							uploadDivWidth:'0%',
							sliderRso:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:'',
							csvcontent:""
						});
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:this.state.refno
						}
						this.onLoadRakePlanningData(parameters)
					}
					else if(rec.notvalid.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:"In Transit RSO not exists. ",
							uploadDivWidth:'0%',
							sliderRso:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					
					else{
						
						
						
					}
					
				})
			}
		})
	}
	
    render(){
        var columnwithDefs = [
			// {
			// 	headerName:"",
			// 	field:"",
			// 	width:100,
			// 	resizable:true,
			// 	cellRendererSelector:function(params){
            //         return {component:"editMLLConsignment"}
            //     }
			// },
			{
				headerName:"Batch No",
				field:"batchno",
				width:150,
				resizable:true
			},
			{
				headerName:"Vehicle ID",
				field:"vehicle_id",
				width:150,
				resizable:true
			},
			{
				headerName:"Master Ref No",
				field:"master_ref_no",
				width:150,
				resizable:true
			},
			{
				headerName:"Status",
				field:"status",
				width:150,
				resizable:true,
				valueGetter:function(params){
                    // console.log("Parms ", params.data)
					try{
						var status = "At Plant"
						if(parseInt(params.data.status) == 1)
						{
							var status = "At Plant";
						}
						if(parseInt(params.data.status) == 2)
						{
							var status = "In Transit To Loading Yard";
						}
						if(parseInt(params.data.status) == 3)
						{
							var status = "At Loading Yard";
						}
						if(parseInt(params.data.status) == 4)
						{
							var status = "Rake Assigned";
						}
						if(parseInt(params.data.status) == 5)
						{
							var status = "In Transit To Destination Yard";
						}
						if(parseInt(params.data.status) == 6)
						{
							var status = "At Destination Yard";
						}
						if(parseInt(params.data.status) == 7)
						{
							var status = "In Transit To RSO";
						}
						if(parseInt(params.data.status) == 8)
						{
							var status = "At RSO";
						}
						if(parseInt(params.data.status) == 9)
						{
							var status = "In Transit To Dealer";
						}
						if(parseInt(params.data.status) == 10)
						{
							var status = "Near Dealer";
						}
						if(parseInt(params.data.status) == 11)
						{
							var status = "POD Receipt";
                        }
                        return status;
					}
					catch(e){
                        return "";
					}
				}
			},
			{
				headerName:"Source",
				field:"source",
				width:150,
				resizable:true
			},
			{
				headerName:"Bill Doc",
				field:"bill_doc",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Bill",
				field:"bill_doc",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Group Code",
				field:"group_code",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Subgroup",
				field:"subgroup",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Matrial No",
				field:"material_no",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Material Description",
				field:"material_description",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Plant Code",
				field:"plant_code",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Storage Location",
				field:"storage_location",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Busniess Place",
				field:"business_place",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Item (SD)",
				field:"item_sd",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Bill To Party",
				field:"bill_to_party",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Bill To Party Name",
				field:"bill_to_party_name",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Address1",
				field:"address1",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Address2",
				field:"address2",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Address3",
				field:"address3",
				width:150,
				resizable:true
			},
            
			{
				headerName:"City",
				field:"city",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Pincode",
				field:"pincode",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Contact Person",
				field:"contact_person",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Contact Number",
				field:"contact_number",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Ship To Party",
				field:"ship_to_party",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Ref Doc",
				field:"ref_doc",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Veh SR No",
				field:"vehicle_no",
				width:150,
				resizable:true
			},
            
			{
				headerName:"System Status",
				field:"system_status",
				width:150,
				resizable:true
			},
            
			{
				headerName:"User Status",
				field:"user_status",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Colour",
				field:"colour",
				width:150,
				resizable:true
			},
            
			{
				headerName:"TC",
				field:"tc",
				width:150,
				resizable:true
			},
            
			{
				headerName:"TRANS DESP",
				field:"trans_desp",
				width:150,
				resizable:true
			},
            
            
			{
				headerName:"Engine No",
				field:"enigne_no",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Qty",
				field:"qty",
				width:150,
				resizable:true
			},
            
			{
				headerName:"STA Date",
				field:"sta_date",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Due Date",
				field:"duedate",
				width:150,
				resizable:true
			},
            
			{
				headerName:"BLG Time",
				field:"blg_time",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Lead Date",
				field:"lead_date",
				width:150,
				resizable:true
			},
            
			{
				headerName:"GRDate",
				field:"grdate",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Bill Date",
				field:"bill_date",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Trans Mode",
				field:"trans_mode",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Parking Time",
				field:"parking_time",
				width:150,
				resizable:true
			},
            
			{
				headerName:"INV Value",
				field:"invoice_value",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Stock Type (Primary Posting)",
				field:"stock_type",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Indentor",
				field:"indentor",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Temp Reg. No",
				field:"temp_reg_no",
				width:150,
				resizable:true
			},
            
			{
				headerName:"Const MTH",
				field:"const_mth",
				width:150,
				resizable:true
			},
			{
				headerName:"Const YR",
				field:"const_yr",
				width:150,
				resizable:true
			},
			{
				headerName:"GST INV No",
				field:"gst_invoice_no",
				width:150,
				resizable:true
			},
			{
				headerName:"CGST",
				field:"cgst",
				width:150,
				resizable:true
			},
			{
				headerName:"IGST",
				field:"igst",
				width:150,
				resizable:true
			},
			{
				headerName:"SGST_UGST",
				field:"sgst_ugst",
				width:150,
				resizable:true
			},
			{
				headerName:"Comp CESS",
				field:"comp_cess",
				width:150,
				resizable:true
			},
			{
				headerName:"Dist CHNL",
				field:"dist_chnl",
				width:150,
				resizable:true
			},
			{
				headerName:"Uploaded By",
				field:"uploaded_by",
				width:150,
				resizable:true
			},
			{
				headerName:"Uploaded On",
				field:"uploaded_on",
				width:150,
				resizable:true,
				valueGetter:function(params){
					try{
						return getDDMMMYYYYHHMMDefault(params.data.uploaded_on)
					}
					catch(e){
						return "";
					}
				}
			},
			{
				headerName: "<i class='icofont icofont-train-line'></i> Loading Yard to Destination Yard",
				headerClass:["cellstylegridB","cellTopHeader"],	
				children:[
					{
						headerName:"Loading Yard",
						field:"rake_loading_yard_name",
						width:160,
						resizable:true
					},
					{
						headerName:"FNR No",
						field:"fnr_no",
						width:120,
						resizable:true
					},
					{
						headerName:"GPS Device Id",
						field:"gps_device_id",
						width:120,
						resizable:true
					},
					{
						headerName:"Destination Yard",
						field:"rake_destination_point_name",
						width:120,
						resizable:true
					},
					{
						headerName:"Load Dispatch Date/Time",
						field:"expected_rake_dispatch_date",
						width:160,
						resizable:true,
						valueGetter:function(params){
							try{
								if(params.data.expected_rake_dispatch_date != "" && params.data.expected_rake_dispatch_date != undefined)
								{
									return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
								}
								else
								{
									return "";
								}
							}
							catch(e){
								return "";
							}
						}
					},
				
					{
						headerName:"Destination Yard Reached On ",
						field:"rake_delivery_point_reached_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
							} 
							else{
								return '';
							}
						}
						
					},
				]
            },
            {
				headerName: "<i class='icofont icofont-train-line'></i> Destination Yard to Dealer",
				headerClass:["cellstylegridB","cellTopHeader"],	
				children:[	
					{
						headerName:"RSO Arrival On ",
						field:"rso_reached_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.rso_reached_on != '' && typeof params.data.rso_reached_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.rso_reached_on);
							} 
							else{
								return '';
							}
						}
						
					},				
					{
						headerName:"Dealer Code",
						field:"last_mile_destination_dealer_code",
						width:120,
						resizable:true
					},
					{
						headerName:"Dealer",
						field:"last_mile_destination_dealer_name",
						width:160,
						resizable:true
					},
					{
						headerName:"Last Mile Updated On ",
						field:"last_mile_updated_on",
						width:140,
						filter:"agSetColumnFilter",
						resizable: true, 
						valueGetter:function(params){
							if(params.data.last_mile_updated_on != '' && typeof params.data.last_mile_updated_on != 'undefined')
							{
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_updated_on);
							} 
							else{
								return '';
							}
						}
						
					},
				]
			}
			
		]
        const { calview } = this.state;
        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
                <div className="row">

					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
										<div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
											<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Vins<br /><br /><br /></span>
											<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalvins != '')?this.state.totalvins:0}/></span></h4>
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atplant")}>
											<span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br />At Plant<br /><br /><br /></span>
											<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.atplant != '')?this.state.atplant:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitload")}>
											<span className="f13"><i className="icofont icofont-clock-time f22 txt-secondary"></i><br /> In Transit To Loading Yard</span>
											<h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.intransitload != '')?this.state.intransitload:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atload")}>
											<span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br /> At Loading Yard<br /><br /></span>
											<h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.atload != '')?this.state.atload:0}/></span></h4>
											
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakeassign")}>
											<span className="f13"><i className="icofont icofont-toy-train f22 txt-success"></i><br /> Rake Assigned<br /><br /> </span>
											<h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.rakeassign != '')?this.state.rakeassign:0}/></span></h4>
										
										</div>
										<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdest")}>
											<span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> In Transit To Destination Yard</span>
											<h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.intransitdest != '')?this.state.intransitdest:0}/></span></h4>
											
										</div>
									
										
									{/* </div>
								</div>
							
							</div>
						</div>
					</div>

                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
						<div className="card">
							<div className="card-body" id="counterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row"> */}

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atdestination")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 darkorange"></i><br /> At Destination Yard</span>
                                            <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.atdestination != '')?this.state.atdestination:0}/></span></h4>
                                        </div>


                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitrso")} >
                                            <span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> In Transit to RSO<br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.intransitrso != '')?this.state.intransitrso:0}/></span></h4>
                                        </div>
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atrso")}>
                                            <span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> At RSO<br /><br /><br /></span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.atrso != '')?this.state.atrso:0}/></span></h4>
                                            
                                        </div>
                                    
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransitdealer")}>
                                            <span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> In Transit To Dealer<br /><br /></span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.intransitdealer != '')?this.state.intransitdealer:0}/></span></h4>
                                            
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"neardealer")} >
                                            <span className="f13"><i className="icofont icofont-truck-alt f24 darkorange"></i><br /> Near Dealer <br /><br /><br /></span>
                                            <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.neardealer != '')?this.state.neardealer:0}/></span></h4>
                                        </div>


                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"podreceipt")} >
                                            <span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> POD Receipt<br /><br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.podreceipt != '')?this.state.podreceipt:0}/></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					
				</div>
            	<div className="row">
                    {/* <div className="row col-xl-12 col-lg-12">
                       
                        <div className="col-xl-6 col-lg-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                    
                                </div>

                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" className="btn btn-success" onClick={this.onClickFilter.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div> */}
                    <div className={"col-xl-12 col-lg-12 "}>
                        {/* <RakePlanCounter
                        // invoiced={this.state.invoicedcnt}
                        // exposedinvoicedcnt={this.state.exposedinvoicedcnt}
                        // planned={this.state.plannedcnt}
                        // revisedCounter={this.state.revisedCounter}
                        // tobeapprovedcounter={this.state.to_be_approved_list.length}
                        // onClickCounterShowData = {this.onClickCounterShowData}
                        /> */}
                    </div>
            		<div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>MLL Consignments  </span>
				       				<button className="float-right f12 custom-btn btn-success white" onClick={this.onClickLastMileTransport.bind(this)} style={{marginRight:"5px"}}>
										Last Mile Tranport
									</button>
                                    <button className="float-right f12 custom-btn btn-warning " onClick={this.onClickRSOData.bind(this)} style={{marginRight:"5px"}}>
										RSO Arrival
									</button>
									<a href="/mlltrainunloadchecksheet" target="_blank" className="float-right f12 custom-btn btn-primary white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{marginRight:"5px"}}>
										Train Unloading Check Sheet
									</a>
									<button className="float-right f12 custom-btn btn-success white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{marginRight:"5px"}}>
										Destination Yard Arrival
									</button>
                                   <a href="/mlltrainloadchecksheet" target="_blank" className="float-right f12 custom-btn btn-info white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{marginRight:"5px"}}>
										Train Loading Check Sheet
									</a>	
									<a href="/mllwagonchecksheet" target="_blank" className="float-right f12 custom-btn btn-danger white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{marginRight:"5px"}}>
										Wagon Check Sheet
									</a>		
                                   	 <button className="float-right f12 custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{marginRight:"5px"}}>
                                        Add Rake Schedule
                                    </button>
									
									 {/* <button className={"float-right custom-btn white btn-danger f14 "+(this.state.gridview)} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className={"float-right custom-btn white btn-info f14 "+(this.state.gridview)} style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>				 */}
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "+(this.state.gridview)}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        />

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeSchedule.bind(this)}>
                                
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Source *</label>
                                
                                <Select 
                                placeholder={"Select Source"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">FNR No *</label>
								<input 
									type="text" 
									name="fnr_no"
									value={this.state.fnr_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdevice}
                                options={this.gpsdevices()} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Dispatch Date and Time *</label>
							    <input type="text" placeholder="Dispatch Date and Time" id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination *</label>
                                
                                <Select 
                                placeholder={"Select Destination"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destopt}
                                options={this.selectdestinations()} />  
                            </div>
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_mll_rake_schedule_vins_new.csv')} target="_blank">Sample Template</a>
							
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<div className={"slide-r "+(this.state.sliderForceDestination)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Move To Destination Yard
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveDestinationYardHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
							    <input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reached" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderForceLastMile)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Last Mile Transport Batch
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formLastMileData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination *</label>
                                
                                <Select 
                                placeholder={"Select Destination"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeLastMilDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.lastmiledestination}
                                options={[
									{"value":"RSO", "label":"RSO"},
									{"value":"Dealer", "label":"Dealer"}
								]} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">{this.state.desttype} *</label>
                                
                                <Select 
                                placeholder={this.state.desttype}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destypeopt}
                                options={this.state.destopts} />  
                            </div>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>



				<div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update RSO Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRSOData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">RSO Reached On</label>
							    <input type="text" placeholder="RSO Reached On " id="rso_reached" name="rso_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}