import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLGPSDevicePlanning extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
            rowData: [],
            frameworkComponents: {
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
            rowSelection:'multiple',
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Summary Dashboard",
            sliderRouteTranslate:'',
            csvcontent:[],
            masterrefnos:[],
            refno:"",
            showgrid:"show-n",
            htitle:"",
            sidebarMarkDealerTranslate:"",
            sidebarMarkRsoDealerTranslate:"",
            allvins:[],
            markRowData:[],
            markRsoRowData:[],
            pinnedRegionBottomRowData: [],
            sidebarDownloadTranslate:"",
            downloadRowData:[],
            markdestgrid:"show-m",
            markdestbulk:"show-n",
            markrsogrid:"show-m",
            markrsobulk:"show-n",
            isdestfile:0,
            isrsofile:0
			
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
	}
	
    componentDidMount(){
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        if(this.props.match.path.indexOf("viewdeviceplanning") >= 0)
        {
            this.setState({
                refno:this.props.match.params.refno,
                htitle : " - "+this.props.match.params.refno
            })
        }
        this.setState({
            gridview:'show-m',
            calcview:'show-n',
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        
			dpt='SNDG';
        
        this.setState({
			dept_code:dpt,
        });

        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        var params = {
            refno : this.props.match.params.refno
        }

        var master_ref_no = this.props.match.params.refno;
        var parametrs = {
            master_ref_no: master_ref_no
        }
        redirectURL.post("/consignments/mllgeneratedeviceplan",parametrs)
        .then((response) => {
            console.log("response ", response)
            // window.location.reload();
        })
        
        redirectURL.post("/mllconsignments/mllconsignmentmarkplan", params)
		.then((response) => {
            var records = response.data.records;
            console.log("records ",records)
            this.setState({
                allvins:records,
                markRowData:records,
                // markRsoRowData:records
            })
        });

        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadRakePlanningData(parameters)

	}
    
    onLoadRakePlanningData(parameters)
    {
        if(this.props.match.path.indexOf("viewdeviceplanning") >= 0)
        {
            var parameters = {
                master_ref_no : this.props.match.params.refno
            }
        }
        else
        {
            var parameters = {}
        }
		redirectURL.post("/consignments/mllgpsdeviceplanning", parameters)
		.then((response) => {
            var records = response.data.records;
            // console.log("records ",records)
            
            var rows = []
            var firstmiletotal=0;
            var middlemiletotal=0;
            var lastmilersototal=0;
            var lastmiledealertotal=0;
            
            var firstmiledevicetotal=0;
            var middlemiledevicetotal=0;
            var lastmilersodevicetotal=0;
            var lastmiledealerdevicetotal=0;
            var directdealerdevice=0;

            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.device_data.length > 0)
                    {
                        item.device_data.map((itm) => {
                            // console.log("rowsitm ", itm)
                            var row = {}
                            if(itm.first_mile_no_of_cars != "")
                            {
                                firstmiletotal = parseInt(firstmiletotal)+parseInt(itm.first_mile_no_of_cars);
                            }
                            else
                            {
                                firstmiletotal = parseInt(firstmiletotal)+0
                            }
                            if(itm.middle_mile_no_of_cars != "")
                            {
                                middlemiletotal = parseInt(middlemiletotal)+parseInt(itm.middle_mile_no_of_cars);
                            }
                            else
                            {
                                middlemiletotal = parseInt(middlemiletotal)+0;
                            }
                            if(itm.last_mile_rso_no_of_cars != "")
                            {
                                lastmilersototal = parseInt(lastmilersototal)+parseInt(itm.last_mile_rso_no_of_cars);
                            }
                            else
                            {
                                lastmilersototal = parseInt(lastmilersototal)+0;
                            }
                            if(itm.last_mile_dealer_no_of_cars != "")
                            {
                                lastmiledealertotal = parseInt(lastmiledealertotal)+parseInt(itm.last_mile_dealer_no_of_cars);
                            }
                            else
                            {
                                lastmiledealertotal = parseInt(lastmiledealertotal)+0;
                            }


                            if(itm.first_mile_device_name != "")
                            {
                                if(itm.first_mile_device_name.includes("device") == true)
                                {
                                    firstmiledevicetotal=parseInt(firstmiledevicetotal)+1
                                }
                                else
                                {
                                    firstmiledevicetotal=parseInt(firstmiledevicetotal)+0
                                }
                            }
                            else
                            {
                                firstmiledevicetotal=parseInt(firstmiledevicetotal)+0
                            }
                            
                            if(itm.middle_mile_device_name != "")
                            {
                                if(itm.middle_mile_device_name.includes("device") == true)
                                {
                                    middlemiledevicetotal=parseInt(middlemiledevicetotal)+1
                                }
                                else
                                {
                                    middlemiledevicetotal=parseInt(middlemiledevicetotal)+0
                                }
                            }
                            else
                            {
                                middlemiledevicetotal=parseInt(middlemiledevicetotal)+0
                            }
                            
                            if(itm.last_mile_rso_device_name != "")
                            {
                                if(itm.last_mile_rso_device_name.includes("device") == true)
                                {
                                    lastmilersodevicetotal=parseInt(lastmilersodevicetotal)+1
                                }
                                else
                                {
                                    lastmilersodevicetotal=parseInt(lastmilersodevicetotal)+0
                                }
                            }
                            else
                            {
                                lastmilersodevicetotal=parseInt(lastmilersodevicetotal)+0
                            }
                            
                            if(itm.last_mile_dealer_device_name != "")
                            {
                                if(itm.last_mile_dealer_device_name.includes("device") == true)
                                {
                                    lastmiledealerdevicetotal=parseInt(lastmiledealerdevicetotal)+1
                                }
                                else
                                {
                                    lastmiledealerdevicetotal=parseInt(lastmiledealerdevicetotal)+0
                                }
                            }
                            else
                            {
                                lastmiledealerdevicetotal=parseInt(lastmiledealerdevicetotal)+0
                            }

                            
                            if(itm.directly_to_dealer_dealer_device_name != "")
                            {
                                if(itm.directly_to_dealer_dealer_device_name.includes("device") == true)
                                {
                                    directdealerdevice=parseInt(directdealerdevice)+1
                                }
                                else
                                {
                                    directdealerdevice=parseInt(directdealerdevice)+0
                                }
                            }
                            else
                            {
                                directdealerdevice=parseInt(directdealerdevice)+0
                            }
                            row.master_ref_no = item.master_ref_no;
                            row.first_mile_device_name = itm.first_mile_device_name;
                            row.first_mile_no_of_cars = itm.first_mile_no_of_cars;                             
                            row.first_mile_mode = itm.first_mile_mode; 
                            // row.mode = itm.mode; 
                            row.middle_mile_device_name = itm.middle_mile_device_name; 
                            row.middle_mile_no_of_cars = itm.middle_mile_no_of_cars; 
                            row.middle_mile_mode = itm.middle_mile_mode;  
                            // row.last_mile_no_of_cars = itm.last_mile_no_of_cars; 
                            // row.last_mile_mode = itm.last_mile_mode; 
                            row.last_mile_rso_device_name = itm.last_mile_rso_device_name;
                            row.last_mile_rso_no_of_cars = itm.last_mile_rso_no_of_cars; 
                            row.directly_to_dealer_dealer_device_name = itm.directly_to_dealer_dealer_device_name; 
                            row.directly_to_dealer_dealer_no_of_cars = itm.directly_to_dealer_dealer_no_of_cars; 
                            row.directly_to_dealer_dealer_mode = itm.directly_to_dealer_dealer_mode; 
                            row.directly_to_dealer_dealer_bill_to_party = itm.directly_to_dealer_dealer_bill_to_party; 
                            row.directly_to_dealer_dealer_bill_to_party_name = itm.directly_to_dealer_dealer_bill_to_party_name; 
                            row.directly_to_dealer_dealer_city = itm.directly_to_dealer_dealer_city; 
                            row.last_mile_rso_mode = itm.last_mile_rso_mode; 
                            row.last_mile_dealer_device_name = itm.last_mile_dealer_device_name; 
                            row.last_mile_dealer_no_of_cars = itm.last_mile_dealer_no_of_cars; 
                            row.last_mile_dealer_mode = itm.last_mile_dealer_mode; 
                            row.last_mile_dealer_bill_to_party = itm.last_mile_dealer_bill_to_party;
                            row.last_mile_dealer_bill_to_party_name = itm.last_mile_dealer_bill_to_party_name; 
                            row.last_mile_dealer_city = itm.last_mile_dealer_city;
                            rows.push(row); 
                        })
                    }
                })
            }
            var total = {
                master_ref_no : "Total",
                first_mile_device_name : firstmiledevicetotal,
                first_mile_no_of_cars : "",                             
                first_mile_mode : "",
                middle_mile_device_name : middlemiledevicetotal, 
                middle_mile_no_of_cars : "", 
                middle_mile_mode : "",
                last_mile_rso_device_name : lastmilersodevicetotal, 
                last_mile_rso_no_of_cars : "", 
                last_mile_rso_mode : "",
                directly_to_dealer_dealer_device_name : directdealerdevice,
                directly_to_dealer_dealer_no_of_cars : "",
                directly_to_dealer_dealer_mode : "",
                directly_to_dealer_dealer_bill_to_party : "",
                directly_to_dealer_dealer_bill_to_party_name : "",
                directly_to_dealer_dealer_city : "",
                last_mile_dealer_device_name : lastmiledealerdevicetotal, 
                last_mile_dealer_no_of_cars : "", 
                last_mile_dealer_mode : "", 
                last_mile_dealer_bill_to_party : "", 
                last_mile_dealer_bill_to_party_name:"",
                last_mile_dealer_city : ""
            }
            // rows.push(total);
            // console.log("rowsrows ", rows)
            var pinnedRegionBottomRowData = {}
            pinnedRegionBottomRowData["master_ref_no"] = "Total";
            pinnedRegionBottomRowData["first_mile_device_name"] = firstmiledevicetotal;
            pinnedRegionBottomRowData["first_mile_no_of_cars"] = "";
            pinnedRegionBottomRowData["first_mile_mode"] = "";
            pinnedRegionBottomRowData["middle_mile_device_name"] =middlemiledevicetotal;
            pinnedRegionBottomRowData["middle_mile_no_of_cars"] = "";
            pinnedRegionBottomRowData["middle_mile_mode"] = "";
            pinnedRegionBottomRowData["last_mile_rso_device_name"] = lastmilersodevicetotal;
            pinnedRegionBottomRowData["last_mile_rso_no_of_cars"] = "";
            pinnedRegionBottomRowData["last_mile_rso_mode"] = "";
            pinnedRegionBottomRowData["directly_to_dealer_dealer_device_name"] = directdealerdevice;
            pinnedRegionBottomRowData["directly_to_dealer_dealer_no_of_cars"] = "";
            pinnedRegionBottomRowData["directly_to_dealer_dealer_mode"] = "";
            pinnedRegionBottomRowData["directly_to_dealer_dealer_bill_to_party"] = "";
            pinnedRegionBottomRowData["directly_to_dealer_dealer_bill_to_party_name"] = "";
            pinnedRegionBottomRowData["directly_to_dealer_dealer_city"] = "";
            pinnedRegionBottomRowData["last_mile_dealer_device_name"] = lastmiledealerdevicetotal;
            pinnedRegionBottomRowData["last_mile_dealer_no_of_cars"] = "";
            pinnedRegionBottomRowData["last_mile_dealer_mode"] = "";
            pinnedRegionBottomRowData["last_mile_dealer_bill_to_party"] = "";
            pinnedRegionBottomRowData["last_mile_dealer_bill_to_party_name"] = "";
            pinnedRegionBottomRowData["last_mile_dealer_city"] = "";
            this.setState({
                rowData:rows,
                showgrid:"show-m",
                pinnedRegionBottomRowData:[pinnedRegionBottomRowData]
            })            
        })
		redirectURL.post("/consignments/mllsummary")
		.then((response) => {
			var records = response.data.records;
            this.setState({
				masterrefnos:records,
                loadshow:"show-n",
                overly:"show-n"
			});
        })
        
    }
    

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='Summary Dashboard';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			try{
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
				
			}
			catch(e){

			}
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
    }
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",
            sidebarMarkDealerTranslate:"",
            sidebarMarkRsoDealerTranslate:"",
            sidebarDownloadTranslate:""
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		try{
			if(this.state.masterrefnos.length > 0)
			{
				this.state.masterrefnos.map((item) =>{
					items.push({"value":item.master_ref_no,"label":item.master_ref_no})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
    
    
    onChangeSourceItem(refno){
		this.setState(
			{ refno },
			() => console.log(`Option selected:`, this.state.refno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
     }
     
     
    onGridReady1 = params => {
	    this.gridApi1 = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi1 = params.columnApi;
	
	
	};
	 onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi1.getFilterModel();
           this.gridApi1.setFilterModel(window.filterState);
	 }

     
    onGridReady2 = params => {
	    this.gridApi2 = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi2 = params.columnApi;
	
	
	};
	 onGridState2 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi2.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi2.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi2.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi2.getFilterModel();
           this.gridApi2.setFilterModel(window.filterState);
	 }


     onGridReady4 = params => {
	    this.gridApi4 = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi4 = params.columnApi;
	
	
	};
	 onGridState4 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi4.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi4.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi4.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi4.getFilterModel();
           this.gridApi4.setFilterModel(window.filterState);
	 }


    onOpenModal = () => {
		this.setState({ open: true });
	};
	
	onCloseModal = () => {
        this.gridApi.deselectAll();
	    this.setState({ open: false });
    };
	
    onClickGetMasterRefData()
    {
        if(this.state.refno != "")
        {
            var master_ref_no = this.state.refno;
            var parameters = {
                master_ref_no: master_ref_no.value
            }
            console.log("parameters ", parameters)
            redirectURL.post("/consignments/mllgeneratedeviceplan",parameters)
            .then((response) => {
                // console.log("response ", response)
                window.location.reload();
            })
        }
        else
        {
            this.setState({
                show1:true,
                basicTitle1:"Please select Master Ref Number",
                basicType1:"danger"
            })
        }
        
    }
    onClickShowMarkDealer()
    {
        
        this.setState({
            markdestgrid:"show-m",
            markdestbulk:"show-n",
            csvcontent:[],
            sidebarMarkDealerTranslate:"slider-translate-75p",
            overly:"show-m",
        })
    }
    onClickShowMarkRsoDealer()
    {
        this.setState({
            markrsogrid:"show-m",
            markrsobulk:"show-n",
            csvcontent:[]
        })
        var params = {
            refno : this.props.match.params.refno
        }
        redirectURL.post("/mllconsignments/mllconsignmentmarkplanrso", params)
		.then((response) => {
            var records = response.data.records;
            var recordsarr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.is_end_destination_rso != "" && item.is_end_destination_rso != undefined)
                    {
                        var is_end_destination_rso = item.is_end_destination_rso
                    }
                    else
                    {
                        var is_end_destination_rso = "No"
                    }
                    var itm = item
                    itm.is_end_destination_rso = is_end_destination_rso
                    recordsarr.push(itm)
                })
            }
            this.setState({
                markRsoRowData:recordsarr,
                sidebarMarkRsoDealerTranslate:"slider-translate-60p",
                overly:"show-m",

            })
        })
    }
    formMarkDestinationYardDealer(){
        // event.preventDefault();
        var rwCount = this.gridApi1.getSelectedNodes();
        // console.log("rwCount ",rwCount)
        var records = []
        if(rwCount.length > 0)
        {
            rwCount.map((item) => {
                records.push(item.data)
            })
        }
        
        // console.log("selectedrows ", records);
        if(records.length > 0)
        {
            var parameters = {
                rownodes : records,
                master_ref_no : this.props.match.params.refno
            }
            redirectURL.post("/mllconsignments/updatemllmarkdesttodelaer",parameters)
            .then((response) => {
                // console.log("response", response.data)
                if(response.data.status == "Success")
                {
                    window.location.reload();
                }
            })
        }
        else
        {
            this.setState({
                show1:true,
                basicType1:"danger",
                basicTitle1:"Please select items"
            })
        }
        
    }
    formMarkRSOToDealer(){
        // event.preventDefault();
        var rwCount = this.gridApi2.getSelectedNodes();
        // console.log("rwCount ",rwCount)
        var records = []
        if(rwCount.length > 0)
        {
            rwCount.map((item) => {
                records.push(item.data)
            })
        }
        if(records.length > 0)
        {
            // console.log("selectedrows ", records);
            var parameters = {
                rownodes : records,
                master_ref_no : this.props.match.params.refno
            }
            redirectURL.post("/mllconsignments/updatemllmarkrsotodelaer",parameters)
            .then((response) => {
                // console.log("response", response.data)
                if(response.data.status == "Success")
                {
                    window.location.reload();
                }
            })
        }
        else
        {
            this.setState({
                show1:true,
                basicType1:"danger",
                basicTitle1:"Please select items"
            })
        }
    }
    
    onClickShowDownloadData()
    {
        var params = {
            refno : this.props.match.params.refno
        }
        redirectURL.post("/mllconsignments/mllconsignmentdownloadros", params)
		.then((response) => {
            var records = response.data.records;
            console.log("last_mile_to_dealer_plan", records)
            if(records.length > 0)
            {
                this.setState({
                    downloadRowData:records[0].last_mile_to_dealer_plan,
                    sidebarDownloadTranslate:"slider-translate-60p",
                    overly:"show-m"
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"No data found",
                    basicType:"danger"
                })
            }
            
        })
    }

    onClickShowMarkDestBulk(){
        this.setState({
            markdestgrid:"show-n",
            markdestbulk:"show-m"
        })
    }
    
    onClickHideMarkDestBulk(){
        this.setState({
            markdestgrid:"show-m",
            markdestbulk:"show-n"
        })
    }
    formMarkDestinationBulk(event){
        event.preventDefault()
        var csvcontent = this.state.csvcontent;
        console.log("csv ", csvcontent)
        if(this.state.isdestfile == 0)
        {
            this.setState({
                show1:true,
                basicTitle1:"Please choose file",
                basicType1:"danger"
            })
        }
        else
        {
            var params = {
                csvcontent:csvcontent,
                master_ref_no:this.props.match.params.refno
            }
            redirectURL.post("/mllconsignments/updateBulkMarkDestination",params)
            .then((response) => {
                // console.log("respone ", response.data)
                var res = response.data;
                if(res.status == "empty")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Uploaded data has empty",
                        basicType1:"danger"
                    })
                }
                else if(res.status == "notvalid")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Invalid Data uploaded",
                        basicType1:"danger"
                    })
                }
                
                else if(res.status == "notexists")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Invalid Vins",
                        basicType1:"danger"
                    })
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully updated data",
                        basicType:"success"
                    })
                }
            })
        }
        
    }
    
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'mode',
					inputName: 'mode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'Destination',
					inputName: 'destination',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		
		
		this.setState({
            file:e.target.files[0],
            isdestfile:1
		});
		
    }
    
    
    onClickShowMarkRsoBulk(){
        this.setState({
            markrsogrid:"show-n",
            markrsobulk:"show-m"
        })
    }
    
    onClickHideMarkRsoBulk(){
        this.setState({
            markrsogrid:"show-m",
            markrsobulk:"show-n"
        })
    }
    formMarkRsoBulk(event){
        event.preventDefault()
        if(this.state.isrsofile == 0)
        {
            this.setState({
                show1:true,
                basicTitle1:"Please choose file",
                basicType1:"danger"
            })
        }
        else
        {
            var csvcontent = this.state.csvcontent;
            console.log("csv ", csvcontent)
            if(csvcontent.length)
            var params = {
                csvcontent:csvcontent,
                master_ref_no:this.props.match.params.refno
            }
            redirectURL.post("/mllconsignments/updateBulkMarkRso",params)
            .then((response) => {
                console.log("respone ", response.data)
                var res = response.data;
                if(res.status == "empty")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Uploaded data has empty",
                        basicType1:"danger",
                    })
                }
                else if(res.status == "notvalid")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Invalid Data uploaded",
                        basicType1:"danger"
                    })
                }
                
                else if(res.status == "notexists")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Invalid Vins",
                        basicType1:"danger"
                    })
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully updated data",
                        basicType:"success"
                    })
                }
            })
        }
    }
    
	changeRsoFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'mode',
					inputName: 'mode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'is_end_destination_rso',
					inputName: 'is_end_destination_rso',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		
		
		this.setState({
            file:e.target.files[0],
            isrsofile:1
		});
		
	}

    render(){
        var columnwithDefs = [
			{
                headerName:"Master Ref No",
                field:"master_ref_no",
                cellClass:function(params){
                    if(params.data.master_ref_no == "Total")
                    {
                        return ["fbold","f15"]
                    }
                }
            },
			{
                headerName:"PLANT TO LOADING YARD",
				headerClass:["cellstylegridG","fbold"],	
                children:[
                    {
                        headerName:"Device",
						headerClass:["cellstylegridG"],
                        field:"first_mile_device_name",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridG"]
                            }
                            else
                            {
                                return ["cellstylegridG"]
                            }
                        }
                    },
                    {
                        headerName:"Mode",
						headerClass:["cellstylegridG"],
                        field:"first_mile_mode",
                        width:80,
						cellClass:["cellstylegridG"]
                    },
                    {
                        headerName:"No Of Cars",
						headerClass:["cellstylegridG"],
                        field:"first_mile_no_of_cars",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridG"]
                            }
                            else{
                                return ["cellstylegridG"]
                            }
                        }
                    }
                ]
            },
            
			{
                headerName:"LOADING YARD TO DESTINATION YARD",
                headerClass:["cellstylegridSk","fbold"],
                children:[
                    {
                        headerName:"Device",
						headerClass:["cellstylegridSk"],
                        field:"middle_mile_device_name",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridSk"]
                            }
                            else
                            {
                                return ["cellstylegridSk"]
                            }
                        }
                    },
                    {
                        headerName:"Mode",
						headerClass:["cellstylegridSk"],
                        field:"middle_mile_mode",
                        width:80,
						cellClass:["cellstylegridSk"]
                    },
                    {
                        headerName:"No Of Cars",
						headerClass:["cellstylegridSk"],
                        field:"middle_mile_no_of_cars",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridSk"]
                            }
                            else
                            {
                                return ["cellstylegridSk"]
                            }
                        }
                    }
                ]
			},
            
			{
                headerName:"DESTINATION TO RSO",
				headerClass:["cellstylegrid","fbold"],
                children:[
                    {
                        headerName:"Device",
						headerClass:["cellstylegrid"],
                        field:"last_mile_rso_device_name",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegrid"]
                            }
                            else
                            {
                                return ["cellstylegrid"]
                            }
                        }
                    },
                    {
                        headerName:"Mode",
						headerClass:["cellstylegrid"],
                        field:"last_mile_rso_mode",
                        width:80,
						cellClass:["cellstylegrid"]
                    },
                    {
                        headerName:"No Of Cars",
						headerClass:["cellstylegrid"],
                        field:"last_mile_rso_no_of_cars",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegrid"]
                            }
                            else
                            {
                                return ["cellstylegrid"]
                            }
                        }
                    }
                ]
            },
            
            {
                headerName:"DESTINATION TO DEALER",
				headerClass:["cellstylegridGLG","fbold"],
                children:[
                    {
                        headerName:"Device",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_device_name",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridGLG"]
                            }
                            else
                            {
                                return ["cellstylegridGLG"]
                            }
                        }
                    },
                    {
                        headerName:"No Of Cars",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_no_of_cars",
                        width:80,
						cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridGLG"]
                            }
                            else
                            {
                                return ["cellstylegridGLG"]
                            }
                        }
                    },
                    {
                        headerName:"Mode",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_mode",
                        width:120,
                        cellClass:["cellstylegridGLG"]
                    },
                    {
                        headerName:"Bill To Party",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_bill_to_party",
                        width:120,
                        cellClass:function(params){
                           
                                return ["cellstylegridGLG"]
                            
                        }
                    },
                    
                    {
                        headerName:"Bill To Party Name",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_bill_to_party_name",
                        width:200,
                        cellClass:function(params){
                           
                                return ["cellstylegridGLG"]
                            
                        }
                    },
                    {
                        headerName:"Dealer City",
						headerClass:["cellstylegridGLG"],
                        field:"directly_to_dealer_dealer_city",
                        width:120,
                        cellClass:function(params){
                           
                                return ["cellstylegridGLG"]
                            
                        }
                    }
                ]
			},


			{
                headerName:"RSO TO DEALER",
				headerClass:["cellstylegridO","fbold"],
                children:[
                    {
                        headerName:"Device",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_device_name",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridO"]
                            }
                            else
                            {
                                return ["cellstylegridO"]
                            }
                        }
                    },
                    {
                        headerName:"Mode",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_mode",
                        width:80,
						cellClass:["cellstylegridO"]
                    },
                    {
                        headerName:"No Of Cars",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_no_of_cars",
                        width:120,
                        cellClass:function(params){
                            if(params.data.master_ref_no == "Total")
                            {
                                return ["fbold","f15","cellstylegridO"]
                            }
                            else
                            {
                                return ["cellstylegridO"]
                            }
                        }
                    },
                    {
                        headerName:"Bill To Party",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_bill_to_party",
                        width:120,
                        cellClass:function(params){
                           
                                return ["cellstylegridO"]
                            
                        }
                    },
                    
                    {
                        headerName:"Bill To Party Name",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_bill_to_party_name",
                        width:200,
                        cellClass:function(params){
                           
                                return ["cellstylegridO"]
                            
                        }
                    },
                    {
                        headerName:"Dealer City",
						headerClass:["cellstylegridO"],
                        field:"last_mile_dealer_city",
                        width:120,
                        cellClass:function(params){
                           
                                return ["cellstylegridO"]
                            
                        }
                    }
                ]
			},
			
        ]
        
        var columnwithMarkDefs = [
            {
                headerName:"Vin No",
                field:"vehicle_id",
                width:180,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Bill To Party",
                field:"bill_to_party",
                width:100,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Bill To Party Name",
                field:"bill_to_party_name",
                width:160,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Dealer City",
                field:"city",
                width:180,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Mode",
                field:"mark_destination_dealer_rso_mode",
                width:120,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["Trailer","Convoy"],
                },
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Destination",
                field:"mark_destination_dealer_rso_destination",
                width:120,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["Dealer", "RSO"],
                },
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_destination_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
        ]
        var columnwithMarkRsoDefs = [
            {
                headerName:"Vin No",
                field:"vehicle_id",
                width:180,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_rso_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Bill To Party",
                field:"bill_to_party",
                width:100,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_rso_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Bill To Party Name",
                field:"bill_to_party_name",
                width:160,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_rso_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Dealer City",
                field:"city",
                width:110,
                cellClass:function(params){
                    try{
                        if(parseInt(params.data.is_rso_to_dealer) == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Mode",
                field:"mark_destination_dealer_rso_mode",
                width:80,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["Trailer","Convoy"],
                },
                cellClass:function(params){
                    try{
                        if(params.data.is_rso_to_dealer == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName:"RSO Is destination",
                field:"is_end_destination_rso",
                width:100,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["No","Yes"],
                },
                cellClass:function(params){
                    try{
                        if(params.data.is_rso_to_dealer == 1)
                        {
                            return ["green"]
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
        ]
        var columnwithDownload = [
            {
                headerName:"Vin No",
                field:"vehicle_id",
                width:180,
                // headerCheckboxSelection: true,
                // checkboxSelection: true,
               
            },
            {
                headerName:"Bill To Party",
                field:"last_mile_dealer_bill_to_party",
                width:100,
                
            },
            {
                headerName:"Bill To Party Name",
                field:"last_mile_dealer_bill_to_party_name",
                width:160,
               
            },
            {
                headerName:"Dealer City",
                field:"last_mile_dealer_city",
                width:160,
               
            },
            {
                headerName:"Mode",
                field:"last_mile_dealer_mode",
                width:120,
                editable:false,
                
            }
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12">
                    	<div className="card">
                            <div className="card-header">
				       			<h5 style={{marginBottom:"5px"}}>
				       				<i className="icon-map cus-i"></i> <span>GPS Device Planning {this.state.htitle}  </span>
				       				
                                    		
								</h5>
                                <div className={"col-xl-2 col-lg-2"}></div>
                                <div className={"col-xl-10 col-lg-10 float-right "}>
                                    
                                    <button className={"float-right custom-btn white btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Layout
                                    </button>
                                    <button className={"float-right custom-btn white btn btn-info f14 "} style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Layout
                                    </button>
                                    		
                                    <button className={"float-right custom-btn white btn btn-danger f14 "} style={{marginRight:"10px",color:"#333"}} onClick={this.onClickShowDownloadData.bind(this)}>
                                        Download RSO To Dealer
                                    </button>		
                                    <button className={"float-right white custom-btn btn btn-primary f14 "} style={{marginRight:"10px",color:"#333"}} onClick={this.onClickShowMarkRsoDealer.bind(this)}>
                                        Mark RSO To Dealer
                                    </button>			
                                    <button className={"float-right custom-btn btn btn-warning f14 "} style={{marginRight:"10px",color:"#333"}} onClick={this.onClickShowMarkDealer.bind(this)}>
                                        Mark Destination Yard To Dealer / RSO
                                    </button>	
                                </div>
				   			</div>
                               
                              
				   			<div className="card-body row">
                                   
                            {(this.props.match.path.indexOf("viewdeviceplanning") == -1)?
                                <div className={"row col-xl-12 col-lg-12 "}>
                                    <div className="col-xl-4 col-lg-4 row form-group">
                                        <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
                                        
                                        <Select 
                                            placeholder={"Select Master Ref No"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeSourceItem.bind(this)} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.sourceopt}
                                            options={this.selectsources()} />
                                    </div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <button type="button" onClick={this.onClickGetMasterRefData.bind(this)} className="btn btn-success mt-30p">Generate Data</button>
                                    </div>
                                </div>
                                :""}
                                <div className={"row col-xl-12 col-lg-12 "+(this.state.showgrid)}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
											masterDetail={true}
                                            pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                                        />

                                    </div>
                                </div>
                                	
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.sidebarMarkDealerTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Mark Destination Yard To Dealer
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"row "+(this.state.markdestgrid)} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12">
							<div className="col-xl-12 col-lg-12 row form-group">
                                <div id="myGrid2" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithMarkDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.markRowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>

							<div className="col-xl-12 col-lg-12 form-group">
								<button type="button" onClick={this.formMarkDestinationYardDealer.bind(this)} className="btn btn-success">Submit</button>
                                <button 
                                type="button"
                                onClick={this.onClickShowMarkDestBulk.bind(this)}
                                className="btn btn-danger float-right"
                                >Bulk Upload</button>
                            </div>
						</form>

                        </div>

                        {/* Bulk Upload */}
                        <div className={"row "+(this.state.markdestbulk)} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDestinationBulk.bind(this)} >
                            <div className="col-xl-12 col-lg-12 row">
                                <div className="col-xl-3 col-lg-3 form-group"></div>
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <input type="file" 
                                        onChange={this.changeFileHandler.bind(this)}
                                        name="bulkdestination"
                                        style={{marginBottom:"10px"}}
                                        required={true}
                                    />
                                     <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.onClickHideMarkDestBulk.bind(this)} className="btn btn-default">Cancel</button>
                                
                                </div>

                                <div className="row col-xl-12 col-lg-12 form-group">
                                    <div className="col-xl-3 col-lg-3 form-group"></div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                    <a href={require("../../assets/json/sample_bulk_mark_destination.csv")} target="_blank" className="btn btn-warning">
                                        Download Sample Template</a>
                                    </div>
                                    
                                    <div className="col-xl-12 col-lg-12" style={{paddingRight:"0px"}}>
                                        Note: Allowed Values<br />
                                        Mode:  <span style={{color:"#ff0000",fontWeight:"600"}}>Convoy</span> / <span style={{color:"#ff0000",fontWeight:"600"}}>Trailer</span><br />
                                        Destination : <span style={{color:"#ff0000",fontWeight:"600"}}>Dealer</span> / <span style={{color:"#ff0000",fontWeight:"600"}}>RSO</span>
                                    </div>
                                </div>
                            </div>
						</form>

                        </div>

                        

					</div>
				</div>


                <div className={"slide-r "+(this.state.sidebarMarkRsoDealerTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Mark RSO To Dealer
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"row "+(this.state.markrsogrid)} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12">
							<div className="col-xl-12 col-lg-12 row form-group">
                                <div id="myGrid3" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithMarkRsoDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.markRsoRowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady2}
                                        onGridState={this.onGridState2}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>

							<div className="col-xl-12 col-lg-12 form-group">
								<button type="button" onClick={this.formMarkRSOToDealer.bind(this)} className="btn btn-success">Submit</button>
                                <button 
                                type="button"
                                onClick={this.onClickShowMarkRsoBulk.bind(this)}
                                className="btn btn-danger float-right"
                                >Bulk Upload</button>
                            </div>
						</form>

                        </div>


                        {/* RSO Bulk Update */}

                         {/* Bulk Upload */}
                         <div className={"row "+(this.state.markrsobulk)} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12 row">
                                <div className="col-xl-3 col-lg-3 form-group"></div>
                                <div className="col-xl-6 col-lg-6 form-group">
                                    <input type="file" 
                                        onChange={this.changeRsoFileHandler.bind(this)}
                                        name="bulkrso"
                                        style={{marginBottom:"10px"}}                                        
                                        required={true}
                                    />
                                     <button type="submit" onClick={this.formMarkRsoBulk.bind(this)} className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.onClickHideMarkRsoBulk.bind(this)} className="btn btn-default">Cancel</button>
                                
                                </div>

                                <div className="row col-xl-12 col-lg-12 form-group">
                                    <div className="col-xl-3 col-lg-3 form-group"></div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                    <a href={require("../../assets/json/sample_bulk_mark_rso.csv")} target="_blank" className="btn btn-warning">
                                        Download Sample Template</a>
                                    </div>
                                    <div className="col-xl-12 col-lg-12" style={{paddingRight:"0px"}}>
                                        Note: Allowed Values<br />
                                        Mode:  <span style={{color:"#ff0000",fontWeight:"600"}}>Convoy</span> / <span style={{color:"#ff0000",fontWeight:"600"}}>Trailer</span><br />
                                        Is End Destination RSO : <span style={{color:"#ff0000",fontWeight:"600"}}>Yes</span> / <span style={{color:"#ff0000",fontWeight:"600"}}>No</span>
                                    </div>
                                </div>
                            </div>
						</form>

                        </div>

					</div>
				</div>



                <div className={"slide-r "+(this.state.sidebarDownloadTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Download RSO To Dealer
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12">
							<div className="col-xl-12 col-lg-12 row form-group" style={{marginLeft:"10px",padding:"0px"}}>
                                <div id="myGrid4" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDownload}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.downloadRowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady4}
                                        onGridState={this.onGridState4s}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                            
						</form>

                        </div>
					</div>
				</div>


            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }