import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';

import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var todaydateis = moment.parseZone().format("YYYY/MM/DD")
export default class MLLLastMileTransshipments extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            //components : {datepicker:getDatePicker()}
        }
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle='Last Mile Transportation';
        this.setState({
            pagetitle:pagetitle
        })
        this.onLoadData()
        

    }
    onLoadData(){
        redirectURL.post("/consignments/mllfirstmilevins")
        .then((response) => {
            console.log("recp ", response.data)
            var records = response.data.records
            this.setState({
                rowData:records,
                overly:"show-n",
                loadshow:"show-n"
            })
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    selectGeofence(){
        let arr=[];
        try{
            this.state.geofencelist.forEach((item,index) =>{
            arr.push({value:this.state.geoFenceData[index]._id,label:item});
        });
        
        return arr;
        }
        catch(e){

        } 
    }
    getRailConsginments(params){
        //console.log("params ", params)
        //try{
            //var geoFenceId = params
            var geofenceidarr = [];
            //geoFenceId.map(function(e){
                geofenceidarr.push(params.value);
          
            var reqParams = {
                geoFenceId : geofenceidarr,
               
            }
            redirectURL.post("/consignments/mllgeofencevins",reqParams).then((response)=>{
                //console.log(response.data);
                if(response.data.records.length > 0){
                    this.setState({
                        rowData:response.data.records
                    });
                }
                else{
                    this.setState({
                        rowData : [],
                    })
                }
                // console.log(this.state.rowData);
            }).catch(function(error){
                console.log(error);
            });
        // }
        // catch(e){

        // }        
    }
    changeUploadYard(e){
        this.setState({
            uploadYard : e.value,
        })
    }
    changeBulkUploadYard(e){
        console.log(e)
        this.setState({
            uploadYard : e.label,
        })
    }
    changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vehicle_no',
					inputName: 'vehicle_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'vehicle_id',
					inputName: 'vehicle_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
                var contents = await e.target.result;
                console.log(contents)
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
    }

    uploadBulkFormHandler(event){
        event.preventDefault();
        
        if(this.state.file != '')
        {
            //console.log("this.state.file ",this.state.file)
            var csvdd = this.state.file;
            
            // console.log("event ",event.target.uploadFile.value);
            
            var rparams = {
                csvcontent:this.state.csvcontent,
                bulkUploadYard : this.state.uploadYard,
                dispatch_date : $(".uploadDispatchDate").val()
            }
        // console.log(rparams);
            var csvContent = this.state.csvcontent;
            // console.log(csvContent)
            // console.log(typeof(csvContent));
            var emptyFlag  = 0;
            for(var i=1;i<csvContent.length;i++)
            {
                var truck_no = csvContent[i].truck_no;
                var consignment_code = csvContent[i].consignment_code;
                //console.log(consignment_code)
                if(truck_no == "" || consignment_code == "" || truck_no == null || truck_no == undefined || consignment_code == undefined || consignment_code==null)
                {
                    emptyFlag = 1;
                }
                
            }
            
            this.setState({
                loadshow:'show-m'
            }); 
            if(emptyFlag == 0)
            {
                redirectURL.post("/consignments/mllupdatelastmiletransshipments",rparams)
                .then((response)=>{
                    var result = response.data;
                    //console.log("response bulk upload ", result)
                    if(result.notvalidrecords.length == 0)
                    {
                        if(result.flag == 0)
                        {
                            this.setState({
                                //csverrmessage:response.data.status,
                                show: true, basicType:'success', basicTitle:"Successfuly update File.",
                                uploadDivWidth:'0%',
                                showDiv:'show-n',
                                loadshow:'show-n',
                                uploadFile:'',
                                bulkslide:"",
                                overly:"show-n",
                                file:''
                            });
                        }
                        else{
                            this.setState({
                                show: true, basicType:'danger', 
                                basicTitle:"Dispatch date should be greater than destination reached date",
                                uploadDivWidth:'0%',
                                showDiv:'show-n',
                                loadshow:'show-n',
                                uploadFile:'',
                                bulkslide:"",
                                overly:"show-n",
                                file:''
                            });
                        }
                        
                    }
                    else{
                        if(result.flag == 0)
                        {
                            this.setState({
                                //csverrmessage:response.data.status,
                                show: true, basicType:'danger', 
                                basicTitle:"Following Consignment Codes does not have destination yard. "+result.notvalidrecords,
                                uploadDivWidth:'0%',
                                showDiv:'show-n',
                                loadshow:'show-n',
                                uploadFile:'',
                                bulkslide:"",
                                overly:"show-n",
                                file:''
                            });
                        }
                        else
                        {
                            this.setState({
                                show: true, basicType:'danger', 
                                basicTitle:"Dispatch date should be greater than destination reached date",
                                uploadDivWidth:'0%',
                                showDiv:'show-n',
                                loadshow:'show-n',
                                uploadFile:'',
                                bulkslide:"",
                                overly:"show-n",
                                file:''
                            });
                        }
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                $("#bulkUploadID").val("");
                
                    this.setState({
                        //csverrmessage:response.data.status,
                        show: true, basicType:'danger', basicTitle:"Consignment Code or Truck No Must Not be Empty.",
                        uploadDivWidth:'0%',
                        sliderTranslate:'',
                        showDiv:'show-n',
                        loadshow:'show-n',
                        uploadFile:'',
                        file:''
                    });
            }
            
        }
        
    }
        
    saveReplacedData(event){
        event.preventDefault();
        //console.log(this.gridApi);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUpdateNow,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
        var rwCount = this.gridApi.getSelectedNodes();
        //console.log("rwCount ",rwCount)
        var truckData=[]
        if(rwCount.length > 0)
        {
            rwCount.map((item)=>{
                truckData.push(item.data);
            });
        }
        var replacement_truck = this.state.replacement_truck;

        var reqParams = {
            truckData : truckData,
            replacement_truck : replacement_truck,
            email:localStorage.getItem("email"),
            transhipment_done_by : localStorage.getItem("username")
            
        } 
        console.log("reqParams " , reqParams)
        redirectURL.post("/consignments/updatelastmiletransshipments",reqParams).then((response)=>{
            this.setState({
                show:true,
                basicTitle:"Updated Successfully.",
                basicType:"success",
            });
            //window.location.reload();
        }).catch(function (error){
            console.log(error);
        })
        
            
    }
    onClickShowBulkUpload(){
        this.setState({
            bulkslide:"slider-translate",
            overly:'show-m'
        });
    }
    resetUpload = () => {
        this.setState({
            uploadDivWidth:'0%',
            bulkslide:'',
            showDiv:'show-n',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:''
        });
        
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }

    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    onRowSelection(event){
        var containerslist=[];
        //console.log("rowselect", event)
        var rwCount = event.api.getSelectedNodes();
        console.log("rwCount ", rwCount)
        //if(event.node.selected == true)
        //{
            //var containerlist=[];
            console.log("rowselect", event.data)
            var rowCount =  event.data
            //console.log("rowCount ", rowCount)
            
            rwCount.map((item) => {
                containerslist.push(item.data.truck_no)
            });
            
            this.setState({
                containerslist:containerslist
            })
            console.log("checkedlist ", containerslist)
        // }
        // else{
        // 	if(containerslist.length > 0)
        // 	{
        // 		containerslist=[]
        // 		this.setState({
        // 			containerslist:containerslist
        // 		})
        // 	}
        // }
        
    }
    render(){
        const columnwithDefs = [
            {
                headerName: "Vin No",
                field: "vehicle_id",
                pinned: 'left',
                filter: true,
                resizable: true,
                headerCheckboxSelection: false,
                //headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
           
            {
                headerName: "Source",
                field: "source_name",
                width:140,
                filter: true,
                resizable: true
            },
            {
                headerName: "City",
                field: "city",
                width:140,
                filter: true,
                resizable: true
            },
            {
                headerName: "Trailer No",
                field: "trailer_no",
                width:140,
                resizable: true
            },
            {
                headerName: "Ewaybill No",
                field: "ewaybill_no",
                width:140,
                resizable: true
            },
            {
                headerName: "Ewaybill Date",
                field: "ewaybilldate",
                width:140,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.ewaybilldate != "" && params.data.ewaybilldate != undefined)
                        {
                            return getHyphenDDMMYYYYHHMMSS(params.data.ewaybilldate);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){

                    }
                }
            },
            {
                headerName: "Ewaybill Expiry Date",
                field: "ewaybill_expiry_date",
                width:140,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.ewaybill_expiry_date != "" && params.data.ewaybill_expiry_date != undefined)
                        {
                            return getHyphenDDMMYYYYHHMMSS(params.data.ewaybill_expiry_date);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){

                    }
                }
            },
            {
                headerName: "Dealer Code",
                field: "bill_to_party",
                width:140,
                resizable: true
            },
            {
                headerName: "Dealer Name",
                field: "bill_to_party_name",
                width:140,
                resizable: true
            },
            {
                headerName: "City",
                field: "city",
                width:140,
                resizable: true
            }
           

        ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className=" col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="col-xl-12 col-lg-12">
                                {/* {this.state.rowData != null ? */}
                                    <div id="myGrid" style={{width:"100%",height:"360px"}} className="ag-theme-balham">    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        rowSelection={this.state.rowSelection}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        />

                                    </div>

                                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p Transhipment-Edit-Row">
                                        <form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.saveReplacedData.bind(this)}>
                                            <div className="col-xl-4 col-lg-4 form-group"></div>
                                            <div className="col-xl-2 col-lg-2 form-group">
                                                <label className="">Replacement Truck</label>
                                                <input type="text" class="form-control" name="replacement_truck" id="replacement_truck" onChange={this.changeHandler.bind(this)} autoComplete="off" Placeholder="Truck No." required />
                                            </div>
                {/* 
                                            <div className={"col-xl-2 col-lg-2 form-group "+(this.state.showonlytransport)}>
                                                <label className="replacement-time">Replacement Time</label>
                                                
                                                <input type="text" value={this.state.replaced_date_time} autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="replaced_Date" />
                                            </div> */}
                                            {/* <div className={"col-xl-3 col-lg-3 form-group"}>
                                                <label className="reason">Reason</label>
                                                <input type="text" name="reason" value={this.state.reason} autoComplete="off" onChange={this.changeHandler.bind(this)} class="form-control" id="reason" />
                                            </div> */}
                                            <div className="col-xl-2 col-lg-2 form-group">
                                                {/* <label className="replacement-time"><br /></label> */}
                                                <button type="submit" className="btn btn-warning mt-30p" id="replacement-submit">Submit</button>
                                            </div>
                                        
                                            
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row" onSubmit={this.getTruckConsignments.bind(this)}>
                       
                            <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div>
                         <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
	                    </div>
                        <div className="col-xl-2 col-lg-2">
                            <button type="submit" className="btn btn-success">Get Data</button>
                        </div>
                    </form> */}
                </div>               
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    console.log("todaydateis ",todaydateis)
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        maxDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
