import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { isNumber } from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD } from '../common/utils';
import RailFinancialColumnLineChart from "./railfinancialcolumlinechart";
import RailBRMColumnLineChart from "./railbrmcolumnchart"
import $ from "jquery";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class BRMDashboard extends Component{
        constructor(props){
        super(props);
        this.state = {
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            dept_code:"",
            pageTitle:"Rail",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            open:false,
            financialyear:'',
            finyear:'',
            raildispatchdata:[],
            raildispatchdataavg:[],
            raildispatchthird:[],
            raildispatchfourth:[],
            raildispatchfifth:[],
            raildispatchsixth:[],
            raildispatchseventh:[],
            raildispatcheigth:[],
            sourceoption:[],
            clusters:"",
            transporters:[],
            clusterdropdown:[],
            transdropdown:[],
            sourcedropdown:[],
            olderfyplot:[],
            rowData: [],
            defaultColDef: {
                sortable: true,
            filter: true,
            resizable: true,
                enableValue: true,
                enableRowGroup: true
            },              
            rowSelection:'multiple',
                rowModelhighchartoptionsType: 'enterprise',
                rowGroupPanelShow: 'always',
                overlayNoRowsTemplate: "",
                frameworkComponents: {
            },

                animateRows: true,
                debug: true,
                showToolPanel: false,
            uppressAggFuncInHeader: true,
            target_fy: "",
            target_amount: "",
            targetFY: "",
            targetFYValue: "",
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            userType: "",
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            showFirstChart: "show-m",
            showSecondChart: "show-n",
            showThirdChart: "show-n",
            showFourthChart: "show-n",
            showFifthChart: "show-n",
            showSixthLogs: "show-n",
            secondChartArr: [],
            firstChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            fifthChartArr: [],
            secondChartRowData: [],
            thirdChartRowData: [],
            fourthChartRowData: [],
            fifthChartRowData: [],
            damageRowData: [],
            railModeSalesData: [],
            yearOptions: [],
            selectedFY: {},
            defaultSelectedFy: {},
            screenName: "Rail Mode Dispatches",
            onTimeActive: "",
            trailerActive: "",
            interplantActive:"",
            damagesActive: "",
            sixthDamagesActive: "",
            railModeActive: "activet",
            fifthChartColumns: [],
            onTimetarget: "",
            editedOnTimeTarget: "",
            trailerDispatchesTarget: "",
            editedDispatchesTarget: "",
            railModeSalesColumns: [],
            sixthLogsColumns: [],
            sixthLogsRowData: [],
            interStockDailyData: [],
            damagesLimit: "",
            remove_fy: "",
            dispatches_target_fy: "",
            ontime_target_fy: "",
            damages_target_fy: "",
            logs_5_from_month: "",
            logs_5_to_month: "",
            logs_6_from_month: "",
            logs_6_to_month: "",
            trailerDispatchesSummaryRowData: [],
            trailerDispatchesSummaryColumns: [],
            trailerDispatchesSummaryPerColumns: [],
            highlitRow: ""
        }

        this.onLoadOlderFys = this.onLoadOlderFys.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                //console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async () => {
        var fy = ""
        var currMonth = moment.parseZone(new Date()).utcOffset("+05:30").format("MM-YYYY");
        var month = moment.parseZone(new Date()).utcOffset("+05:30").format("MM");
        var year = moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY");
        var fy;
        var shorfy = ""
        if(month == 4)
        {
            this.setState({
                remove_fy: year+" - "+(parseInt(year)+1).toString()
            })
        }
        if(month > 4)
        {
            fy = year+" - "+(parseInt(year)+1).toString();
        }
        else
        {
            fy = (parseInt(year) - 1).toString()+" - "+year
        }
        if(this.props.match.path != "/railbrmdashboard" && this.props.match.path != "/brmdashboardmgmt")
        {
            if(localStorage.getItem("roles").includes("tpt_rail") == false || localStorage.getItem("roles").includes("SITE_ADMIN") == true)
            {
                this.setState({
                    railModeActive: "",
                    trailerActive: "activet",
                    screenName: "Trailer Dispatches",
                    showThirdChart: "",
                    showFirstChart: "show-n"
                })
            }    
        }
        

        await this.onLoadOlderFys(fy);
        await this.onLoadSecondChart(fy);
        await this.onLoadThirdChart(fy);
        await this.onLoadFourthChart(fy);
        await this.onLoadFifthChart(fy);
        await this.onLoadSixthLogs(fy);

        if(this.props.match.path == "/brmdashboardmgmt")
        {
            var userType = "management"
        }
        else
        {
            var userType = "user"
        }
        
        redirectURL.post("/dashboard/getMonthlyDataYears",{"collection": "brmanalyticsdata"}).then((response)=>{
            var yearOptions = [];
            response.data.map(function(e){
                yearOptions.push({"label": e, "value": e})
            })
            this.setState({
                yearOptions: yearOptions,
                selectedFY: {"label": fy, "value": fy},
                defaultSelectedFy: {"label": fy, "value": fy}
            })
        })
        this.setState({
            "userType": userType
        })
    }

    onLoadOlderFys(fy){
        redirectURL.post("/dashboard/brmdashboarddata",{"fy": fy}).then((response)=>{
            var records = response.data.nodeData;
            var apiData = JSON.parse(JSON.parse(response.data.apiData).railSalesData);
            var railModeSalesColumns = []
            // railmodelcolumns = Object.keys(apiData[0])
            for(var i in apiData[0])
            {
                var header_name = i;
                if(i != "Change Percent" && i != "Mode")
                {
                    //  header_name = i.split("-")[0]+" "+records[0]['from_month']+" - "+i.split("-")[1]+" "+records[0]['to_month']
                    header_name = i+" (Till "+records[0]['from_month']+")"
                }
                var railfyrowdata={
                    headerName: header_name,
                    field: i,
                    width: 150
                }
                if(i == "Change Percent")
                {
                    railfyrowdata['headerName'] = "% Change"
                    railfyrowdata["cellClass"] = ["highlitRow"];
                    railfyrowdata["headerClass"] = ["highlitRow"];
                    
                }
                
                if(i.includes("-") && i != "Rail Contribution")
                {
                    railfyrowdata['editable'] = function(params){
                        if(params.data.Mode != "Rail Contribution" && params.data.Mode != "Total"){
                            return true;
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                railModeSalesColumns.push(railfyrowdata)
                
            }

            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))
            records = prevFy.concat(currFY)

            this.setState({
                rowData: records
            })
            var raildispatcharr = [];
            var exports = [];
            var domestic = [];
            var categories = [];
            var no_of_cars = [];
            records.map(function(e){
                exports.push(e.exports)
                domestic.push(e.domestic);
                no_of_cars.push(e.no_of_cars);
                categories.push(e.month);
            })
            var series = [
                {
                    name: 'Domestic',
                    type: 'column',
                    data: domestic,
                    color: "#bacee5",
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'Exports',
                    type: 'column',
                    data: exports,
                    color: "#ff0000",
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'No. of Cars',
                    type: 'spline',
                   // yAxis: 1,
                    data: no_of_cars,
                    color: "#84a7d0",
                    dataLabels: {
                        enabled: true
                    }
                }
                
            ]

            raildispatcharr.push({
                series : series,
                categories : categories,
                subtitle: "Target For "+response.data.nodeData[0].target_fy+": "+response.data.nodeData[0].target_amount,
                xtitle:"",
                ytitle:"",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            console.log("raildispatcharr ", raildispatcharr)
            console.log("apiData",response.data.nodeData)
            this.setState({
                raildispatchdata: raildispatcharr,
                firstChartArr: raildispatcharr,
                targetFY: response.data.nodeData[0].target_fy,
                targetFYValue: response.data.nodeData[0].target_amount,
                railModeSalesColumns: railModeSalesColumns,
                railModeSalesData: apiData
            })
            
           
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadSecondChart(fy){
        redirectURL.post("/dashboard/brmOnTime").then((response)=>{
            var records = response.data;
            this.setState({
                onTimetarget: records[0].target,
                ontime_target_fy: records[0].target_fy
            })
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            // if(this.state.remove_fy == "")
            // {
            //     currFY.pop()
            //     currFY.pop()
            // }
            // else
            // {
            //     currFY.pop()
            // }
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))

            records = prevFy.concat(currFY)
            this.setState({
                secondChartRowData: records
            })
            var secondChartArr = [];
            var onTimePercentageColumn = [];
            var onTimePercentageLine = [];
            var categories = [];
            records.map(function(e){
                // if(e.month_flag == 1 && e.month.includes("Apr") == false)
                // {
                    onTimePercentageLine.push({y: e.on_time_percentage, "total_trips": e.total_trips, "on_time_trips": e.on_time_trips})
                // }
                // else{
                //     onTimePercentageLine.push({y: null, "total_trips": 0, "on_time_trips": 0})

                // }
                categories.push(e.month);
            })
            prevFy.map(function(e){
                onTimePercentageColumn.push({y: e.on_time_percentage, dataLabels: { x: 0, y: -130 } ,
                                                 "total_trips": e.total_trips, "on_time_trips": e.on_time_trips})

            })
            var series = [
                {
                    name: '% On Time',
                    type: 'column',
                    data: onTimePercentageColumn,
                    color : "#9bbb59",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: '% On Time month-wise',
                    type: 'spline',
                    color : "#8064a2",
                    marker: {
                        fillColor: '#8064a2',
                    },
                   // yAxis: 1,
                    data: onTimePercentageLine,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                }
                
            ]

            secondChartArr.push({
                series : series,
                categories : categories,
                subtitle: "",
                xtitle:"",
                ytitle:"On Time %",
                yaxistitle:"",
                chartNo: 2
            })
            this.setState({
                secondChartArr: secondChartArr,
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadThirdChart(fy){
        redirectURL.post("/dashboard/brmDispatchedTrailers").then((response)=>{
            var records = response.data.nodedata;
            var apidata = response.data.apidata;
            var trailerDispatchesSummaryColumns = [];
            var trailerDispatchesSummaryPerColumns = [];
            var apidatacolumns = Object.keys(apidata[0]);
            apidatacolumns.map((e)=>{
                if(e.includes("%") == false)
                {
                    if(e == "route_name")
                    {
                        trailerDispatchesSummaryColumns.push({
                            "headerName": "Mar'21",
                            "field": e,
                            "resizable": true,
                            "width": 120,
                            "valueGetter": function(params){
                                if(params.data[e] == "Total"){
                                    return "Domestic Total"
                                }
                                else
                                {
                                    return params.data[e]
                                }
                            }
                            
                        })
                        
                    }
                    else{
                        trailerDispatchesSummaryColumns.push({
                            "headerName": e,
                            "field": e,
                            "resizable": true,
                            width: 100
                        })
                    }
                }
                else
                {
                    trailerDispatchesSummaryPerColumns.push({
                        "headerName": e,
                        "field": e,
                        "resizable": true,
                        width: 100
                    })
                }
                
            })
            this.setState({
                trailerDispatchesSummaryRowData: apidata,
                trailerDispatchesSummaryColumns:trailerDispatchesSummaryColumns,
                trailerDispatchesSummaryPerColumns: trailerDispatchesSummaryPerColumns
            })
            this.setState({
                trailerDispatchesTarget: records[0].target,
                dispatches_target_fy: records[0].target_fy
            })
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            // if(this.state.remove_fy == "")
            // {
            //     currFY.pop()
            // }
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))
            records = prevFy.concat(currFY)
            this.setState({
                thirdChartRowData: records
            });
            var thirdChartArr = [];
            var prevFyDataTotals = [];
            var prevFyDataDispatched = [];
            var currFyDataTotals = [];
            var currFyDataDispatched = [];
            var categories = [];
            prevFy.map(function(e){
                if(e.total_trailers != ""){
                    prevFyDataTotals.push(e.total_trailers)
                }
                else{
                    prevFyDataTotals.push(null)
                }
                if(e.total_trailers_dispatched != "")
                {
                    prevFyDataDispatched.push(e.total_trailers_dispatched)
                }
                else{ 
                    prevFyDataDispatched.push(null)
                }
                currFyDataTotals.push(null) //adding nulls to hide data point in chart
                currFyDataDispatched.push(null) //    adding nulls
            })
            records.map(function(e){
                if(e.month_flag == 1)
                {
                    if(e.total_trailers != "")
                    {
                        currFyDataTotals.push(e.total_trailers)
                    }
                    else
                    {
                        currFyDataTotals.push(null)
                    }
                    if(e.total_trailers_dispatched != "none")
                    {
                        currFyDataDispatched.push(e.total_trailers_dispatched)
                    }
                    else
                    {
                        currFyDataDispatched.push(null)
                    }
                }
                
                categories.push(e.month);
            })
            
            var series = [
                {
                    name: '% of Trailers in fleet',
                    type: 'column',
                    data: prevFyDataTotals,
                    color: "#000000",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Disp. % by Trailers',
                    type: 'column',
                    color: "#ff8080",
                    data: prevFyDataDispatched,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: '% of Trailers in fleet (month-wise)',
                    type: 'spline',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: currFyDataTotals,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Disp. % by Trailers (month-wise)',
                    type: 'spline',
                   // yAxis: 1,
                   color: "#5b5b5b",
                    data: currFyDataDispatched,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]

            thirdChartArr.push({
                series : series,
                categories : categories,
                subtitle: "",
                xtitle:"",
                ytitle:"On Time %",
                yaxistitle:"",
                chartNo: 2
            })
            console.log(thirdChartArr, "thirdChartArr")
            this.setState({
                thirdChartArr: thirdChartArr,
            })
        })
    }
    onLoadFourthChart(fy)
    {
        redirectURL.post("/dashboard/brminterstockyarddata").then((response)=>{
            var dailyData = response.data.dailyData;
            var records = response.data.fyData;
            
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            var prevFy = records.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            records = prevFy.concat(currFY)

            var fourthChartArr = [];
            var moves = [];
            var avg_stock = [];
            var max_stock = [];

            var categories = [];
            records.map(function(e){
                moves.push(Math.round(e.moves, 2))
                avg_stock.push(Math.round(e.average_stock_at_gurgoan, 2));
                max_stock.push(Math.round(e.maximum_stock_at_gurgoan, 2));
                categories.push(e.month);
            })
            var series = [
                {
                    name: 'Moves',
                    type: 'column',
                    data: moves,
                    color: "#92d050",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                {
                    name: 'Average Stock at GGN',
                    type: 'column',
                    data: avg_stock,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                {
                    name: 'Maximum Stock at GGN',
                    type: 'column',
                    color: "#0D233A",
                    data: max_stock,
                    color: "#000000",
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        crop: false,
                        overflow: 'none'
                    }
                },
                
            ]

            fourthChartArr.push({
                series : series,
                categories : categories,
                xtitle:"",
                ytitle:"Total",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            console.log("fourthChartArr", fourthChartArr)
            dailyData.map(function(e){
                if(getHyphenYYYYMMDD(e.stock_date) == moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD"))
                {
                    e.curr_day = 1
                }
            })
            this.setState({
                fourthChartArr: fourthChartArr,
                fourthChartRowData: response.data.fyData,
                interStockDailyData: dailyData
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadFifthChart(fy)
    {
        redirectURL.post("/dashboard/brmdamagesreport").then((response)=>{
            var records = response.data.nodedata;
            this.setState({
                damagesLimit: records[0]['fy_limit'],
                damages_target_fy: records[0].target_fy
            })
            var damageRowData = response.data.damageData;
            // removing 16 - 17 fy row
            damageRowData.shift()
            // getting n-1 months
            damageRowData.pop()
            var damage_report_api_data = JSON.parse(JSON.parse(response.data.apidata).damage_data) ;
            var fifthChartColumns = []
            for(var key in damage_report_api_data[0])
            {
                var cellwidth = 100;
                var cellclass = "";
                var pinned = ""
                if(key.split(" -")[0].length == 2)
                {
                    cellclass = "cellstylegridG";
                }
                if(key == "Classification" || key == "Category of Damage")
                {
                    cellwidth = 130;
                    pinned = "left"
                }
                fifthChartColumns.push({
                    headerName: key,
                    field: key,
                    pinned: pinned,
                    width: cellwidth,
                    headerClass: cellclass,
                    cellClass: cellclass,
                    editable: false
                })
            }
            var currFY = records.filter(function(e){
                return e.year == fy && e.month_flag == 1
            })
            // getting n-1 months
            currFY.pop()
            var prevFy = records.filter(function(e){
                return e.month_flag == 0
            })
            prevFy = prevFy.slice(Math.max(prevFy.length - 4, 0))
            records = prevFy.concat(currFY)
            this.setState({
                fifthChartRowData: records,
                fifthChartColumns: fifthChartColumns,
                damageRowData: damageRowData
            })
            var fifthChartArr = [];
            var cat_a = [];
            var cat_b = [];
            var cat_c = [];
            var returns = [];
            var cost_per_vehicle=[];
            var categories = [];
            records.map(function(e){
                cat_a.push(e.no_of_vehicles['A'])
                cat_b.push(e.no_of_vehicles['B'])
                cat_c.push(e.no_of_vehicles['C'])
                returns.push(e.return_vehicles)
                cost_per_vehicle.push({name: e.month, y: e.cost_per_vehicle_dispatch})

                categories.push(e.month);
            })
            var series = [
                {
                    name: 'A Cat',
                    type: 'column',
                    data: cat_a,
                    color: "#c88e60",
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'B Cat',
                    type: 'column',
                    data: cat_b,
                    color: "#e2d9cb",
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'C Cat',
                    type: 'column',
                    color: "#3f0c67",
                    data: cat_c,
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'Return Veh. / Lakh (Dispatches)',
                    type: 'spline',
                    color: "#64b8ce",
                    data: returns,
                    dataLabels: {
                        enabled: true
                    }
                },
                {
                    name: 'Cost (Rs) - per vehicle dispatch',
                    type: 'scatter',
                    data: cost_per_vehicle,
                    color: '#000',
                    // borderColor: "#000",
                    borderWidth: 2,
                    dataLabels: {
                        enabled: true
                    },
                    marker: {
                        symbol: 'square'
                    },
                    tooltip: {
                        crosshairs: true,
                        // headerFormat: '<b>{point.x}</b>',
                        pointFormat: '<br /><b>{point.name} : </b> {point.y}'
                    }
                },
            ]

            fifthChartArr.push({
                series : series,
                categories : categories,
                xtitle:"",
                ytitle:"Total",
                yaxistitle:"Total Invoiced & Delayed Cars",
                chartNo: 1
            })
            // console.log("fifthChartArr", fifthChartArr)
            this.setState({
                fifthChartArr: fifthChartArr
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    onLoadSixthLogs(fy){
        redirectURL.post("/dashboard/brmCarrierDriverReport").then((response)=>{
            console.log(response.data)
            var sixthLogsRowData = eval(JSON.parse(response.data.apidata).final_data);
            var nodedata = response.data.nodedata
            var sixthLogsColumns = []
            sixthLogsRowData.push({
                "Training Center": "* IDTR - Institute of Driving Training and Research, New Delhi ( 1 day training at Loni, Delhi) - Training started from 1st Feb'21 & Taget is revised to 1,000\
                due to pandemic."
            })
            sixthLogsRowData.push({
                "Training Center": "** DEC - Driver Education Center (2 hrs session at Gurgaon, Manesar, Siliguri, Gujarat, TVP-N & TVP-B)- Training started from Aug'20 & Taget is revised\
                to 53,000 due to pandemic."
            })
            for(var key in sixthLogsRowData[0])
            {
                var keyname = key;
                if(key.includes("Target") || key.includes("Acheived"))
                {
                    // var count = 0
                    // var headerSplit = "";
                    // key.split(" ").map(function(e){
                    //     console.log(e, e.includes("'"))
                    //     if(e.includes("'"))
                    //     {
                    //         console.log(e.split("'")[0])
                    //         if(count == 0){
                    //             e = e.replace(e.split("'")[0], nodedata[0]['from_month'])
                    //             count = count + 1
                    //         }
                    //         else
                    //         {
                    //             e = e.replace(e.split("'")[0], nodedata[0]['to_month'])
                    //         }
                    //     }
                    //     if(headerSplit == "")
                    //     {
                    //         headerSplit = e
                    //     }
                    //     else
                    //     {
                    //         headerSplit = headerSplit+" "+e
                    //     }
                    // })
                    // keyname = headerSplit
                    keyname = key+" "+nodedata[0]['from_month']

                }
                if(key == "last_approved_on")
                {
                    keyname = "Last Approved On"
                }
                var coldata = {
                    headerName: keyname,
                    field: key,
                    width: 140
                }
                if(key.includes("Acheived"))
                {
                coldata['width'] = 200
                }
                if(key == "last_approved_on")
                {
                    coldata['valueGetter'] = function(params)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.last_approved_on)
                    }
                }
                if(key != "Training Center" && key != "% Acheivement")
                {
                    coldata['editable'] = true
                }
               
                if(key == "Training Center")
                {
                    coldata['colSpan'] = function(params){
                        if(params.data['Training Center'] != "IDTR" && params.data['Training Center'] != "DEC")
                        {
                            return 6
                        }
                        else
                        {
                            return 1
                        }
                    }
                }
                if(key == "Training Center")
                {
                    coldata["width"] = 300
                }
                sixthLogsColumns.push(coldata)
            }
            this.setState({
                sixthLogsColumns: sixthLogsColumns,
                sixthLogsRowData: sixthLogsRowData
            })
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
  
    }
    onFifthGridReady = params => {
		this.fifthGridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onFifthGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.fifthGridApi.setFilterModel(window.filterState);
  
    }
    onDamageGridReady = params => {
		this.damageGridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onDamageGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.damageGridApi.setFilterModel(window.filterState);
  
    }
    onISGridReady = params => {
		this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setSortModel([
            {
              colId: 'stock_date',
              sort: 'asc',
            },
          ]);
    };
    
	onISGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
       
    }

    
    
    

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
    }
    
    onRowSelected(event) {

    }
    closeAlert = async() => {
        await this.setState({
            show: false
        });
        // $("#targetform")[0].reset();
        // $("#ontimetargetform")[0].reset();
        // $("#dispatchestargetform")[0].reset();
    }


    onCellUpdatingStopped(cell)
    {   
        try{
            cell.value = eval(cell.value);
        }catch(e){
            cell.value = 0
        }
        
        var no_of_cars = cell.value;

        if(cell.colDef.field == "exports")
        {
            no_of_cars = no_of_cars + cell.data.domestic;
        }
        if(cell.colDef.field == "domestic")
        {
            no_of_cars = no_of_cars + cell.data.exports;
        }
        var params = {
            "_id": cell.data._id,
            "field": cell.colDef.field,
            "no_of_cars": no_of_cars       
        }
        params["value"] = parseInt(cell.value)

        redirectURL.post("/dashboard/updateBrmData", params).then((resp)=>{
            this.onLoadOlderFys(this.state.selectedFY.value);
        })
    }
    onCellOnTimeUpdatingStopped(cell)
    {
        try{
            cell.value = eval(cell.value);
        }catch(e){
            cell.value = 0
        }
        var inputValue = cell.value;
        var on_time_percentage;
        if(cell.colDef.field == "total_trips")
        {
            on_time_percentage = ((( cell.data.on_time_trips / inputValue)*100).toFixed(2));
            console.log(cell.value, "total_trips")
        }
        if(cell.colDef.field == "on_time_trips")
        {
            on_time_percentage = (((inputValue / cell.data.total_trips)*100).toFixed(2));
            console.log(cell.value, "on_time")

        }
        var params = {
            on_time_percentage: on_time_percentage,
            _id: cell.data._id,
            field: cell.colDef.field,
            value: cell.value
        }
        redirectURL.post("/dashboard/updateBrmOnTimeData", params).then((resp)=>{
            this.onLoadSecondChart(this.state.selectedFY.value)
        })
    }
    onCellTrailerDispatchUpdate(cell)
    {
        if(cell.value == "" || cell.value == null)
        {
            cell.value = ""
        }
        var row = cell.data;
        var total_trailers_percentage;
        var total_trailers_dispatched_percentage
        if(cell.colDef.field != "total_trailers" && cell.colDef.field != "total_trailers_dispatched")
        {
            total_trailers_percentage = parseFloat(((row.total_trailers_count/row.total_trucks_count) * 100).toFixed(2))
            total_trailers_dispatched_percentage = parseFloat(((row.total_trailers_dispatched_count/row.total_dispatches_count) * 100).toFixed(2))
        }
        else
        {
            if(cell.colDef.field == "total_trailers")
            {
                total_trailers_percentage = cell.value;
                total_trailers_dispatched_percentage = cell.data.total_trailers_dispatched
            }
            else
            {
                total_trailers_percentage =  cell.data.total_trailers;
                total_trailers_dispatched_percentage =cell.value
            }
        }
        
        var params = {
            _id: cell.data._id,
            field: cell.colDef.field,
            value: cell.value,
            total_trailers_percentage: total_trailers_percentage,
            total_trailers_dispatched_percentage: total_trailers_dispatched_percentage
        }
        console.log(params)
        redirectURL.post("/dashboard/updateTrailerDispatchData", params).then((resp)=>{
            this.onLoadThirdChart(this.state.selectedFY.value)
        })
    }
    onCellInterStockYardUpdate(cell)
    {
        if(cell.value == "" || cell.value == null)
        {
            cell.value = 0
        }
        var params = {
            _id: cell.data._id,
            field: cell.colDef.field,
            value: cell.value
        }
        redirectURL.post("/dashboard/updateInterStockYardData", params).then((resp)=>{
            this.onLoadFourthChart(this.state.selectedFY.value)
        })
    }
    onclicksaveTargetData(e){
        e.preventDefault();
        var params = {
            target_fy : this.state.target_fy,
            target_amount: this.state.target_amount
        }
        console.log(params);
        redirectURL.post("/dashboard/updateTargetFy",params).then((resp)=>{
            this.setState({
                basicTitle: "updated",
                basicType: "success",
                show: true,
                targetFY: params.target_fy,
                targetFYValue: params.target_amount
            })
        })
    }
    onclicksaveOnTimeTargetData(e){
        e.preventDefault();
        var params = {
            target: this.state.editedOnTimeTarget,
            target_fy: this.state.ontime_target_fy
        }
        console.log(params);
        redirectURL.post("/dashboard/updateTargetOnTimeFy",params).then((resp)=>{
            this.setState({
                basicTitle: "updated",
                basicType: "success",
                show: true,
                onTimetarget: this.state.editedOnTimeTarget
            })
        })
    }
    onclicksaveDispatchesTargetData(e){
        e.preventDefault();
        var params = {
            target: this.state.editedDispatchesTarget,
            target_fy: this.state.dispatches_target_fy
        }
        redirectURL.post("/dashboard/updateTargetDispatchesFy",params).then((resp)=>{
            this.setState({
                basicTitle: "updated",
                basicType: "success",
                show: true,
                trailerDispatchesTarget: this.state.editedDispatchesTarget,
            })
        })
    }
    showTab(tabno)
    {
       var tabs = {1: "showFirstChart", 2: "showSecondChart" ,3: "showThirdChart", 4: "showFourthChart", 5: "showFifthChart", 6: "showSixthLogs"}
       var collections = {1: "brmanalyticsdata", 2: "brmontime", 3: "brmdispatchtrailers", 4: "brminterstockyard", 5: "brmdamagereport", 6: "brmdrivertrainings"}
       var screenNames = {1: "Rail Mode Dispatches", 2: "On Time Trips" ,3: "Trailer Dispatches", 4: "Inter-Stockyard Move Data", 5: "Damage Report", 6 : "Carrier Driving Trainings"}
       var activeTabs = {1: "railModeActive",2: "onTimeActive", 3: "trailerActive", 4: "interplantActive", 5: "damagesActive", 6: "sixthDamagesActive"}
       for(var key in tabs)
       {
           this.setState({
               [tabs[key]] : "show-n"
           })
       }
       for(var key in activeTabs)
       {
           this.setState({
               [activeTabs[key]] : ""
           })
       }     
       this.setState({
        [activeTabs[tabno]]:"activet"
       })
       this.setState({
           [tabs[tabno]] : "",
           screenName: screenNames[tabno] 
       }) 

       redirectURL.post("/dashboard/getMonthlyDataYears",{"collection": collections[tabno]}).then((response)=>{
            var yearOptions = [];
            response.data.map(function(e){
                yearOptions.push({"label": e, "value": e})
            })
            this.setState({
                yearOptions: yearOptions
            })
        })
    }
    onClickSubmit(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        if(this.state.defaultSelectedFy.value != this.state.selectedFY)
        {
            this.setState({
                defaultSelectedFy: this.state.selectedFY
            })
            this.onLoadOlderFys(this.state.selectedFY.value);
            this.onLoadSecondChart(this.state.selectedFY.value);
            this.onLoadThirdChart(this.state.selectedFY.value);
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }
        else
        {
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }
    }
    onCellBrmDamageData(cell)
    {
        if(cell.colDef.field == "uncategorised" || cell.colDef.field == "unreturned")
        {
            // setting total actual return uncategoried + unreturned
            let rowData = [];
            this.damageGridApi.forEachNode(node => rowData.push(node.data))
            var damageRowData = rowData.filter(function(e){
                return cell.data.month.includes(e.month)
            })
            var where = {
                year: this.state.selectedFY.value,
                month: damageRowData[0].month
            }
            var newQ = {
                "$set": {
                    "tar_uncat_unret": parseInt(damageRowData[0].tar_uncat_unret)
                }
            }
            redirectURL.post("/dashboard/updateDamageReportTARData",{"where": where, "newQ": newQ}).then((response)=>{
                redirectURL.post("/dashboard/updateBrmDamageData", cell.data).then((response)=>{
                    this.onLoadFifthChart(this.state.selectedFY.value)
                })
            })
        }
        else{
            redirectURL.post("/dashboard/updateBrmDamageData", cell.data).then((response)=>{
                this.onLoadFifthChart(this.state.selectedFY.value)
            })
        }

        
        
    }
     onCellEditingStoppedDamageData(cell)
    {
        let rowData = [];
        this.damageGridApi.forEachNode(node => rowData.push(node.data));
        var currFyRow = rowData.filter(function(e){return e.month_flag === 0});
        var currFY = currFyRow[currFyRow.length - 1]['month']
        try
        {
            if(["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(cell.data.month))
            {
                if(cell.colDef.field != "total_actual_return" && cell.colDef.field != "tar_uncat_unret"
                    && cell.colDef.field != "total_domestic" && cell.colDef.field != "total_exports")
                {
                    var fydata = rowData.filter(function(e){
                        return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month) == false
                    })
                    var filtereddata = rowData.filter(function(e){
                        return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month)
                    })
                    var selectedfield = cell.colDef.field.split(".")[0]
                    var selctedSubField = cell.colDef.field.split(".")[1]
                    var val = 0
                    filtereddata.map(function(e){
                        val = val + e[selectedfield][selctedSubField]
                    })
                    console.log(val)
                    console.log(fydata)
                    fydata[fydata.length - 1][selectedfield][selctedSubField] = val
                    var rowNode1 = this.gridApi.getDisplayedRowAtIndex(fydata.length - 1);
                    fydata = fydata.concat(filtereddata)
                    
                    var rowNode2 = this.gridApi.getDisplayedRowAtIndex(cell.node.childIndex);
                    var hoverTotalField = ""
                    if(selectedfield == "cost_in_lacs_domestic")
                    {
                        hoverTotalField = "total_cost_in_lacs_domestic"
                    }
                    else if(selectedfield == "cost_in_lacs_exports")
                    {
                        hoverTotalField = "total_cost_in_lacs_exports"
                    }
                    else if(selectedfield == "no_of_vehicles_domestic")
                    {
                        hoverTotalField = "total_no_of_vehicles_domestic"
                    }
                    else if(selectedfield == "no_of_vehicles_exports")
                    {
                        hoverTotalField = "total_no_of_vehicles_exports"
                    }
                    
                    // month wise total actual return summation
                    fydata.map(function(e){
                        if(e.month == cell.data.month)
                        {
                            e.total_actual_return = e.total_no_of_vehicles_domestic + e.total_no_of_vehicles_exports
                        }
                    })
                    // current fy total actual return summation
                    var total_actual_return_fy = 0
                    fydata.map(function(e){
                        if(["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month))
                        {
                            total_actual_return_fy  = total_actual_return_fy + e.total_actual_return
                        } 
                    })
                    // setting fy total_actual_return value
                    var currFyRow = fydata.filter(function(e){return e.month_flag === 0});
                    var currFY = currFyRow[currFyRow.length - 1]['month']
                    fydata.map(function(e){
                        if(e.month == currFY)
                        {
                            e.total_actual_return = total_actual_return_fy
                        }
                    })
                    console.log(fydata,"fydata")
                    this.damageGridApi.setRowData(fydata)
                    if(hoverTotalField != "")
                    {
                        console.log(rowNode1, rowNode2)
                        // this.damageGridApi.flashCells({
                        //     rowNodes: [rowNode1, rowNode2],
                        //     columns: [cell.colDef.field, hoverTotalField],
                        // });
                    }
                }
                
               
            }
            else
            {
                console.log("in here!!!!")
            }
        }
        catch(e){
            console.log(e, "exception")
        }
      
    }
    onCellUpdatingRailModeFyData(cell)
    {
        console.log(cell)
        var year = cell.colDef.field; // fy
        var mode = cell.data.Mode; // updating mode name
        var value = parseInt(cell.value); // updating mode value
        var keys = Object.keys(cell.data)
        keys.pop()
        keys.shift()
        // calculating change %
        console.log(keys, year)
        var colIndex = cell.node.rowIndex // index of fy year for change percent usage
        var otherFyIndex = 0
        
        var selectedFyIndex = keys.indexOf(year)
        if(selectedFyIndex == 0){
            otherFyIndex = 1
        }
        var change_percent_value;
        if(selectedFyIndex > 0)
        {
            change_percent_value = Math.round((((value/cell.data[keys[otherFyIndex]]) - 1)*100))
        }
        else
        {
            change_percent_value = Math.round((((cell.data[keys[otherFyIndex]]/value) - 1)*100))
        }
        console.log(change_percent_value, "change_percent_value")
        // calculating total Road + total Rail counts
        var selectedRowIndex = cell.rowIndex;
        var otherRowIndex = 0;
        if(selectedRowIndex == 0)
        {
            otherRowIndex = 1
        }
        var total = 0
        total = parseInt(this.state.railModeSalesData[otherRowIndex][year]) + value

        // calculating change % for total row
        var total_change_percent_value=0;

        if(selectedFyIndex == 1)
        {
            total_change_percent_value = Math.round((((total/this.state.railModeSalesData[2][keys[otherFyIndex]]) - 1)*100))
        }
        else
        {
            total_change_percent_value = Math.round((((this.state.railModeSalesData[2][keys[otherFyIndex]]/total) - 1)*100))

        }
        // calculating rail contribution
        var rail_count = 0
        if(selectedRowIndex == 1)
        {
            rail_count = value
        }
        else
        {
            rail_count = this.state.railModeSalesData[1][year]
        }
        var rail_contribution = parseFloat(((rail_count/total)*100).toFixed(1))
        var params = {
            year: year,
            mode: mode,
            modevalue: value,
            change_percent_index: colIndex,
            change_percent_value: change_percent_value,
            total: total,
            total_change_percent_value: total_change_percent_value,
            rail_contribution: rail_contribution
        }
        
        redirectURL.post("/dashboard/updateRailModeFy",params).then((response)=>{
            // window.location.reload()
        })
    }
    onCellEditSixthLogs(cell)
    {
        console.log(cell)
        var colName = cell.colDef.field;
        var colValue = parseInt(cell.value);
        var acheivement_percent="";
        var year = ""
        var params = {
            colName: colName,
            colValue: colValue
        }
        if(colName.includes("Target") || colName.includes("Acheived"))
        {
            var otherValue = "";
            var objkeys = Object.keys(cell.data);
            var otherkey =""
            if(colName.includes("Target"))
            {
                objkeys.map(function(e){
                    if(e.includes("Acheived"))
                    {
                        otherkey =e
                    }
                })
                otherValue = cell.data[otherkey]
            }
            else
            {
                objkeys.map(function(e){
                    if(e.includes("Target"))
                    {
                        otherkey = e
                    }
                })
                otherValue = cell.data[otherkey]
            }
            if(otherValue != "")
            {
                if(colName.includes("Target"))
                {
                    acheivement_percent = Math.round((otherValue/colValue)*100,2)
                }
                else
                {
                    acheivement_percent = Math.round((colValue/otherValue)*100,2)
                }
                params["acheived_percent"] = acheivement_percent
            }
            year = this.state.selectedFY.value
        }
        else
        {
            year = colName.split(" ")[1].split("-")
            year = "20"+year[0]+" - 20"+year[1]
            params.colName = "target"
        }
        if(params.colName.includes("Target"))
        {
            params.colName = "target_apr_feb"
        }
        if(params.colName.includes("Acheived"))
        {
            params.colName = "acheived_apr_feb"
        }
        params.training_center = cell.data['Training Center']
        params.year = year
        var where = {
            "year": params.year,
            "training_center": params.training_center
        }
        delete params['year']
        delete params['training_center']
        var updateQuery = params
        updateQuery[params.colName] = colValue
        delete updateQuery['colName'];
        delete updateQuery['colValue'];
        
        redirectURL.post("/dashboard/updateCarrierTrainingReport",{"where": where, "updateQ": updateQuery}).then(async (response)=>{
            // window.location.reload()
            await this.onLoadSixthLogs(this.state.selectedFY.value)
        })
        
    }
    onCellUpdateDailyInterStockYard(cell)
    {
        redirectURL.post("/dashboard/updateDailyInterStockData",cell.data).then((response)=>{
            this.setState({
                basicTitle: "Updated.",
                basicType: "success",
                show: true
            })
        })
    }
    onclicksaveDamagesTargetData(e){
        e.preventDefault()
        redirectURL.post("/dashboard/updateDamagesLimitData",{"fy_limit": this.state.damagesLimit, "target_fy": this.state.damages_target_fy}).then((response)=>{
            this.setState({
                basicTitle: "Updated.",
                basicType: "success",
                show: true
            })
        })
    }
    onclickUpdateDamageReport()
    {
        try{
            let rowData = []
            this.damageGridApi.forEachNode(node => rowData.push(node.data));
            redirectURL.post("/dashboard/updateDamageData", {"rowData": rowData}).then((response)=>{
                this.setState({
                    basicTitle: "Updated Successfully.",
                    basicType: "success",
                    show: true
                })
            })
        }   
        catch(e){
            
        }
    }
    onclickUpdateDamageData(){
        let rowData = []
        this.fifthGridApi.forEachNode(node => rowData.push(node.data));
        redirectURL.post("/dashboard/updateDamageReportData", {"rowData": rowData}).then((response)=>{
            let rowData = []
            this.damageGridApi.forEachNode(node => rowData.push(node.data));
            redirectURL.post("/dashboard/updateDamageData", {"rowData": rowData}).then((response)=>{
                this.setState({
                    basicTitle: "Updated Successfully.",
                    basicType: "success",
                    show: true
                })
            })
        })
    }
    onClickApprove(collectionName)
    {
        var params = {
            "collectionName" : collectionName,
            "email": localStorage.getItem("email")
        }
        redirectURL.post("/dashboard/approveBrmDashboard", params).then((response)=>{
            this.setState({
                basicTitle: "Approved Successfully.",
                basicType: "success",
                show: true
            })
        })
    }
    onClickSaveColumnMonths(e){
        e.preventDefault();
        var formdata = {
            "from_month": this.state.logs_5_from_month,
            "to_month": this.state.logs_5_to_month
        }
        redirectURL.post("/dashboard/brmRailDispatchSaveColumnName",formdata).then(()=>{
            this.setState({
                basicTitle: "Updated",
                basicType: "success",
                show: true
            })
        })
    }
    
    onClickSaveLogs6ColumnMonths(e){
        e.preventDefault();
        var formdata = {
            "from_month": this.state.logs_6_from_month,
            "to_month": this.state.logs_6_to_month
        }
        redirectURL.post("/dashboard/brmCarrierDrivingSaveColumnName",formdata).then(()=>{
            this.setState({
                basicTitle: "Updated",
                basicType: "success",
                show: true
            })
        })
    }
    render(){
       const columnwithDefs = [
            {
               headerName: "FY / Month",
               field: "month",
               width: 145,
               editable: false,
            },
            {
                headerName: "Exports",
                field: "exports",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Domestic",
                field: "domestic",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total",
                field: "no_of_cars",
                width: 130,
                editable: false,
                enableValue: true,
                enableRowGroup: true,
            },
       ]
       const secondChartColumns = [
        {
            headerName: "FY / Month",
            field: "month",
            width: 145,
            editable: false,
         },
         {
             headerName: "Total Trips",
             field: "total_trips",
             width: 130,
             editable: true,
             enableValue: true,
            enableRowGroup: true,
         },
         {
             headerName: "On Time Trips",
             field: "on_time_trips",
             width: 130,
             editable: true,
             enableValue: true,
            enableRowGroup: true,
         },
         {
             headerName: "On Time %",
             field: "on_time_percentage",
             width: 130,
             editable: false,
             enableValue: true,
            enableRowGroup: true,
         },
       ]
       const thirChartcolumns = [
            {
                headerName: "FY / Month",
                field: "month",
                width: 145,
                editable: false,
            },
            {
                headerName: "Total Trucks",
                field: "total_trucks_count",
                width: 130,
                editable: (params)=>{
                   return (params.data.month_flag == 1)?true:false
                },
                enableValue: true,
                enableRowGroup: true,

            },
            {
                headerName: "Total Trailers",
                field: "total_trailers_count",
                width: 130,
                editable: (params)=>{
                    return (params.data.month_flag == 1)?true:false
                },
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total Trailers %",
                field: "total_trailers",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total Trucks Dispatched",
                field: "total_dispatches_count",
                width: 130,
                editable: (params)=>{
                    return (params.data.month_flag == 1)?true:false
                 },
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total Trailers Dispatched",
                field: "total_trailers_dispatched_count",
                width: 130,
                editable: (params)=>{
                    return (params.data.month_flag == 1)?true:false
                },
                enableValue: true,
                enableRowGroup: true,
            },
            {
                headerName: "Total Trailers Dispatched %",
                field: "total_trailers_dispatched",
                width: 130,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            }
       ]
       const fourthChartcolumns = [
        {
            headerName: "FY / Month",
            field: "month",
            width: 145,
            editable: false,
        },
        {
            headerName: "Moves",
            field: "moves",
            width: 145,
            editable: true,
        },{
            headerName: "Stocking Capacity At Gurgaon",
            field: "stocking_capacity_at_gurgaon",
            width: 145,
            editable: false,
        },
        {
            headerName: "Maximum Stock At Gurgaon",
            field: "maximum_stock_at_gurgoan",
            width: 145,
            editable: true,
        },
        {
            headerName: "Average Stock At Gurgaon",
            field: "average_stock_at_gurgoan",
            width: 145,
            editable: true,
        },
       ]
       const fifthChartColumns = [
            {
                headerName: "FY / Month",
                field: "month",
                width: 145,
                editable: false,
            },
            {
                headerName: "Classification",
                field: "classification",
                width: 130,
                editable: false
            },
            {
                headerName: "Category of Damage",
                children : [
                    {
                        headerName: "A",
                        field: "A",
                        width: 100,
                        editable: (params)=>{
                            if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                            {
                                return true
                            }
                            else
                            {
                                return false
                            }
                        },
                        valueGetter: function(params){
                            return params.data.no_of_vehicles["A"]
                        },
                        valueSetter: (params)=>{
                            params.data.no_of_vehicles['A'] = parseInt(parseInt(params.newValue))
                        }
                    },
                    {
                        headerName: "B",
                        field: "B",
                        width: 100,
                        editable: (params)=>{
                            if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                            {
                                return true
                            }
                            else
                            {
                                return false
                            }
                        },
                        valueGetter: function(params){
                            return params.data.no_of_vehicles["B"]
                        },
                        valueSetter: (params)=>{
                            params.data.no_of_vehicles['A'] = parseInt(parseInt(params.newValue))
                        }
                    },
                    {
                        headerName: "C",
                        field: "C",
                        width: 100,
                        editable: (params)=>{
                            if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                            {
                                return true
                            }
                            else
                            {
                                return false
                            }
                        },
                        valueGetter: function(params){
                            return params.data.no_of_vehicles["C"]
                        },
                        valueSetter: (params)=>{
                            params.data.no_of_vehicles['A'] = parseInt(parseInt(params.newValue))
                        }
                    }
                ]
            },
            {
                headerName: "Returned Vehicles",
                field: "return_vehicles",
                width : 100,
                editable: (params)=>{
                    if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                },
                valueSetter: (params)=>{
                    params.data.return_vehicles = parseInt(parseInt(params.newValue))
                }
            }
            ,
            {
                headerName: "Uncategorised",
                field: "uncategorised",
                width : 100,
                editable: true,
                valueSetter: (params)=>{
                    var damagerowdata = this.state.damageRowData
                    this.setState({
                        damageRowData: []
                    })
                    params.data.uncategorised = parseInt(parseInt(params.newValue))

                    damagerowdata.map(function(e){
                        if(params.data.month.includes(e.month))
                        {
                            e.tar_uncat_unret = e.total_actual_return + params.data.unreturned + params.data.uncategorised
                        }
                    })
                    this.setState({
                        damageRowData: damagerowdata
                    })
                }
            },
            {
                headerName: "Unreturned",
                field: "unreturned",
                width : 100,
                editable: true,
                valueSetter: (params)=>{
                    var damagerowdata = this.state.damageRowData
                    this.setState({
                        damageRowData: []
                    })
                    
                    params.data.unreturned = parseInt(parseInt(params.newValue))

                    
                    damagerowdata.map(function(e){
                        if(params.data.month.includes(e.month))
                        {
                            e.tar_uncat_unret = e.total_actual_return + params.data.unreturned + params.data.uncategorised
                        }
                    })
                    this.setState({
                        damageRowData: damagerowdata
                    })
                }
            },
            {
                headerName: "Limit",
                field: "limit",
                width : 100,
                editable: true,
                valueSetter: (params)=>{
                    params.data.limit = parseInt(parseInt(params.newValue))
                }
            },
            {
                headerName: "Cost Per Return (Rs.)",
                field: "cost_per_return",
                width : 120,
                editable: (params)=>{
                    if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            },
            {
                headerName: "Cost Per Vehicle Dispatch",
                field: "cost_per_vehicle_dispatch",
                width : 135,
                editable: (params)=>{
                    if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                },
                valueSetter: (params)=>{
                    params.data.cost_per_vehicle_dispatch = parseInt(parseInt(params.newValue))
                }
            },
            {
                headerName: "Total Cost (Lakhs)",
                field: "total_cost_in_lacs",
                width : 100,
                editable: (params)=>{
                    if(params.data.month_flag == 0 && params.data.year != this.state.selectedFY.value)
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                },
                valueSetter: (params)=>{
                    params.data.total_cost_in_lacs = parseInt(parseInt(params.newValue))
                }
            }
            
       ]
       const damageColumns = [
            {
                    headerName: "FY / Month",
                    children:[{
                        headerName: "",
                        field: "month",
                        pinned: "left",
                        width: 135,
                        editable: false,
                    }]
            },
            {
                headerName: "Domestic",
                headerClass:["cellstylegridB","cellTopHeader"],
                children: [
                    {
                        headerName: "Cost (Lakhs)",
                        headerClass:["cellstylegridB"],
                        children: [
                            {
                                headerName: "A",
                                field: "cost_in_lacs_domestic.A",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_domestic['A']
                                // },
                                valueSetter: (params)=>{
                                    // if(params.oldValue != parseInt(params.newValue))
                                    // {
                                        params.data.cost_in_lacs_domestic['A'] = parseFloat(params.newValue)
                                        params.data.total_cost_in_lacs_domestic =   parseFloat((params.data.cost_in_lacs_domestic['A'] + 
                                                                                    params.data.cost_in_lacs_domestic['B'] 
                                                                                    + params.data.cost_in_lacs_domestic['C']).toFixed(1))

                                        params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C'])

                                        params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C'])
                                        // calculating cost per vehicle
                                        var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                                (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                        
                                        let rowData = [];
                                        this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                            }
                                        });
                                        
                                        let damageRowData = []
                                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))

                                        // calculating cost per vehicle dispatch
                                        var cost_per_vehicle_dispatch = 0
                                        if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                        {
                                            // curr fy row
                                            cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                            var apr_to_oct_total = 0
                                            damageRowData.map(function(e){
                                                if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                                {
                                                    apr_to_oct_total = apr_to_oct_total + e.total
                                                }
                                            })
                                            cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                        }
                                        else
                                        {
                                            cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                        }
                                        
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                            }
                                        });

                                        // calculating total_cost in fifth grid data or damage data
                                        var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.total_cost_in_lacs = total_cost
                                            }
                                        });
                                        console.log("total_cost",total_cost)
                                        this.fifthGridApi.setRowData(rowData)

                                    // }   
                                },
                                cellClass:["cellstylegridB"],
                            },
                            {
                                headerName: "B",
                                field: "cost_in_lacs_domestic.B",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_domestic['B']
                                // },
                                valueSetter: (params)=>{
                                    params.data.cost_in_lacs_domestic['B'] = parseFloat(params.newValue)
                                    params.data.total_cost_in_lacs_domestic =   parseFloat((params.data.cost_in_lacs_domestic['A'] + 
                                                                                    params.data.cost_in_lacs_domestic['B'] 
                                                                                    + params.data.cost_in_lacs_domestic['C']).toFixed(1))
                                                                                    params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C'])

                                    params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                params.data.no_of_vehicles_exports['B'] 
                                                                                + params.data.no_of_vehicles_exports['C'])
                                    
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    // calculating cost per vehicle dispatch
                                    var cost_per_vehicle_dispatch = 0
                                    if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                    {
                                        // curr fy row
                                        cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                        let damageRowData = []
                                        var apr_to_oct_total = 0
                                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                                        damageRowData.map(function(e){
                                            if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                            {
                                                apr_to_oct_total = apr_to_oct_total + e.total
                                            }
                                        })
                                        cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                    }
                                    else
                                    {
                                        cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                    }
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                        }
                                    });

                                    // calculating total_cost in fifth grid data or damage data
                                    var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.total_cost_in_lacs = total_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                },
                                cellClass:["cellstylegridB"],
                            },
                            {
                                headerName: "C",
                                field: "cost_in_lacs_domestic.C",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_domestic['C']
                                // },
                                valueSetter: (params)=>{
                                    // if(params.oldValue != params.newValue)
                                    // {
                                        params.data.cost_in_lacs_domestic['C'] = parseFloat(params.newValue)
                                        params.data.total_cost_in_lacs_domestic =   parseFloat((params.data.cost_in_lacs_domestic['A'] + 
                                                                                    params.data.cost_in_lacs_domestic['B'] 
                                                                                    + params.data.cost_in_lacs_domestic['C']).toFixed(1))
                                        params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C'])

                                        params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C'])
                                        
                                        var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                                (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                        
                                        let rowData = [];
                                        this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                            }
                                        });
                                        // calculating cost per vehicle dispatch
                                        var cost_per_vehicle_dispatch = 0
                                        if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                        {
                                            // curr fy row
                                            cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                            let damageRowData = []
                                            var apr_to_oct_total = 0
                                            this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                                            damageRowData.map(function(e){
                                                if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                                {
                                                    apr_to_oct_total = apr_to_oct_total + e.total
                                                }
                                            })
                                            cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                        }
                                        else
                                        {
                                            cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                        }
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                            }
                                        });

                                        // calculating total_cost in fifth grid data or damage data
                                        var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                        console.log(total_cost, "total_costtotal_cost")
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.total_cost_in_lacs = total_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    // }
                                    
                                },
                                cellClass:["cellstylegridB"],

                            },
                        ]
                    },
                    {
                        headerName: "Total Cost",
                        headerClass:["cellstylegridB"],
                        children: [
                            {
                                headerName: "",
                                field: "total_cost_in_lacs_domestic",
                                width: 110,
                                editable: false,
                                cellClass:["cellstylegridB"],
                                valueGetter: function(params){
                                    return  parseFloat((params.data.cost_in_lacs_domestic['A'] + 
                                            params.data.cost_in_lacs_domestic['B'] +
                                            params.data.cost_in_lacs_domestic['C']).toFixed(1))
                                },
                                // valueSetter: (params)=>{
                                //      (params.data.cost_in_lacs_domestic['A'] + 
                                //             params.data.cost_in_lacs_domestic['B'] +
                                //             params.data.cost_in_lacs_domestic['C'])
                                // }
                            }
                        ]
                    },
                    {
                        headerName: "No. of Vehicles",
                        headerClass:["cellstylegridB"],
                        children: [
                            {
                                headerName: "A",
                                field: "no_of_vehicles_domestic.A",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.no_of_vehicles_domestic['A']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_domestic['A'] = parseInt(params.newValue)
                                    // calculating total no .of vehicles in domestic
                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']

                                    params.data.total_no_of_vehicles_exports =   params.data.no_of_vehicles_exports['A'] + 
                                                                                params.data.no_of_vehicles_exports['B'] 
                                                                                + params.data.no_of_vehicles_exports['C']
                                    
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    // calculating total actual return
                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return
                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_a_cost = Math.round((params.data.no_of_vehicles_domestic['A'] + params.data.no_of_vehicles_exports['A'])/params.data.total*100000)
                                        // console.log(params.da    ta.no_of_vehicles_domestic['A'], params.data.no_of_vehicles_exports['A'], params.data.total, cat_a_cost, "dec cat a")
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                console.log(e, cat_a_cost)
                                                e.no_of_vehicles['A'] = cat_a_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridB"],
                            },
                            {
                                headerName: "B",
                                field: "no_of_vehicles_domestic.B",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.no_of_vehicles_domestic['B']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_domestic['B'] = parseInt(params.newValue)
                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']
                                    
                                    params.data.total_no_of_vehicles_exports =   params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C']
                                    
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)

                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                        
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)

                                    // calculating total actual return
                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return         

                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_b_cost = Math.round((params.data.no_of_vehicles_domestic['B'] + params.data.no_of_vehicles_exports['B'])/params.data.total*100000)
                                        console.log(params.data.no_of_vehicles_domestic['B'], params.data.no_of_vehicles_exports['B'],params.data.total, cat_b_cost)
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.no_of_vehicles['B'] = cat_b_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridB"],
                            },
                            {
                                headerName: "C",
                                field: "no_of_vehicles_domestic.C",
                                width: 110,
                                editable: true,
                                // valueGetter: (params)=>{
                                //     return params.data.no_of_vehicles_domestic['C']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_domestic['C'] = parseInt(params.newValue)
                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']
                                    
                                                                        
                                    params.data.total_no_of_vehicles_exports =   params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C']
                                    
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                        
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    
                                    // calculating total actual return
                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return
                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_c_cost = Math.round((params.data.no_of_vehicles_domestic['C'] + params.data.no_of_vehicles_exports['C'])/params.data.total*100000)
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.no_of_vehicles['C'] = cat_c_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridB"],
                            },
                        ]
                    },
                    {
                        headerName: "Total No. of Vehicles",
                        headerClass:["cellstylegridB"],
                        children: [
                            {
                                headerName: "",
                                field: "total_no_of_vehicles_domestic",
                                width: 110,
                                editable: false,
                                cellClass:["cellstylegridB"],
                                valueGetter: (params)=>{
                                    return  (params.data.no_of_vehicles_domestic['A'] + 
                                            params.data.no_of_vehicles_domestic['B'] +
                                            params.data.no_of_vehicles_domestic['C'])
                                },
                            }
                        ]
                    },
                    
                ]
            },
            {
                headerName: "Exports",
                headerClass:["cellstylegridG","cellTopHeader"],
                children: [
                    {
                        headerName: "Cost (Lakhs)",
                        headerClass:["cellstylegridG"],
                        children: [
                            {
                                headerName: "A",
                                field: "cost_in_lacs_exports.A",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_exports['A']
                                // },
                                valueSetter: (params)=>{
                                    params.data.cost_in_lacs_exports['A'] = parseFloat(params.newValue)
                                    params.data.total_cost_in_lacs_exports =   parseFloat((params.data.cost_in_lacs_exports['A'] + 
                                                                                    params.data.cost_in_lacs_exports['B'] 
                                                                                    + params.data.cost_in_lacs_exports['C']).toFixed(1))

                                    params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C'])

                                    params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                params.data.no_of_vehicles_exports['B'] 
                                                                                + params.data.no_of_vehicles_exports['C'])
                                    
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    // calculating cost per vehicle dispatch
                                    var cost_per_vehicle_dispatch = 0
                                    if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                    {
                                        // curr fy row
                                        cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                        let damageRowData = []
                                        var apr_to_oct_total = 0
                                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                                        damageRowData.map(function(e){
                                            if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                            {
                                                apr_to_oct_total = apr_to_oct_total + e.total
                                            }
                                        })
                                        cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                    }
                                    else
                                    {
                                        cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                    }
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                        }
                                    });

                                    // calculating total_cost in fifth grid data or damage data
                                    var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.total_cost_in_lacs = total_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                },
                                cellClass:["cellstylegridG"],
                            },
                            {
                                headerName: "B",
                                field: "cost_in_lacs_exports.B",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_exports['B']
                                // },
                                valueSetter: (params)=>{
                                    params.data.cost_in_lacs_exports['B'] = parseFloat(params.newValue)
                                    params.data.total_cost_in_lacs_exports =   parseFloat((params.data.cost_in_lacs_exports['A'] + 
                                                                                    params.data.cost_in_lacs_exports['B'] 
                                                                                    + params.data.cost_in_lacs_exports['C']).toFixed(1))
                                    params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                params.data.no_of_vehicles_domestic['B'] 
                                                                                + params.data.no_of_vehicles_domestic['C'])

                                    params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                params.data.no_of_vehicles_exports['B'] 
                                                                                + params.data.no_of_vehicles_exports['C'])
                                    
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    // calculating cost per vehicle dispatch
                                    var cost_per_vehicle_dispatch = 0
                                    if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                    {
                                        // curr fy row
                                        cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                        let damageRowData = []
                                        var apr_to_oct_total = 0
                                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                                        damageRowData.map(function(e){
                                            if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                            {
                                                apr_to_oct_total = apr_to_oct_total + e.total
                                            }
                                        })
                                        cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                    }
                                    else
                                    {
                                        cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                    }
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                        }
                                    });
                                    // calculating total_cost in fifth grid data or damage data
                                    var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.total_cost_in_lacs = total_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                },
                                cellClass:["cellstylegridG"],
                            },
                            {
                                headerName: "C",
                                field: "cost_in_lacs_exports.C",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.cost_in_lacs_exports['C']
                                // },
                                valueSetter: (params)=>{
                                    params.data.cost_in_lacs_exports['C'] = parseFloat(params.newValue)
                                    params.data.total_cost_in_lacs_exports =   parseFloat((params.data.cost_in_lacs_exports['A'] + 
                                                                                    params.data.cost_in_lacs_exports['B'] 
                                                                                    + params.data.cost_in_lacs_exports['C']).toFixed(1))
                                    params.data.total_no_of_vehicles_domestic =   parseInt(params.data.no_of_vehicles_domestic['A'] + 
                                                                                params.data.no_of_vehicles_domestic['B'] 
                                                                                + params.data.no_of_vehicles_domestic['C'])

                                    params.data.total_no_of_vehicles_exports =   parseInt(params.data.no_of_vehicles_exports['A'] + 
                                                                                params.data.no_of_vehicles_exports['B'] 
                                                                                + params.data.no_of_vehicles_exports['C'])
                                    
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    // calculating cost per vehicle dispatch
                                    var cost_per_vehicle_dispatch = 0
                                    if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                                    {
                                        // curr fy row
                                        cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                                        let damageRowData = []
                                        var apr_to_oct_total = 0
                                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                                        damageRowData.map(function(e){
                                            if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                            {
                                                apr_to_oct_total = apr_to_oct_total + e.total
                                            }
                                        })
                                        cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                                    }
                                    else
                                    {
                                        cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                                    }
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                                        }
                                    });
                                    // calculating total_cost in fifth grid data or damage data
                                    var total_cost = params.data.total_cost_in_lacs_exports + params.data.total_cost_in_lacs_domestic
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.total_cost_in_lacs = total_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                },
                                cellClass:["cellstylegridG"],
                            },
                        ]
                    },
                    {
                        headerName: "Total Cost",
                        headerClass:["cellstylegridG"],
                        children: [
                            {
                                headerName: "",
                                field: "total_cost_in_lacs_exports",
                                width: 110,
                                editable: false,
                                cellClass:["cellstylegridG"],
                                valueGetter: function(params){
                                    return  parseFloat((params.data.cost_in_lacs_exports['A'] + 
                                            params.data.cost_in_lacs_exports['B'] +
                                            params.data.cost_in_lacs_exports['C']).toFixed(1))
                                },
                            }
                        ]
                    },
                    {
                        headerName: "No. of Vehicles",
                        headerClass:["cellstylegridG"],
                        children: [
                            {
                                headerName: "A",
                                field: "no_of_vehicles_exports.A",
                                width: 110,
                                editable: true,
                                // valueGetter: function(params){
                                //     return params.data.no_of_vehicles_exports['A']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_exports['A'] = parseInt(params.newValue)
                                    params.data.total_no_of_vehicles_exports =   (params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C'])
                                                                        
                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                        
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    
                                    // calculating total actual return

                                                
                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return 

                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_a_cost = Math.round((params.data.no_of_vehicles_domestic['B'] + params.data.no_of_vehicles_exports['B'])/params.data.total*100000)
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.no_of_vehicles['B'] = cat_a_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridG"],
                            },
                            {
                                headerName: "B",
                                field: "no_of_vehicles_exports.B",
                                width: 110,
                                editable: true,
                                // valueGetter: (params)=>{
                                //     return params.data.no_of_vehicles_exports['B']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_exports['B'] = parseInt(params.newValue)
                                    params.data.total_no_of_vehicles_exports =   (params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C'])
                                    
                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']
                                    
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                        
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    
                                    // calculating total actual return

                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return   

                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_b_cost = Math.round((params.data.no_of_vehicles_domestic['B'] + params.data.no_of_vehicles_exports['B'])/params.data.total*100000)
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.no_of_vehicles['B'] = cat_b_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridG"],
                            },
                            {
                                headerName: "C",
                                field: "no_of_vehicles_exports.C",
                                width: 110,
                                editable: true,
                                // valueGetter: (params)=>{
                                //     return params.data.no_of_vehicles_exports['C']
                                // },
                                valueSetter: (params)=>{
                                    params.data.no_of_vehicles_exports['C'] = parseInt(params.newValue)
                                    params.data.total_no_of_vehicles_exports =   params.data.no_of_vehicles_exports['A'] + 
                                                                                    params.data.no_of_vehicles_exports['B'] 
                                                                                    + params.data.no_of_vehicles_exports['C']

                                    params.data.total_no_of_vehicles_domestic =   params.data.no_of_vehicles_domestic['A'] + 
                                                                                    params.data.no_of_vehicles_domestic['B'] 
                                                                                    + params.data.no_of_vehicles_domestic['C']
                                    
                                    // calculating cost per vehicle
                                    var cost_per_vehicle = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports) /
                                                            (params.data.total_no_of_vehicles_domestic + params.data.total_no_of_vehicles_exports)
                                    
                                    let rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.cost_per_return = parseFloat(cost_per_vehicle.toFixed(1))
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                        
                                    // getting data of damage grid or fifth grid data
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    // calculating total returned vehicles cost in fifth grid row data
                                    var total_vehicles_cost = Math.round(((params.data.total_no_of_vehicles_domestic + 
                                                                params.data.total_no_of_vehicles_exports + 
                                                                filtered[0].uncategorised + 
                                                                filtered[0].unreturned)/params.data.total)*100000)
                                    rowData.map(function(e){
                                        if(e.month.includes(params.data.month))
                                        {
                                            e.return_vehicles = total_vehicles_cost
                                        }
                                    });
                                    this.fifthGridApi.setRowData(rowData)
                                    
                                    // calculating total actual return
                                    var total_actual_return = 0
                                    var fields = ['no_of_vehicles_exports',"no_of_vehicles_domestic"]
                                    fields.map(function(e){
                                        for(var key in params.data[e])
                                        {
                                            total_actual_return = total_actual_return + params.data[e][key]
                                        }
                                    })
                                    params.data.total_actual_return = total_actual_return

                                    // calculating total_actual_return_uncategoried + unreturned
                                    rowData = [];
                                    this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                                    var filtered = rowData.filter(function(e){
                                        return e.month.includes(params.data.month)
                                    })
                                    params.data.tar_uncat_unret = params.data.total_actual_return + filtered[0].uncategorised + filtered[0].unreturned
                                    // calculating category wise each vehicle damage cost in above grid (fifthrowdata) for curr fy row and curr fy months
                                    if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                                    {
                                        var cat_c_cost = Math.round((params.data.no_of_vehicles_domestic['B'] + params.data.no_of_vehicles_exports['B'])/params.data.total*100000)
                                        rowData.map(function(e){
                                            if(e.month.includes(params.data.month))
                                            {
                                                e.no_of_vehicles['B'] = cat_c_cost
                                            }
                                        });
                                        this.fifthGridApi.setRowData(rowData)
                                    }
                                },
                                cellClass:["cellstylegridG"],
                            },
                        ]
                    },
                    {
                        headerName: "Total No. of Vehicles",
                        headerClass:["cellstylegridG"],
                        children: [
                            {
                                headerName: "",
                                field: "total_no_of_vehicles_exports",
                                width: 110,
                                editable: false,
                                cellClass:["cellstylegridG"],
                                valueGetter: function(params){
                                    return  (params.data.no_of_vehicles_exports['A'] + 
                                            params.data.no_of_vehicles_exports['B'] +
                                            params.data.no_of_vehicles_exports['C'])
                                },
                            }
                        ]
                    },

                    
                ]
            },
            {
                headerName: "Actual Return",
                children:[{
                    headerName: "",
                    field: "total_actual_return",
                    width: 110,
                    editable: (params)=>{
                        if(params.data.month_flag == 1 || params.data.year == this.state.selectedFY.value)
                        {
                            return false
                        }
                        else{
                            return true
                        }
                    },
                    
                }]
            },
            {
                headerName: "Actual Return (Uncat + Unret)",
                children:[{
                    headerName: "",
                    field: "tar_uncat_unret",
                    width: 140,
                    editable: true,
                }]
            },
            {
                headerName: "Domestic",
                children:[{
                    headerName: "",
                    field: "total_domestic",
                    width: 110,
                    editable: true,
                    valueSetter: (params)=>{
                        params.data.total_domestic = parseInt(params.newValue)
                        params.data.total = parseInt(params.data.total_domestic) + parseInt(params.data.total_exports)

                        let damageRowData = []
                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                        // updating FY Row domestic sum value
                        // getting curr & prev fy rows
                        var fydata = damageRowData.filter(function(e){
                            return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month) == false
                        })
                        // getting curr fy months rows
                        var filtereddata = damageRowData.filter(function(e){
                            return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month)
                        })
                        var total_domestic_fy = 0
                        filtereddata.map(function(e){
                            total_domestic_fy = total_domestic_fy + e.total_domestic
                        })
                        fydata[fydata.length - 1]["total_domestic"] = total_domestic_fy
                        fydata = fydata.concat(filtereddata)
                        // calculating cost per vehicle dispatch
                        var cost_per_vehicle_dispatch = 0
                        if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                        {
                            // curr fy row
                            cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                            let damageRowData = []
                            var apr_to_oct_total = 0
                            this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                            damageRowData.map(function(e){
                                if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                {
                                    apr_to_oct_total = apr_to_oct_total + e.total
                                }
                            })
                            cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                        }
                        else
                        {
                            cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                        }
                        console.log(cost_per_vehicle_dispatch,"cost_per_vehicle_dispatch")
                        let rowData = []
                        this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                        rowData.map(function(e){
                            if(e.month.includes(params.data.month))
                            {
                                e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                            }
                        });
                        this.fifthGridApi.setRowData(rowData)
                        this.damageGridApi.setRowData(fydata)
                    }
                }]
            },
            {
                headerName: "Exports",
                children:[{
                    headerName: "",
                    field: "total_exports",
                    width: 110,
                    editable: true,
                    valueSetter: (params)=>{
                        params.data.total_exports = parseInt(params.newValue)
                        params.data.total = parseInt(params.data.total_domestic) + parseInt(params.data.total_exports)
                        // updating FY Row domestic sum value
                        let damageRowData = []
                        this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                        // getting curr & prev fy rows
                        var fydata = damageRowData.filter(function(e){
                            return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month) == false
                        })
                        // getting curr fy months rows
                        var filtereddata = damageRowData.filter(function(e){
                            return ["Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Jan","Feb","Mar"].includes(e.month)
                        })
                        var total_exports_fy = 0
                        filtereddata.map(function(e){
                            total_exports_fy = total_exports_fy + e.total_exports
                        })
                        fydata[fydata.length - 1]["total_exports"] = total_exports_fy
                        fydata = fydata.concat(filtereddata)

                        // calculating cost per vehicle dispatch
                        var cost_per_vehicle_dispatch = 0
                        if(params.data.year == this.state.selectedFY.value && params.data.month_flag == 0)
                        {
                            // curr fy row
                            cost_per_vehicle_dispatch = (params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)
                            let damageRowData = []
                            var apr_to_oct_total = 0
                            this.damageGridApi.forEachNode(node => damageRowData.push(node.data))
                            damageRowData.map(function(e){
                                if(["Apr","May","Jun","Jul","Aug","Sep","Oct"].includes(e.month))
                                {
                                    apr_to_oct_total = apr_to_oct_total + e.total
                                }
                            })
                            cost_per_vehicle_dispatch = Math.round((cost_per_vehicle_dispatch / apr_to_oct_total))
                        }
                        else
                        {
                            cost_per_vehicle_dispatch = Math.round(((params.data.total_cost_in_lacs_domestic + params.data.total_cost_in_lacs_exports)/params.data.total))

                        }
                        let rowData = []
                        this.fifthGridApi.forEachNode(node => rowData.push(node.data))
                        rowData.map(function(e){
                            if(e.month.includes(params.data.month))
                            {
                                e.cost_per_vehicle_dispatch = Math.round(cost_per_vehicle_dispatch)
                            }
                        });
                        this.fifthGridApi.setRowData(rowData)
                    }
                }]
            },
            {
                headerName: "Total",
                children:[{
                    headerName: "",
                    field: "total",
                    width: 110,
                    editable: false,
                }]
            },
       ]
       const interStockDailyColumns = [
           {
               headerName: "Stockyard",
               field: "stockyard",
               colId: "stockyard",
               width: 140,
               editable: false
           },
           {
                headerName: "Stock Date",
                field: "stock_date",
                colId: "stock_date",
                sortable: true,
                width: 140,
                editable: false,
                valueGetter: function(params){
                    return getHyphenYYYYMMDD(params.data.stock_date)
                }
            },
            {
                headerName: "No. of stocks",
                field: "no_of_stocks",
                colId: "no_of_stocks",
                width: 140,
                editable: true,
                valueSetter: (params)=>{
                    if(params.oldValue != params.newValue)
                    {
                        params.data.no_of_stocks = params.newValue
                    }
                }
            }
       ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className={"row col-xl-12 col-lg-12 "}>
                            {/* <ul className="dashboard-page" style={{left:"-69px",top:"-16px"}}>
                                <li className="btn-group">
                                    <a href="javascript:;"  className="loadclickm" id="load">
                                        <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />
                                    
                                        <ul className="dashboard-sub" style={{"display":"none"}}>
                                            <li >
                                                <span onClick={this.showTab.bind(this,1)}>
                                                    Rail Mode Dispatch
                                                </span>
                                            </li>
                                            <li >
                                                <span onClick={this.showTab.bind(this,2)}>
                                                    On Time Dashboard
                                                </span>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>	 */}
                            {(this.state.userType == "user")?
                            <span>
                                {(localStorage.getItem("roles").includes("tpt_rail") == false && this.props.match.path != "/railbrmdashboard")?
                                    <span>
                                        <button className={"btn btn-default "+this.state.trailerActive} onClick={this.showTab.bind(this,3)}>Dispatched Trailers</button>
                                        <button className={"btn btn-default "+this.state.onTimeActive} onClick={this.showTab.bind(this,2)}>On Time Dashboard</button>
                                        <button className={"btn btn-default "+this.state.damagesActive} onClick={this.showTab.bind(this,5)}>Damages Report</button>
                                        <button className={"btn btn-default "+this.state.railModeActive} onClick={this.showTab.bind(this,1)}>Rail Mode Dispatch</button>
                                        <button className={"btn btn-default "+this.state.interplantActive} onClick={this.showTab.bind(this,4)}>Inter-Stockyard Move Data</button>
                                        <button className={"btn btn-default "+this.state.sixthDamagesActive} onClick={this.showTab.bind(this,6)}>Carrier Driving Trainings</button>
                                    </span>
                                :                                
                                <button className={"btn btn-default "+this.state.railModeActive} onClick={this.showTab.bind(this,1)}>Rail Mode Dispatch</button>
                            }
                            </span>
                            :""}
                            

                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-pie-chart cus-i"></i> <span>Logistics BRM Report - { this.state.screenName }</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                {/* <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-3 col-lg-3">
                                        <label>Select Financial Year : </label>
                                        <Select 
                                            closeMenuOnSelect={true} 
                                            value={this.state.selectedFY} 
                                            className="border-radius-0" 
                                            onChange={(e)=>{this.setState({selectedFY: e})}} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.state.yearOptions} required
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-2">
                                        <br />
                                        <button className="btn btn-success" onClick={this.onClickSubmit.bind(this)}>Submit</button>
                                    </div>
                                </div> */}
                                
                                <div className={"row "+this.state.showFirstChart}>
                                    {/*1st chart*/}
                                    <div className={"col-xl-12 col-lg-12"}>
                                        <div className="col-xl-6 col-lg-6" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 4</h6>
                                        </div>
                                        {(this.state.raildispatchdata.length > 0)?
                                            ((this.state.raildispatchdata[0].message != "no data")?
                                                (this.state.raildispatchdata.map(item => 
                                                    <RailFinancialColumnLineChart 
                                                        title={"Rail Mode Dispatches"}
                                                        subtitle={item.subtitle}
                                                        context={this}
                                                        series={item.series}
                                                        categories={item.categories}
                                                        xtitle={item.xtitle}
                                                        ytitle={item.ytitle}
                                                        yaxistitle={item.yaxistitle}
                                                    />))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Rail Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Rail Mode Dispatches  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                            }
                                       
                                    </div>
                                    {(this.state.userType == "user")?
                                        <span className='row col-xl-12 col-lg-12'>
                                        <form id="targetform" className="col-xl-4 col-lg-4" onSubmit={this.onclicksaveTargetData.bind(this)}>
                                                <div className="form-group">
                                                    <label>Target Financial Year:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> &nbsp;20-21, 2020-2021</span></label>
                                                    <input type="text" className="form-control" onChange={(e)=>{this.setState({target_fy: e.target.value})}}  required/>
                                                </div>
                                                <div className="form-group ">
                                                    <label>Target Amount: <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> &nbsp;1.75 Lakhs, 2.50 Lakhs</span></label>
                                                    <input type="text" className="form-control" onChange={(e)=>{this.setState({target_amount: e.target.value})}} required/>
                                                </div>
                                                
                                                <div className="form-group">
                                                    <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                                </div>
                                                
                                        </form>
                                        <div className="col-xl-8 col-lg-8">
                                            <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brmanalyticsdata")}>Approve</button>
                                            <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={false}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    enableRangeSelection={true}
                                                    components={this.state.components}
                                                    rowSelection={this.state.rowSelection}
                                                    suppressRowClickSelection={true}
                                                    onRowSelected={this.onRowSelected.bind(this)}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    onCellEditingStopped={this.onCellUpdatingStopped.bind(this)}
                                                />
                                            </div>
                                        </div> 
                                        </span>
                                    :""}
                                    
                                    <div id="myGrid" style={{ height: "250px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.railModeSalesColumns}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.railModeSalesData}
                                            enableCharts={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            components={this.state.components}
                                            rowSelection={this.state.rowSelection}
                                            rowClassRules={{
                                                "highlitRow": "data.Mode == 'Rail Contribution'"
                                            }}
                                            // suppressRowClickSelection={true}
                                            // onRowSelected={this.onRowSelected.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellEditingStopped={this.onCellUpdatingRailModeFyData.bind(this)}
                                        />
                                    </div>
                                    <form id="targetform" className="col-xl-4 col-lg-4" onSubmit={this.onClickSaveColumnMonths.bind(this)}>
                                            <div className="form-group">
                                                <label>Till Month:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> </span></label>
                                                <input type="text" className="form-control" onChange={(e)=>{this.setState({logs_5_from_month: e.target.value})}}  required/>
                                            </div>
                                            
                                            
                                            <div className="form-group">
                                                <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                            </div>
                                            
                                    </form>
                                </div>
                                {(this.state.showSecondChart == "")?
                                    <div className={"row "+this.state.showSecondChart}>
                                        <div className="col-xl-8 col-lg-8" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 2</h6>
                                        </div>
                                        <div className="col-xl-4 col-lg-4" style={{border: "1px solid",background: "#ccffcc" }}>
                                            <h6 style={{textDecoration:"underline",fontSize: '11px', paddingTop:"5px"}}>Target For {this.state.ontime_target_fy} :- {this.state.onTimetarget}</h6>
                                        </div>
                                    {/*1st chart*/}
                                       
                                        <div className={"col-xl-12 col-lg-12"}>
                                            {(this.state.secondChartArr.length > 0)?
                                                ((this.state.secondChartArr[0].message != "no data")?
                                                    (this.state.secondChartArr.map(item => 
                                                        <RailBRMColumnLineChart 
                                                            title={"% On Time (Domestic)"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5> On Time {this.state.finyear}</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Rail On time  {this.state.finyear}</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                                }
                                        
                                        </div>
                                        {(this.state.userType == "user")?
                                            <span className='row col-xl-12 col-lg-12' style={{padding: "0em 4em 0em 4em"}}>
                                             <form id="ontimetargetform" className="col-xl-4 col-lg-4" onSubmit={this.onclicksaveOnTimeTargetData.bind(this)}>
                                                    <div className="form-group">
                                                        <label>Target Financial Year:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> &nbsp;20-21, 2020-2021</span></label>
                                                        <input type="text" className="form-control" onChange={(e)=>{this.setState({ontime_target_fy: e.target.value})}}  required/>
                                                    </div>
                                                    <div className="form-group ">
                                                        <label>Target : <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                                        <input type="text" className="form-control" onChange={(e)=>{this.setState({editedOnTimeTarget: e.target.value})}} required/>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                                    </div>
                                            </form>
                                            <div className="col-xl-8 col-lg-8">
                                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                    <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brmontime")}>Approve</button>
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={secondChartColumns}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.secondChartRowData}
                                                        enableCharts={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        components={this.state.components}
                                                        rowSelection={this.state.rowSelection}
                                                        suppressRowClickSelection={true}
                                                        onRowSelected={this.onRowSelected.bind(this)}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        onCellEditingStopped={this.onCellOnTimeUpdatingStopped.bind(this)}
                                                    />
                                                </div>
                                            </div> 
                                            </span>
                                        :""}
                                        
                                        <div className="form-group pull-right col-xl-9 col-lg-9"></div>
                                    </div>
                                :""}
                                {(this.state.showThirdChart == "")?
                                    <div className={"row "+this.state.showThirdChart}>
                                    {/*1st chart*/}
                                    <div className={"row col-xl-12 col-lg-12"}>
                                        <div className="col-xl-6 col-lg-6" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 1</h6>
                                        </div>
                                        <div className="col-xl-6 col-lg-6" style={{border: "1px solid",background: "#ccffcc",paddingTop:"5px",}}>
                                            <h6 style={{ fontSize:"11px"}} ><b style={{textDecoration:"underline"}}>Target For {this.state.dispatches_target_fy} :-</b><br /> {this.state.trailerDispatchesTarget}</h6>
                                        </div>
                                        {(this.state.thirdChartArr.length > 0)?
                                            ((this.state.thirdChartArr[0].message != "no data")?
                                                (this.state.thirdChartArr.map(item => 
                                                    
                                                    <div className="col-xl-12 col-lg-12">
                                                        <RailFinancialColumnLineChart 
                                                            title={"% Trailer Dispatches"}
                                                            subtitle={item.subtitle}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />
                                                    </div>

                                                ))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Trailer Dispatches  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                            }
                                    
                                    </div>
                                    <div className='row col-xl-12 col-lg-12'>
                                        <form id="dispatchestargetform" className="col-xl-4 col-lg-4" onSubmit={this.onclicksaveDispatchesTargetData.bind(this)}>
                                            <div className="form-group">
                                                <label>Target Financial Year:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> &nbsp;20-21, 2020-2021</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>{this.setState({dispatches_target_fy: e.target.value})}}  required/>
                                            </div>
                                            <div className="form-group ">
                                                <label>Target : <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                                <input type="text" className="form-control" onChange={(e)=>{this.setState({editedDispatchesTarget: e.target.value})}} required/>
                                            </div>
                                            
                                            <div className="form-group">
                                                <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                            </div>
                                        </form>
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham col-xl-8 col-lg-8">
                                            <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brmdispatchtrailers")}>Approve</button>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={thirChartcolumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.thirdChartRowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                onCellEditingStopped={this.onCellTrailerDispatchUpdate.bind(this)}
                                            />
                                        </div>
                                    </div>                                    
                                    {/* <div className="row col-xl-12 col-lg-12" style={{marginTop: "2em"}}>
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham col-xl-7 col-lg-7">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.trailerDispatchesSummaryColumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.trailerDispatchesSummaryRowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                // onGridReady={this.onGridReady}
                                                // onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                rowClassRules={{
                                                    "cellstylegridDY": "data.route_name == 'Total'"
                                                }}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                            />
                                        </div>
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham col-xl-5 col-lg-5">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.trailerDispatchesSummaryPerColumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.trailerDispatchesSummaryRowData}
                                                enableCharts={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                // onGridReady={this.onGridReady}
                                                // onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                rowClassRules={{
                                                    "cellstylegridDY": "data.route_name == 'Total'"
                                                }}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    
                                </div>:""    
                                }
                                {(this.state.showFourthChart == "")?
                                    <div className={"row "+this.state.showFourthChart} >
                                        <div className="col-xl-6 col-lg-6" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 5</h6>
                                        </div>

                                        <div className={"col-xl-12 col-lg-12"}>
                                            {(this.state.fourthChartArr.length > 0)?
                                                ((this.state.fourthChartArr[0].message != "no data")?
                                                    (this.state.fourthChartArr.map(item => 
                                                        <RailFinancialColumnLineChart 
                                                            title={"Inter-Stockyard Moves"}
                                                            subtitle={""}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />
                                                        
                                                    ))
                                                        
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Trailer Dispatches FY {this.state.finyear}</h5>No Data</div>
                                                )
                                                
                                            :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Trailer Dispatches  {this.state.finyear}</h5>
                                                    <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span>
                                                </div>
                                            }
                                            <div className="col-xl-12 col-lg-12" >
                                                <h5>* Inter-Stockyard move data excludes D21 project volumes wef Oct'20</h5>
                                            </div>
                                        </div>
                                        {(this.state.userType == "user")?
                                            <div className='row col-xl-12 col-lg-12'>
                                                <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brminterstockyard")}>Approve</button>

                                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={fourthChartcolumns}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.fourthChartRowData}
                                                        enableCharts={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        components={this.state.components}
                                                        rowSelection={this.state.rowSelection}
                                                        suppressRowClickSelection={true}
                                                        onRowSelected={this.onRowSelected.bind(this)}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        onCellEditingStopped={this.onCellInterStockYardUpdate.bind(this)}
                                                    />
                                                </div>
                                                <div className="col-xl-12 col-lg-12">
                                                    <h5>Current Month Daily Stocks At GGN</h5>
                                                </div>
                                                <div id="myGrid1" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={interStockDailyColumns}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.interStockDailyData}
                                                        enableCharts={false}
                                                        onGridReady={this.onISGridReady}
                                                        onGridState={this.onISGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        components={this.state.components}
                                                        rowSelection={this.state.rowSelection}
                                                        suppressRowClickSelection={true}
                                                        rowClassRules={{
                                                            "highlitRow": "data.curr_day == 1"
                                                        }}
                                                        // onRowSelected={this.onRowSelected.bind(this)}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        onCellEditingStopped={this.onCellUpdateDailyInterStockYard.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        :""}
                                        
                                    </div>:""    
                                }
                                {(this.state.showFifthChart == "")?
                                    <div className={"row "+this.state.showFifthChart}>
                                        <div className="col-xl-7 col-lg-7" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 3</h6>
                                        </div>
                                        <div className="col-xl-5 col-lg-5" style={{border: "1px solid",background: "#ccffcc",paddingTop:"5px",}}>
                                            <h6 style={{ fontSize:"11px"}} ><b style={{textDecoration:"underline"}}>Target For {this.state.damages_target_fy} :-</b> {this.state.damagesLimit}</h6>
                                        </div>
                                        <div className={"col-xl-12 col-lg-12"}>
                                            {(this.state.fifthChartArr.length > 0)?
                                                ((this.state.fifthChartArr[0].message != "no data")?
                                                    (this.state.fifthChartArr.map(item => 
                                                        <RailFinancialColumnLineChart 
                                                            title={"Damages(External) - per lakh dispatches"}
                                                            subtitle={""}
                                                            context={this}
                                                            series={item.series}
                                                            categories={item.categories}
                                                            xtitle={item.xtitle}
                                                            ytitle={item.ytitle}
                                                            yaxistitle={item.yaxistitle}
                                                        />))
                                                    :
                                                    <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                        <h5>Damages Report FY {this.state.finyear}</h5>No Data</div>
                                                )
                                                
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Damages Report  {this.state.finyear}</h5>
                                            <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                                }
                                        
                                        </div>
                                        {(this.state.userType == "user")?
                                            <div className='row col-xl-12 col-lg-12'>
                                                <button className="btn btn-success" onClick={this.onclickUpdateDamageData.bind(this)}>Update</button>
                                                <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brmdamagereport")}>Approve</button>
                                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={fifthChartColumns}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.fifthChartRowData}
                                                        enableCharts={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onFifthGridReady}
                                                        onGridState={this.onFifthGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        components={this.state.components}
                                                        rowSelection={this.state.rowSelection}
                                                        suppressRowClickSelection={true}
                                                        onRowSelected={this.onRowSelected.bind(this)}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        onCellEditingStopped={this.onCellBrmDamageData.bind(this)}
                                                    />
                                                </div>
                                                {/* <button className="btn btn-success" onClick={this.onclickUpdateDamageReport.bind(this)}>Update</button> */}
                                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={damageColumns}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.damageRowData}
                                                        enableCharts={false}
                                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                        onGridReady={this.onDamageGridReady}
                                                        onGridState={this.onDamageGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                        enableRangeSelection={true}
                                                        components={this.state.components}
                                                        rowSelection={this.state.rowSelection}
                                                        suppressRowClickSelection={true}
                                                        onRowSelected={this.onRowSelected.bind(this)}
                                                        gridOptions={{
                                                            context: { componentParent: this }
                                                        }}
                                                        onCellEditingStopped={this.onCellEditingStoppedDamageData.bind(this)}
                                                        // onCellEditingStopped={this.onCellBrmDamageData.bind(this)}
                                                    />
                                                </div>
                                                <form id="damagestargetform" className="col-xl-4 col-lg-4" onSubmit={this.onclicksaveDamagesTargetData.bind(this)}>
                                                    <div className="form-group">
                                                        <label>Target Financial Year:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> &nbsp;20-21, 2020-2021</span></label>
                                                        <input type="text" className="form-control" onChange={(e)=>{this.setState({damages_target_fy: e.target.value})}}  required/>
                                                    </div>
                                                    <div className="form-group ">
                                                        <label>Limit : <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
                                                        <input type="text" className="form-control" onChange={(e)=>{this.setState({damagesLimit: e.target.value})}} required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                            
                                            
                                        :""}
                                    
                                </div>:""    
                                }
                                {(this.state.showSixthLogs == "")?
                                    <div className={"row "+this.state.showSixthLogs}>
                                        <div className="col-xl-8 col-lg-8" style={{paddingLeft:"5em"}}>
                                            <h6>Logs - 6</h6>
                                        </div>
                                        <div>
                                            <button className="btn btn-success" onClick={this.onClickApprove.bind(this, "brmdrivertrainings")}>Approve</button>
                                        </div>
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham col-xl-12 col-lg-12">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.sixthLogsColumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.sixthLogsRowData}
                                                enableCharts={false}
                                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                // onRowSelected={this.onRowSelected.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                onCellEditingStopped={this.onCellEditSixthLogs.bind(this)}
                                            />
                                        </div>
                                        <form id="targetform" className="col-xl-4 col-lg-4" onSubmit={this.onClickSaveLogs6ColumnMonths.bind(this)}>
                                            <div className="form-group">
                                                <label>Column Name:  <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></label>
                                                <input type="text" className="form-control" onChange={(e)=>{this.setState({logs_6_from_month: e.target.value})}}  required/>
                                            </div>
                                            {/* <div className="form-group ">
                                                <label>To Month: <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E.g.</b> </span></label>
                                                <input type="text" className="form-control" onChange={(e)=>{this.setState({logs_6_to_month: e.target.value})}} required/>
                                            </div> */}
                                            
                                            <div className="form-group">
                                                <button type="submit" style={{marginTop:"30px"}} className="btn btn-success">Submit</button>
                                            </div>
                                            
                                    </form>
                                    </div>
                                    :""    
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 


function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 