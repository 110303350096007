import React, { Component } from 'react';
// import { useState } from 'react';

// import ReactDOM from 'react-dom';
// import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
// import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
// import { scaleQuantile } from 'd3-scale';
// import ReactTooltip from 'react-tooltip';
// import Select from "react-select";
// import LinearGradient from './LinearGradient';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import Modal from 'react-responsive-modal';

// import Map from './mapLocationComponent';

import redirectURL from '../redirectURL';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var infoBox = require('../common/google-infowindow');
var map;

export default class SegmentSideBar extends Component {

      constructor(props) {
          super(props);
          this.state={
            tripEndData : "",
            breaksData : "",
            consigneee_code : "",
            lat : "",
            long : "",
            consignmentList:"",
            invoice_time : "",
            filterStart : "",
            filterEnd : "",
            truck_no : "",
            mapinfo:"",
            starttime:"",
            consignee_coords:"",
            endtime : '',
            showSegmentData:"show-n",
            selected_truck_no : "",
            filteredData : [],
            modules: AllModules,
            showTrucksList : "show-n",
            basicTitle:"",
            basicType:"success",
            show:false,
            loadshow:"show-n",
            overly : "show-n",
            consignmentData:"",
            noRouteData:1,
            disableFilters : "show-m",
            mapoverly : "show-n",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"",
            eventGridAction: "gridAction",
            checkwaiver:"",
            waiverComments : "",
            defaultStartDate : "",
            defalutEndDate : "",
            tolls : [],
            showMarkers : false,
            selectedBreakRow : [],
            data_type:"",
            defaultColDef : {
                sortable : true,
                filter : true
            },
            usermanualmodal:false,
            consignment_code : ''
          };
      }
      
      showMap(reqData)
      {
          console.log(reqData,"reqData");
          reqData["screen"] = "consignment";
          reqData["segmentation"] = 1;
        redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
        .then((response) => {
            var records = response.data;
            console.log(records)
            if(records.coords.length == 0)
            {
                //console.log("records", records.coords);
                this.setState({
                    // show: true,
                    // basicTitle:'No Route data available',
                    // basicType:"danger",
                    // loadshow:'show-n',
                    // overly : "show-n"
                    showSegmentData : "show-m",
                    breaksData : reqData.breaksData,
                    noRouteData : 0,
                    disableFilters : "show-n",
                    mapoverly : "show-m",
                    lat : "",
                    long : "",
                });
                console.log("No Data")
                this.renderMap();
            }
            else
            {
                //console.log("End Date ", edate)
                if(this.state.filterStart == "" && this.state.filterEnd == "")
                {
                    var startTime = moment.parseZone(this.state.invoice_time).format("DD-MM-YYYY HH:mm");
                    // var endTime = moment.parseZone().format("DD-MM-YYYY HH:mm");
                    var tripEndData = this.state.tripEndData;
                    var endTime;
                    var defalutend;
                    if(tripEndData.status == 3)
                    {
                        endTime = moment.parseZone(tripEndData.dealer_reached_25_kms).format("DD-MM-YYYY HH:mm");
                        defalutend = tripEndData.dealer_reached_25_kms;
                    }
                    if(tripEndData.status == 4)
                    {
                        endTime = moment.parseZone(tripEndData.dealer_reached_5_kms).format("DD-MM-YYYY HH:mm");
                        defalutend = tripEndData.dealer_reached_5_kms;
                    }
                    if(tripEndData.status == 5)
                    {
                        endTime = moment.parseZone(tripEndData.truck_left_dealer_on).format("DD-MM-YYYY HH:mm");
                        defalutend = tripEndData.truck_left_dealer_on;
                    }
                    if(tripEndData.status == 6)
                    {
                        if(tripEndData.truck_left_dealer_on != undefined)
                        {
                            endTime = moment.parseZone(tripEndData.truck_left_dealer_on).format("DD-MM-YYYY HH:mm");
                            defalutend = tripEndData.truck_left_dealer_on;
                        }
                        else
                        {
                            endTime = moment.parseZone(tripEndData.trip_ended_on).format("DD-MM-YYYY HH:mm");
                            defalutend = tripEndData.trip_ended_on;
                        }
                        
                    }
                    this.setState({
                        mapinfo:records.coords,
                        starttime : startTime,
                        endtime : endTime,
                        defaultStartDate : this.state.invoice_time,
                        defalutEndDate : defalutend,
                        lat : "",
                        long : "",
                        tolls : records.tolls
                    });
                }
                else{
                    this.setState({
                        mapinfo:records.coords,
                        tolls : records.tolls
                    });
                }                    
                this.setState({
                    breaksData : reqData.breaksData,
                    showSegmentData : "show-m",
                    noRouteData : 1,
                    disableFilters : "show-m",
                    mapoverly : "show-n",
                    lat : "",
                    long : "",
                })
                loadDateTimeScript();
                this.renderMap();
            }
            
        })
      }

      componentDidMount = async()=>{
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
        // this.setState({
        //     overly : "show-m",
        //     loadshow : "show-m",
        // })
        // this.logPageView(); // Log page view to GA
        // await redirectURL.post("consignments/getAllDeliveredConsginments").then((response)=>{
            
        //     console.log("response.data",response.data);
        //     if(response.data.length > 0)
        //     {
        //         var consignments = response.data.map(function(e)
        //         {
        //             if(e.consignment_code != "")
        //             {
        //                 return e.consignment_code;
        //             }
        //         })
        //         this.setState({
        //             consignmentList :consignments,
        //             overly : "show-n",
        //             loadshow : "show-n",
        //         })
        //     }
            
        // })
        //   alert(this.props.segmentConsignmentCode);
        // var reqparams = {
        //     "consignment_code" : this.props.segmentConsignmentCode
        // }
        // redirectURL.post("/consignments/getTripEndData",reqparams).then((response)=>{
        //     // console.log(response.data.invoiceTime);
        //     if(response.data.tripEndData.length > 0)
        //     {
        //         var invoiceTime = response.data.invoiceTime[0].invoice_time;
        //         this.setState({
        //             tripEndData : response.data.tripEndData[0],
        //             truck_no : response.data.tripEndData[0].truck_no,
        //             breaksData : response.data.tripEndData[0].breaks,
        //             invoice_time : response.data.invoiceTime[0].invoice_time,
        //             consignee_code : response.data.invoiceTime[0].consignee_code,
        //         })
        //         var reqData = {
        //             truck_no : response.data.tripEndData[0].truck_no,
        //             consignment_code : this.props.segmentConsignmentCode,
        //             consignee_code : response.data.invoiceTime[0].consignee_code,
        //             gate_out_time : moment.parseZone(new Date(response.data.invoiceTime[0].invoice_time)).format('YYYY-MM-DD HH:mm:ss'),
        //             seconddate : moment.parseZone().format('YYYY-MM-DD HH:mm:ss'),
        //         }
        //         this.showMap(reqData);
        //         console.log("reqData: ", reqData);
        //     }
        //     else
        //     {
        //         this.props.context.closeSegmentSideBar();

        //     }
            
            
        // }).catch(function(error){
        //   console.log(error);
        // })        
    }
    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }  
    renderMap = () =>
    {    	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
    window.initMap = this.initMap
    }

    initMap = () => {
        // console.log("here slidebar")
        // $("#mapweather").empty();
        if(this.state.mapinfo.length)
        {
            var data_type_list = [...new Set(this.state.mapinfo.map(item => item.type))];
            if(data_type_list.includes("transshipment"))
            {
                var data_type = "transshipment"
            }
            if(data_type_list.includes("rake"))
            {
                var data_type = "rake"
            }
            this.setState({
                data_type : data_type
            });
            
        }
        var bounds = new window.google.maps.LatLngBounds();
        if(this.state.lat != "" && this.state.long != "")
        {
            var lat = this.state.lat;
            var long = this.state.long;
            map = new window.google.maps.Map(document.getElementById('mapweather'), {
            zoom: 8,
            center: new window.google.maps.LatLng(lat,long),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },            
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
            });
            
            // var iconName = {
            //     url: require("../../assets/icons/cf.png"), // url
            //     scaledSize: new window.google.maps.Size(32, 32), // scaled size
            //     origin: new window.google.maps.Point(0,0), // origin
            //     anchor: new window.google.maps.Point(0, 0) // anchor
            // };
            var breakmarker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(this.state.lat,this.state.long),
                map: map,
                icon: require("../../assets/icons/cf.png"),
                // title : ""+params.address+","+params.forecast_date+","+params.description
            });

            var infowindow = new window.google.maps.InfoWindow();
			//var l;
			var selectedBreakRow = this.state.selectedBreakRow;
            window.google.maps.event.addListener(breakmarker, 'mouseover', (function(breakmarker) {
                return function() 
                {
                    var contentarr = []
                    var header = selectedBreakRow.area
                    contentarr.push({"key":"Break start", "value":getHyphenDDMMMYYYYHHMM(selectedBreakRow.break_start)})
                    contentarr.push({"key":"Break End", "value":getHyphenDDMMMYYYYHHMM(selectedBreakRow.break_end)})
                    contentarr.push({"key":"Break Duration", "value":ConvertMinutes(selectedBreakRow.break_time_mins)})
                    var contentString = infoBox(breakmarker.icon, header, contentarr)
                    infowindow.setContent(contentString);
                    var currentwindow = infowindow;
                    infowindow.open(map, breakmarker);
                }
            })(breakmarker));
            window.google.maps.event.addListener(breakmarker, 'mouseout', function() {
                infowindow.close();
                });
                
            bounds.extend(new window.google.maps.LatLng(lat,long));
            map.fitBounds(bounds)
        }
        else
        {
            var lat = 28.4595;
            var long = 77.0266;
            map = new window.google.maps.Map(document.getElementById('mapweather'), {
                zoom: 8,
                center: new window.google.maps.LatLng(lat,long),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
            });
            var line=new window.google.maps.Polyline({
                map:map,
                icons: [{
                    icon: {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            strokeColor:'#FF0000',
                            fillColor:'#FF0000',
                            fillOpacity:1,
                            strokeWeight: 2
                    },
                    repeat:'100px',
                    path:[]
                }],
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
            });
            var path=line.getPath().getArray();
            // var iconName = {
            //     url: require("../../assets/icons/cf.png"), // url
            //     scaledSize: new window.google.maps.Size(32, 32), // scaled size
            //     origin: new window.google.maps.Point(0,0), // origin
            //     anchor: new window.google.maps.Point(0, 0) // anchor
            // };
            var linecolr="";
            if(this.state.noRouteData == 1)
            {
                if(this.state.showMarkers)
                {
                        var mapinfo = this.state.mapinfo;
                        console.log(mapinfo);
                        mapinfo.map(function(e,index)
                        {
                            var marker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(e.lat, e.lng),
                                icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    strokeColor:'#157254',
                                    fillColor:'#157254',
                                    fillOpacity:1,
                                        strokeWeight: 3,
                                        scale:2,
                                    },
                                map: map,
                                content: e
                            });
                            var infowindow = new window.google.maps.InfoWindow();
                            //console.log("Arr ",arr1[a])
                            window.google.maps.event.addListener(marker, 'click', (function(marker) {
                                return function() {
                                    //console.log("Arr ",marker)
                                    var contentarr = []
                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                var header = "Current Status"
                                contentarr.push({"key":"Time ", "value":getHyphenDDMMMYYYYHHMM(marker.content.stime)})
                                contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                                
                                var contentString = infoBox("", header, contentarr,'')
                                
                                
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, marker);
                                //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                                }
                            })(marker));
                        });
                        
                }
                if(this.state.filterStart == "" && this.state.filterEnd == "")
                {
                    var infowindow = new window.google.maps.InfoWindow();
                    var mapinfo = this.state.mapinfo;
                    var startCoords = this.state.mapinfo[0];
                    var endCoords = this.state.mapinfo[this.state.mapinfo.length - 1];

                    if(mapinfo.length <= 1000)
                    {
                        var icon_cnt = 60;
                        var icon_divisible = 60;
                    }
                    else
                    {
                        var icon_cnt = 500;
                        var icon_divisible = 500;
                    }
                    
                    for(var i=0;i< mapinfo.length-1;i++)
                    {
                        var locationLatLng = [];
                        locationLatLng.push(new window.google.maps.LatLng(mapinfo[i].lat,mapinfo[i].lng));
                        locationLatLng.push(new window.google.maps.LatLng(mapinfo[i+1].lat,mapinfo[i+1].lng));
                        if(icon_cnt % icon_divisible == 0)
                        {
                            var lineicon = [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#FF0000',
                                                fillColor:'#FF0000',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                        },
                                        repeat:'100px',
                                        path:[]
                                    }];
                        }
                        else
                        {
                            lineicon=[]
                        }
                        icon_cnt = icon_cnt + 1

                        var consignments_missing_route_line = new window.google.maps.Polyline({
                            map:map,
                            path : locationLatLng,
                            strokeColor: '#157254',
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                            icons: lineicon
                        });

                        if(mapinfo[i].msgtype == "G")
                        {
                            consignments_missing_route_line.setOptions({
                                strokeColor : "#452a68",
                                strokeWeight: 5.5,
                            })
                        }
                        if(mapinfo[i].set == undefined && mapinfo[i].msgtype != "G")
                        {
                            consignments_missing_route_line.setOptions({
                                strokeColor : "#157254",
                            })
                        }
                        if(mapinfo[i].set == 1 && mapinfo[i].msgtype != "G")
                        {
                            consignments_missing_route_line.setOptions({
                                strokeColor : "#157254",
                            })
                        }
                        if(mapinfo[i].set == 2 && mapinfo[i].msgtype != "G")
                        {
                            consignments_missing_route_line.setOptions({
                                strokeColor : "#0000ff",
                                strokeWeight: 5.5,
                            })
                        }
                        if(mapinfo[i].set == 3 && mapinfo[i].msgtype != "G")
                        {
                            consignments_missing_route_line.setOptions({
                                strokeColor : "#980000",
                                strokeWeight: 5.5,
                            })
                        }
                        bounds.extend(new window.google.maps.LatLng(mapinfo[i].lat,mapinfo[i].lng));
                    }
                    
                    
                    
                    try{
                        this.state.breaksData.map(function(breaks,index)
                        {
                            var breaksmarker = new window.google.maps.Marker({
                                position: new window.google.maps.LatLng(breaks.lat,breaks.lng),
                                map: map,
                                icon: require("../../assets/icons/cf.png"),
                                // title : ""+params.address+","+params.forecast_date+","+params.description
                            });
                            window.google.maps.event.addListener(breaksmarker, 'mouseover', (function(breaksmarker, index) {
                                return function() 
                                {
                                    var contentarr = []
                                    var header = breaks.area
                                    contentarr.push({"key":"Break start", "value":getHyphenDDMMMYYYYHHMM(breaks.break_start)})
                                    contentarr.push({"key":"Break End", "value":getHyphenDDMMMYYYYHHMM(breaks.break_end)})
                                    contentarr.push({"key":"Break Duration", "value":ConvertMinutes(breaks.break_time_mins)})
                                    var contentString = infoBox(breaksmarker.icon, header, contentarr)
                                    infowindow.setContent(contentString);
                                    var currentwindow = infowindow;
                                    infowindow.open(map, breaksmarker);
                                }
                            })(breaksmarker, index));
                            window.google.maps.event.addListener(breaksmarker, 'mouseout', function() {
                                infowindow.close();
                                });
                        })
                    }catch(e){
                        console.log(e,"Breaks data is not found");
                    }
                    
                    new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(startCoords.lat,startCoords.lng),
                        map: map,
                        icon: require('../../assets/icons/track_start.png'),
                    });
                    new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(endCoords.lat,endCoords.lng),
                        map: map,
                        icon: require('../../assets/icons/truck-end.png'),
                    });       
                    
                }
                else
                {
                    var filterStart = Date.parse(moment.parseZone(new Date(this.state.filterStart)));
                    var filterEnd = Date.parse(moment.parseZone(new Date(this.state.filterEnd)))
                    try{
                        for(var i=0;i<this.state.mapinfo.length-1;i++)
                        {
                            var coordstime = Date.parse(moment.parseZone(new Date(this.state.mapinfo[i].stime)));
                            var coordstime1 = Date.parse(moment.parseZone(new Date(this.state.mapinfo[i+1].stime)));
                            // console.log(coordstime,filterStart,filterEnd)
                            if((coordstime >= filterStart && coordstime <= filterEnd) && (coordstime1 >= filterStart && coordstime1 <= filterEnd))
                            {
                                console.log("in else")
                                var locationLatLng = [];
                                locationLatLng.push(new window.google.maps.LatLng(this.state.mapinfo[i].lat,this.state.mapinfo[i].lng));
                                locationLatLng.push(new window.google.maps.LatLng(this.state.mapinfo[i+1].lat,this.state.mapinfo[i+1].lng));
                                if(icon_cnt % icon_divisible == 0)
                                {
                                    var lineicon = [{
                                                icon: {
                                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                        strokeColor:'#FF0000',
                                                        fillColor:'#FF0000',
                                                        fillOpacity:1,
                                                        strokeWeight: 2
                                                },
                                                repeat:'100px',
                                                path:[]
                                            }];
                                }
                                else
                                {
                                    lineicon=[]
                                }
                                icon_cnt = icon_cnt + 1

                                var consignments_missing_route_line = new window.google.maps.Polyline({
                                    map:map,
                                    path : locationLatLng,
                                    strokeColor: '#157254',
                                    strokeOpacity: 1.0,
                                    strokeWeight: 5.5,
                                    icons: lineicon
                                });

                                if(this.state.mapinfo[i].msgtype == "G")
                                {
                                    consignments_missing_route_line.setOptions({
                                        strokeColor : "#452a68"
                                    })
                                }
                                if(this.state.mapinfo[i].set == undefined && this.state.mapinfo[i].msgtype != "G")
                                {
                                    consignments_missing_route_line.setOptions({
                                        strokeColor : "#157254"
                                    })
                                }
                                if(this.state.mapinfo[i].set == 1 && this.state.mapinfo[i].msgtype != "G")
                                {
                                    consignments_missing_route_line.setOptions({
                                        strokeColor : "#157254"
                                    })
                                }
                                if(this.state.mapinfo[i].set == 2 && this.state.mapinfo[i].msgtype != "G")
                                {
                                    consignments_missing_route_line.setOptions({
                                        strokeColor : "#0000ff"
                                    })
                                }
                                if(this.state.mapinfo[i].set == 3 && this.state.mapinfo[i].msgtype != "G")
                                {
                                    consignments_missing_route_line.setOptions({
                                        strokeColor : "#980000"
                                    })
                                }
                                bounds.extend(new window.google.maps.LatLng(this.state.mapinfo[i].lat,this.state.mapinfo[i].lng));
                                    // if(this.state.mapinfo[i].set == undefined){
                                    //     nosetpath.push(latLng);
                                    // }
                                    // else if(this.state.mapinfo[i].set == 1){
                                    //     path1.push(latLng);
                                    // }
                                    // else if(this.state.mapinfo[i].set == 2){
                                    //     path2.push(latLng);
                                    // }
                                    // else if(this.state.mapinfo[i].set == 3){
                                    //     path3.push(latLng);
                                    // }
                                if(this.state.showMarkers == true)
                                {
                                    var marker = new window.google.maps.Marker({
                                        position: new window.google.maps.LatLng(this.state.mapinfo[i].lat, this.state.mapinfo[i].lng),
                                        icon: {
                                            path: window.google.maps.SymbolPath.CIRCLE,
                                            strokeColor:'#157254',
                                            fillColor:'#157254',
                                            fillOpacity:1,
                                                strokeWeight: 3,
                                                scale:2,
                                            },
                                        map: map,
                                        content: this.state.mapinfo[i]
                                    });
                                    var infowindow = new window.google.maps.InfoWindow();
                                    //console.log("Arr ",arr1[a])
                                    window.google.maps.event.addListener(marker, 'click', (function(marker) {
                                        return function() {
                                            //console.log("Arr ",marker)
                                            var contentarr = []
                                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                                        var header = "Current Status"
                                        contentarr.push({"key":"Time ", "value":getHyphenDDMMMYYYYHHMM(marker.content.stime)})
                                        contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
                                        
                                        var contentString = infoBox("", header, contentarr,'')
                                        
                                        
                                        infowindow.setContent(contentString);
                                        var currentwindow = infowindow;
                                        infowindow.open(map, marker);
                                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                                        }
                                    })(marker));
                                }   
                                
                                
                            }
                            if(i == 0)
                            {
                                var startCoords = {"lat":this.state.mapinfo[i].lat,"lng":this.state.mapinfo[i].lng}
                            }
                            if(i == (this.state.mapinfo.length-1))
                            {
                                var endCoords = {"lat":this.state.mapinfo[i].lat,"lng":this.state.mapinfo[i].lng}
                            }
                        }

                    }
                    catch(e){
                        console.log(e,"No map data found");
                    }
                    
                    // var startIcon = new window.google.maps.Marker({
                    //     position: new window.google.maps.LatLng(startCoords.lat,startCoords.lng),
                    //     map: map,
                    //     icon: require('../../assets/icons/track_start.png'),
                    // });
                    // var endIcon = new window.google.maps.Marker({
                    //     position: new window.google.maps.LatLng(endCoords.lat,endCoords.lng),
                    //     map: map,
                    //     icon: require('../../assets/icons/truck-end.png'),
                    // }); 
                    try{
                        this.state.breaksData.map(function(breaks,index)
                        {
                            var break_start_time = Date.parse(moment.parseZone(breaks.break_start));
                            if(break_start_time >= filterStart && break_start_time <= filterEnd)
                            {
                                var breaksmarker = new window.google.maps.Marker({
                                    position: new window.google.maps.LatLng(breaks.lat,breaks.lng),
                                    map: map,
                                    icon: require("../../assets/icons/cf.png"),
                                    // title : ""+params.address+","+params.forecast_date+","+params.description
                                });
                                window.google.maps.event.addListener(breaksmarker, 'mouseover', (function(breaksmarker, index) {
                                    return function() 
                                    {
                                        var contentarr = []
                                        var header = breaks.area
                                        contentarr.push({"key":"Break start", "value":getHyphenDDMMMYYYYHHMM(breaks.break_start)})
                                        contentarr.push({"key":"Break End", "value":getHyphenDDMMMYYYYHHMM(breaks.break_end)})
                                        contentarr.push({"key":"Break Duration", "value":ConvertMinutes(breaks.break_time_mins)})
                                        var contentString = infoBox(breaksmarker.icon, header, contentarr)
                                        infowindow.setContent(contentString);
                                        var currentwindow = infowindow;
                                        infowindow.open(map, breaksmarker);
                                    }
                                })(breaksmarker, index));
                                window.google.maps.event.addListener(breaksmarker, 'mouseout', function() {
                                    infowindow.close();
                                    });
                            }
                        })
                    }catch(e){
                        console.log(e,"no breaks data found")
                    }
                    
                    
                }
                line.setPath(path);
                map.fitBounds(bounds)
            }
            
            
        }
        new window.google.maps.Marker({
            position: new window.google.maps.LatLng(this.state.consignee_coords.lat, this.state.consignee_coords.lng),
            icon:require('../../assets/icons/customer_pin_38.png'),
            map: map,	
        });
        if(this.state.tolls.length > 0)
        {
            var infowindow = new window.google.maps.InfoWindow();
            var l;
            this.state.tolls.map(function(e,index){
                var tollMarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.lat, e.lon),
                    icon:require('../../assets/icons/barrier.png'),
                    map: map,
                });
                window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
                    return function() 
                    {
                        var contentarr = []
                        var header = "Toll - "+e.name
                        contentarr.push({"key":"Address", "value":e.location})
                        var contentString = infoBox(tollMarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, tollMarker);
                    }
                })(tollMarker, index));
                window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                    infowindow.close();
                    });
            })  
            
        }
        
        
        
        this.setState({
            overly : "show-n",
            loadshow : "show-n",
        })
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
      }
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.consignmentList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.consignmentList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
    formHandler = async (event) => {
        event.preventDefault();
        this.setState({
            overly : "show-m",
            loadshow : "show-m",
            // tripEndData : "",
            // consignmentData : "",
            checkwaiver:"",
            waiverComments : ""
        })
       // console.log($("#inputTruck").val());
        var reqparams = {
            "invoice_no" : $("#inputTruck").val()
        }
        redirectURL.post("/consignments/getTripEndData",reqparams).then((response)=>{
            console.log("invoice time",response.data.invoiceTime);
            console.log(response.data.tripEndData);
            try{
                if(response.data.tripEndData.length > 0)
                {
                    var invoiceTime = response.data.invoiceTime;
                    // console.log("invoiceTime",invoiceTime[0]);
                    var delivery_waiver = invoiceTime[0].delivery_waiver;
                    // console.log("delivery_waiver",delivery_waiver);
                    var waiverComments = ""
                    if(response.data.invoiceTime[0].delivery_waiver_comments != undefined)
                    {
                        waiverComments = response.data.invoiceTime[0].delivery_waiver_comments;
                    }
                    // $("#waiver-comments").val(invoiceTime[0].delivery_waiver_comments)
                    this.setState({
                        tripEndData : response.data.tripEndData[0],
                        truck_no : response.data.tripEndData[0].truck_no,
                        invoice_time : response.data.invoiceTime[0].invoice_time,
                        consignee_coords : JSON.parse(response.data.tripEndData[0].consignee_coordinates),
                        consignee_code : response.data.invoiceTime[0].consignee_code,
                        consignment_code: response.data.invoiceTime[0].consignment_code,
                        consignmentData : response.data.invoiceTime[0],
                        filterStart : "",
                        filterEnd : "",
                        checkwaiver : delivery_waiver,
                        waiverComments : waiverComments
                    })
                    var sdate;
                    if(response.data.tripEndData[0].truck_left_dealer_on != undefined)
                    {
                        sdate = moment.parseZone(new Date(response.data.tripEndData[0].truck_left_dealer_on)).format('YYYY-MM-DD HH:mm:ss');
                    }
                    else
                    {
                        sdate = moment.parseZone(new Date(response.data.tripEndData[0].trip_ended_on)).format('YYYY-MM-DD HH:mm:ss');

                    }
                    var breaks_data = [];
                    if(response.data.tripEndData[0].breaks == undefined){
                        breaks_data = [];
                    }
                    else
                    {
                        breaks_data = response.data.tripEndData[0].breaks;
                    }
                    var reqData = {
                        truck_no : response.data.tripEndData[0].truck_no,
                        // invoice_no : $("#inputTruck").val(),
                        consignment_code : this.state.consignment_code,
                        consignee_code : response.data.invoiceTime[0].consignee_code,
                        breaksData : breaks_data,
                        gate_out_time : moment.parseZone(new Date(response.data.invoiceTime[0].invoice_time)).format('YYYY-MM-DD HH:mm:ss'),
                        seconddate : sdate
                    }
                    this.showMap(reqData);
                }
                else
                {
                    this.setState({
                        overly : "show-n",
                        loadshow : "show-n",
                        basicTitle : "No Data Found",
                        basicType : "warning",
                        show : true,

                    })

                }
            }catch(e){
                this.setState({
                    overly : "show-n",
                    loadshow : "show-n",
                    basicTitle : "No Data Found",
                    basicType : "warning",
                    show : true,

                })
            }
            
            
            
        }).catch(function(error){
          console.log(error);
        })  
    }
    printDocument=()=> {
        // console.log("printDoc")
        const input = document.getElementById('segmentID');
        // alert(input);
        // console.log(input);
    
        // html2canvas(input)
        //   .then((canvas) => {
        //     const imgData = canvas.toDataURL('image/jpeg,1.0');
        //     const pdf = new jsPDF();
        //     var doc = new jsPDF("p", "mm", "a4");
        //     var width = doc.internal.pageSize.getWidth();
        //     var height = doc.internal.pageSize.getHeight();
        //     pdf.addHTML(imgData, 'JPEG', 0,0,width,height);
        //     // pdf.output('dataurlnewwindow');
        //     pdf.save("download.pdf");
        //     alert("downloaded")
        //   });
        // html2canvas(input).then(canvas => {
        //     alert("htmk")
        //     var can = document.body.appendChild(canvas);
        //     const imgData = can.toDataURL('image/jpeg,1.0');
        //     const pdf = new jsPDF();
        //     var doc = new jsPDF("p", "mm", "a4");
        //     var width = doc.internal.pageSize.getWidth();
        //     var height = doc.internal.pageSize.getHeight();
        //     pdf.addImage(imgData, 'JPEG', 0,0,width,height);
        //     // pdf.output('dataurlnewwindow');
        //     pdf.save("download.pdf");
        //     alert("downloaded")
        //     // console.log(can);
        // });
        // html2canvas(input).then(function(canvas) {
        //     document.body.appendChild(canvas);
        //     var imgData = canvas.toDataURL("image/png");
        //     var doc = new jsPDF();
        //     doc.addImage(imgData,"PNG",10,10);
        //     doc.save("sample.pdf");
        // });
        // html2canvas(input)
        // .then(function (canvas) {
        //    document.body.appendChild(canvas);
        //    var base64URL = canvas.toDataURL('image/jpeg')
        //    var doc = new jsPDF();
        //     doc.addImage(base64URL,"PNG",10,10);
        //     doc.save("sample.pdf");
        //    console.log(base64URL)
        // });
       
      }

    onRowSelection(event){
        console.log(event.api.getSelectedNodes()[0].data)
        var lat = event.api.getSelectedNodes()[0].data.lat;
        var long = event.api.getSelectedNodes()[0].data.lng;
        this.setState({
            lat : lat,
            long : long,
            mapoverly : "show-n",
            selectedBreakRow : event.api.getSelectedNodes()[0].data
        });
        this.renderMap();
    }
    setDeliveryWaiver(event){
        event.preventDefault();
        var reqparams = {
            delivery_waiver : ($("#waiver-checkbox").prop("checked"))?1:0,
            delivery_waiver_comments : $("#waiver-comments").val(),
            delivery_waiver_commented_by : localStorage.getItem("email"),
            delivery_waiver_commented_on : moment.parseZone().format("YYYY-MM-DD HH:MM:SS"),
        }
        var wherQuery = {
            consignment_code : {"$in":this.state.tripEndData.consignment_codes},
            truck_no : this.state.tripEndData.truck_no
        }
        redirectURL.post("/consignments/saveWaiverForm",{"reqparams" : reqparams,"whereQuery":wherQuery}).then((response)=>{
            this.setState({
                basicTitle : "success",
                basicType : "success",
                show : true
            })
        })
        
    }
    filterMapRoute(){
        var startTime = document.getElementById("start-time").value;
        var endTime = document.getElementById("end-time").value;
        this.setState({
            starttime : startTime,
            endtime : endTime,
        })
        
        startTime = startTime.split(" ")[0].split("-")[2]+"-"+startTime.split(" ")[0].split("-")[1]+"-"+startTime.split(" ")[0].split("-")[0]+" "+startTime.split(" ")[1]+":00";
        endTime = endTime.split(" ")[0].split("-")[2]+"-"+endTime.split(" ")[0].split("-")[1]+"-"+endTime.split(" ")[0].split("-")[0]+" "+endTime.split(" ")[1]+":00";
        console.log(startTime,endTime)
        var selectedStartTime = new Date(startTime).getTime();
        var selectedEndTime = new Date(endTime).getTime();
        var defaultStartDate = new Date(moment.parseZone(this.state.defaultStartDate).format("YYYY-MM-DD HH:MM")+":00").getTime();
        var defalutEndDate = new Date(moment.parseZone(this.state.defalutEndDate).format("YYYY-MM-DD HH:MM")+":00").getTime();
        var startflag = 0;
        var endflag = 0;
        // console.log(defaultStartDate,selectedStartTime);
        if((selectedStartTime < defaultStartDate) || (selectedStartTime > defalutEndDate))
        {
            startflag = 1;
        }
        if((selectedEndTime < defaultStartDate) || (selectedEndTime > defalutEndDate))
        {
            endflag = 1;
        }
        if(startflag == 0 && endflag == 0)
        {
            var reqData = 
            {
                truck_no : this.state.truck_no,
                invoice_no : $("#inputTruck").val(),
                consignee_code : this.state.consignee_code,
                breaksData :this.state.breaksData,
                gate_out_time : moment.parseZone(new Date(startTime)).format('YYYY-MM-DD HH:mm:ss'),
                seconddate : moment.parseZone(new Date(endTime)).format('YYYY-MM-DD HH:mm:ss'),

            }
            this.setState({
                filterStart : moment.parseZone(new Date(startTime)),
                filterEnd : moment.parseZone(new Date(endTime)),
                lat : "",
                mapinfo : "",
                overly : "show-m",
                loadshow : "show-m",
                long : "",
                noRouteData : 1,
                disableFilters : "show-m",
                mapoverly : "show-n",
                mapinfo : []
            })
            this.showMap(reqData);
        }
        else{
            if(startflag == 1)
            {
                this.setState({
                    basicTitle : "Start date must be between "+getHyphenDDMMMYYYYHHMM(this.state.defaultStartDate),
                    basicType : "warning",
                    show : true
                })
            }
            else{
                this.setState({
                    basicTitle : "End date must be between "+getHyphenDDMMMYYYYHHMM(this.state.defalutEndDate),
                    basicType : "warning",
                    show : true
                })
            }
            
        }
        
    }
    resetMap(){
        this.setState({
            lat : "",
            long : ""
        })
        if(this.state.mapinfo.length == 0)
        {
            this.setState({
                mapoverly : "show-m"
            })
        }
        this.renderMap()
    }
onCloseRouteDiv = (event) => {
    this.props.context.onCloseRouteDiv();
}

async showMarkers(event)
{
    if(this.state.showMarkers)
    {
        await this.setState({
            showMarkers : false
        });
        this.initMap()
    }
    else
    {
        await this.setState({
            showMarkers : true
        });
        this.initMap()
    }
    
    
}


onCloseUserManualModal = () => {
    this.setState({ usermanualmodal: false });
};

onClickShowUserManaul = () => {
    this.setState({ usermanualmodal: true });
};
render(){
    const modalStyles  = {
        width:'1300px !important',
    }
    
    const {usermanualmodal} = this.state;
        const columnwithDefs = [
            {
                headerName : "Break No",
                field : "break_number",
                resizable : true,
                width : 80
            },
            {
                headerName : "Break Start",
                field : "break_start",
                resizable : true,
                width : 140,
                valueGetter : function(params){
                    if(params.data.break_start != "")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.break_start);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName : "Break End",
                field : "break_end",
                resizable : true,
                width : 140,
                valueGetter : function(params){
                    if(params.data.break_end != "")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.break_end);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName : "Break Time",
                field : "break_time_mins",
                resizable : true,
                width : 140,
                valueGetter : function(params)
                {
                    return ConvertMinutes(params.data.break_time_mins);
                }
            },
            {
                headerName : "Distance From Plant (KM)",
                field : "distance_from_plant",
                resizable : true,
                width : 120,
                valueGetter : function(params){
                    return Math.round(params.data.distance_from_plant)
                }
            },
            {
                headerName : "Distance From Dealer (KM)",
                field : "distance_from_dealer",
                resizable : true,
                width : 120,
                valueGetter : function(params){
                    return Math.round(params.data.distance_from_dealer)
                }
            },
            {
                headerName : "Toll Name",
                field : "toll_name",
                resizable : true,
                width : 120
            },
            {
                headerName : "Distance From Toll (KM)",
                field : "distance_from_toll",
                resizable : true,
                width : 120
            },
            {
                headerName : "State Border Name",
                field : "state_border_name",
                resizable : true,
                width : 120
            },
            {
                headerName : "Distance From State Border (KM)",
                field : "distance_from_state_border",
                resizable : true,
                width : 150
            },
            {
                headerName : "Area",
                field : "area",
                resizable : true,
                width : 120
            },
            {
                headerName : "State",
                field : "state",
                resizable : true,
                width : 120
            },
        ]
        const dStyles={
            maxWidth:'100%',
            height:'500px',
            // marginTop : "20px"
          }
        var listData = []
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){console.log(e)}
        
        return(
            
            <div style={{maxWidth:"100%",height:"100%",}}>
                <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {/* <div className="slide-r slider-translate-60p " style={{"width":"95%"}}> */}
                    {/* <div className="slide-r-title row col-xl-12 col-lg-12" style={{background:"#21759d",color:"#fff"}}>
                        <div className="col-xl-3">
                            <button className="btn btn-default" onClick={()=>{window.print()}}>PRINT</button>
                        </div>
                        <div className="col-xl-3" style={{textAlign: "right"}}>
                            <label className="Forecast-Header" style={{paddingTop: "9px"}}>Segment</label>
                        </div>
                        <div class="col-xl-5"></div>
                        <div class="col-xl-1">
                            <span className="float-right closebtn" style={{marginTop: "4px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </div>
                    </div>  */}
                    {/* <div className={"trucks-dropdown "+this.state.showTrucksList} style={{top: "76px",left: "58px", width: "24.3em"}}>
                        <ul>
                            {listData}
                        </ul>
                    </div>
                    onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} */}
                    <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                        <div className="col-xl-3 col-lg-3 form-group">
                            <input type="text" name="truck_no" id="inputTruck"  placeholder="Invoice No" autoComplete="off" className="form-control"  />
                        </div>
                        <div className="col-xl-3 col-lg-3 form-group">
                            <button type="submit" className="btn btn-success">Get Data</button>
                        </div>
                    </form>
                    {(this.state.showSegmentData == "show-m")?  
                    <div className={"col-xl-12 col-lg-12"+this.state.showSegmentData} id="">
                        <div class="col-xl-12 row">
                            <div className="card col-xl-4" >
                                <table className="table">
                                    <tr>
                                        <td>
                                            <span><b>Consignment No : </b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.consignment_codes}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Load No : </b></span>
                                        </td>
                                        <td>
                                            {this.state.consignmentData.load_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Club Load No : </b></span>
                                        </td>
                                        <td>
                                            {this.state.consignmentData.club_load_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b> Truck No :</b></span>
                                        </td>
                                        
                                        <td>
                                            {this.state.tripEndData.truck_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b> Transporter Code :</b></span>
                                        </td>
                                        
                                        <td>
                                            {this.state.tripEndData.transporter_code}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b> Transporter Name :</b></span>
                                        </td>
                                        
                                        <td>
                                            {this.state.tripEndData.transporter_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b> Plant Location :</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.plant_location}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Consignee Name</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.consignee_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Consignee City</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.consignee_city}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Cluster</b></span>
                                        </td>
                                        <td>
                                            {this.state.consignmentData.cluster}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Invoice Time</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.invoice_time)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Gate In Time</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.gate_in_time)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Gate Out Time</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.trip_started_on)}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>
                                            <span><b>Route ID</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.route_id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Crossed 50km From Plant At</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.truck_crossed_50_km_from_plant_at)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Total Travelled Distance without Google (KM)</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.total_travel_distance_km}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Total Travel Distance with Google (KM)</b></span>
                                        </td>
                                        <td>
                                            {this.state.tripEndData.total_travel_distance_google_km}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Total Travel Time </b></span>
                                        </td>
                                        <td>
                                            {ConvertMinutes(this.state.tripEndData.total_travel_mins)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Reached Dealer Proximity At</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.dealer_reached_25_kms)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Reached Dealer At</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.dealer_reached_5_kms)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Left Dealer At</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.truck_left_dealer_on)}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>
                                            <span><b>Transshipment</b></span>
                                        </td>
                                        <td>
                                            {(this.state.consignmentData.is_transshipment == 1)?"Yes":"No"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Old Truck No</b></span>
                                        </td>
                                        <td>
                                            {this.state.consignmentData.old_truck_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Replacement On</b></span>
                                        </td>
                                        <td>
                                            {getHyphenDDMMMYYYYHHMM(this.state.consignmentData.transshipment_date)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b>Reason For Transshipment</b></span>
                                        </td>
                                        <td>
                                            {this.state.consignmentData.transhipment_reason}
                                        </td>
                                    </tr>
                                    {(this.state.tripEndData.status == 6)?
                                        <tr>
                                            <td>
                                                <span><b>Force Closure Done By</b></span>
                                            </td>
                                            <td>
                                                {this.state.tripEndData.force_closure_performed_by}
                                            </td>
                                        </tr>
                                    :""}
                                    {(this.state.tripEndData.status == 6)? 
                                        <tr>
                                            <td>
                                                <span><b>Force Closure Time</b></span>
                                            </td>
                                            <td>
                                                {getHyphenDDMMMYYYYHHMM(this.state.tripEndData.force_closure_time)}
                                            </td>
                                        </tr>
                                    :""}
                                    {(this.state.tripEndData.status == 6)?
                                        <tr>
                                            <td>
                                                <span><b>Force Closure Reason</b></span>
                                            </td>
                                            <td>
                                                {this.state.tripEndData.force_closure_reason}
                                            </td>
                                        </tr>
                                    :""}
                                    {((this.state.tripEndData.status == 6) && (this.state.tripEndData.force_closure_reason == "Others"))?
                                        
                                        <tr>
                                            <td>
                                                <span><b>Force Closure Other Reason</b></span>
                                            </td>
                                            <td>
                                                {this.state.tripEndData.force_closure_other_reason }
                                            </td>
                                        </tr>
                                    
                                    :""}
                                    
                                </table>
                                <div className="col-xl-12" style={{background: "#343434",marginTop:"19px",color: "white",padding: "6px 0px 0px 14px"}}>
                                    <h6>Delivery Wavier Form</h6>
                                </div>
                                <div className="row mt-20p mb-20p">
                                    <form className="col-xl-12 col-lg-12" onSubmit={this.setDeliveryWaiver.bind(this)}>
                                        <div className="row form-group">
                                            <label className="col-xl-4" style={{marginLeft:"16px"}}>Delivery Waiver : </label>
                                            {(this.state.checkwaiver == 1)?
                                            <input type="checkbox" style={{marginTop:"4px"}} id="waiver-checkbox" checked className="col-xl-1" />
                                            :
                                            <input type="checkbox" style={{marginTop:"4px"}} id="waiver-checkbox" className="col-xl-1" />
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label className="col-xl-12">Delivery Waiver Comments : </label>
                                            <textarea className="form-control" style={{marginLeft:"12px"}} value={this.state.waiverComments} id="waiver-comments" required />
                                        </div>
                                        <button className="btn btn-success" style={{marginLeft:"16px"}} type="submit">Submit</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xl-8 ">
                                <div className={"col-xl-12 searchConsSegRoute "+this.state.disableFilters} style={{marginBottom:"10px"}}>
                                    <div class="row col-xl-12">
                                        <div class="col-xl-3">
                                            <input type="checkbox" name="viewtimelinemarkers" onClick={this.showMarkers.bind(this)} value={this.state.showMarkers} /> <span style={{fontSize:"14px"}}>View Markers</span>
                                        </div>
                                        <div class="col-xl-3">
                                            <input type="text" value={this.state.starttime} autoComplete="off" class="form-control datetimepicker_mask" id="start-time" /> 
                                        </div>
                                        <div class="col-xl-3">
                                            <input type="text" value={this.state.endtime} autoComplete="off" class="form-control datetimepicker_mask" id="end-time" />
                                        </div>
                                        <div class="col-xl-3">
                                            <button type="submit" className="btn btn-warning" onClick={this.filterMapRoute.bind(this)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                                <div id="mapweather" className="" style={dStyles}></div>
                                <div style={{ "margin":"1em" }}>
                                {(this.state.data_type == "regular")?
                                <span>
                                    <i class="fa fa-minus fa-2x" style={{color:"#157254",}}></i> <b style={{"paddingRight":"10px"}}>Current Truck </b>
                                    <i class="fa fa-minus fa-2x" style={{color:"#452a68",}}></i> <b style={{"paddingRight":"10px"}}>Missed GPS Route </b>
                                </span>
                                :
                                <span>
                                    {(this.state.data_type == "rake")?
                                        <span>
                                            <i class="fa fa-minus fa-2x" style={{color:"#157254",}}></i><b style={{"paddingRight":"10px"}}> First Mile Truck </b>
                                            <i class="fa fa-minus fa-2x" style={{color:"#0000ff",}}></i><b style={{"paddingRight":"10px"}}> Rake </b>
                                            <i class="fa fa-minus fa-2x" style={{color:"#980000",}}></i><b style={{"paddingRight":"10px"}}> Last Mile Truck </b>
                                            <i class="fa fa-minus fa-2x" style={{color:"#452a68",}}></i> <b style={{"paddingRight":"10px"}}>Missed GPS Route </b>
                                        </span>
                                    :
                                        <span>
                                            <i class="fa fa-minus fa-2x" style={{color:"#157254",}}></i> <b style={{"paddingRight":"10px"}}>Old Truck </b>
                                            <i class="fa fa-minus fa-2x" style={{color:"#0000ff",}}></i> <b style={{"paddingRight":"10px"}}>New Truck </b>
                                            <i class="fa fa-minus fa-2x" style={{color:"#452a68",}}></i> <b style={{"paddingRight":"10px"}}>Missed GPS Route </b>
                                        </span>
                                    }
                                </span>
                                }
                                </div>
                                <div className={"map-overly "+this.state.mapoverly} style={dStyles}><i className="fa fa-exclamation-circle"></i> No Route Data Available </div>
                                <div className="col-xl-12" style={{marginTop:"20px"}}>
                                        <h5 style={{float:"left",width:"78%"}}>
                                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Breaks </span>
                                        </h5>
                                        <button className="btn btn-info" onClick={this.resetMap.bind(this)}>Back to Main Map</button>
                                        <div className="ag-theme-balham" id="mygrid" style={{width:"100%",height:"350px"}}>
                                            <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.breaksData}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            rowSelection="single"
                                            onRowSelected={this.onRowSelection.bind(this)}
                                            />
                                        </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    :""}
                    
                {/* </div>     */}
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>  
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                
                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <h5 className="model-title">Segmentation</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen is available for SND department only. Through this screen user can forgo any tickets or delays generated by any consignment. With this feature a trip can be marked as ontime. This feature cannot be used for multiple trips at single time. It is available only for trip wise segmentation. This feature can be used for the consignments which are delivered day before yesterday from current day.
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                To access the segmentation following steps are to be followed
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        1. Click on segmentation
                                    </li>
                                    <li>
                                        2. Enter the consignment number for which details are required for segmentation
                                        <div className="col-xl-12 col-lg-12">
                                            Image here
                                        </div>
                                    </li>
                                    <li>
                                        3. After entering the consignment details user will get entire details of trip in below mentioned format along with map view of the trail available for the trip
                                        <div className="col-xl-12 col-lg-12">
                                            Image here
                                        </div>
                                        <div className="col-xl-12 col-lg-12">
                                            Image here
                                        </div>
                                        <div className="col-xl-12 col-lg-12">
                                            Image here
                                        </div>
                                    </li>
                                    <li>
                                        4. By checking the delivery waiver and mentioning the comment for the same one can segment the trip and make the delivery on time
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </Modal>
            </div>
        )
      }
    }

    function loadScriptWeather(url){
        var index  =window.document.getElementsByTagName("script")[1]
          var script = window.document.createElement("script")
          script.src=url
          script.async=true
          script.defer = true
          index.parentNode.insertBefore(script,index)
      }

    function loadDateTimeScript(){
        // alert("timeDate");
        $('.datetimepicker_mask').datetimepicker({
            mask:'39-19-9999 29:59',
            format:'d-m-Y H:i',
        });
        $('.datetimepicker_date').datetimepicker({
            mask:'39-19-9999',
            format:'d-m-Y',
            timepicker:false
        });
        var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
        //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
}


$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function ConvertMinutes(num){
    var d = Math.floor(num/1440); // 60*24
    var h = Math.floor((num-(d*1440))/60);
    var m = Math.round(num%60);
    return d+" Day(s) "+h+" Hrs "+m+" mins"
}




// for(var a=0;a<mapinfo.length;a++)
// {

//     var marker = new window.google.maps.Marker({
//         position: new window.google.maps.LatLng(mapinfo[a].lat, mapinfo[a].lng),
//         icon: {
//             path: window.google.maps.SymbolPath.CIRCLE,
//             strokeColor:'#157254',
//             fillColor:'#157254',
//             fillOpacity:1,
//                 strokeWeight: 3,
//                 scale:2,
//             },
//         map: map,
//         content:mapinfo[a]
//     });
    
//     //console.log("Arr ",arr1[a])
//     window.google.maps.event.addListener(marker, 'click', (function(marker) {
//         return function() {
//             //console.log("Arr ",marker)
//             var contentarr = []
//         //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
//         var header = "Current Status"
//         contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
//         contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
        
//         var contentString = infoBox("", header, contentarr,'')
        
        
//         infowindow.setContent(contentString);
//         currentwindow = infowindow;
//         infowindow.open(map, marker);
//         //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
//         }
//     })(marker));

    
// }
// }