import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var map;
var dept_code;
var infoBox = require('../common/google-infowindow');

export default class RailLoadOptimizationComponent extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            dept_code: "",
            detailCellRendererParams: {},
            breakCount: "",
            id: "",
            plants_coords : [],
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/dashboard/getInitialRailLoadData").then((response)=>{
            this.setState({
                rowData: response.data.traindimensions,
                loadshow : "show-n",
                overly : "show-n"
            })
        })
    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_breaks',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    getOptimizedLoadReport(){
        let selectedNodes = this.gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data);
        let train_name = selectedData[0]['train_name']
        window.location.href= "/getOptimizedLoadReport/"+window.btoa(train_name);
    }
    
    render(){
        const hidebreakname = (this.state.dept_code == "SNDG")?true:false;
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                checkboxSelection: true,
                width: 40
            },
            {
                headerName : "Train Name",
                field : "train_name",
                colId: "train_name",
                filter : true,
                resizable :true,
                width:120,
                editable:false,
            },
            {
                headerName: "Compartment A",
                headerClass:["cellstylegridG","cellTopHeader"],	
                children :[
                    {
                        headerName : "No. of Bogies",
                        field : "no_of_bogies_A",
                        colId: "no_of_bogies_A",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                    {
                        headerName : "Upper Deck Length",
                        field : "upper_deck_length_A",
                        colId: "upper_deck_length_A",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                    {
                        headerName : "Lower Deck Length",
                        field : "lower_deck_length_A",
                        colId: "lower_deck_length_A",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                    {
                        headerName : "Height Adjustment",
                        field : "height_adjustment_A",
                        colId: "height_adjustment_A",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    }
                ]
            },
            {
                headerName: "Compartment B",
                headerClass:["cellstylegridB","cellTopHeader"],
                children :[
                    {
                        headerName : "No. of Bogies",
                        field : "no_of_bogies_B",
                        colId: "no_of_bogies_B",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Upper Deck Length",
                        field : "upper_deck_length_B",
                        colId: "upper_deck_length_B",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Lower Deck Length",
                        field : "lower_deck_length_B",
                        colId: "lower_deck_length_B",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName : "Height Adjustment",
                        field : "height_adjustment_B",
                        colId: "height_adjustment_B",
                        filter : true,
                        resizable :true,
                        width:120,
                        editable:false,
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    }
                ]
            },
            {
                headerName: "Upper Deck (Height)",
                headerClass:["cellstylegridG","cellTopHeader"],	
                children: [
                    {
                        headerName: "Min. height",
                        field: "",
                        filter: true,
                        resizable: true,
                        width: 100,
                        valueGetter: function(params){
                            return params.data.upper_deck_height.min
                        },
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	

                    },
                    {
                        headerName: "Max. height",
                        field: "",
                        filter: true,
                        resizable: true,
                        width: 100,
                        valueGetter: function(params){
                            return params.data.upper_deck_height.max
                        },
                        headerClass:"cellstylegridG",
                        cellClass:'cellstylegridG',	
                    },
                ]
            },
            {
                headerName: "Lower Deck (Height)",
                headerClass:["cellstylegridB","cellTopHeader"],
                children: [
                    {
                        headerName: "Min. height",
                        field: "",
                        filter: true,
                        resizable: true,
                        width: 100,
                        valueGetter: function(params){
                            return params.data.lower_deck_height.min
                        },
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                    {
                        headerName: "Max. height",
                        field: "",
                        filter: true,
                        resizable: true,
                        width: 100,
                        valueGetter: function(params){
                            return params.data.lower_deck_height.max
                        },
                        headerClass:"cellstylegridB",
				        cellClass:'cellstylegridB'
                    },
                ]
            },
            {
                headerName: "Transporter Codes",
                field: "",
                filter: true,
                resizable: true,
                width: 130,
                valueGetter: function(params){
                    var lspcodes = [];
                    params.data.transporters.map(function(e){
                        lspcodes.push(e.transporter_code)
                    });
                    return lspcodes
                }
            },
            {
                headerName: "Transporter Names",
                field: "",
                filter: true,
                resizable: true,
                width: 130,
                valueGetter: function(params){
                    var lspcodes = [];
                    params.data.transporters.map(function(e){
                        lspcodes.push(e.transporter_name)
                    })
                    return lspcodes;
                }
            }
            
        ]
        const dStyles={
            width:'100%',
            height:'550px'
        }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row card  col-xl-12 col-lg-12">
                    <div className="card-header form-group col-xl-10 col-lg-10 ">
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rail Load Optimization </span>
                        </h5>
                    </div>
                    <button className="btn btn-success" onClick={this.getOptimizedLoadReport.bind(this)}>Get optimized Load Report</button>
                    <div id="myGrid" style={{width:"100%",height:"380px"}} className="card-body ag-theme-balham col-xl-12 col-lg-12">    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            enableRangeSelection={false}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={50}
                            pagination={true}
                            rowSelection={"single"}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            enableRangeSelection={true}
                            required />

                    </div>
                </div>
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}