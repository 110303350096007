/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css"; import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnDrilldownOnTime from './columndrilldownontime';
import OnTimeColumnChart from './ontimecolumnchart';
import ColumnOverallOnTime from "./columnoverallontime";
import OnTimeLineChart from "./ontimelinechart";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
var moment = require('moment');

var transportercolumnwithDefs = [
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        editable: false,
        resizable: true,
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        editable: false,
        resizable: true,
    },
    {
        headerName: "Total",
        field: "Total",
        editable: false,
        resizable: true,
    },
    {
        headerName: "POD Not Processed",
        field: "POD Not Processed",
        editable: false,
        resizable: true,
    },
    // {
    //     headerName : "POD Not Processed Per",
    //     field : "POD Not Processed Per",
    //     editable : false,
    //     resizable :true,
    //     width:150
    // },
    {
        headerName: "Ontime",
        field: "Ontime",
        editable: false,
        resizable: true,
    },
    // {
    //     headerName : "On Time Per",
    //     field : "Ontime_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // },
    {
        headerName: "Delayed",
        field: "Delayed",
        editable: false,
        resizable: true,
    },
    // {
    //     headerName : "Delayed Per",
    //     field : "Delayed_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // }
]
var consolidatedcolumnwithDefs = [
    {
        headerName: "Route ID",
        field: "route_id",
        editable: false,
        resizable: true,
        width: 160
    },
    {
        headerName: "State",
        field: "consignee_state",
        editable: false,
        resizable: true,

    },
    {
        headerName: "Total",
        field: "Total",
        editable: false,
        resizable: true,
    },
    {
        headerName: "POD Not Processed",
        field: "POD Not Processed",
        editable: false,
        resizable: true,
    },
    // {
    //     headerName : "POD Not Processed Per",
    //     field : "POD Not Processed Per",
    //     editable : false,
    //     resizable :true,
    //     width:150
    // },
    {
        headerName: "Ontime",
        field: "Ontime",
        editable: false,
        resizable: true,
        width: 150
    },

    {
        headerName: "Delayed",
        field: "Delayed",
        editable: false,
        resizable: true,
        width: 150
    },
    {
        headerName: "Ontime Percentage",
        field: "Ontime_per",
        editable: false,
        resizable: true,
        width: 120
    },
    // {
    //     headerName : "Delayed Per",
    //     field : "Delayed_per",
    //     editable : false,
    //     resizable :true,
    //     width:100
    // }
]
var routewisecolumnwithDefs = [

    {
        headerName: "Route ID",
        field: "route_id",
        editable: false,
        resizable: true,
        width: 160
    },
    {
        headerName: "Total",
        field: "Total",
        editable: false,
        resizable: true,
    },
    {
        headerName: "POD Not Processed",
        field: "POD Not Processed",
        editable: false,
        resizable: true,
    },
    {
        headerName: "Ontime",
        field: "Ontime",
        editable: false,
        resizable: true,
    },
    {
        headerName: "Delayed",
        field: "Delayed",
        editable: false,
        resizable: true,
    },
]
var completedatacolumnwithDefs = [
    {
        headerName: "Invoice No",
        field: "invoice_no",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Trip No",
        field: "consignment_code",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Truck No",
        field: "truck_no",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        editable: false,
        resizable: true,
    },
    {
        headerName: "Dealer Code",
        field: "consignee_code",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Dealer Name",
        field: "consignee_name",
        editable: false,
        resizable: true,
        width: 130
    },   
    // {
    //     headerName : "Gate In Date",
    //     field : "gate_in_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.gate_in_time != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time).split(" ")[0];
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    // {
    //     headerName : "Gate In Time",
    //     field : "gate_in_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.gate_in_time != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time).split(" ")[1];
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },

    {
        headerName: "Invoice Date",
        field: "invoice_time",
        editable: false,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
            if (params.data.invoice_time != "") {
                return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
            }
            else {
                return "";
            }
        }
    },
    // {
    //     headerName : "Invoice Time",
    //     field : "invoice_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.invoice_time != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.invoice_time).split(" ")[1];
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    // {
    //     headerName : "Gate Out Date",
    //     field : "gate_out_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.gate_out_time != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time).split(" ")[0];
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    // {
    //     headerName : "Gate Out Time",
    //     field : "gate_out_time",
    //     editable : false,
    //     resizable :true,
    //     width:130,
    //     valueGetter : function(params){
    //         if(params.data.gate_out_time != "")
    //         {
    //             return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time).split(" ")[1];
    //         }
    //         else
    //         {
    //             return "";
    //         }
    //     }
    // },
    {
        headerName: "Expected ETA",
        field: "expected_trip_end",
        editable: false,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
            if (params.data.expected_trip_end != "") {
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
            }
            else {
                return "";
            }
        }
    },

    {
        headerName: "Trip Delivery On",
        field: "first_reported_at_25kms",
        editable: false,
        resizable: true,
        width: 130,
        valueGetter: function (params) {
            if (params.data.first_reported_at_25kms != "") {
                return getHyphenDDMMMYYYYHHMM(params.data.first_reported_at_25kms);
            }
            else {
                return "";
            }
        }
    },


    {
        headerName: "Dealer City",
        field: "consignee_city",
        editable: false,
        resizable: true,
        width: 130
    },
    {
        headerName: "Dealer State",
        field: "consignee_state",
        editable: false,
        resizable: true,
        width: 130
    }
]

const cipher = (salt) => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    catch (e) { }

}

var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class OnTimeDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: false,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowData: [],
            rowDataExcluded: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            locations: [],
            transporters: [],
            transporter: { value: "all", label: "All" },
            location: { value: "all", label: "All" },
            dept_code: '',
            departmentarray: [],
            originaldepartmentarray: [],
            scatterdepartmentarray: [],
            categories: [],
            scattercategories: [],
            transporterdata: [],
            transdrilldown: [],
            transportergpsdata: [],
            transgpsdrilldown: [],
            showtranschart: 'show-m',
            showgpschart: 'show-n',
            loadcheck: true,
            gpsloadcheck: false,
            loadshow: 'show-n',
            overly: 'show-n',
            monthlydata: [],
            monthlyexpdata: [],
            chartdrildown: [],
            showexclude: 0,
            startDate: '',
            endDate: '',
            defaultsdate: '',
            defaultddate: '',
            yearlydata: '',
            statewisedata: [],
            onTimeGrid: "",
            // columnHeaders:[],
            gridOverlay: "show-n",
            gridLoader: "show-n",
            selectedDataType: { label: "Complete Data", value: "Complete Data" },
            columnHeaders: completedatacolumnwithDefs,
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            pagetitle: "OnTime Dashboard",
            eventGridAction: "gridAction",
            chart_search_by: { "label": "Invoice Date", "value": 0 },
            grid_search_by: { "label": "Invoice Date", "value": 0 },
            filter_grid_transporter: { value: "all", label: "All" },
            filter_grid_location: { value: "all", label: "All" },
            grid_start_date: "",
            grid_end_date: "",
            defaultedate: "",
            defaultGridSDate: "",
            defaultGridEDate: "",
            showSummary: "show-m active",
            showDetailed: "show-n",
            startOverly: "show-n",
            startLoader: "show-n",
            detbtn: "btn-defaultc",
            sumbtn: "btn-warning",
            overalldataarray: [],
            zone: [{ value: "all", label: "All" }],
            zones: [],
            truck_nos: [{ value: "all", label: "All" }],
            trucksArr: [],
            truckVal: { value: "all", label: "All" },
            areaList: [],
            areaVal: { value: "all", label: "All" },
            barChartRowData: [],
            showBarChart: "show-m",
            showBarGridData: "show-n",
            completedata: [],
            showPlantWiseChart: "show-m",
            showPlantWiseGrid: "show-n",
            showZoneWiseChart: "show-m",
            showZoneWiseGrid: "show-n",
        }
        this.onLoadShowDashboard = this.onLoadShowDashboard.bind(this);
        this.onShowGridData = this.onShowGridData.bind(this);
    }

    async componentWillReceiveProps(newprops) {
        // if(newprops.dept_code != this.props.dept_code)
        // {

        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

        this.setState({
            transporter: { "value": "all", "label": 'All' },
            location: { "value": "all", "label": 'All' },
            showexclude: newprops.showexclude
        });
        //console.log("newprops asd ",newprops)
        var edate = newprops.endDate;
        var sdate = newprops.startDate;
        var dept_code = newprops.dept_code;
        //var locations=newprops.locations;
        //var transporters=newprops.transporters;
        var gridSDate = moment.parseZone().subtract(30, 'days').format("YYYY-MM-DD");
        var gridEDate = moment.parseZone().format("YYYY-MM-DD");
        var preqparams = {
            dept_code: encode(dept_code)
        }
        this.setState({
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
            grid_start_date: gridSDate,
            grid_end_date: gridEDate,
            defaultGridSDate: gridSDate,
            defaultGridEDate: gridEDate,
            locations: [],
            transporters: []
        })
        var preqparams = {
            dept_code: dept_code
        }
        redirectURL.post("/dashboard/snddeptplants", preqparams)
            .then((response) => {
                var records = response.data.records;
                //console.log("records ", records)
                var locations = [];
                if (records.length > 0) {
                    records.map((item) => {
                        locations.push({
                            code: item.plant_code,
                            label: item.plant_name,
                        })
                    })
                }
                this.setState({
                    locations: locations
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

        redirectURL.post("/masters/transportersbydept", {
            deptcode: encode(dept_code)
        })
            .then((response) => {
                var records = response.data;
                var transporters = [];
                if (records.length > 0) {
                    records.map((item) => {
                        transporters.push({
                            code: item.transporter_code,
                            label: item.transporter_name,
                        })
                    })
                }
                this.setState({
                    transporters: transporters
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            });

        redirectURL.post("/dashboard/zones", preqparams)
            .then((response) => {
                var records = response.data.zones;
                var zones = [];
                if (records.length > 0) {
                    records.map((item) => {
                        zones.push({
                            value: item,
                            label: item
                        })
                    })
                }
                this.setState({
                    zones: zones
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

        redirectURL.post("/consignments/getAreaListForAreaFilter",).then(response => {
            var records = response.data;
            //console.log("records ", records)
            var areaArr = [];
            if (records.length > 0) {
                records.map((item) => {
                    if (item != '') {
                        areaArr.push({
                            value: item,
                            label: item,
                        })
                    }
                })
            }
            this.setState({
                areaList: areaArr
            })
        })
            .catch(function (e) {
                console.log("Error ", e)
            })
        redirectURL.post("/consignments/getTrucksListByDept", { dept_code: encode(dept_code) }).then(trucksResponse => {
            let records = trucksResponse.data
            // console.log("records", records)
            let trucksArr = []
            records.map(e => {
                trucksArr.push({ label: e.truck_no, value: e.truck_no })
            })
            this.setState({
                trucksArr
            })

        })

        var allzones = this.state.zones;
        var zones = []
        if (allzones.length > 0) {
            allzones.map((item) => {
                zones.push(item.value)
            })
        }

        //getPlantWiseLoadingDelayAnalysis
        //console.log(" this.state.transporter ",this.state.transporter)
        var parameters = {
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate,
            transporter: [{ "value": "all", "label": 'All' }],
            location: [{ "value": "all", "label": 'All' }],
            pod_search: this.state.chart_search_by.value,
            // zones: zones,
            // truck_nos: [{ "value": "all", "label": 'All' }],
        }

        await this.onLoadShowDashboard(parameters);
        // var reqparams = {
        //     startDate: gridSDate,
        //     endDate: gridEDate,
        //     transporter: [{ "value": "all", "label": 'All' }],
        //     location: [{ "value": "all", "label": 'All' }],
        //     pod_search: this.state.grid_search_by.value,
        //     // zones: zones,
        //     // truck_nos: ["all"],
        //     // consignee_areas: ["all"]
        // }
        // await this.onShowGridData(reqparams);

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onLoadShowDashboard(parameters) {
        redirectURL.post("/dashboard/getOnTimeGridData", parameters)
            .then((response) => {
                // console.log("onLoadShowDashboard_respData", response.data);
                var records = JSON.parse(response.data[0].plant_wise);
                var rowdata = response.data[1].records
                // console.log("records ", records);
                //var monthlydata = JSON.parse(response.data.monthly_route_wise_data)
                var completedata = JSON.parse(response.data[0].complete_data)
                var consignee_state_wise_data = JSON.parse(response.data[0].plant_zone_wise);
                var zoneplants = JSON.parse(response.data[0].plant_zone_wise);
                var plant_zone_area_wise = JSON.parse(response.data[0].plant_zone_area_wise);
                var plant_zone_area_lsp_grps = JSON.parse(response.data[0].plant_zone_area_lsp_grps);
                // console.log("consignee_state_wise_data",consignee_state_wise_data)

                var trans_consignee_state_wise_data = JSON.parse(response.data[0].transporter_plant_wise);
                // console.log("trans_consignee_state_wise_data",trans_consignee_state_wise_data)

                var route_trans_wise_data = JSON.parse(response.data[0].transporter_wise);
                // console.log("route_trans_wise_data",route_trans_wise_data)

                // var route_consignee_state_wise_data = JSON.parse(response.data.route_consignee_state_wise_data)
                var zonetransporter = JSON.parse(response.data[0].zone_transporter_wise)
                // console.log("monthly_routw_wise_data",monthly_routw_wise_data)
                var monthly_routw_wise_data = JSON.parse(response.data[0].monthly_year_wise);
                var trans_plant_zone_wise = JSON.parse(response.data[0].trans_plant_zone_wise)
                // console.log("trans_plant_zone_wise",trans_plant_zone_wise)
                console.log("response.data", completedata);
                var zone_wise = JSON.parse(response.data[0].zone_wise)
                // console.log("zone_wise",zone_wise)
                var overall = JSON.parse(response.data[0].overall_status);
                console.log("overall ", overall)
                var departmentarray = [];
                var loadingarr = [];
                var botharr = [];
                var delayedarr = [];
                var transdrildownarr = [];
                var categories = [];
                var ontimedata = 0;
                var ontimedatatrucks = 0;
                var overalldataarray = []
                var ontimar = [];
                overall.forEach((item, key) => {
                    if (item.ontime_status == "On Time") {
                        ontimedata = item.type_percentage;
                        ontimedatatrucks = item.type_count;
                        ontimar.push({
                            name: "OnTime",
                            y: item.type_percentage,
                            ontime: item.type_count,
                            // color:"#00F0A6"
                        });
                    }
                })

                overalldataarray.push({
                    // series: [{
                    //     name: "OnTime",
                    //     data: [ontimedata],
                    //     ontime:ontimedatatrucks
                    // }],
                    series: ontimar,
                    categories: ["Ontime"],
                    yaxistitle: "Total.",
                    isfilter: "no"
                })
                // console.log("overalldataarray", overalldataarray)

                records = records.sort(GetSortOrder("consigner_code_order"));
                records.forEach((item, key) => {
                    //var categories = [];
                    categories.push(item.consigner_code)
                    // if(item.Ontime_per > 0)
                    // {
                    //     loadingarr.push(Math.round(item.Ontime_per));
                    // }

                    // delayedarr.push({
                    //     name:item.consigner_code,
                    //     y: Math.round(item.Delayed),
                    //     drilldown:"Delayed-"+item.consigner_code,
                    //     text:"Delayed",
                    //     color:"#ff0000"
                    // });
                    botharr.push({
                        name: item.consigner_code,
                        y: item.On_Time_Percent,
                        drilldown: item.consigner_code,
                        ontime: item.On_Time,
                        // color:"#00F0A6"
                    });

                })
                //Plant Wise Zone level
                var nzoneplants = groupBy(zoneplants, rdata => rdata.consigner_code);
                //console.log("consignee_state_wise_data ", consignee_state_wise_data)
                nzoneplants.forEach((item, key) => {
                    var zp = groupBy(item, rdata => rdata.zone);

                    var trandrilseries = [];
                    zp.forEach((i, k) => {

                        var dtrandrilseries = [];
                        if (i.length > 0) {
                            i.map((itm) => {
                                if (itm.consigner_code == key) {
                                    trandrilseries.push({
                                        name: itm.zone,
                                        y: itm.On_Time_Percent,
                                        drilldown: key + "-" + itm.zone,
                                        ontime: itm.On_Time,
                                    })
                                }

                            })
                        }
                    })

                    trandrilseries = trandrilseries.sort(GetSortDescOrder("y"));
                    transdrildownarr.push({
                        name: key,
                        id: key,
                        data: trandrilseries,
                        dataLabels: {
                            enabled: true
                        }
                    })

                })

                //Plant Wise Zone wise area level
                plant_zone_area_wise = groupBy(plant_zone_area_wise, rdata => rdata.consigner_code);
                //console.log("consignee_state_wise_data ", consignee_state_wise_data)
                plant_zone_area_wise.forEach((item, key) => {
                    var pza = groupBy(item, rdata => rdata.zone);

                    pza.forEach((i, k) => {

                        var trandrilseries = [];
                        var dtrandrilseries = [];
                        if (i.length > 0) {
                            i.map((itm) => {
                                if (itm.consigner_code == key && itm.zone == k) {
                                    trandrilseries.push({
                                        name: itm.consignee_area,
                                        y: itm.On_Time_Percent,
                                        drilldown: key + "-" + k + "-" + itm.consignee_area,
                                        ontime: itm.On_Time,
                                    })
                                }

                            })
                        }
                        trandrilseries = trandrilseries.sort(GetSortDescOrder("y"));
                        transdrildownarr.push({
                            name: key + "-" + k,
                            id: key + "-" + k,
                            data: trandrilseries,
                            dataLabels: {
                                enabled: true
                            }
                        })
                    })



                })

                //Plant Wise Zone wise area wise transporter level
                plant_zone_area_lsp_grps = groupBy(plant_zone_area_lsp_grps, rdata => rdata.consigner_code);
                //console.log("consignee_state_wise_data ", consignee_state_wise_data)
                plant_zone_area_lsp_grps.forEach((item, key) => {
                    var pza = groupBy(item, rdata => rdata.zone);

                    pza.forEach((i, k) => {
                        var pzat = groupBy(i, rdata => rdata.consignee_area);
                        pzat.forEach((a, b) => {
                            var trandrilseries = [];
                            var dtrandrilseries = [];
                            if (a.length > 0) {
                                a.map((itm) => {
                                    if (itm.consigner_code == key && itm.zone == k) {

                                        trandrilseries.push({
                                            name: itm.transporter_name,
                                            y: itm.On_Time_Percent,
                                            ontime: itm.On_Time,
                                        })
                                    }

                                })
                            }
                            trandrilseries = trandrilseries.sort(GetSortDescOrder("y"));
                            transdrildownarr.push({
                                name: key + "-" + k + "-" + b,
                                id: key + "-" + k + "-" + b,
                                data: trandrilseries,
                                dataLabels: {
                                    enabled: true
                                }
                            })
                        })

                    })



                })

                // consignee_state_wise_data = groupBy(consignee_state_wise_data, rdata => rdata.consigner_code);
                // //console.log("consignee_state_wise_data ", consignee_state_wise_data)
                // consignee_state_wise_data.forEach((item, key) => {
                //     // console.log("key ", key)
                //     // console.log("item",item)
                //     var trandrilseries = [];
                //     var dtrandrilseries = [];
                //     if (item.length > 0) {
                //         item.map((itm) => {
                //             trandrilseries.push({
                //                 name: itm.zone,
                //                 y: itm.On_Time_Percent,
                //                 drilldown: itm.zone,
                //                 ontime: itm.On_Time,
                //             })
                //         })
                //     }

                //     trandrilseries = trandrilseries.sort(GetSortDescOrder("y"));
                //     transdrildownarr.push({
                //         name: key,
                //         id: key,
                //         data: trandrilseries,
                //         dataLabels: {
                //             enabled: true
                //         }
                //     })

                // })

                // // console.log("transdrildownarr ",transdrildownarr)
                // trans_plant_zone_wise = groupBy(trans_plant_zone_wise, rdata => rdata.zone);
                // //console.log("consignee_state_wise_data ", consignee_state_wise_data)
                // trans_plant_zone_wise.forEach((item, key) => {
                //     // console.log("key ", key)
                //     // console.log("item",item)
                //     var ctrandrilseries = [];
                //     var cdtrandrilseries = [];
                //     if (item.length > 0) {
                //         item.map((itm) => {
                //             if (itm.zone == key) {
                //                 ctrandrilseries.push({
                //                     name: itm.transporter_name,
                //                     y: itm.On_Time_Percent,
                //                     ontime: itm.On_Time,
                //                     zone: itm.zone,
                //                     plant: itm.consigner_code
                //                 })
                //             }
                //         })
                //     }

                //     ctrandrilseries = ctrandrilseries.sort(GetSortDescOrder("y"));
                //     transdrildownarr.push({
                //         name: key,
                //         id: key,
                //         data: ctrandrilseries,
                //         dataLabels: {
                //             enabled: true
                //         }
                //     })
                // })
                departmentarray.push({

                    delayedseries: [],
                    bothseries: botharr,
                    categories: categories,
                    yaxistitle: "Avg.",
                    isfilter: "no",
                    chartdrildown: transdrildownarr,
                })

                // console.log("departmentarray ", departmentarray)


                /*Zone wise Chart Data*/
                var statewisedataarr = [];
                var stateloadingarr = [];
                var statedrildownarr = [];
                var statecategories = [];
                zone_wise.forEach((item, key) => {
                    //var categories = [];
                    statecategories.push(item.zone)
                    // if(item.Ontime_per > 0)
                    // {
                    //     loadingarr.push(Math.round(item.Ontime_per));
                    // }
                    if (item.On_Time_Percent > 0) {
                        stateloadingarr.push({
                            name: item.zone,
                            y: item.On_Time_Percent,
                            drilldown: item.zone,
                            ontime: item.On_Time
                        });

                    }

                })

                var transconsignee_state_wise = groupBy(zoneplants, rdata => rdata.zone);
                // console.log("consignee_state_wise_data ", transconsignee_state_wise)
                transconsignee_state_wise.forEach((item, key) => {
                    // console.log("itemkey ", item)
                    //   console.log("key ", key)
                    var sttrandrilseries = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            sttrandrilseries.push({
                                name: itm.consigner_code,
                                y: Math.round(itm.On_Time_Percent),
                                drilldown: itm.consigner_code,
                                ontime: itm.On_Time
                            })
                        })
                    }

                    sttrandrilseries = sttrandrilseries.sort(GetSortDescOrder("y"));
                    statedrildownarr.push({
                        name: key,
                        id: key,
                        data: sttrandrilseries,
                        dataLabels: {
                            enabled: true
                        }
                    })

                })

                var tptplant = groupBy(trans_consignee_state_wise_data, rdata => rdata.consigner_code);
                tptplant.forEach((item, i) => {
                    // console.log("item" , item)
                    var pltrans = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            pltrans.push({
                                name: itm.transporter_name,
                                y: itm.On_Time_Percent,
                                ontime: itm.On_Time
                            })
                        })
                    }
                    pltrans = pltrans.sort(GetSortDescOrder("y"));
                    statedrildownarr.push({
                        name: i,
                        id: i,
                        data: pltrans,
                        dataLabels: {
                            enabled: true
                        }
                    })

                });


                //console.log("statecategories ",statecategories)

                statewisedataarr.push({

                    bothseries: stateloadingarr,
                    categories: statecategories,
                    yaxistitle: "Avg.",
                    isfilter: "no",
                    chartdrildown: statedrildownarr,

                })
                // console.log("statewisedataarr ", statewisedataarr)
                var transporterdataarray = [];
                var tranptrloadingarr = [];
                var transpcategories = [];

                route_trans_wise_data.forEach((item, key) => {
                    //console.log("item ", item)
                    //var categories = [];
                    transpcategories.push(item.transporter_name)
                    // if(item.Ontime_per > 0)
                    // {
                    //     loadingarr.push(Math.round(item.Ontime_per));
                    // }
                    if (item.Ontime_per > 0) {
                        tranptrloadingarr.push({
                            name: item.transporter_name,
                            y: Math.round(item.Ontime_per),
                        });
                    }
                })

                tranptrloadingarr = tranptrloadingarr.sort(GetSortDescOrder("y"));
                transporterdataarray.push({

                    series: tranptrloadingarr,
                    categories: transpcategories,
                    yaxistitle: "Avg.",
                    isfilter: "no"
                })

                //console.log("transporterdata ", transporterdataarray)


                //Monthly Line Chart
                var monthcategories = [];
                var monthlydataarr = [];
                // var monthlydatagroup = groupBy(monthly_routw_wise_data, rdata => rdata.route_id);
                // monthly_routw_wise_data = monthly_routw_wise_data.sort(GetSortOrder("index"));
                var mncolgroups = groupBy(monthly_routw_wise_data, rdata => (rdata.invoice_month + " " + rdata.invoice_year));

                //console.log("monthlydatagroup ", monthlydatagroup)

                var monthgrparr = [];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr = []
                var monthloadarr = [];
                mncolgroups.forEach((item, key) => {
                    var monthgpsloadarr = [];

                    monthcategories.push(key)
                    // var itemdata = item.sort(GetSortOrder("gate_out_month_order"));
                    item.map((val) => {
                        //colgroups.forEach((ci,ky) => {
                        //   console.log("CI ",ky)
                        // if(key == val.route_id)
                        // {
                        // monthloadarr.push({
                        //   name:key,
                        //   y:val.On_Time_Percent
                        // })
                        monthloadarr.push(val.On_Time_Percent);

                        // }
                        // })

                    });
                    //console.log(key);
                    //console.log(item);
                    //console.log("timeloadarr ", timeloadarr)

                })
                // mncolgroups.sort(GetSortOrder("month_order"));
                // console.log("monthlydataarr ",monthlydataarr)
                //  mncolgroups.forEach((it,ky) =>{
                //      monthcategories.push(ky)
                //  })
                monthgrparr.push({
                    name: "Ontime",
                    data: monthloadarr
                })
                monthlydataarr.push({
                    categories: monthcategories,
                    lineseries: monthgrparr
                })

                // console.log("monthlydataarr ", monthlydataarr)

                this.setState({
                    departmentarray: departmentarray,
                    originaldepartmentarray: departmentarray,
                    statewisedata: statewisedataarr,
                    transporterdata: transporterdataarray,
                    monthlydata: monthlydataarr,
                    overalldataarray: overalldataarray,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    startLoader: "show-n",
                    startOverly: "show-n",
                    rowData: rowdata,
                    completedata
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }
    onShowGridData = (params) => {
        this.setState({
            gridOverlay: "show-m",
            gridLoader: "show-m",
        })
        redirectURL.post("/dashboard/getOnTimeGridData", params).then((response) => {
            var gridData = response.data[1].records;
            console.log("OntimeGridData", gridData);
            console.log("ResponseData", response.data);
            var completedata = JSON.parse(response.data[0].complete_data)

            console.log("CompleteData", completedata)
            // console.log("transporter",JSON.parse(data[0].trans_wise_data));
            // console.log("consolidated",JSON.parse(data[0].route_consignee_state_wise_data));
            // console.log("route wise",JSON.parse(data[0].route_wise_data));
            // var completedata = [];
            // gridData.map(function(e){
            //     completedata.push({...e._id,...e.data})
            // })
            // console.log(completedata, "before dropping")
            // var club_loads = []
            // completedata.map((e)=>{
            //     club_loads.push(e.club_load_no)
            // })
            // completedata = completedata.filter(e=> club_loads.includes(e.load_no) == false)
            // var newCompleteData = [];
            // completedata.map((e)=>{
            //     if(e.cluster == "NCR" && e.consigner_code != "G"){}else{newCompleteData.push(e)}
            // })
            // console.log(newCompleteData.length, "after dropping")
            
            this.setState({
                // onTimeGrid : gridData,
                rowData: gridData,
                gridLoader: "show-n",
                gridOverlay: "show-n"
            });

        }).catch(function (e) {
            console.log("Error ", e)
        })
    }
    onClickShowTab(event) {
        console.log(event);
        if (event == "summary") {
            this.setState({
                showSummary: "show-m",
                showDetailed: "show-n",
                detbtn: "btn-defaultc",
                sumbtn: "btn-warning"
                // gridOverlay : "show-n",
                // gridLoader : "show-n"
            })
        }
        else {
            this.setState({
                showSummary: "show-n",
                showDetailed: "show-m",
                detbtn: "btn-warning",
                sumbtn: "btn-defaultc"
                // loadshow : "show-n",
                // overly : "show-n"
            })
        }
    }
    onSelectLocation = location => {
        this.setState(
            { location },
            () => console.log(`Location Option selected:`, this.state.location)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectGridLocation = filter_grid_location => {
        this.setState(
            { filter_grid_location },
            () => console.log(`Location Option selected:`, this.state.filter_grid_location)
        );
    }
    onSelectChartSearchType = chart_search_by => {
        this.setState(
            { chart_search_by },
            () => console.log(`Location Option selected:`, this.state.chart_search_by)
        )
    }
    onSelectGridSearchType = grid_search_by => {
        this.setState(
            { grid_search_by },
            () => console.log(`Location Option selected:`, this.state.grid_search_by)
        )
    }

    onSelectTransporter = transporter => {
        this.setState(
            { transporter },
            () => console.log(`Transporter Option selected:`, this.state.transporter)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectZone = zone => {
        this.setState(
            { zone },
            () => console.log(`Transporter Option selected:`, this.state.zone)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectArea = areaVal => {
        this.setState(
            { areaVal }, () => console.log(this.state.areaVal)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectTruck = truckVal => {
        this.setState(
            { truckVal }, () => console.log(this.state.truckVal)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectGridTransporter = filter_grid_transporter => {
        this.setState(
            { filter_grid_transporter },
            () => console.log(`Transporter Option selected:`, this.state.filter_grid_transporter)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    locationoptions() {
        var items = [];
        // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if (locations.length > 0) {
            items.push({ value: 'all', label: 'All' });
            locations.forEach(item => {
                items.push({ value: item.code, label: item.label });
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions() {
        var items = []
        var transporters = this.state.transporters;
        items.push({ value: 'all', label: 'All' });
        if (transporters.length > 0) {
            transporters.forEach(item => {
                items.push({ value: item.code, label: item.label });
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerGridStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            grid_start_date: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            endDate: edate
        });
    }
    handlerGridEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            grid_end_date: edate
        });
    }
    onClickGetLoadingData = (event) => {
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Filters",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m',
            overly: "show-m",
            departmentarray: [],
        })
        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var startDate = this.state.defaultsdate;
        }
        else {
            var startDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
            var endDate = this.state.defaultedate;
        }
        else {
            var endDate = this.state.endDate;
        }
        if (this.state.location == '' || this.state.location == null) {
            var location = [{ "value": "all", "label": "All" }];
        }
        else {
            var location = this.state.location;
        }
        if (this.state.transporter == '' || this.state.transporter == null) {
            var transporter = [{ "value": "all", "label": "All" }];
        }
        else {
            var transporter = this.state.transporter;
        }

        if (Array.isArray(location) == true) {
            var locations = location
        }
        else {
            var locations = [location]
        }
        if (Array.isArray(transporter) == true) {
            var transporters = transporter
        }
        else {
            var transporters = [transporter]
        }
        if (this.state.areaVal == '' || this.state.areaVal == null) {
            var areaVal = [{ "value": "all", "label": "All" }];
        }
        else {
            areaVal = this.state.areaVal;
        }
        if (this.state.truckVal == '' || this.state.truckVal == null) {
            var truckVal = [{ "value": "all", "label": "All" }];
        }
        else {
            truckVal = this.state.truckVal;
        }
        if (Array.isArray(areaVal) == true) {
            var consignee_areas = areaVal
        }
        else {
            consignee_areas = [areaVal]
        }
        if (Array.isArray(truckVal) == true) {
            var truck_nos = truckVal
        }
        else {
            truck_nos = [truckVal]
        }
        var allzones = this.state.zone;
        // console.log("allzone ", allzones);
        var zones = []
        if (allzones != null) {
            if (allzones.length > 0) {
                allzones.map((item) => {
                    zones.push(item.value)
                })
            }
        }
        else {
            zones.push('all');
        }

        var parms = {
            startDate: startDate,
            endDate: endDate,
            location: locations,
            transporter: transporters,
            dept_code: this.state.dept_code,
            pod_search: this.state.chart_search_by.value,
            zones: zones,
            consignee_areas,
            truck_nos
        }
        this.onLoadShowDashboard(parms)
    }
    onClickGetGridData = (event) => {
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Filters",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            gridOverlay: 'show-m',
            gridLoader: "show-m",
            //  departmentarray:[],
        })
        if (this.state.grid_start_date == '' || this.state.grid_start_date == "NaN-NaN-NaN") {
            var startDate = this.state.defaultsdate;
        }
        else {
            var startDate = this.state.grid_start_date;
        }
        if (this.state.grid_end_date == '' || this.state.grid_end_date == "NaN-NaN-NaN") {
            var endDate = this.state.defaultedate;
        }
        else {
            var endDate = this.state.grid_end_date;
        }
        if (this.state.filter_grid_location == '' || this.state.filter_grid_location == null) {
            var location = [{ "value": "all", "label": "All" }];
        }
        else {
            var location = this.state.filter_grid_location;
        }
        if (this.state.filter_grid_transporter == '' || this.state.filter_grid_transporter == null) {
            var transporter = [{ "value": "all", "label": "All" }];
        }
        else {
            var transporter = this.state.filter_grid_transporter;
        }
        if (this.state.areaVal == '' || this.state.areaVal == null) {
            var areaVal = [{ "value": "all", "label": "All" }];
        }
        else {
            areaVal = this.state.areaVal;
        }
        if (this.state.truckVal == '' || this.state.truckVal == null) {
            var truckVal = [{ "value": "all", "label": "All" }];
        }
        else {
            truckVal = this.state.truckVal;
        }
        if (Array.isArray(location) == true) {
            var locations = location
        }
        else {
            var locations = [location]
        }
        if (Array.isArray(transporter) == true) {
            var transporters = transporter
        }
        else {
            var transporters = [transporter]
        }
        if (Array.isArray(areaVal) == true) {
            var consignee_areas = areaVal
        }
        else {
            var consignee_areas = [areaVal]
        }
        if (Array.isArray(truckVal) == true) {
            var truckVal = truckVal
        }
        else {
            var truckVal = [truckVal]
        }

        var allzones = this.state.zones;
        var zones = []
        if (allzones.length > 0) {
            allzones.map((item) => {
                zones.push(item.value)
            })
        }
        var parms = {
            startDate: startDate,
            endDate: endDate,
            location: locations,
            transporter: transporters,
            dept_code: this.state.dept_code,
            pod_search: this.state.grid_search_by.value,
            consignee_areas,
            truck_nos: truckVal
        }
        // console.log("params", parms)
        this.onShowGridData(parms)
    }


    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        //    ChartMenu.prototype.saveChart = function (e) {
        // 	   var event = {
        // 	      data: this.gridOptionsWrapper.getApi(),
        // 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
        // 	    };
        // 	   //console.log("Testing", event);
        // 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
        // 	   this.dispatchEvent(event);
        // 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

        //    };


    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }

    onChangeChartView(params) {


        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Change Chart View",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (params == 'gpsloading') {
            this.setState({
                gpsloadcheck: true,
                loadcheck: false,
                showgpschart: 'show-m',
                showtranschart: 'show-n'
            })
        }
        if (params == 'loading') {
            this.setState({
                gpsloadcheck: false,
                loadcheck: true,
                showgpschart: 'show-n',
                showtranschart: 'show-m'
            })
        }
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',

        });

    }
    // getOnTimeGridData = (params) => {
    //     var selectedvalue = params.value;
    //     console.log(this.state.onTimeGrid, "selectedValue");
    //     if (selectedvalue === "Route Wise") {
    //         this.setState({
    //             rowData: JSON.parse(this.state.onTimeGrid[0].route_wise_data),
    //             selectedDataType: { label: selectedvalue, value: selectedvalue },
    //             columnHeaders: routewisecolumnwithDefs
    //         });
    //     }
    //     if (selectedvalue === "TPT Wise") {
    //         // console.log(JSON.parse(this.state.onTimeGrid[0].trans_wise_data))
    //         this.setState({
    //             rowData: JSON.parse(this.state.onTimeGrid[0].trans_wise_data),
    //             selectedDataType: { label: selectedvalue, value: selectedvalue },
    //             columnHeaders: transportercolumnwithDefs
    //         });
    //     }
    //     if (selectedvalue === "consolidated") {
    //         this.setState({
    //             rowData: JSON.parse(this.state.onTimeGrid[0].route_consignee_state_wise_data),
    //             selectedDataType: { label: selectedvalue, value: selectedvalue },
    //             columnHeaders: consolidatedcolumnwithDefs
    //         });
    //     }
    //     if (selectedvalue === "Complete Data") {
    //         var completeData = this.state.onTimeGrid[1];
    //         completeData = completeData.filter(function (e) {
    //             if (e.cluster === "NCR" && e.consigner_code != "G") {
    //                 console.log("excluded");
    //             }
    //             else {
    //                 return e;
    //             }
    //         })
    //         this.setState({
    //             columnHeaders: completedatacolumnwithDefs,
    //             rowData: completeData,
    //             selectedDataType: { label: selectedvalue, value: selectedvalue },

    //         })
    //     }
    //     console.log(this.state.selectedDataType, "selectedValue");

    // }

    onClickBarChartGrid = () => {
        this.setState({
            showBarGridData: "show-m",
            showBarChart: "show-n"
        })
    }

    onClickShowBarChartView = () => {
        this.setState({
            showBarGridData: "show-n",
            showBarChart: "show-m"
        })
    }

    onClickShowPlantBarChartView = () => {
        this.setState({
            showPlantWiseChart: "show-n",
            showPlantWiseGrid: "show-m"
        })
    }

    onClickPlantBarChartGrid = () => {
        this.setState({
            showPlantWiseChart: "show-m",
            showPlantWiseGrid: "show-n"
        })
    }

    onClickShowZoneBarChartView = () => {
        this.setState({
            showZoneWiseChart: "show-n",
            showZoneWiseGrid: "show-m"
        })
    }

    onClickZoneBarChartGrid = () => {
        this.setState({
            showZoneWiseChart: "show-m",
            showZoneWiseGrid: "show-n"
        })
    }
    render() {
        var hideinvoice = (this.state.dept_code == 'LOG-PRT') ? true : false;
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    //console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                // 	browserDatePicker: true,
                // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                // 	// provide comparator function
                // 	comparator: function(filteredDate,cellValue,secondDate) {
                // 		cellValue = cellValue.replace(/\//g,"-")
                // 		if(cellValue != "" && cellValue != " "){
                // 			cellValue = cellValue.split(" ")[0].split("-");
                // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                // 			var cellDate = new Date(cellValue);
                // 			if(filteredDate.getMonth() < 10){
                // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			else{
                // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			if(filterValue.split("-")[2].length < 2)
                // 			{
                // 				var temp = filterValue
                // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                // 			}
                // 			var filteredDate = new Date(filterValue);
                // 			cellDate = cellDate.getTime();
                // 			filteredDate = filteredDate.getTime();
                // 			// console.log(cellDate,filteredDate);
                // 			if(cellDate === filteredDate)
                // 			{
                // 				return 0;
                // 			}
                // 			if(cellDate < filteredDate){
                // 				return -1;
                // 			}
                // 			if(cellDate > filteredDate)
                // 			{
                // 				return 1;
                // 			}
                // 		}
                // 	}
                // },
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Trip Distance",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: " Trip Duration ( + 0.25 days)",
                field: "trip_completion_days",
                width: 150
            },
            {
                headerName: " Trip Distance Range",
                field: "standard_distance_bin",
                width: 150
            },
            {
                headerName: "Standard Avg. Distance/Day",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day",
                field: "actual_average_days",
                width: 150
            }
        ]

        var columnwithDefsExclude = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                // 	browserDatePicker: true,
                // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                // 	// provide comparator function
                // 	comparator: function(filteredDate,cellValue,secondDate) {
                // 		cellValue = cellValue.replace(/\//g,"-")
                // 		if(cellValue != "" && cellValue != " "){
                // 			cellValue = cellValue.split(" ")[0].split("-");
                // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                // 			var cellDate = new Date(cellValue);
                // 			if(filteredDate.getMonth() < 10){
                // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			else{
                // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			if(filterValue.split("-")[2].length < 2)
                // 			{
                // 				var temp = filterValue
                // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                // 			}
                // 			var filteredDate = new Date(filterValue);
                // 			cellDate = cellDate.getTime();
                // 			filteredDate = filteredDate.getTime();
                // 			// console.log(cellDate,filteredDate);
                // 			if(cellDate === filteredDate)
                // 			{
                // 				return 0;
                // 			}
                // 			if(cellDate < filteredDate){
                // 				return -1;
                // 			}
                // 			if(cellDate > filteredDate)
                // 			{
                // 				return 1;
                // 			}
                // 		}
                // 	}
                // },
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Trip Distance",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: " Trip Duration ( + 0.25 days)",
                field: "trip_completion_days",
                width: 150
            },
            {
                headerName: " Trip Distance Range (KM)",
                field: "standard_distance_bin",
                width: 150
            },
            {
                headerName: "Standard Avg. Distance/Day (KM)",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day (KM)",
                field: "actual_average_days",
                width: 150
            }
        ]
        const gridoverlaystyle = {
            position: "absolute",
            background: "white",
            top: "11.2em",
            left: "1.1em",
            opacity: "0.2",
            width: "88%",
            height: "37em",
        }
        const gridloaderstyle = {
            position: "absolute",
            top: "23em",
            left: "41em"
        }

        const performanceColumnWithDefs = [
            {
                headerName: "Plant",
                field: "consigner_code",
                width: 100
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 100
            },
            {
                headerName: "Zone",
                field: "zone",
                width: 80
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 140
            },
            {
                headerName: "OnTime Status",
                field: "ontime_status",
                width: 100
            },
            {
                headerName: "Invoice No",
                field: "invoice_no",
                width: 100
            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                width: 130,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                },
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                width: 100
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 100
            },

        ]
        return (
            <div className="card-body n-p-0 mt-10p">
                <ul className="ul-tabs mt-25p">
                    <li>
                        <a href="javascript:;" className={"btn " + (this.state.sumbtn)} id="summary" onClick={this.onClickShowTab.bind(this, 'summary')}>Summary</a>
                    </li>
                    <li>
                        <a href="javascript:;" className={"btn " + (this.state.detbtn)} id="detailed" onClick={this.onClickShowTab.bind(this, 'detailed')}>Detailed</a>
                    </li>
                    {/* <li>
                        <a href="javascript:;" className="loadclick" id="LOG-TNP" onClick={this.onClickLoadDept.bind(this,'LOG-TNP')}>Train and Production</a>
                    </li> */}
                </ul>
                <div className="row pt-20px tab-brd">
                    <div class={this.state.showDetailed + " col-xl-12 col-lg-12"}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Search By</label><br />
                                <Select
                                    placeholder={"All"}
                                    isMulti={false}
                                    value={this.state.grid_search_by}
                                    className="border-radius-0"
                                    onChange={this.onSelectGridSearchType.bind(this)}
                                    style={{ borderRadius: "0px" }}
                                    options={[{ "label": "Invoice Date", "value": 0 }, { "label": "POD Received on", "value": 1 }]} required />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Start Date: </label>
                                <Datetime value={this.state.grid_start_date}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="startDate"
                                    onChange={this.handlerGridStartDateTime.bind(this)} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>End Date: </label>
                                <Datetime value={this.state.grid_end_date}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="endDate"
                                    onChange={this.handlerGridEndDateTime.bind(this)} />
                            </div>
                            <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                <label>Location: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.filter_grid_location} className="border-radius-0" onChange={this.onSelectGridLocation.bind(this)} style={{ borderRadius: "0px" }} options={this.locationoptions()} required />
                            </div>

                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Transporter: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.filter_grid_transporter} className="border-radius-0" onChange={this.onSelectGridTransporter} style={{ borderRadius: "0px" }} options={this.transporteroptions()} required />
                            </div>
                            <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                <label>Zone: </label>
                                <Select placeholder={"All"}
                                    isMulti={true}
                                    value={this.state.zone}
                                    className="border-radius-0"
                                    onChange={this.onSelectZone}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.zones}
                                    required />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Area: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.areaVal} className="border-radius-0" onChange={this.onSelectArea} style={{ borderRadius: "0px" }} options={this.state.areaList} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Trucks: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.truckVal} className="border-radius-0" onChange={this.onSelectTruck} style={{ borderRadius: "0px" }} options={this.state.trucksArr} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-success" onClick={this.onClickGetGridData}>Submit</button>
                            </div>
                        </div>
                        {/*  <div className="col-xl-3 col-lg-3" style={{marginTop:"23px"}}>
                            <label>Select Type : </label>
                            <Select
                            value={this.state.selectedDataType}
                            className="border-radius-0"
                            isMulti={false}
                            id="selectedFilter"
                            style={{borderRadius:"0px"}}
                            onChange = {this.getOnTimeGridData}
                            options={[
                                {value:"TPT Wise",label:"Transporter Wise"},
                                {label:"Route Wise",value:"Route Wise"},
                                {label:"Consolidated",value:"consolidated"},
                                {label:"Complete Data",value:"Complete Data"}
                                ]} required />
                        </div>*/}
                        <div className="col-xl-12">
                            <div id="myGrid" style={{ width: "100%", height: "460px", marginTop: "23px" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnHeaders}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.completedata}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    suppressRowClickSelection={true}
                                />

                            </div>
                        </div>
                        <div className={"grid-loader " + this.state.gridOverlay} style={gridoverlaystyle}></div>
                        <div className={"loader-box " + this.state.gridLoader} style={gridloaderstyle}><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div class={this.state.showSummary + " col-xl-12 col-lg-12"}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Search By</label><br />
                                <Select
                                    placeholder={"All"}
                                    isMulti={false}
                                    value={this.state.chart_search_by}
                                    className="border-radius-0"
                                    onChange={this.onSelectChartSearchType.bind(this)}
                                    style={{ borderRadius: "0px" }}
                                    options={[{ "label": "Invoice Date", "value": 0 }, { "label": "Delivery Date", "value": 1 }]} required />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Start Date: </label>
                                <Datetime value={this.state.startDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>End Date: </label>
                                <Datetime value={this.state.endDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="endDate"
                                    onChange={this.handlerEndDateTime.bind(this)} />
                            </div>
                            <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                <label>Location: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation.bind(this)} style={{ borderRadius: "0px" }} options={this.locationoptions()} required />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Transporter: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{ borderRadius: "0px" }} options={this.transporteroptions()} required />
                            </div>
                            <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                <label>Zone: </label>
                                <Select placeholder={"All"}
                                    isMulti={true}
                                    value={this.state.zone}
                                    className="border-radius-0"
                                    onChange={this.onSelectZone}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.zones}
                                    required />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Area: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.areaVal} className="border-radius-0" onChange={this.onSelectArea} style={{ borderRadius: "0px" }} options={this.state.areaList} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Trucks: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.truckVal} className="border-radius-0" onChange={this.onSelectTruck} style={{ borderRadius: "0px" }} options={this.state.trucksArr} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData}>Submit</button>
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="row col-xl-12 col-lg-12 mb-40p">
                                {this.state.overalldataarray.length > 0 ?

                                    this.state.overalldataarray.map(item =>
                                        <div className="col-xl-12 col-lg-12">
                                            <span className={this.state.showBarChart}>
                                                <ColumnOverallOnTime
                                                    title={"Overall Ontime Performance"}
                                                    subtitle={"Delivery performance % across all consignments for the selected duration"}
                                                    context={this}
                                                    series={item.series}
                                                    categories={item.categories}
                                                    yaxistitle={item.yaxistitle}
                                                    isfilter={item.isfilter}
                                                    dept_code={this.state.dept_code}
                                                />
                                            </span>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showBarChart)}
                                                onClick={this.onClickBarChartGrid}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Data</button>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showBarGridData)}
                                                onClick={this.onClickShowBarChartView}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                            <div className={"col-sm-12 " + (this.state.showBarGridData)}>
                                                <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Overall Ontime Performance</h4>
                                                <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Delivery performance % across all consignments for the selected duration</h6>
                                                <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        defaultColDef={this.state.defaultColDef}
                                                        columnDefs={performanceColumnWithDefs}
                                                        rowData={this.state.completedata}
                                                        enableCharts={true}
                                                        enableRangeSelection={true}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    )
                                    : ""}
                                {this.state.departmentarray.length > 0 ?

                                    this.state.departmentarray.map(item =>
                                        <div className="col-xl-12 col-lg-12">
                                            <span className={this.state.showPlantWiseChart}>
                                                <ColumnDrilldownOnTime
                                                    title={"Plant wise Ontime Performance"}
                                                    subtitle={"Delivery performance % across all consignments originating from the plants for the selected duration"}
                                                    context={this}
                                                    delayedseries={item.delayedseries}
                                                    bothseries={item.bothseries}
                                                    drildown={item.chartdrildown}
                                                    categories={item.categories}
                                                    yaxistitle={item.yaxistitle}
                                                    isfilter={item.isfilter}
                                                    dept_code={this.state.dept_code}
                                                />
                                            </span>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showPlantWiseChart)}
                                                onClick={this.onClickShowPlantBarChartView}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Data</button>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showPlantWiseGrid)}
                                                onClick={this.onClickPlantBarChartGrid}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                            <div className={"col-sm-12 " + (this.state.showPlantWiseGrid)}>
                                                <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Plant wise Ontime Performance</h4>
                                                <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Delivery performance % across all consignments originating from the plants for the selected duration</h6>
                                                <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        defaultColDef={this.state.defaultColDef}
                                                        columnDefs={performanceColumnWithDefs}
                                                        rowData={this.state.completedata}
                                                        enableCharts={true}
                                                        enableRangeSelection={true}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    )
                                    : ""}

                                {this.state.statewisedata.length > 0 ?

                                    this.state.statewisedata.map(item =>
                                        <div className="col-xl-12 col-lg-12">
                                            <span className={this.state.showZoneWiseChart}>
                                            <ColumnDrilldownOnTime
                                                title={"Zone wise Ontime Performance"}
                                                subtitle={"Delivery performance % across all consignments delivered to the zones for the selected duration"}
                                                context={this}
                                                bothseries={item.bothseries}
                                                drildown={item.chartdrildown}
                                                categories={item.categories}
                                                yaxistitle={item.yaxistitle}
                                                isfilter={item.isfilter}
                                                dept_code={this.state.dept_code}
                                            />
                                            </span>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showZoneWiseChart)}
                                                onClick={this.onClickShowZoneBarChartView}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Data</button>
                                            <button
                                                type="button"
                                                className={"btn-sm btn-outline-danger " + (this.state.showZoneWiseGrid)}
                                                onClick={this.onClickZoneBarChartGrid}
                                                style={{ position: "absolute", right: "10px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                            <div className={"col-sm-12 " + (this.state.showZoneWiseGrid)}>
                                                <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Zone wise Ontime Performance</h4>
                                                <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Delivery performance % across all consignments delivered to the zones for the selected duration</h6>
                                                <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        defaultColDef={this.state.defaultColDef}
                                                        columnDefs={performanceColumnWithDefs}
                                                        rowData={this.state.completedata}
                                                        enableCharts={true}
                                                        enableRangeSelection={true}
                                                        onGridReady={this.onGridReady}
                                                        onGridState={this.onGridState}
                                                        statusBar={this.state.statusBar}
                                                        sideBar={this.state.sideBar}
                                                        paginationPageSize={this.state.paginationPageSize}
                                                        pagination={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    )
                                    : ""}

                                {/* {this.state.transporterdata.length > 0?

                                this.state.transporterdata.map(item =>
                                    <div className="col-xl-12 col-lg-12">
                                        <OnTimeColumnChart
                                            title={"Transporter wise On Time Performance"}
                                            subtitle={""}
                                            context={this}
                                            series={item.series}
                                            categories={item.categories}
                                            yaxistitle={item.yaxistitle}
                                            isfilter={item.isfilter}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>

                                )
                            :""} */}

                                {this.state.monthlydata.length > 0 ?

                                    this.state.monthlydata.map(item =>
                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <OnTimeLineChart
                                                title={'Monthly Ontime'}
                                                subtitle={"Month wise delivery performance %"}
                                                context={this}
                                                series={item.lineseries}
                                                categories={item.categories}
                                                xaxistitle={""}
                                                yaxistitle={"Ontime (%)"}
                                                pointpostfix={""}
                                                dept_code={this.state.dept_code}
                                            />
                                        </div>

                                    )
                                    : ""}
                            </div>
                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow)} ></div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)} >
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.startLoader)} ></div>
                <div className={"dataLoadpageimg " + (this.state.startLoader)} >
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay " + (this.state.startOverly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


$(document).ready(function () {
    $("#detailed").removeClass("active");
    $(".loadclick").click(function () {
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#" + id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}
