
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const Mllbatchlegs = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowBatchLegs(props);
    };

    return (
        <div>
            <button 
              onClick={handleOverspeedClick} 
              className={"custom-btn f16 label label-success "} 
              title="Show Batches" >
                  <i className="icofont icofont-list f16"></i>&nbsp;
            </button>
          
        </div>
    );
};

export default Mllbatchlegs;
