import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ChangeDriverDetails = (props) => {
    
    const handleEventClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onChangeDriverDetails(props);
    };
    

    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-warning custom-btn f12 label label-success" 
            title="Change Driver Details" >Change Driver Details</button>    
        </div>
    );
};

export default ChangeDriverDetails;
