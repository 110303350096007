
import React from 'react';

 
const ConsignmentActions = (props) => {

    // var isDisabled = "";

    // if(props.data.hasOwnProperty("vehicle_mode") && props.data.vehicle_mode !== "Surface Shipment"){
    //     isDisabled="disabled"
    // }else{
    //     isDisabled=""
    // }

    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log("props.data",props);
        props.context.componentParent.onCloseUploadDiv(e);
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onShowRouteDiv(props);
        // if(props.data.hasOwnProperty("vehicle_mode") && props.data.vehicle_mode === "Surface Shipment"){
        //     props.context.componentParent.onShowRouteDiv(props);
        //     // console.log(props);
        // }        
    };

    return (
        <div className="map-icon-component">
            {/* <button onClick={handleRouteClick} className={`custom-btn label label-success ${isDisabled}`} title="View Route"><i className="icofont icofont-map-pins f25"></i> </button> */}
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25"></i> </button>
        </div>
    );
};

export default ConsignmentActions;
