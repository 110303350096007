'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
import Showlmvalue from "./showlmvalue";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmTruckAvailability extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          Showlmvalue:Showlmvalue
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        bulkslide1:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        trnsportersList : [],
        statesList : [],
        vehicleList: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        showGridData1 : "show-n",
        transdata:[],
        transactionId : "",
        partial: "activet",
        complete: "",
        partialData: "",
        completeData: "show-n",
        truckData: [],
        lmtransporters: [],
        selectedTransporter : {},
        lmvehicletypes: [],
        pageType: 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
    }
    this.onLoadShowData = this.onLoadShowData.bind(this);
    this.createColumns = this.createColumns.bind(this);
    this.createRows = this.createRows.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.uploadSingleFormHandler = this.uploadSingleFormHandler.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    this.pageAccess(1);
    var transactionId = "";
    if(this.props.match.params.eventname !='default')
    {
        var transactionId = window.atob(this.props.match.params.eventname);
    }
    this.setState({
        transactionId : transactionId
    });
}
async pageAccess(pageType){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var currentUrl = "/lmtruckavailability/default";
    console.log(currentUrl,"currentUrl")
    var is_dept_admin = localStorage.getItem("is_department_admin");
    var role = localStorage.getItem("roles");
    if(role.indexOf("lm_site_admin") >= 0 || is_dept_admin == 1 || role.indexOf("lm_admin") >= 0)
    {
        this.setState({
            pageAccess : 3,
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then(async (response2)=>{
            //console.log(response1.data,"LLPN");
            var lpData = response2.data;
            var lpNames = [];
            if(lpData.length > 0)
            {
                lpData.map((item) => {
                    if(item.comp_short_name != '' && item.comp_short_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.comp_short_name,
                        })
                    }
                })
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = lpData.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    var loading_port_code = lpData[0].loading_port_code;
                    comp_code = lpData[0].comp_code;
                }
                this.setState({
                    comp_code : comp_code,
                    loading_port_code : loading_port_code,
                    loadshow:'show-n',
                    overly:'show-n',
                    lpNames:lpNames
                });
                console.log(lpData[0].loading_port_code,"lpData[0].loading_port_code")
                if(pageType == 1)
                {
                    await this.onLoadShowData();
                }
                else
                {
                    await this.loadPageData();
                }
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        var query = {
            "userid" : localStorage.getItem("userid"),
            "menu_url" : currentUrl,
        }
        redirectURL.post("/lmconsignees/checkUserCurrentPageAccess",query).then(async (response)=>{
            var result = response.data;
            if(result.length > 0)
            {                
                var pageAccess = 2;
                var lpNames = [];
                await result.map(async function(item){
                    if(item.loading_port_name != '' && item.loading_port_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.loading_port_name,
                        })
                    }
                })
                
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = result.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {                        
                        if(result1[0].access !='' && result1[0].access !=undefined)
                        {
                            var pageAccess = result1[0].access;                    
                        }
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    if(result[0].access !='' && result[0].access !=undefined)
                    {
                        var pageAccess = result[0].access;                    
                    }
                    var loading_port_code = result[0].loading_port_code;
                    comp_code = result[0].comp_code;
                }
                //console.log(lpNames,"lpNames")
                this.setState({
                    pageAccess : pageAccess,
                    loading_port_code : loading_port_code,
                    comp_code : comp_code,
                    lpNames : lpNames,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(loading_port_code,"loading_port_code")
                if(pageType == 1)
                {
                    await this.onLoadShowData();
                }
                else
                {
                    await this.loadPageData();
                }
            }
            else
            {
                alert("You Don't have access to this screen, Please request access")
                window.location.href="/lmpageaccess";
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}
onLoadShowData = async(transactionId) =>{
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    //var truckUrl = "/lmconsignees/getLmTruckAvailability";
    // Data From Autometrics
    var truckUrl = "/lmconsignees/getPRTTruckAvailableData";
    //var truckUrl = "/lmconsignees/getLMTruckAvailableData";
    // if(transactionId !=undefined && transactionId !='')
    // {
    //     var truckUrl = "/lmconsignees/getUpdatedLmTruckAvailability";
    // }
    console.log(truckUrl,"truckUrl")
    var loading_port_code = this.state.loading_port_code;
    var sdate = moment.parseZone().format('YYYY-MM-DD');
    var edate = moment.parseZone().format('YYYY-MM-DD');
    var params = {
        transactionId : transactionId,
        loading_port_code : loading_port_code,
        startDate : sdate,
        endDate : edate,
        request_type : 1
    }
    await redirectURL.post(truckUrl,params).then((response)=>{
        console.log(response.data,"resultssss");
        this.setState({
            transdata : response.data
        })
    }).catch((e)=>{
        console.log(e);
    })
    await this.createColumns();
    await this.createRows();
}
loadPageData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    var loading_port_code = this.state.loading_port_code;
    var params = {
        loading_port_code : loading_port_code,
    }
    redirectURL.post('/lmconsignees/getLmUniqueTransporters',params)
    .then((response) => {
        console.log(response.data,"lmtransporters");
        var lmtransporters = response.data;
        var ltms = [];
        lmtransporters.map(function(lt){
            ltms.push({
                "label" : lt.transporter_name,
                "value" : lt.transporter_code,
            })
        });
        this.setState({
            lmtransporters:ltms,
        })
    })
    .catch(function(error){
        console.log(error)
    });
    redirectURL.post('/lmconsignees/getLmVehicleCapacity',params)
    .then((response1) => {
        console.log(response1.data,"vehcileTypes");
        var lmvehicletypes = response1.data;
        this.setState({
            lmvehicletypes:lmvehicletypes,
        })
    })
    .catch(function(error){
        console.log(error)
    });
    redirectURL.post('/lmconsignees/getLmTrucksDetails',params)
    .then((response) => {
        console.log(response.data);
        var truckData = response.data;
        this.setState({
            truckData:truckData,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(error){
        console.log(error)
    });
} 
createColumns(){
//    console.log("transdata ", this.state.transdata)
   var transporters = this.state.transdata;
   var columns = []
   columns.push({
        headerName:"Transporter Name",
        field:"transporter_name",
        width:250
    });

   if(transporters.length > 0)
    {      
        var zones = groupBy(transporters, rdata => rdata.zone);
        //console.log("zones ", zones)
        zones.forEach((zone, zKey) => {
            var uniquechild = []
            var defs = {
                headerName:zKey
            }
            var capacities = groupBy(zone, rdata=>rdata.vehicle_capacity)
           // console.log("capacities ", capacities)
            var childcols = []
            capacities.forEach((z, k) => {
                childcols.push({
                    headerName:k,
                    field:zKey+"-"+k,
                    width:100,
                    cellEditor: NumericCellEditor,
                })
            })          
            defs.children = childcols            
            columns.push(defs)
        })
    }
    var transactionId = this.state.transactionId;
    if(transactionId == '')
    {
        columns.push({
            headerName:"Total",
            field:"total",
            width:160
        });
    }

    //console.log("columns ", columns)
    this.setState({
        columnwithDefs:columns,
        overly:"show-n",
        loadshow:"show-n",
    })
}
createRows(){
    var transdata =this.state.transdata;
    var rowdefs = []
    // console.log("transdata ", transdata)
    var transportername = groupBy(transdata, rdata => rdata.transporter_name);
    transportername.forEach((trans, transkey) =>{
        var rows = {}
        rows.transporter_name=transkey
        var rowcnt = 0
        if(trans.length > 0)
        {
            var zones = groupBy(trans, rdata => rdata.zone);
            zones.forEach((wares,wkey) => {
                var capacities = groupBy(wares, rdata=>rdata.vehicle_capacity)
                //console.log("capacities ", capacities)
                capacities.forEach((wh,ky) => {
                   //console.log(wh.length,"wh.length")
                    if(wh.length == undefined)
                    {
                       var whLen = 0;
                    }
                    else{
                        var whLen = wh.length;
                    }
                    rows[wh[0].zone+"-"+ky] = wh[0].vehicle_count
                    rowcnt = rowcnt+parseInt(wh[0].vehicle_count)
                })
            })
        }
        var transactionId = this.state.transactionId;
        if(transactionId == '')
        {
            rows["total"] = rowcnt
        }
        rowdefs.push(rows)
    })
    console.log("rowdefs ", rowdefs)
    this.setState({
        rowData:rowdefs,
        overly:"show-n",
        loadshow:"show-n",
    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    selectedTransporter:{},
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

updateGridData()
{
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    var allrows = []
    this.gridApi.forEachNode((node, index) => {
        //console.log("node ",node)
        allrows.push(node.data)
    })
    //console.log("allrows ", allrows)
    var records = []
    var transporters = []
    var row = {}
    if(allrows.length > 0)
    {       
        allrows.forEach((item) => {
            //console.log("item ", item)
            
            //row.transporter_name = item.transporter_name
            
            var truckAvailability = []
            for(var x in item)
            {
                //console.log("Item key ", x)
                if(parseFloat(item[x]) > 0)
                {
                    var expKey = x.split("-");
                    truckAvailability.push({
                        "zone":expKey[0],
                        "vehicle_capacity":expKey[1],
                        "vehicle_count":parseFloat(item[x]),
                    })
                }
            }
            transporters.push({
                "transporter_name":item.transporter_name,
                "truckAvailability":truckAvailability
            })
        })
    }
    console.log("records ",transporters)
    var loading_port_code = this.state.loading_port_code;
    var parameters = {
        records : transporters,
        loading_port_code : loading_port_code,
    }
    redirectURL.post("/lmconsignees/updateLmTruckAvailability", parameters)
    .then((response) => {
        //console.log(response);
        if(response.data.message == "success")
        {
            // this.setState({
            //     basicTitle:"Updated Successfully.",
            //     basicType : "success",
            //     show : true
            // })
            // this.onLoadShowData(this.state.transactionId)
            
            // var loadQry = {
            //     "transaction_id" : this.state.transactionId,
            //     "loading_port_code" : this.state.loading_port_code
            // }
            // redirectURL.post("/lmconsignees/generateLoadBySOB",loadQry).then((response)=>{
            //     console.log(response,"responseFromProcess")
            //     var statusCode = response.data.status;
            //     if(statusCode > 3)
            //     {
            //         this.setState({
            //             processErrMsg : response.data.message,
            //             processErrShow : 1,
            //             transactionId : response.data.transactionId,
            //             droppedList : response.data.droppedList,
            //             loadshow:'show-n',
            //             overly:'show-n',
            //         });
            //     }
            //     else
            //     {
            //         this.setState({                    
            //             basicTitle : response.data.message,
            //             basicType : "success",
            //             show : true,
            //             loadshow:'show-n',
            //             overly:'show-n',
            //             bulkslide:"",
            //         });
            //     }
            //     window.location.href="/lmloadsummary";
            // });
            window.location.href="/lmloadshare/"+window.btoa(this.state.transactionId);
        }
        else
        {
            this.setState({
                basicTitle:"Failed to update.",
                basicType : "warning",
                show : true
            })
        }
    })
}

generateLoad()
{
    var loadQry = {
        "transaction_id" : this.state.transactionId,
        "loading_port_code" : this.state.loading_port_code
    }
    redirectURL.post("/lmconsignees/generateLoadBySOB",loadQry).then((response)=>{
        console.log(response,"responseFromProcess")
        var statusCode = response.data.status;
        if(statusCode > 3)
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
                droppedList : response.data.droppedList,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
        else
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
                bulkslide1:"",
            });
        }
        //window.location.href="/lmloadsummary";
    });
}
onClickTab(pageType){
    if(pageType == "partial")
    {
        var partial = "activet";
        var complete = "";
        var partialData = "";
        var completeData = "show-n";
        var showGridData = "show-m";
        var showGridData1 = "show-n";
        var pageClicked = 1;
        this.pageAccess(1);
    }
    else{
        var partial = "";
        var complete = "activet";
        var partialData = "show-n";
        var completeData = "";
        var showGridData = "show-n";
        var showGridData1 = "show-m";
        var pageClicked = 2;
        this.pageAccess(2);
    }
    this.setState({
        partial: partial,
        complete: complete,
        partialData: partialData,
        completeData: completeData,
        showGridData: showGridData,
        showGridData1: showGridData1,
        pageType: pageClicked
    })
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
showSingleUpload(){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
}
resetToLiveTrucks(){
    var cnf = window.confirm("Are you sure!!! You want to reset to live data?")
    if(cnf == true)
    {
        var reqParams = {
            loading_port_code : this.state.loading_port_code,
        }
        console.log("reqParams",reqParams)
        redirectURL.post("/lmconsignees/resetLmTrucksDetails",reqParams).then((response)=>{
            this.setState({
                basicTitle : "Success",
                basicType : "success",
                show : true,
                bulkslide:'',
                bulkslide1:'',
                overly:'show-n',
            })
            this.loadPageData();
            //window.location.reload();
        }).catch((e)=>{
            console.log(e);
        })
    }
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        bulkslide1:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Transporter Name',
                inputName: 'transporter_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Transporter Code',
                inputName: 'transporter_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Vehicle Type',
                inputName: 'vehicle_type',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'Sequence No',
            //     inputName: 'sequence_no',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
            { 
                name: 'Vehicle Number',
                inputName: 'vehicle_number',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Punching Date',
                inputName: 'punching_date',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'Punching Time',
            //     inputName: 'punching_time',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },                   
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(this.state.csvData,"csvData");
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    console.log(fileData,"fileData");
    
    if(flag == 0)
    {
        if(fileData.length > 0)
        {
            var reqParams = {
                loading_port_code : this.state.loading_port_code,
                csvData : fileData,
            }
            console.log("reqParams",reqParams)
            redirectURL.post("/lmconsignees/savebulkLmTrucksDetails",reqParams).then((response)=>{
                var resp=response.data.message;
                console.log(resp)
                this.setState({
                    basicTitle : resp,
                    basicType : "success",
                    show : true,
                    bulkslide:'',
                    bulkslide1:'',
                    overly:'show-n',
                })
                this.loadPageData();
                //window.location.reload();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle : "Invalid File",
                basicType : "warning",
                show : true,
            })
        }
        $('#upform')[0].reset();
    }
    else
    {
        $("#bulkUploadID").val();
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
        $('#upform')[0].reset();
    }
}
uploadSingleFormHandler(event){
    event.preventDefault();
    var vehicle_no = $("#vehicle_no").val();
    if(vehicle_no == undefined || vehicle_no == '')
    {
        vehicle_no = "unknown";
    }
    var vehicle_type = $("#vehicle_type").val();
    var transporter = this.state.selectedTransporter;
    var transporter_code = "";
    var transporter_name = "";
    if(transporter !=undefined && transporter !='')
    {
        var transporter_code = transporter.value;
        var transporter_name = transporter.label;
    }
    var punching_date = $("#punching_date").val();
    var t = punching_date.split(" ");
    var ts = t[0].split("-")
    if(ts[2].length>2)
    {
        var tm = ts[2]+"-"+ts[1]+"-"+ts[0]+" "+t[1]+":00"
    }
    else
    {
        var tm = ts[0]+"-"+ts[1]+"-"+ts[2]+" "+t[1]+":00"
    }
    if(vehicle_type !=undefined && vehicle_type !='' && transporter_code !=undefined && transporter_code !='' && punching_date !=undefined && punching_date !='')
    {
        var reqParams = {
            loading_port_code : this.state.loading_port_code,
            vehicle_no : vehicle_no,
            vehicle_type : vehicle_type,
            transporter_code : transporter_code,
            transporter_name : transporter_name,
            punching_date : tm+":00",
            punching_original_date : punching_date,                
        }
        console.log("reqParams",reqParams)
        redirectURL.post("/lmconsignees/saveLmTrucksDetails",reqParams).then((response)=>{
            var resp=response.data.message;
            console.log(resp)
            this.setState({
                basicTitle : resp,
                basicType : "success",
                show : true,
                bulkslide:'',
                bulkslide1:'',
                selectedTransporter:{},
                overly:'show-n',
            })
            this.loadPageData();
            //window.location.reload();
        }).catch((e)=>{
            console.log(e);
        })
        $('#upform')[0].reset();
    }
    else
    {
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
        })
    }
}
setSelectedTransporter(event){
    console.log(event)
    this.setState({
        selectedTransporter : event
    })
}
onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        var pageType = this.state.pageType;
        this.pageAccess(pageType);
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}
  render() {
      var loading_port_code = this.state.loading_port_code;
      var hideAxle = true;
      if(loading_port_code == "RPDCGUR")
      {
        hideAxle = false;
      }
    var columnwithDefsForTruck = [
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Vehicle Type",
            field: "vehicle_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Axle Type",
            field: "axle_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            hide:hideAxle
        },
        // {
        //     headerName: "Sequence No",
        //     field: "sequence_no",
        //     width:130,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        // },
        {
            headerName: "Vehicle Number",
            field: "vehicle_number",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"cellstylegrid"
        },
        {
            headerName: "Punching Date",
            field: "punching_date",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter : function(params){
                if(params.data.punching_date !=undefined && params.data.punching_date !='')
                {
                    return getHyphenDDMMYYYYHHMMSS(params.data.punching_date);
                }
            }
        },
        // {
        //     headerName: "Punching Time",
        //     field: "punching_time",
        //     width:140,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        // },
    ];
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Truck Availability</span>
                    {(this.state.transactionId !='' && this.state.pageAccess != 2) ?  
                    <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.updateGridData.bind(this)} className="btn btn-warning">Assign Transporters</button>
                    :""
                    }
                    {/* <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.generateLoad.bind(this)} className="btn btn-info">Assign Transporters</button> */}
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"partial")} className={"lttabs "+(this.state.partial)}>
                            <button type="button" className="btn">Truck Availability</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"complete")} className={"lttabs "+(this.state.complete)}>
                            <button type="button" className="btn">Truck Details</button>
                        </li>
                    </ul>
                </div>
                {(this.state.lpNames.length > 0) ? 
                    <div className="row" style={{margin:"2% 0 1% 0"}}>
                        <div className="col-xl-12 col-lg-12">
                        {this.state.lpNames.length == 1 ?
                        <span style={{fontSize:"16px"}}>Loading Plant : {(this.state.lpNames[0].loading_port)}</span>
                        :
                        <span style={{fontSize:"16px"}}>Loading Plant : 
                            <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                                {(this.state.lpNames).map(function(obj){
                                    return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                                })}                                           
                            </select>
                        </span>
                        }
                        </div>
                    </div>
                :""}
                <div id="myGrid" style={{width:"100%",height:"500px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={this.state.columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                </div>
                <div className={"row col-xl-12 col-lg-12"}>
                    <div className={"col-xl-12 col-lg-12 "+this.state.showGridData1} style={{marginBottom:"2%"}}>
                        {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                        {(this.state.pageAccess != 2) ? 
                        <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.resetToLiveTrucks.bind(this)}>Reset To Live Trucks</button>
                        : ""}
                        {(this.state.pageAccess != 2) ?
                        <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showSingleUpload.bind(this)}>Add Truck</button>
                        : ""}
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.showGridData1}>                   
                        <div id="myGrid1" style={{width:"100%",height:"500px"}} className={"ag-theme-balham "+this.state.showGridData1}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForTruck}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.truckData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                //onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>           
            </div>
        </div>

        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Upload</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upBulkform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/sample_lmtruckdetails.csv')} target="_blank">Sample Template</a>
                    </div>	
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
            <h3 className="subH">Add Truck</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadSingleFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Vehicle No</label> 
                        <input type="text" name="vehicle_no" id="vehicle_no" className="form-control"  />
                    </div>
                    
                    <div className="form-group mt-20p">
                        <label className="">Vehicle Type*</label> 
                        <select name="vehicle_type" id="vehicle_type" className="form-control" required>
                            <option value="">Select Vehicle Type</option>
                            {this.state.lmvehicletypes.map(function(v){
                                return <option value={v.vehicle_cap}>{v.vehicle_cap}</option>
                            })}
                        </select>
                    </div>

                    <div className="form-group mt-20p">
                        <label className="">Transporter*</label> 
                        <Select 
                            placeholder={"Select Transporter"}  
                            //isMulti={true}
                            closeMenuOnSelect={true} 
                            className="border-radius-0" 
                            onChange={this.setSelectedTransporter.bind(this)} 
                            style={{borderRadius:"0px"}} 
                            options={this.state.lmtransporters} required
                        />
                    </div>

                    <div className="form-group mt-20p">
                        <label className="">Punching Time*</label> 
                        <input
                        type="text"
                        placeholder="Punching Time"
                        id="punching_date"
                        name="punching_date"
                        //  onChange={this.handlerStartDateTime.bind(this)} 
                        className="datetimepicker_mask form-control" required />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function loadDateTimeScript(){
	var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };
	$('.datetimepicker_mask').datetimepicker({
		format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

// function isCharDecimal(charStr) {
//     return !!/\./.test(charStr);
// }

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    //return isCharNumeric(charStr) || isCharDecimal(charStr);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
