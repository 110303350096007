'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import Deleterakeitem from "./deleterakeitem";
import RakeCommentActions from "../layouts/rakeCommentsComponent";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DrawMap from '../common/drawmap';
import Rakemapaction from "./rakemapaction";
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");

export default class RakeArrivals extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Rake Arrivals",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
                UpdateButton:UpdateButton,
                deleterakeitem:Deleterakeitem,
                rakemapaction:Rakemapaction,
                commentActions:RakeCommentActions
              },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rowData : [],
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            sliderRouteTranslate:"",
            transporters:[],
            dactminDate:"",
            dactmaxDate:"",
            dminDate:"",
            dmaxDate:"",
            destinationloadcapacity:'270',
            sliderRouteTranslate:'',
            rakeitem:"",
            loadpoint:"",
            destinationpoint:"",
            statustext:"",
            rakesitems:[],
            rakeloadingpoints:[],
            rakedestinationpoints:[],
            summary: "activet",
            complete:"",
            completeData:"show-n",
            commentsDiv:0,
            sldTitle:"Rake Schedule",
            rowId:"",
            defaultRowData: []
        };
        this.onClickShowMap = this.onClickShowMap.bind(this);
        this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this)
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA
        if(this.props.match.path == "/lsprakearrivals")
        {
           var userType="transporter"
        }
        if(this.props.match.path == "/rakearrivals")
        {
            var userType="msil"
        }
        this.setState({
            userType:userType
        });
        var currentmonth = new Date();
        currentmonth = (currentmonth.getMonth()+1).toString()+"-"+currentmonth.getFullYear().toString()
        $(".selected_date").val(currentmonth);
        this.loadPageData(currentmonth);
        
        
        redirectURL.post("/consignments/alltransporters")
        .then((response) => {
            this.setState({
                transporters:response.data
            })
        })
        .catch(function(e){
            console.log("Error ", e)
        })


        redirectURL.post("/consignments/rakeloaddestpoints", {
			transportercode:localStorage.getItem("transportercode")
		})
		.then((resp) => {
			console.log("Rakes ", resp.data)
			//console.log(JSON.parse(localStorage.getItem("transportercode")))
			
			
			var allrakes=[]
			try{
				var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
				//console.log(loggedusercode[0].trim())
				if(resp.data.rakes.length > 0)
				{
					resp.data.rakes.map((item) => {
						//console.log("item ",item)
						if(item.truck[0].transporter_code == loggedusercode[0])
						{
							//console.log("Item ", item)
							allrakes.push(item)
						}
					})
				}
			}
			catch(e){
				allrakes=resp.data.rakes
			}
			
			//console.log("allrakes ",allrakes)
			this.setState({
				rakesitems:allrakes,
				rakeloadingpoints:resp.data.loadingpoints,
				rakedestinationpoints:resp.data.destinationpoints
			});
        })
    }
    
    loadPageData(currentmonth)
    {
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var transporterCode = "";
        if(this.props.match.path == "/lsprakearrivals")
        {
           var userType="transporter";
            if(localStorage.getItem("user_type") == 'TRANSPORTER')
            {
                var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
                if(loggedusercode !=undefined && loggedusercode !="")
                {
                        var transporterCode = loggedusercode[0];
                }   
            }        
        }
        if(this.props.match.path == "/rakearrivals")
        {
            var userType="msil";
            var transporterCode = "";
        }
        var tranParams = {
            "userType" : userType,
            "transporterCode" : transporterCode,
            "selectedmonth" : currentmonth
        }
        redirectURL.post("/consignments/getRakeArrivals",tranParams).then((response)=>{
            // console.log(response.data.records,"resprecords");
            var loadPorts = response.data.loadingpoints;
            var loadingpoints = [];
            if(loadPorts.length > 0)
            {
                loadPorts.map((item) =>{
                    loadingpoints.push(item.geofence_name)
                })
            }
            var destPorts = response.data.destinationpoints;
            var destinationpoints = [];
            if(destPorts.length > 0)
            {
                destPorts.map((item1) =>{
                    destinationpoints.push(item1.geofence_name)
                })
            }
            var allrakes=[]
            allrakes.push("NMG")
			try{
				var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
				//console.log(loggedusercode[0].trim())
				if(response.data.rakes.length > 0)
				{
					response.data.rakes.map((item) => {
						//console.log("item ",item)
						if(item.truck[0].transporter_code == loggedusercode[0])
						{
							//console.log("Item ", item)
							allrakes.push(item.truck_no)
						}
					})
				}
			}
			catch(e){
                var rakesList=response.data.rakes;                
                if(rakesList.length > 0)
                {
                    rakesList.map((item1) =>{
                        allrakes.push(item1.truck_no)
                    })
                }
            }
            console.log(response.data.records,"default")
            this.setState({
                rowData : response.data.records,
                defaultRowData: response.data.records,
                rakeslist : allrakes,
                loadingpoints : loadingpoints,
                destinationpoints : destinationpoints,
                loadshow : "show-n",
                overly : "show-n"
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

    completedData(currentmonth)
    {
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var transporterCode = "";
        if(this.props.match.path == "/lsprakearrivals")
        {
           var userType="transporter";
            if(localStorage.getItem("user_type") == 'TRANSPORTER')
            {
                var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
                if(loggedusercode !=undefined && loggedusercode !="")
                {
                        var transporterCode = loggedusercode[0];
                }   
            }        
        }
        if(this.props.match.path == "/rakearrivals")
        {
            var userType="msil";
            var transporterCode = "";
        }
        var tranParams = {
            "userType" : userType,
            "transporterCode" : transporterCode,
            "selectedmonth" : currentmonth,
            "statustext" : "Completed"
        }
        redirectURL.post("/consignments/getRakeArrivals",tranParams).then((response)=>{
            // console.log(response.data.records,"resprecords");
            var loadPorts = response.data.loadingpoints;
            var loadingpoints = [];
            if(loadPorts.length > 0)
            {
                loadPorts.map((item) =>{
                    loadingpoints.push(item.geofence_name)
                })
            }
            var destPorts = response.data.destinationpoints;
            var destinationpoints = [];
            if(destPorts.length > 0)
            {
                destPorts.map((item1) =>{
                    destinationpoints.push(item1.geofence_name)
                })
            }
            var allrakes=[]
			try{
				var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
				//console.log(loggedusercode[0].trim())
				if(response.data.rakes.length > 0)
				{
					response.data.rakes.map((item) => {
						//console.log("item ",item)
						if(item.truck[0].transporter_code == loggedusercode[0])
						{
							//console.log("Item ", item)
							allrakes.push(item.truck_no)
						}
					})
				}
			}
			catch(e){
                var rakesList=response.data.rakes
                if(rakesList.length > 0)
                {
                    rakesList.map((item1) =>{
                        allrakes.push(item1.truck_no)
                    })
                }
            }
            this.setState({
                rowData : response.data.records,
                defaultRowData: response.data.records,
                rakeslist : allrakes,
                loadingpoints : loadingpoints,
                destinationpoints : destinationpoints,
                loadshow : "show-n",
                overly : "show-n"
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }

	onClickHideAll(){
		this.setState({
			overly : "show-n",
            sliderStateEmailsTranslate : "",
            sliderRouteTranslate:"",
            sliderRakeTranslate:""
			
		})
	}
	
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
    };
    
    updateLmStates(params)
    {
        console.log(params,"updateData");

        if(params.arrival_Date != "" && params.arrival_Date != undefined 
          && params.loading_point != "" && params.loading_point != undefined &&
          params.destination_point != "" && params.destination_point != undefined)
        {
            params['userid'] = localStorage.getItem('userid');
            params['email'] = localStorage.getItem('email');
            params['username'] = localStorage.getItem('username');
            params['userType'] = this.state.userType;
            var lspfields = ['transporter_rake_no', 'transporter_arrival_date', 'transporter_loading_yard']
            var empty_flag = 0;

            lspfields.map((e)=>{
                if(this.state.userType != "msil")
                {
                    if(params[e] == undefined)
                    {
                        empty_flag = 1;
                    }
                }
                else
                {
                    //params[e] = ""
                }
            })
            
            if(empty_flag == 0)
            {
                
                redirectURL.post("/consignments/updateRakeArrivals",params).then((response)=>{
                    console.log(response);
                    if(response.data.message == "Success")
                    {
                        this.setState({
                            basicTitle:"Updated Successfully.",
                            basicType : "success",
                            show : true
                        })
                    }
                    else
                    {
                        this.setState({
                            basicTitle : response.data.message,
                            basicType : "warning",
                            show : true
                        })
                    }
                    var selectedmonth = $(".selected_date").val();	
                    this.loadPageData(selectedmonth);
                })
            }
            else
            {
                this.setState({
                    basicTitle:"Fields Should not be Empty.",
                    basicType : "warning",
                    show : true
                })
            }
            
        }
        else
        {
            this.setState({
                basicTitle:"Fields Should not be Empty.",
                basicType : "warning",
                show : true
            })
        }
    }
    addNewRow()
    {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0,[{rakeno:"",loading_point:"",destination_point:"",arrival_Date:"",transporter_rake_no:"",transporter_arrival_date:"",transporter_loading_yard:""}])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }

    onClickSubmit(event)
    {   
        event.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var selectedmonth = $(".selected_date").val();
		this.loadPageData(selectedmonth);
    }

    
    onCellClicked(e)
    {
        if(e.colDef.field == "revert")
        {
            if(e.data.status_text == "Planning" || e.data.status_text == "Rake available for planning")
            {  
                var rowId = e.data._id;
                var rngParam = {
                    rowId : rowId,
                }
                redirectURL.post("/consignments/revertRakeArrival",rngParam).then((response)=>{
                    if(response.data.message == "Success")
                    {
                        this.setState({
                            basicTitle:"Reverted Successfully.",
                            basicType : "success",
                            show : true
                        })
                        var selectedmonth = $(".selected_date").val();
		                this.loadPageData(selectedmonth);
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to Revert.",
                            basicType : "warning",
                            show : true
                        })
                    }
                })
            }
        }
        if(e.colDef.field == "complete")
        {
            console.log("MArk As Complete")
            if(e.data.status_text != "Completed")
            {  
                console.log(e.data.status_text)
                var rowId = e.data._id;
                var rngParam = {
                    rowId : rowId,
                }
                redirectURL.post("/consignments/changeRakeStatus",rngParam).then((response)=>{
                    if(response.data.message == "Success")
                    {
                        this.setState({
                            basicTitle:"Marked as completed.",
                            basicType : "success",
                            show : true
                        })
                        var selectedmonth = $(".selected_date").val();
		                this.loadPageData(selectedmonth);
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to mark as complete.",
                            basicType : "warning",
                            show : true
                        })
                    }
                })
            }
        }
    }

    onClickShowMap = (params) =>{
        try{

            // console.log("ads", params)
            if(params.msil_actual_dispatch_date == undefined || params.msil_actual_dispatch_date == "")
            {
                var fromdate = getHyphenYYYYMMDDHHMMSS(params.create_date);
            }
            else
            {
                var fromdate = getHyphenYYYYMMDDHHMMSS(params.msil_actual_dispatch_date);
            }
            if(params.destination_yard_reached_on == undefined || params.destination_yard_reached_on == "")
            {
                var todate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
            }
            else
            {
                var todate = getHyphenYYYYMMDDHHMMSS(params.destination_yard_reached_on);
            }
            var loading_point = params.loading_point;
            var destination_point = params.destination_point;
            if(params.actual_rake_no != undefined && params.actual_rake_no != "")
            {
                var rake = params.actual_rake_no;
            }
            else
            {
                var rake = params.rakeno;
            }
            if(rake != '' && rake != null && rake != undefined)
            {
                var parameters = {
                    rake_no : rake,
                    from_date:fromdate,
                    to_date:todate,
                    loading_point:loading_point,
                    destination_point:destination_point
                }
                //console.log("parameters ", parameters)
                redirectURL.post("/consignments/rakemaproute", parameters)
                .then((response) => {
                    console.log("response ", response.data)
                    var records = response.data;
                    if(records.status == "failure")
                    {
                        this.setState({
                            show: true,
                            basicTitle:"No data found",
                            basicType:"danger"
                        });
                    }
                    else
                    {
                        this.setState({
                            mapinfo:records,
                            maprakeno:rake,
                            // maprakestart:records.route_details.stime,
                            // maprakeend:records.route_details.etime,
                            sliderRouteTranslate:"slider-translate-75p",
                            show: false,
                            overly:'show-m'
                        });
                    }
                    
                })
                .catch(function(e){
                    console.log("Error ",e);
                })
            }
            else
            {
                this.setState({
                    show: true,
                    basicTitle:"Rake no has not assigned",
                    basicType:"danger"
                });
            }
            
            // var records = {
            //     route:[{
            //         lat:params.truck[0].latitude,
            //         lng:params.truck[0].longitude
            //     }],
            //     rakeno:params.rakeno,
            //     timestamp:params.truck[0].timestamp
            // }
            // this.setState({
            //     mapinfo:records,
            //     sliderRouteTranslate:"slider-translate-75p",
            //     show: false,
            //     overly:'show-m'
            // });
        }
        catch(e){
            this.setState({
                show:true,
                basicTitle:"No route found",
                basicType: "info"
            });
        }
    }
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
    
    onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
    }

    onChangeTransporterItem(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
          );
          
          redirectURL.post("/consignments/transporterrakes",{
            transporter_code:transporter.value
          })
          .then((response) => {
              this.setState({
                rakesitems:response.data
              })
          })
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeRakeItem(rakeitem){
		this.setState(
			{ rakeitem },
			() => console.log(`Consigner Option selected:`, this.state.rakeitem)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeLoadingPointItem(loadpoint){
		this.setState(
			{ loadpoint },
			() => console.log(`Consigner Option selected:`, this.state.loadpoint)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationPointItem(destinationpoint){
        if(destinationpoint.value == 'Changsari Railyard')
        {
            this.setState({
                destinationloadcapacity:'300'
            })
        }
        else
        {
            this.setState({
                destinationloadcapacity:'270'
            })
        }
		this.setState(
			{ destinationpoint },
			() => console.log(`Consigner Option selected:`, this.state.destinationpoint)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                    this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }
    selecttransporters(){
		let items = [];    
		items.push({"value":"all","label":"All"})
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.firstname})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
    selectrakeoptins(){
		let items = [];    
		items.push({"value":"NMG","label":"NMG"})
		try{
			if(this.state.rakesitems.length > 0)
			{
				this.state.rakesitems.map((item) =>{
					items.push({"value":item.truck_no,"label":item.truck_no})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	selectloadingpoints(){
		let items = [];    
		try{
			if(this.state.rakeloadingpoints.length > 0)
			{
				this.state.rakeloadingpoints.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		return items;
	}
	selectdestinationpoints(){
		let items = [];    
		try{
			if(this.state.rakedestinationpoints.length > 0)
			{
				this.state.rakedestinationpoints.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
    }
    formRakeHandler = async (event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Add or Edit Rake Planning",
			}
			googleAnalytics.logEvent(eventOptions);
        }
        
        var transcode='';
        var transname='';
        var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
        if(localStorage.getItem("user_type") != "Transporter")
        {
            // if(splitrole.indexOf("tpt_rail") == -1)
            // {
                if(Array.isArray(this.state.transporter.value))
                {
                    transcode = this.state.transporter.value[0]
                    transname = this.state.transporter.label
                }
                else
                {
                    transcode = this.state.transporter.value
                    transname = this.state.transporter.label
                }
                
            // }
        }
        else
        {
            transcode =splitrole[0]
        }
        if(transcode != '' && this.state.arrival_Date != ""  && this.state.arrival_Date != "NaN-NaN-NaN" && this.state.loadpoint != "" && this.state.destinationpoint != '')
        {
            if(this.state.msilrakeno != '')
            {
                if(this.state.statustext != "Completed")
                {
                    var statustext = "Planning";
                }
                else{
                    var statustext = "Completed";
                }
                
            }
            else{
                var statustext = this.state.statustext
            }
            if(statustext == "")
            {
               var statustxt = "Planning Yet to Start"
            }
            else{
                var statustxt = statustext
            }
            if(this.state.formtype == 'add')
            {
                if(this.state.msilrakeno != '')
                {
                    var checkparams = {
                        msil_rake_no:this.state.msilrakeno,
                        exists_msil_rake_no:""
                    }
                    redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                    .then((response) => {
                       // console.log("response ", response.data)
                        var records =response.data.records
                       // console.log("records ", records)
                       if(records.length > 0)
                        {
                            this.setState({
                                show1:true,
                                basicTitle1:"Already exists rake no",
                                basicType1:"info"
                            })
                        }
                        else
                        {
                            this.applyFormAction();
                        }
                         
                    })
                    .catch(function(e){
            
                    })
        
                }
                else
                {
                    this.applyFormAction();
                }
            }
            else
            {
                if(this.state.msilrakenoexists != '' && this.state.msilrakenoexists != this.state.msilrakeno)
                {
                    var checkparams = {
                        msil_rake_no:this.state.msilrakeno,
                        exists_msil_rake_no:this.state.msilrakenoexists
                    }
                    redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                    .then((response) => {
                        //console.log("Edit response ", response.data)
                
                        var records =response.data.records
                        console.log("records ", records)
                        if(records.length > 0)
                        {
                            this.setState({
                                show1:true,
                                basicTitle1:"Already exists rake no",
                                basicType1:"info",
                                msilrakeno:this.state.msilrakenoexists
                            })
                        }
                        else
                        {
                            this.applyFormAction();
                        }
                    })
                    .catch(function(e){
            
                    })
                }
                else
                {
                    if(this.state.msilrakeno != '')
                    {
                        var checkparams = {
                            msil_rake_no:this.state.msilrakeno,
                            exists_msil_rake_no:""
                        }
                        redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                        .then((response) => {
                            //console.log("Edit response ", response.data)
                    
                            var records =response.data.records
                            console.log("records ", records)
                            if(records.length > 0)
                            {
                                this.setState({
                                    show1:true,
                                    basicTitle1:"Already exists rake no",
                                    basicType1:"info",
                                    msilrakeno:this.state.msilrakenoexists
                                })
                            }
                            else
                            {
                                this.applyFormAction();
                            }
                        })
                        .catch(function(e){
                
                        })
                    }
                    else{
                        this.applyFormAction()
                    }
                }
            }
            
        //     var reqparams = {
        //         transporter_code:transcode,
        //         transporter_name:transname,
        //         rakeno:this.state.rakeitem.value,
        //         loadpoint:this.state.loadpoint.value,
        //         destinationpoint:this.state.destinationpoint.value,
        //         arrival_Date:this.state.arrival_Date,
        //         msil_rake_no:this.state.msilrakeno,
        //         //msil_actual_rake:this.state.msilactualrake,
        //         load_capacity:this.state.destinationloadcapacity,
        //         status_text:statustxt
        //     }
        //    // console.log("reqparams ", reqparams)
        //    // console.log("this.state.formtype ", this.state.formtype)
        //     if(this.state.formtype == 'add')
        //     {
        //         var urlpath = "/consignments/storeRakeSchedule";
        //     }
        //     else
        //     {
        //         var urlpath = "/consignments/updateRakeSchedule";
        //         reqparams.rowid = this.state.rowitem;
        //     }
            // redirectURL.post(urlpath,reqparams)
            // .then((response) => {
            //     //console.log("Rake response.data ",response.data)
            //     if(response.data.status == "Success")
            //     {
            //         if(this.state.formtype == 'add')
            //         {
            //             var messageres = 'Successfully added.'
            //         }
            //         else{
            //             var messageres = 'Updated successfully.'
            //         }
            //         this.setState({
            //             show: true, 
            //             basicType:'success', 
            //             basicTitle:messageres,
            //             uploadDivWidth:'0%',
            //             sliderRakeTranslate:'',
            //             showDiv:'show-n',
            //             uploadFile:'',
            //             loadshow:'show-n',
            //             file:''
            //         })
                    
            //     }
            //     else
            //     {
            //         this.setState({
            //             sliderRakeTranslate:"",
            //             loadshow:"show-n",
            //             show: true, 
            //             basicType:'danger',
            //             basicType:"Failed to add" 
            //         })
            //         //window.location.reload();
            //     }
            // })
        }
        else{
            this.setState({
                show1: true, 
                basicType1:'danger', 
                basicTitle1:"Please enter mandatory fields.",
            })  
        }
		
        
    }

    formCommentsHandler= async (event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Add or Edit Rake Planning",
			}
			googleAnalytics.logEvent(eventOptions);
        }

        var comments = $("#comments").val();
        var rowId = $("#rowId").val();
        var params = {};
        // console.log(comments,"comments")
        // console.log(rowId,"rowId")
        if(comments !='' && comments !=undefined)
        {
            params['comments'] = comments;
            params['rowId'] = rowId;
            params['userid'] = localStorage.getItem('userid');
            params['email'] = localStorage.getItem('email');
            params['username'] = localStorage.getItem('username');
            params['userType'] = this.state.userType;
            redirectURL.post("/consignments/updateRakeArrivalsComments",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    $("#comments").val("");
                    var reqparams = {
                        rowId : rowId,
                    }
                    redirectURL.post("/consignments/getRakeCommentLogs",reqparams).then((response)=>{
                        console.log(response.data);
                        this.setState({
                          commentsRowData : response.data
                        })
                    }).catch(function(error){
                        console.log(error);
                    })
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true
                    })
                }
                else
                {
                    this.setState({
                        basicTitle : response.data.message,
                        basicType : "warning",
                        show : true
                    })
                }
                var selectedmonth = $(".selected_date").val();	
                this.loadPageData(selectedmonth);
            })
        }
        else{
            this.setState({
                show1: true, 
                basicType1:'danger', 
                basicTitle1:"Please enter comments.",
            })  
        }
    }

    applyFormAction(){
        var transcode='';
        var transname='';
        var userrols = localStorage.getItem("roles");
        var splitrole = userrols.split(",")
        if(localStorage.getItem("user_type") != "Transporter")
        {
            // if(splitrole.indexOf("tpt_rail") == -1)
            // {
                if(Array.isArray(this.state.transporter.value))
                {
                    transcode = this.state.transporter.value[0]
                    transname = this.state.transporter.label
                }
                else
                {
                    transcode = this.state.transporter.value
                    transname = this.state.transporter.label
                }
                
            // }
        }
        else
        {
            // transcode =splitrole[0]
            transcode =localStorage.getItem("transportercode")
            transname =localStorage.getItem("username")
        }
        if(transcode != '' && this.state.arrival_Date != ""  && this.state.arrival_Date != "NaN-NaN-NaN" && this.state.loadpoint != "" && this.state.destinationpoint != '')
        {
            if(this.state.msilrakeno != '' && this.state.msilrakeno != undefined)
            {
                if(this.state.statustext != "Completed")
                {
                    var statustext = "Planning";
                }
                else{
                    var statustext = "Completed";
                }
                
            }
            else{
                var statustext = this.state.statustext
            }
            if(statustext == "")
            {
                var statustxt = "Planning Yet to Start"
            }
            else{
                var statustxt = statustext
            }
           
            
            var reqparams = {
                transporter_code:transcode,
                transporter_name:transname,
                rakeno:this.state.rakeitem.value,
                loadpoint:this.state.loadpoint.value,
                destinationpoint:this.state.destinationpoint.value,
                arrival_Date:this.state.arrival_Date,
                msil_rake_no:this.state.msilrakeno,
                //msil_actual_rake:this.state.msilactualrake,
                load_capacity:this.state.destinationloadcapacity,
                status_text:statustxt
            }
            // console.log("reqparams ", reqparams)
            // console.log("this.state.formtype ", this.state.formtype)
            if(this.state.formtype == 'add')
            {
                var urlpath = "/consignments/storeRakeSchedule";
            }
            else
            {
                var urlpath = "/consignments/updateRakeSchedule";
                reqparams.rowid = this.state.rowitem;
            }
            redirectURL.post(urlpath,reqparams)
            .then((response) => {
                //console.log("Rake response.data ",response.data)
                if(response.data.status == "Success")
                {
                    if(this.state.formtype == 'add')
                    {
                        var messageres = 'Successfully added.'
                    }
                    else{
                        var messageres = 'Updated successfully.'
                    }
                    this.setState({
                        show: true, 
                        basicType:'success', 
                        basicTitle:messageres,
                        uploadDivWidth:'0%',
                        sliderRakeTranslate:'',
                        showDiv:'show-n',
                        uploadFile:'',
                        loadshow:'show-n',
                        overly:"show-n",
                        file:''
                    })
                    
                }
                else
                {
                    this.setState({
                        sliderRakeTranslate:"",
                        loadshow:"show-n",
                        overly:"show-n",
                        show: true, 
                        basicType:'danger',
                        basicType:"Failed to add" 
                    })
                    //window.location.reload();
                }
            })
        }
        else{
            this.setState({
                show1: true, 
                basicType1:'danger', 
                basicTitle1:"Please enter mandatory fields.",
            })  
        }
        
    }
    
    deleteRakeData = async (params) =>{
        //console.log("Delete ", params)
        var confirm = await window.confirm("Are you sure you want to delete item?")
        if(confirm == true)
        {
            var reqparams = {
                rowid : params._id
            }
            await redirectURL.post("/consignments/deleteRakePlan", reqparams)
            .then((response) => {
                // console.log("Delete response ", response.data)
                if(response.data.deletedCount == 1)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Delete item successfully",
                        basicType:"success"
                    });
                }
            })
        }
    }

    onShowCommentsDiv = async(params) =>{
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
              "category": pageTitle,
              "action": this.state.eventGridAction,
              "label": "Show Comments",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        
          //console.log(e);
        var reqparams = {
            rowId : params._id,
        }
        redirectURL.post("/consignments/getRakeCommentLogs",reqparams).then((response)=>{
            console.log(response.data);
            this.setState({
              commentsRowData : response.data
            })
          }).catch(function(error){
            console.log(error);
          })
        this.setState({
            commentsDiv:1,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            sldTitle:"Comments",
            rowId : params._id,
        })
    }

    onClickTab(pageType){
        if(pageType == "summary")
        {
            var summary = "activet";
            var complete = "";
            var selectedmonth = $(".selected_date").val();	
            this.loadPageData(selectedmonth);
        }
        if(pageType == "complete")
        {
            var summary = "";
            var complete = "activet";
            var selectedmonth = $(".selected_date").val();	
            this.completedData(selectedmonth);
        }
        this.setState({
            summary: summary,
            complete: complete,
        })
    }

    render(){
        var userType = this.state.userType;
        if(userType == "transporter")
        {
            var showTransporter = false;
            var showMsil = true;
            var editField = true;
            var hideTransporter = true;
        }
        else
        {
            var showTransporter = true;
            var showMsil = false;
            var editField = true;
            var hideTransporter = false;
        }
        var editmsil = (userType == "msil")?true:false
		var columnwithDefs= [
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRenderer:'rakemapaction',
                resizable: true
                
            },
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRenderer:'deleterakeitem',
                resizable: true,
                hide:hideTransporter
                
            },
			{
				headerName: "Transporter Name",
				field: "transporter_name",
                width: 160,
                filter: true,
                resizable: true,
                editable:false,
                sortable:true,
                hide: hideTransporter,
            },
            // {
			// 	headerName: "Rake No",
			// 	field: "rakeno",
            //     width: 100,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     sortable:true,
			// },
			{
				headerName: "Loading Yard",
				field: "loading_point",
                width: 150,
                filter: true,
                resizable: true,
                editable:editmsil,
                sortable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.loadingpoints,
                },
			},
			{
				headerName: "Destination Yard",
				field: "destination_point",
                width: 150,
                filter: true,
                resizable: true,
                editable:editmsil,
                sortable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.destinationpoints,
                },
			},
			{
				headerName: "MSIL Planned Date",
				field: "arrival_Date",
                width: 150,
                filter: true,
                resizable: true,
                editable:editmsil,
                sortable:true,
                cellEditor: DateEditor,
                valueGetter:function(params){
                    if (params.data.arrival_Date != null)
                    {
                        if(params.data.arrival_Date.includes("T"))
                        {
                            return getHyphenDDMMYYYY(params.data.arrival_Date);
                        }
                        else if(params.data.arrival_Date.includes("/"))
                        {
                            // var arr = new Date(params.data.arrival_Date)
                            // arr.setDate(arr.getDate() + 1)

                            return moment.parseZone(new Date(params.data.arrival_Date)).format("DD-MM-YYYY")
                        }
                        else
                        {
                            return params.data.arrival_Date
                        }
                    }
                    else
                    {
                        return ""
                    }
                },
                comparator: dateComparator1,
            },
            {
				headerName: "Distance From Loading Yard (Km's)",
				field: "distance_from_loading_yard",
                width: 150,
                filter: true,
                resizable: true,
                editable:false,
                sortable:true,
                hide: hideTransporter,
            },
            {
                headerName:"ETA",
                field:"replanned_date",
                width:150,
                valueGetter:function(params){
                    if(params.data.transporter_rake_no !='' && params.data.transporter_rake_no !=undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.replanned_date)
                    }                    
                },
                comparator: dateComparator1,
                filter : true,
                editable:false,
                hide: hideTransporter,
            },
            {
                headerName:"Status",
                field:"status_text",
                width: 150,
                filter: true,
                resizable: true,
                editable:false,
                sortable:true,
            },
			{
				headerName: "Transporter Rake",
				field: "transporter_rake_no",
                width: 150,
                filter: true,
                resizable: true,
                editable:editField,
                sortable:true,
                cellClass:"boxbgblue",
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.rakeslist,
                },
			},
			{
				headerName: "Transporter Planned Date",
				field: "transporter_arrival_date",
                width: 180,
                filter: true,
                resizable: true,
                editable:editField,
                sortable:true,
                cellClass:"boxbgblue",
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.transporter_arrival_date != null)
                    {
                        if(params.data.transporter_arrival_date.includes("T"))
                        {
                            return getHyphenDDMMYYYY(params.data.transporter_arrival_date);
                        }
                        else if(params.data.transporter_arrival_date.includes("/"))
                        {
                            // var arr = new Date(params.data.transporter_arrival_date)
                            // arr.setDate(arr.getDate() + 1)

                            return moment.parseZone(new Date(params.data.transporter_arrival_date)).utcOffset("+05:30").format("DD-MM-YYYY")
                        }
                        else
                        {
                            return params.data.transporter_arrival_date
                        }
                    }
                    else
                    {
                        return ""
                    }
                },
                cellEditor: DateEditor,
                comparator: dateComparator1,
			},
			{
				headerName: "Transporter Loading Yard",
				field: "transporter_loading_yard",
                width: 180,
                filter: true,
                resizable: true,
                editable:editField,
                sortable:true,
                cellClass:"boxbgblue",
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.loadingpoints,
                },
            },
            {
				headerName: "MSIL Rake",
				field: "msil_rake_no",
                width: 150,
                filter: true,
                resizable: true,
                editable:editField,
                sortable:true,
                cellClass:"boxbgblue",
            },
            {
                headerName:"Transporter Marked Date",
                field:"transporter_marking_date",
                width:150,
                valueGetter:function(params){
                    if(params.data.transporter_marking_date !='' && params.data.transporter_marking_date !=undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.transporter_marking_date)
                    }                    
                },
                comparator: dateComparator1,
                filter : true,
                editable:false,
                hide: hideTransporter,
            },
            // {
			// 	headerName: "Comments",
			// 	field: "comments",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     sortable:true,
            //     cellClass:"boxbgblue",
			// },
            {
                pinned:"left",
                headerName : "Update",
                field:"update",
                width:120,
                resizable : true,
                hide : false,
                cellRendererSelector:function(params){       
                    var rendComponent = {
                        component: 'UpdateButton'
                    };
                    if(userType == "transporter")
                    {
                        if(params.data.status_text !="Planning" && params.data.status_text !="Completed")
                        {
                            return rendComponent;
                        }
                    }
                    else
                    {
                        if(params.data.status_text !="Completed")
                        {
                            return rendComponent;
                        }
                    }
                },
            },
            {
                pinned:"left",
                headerName : "Revert",
                field:"revert",
                width:120,
                resizable : true,
                hide : showMsil,
                cellClass : "cellRvrt",
                valueGetter:function(params){
                    if(params.data.status_text == "Planning" || params.data.status_text == "Rake available for planning")
                    {                        
                        return "Revert";
                    }
                },
                
            },
            {
                pinned:"left",
                headerName : "",
                field:"complete",
                width:130,
                resizable : true,
                hide : showMsil,
                cellClass : "cellRvrt",
                valueGetter:function(params){
                    if(params.data.status_text == "Planning" || params.data.status_text == "Rake available for planning")
                    {                        
                        return "Mark As Complete";
                    }
                },
            },
            {
                headerName : "",
                field:"commentaction",
                pinned : "left",
                width:40,
                cellRenderer : "commentActions"
            },		
        ];
        
        const commentColumns = [
            {
              headerName : "User Type",
              field : "user_type",
              width:120,
              resizable : true,
              filter : true,
              valueGetter : function(params){
                return params.data.user_type.toUpperCase();
              }
            },
            {
              headerName : "Comment",
              field : "comments",
              width:230,
              cellClass: ['wraptext'],
              resizable : true,
              filter : true
            },            
            {
              headerName : "Commented Date",
              field : "comment_date",
              resizable : true,
              filter : true,
              width:150,
              valueGetter : function(params){
                if(params.data.comment_date != ""){
                    return getDDMMYYYYHHMMSS(params.data.comment_date);
                }
              }
            }
          
        ]

        const { transporter } = this.state;
        const { rakeitem } = this.state;
        const { loadpoint } = this.state;
        const { destinationpoint } = this.state;
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <div className="row">
                <form method="post" className='row col-xl-12 col-lg-12' onSubmit={this.onClickSubmit.bind(this)}>
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Select Month</label>
                        <input type="text" className="selected_date form-control" id="dispatch_dashboard_date" required />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="submit" className="btn btn-success" autoComplete="off">Submit</button>
                    </div>
                </form>
                <div className="col-xl-12 col-lg-12">
                    <ul className="d-tabs" style={{"marginBottom":"2%"}}>
                        <li onClick={this.onClickTab.bind(this,"summary")} className={"ttabs "+(this.state.summary)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"all")}>All Data</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"complete")} className={"ttabs "+(this.state.complete)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"complete")}>Completed Data</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5>
                        <i className="icon-map cus-i"></i> <span> Rail Arrivals</span>         
                        {/* <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Add Rail Arrival</button>*/}
                        {(localStorage.getItem("user_type") != "TRANSPORTER")?
                            <button className="float-right f14 custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{marginRight:"5px"}}>
                                Add Rake Schedule
                            </button>
                        :""}
                        
                    </h5>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        onCellClicked={this.onCellClicked.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                    </div>
                </div>
            </div>

            
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				

            {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<DrawMap mapFor={"rakeschedule"} mapinfo={this.state.mapinfo} />
								{(this.state.mapinfo != '')?
								<div className="col-xl-12 col-lg-12">
									<div className="crm-numbers pb-0">
										<h3 className="subH">Route Details</h3>
										{/* <div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Rake No</label>
												<div>{this.state.maprakeno}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '' && typeof this.state.mapinfo.route_details.start_time != undefined  && this.state.mapinfo.route_details.start_time != "undefined")?getHyphenDDMMMYYYYHHMM(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '' && typeof this.state.mapinfo.route_details.end_time != undefined && this.state.mapinfo.route_details.end_time != "undefined")?getHyphenDDMMMYYYYHHMM(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
										</div> */}
									</div>

								</div>
								:""}
								
							</div>
						</div>
					</div>
				:""	
				}

                
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto",width:"40%"}}>
                    <div className="slide-r-title">
                        <h4>
                            {this.state.sldTitle}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        {(this.state.commentsDiv) == 1 ? 
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid1" style={{ height: "430px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={commentColumns}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.commentsRowData}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    enableRangeSelection= {true}
                                    // sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                
                                    />
                                </div>
                                <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formCommentsHandler.bind(this)}>
                                    
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <input type="text" placeholder="Comments" id="comments" name="comments" className="form-control ml-15p" onChange={this.changeHandler} required/>
                                        <input type="hidden" id="rowId" name="rowId" value={this.state.rowId} />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                        :
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeHandler.bind(this)}>
                            {(localStorage.getItem("user_type") != "Transporter" && localStorage.getItem("user_type") != "TRANSPORTER")?
                                
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Select Transporter *</label>
                                    
                                    <Select 
                                    placeholder={"Select Transporter"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporterItem.bind(this)} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={transporter}
                                    options={this.selecttransporters()} />  
                                </div>
                               
                            :""}
                            
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
								
								<Select 
								placeholder={"Select Loading Yard"}
								closeMenuOnSelect={true}
								onChange={this.onChangeLoadingPointItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={loadpoint}
								options={this.selectloadingpoints()} />  
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
								
								<Select 
								placeholder={"Select Destination Yard"}
								closeMenuOnSelect={true}
								onChange={this.onChangeDestinationPointItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={destinationpoint}
								options={this.selectdestinationpoints()} />  
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Load Capacity</label>
                                    {/* <span className="form-control col-xl-12 col-lg-12 border-radius-0 ml-15p">{this.state.destinationloadcapacity}</span> */}
                                    <input type="text" placeholder="Load Capacity " id="destinationloadcapacity" name="destinationloadcapacity" value={this.state.destinationloadcapacity} className="form-control ml-15p" onChange={this.changeHandler} />
							
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Planned Departure Date *</label>
								
                                {/* <input type="text" placeholder="Expected Arrival Date" id="arrival_Date" name="arrival_Date" value={this.state.arrival_Date} className="datetimepicker_mask form-control" required /> */}
                                <Datetime 
                                    value={this.state.arrival_Date} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Planned Departure Date', name: 'arrival_Date', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"
                                    name="arrival_Date" 
                                    onChange={this.handlerArrivalDate.bind(this)}
                                    className="col-xl-12 col-lg-12"
                                    //isValidDate={this.dateValidation.bind(this)}
								/>
							</div>
                            	
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Rake</label>
								
								<Select 
								placeholder={"Select Rake"}
								closeMenuOnSelect={true}
								onChange={this.onChangeRakeItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={rakeitem}
								options={this.selectrakeoptins()} />  
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">MSIL Rake No</label>
								
                                <input type="text" placeholder="Rake No " id="msilrakeno" name="msilrakeno" value={this.state.msilrakeno} className="form-control ml-15p" onChange={this.changeHandler} />
							
							</div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">ETA/ATA Arrival Date</label>
							    <Datetime 
                                    value={this.state.msilactualrake} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'ETA/ATA Arrival Date', name: 'msilactualrake', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"
                                    name="msilactualrake" 
                                    onChange={this.handlerActualArrivalDate.bind(this)}
                                    className="col-xl-12 col-lg-12"
                                   // isValidDate={this.dateActualValidation.bind(this)}
								/>
                            </div> */}

							<div className="col-xl-12 col-lg-12 row form-group">
                                <div className="col-xl-12 col-lg-12">
                                    <label className="col-xl-12 col-lg-12">Is Completed?</label>
                                    <div className="col-xl-12 col-lg-12">
                                        <input onChange={this.changeHandler} name="statustext" checked={this.state.statustext == 'Completed'} type="radio" className="" value="Completed" />Yes
                                        &nbsp;&nbsp;<input onChange={this.changeHandler} name="statustext" checked={this.state.statustext != 'Completed'} type="radio" className="" value="Planning Yet to Start" />No
                                    </div>
                                </div>
                            </div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        }
                        </div>
					</div>
				</div>

        </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){

    $("#dispatch_dashboard_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("dispatch_dashboard_date");
        }
    });
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
