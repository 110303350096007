import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            sameDayDelay:"",
            delayDuration:"",
            criticalConsignment : "",
            criticalConsignmentCC : "",
            dept_code:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        // var dept_code = "SNDG";
        var dept_code = this.props.deptCode;
        console.log(dept_code);
        var formdata = {
            config_type:'transitdelay',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                let criticalConsignments = "";
                let criticalConsignmentCC = "";
                response.data[0].msil.map(function(e){
                    
                    criticalConsignments = criticalConsignments + "," + e
        
                })  
                if(response.data[0].msilcc.length > 0){
                    response.data[0].msilcc.map(function(e){
                        
                        criticalConsignmentCC = criticalConsignmentCC +","+ e
                        
                    })
                    if(criticalConsignmentCC.charAt(0) == ","){
                        criticalConsignmentCC = criticalConsignmentCC.substr(1);
                    }
                }
                else{
                    criticalConsignmentCC = ""
                }
                if(criticalConsignments.charAt(0) == ","){
                    criticalConsignments = criticalConsignments.substr(1);
                }
                
                this.setState({
                    sameDayDelay:response.data[0].same_day_delay,
                    // time:(response.data[0].threshold_time/60),
                    delayDuration : response.data[0].min_delay_duration,
                    criticalConsignment : criticalConsignments,
                    criticalConsignmentCC : criticalConsignmentCC,
                    // nightRules : response.data[0].night_driving_rules,
                    dept_code : dept_code
                });
            }
            else
            {
                this.setState({
                    sameDayDelay:"",
                    // time:(response.data[0].threshold_time/60),
                    delayDuration : "",
                    // nightRules : response.data[0].night_driving_rules,
                    dept_code : dept_code
                });
            }

        })
    }
   
	formHandler = (event) => {
        event.preventDefault(); 
        var same_day_delay = document.getElementById("sameDayDelayID").value;
        var min_delay_duration = document.getElementById("delayDurationID").value;
        var ccmsil = document.getElementById("MSILID").value;
        var ccmsilcc = document.getElementById("MSILCCID").value;
        if(ccmsil.charAt(0) == ","){
            ccmsil = ccmsil.substr(1);
        }
        if(ccmsilcc != ""){
            if(ccmsilcc.charAt(0) == ","){
                ccmsilcc = ccmsilcc.substr(1);
            }

            ccmsilcc = ccmsilcc.split(",");
        }
        else{
            ccmsilcc = ""
        }
        ccmsil = ccmsil.split(",");
        
        var formdata = {
            userid:this.state.userid,
            user_email:localStorage.getItem('email'),
            dept_code:this.state.dept_code,
            same_day_delay:same_day_delay,
            config_type:"transitdelay",
            min_delay_duration:min_delay_duration,
            ccmsil : ccmsil,
            ccmsilcc : ccmsilcc
            // threshold_time:(this.state.time*60)
        }
        console.log(formdata)
        redirectURL.post('/manage/saveTDKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    checkDuration = (event) =>{
        this.setState({
            // distance : event.target.value,
            delayDuration : event.target.value
        })
    }
    criticalConsignmentCC = (event)=>{
        this.setState({
            criticalConsignmentCC : event.target.value
        })
    }
    criticalConsignment = (event)=>{
        this.setState({
            // distance : event.target.value,
            criticalConsignment : event.target.value
        })
    }
    checkInput = (event) =>{
        this.setState({
            distance : event.target.value,
            // duration : event.target.value
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Transit Delay Settings</h5>
                </div>
                <form className=" theme-form col-xl-8 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>      
                        {/* fajk   */}
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Same Day Delay to be Considered as Delay * : </label>
                            &nbsp; <input type="radio" name="sameDayDelay" id="sameDayDelayID" value="yes"  /> Yes 
                            &nbsp; <input type="radio" name="sameDayDelay" id="sameDayDelayID" value="no" /> No
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Delay Duration (Hours) for Exception *:</label>
                            <input type="number" name="duration" min="0" id="delayDurationID" value={this.state.delayDuration} className="form-control validateNumber" placeholder="Duration in Hours" autoComplete="off" onChange={this.checkDuration.bind(this)} required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Critical Consignment MSIL Email to *:</label>
                            <input type="textarea" name="" id="MSILID"  autoComplete="off" value={this.state.criticalConsignment} className="form-control" placeholder="" onChange={this.criticalConsignment.bind(this)} required />
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Critical Consignment MSIL Email CC to *:</label>
                            <input type="textarea" name="" id="MSILCCID"  autoComplete="off" value={this.state.criticalConsignmentCC} className="form-control" placeholder="" onChange={this.criticalConsignmentCC.bind(this)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#MSILCCID",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

    });
    $(document).on("keyup","#MSILID",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

    });
    $(document).on("keyup","#delayDurationID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
    })
});

