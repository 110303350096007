/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import '@ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';
import DateFormater from '../layouts/dateComponent';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import CountUp from 'react-countup';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { components } from 'react-select/dist/react-select.cjs.prod';
$.datetimepicker.setLocale('en');
// var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return (text) =>
            text
                .split("")
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join("");
        // eslint-disable-next-line no-unreachable
    } catch (e) { }
};
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class VehicleArrivalDashboard extends Component {
    state = {
        startDate: moment.parseZone().format('YYYY-MM-DD'),
        endDate: moment.parseZone().format('YYYY-MM-DD'),
        modules: AllModules,
        eventGridAction: "gridAction",
        eventFormAction: "formAction",
        pageTitle: "Transporter Performance Dashboard",
        loadshow: 'show-n',
        overly: "show-n",
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            // editable: true,
            resizable: true
        },
        animateRows: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
        },
        popupParent: document.body,
        loadingCellRenderer: "customLoadingCellRenderer",
        paginationPageSize: 50,
        rowClassRules: {
            "colorForDangerLight": function (params) {
                if (params.data.parameters == "growth") {
                    return true;
                }
                else {
                    return false;
                }
            }
        }, 
        groupHeaderHeight: 50,
        headerHeight: 40,
        floatingFiltersHeight: 50,
        pivotGroupHeaderHeight: 30,
        pivotHeaderHeight: 50,
        rowHeight: 50,
        gridData : [],
        columnHoverHighlight: true,
    }

    componentDidMount() {
        this.setState({
            loadshow : 'show-m',
            overly : 'show-m'
        })
        this.onLoadPageData()
    }

    onLoadPageData= () =>{

        redirectURL.post("/consignments/getVehicleArrivalDashboardDetails", ).then( resp =>{
            console.log("respData", resp.data)
            this.setState({
                gridData : resp.data,
                loadshow: 'show-n',
                overly: "show-n",
            })

        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        // this.gridApi.sizeColumnsToFit();
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    render() {
        const { show, basicTitle, basicType, modules, defaultColDef, autoGroupColumnDef, gridData, groupHeaderHeight, headerHeight, floatingFiltersHeight, pivotGroupHeaderHeight, rowHeight, pivotHeaderHeight, frameworkComponents, popupParent, overly, loadshow, } = this.state

        const columnDefs = [
            {
                headerName: "Vehicle Arrival Dashboard",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", ],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Transporter Code",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "transporter_code",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Transporter Name",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "transporter_name",
                        width: 160,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Truck No",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "truck_no",
                        width: 120,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },                    
                    {
                        headerName: "Last Packet Time",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "timestamp_x",
                        width: 140,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series",
                        valueGetter: (params)=>{
                            if(params.data.timestamp_x !== undefined && params.data.timestamp_x !== ''){
                                return getHyphenDDMMMYYYYHHMM(params.data.timestamp_x);
                            }else{
                                return ''
                            }
                        }
                    },
                ]
            },
            {
                headerName: "Current Status",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridM", ],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Dharuhera",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Dharuhera",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Gurgaon",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Gurgaon",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Haridwar",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Haridwar",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Neemrana",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Neemrana",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Halol",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Halol",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Chittoor",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Current_Chittoor",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "1 Hour Before",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridSk", ],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Dharuhera",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Dharuhera",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Gurgaon",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Gurgaon",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Haridwar",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Haridwar",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Neemrana",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Neemrana",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Halol",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Halol",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Chittoor",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "Past_Chittoor",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "Remarks",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridM", ],
                // chartDataType: 'series',
                width: 320,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Dharuhera",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Dharuhera",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Gurgaon",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Gurgaon",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Haridwar",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Haridwar",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Neemrana",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Neemrana",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Halol",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Halol",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Chittoor",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Remark_Chittoor",
                        width: 200,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridM", ],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Nearest Plant",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "Nearest_Plant",
                        width: 100,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridW", ],
                        chartDataType: "series"
                    }                   
                ]
            },           
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icon-map cus-i"></i> <span>Vehicle Arrival Dashboard</span>
                            {/* <span className='float-right' >{time}</span> */}
                        </h5>
                    </div>
                    {/* grid data start*/}
                    <div id="myGrid" style={{ height: "900px", width: "100%" }}
                        className={"ag-theme-balham "} >
                        <AgGridReact
                            modules={modules}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            sideBar={false}
                            groupUseEntireRow={false}
                            enableRangeSelection={true}
                            groupRowAggNodes={this.groupRowAggNodes}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            rowData={gridData}
                            groupHeaderHeight={groupHeaderHeight}
                            headerHeight={headerHeight}
                            floatingFiltersHeight={floatingFiltersHeight}
                            pivotGroupHeaderHeight={pivotGroupHeaderHeight}
                            pivotHeaderHeight={pivotHeaderHeight}
                            rowHeight={rowHeight}
                            frameworkComponents={frameworkComponents}
                            enableCharts={true}
                            columnHoverHighlight={true}
                            onFirstDataRendered={this.onFirstDataRendered}
                            suppressChartRanges={true}
                            onRowGroupOpened={this.onRowGroupOpened}
                            onCellClicked={this.onShowLegs}
                        // popupParent={popupParent}
                        //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
                        //suppressCount={true}
                        // pinnedBottomRowData={grandTotal}
                        // detailCellRendererParams={this.state.detailCellRendererParams}
                        // masterDetail={true}
                        />
                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>

            </div>
        )
    }
}