import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import download from 'js-file-download';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var path = require('path');

export default class EditCargoExport extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenter_name:"",
            mobile:"",
            indenter_email:"",
            erpPO:"",
            erpPoNo:"",
            erpPoDate:"",
            ringiNo:"",
            ringi_no:"",
            cargoDeliveryPlant:"",
            item_description:"",
            gross_weight:"",
            dimensions:"",
            consignee_name:"",
            consignee_address:"",
            consignee_contact_person:"",
            country:"",
            emailID:"",
            phone:"",
            destination_airport:"",
            delivery_terms:"",
            pick_up_address:"",
            cargoDate:"",
            commercial_invoice : "",
            commercial_invoice_date : "",
            payment_terms : "",
            shipment_return : "",
            cert_of_origin : "",
            dg : "",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            hiddenRingiFile:"",
            port : "",
            ringi_file : "",
            uploaded_ringi_file : "",
            airport_name : "",
            port : "",
            pickupData : [{}],
            ringiData : [{}],
            ffsuppliers: [],
            chasuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            invoice_docs:"",
            packing_docs:"",
            sqc_docs:"",
            annex_docs:"",
            gr_docs:"",
            msds_docs:"",
            iip_docs:"",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

		var inputEventval = window.atob(this.props.match.params.eventname);
		// console.log
		
		var reqparams = {
            eventID : inputEventval,
        }

        
        redirectURL.post("/consignments/aircargoexportssuppliers").then((response)=>{
            var ffsuppliers = response.data.ffsuppliers;
            var chasuppliers = response.data.chasuppliers;
            console.log(ffsuppliers,"ffsuppliers")
            console.log(chasuppliers,"chasuppliers")
            this.setState({
                ffsuppliers : ffsuppliers,
                chasuppliers : chasuppliers,
            }) 
        }).catch((e)=>{
            console.log(e);
        })

        redirectURL.post("/consignments/getAirCargoExportsByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            this.setState({erpSel : "",erpSel1 : "",cdpSel : "",cdpSel1 : "",cdpSel2 : "",cdpSel3 : "",cdpSel4 : "",gwSel : "",gwSel1 : "",dtSel : "",dtSel1 : "",dtSel2 : "",lcSel : "",lcSel1 : "",natSel : "",natSel1 : "",ftSel : "",ftSel1 : "",bcSel : "",bcSel1 : "",acSel : "",acSel1 : "",acSel2 : "",acSel3 : "",achSel : "",achSel1 : "",});
            if(response.data[0].pickup_plant == "MSIL Gurgaon")
            {
                this.setState({cdpSel : "selected"});
            }
            else if(response.data[0].pickup_plant == "MSIL Manesar")
            {
                this.setState({cdpSel1 : "selected"});
            }
            else if(response.data[0].pickup_plant == "MSIL MPT")
            {
                this.setState({cdpSel2 : "selected"});
            }
            else if(response.data[0].pickup_plant == "MSIL casting plant")
            {
                this.setState({cdpSel3 : "selected"});
            }

            if(response.data[0].invoice_file != undefined && response.data[0].invoice_file !='')
            {
                this.setState({invoice_docs:response.data[0].invoice_file})
            }
            if(response.data[0].packing_list_file != undefined && response.data[0].packing_list_file !='')
            {
                this.setState({packing_docs:response.data[0].packing_list_file})
            }
            if(response.data[0].sqc_list_file != undefined && response.data[0].sqc_list_file !='')
            {
                this.setState({sqc_docs:response.data[0].sqc_list_file})
            }
            if(response.data[0].annex_file != undefined && response.data[0].annex_file !='')
            {
                this.setState({annex_docs:response.data[0].annex_file})
            }
            if(response.data[0].gr_wavier_file != undefined && response.data[0].gr_wavier_file !='')
            {
                this.setState({gr_docs:response.data[0].gr_wavier_file})
            }
            if(response.data[0].msds_file != undefined && response.data[0].msds_file !='')
            {
                this.setState({msds_docs:response.data[0].msds_file})
            }
            if(response.data[0].iip_file != undefined && response.data[0].iip_file !='')
            {
                this.setState({iip_docs:response.data[0].iip_file})
            }
            

            if(response.data[0].ff_code != undefined && response.data[0].ff_code !='')
            {
                $("#assign_to_ff").val(response.data[0].ff_code);
            }            
            if(response.data[0].cha_code != undefined && response.data[0].cha_code !='')
            {
                $("#assign_to_cha").val(response.data[0].cha_code);
            }            
            if(response.data[0].transporter_code != undefined && response.data[0].transporter_code !='')
            {
                $("#assign_to_transporter").val(response.data[0].transporter_code);
            }
            var pickupData=[{}];
            if(response.data[0].pickupData !=undefined && response.data[0].pickupData !='')
            {
                var pickupData = JSON.parse(response.data[0].pickupData);
            }
            var ringiData=[{}];
            if(response.data[0].ringiData !=undefined && response.data[0].ringiData !='')
            {
                var ringiData = JSON.parse(response.data[0].ringiData);
            }
            var date ="";
            if(response.data[0].date !=undefined && response.data[0].date !='')
            {
                date = response.data[0].date;
                if(date.includes("T") == true)
                {
                    date = date.split("T");
                    date = date[0];
                    date = date.split("-")[2]+"-"+date.split("-")[1]+"-"+date.split("-")[0];
                }
            }
            var cargo_date ="";
            if(response.data[0].cargo_date !=undefined && response.data[0].cargo_date !='')
            {
                cargo_date = response.data[0].cargo_date;
                if(cargo_date.includes("T") == true)
                {
                    cargo_date = cargo_date.split("T");
                    cargo_date = cargo_date[0];
                    cargo_date = cargo_date.split("-")[2]+"-"+cargo_date.split("-")[1]+"-"+cargo_date.split("-")[0];
                }
            }
            var eta_igi_airport_date = "";
            if(response.data[0].eta_igi_airport_date !=undefined && response.data[0].eta_igi_airport_date !='')
            {
                eta_igi_airport_date = response.data[0].eta_igi_airport_date;
                if(eta_igi_airport_date.includes("T") == true)
                {
                    eta_igi_airport_date = eta_igi_airport_date.split("T");
                    eta_igi_airport_date = eta_igi_airport_date[0];
                    eta_igi_airport_date = eta_igi_airport_date.split("-")[2]+"-"+eta_igi_airport_date.split("-")[1]+"-"+eta_igi_airport_date.split("-")[0];
                }
            }
            var commercial_invoice_date ="";
            if(response.data[0].commercial_invoice_date !=undefined && response.data[0].commercial_invoice_date !='')
            {
                commercial_invoice_date = response.data[0].commercial_invoice_date;
                if(commercial_invoice_date.includes("T") == true)
                {
                    commercial_invoice_date = commercial_invoice_date.split("T");
                    commercial_invoice_date = commercial_invoice_date[0];
                    commercial_invoice_date = commercial_invoice_date.split("-")[2]+"-"+commercial_invoice_date.split("-")[1]+"-"+commercial_invoice_date.split("-")[0];
                }
            }
            if(response.data[0].delivery_terms !=undefined && response.data[0].delivery_terms !='')
            {
                $("#delivery_terms").val(response.data[0].delivery_terms);
            }
            if(response.data[0].payment_terms !=undefined && response.data[0].payment_terms !='')
            {
                $("#payment_terms").val(response.data[0].payment_terms);
            }
            if(response.data[0].shipment_return !=undefined && response.data[0].shipment_return !='')
            {
                $("#shipment_return").val(response.data[0].shipment_return);
            }
            if(response.data[0].cert_of_origin !=undefined && response.data[0].cert_of_origin !='')
            {
                $("#cert_of_origin").val(response.data[0].cert_of_origin);
            }
            if(response.data[0].dg !=undefined && response.data[0].dg !='')
            {
                $("#dg").val(response.data[0].dg);
            }
            
			this.setState({
				event : response.data[0].event,
				editedEventID : response.data[0]._id,
				oldEvent : response.data[0].event,
				airport_name : response.data[0].airport_name,
                benefit_certificate : response.data[0].benefit_certificate,
                assign_to_ff : response.data[0].assign_to_ff,
                assign_to_cha : response.data[0].assign_to_cha,
                assign_to_transporter : response.data[0].assign_to_transporter,
                pickupData : pickupData,
				cargo_date : cargo_date,
				pickup_plant : response.data[0].pickup_plant,
                country : {"label": response.data[0].country,"value" : response.data[0].country},
                date : date,
                delivery_terms : response.data[0].delivery_terms,
                dept_name : response.data[0].dept_name,
                email : response.data[0].email,
                erpPO : response.data[0].erpPO,
                free_trade : response.data[0].free_trade,
                indenter_email : response.data[0].indenter_email,
                indenter_name : response.data[0].indenter_name,
                job_no : response.data[0].job_no,
                commercial_invoice : response.data[0].commercial_invoice,
                commercial_invoice_date : commercial_invoice_date,
                payment_terms : response.data[0].payment_terms,
                mobile : response.data[0].mobile,
                shipment_return : response.data[0].shipment_return,
                cert_of_origin : response.data[0].cert_of_origin,
                dg : response.data[0].dg,
                no_of_packets : response.data[0].no_of_packets,
                phone : response.data[0].phone,
                destination_airport : response.data[0].destination_airport,
                port : response.data[0].port,
                pick_up_address : response.data[0].pick_up_address,
                ringiData : ringiData,
                consignee_address : response.data[0].consignee_address,
                consignee_contact_person : response.data[0].consignee_contact_person,
                consignee_name : response.data[0].consignee_name,
                eta_igi_airport_date : eta_igi_airport_date,
                //ff_contact_person_details : response.data[0].ff_contact_person_details,
                //fta : response.data[0].fta,
                request_type : response.data[0].request_type,
            })
            // if(response.data[0].fta !=undefined && response.data[0].fta !="")
            // {
            //     $("#fta").val(response.data[0].fta);
            // }
            var request_type = response.data[0].request_type;
            if(request_type == 2)
            {
                console.log(request_type,"request_type")
                var requestFields = "show-n";
                var prepaidFields = "show-m";
                var addresstxt = "(To be mentioned on BOE)";
                //$(".requestFields").removeAttr("required",true);
                //$(".prepaidFields").attr("required",true);
            }
            else
            {
                console.log(request_type,"request_type")
                var requestFields = "show-m";
                var prepaidFields = "show-n";
                var addresstxt = "";
                //$(".requestFields").attr("required",true);
                //$(".prepaidFields").removeAttr("required",true);
            }
            this.setState({
                requestFields:requestFields,
                prepaidFields:prepaidFields,
                addresstxt:addresstxt,
            })
        }).catch(function(error){
            console.log(error);
		})
		
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});        
}
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var eventTarget = event.target;
    var request_type = this.state.request_type;
    //console.log(this.state.file,"this.state.file");
    if($("#dateField").val() == "__-__-____")
    {
        var date = "";
    }
    else{
        var date = $("#dateField").val();
    }
    if($("#erpPoDate").val() == "__-__-____")
    {
        var erpPo_date = "";
    }
    else{
        var erpPo_date = $("#erpPoDate").val();
    }
    if($("#cargoDate").val() == "__-__-____")
    {
        var cargo_date = "";
    }
    else{
        var cargo_date = $("#cargoDate").val();
    }
    var rngLength=$("#rngLength").val();
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        var ringiDesc=$("#ringiDesc_"+r).val();
        if(ringiFile !='')
        {
            ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
    }
    var pktLength=$("#no_of_packets").val();
    var pickupData=[];
    for(var p=0;p<pktLength;p++)
    {
        var itemDesc=$("#itemDesc_"+p).val();
        var grossWt=$("#grossWt_"+p).val();
        var dimensions=$("#dimensions_"+p).val();
        var ccw=$("#cargo_chargeable_weight_"+p).val();
        if(itemDesc !='' || grossWt !='' || dimensions !='' || ccw !='')
        {
            var temp = {"item_description":itemDesc,"cargo_gross_weight":grossWt,"dimensions":dimensions,"cargo_chargeable_weight":ccw}
            pickupData.push(temp)
        }
    }
    pickupData=JSON.stringify(pickupData);
    ringiData=JSON.stringify(ringiData);

    console.log(ringiData);

    var dept_name=$("#dept_name").val();
    var indenter_name=$("#indenter_name").val();
    var mobile=$("#mobile").val();
    var indenter_email=$("#indenter_email").val();
    var erpPO=$("#erpPO").val();
    var ringi_no=$("#ringi_no").val();
    var pickup_plant=$("#pickup_plant").val();
    var item_description=$("#item_description").val();
    var gross_weight=$("#gross_weight").val();
    var dimensions=$("#dimensions").val();
    var consignee_name=$("#consignee_name").val();
    var consignee_address=$("#consignee_address").val();
    var consignee_contact_person=$("#consignee_contact_person").val();
    var country=this.state.country.value;
    //console.log(country);
    var destination_airport=$("#destination_airport").val();
    var email=$("#email").val();
    var phone=$("#phone").val();
    var delivery_terms=$("#delivery_terms").val();
    var pick_up_address=$("#pick_up_address").val();
    var port=$("#port").val();
    var airport_name=$("#airport_name").val();
    var lc=$("#lc").val();
    var lc_no=$("#lc_no").val();
    var nature=$("#nature").val();
    var free_trade=$("#free_trade").val();
    var benefit_certificate=$("#benefit_certificate").val();
    var ff_code = "";
    var assign_to_ff = "";
    // if(request_type !=2)
    // {
        var ff_code=$("#assign_to_ff").val();
        var assign_to_ff=$("#assign_to_ff").find('option:selected').text();    
    //}
    var cha_code=$("#assign_to_cha").val();
    var assign_to_cha=$("#assign_to_cha").find('option:selected').text();
    var transporter_code=$("#assign_to_transporter").val();
    var assign_to_transporter=$("#assign_to_transporter").find('option:selected').text();
    var no_of_packets=$("#no_of_packets").val();

    var formfields = {
        date : date,
        dept_name : dept_name,
        indenter_name : indenter_name,
        mobile : mobile,
        indenter_email : indenter_email,
        pickup_plant : pickup_plant,
        consignee_name : consignee_name,
        consignee_address : consignee_address,
        consignee_contact_person : consignee_contact_person,
        country : country,
        email : email,
        phone : phone,
        delivery_terms : delivery_terms,
        pick_up_address : pick_up_address,
        port : port,
        airport_name : airport_name,
        cargo_date : cargo_date,
        lc : lc,
        lc_no : lc_no,
        nature : nature,
        free_trade : free_trade,
        benefit_certificate : benefit_certificate,
        ff_code : ff_code,
        cha_code : cha_code,
        transporter_code : transporter_code,
        assign_to_ff : assign_to_ff,
        assign_to_cha : assign_to_cha,
        assign_to_transporter : assign_to_transporter,
    }
    
    //console.log(formfields);
    var flag= 0;
    var emailflag = 0;
    for(let key in formfields)
    {
        // console.log(key,formfields[key]);editedEventID
        // if(key != "lc_no" && key != "pick_up_address" && key != "port" && key != "airport_name" && key != "erpPoNo" && key != "erpPo_date" && key !="ringi_file" && key !="hiddenRingiFile" && key !="ringiNo_base64")
        // {
        //     console.log(key)
        //     if(formfields[key].replace(/\s/g,"").length == 0)
        //     {
        //         console.log(key,formfields[key]);
        //         flag = 1;
        //     }
        // }
        if(key == "intender_email")
        {   
            //console.log(formfields[key].split("@"))
            if(formfields[key].split("@")[1] != "maruti.co.in")
            {
                emailflag = 1;
            }
        }
    }
    
    if(flag == 0 && emailflag == 0)
    {
        // console.log(ringiNo)
        let saveData = new FormData();
        saveData.append("request_type", this.state.request_type);
        saveData.append("rngLength", rngLength);
        for(var rn=0;rn<rngLength;rn++)
        {
            var ringiFile="ringi_file_"+rn;
            
            saveData.append(ringiFile,this.state[ringiFile]);
            console.log(this.state[ringiFile]);

            var hdringiFile = $("#hiddenRingiFile_"+rn).val();
            var hdringiName = "hiddenRingiFile_"+rn;
            saveData.append(hdringiName,hdringiFile);
            console.log(hdringiFile);
        }
        
        var no_of_packets=$("#no_of_packets").val();
        var hidden_invoice_file = $("#hidden_invoice_file").val();
        saveData.append("hidden_invoice_file",hidden_invoice_file);
        var hidden_packing_file = $("#hidden_packing_file").val();
        saveData.append("hidden_packing_file",hidden_packing_file);
        var hidden_sqc_file = $("#hidden_sqc_file").val();
        saveData.append("hidden_sqc_file",hidden_sqc_file);
        var hidden_annex_file = $("#hidden_annex_file").val();
        saveData.append("hidden_annex_file",hidden_annex_file);
        var hidden_gr_file = $("#hidden_gr_file").val();
        saveData.append("hidden_gr_file",hidden_gr_file);
        var hidden_msds_file = $("#hidden_msds_file").val();
        saveData.append("hidden_msds_file",hidden_msds_file);
        var hidden_iip_file = $("#hidden_iip_file").val();
        saveData.append("hidden_iip_file",hidden_iip_file);
        var commercial_invoice = $("#commercial_invoice").val();
        var commercial_invoice_date = $("#commercial_invoice_date").val();
        var payment_terms = $("#payment_terms").val();
        var shipment_return = $("#shipment_return").val();
        var cert_of_origin = $("#cert_of_origin").val();
        var dg = $("#dg").val();
        saveData.append("date",date);
        saveData.append("dept_name",dept_name);
        saveData.append("indenter_name",indenter_name);
        saveData.append("mobile",mobile);
        saveData.append("indenter_email",indenter_email);
        saveData.append("ringiData",ringiData);
        saveData.append("pickup_plant",pickup_plant);
        saveData.append("no_of_packets",no_of_packets);
        saveData.append("pickupData",pickupData);
        saveData.append("consignee_name",consignee_name);
        saveData.append("consignee_address",consignee_address);
        saveData.append("consignee_contact_person",consignee_contact_person);
        saveData.append("country",country);
        saveData.append("destination_airport",destination_airport);
        saveData.append("email",email);
        saveData.append("phone",phone);
        saveData.append("delivery_terms",delivery_terms);
        saveData.append("pick_up_address",pick_up_address);
        saveData.append("port",port);
        saveData.append("airport_name",airport_name);
        saveData.append("cargo_date",cargo_date);
        saveData.append("commercial_invoice",commercial_invoice);
        saveData.append("commercial_invoice_date",commercial_invoice_date);
        saveData.append("payment_terms",payment_terms);
        saveData.append("shipment_return",shipment_return);
        saveData.append("cert_of_origin",cert_of_origin);
        saveData.append("dg",dg);
        saveData.append("ff_code",ff_code);
        saveData.append("assign_to_ff",assign_to_ff);
        saveData.append("cha_code",cha_code);
        saveData.append("transporter_code",transporter_code);
        saveData.append("assign_to_cha",assign_to_cha);
        saveData.append("assign_to_transporter",assign_to_transporter);
        saveData.append("editedEventID",this.state.editedEventID);
        saveData.append("hiddenRingiFile",this.state.ringi_file);
        saveData.append("job_no",this.state.job_no);
        //saveData.append("ff_contact_person_details",this.state.ff_contact_person_details);
        //saveData.append("fta",this.state.fta);
        saveData.append("updated_by",localStorage.getItem("email"));
        saveData.append("updated_by_user",localStorage.getItem("username"));
        console.log(saveData);

        redirectURL.post("/consignments/updatecargoExportsFormData",saveData,{
            headers:{
                'content-type': 'multipart/form-data'
            }
        }).then((response)=>{   
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true
            });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch((e)=>{
            console.log(e);
        })
        
    }
    else
    {
        if(emailflag == 0)
        {
            this.setState({
                basicTitle:"Fields Should not be Empty",
                basicType : "warning",
                show : true
            })
        }
        else{
            this.setState({
                basicTitle:"Invalid Email. Must be maruti.co.in",
                basicType : "warning",
                show : true
            })
        }
        
    }
}


handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeExportsRingiFile",rngParam).then((response)=>{
        }).catch(function(error){
            console.log(error);
        })
    }
};

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeExportsRingiFile",rngParam).then((response)=>{
            $(".rnFile_"+idx).html("");
            this.hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}



handleRemoveFile = (ringiFile,clickedDiv) => {
    console.log(ringiFile)
    if(ringiFile !=undefined && ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeExportsRingiFile",rngParam).then((response)=>{
            $("#"+clickedDiv).hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !=undefined && ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) > 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight: "",
            };
            this.setState({
                pickupData: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight: "",
        };
        this.setState({
            pickupData: [item]
        });
    }
}

countryoptions(){
    var items=[];
    items.push({label:"Algeria",value:"Algeria"})
    items.push({label:"Australia",value:"Australia"})
    items.push({label:"Austria",value:"Austria"})
    items.push({label:"Belgium",value:"Belgium"})
    items.push({label:"Canada",value:"Canada"})
    items.push({label:"Chile",value:"Chile"})
    items.push({label:"Czech Republic",value:"Czech Republic"})
    items.push({label:"China",value:"China"})
    items.push({label:"Denmark",value:"Denmark"})
    items.push({label:"Dubai",value:"Dubai"})
    items.push({label:"France",value:"France"})
    items.push({label:"Germany",value:"Germany"})
    items.push({label:"Hong Kong",value:"Hong Kong"})
    items.push({label:"Hungary",value:"Hungary"})
    items.push({label:"Indonesia",value:"Indonesia"})
    items.push({label:"Italy",value:"Italy"})
    items.push({label:"Japan",value:"Japan"})
    items.push({label:"Malaysia",value:"Malaysia"})
    items.push({label:"Myanmar",value:"Myanmar"})
    items.push({label:"Nigeria",value:"Nigeria"})
    items.push({label:"Netherlands",value:"Netherlands"})
    items.push({label:"Poland",value:"Poland"})
    items.push({label:"Romania",value:"Romania"})
    items.push({label:"S. Korea",value:"S. Korea"})
    items.push({label:"Serbia",value:"Serbia"})
    items.push({label:"Singapore",value:"Singapore"})
    items.push({label:"Slovakia",value:"Slovakia"})
    items.push({label:"South Africa",value:"South Africa"})
    items.push({label:"Spain",value:"Spain"})
    items.push({label:"Sweden",value:"Sweden"})
    items.push({label:"Switzerland",value:"Switzerland"})
    items.push({label:"Taiwan",value:"Taiwan"})
    items.push({label:"Thailand",value:"Thailand"})
    items.push({label:"Turkey",value:"Turkey"})
    items.push({label:"UK",value:"UK"})
    items.push({label:"USA",value:"USA"})
    items.push({label:"Vietnam",value:"Vietnam"})
    return items;
}

onSelectLocation= country =>{
    this.setState(
        { country },
        () => console.log(`Location Option selected:`, this.state.country)
      );
}

render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Air Cargo Form </span>
								</h5>
				   			</div>
                               
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="date" id="dateField" value={this.state.date} className="datetimepicker_date form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department Name : </label>
                                            <input type="text" name="dept_name" id="dept_name" className="form-control" value={this.state.dept_name} required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Name : </label>
                                            <input type="text" name="indenter_name" id="indenter_name" className="form-control" value={this.state.indenter_name} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Mobile No : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control" pattern="[0-9]{10}" value={this.state.mobile} required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Email ID : </label>
                                            <input type="email" name="indenter_email" id="indenter_email" className="form-control" value={this.state.indenter_email} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringiData.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No (File): </label>
                                            <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control" onChange={this.changeFileHandler} />

                                            {((item.ringi_file != "") && (item.ringi_file != undefined)) ?  
                                            <div className="row col-xl-12">
                                                <div className="col-xl-9">
                                                     <a className={"rnFile_"+idx}>{item.ringi_file}</a> 
                                                </div>
                                                <div className="col-xl-1">
                                                    <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                    
                                                    {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.ringi_file))}>Test </a> */}
                                                </div>
                                                <div className="col-xl-1">
                                                    <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleRemoveRingiFile(idx)}><i title="Delete" className="fa fa-trash"></i></p>
                                                </div>
                                                <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.ringi_file} />
                                            </div>
                                            :
                                            ""
                                            }
                                            
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No : </label>
                                            <input type="text" name={"ringiDesc_"+idx} id={"ringiDesc_"+idx} className="form-control" value={item.ringi_desc} onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em",cursor:"pointer"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pickup Plant : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="pickup_plant" id="pickup_plant" required onChange={this.changeHandler}>
                                                <option value="">-- Select Cargo Delivery Plant --</option>
                                                <option selected={this.state.cdpSel} value="MSIL Gurgaon">MSIL Gurgaon</option>
                                                <option selected={this.state.cdpSel1} value="MSIL Manesar">MSIL Manesar</option>
                                                <option selected={this.state.cdpSel2} value="MSIL MPT">MSIL MPT</option>
                                                <option selected={this.state.cdpSel3} value="MSIL casting plant">MSIL casting plant</option>
                                            </select>
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No Of Packets : </label>
                                            <input type="text" name="no_of_packets" id="no_of_packets" className="form-control num-cls" value={this.state.no_of_packets} onBlur={this.packetsHandler}/>
                                        </div>
                                    </div>
                                    {this.state.pickupData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Item Description : </label>
                                            <input type="text" name={"itemDesc_"+idx} id={"itemDesc_"+idx}className="form-control" value={item.item_description} onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight : </label>
                                            <input type="text" name={"grossWt_"+idx} id={"grossWt_"+idx}className="form-control num-cls" value={item.cargo_gross_weight} onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Dimensions (cms) : </label>
                                            <input type="text" name={"dimensions_"+idx} id={"dimensions_"+idx} placeholder="l x b x h" className="form-control requestFields" value={item.dimensions} onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">Cargo Chargeable Weight* : </label>
                                            <input type="text" name={"cargo_chargeable_weight_"+idx} id={"cargo_chargeable_weight_"+idx} className="form-control prepaidFields" value={item.cargo_chargeable_weight} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>                                   
                                    <div className="row col-xl-12">
                                        <h4 style={{color: "#fff",width: "100%",backgroundColor: "#3d6db7",textAlign: "center",fontSize: "18px",padding: "6px 0"}}>Consignee Details</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Name : </label>
                                            <input type="text" name="consignee_name" id="consignee_name" value={this.state.consignee_name} className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Address {this.state.addresstxt}* : </label>
                                            <textarea name="consignee_address" id="consignee_address" value={this.state.consignee_address} className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Consignee Contact Person : </label>
                                            <input type="text" name="consignee_contact_person" id="consignee_contact_person" value={this.state.consignee_contact_person} placeholder="" className="form-control requestFields" required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Invoice* : </label>
                                            <input type="file" name={"invoice_file"} id={"invoice_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.invoice_docs != "") && (this.state.invoice_docs != undefined)) ?  
                                                <div id="invoice_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.invoice_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.invoice_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.invoice_docs,"invoice_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_invoice_file"} value={this.state.invoice_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Packing List* : </label>
                                            <input type="file" name={"packing_list_file"} id={"packing_list_file"} className="form-control requestFields" onChange={this.changeFileHandler}  />
                                            {((this.state.packing_docs != "") && (this.state.packing_docs != undefined)) ?  
                                                <div id="packing_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.packing_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.packing_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.packing_docs,"packing_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_packing_file"} value={this.state.packing_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">SQC List : </label>
                                            <input type="file" name={"sqc_list_file"} id={"sqc_list_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.sqc_docs != "") && (this.state.sqc_docs != undefined)) ?  
                                                <div id="sqc_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.sqc_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.sqc_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.sqc_docs,"sqc_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_sqc_file"} value={this.state.sqc_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Annex II : </label>
                                            <input type="file" name={"annex_file"} id={"annex_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.annex_docs != "") && (this.state.annex_docs != undefined)) ?  
                                                <div id="annex_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.annex_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.annex_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.annex_docs,"annex_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_annex_file"} value={this.state.annex_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">GR wavier – in case of free of charge : </label>
                                            <input type="file" name={"gr_wavier_file"} id={"gr_wavier_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.gr_docs != "") && (this.state.gr_docs != undefined)) ?  
                                                <div id="gr_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.gr_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.gr_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.gr_docs,"gr_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_gr_file"} value={this.state.gr_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MSDS – in case of DG : </label>
                                            <input type="file" name={"msds_file"} id={"msds_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.msds_docs != "") && (this.state.msds_docs != undefined)) ?  
                                                <div id="msds_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.msds_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.msds_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.msds_docs,"msds_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_msds_file"} value={this.state.msds_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">IIP certificate (UN packing) – in case of DG : </label>
                                            <input type="file" name={"iip_file"} id={"iip_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                            {((this.state.iip_docs != "") && (this.state.iip_docs != undefined)) ?  
                                                <div id="iip_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.iip_docs}</a>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.iip_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleRemoveFile(this.state.iip_docs,"iip_docs")}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_iip_file"} value={this.state.iip_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Country : </label>
                                           <Select  
                                           placeholder={"Select Country"} 
                                           isMulti={false} 
                                           value={this.state.country} 
                                           onChange={this.onSelectLocation.bind(this)} className="border-radius-0" 
                                           style={{borderRadius:"0px"}} 
                                           options={this.countryoptions()} 
                                           required />
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Destination Airport* : </label>
                                            <input type="text" name="destination_airport" id="destination_airport" className="form-control requestFields" value={this.state.destination_airport} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Email ID : </label>
                                            <input type="email" name="email" id="email" className="form-control requestFields" value={this.state.email} required onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Phone No : </label>
                                            <input type="text" name="phone" id="phone" placeholder="" className="form-control requestFields" value={this.state.phone} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Delivery/Inco Terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="delivery_terms" id="delivery_terms" required onChange={this.changeHandler}>
                                                <option value="">-- Select Delivery Terms --</option>
                                                <option value="Free on Board (FOB)">Free on Board (FOB)</option>
                                                <option value="Cost and Freight (CFR)">Cost and Freight (CFR)</option>
                                                <option value="Cost Insurance Freight (CIF)">Cost Insurance Freight (CIF)</option>
                                                <option value="Delivered at Place (DAP)">Delivered at Place (DAP)</option>
                                                <option value="Delivery Duty Paid (DDP)">Delivery Duty Paid (DDP)</option>
                                                <option value="Delivered Duty Unpaid (DDU)">Delivered Duty Unpaid (DDU)</option>
                                            </select>
                                        </div>
                                        {(this.state.delivery_terms == "Delivery Duty Paid (DDP)" || this.state.delivery_terms == "Delivered Duty Unpaid (DDU)")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Delivery Address* : </label>
                                                <textarea className="form-control dterms" name="pick_up_address" id="pick_up_address" onChange={this.changeHandler} required>{this.state.pick_up_address}</textarea>
                                            </div> : ""
                                            // :(this.state.delivery_terms == "FCA")?
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                            //         <input type="text" className="form-control dterms" name="port" id="port" onChange={this.changeHandler} required/>
                                            //     </div>
                                            // :
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Airport Name : </label>
                                            //         <input type="text" className="form-control dterms" name="airportName" id="airportName"  onChange={this.changeHandler} required/>
                                            //     </div>
                                        }
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date : </label>
                                            <input type="text" name="cargoDate" id="cargoDate" className="datetimepicker_date form-control requestFields" value={this.state.cargo_date} required onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "+this.state.requestFields}></div>
                                    <div className="row col-xl-12">
                                        
                                    <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice* : </label>
                                            <input type="text" name="commercial_invoice" id="commercial_invoice" value={this.state.commercial_invoice} className="form-control requestFields" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice Date* : </label>
                                            <input type="text" name="commercial_invoice_date" id="commercial_invoice_date" value={this.state.commercial_invoice_date} className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Payment terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="payment_terms" id="payment_terms" onChange={this.changeHandler} required>
                                                <option value="">-- Select Payment Terms --</option>
                                                <option value="Advance">Advance</option>
                                                <option value="Free of Cost (FOC)">Free of Cost (FOC)</option>
                                                <option value="Telegraphic Transfer (TTC)">Telegraphic Transfer (TTC)</option>
                                                <option value="Letter of credit (LC)">Letter of credit (LC)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                    <div className={"col-xl-4"}>
                                            <label className="col-xl-12 col-lg-12">Shipment Return (Re-Import)* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="shipment_return" id="shipment_return" onChange={this.changeHandler} required>
                                                <option value="">-- Select Shipment Return --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Certificate of origin* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cert_of_origin" id="cert_of_origin" onChange={this.changeHandler} required>
                                                <option value="">-- Select Benefit Certificate --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">DG* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="dg" id="dg" onChange={this.changeHandler} required>
                                                <option value="">-- Select DG/Non DG --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Assign To Transporter: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_transporter" id="assign_to_transporter" required onChange={this.changeHandler}>
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.chasuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Assign To Custom House Agent: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_cha" id="assign_to_cha" required onChange={this.changeHandler}>
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.chasuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        {(this.state.request_type != 2) ?
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Assign To Frieght Forward: </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="assign_to_ff" id="assign_to_ff" required onChange={this.changeHandler}>
                                                <option value="">-- Select Assign To --</option>
                                                {this.state.ffsuppliers.map(function(item){
                                                    return <option value={item.supplier_code}>{item.supplier_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        : ""}
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});

$(document).on("change", "#cargoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#dateField").val();
    var cargoDate=$("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime >= endTime)
    {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})

