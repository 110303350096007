import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
//import CustomDateComponent from "../common/customdatepicker";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class ChaDocumentHistory extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "CHA Document History",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
		}
		
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.selectContainersOptionsItems = this.selectContainersOptionsItems.bind(this);
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   var sort = [
			{
			colId: 'eta_pod',
			sort: 'asc',
			},
		];
			this.gridApi.setSortModel(sort);
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
   
	selectOptionsItems() {
        let items = [];     
        //console.log("transporterslist ",this.state.transporterslist);
        this.state.transporterslist.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push({value:item.transporter_code,label:item.transporter_name}); 
    		}
        	
        });
        return items;
	}

	handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeArrayHandler = async searates => {
    	
    	await this.setState(
	      { searates },
	      () => console.log(`Option selected:`, this.state.searates)
	    );
    	
		//await console.log("Selecton ",this.state.searates.value)
		
    }

    changeContainerArrayHandler = container_no =>{
        this.setState(
	      { container_no },
	      () => console.log(`Container Option selected:`, this.state.container_no)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    changeTransporterHandler = transporter_code => {
        this.setState(
            { transporter_code },
            () => console.log(`Container Option selected:`, this.state.transporter_code)
          );
    }

    selectContainersOptionsItems(){
        let items = [];     
        //console.log(this.state.trucks);
        if(this.state.containerslist.length > 0)
        {
            this.state.containerslist.forEach(item => {
                if(item != '' && item != null)
                {
                    items.push({value:item,label:item}); 
                }
                
            });
        }
        
        return items;
    }
    
    formHandler = async (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
		});
		//console.log("this.state.searates.value ", this.state.searates.value)
		if(!this.state.searates.value)
		{
			this.setState({
				show:true,
				basicTitle:"Please select atleast one option",
				basicType:"danger",
				loadshow:'show-n'
			});
		}
		else{
			if(this.state.searates.value == 'Garhi')
			{

			}
			else	
			{
				await redirectURL.post("/imports/searateimports",{
					searates_pod:this.state.searates.value
				})
				.then((response) =>{
					//console.log("Searates Reponse ", response.data);
					var importRecords = response.data.records;
					//console.log(importRecords);
					var documents = response.data.documents;
					
					var records=[];
					importRecords.map(function(k){
						var flag=0;
						documents.map(function(e){
							if(e.bl_no == k.hbl_no && e.document_sent_cha_date != "")
							{
								flag =1;

							}
						});
						if(flag == 1)
						{
							records.push(k);
						}

					})
					
					//console.log(records)
					let newArray = []; 
              
					// Declare an empty object 
					let uniqueObject = {}; 
					var objTitle;
					// Loop for the array elements 
					for (let i in records) { 
			
						// Extract the title 
						objTitle = records[i]['hbl_no']; 
			
						// Use the title as the index 
						uniqueObject[objTitle] = records[i]; 
					} 
					
					// Loop to push unique object into array 
					for (var a in uniqueObject) { 
						newArray.push(uniqueObject[a]); 
					} 

					var dataArray = [];
					try{

						if(newArray.length > 0)
						{
							newArray.map((item) => {
								dataArray.push({
									"bl_no" : item.hbl_no,
									"searates_eta_pod" : item.searates_eta_pod,
									"searates_ata_pod" : item.searates_ata_pod,
									"supplier_code" : item.supplier_code,
									
								})
							});
							var searates_date = moment.parseZone(new Date("2020-07-01 00:00:00")).utcOffset("+05:30")._d;
							dataArray = dataArray.filter(function(e){
								if(e.searates_eta_pod != null && e.searates_eta_pod != undefined)
								{
									var searates_eta_pod = moment.parseZone(new Date(e.searates_eta_pod)).utcOffset("+05:30")._d
									// console.log(searates_eta_pod)
									if(searates_eta_pod >= searates_date)
									{
										return e;
									}
									
								}
								else
								{
									return e;
								}
							})
							dataArray.map(function(k){
								var flag=0;
								documents.map(function(e){
									if(e.bl_no == k.bl_no)
									{
										flag =1;
										k['document_received_date'] = e.document_received_date;
										k['document_sent_cha_date'] = e.document_sent_cha_date;
										k["job_no"] = e.job_no;
									}
								});
							})
							//console.log(dataArray);
							this.setState({
								rowData:dataArray,
								loadshow:'show-n'
							})
						}
						else
						{
							this.setState({
								rowData : [],
								loadshow:'show-n',
								show:true,
								basicTitle : "No Data Found",
								basicType : "warning"
							})
						}
					}
					catch(e){
						console.log("Response Error ", e)
					}
				})
				.catch(e => {
					console.log("Error ",e);
				})
			}
		}
    }


    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
		});
		var assignarr=[];
		itemsToUpdate.map((item) => {
			var cdate =new Date();
			var currentDate = getDDMMYYYYHHMMSS(cdate);
			assignarr.push({
				"hbl_no":item.bl_no, 
				"document_received_date":item.document_received_date,
				"document_sent_cha_date":item.document_sent_cha_date
			});
		})

	   // var res = this.gridApi.updateRowData({ update: itemsToUpdate });
	   console.log("itemsToUpdate " , itemsToUpdate)
	   console.log("assignarr " , assignarr)

	   var urlpath = '/imports/storeDocumentReceive';
	   var reqparams = {
		   updateData:itemsToUpdate
	   } 
		redirectURL.post(urlpath,reqparams)
		.then((response) => {
			console.log("Response ", response)
			//if(response.data.status == 'Success')
			//{
				this.setState({
					loadshow:'show-n',
					successMessage:"Successfully updated"
					
				})
			//}
		})
		.catch(function (error) {
			console.log(error);
		});
		
	}
	
	applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
				{
					headerName : "Job No",
					colId : "job_no",
					field : "job_no",
					width:120,
					editable : false
				},
				{
					headerName: "BL No",
					colId : "bl_no",
					field: "bl_no",
					width: 160,
					filter: true,
                    resizable: true
				},
				{
					headerName: "ETA POD",
					field: "searates_eta_pod",
					colId : "eta_pod",
					width: 160,
					filter: true,
					resizable: true,
					editable:false, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYY(params.data.searates_eta_pod)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					// cellRenderer: function(params) {
					// 	return  '<i class="fa fa-calendar-o" aria-hidden="true"></i>'+params.value;
					// },
				   
					//cellEditor: "datePicker"

				},
				{
					headerName: "ATA POD",
					colId : "searates_ata_pod",
					field: "searates_ata_pod",
					width: 160,
					filter: true,
					resizable: true,
					editable:false, 
					valueGetter:function(params){
						return getHyphenDDMMMYYYY(params.data.searates_ata_pod)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					// cellRenderer: function(params) {
					// 	return  '<i class="fa fa-calendar-o" aria-hidden="true"></i>'+params.value;
					// },
				   
					//cellEditor: "datePicker"
				},
				{
					headerName: "Supplier",
					colId : "supplier_code",
					field: "supplier_code",
					width: 200,
					filter: true,
					resizable: true,
					editable:false, 
					// valueGetter:function(params){
					// 	return getDDMMYYYYHHMMSS(params.data.searates_ata_pod)
					// }
					// cellRenderer: function(params) {
					// 	return  '<i class="fa fa-calendar-o" aria-hidden="true"></i>'+params.value;
					// },
				   
					//cellEditor: "datePicker"
				},
				{
					
					headerName: "Document Received Date",
					field: "document_received_date",
					colId : "document_received_date",
					width: 140,
					filter: true,
					resizable: true,
					editable:false, 
					valueGetter:function(params){
						if(params.data.document_received_date !='')
						{
							return getHyphenDDMMMYYYY(params.data.document_received_date)
						}
						else
						{
							return "";
						}
						
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					// cellEditor: DateEditor
					// cellRenderer: function(params) {
					// 	return  '<i class="fa fa-calendar-o" aria-hidden="true"></i>'+params.value;
					// },
				   
					//cellEditor: "datePicker"
				},
				
				
				{
					headerName: "Document Sent to CHA Date",
					field: "document_sent_cha_date",
					colId : "document_sent_cha_date",
					width: 140,
					filter: true,
					resizable: true,
					editable:false, 
					valueGetter:function(params){
						if(params.data.document_sent_cha_date !='')
						{
							return getHyphenDDMMMYYYY(params.data.document_sent_cha_date)
						}
						else
						{
							return "";
						}
						
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					// cellEditor: DateEditor
					//cellEditor: "datePicker"
				},
				
			];
		const dStyles={
			width:this.props.width,
            height:'500px',	
            marginLeft:'0px !important'

	    }
        
        const searatesList=[
			{value:"ALL",label:"ALL"},
            {value:"Nhava Sheva", label:"Nhava Sheva"},
            {value:"Pipavav", label:"Pipava"},
            {value:"Mundra", label:"Mundra"},
            //{value:"Garhi", label:"Garhi"}
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                    <form className="row theme-form col-xl-9 col-lg-9" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>Choose Port</label>
                                            <Select value={this.state.searates} className="border-radius-0" onChange={this.changeArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={searatesList} required />
					               			
										</div>
                                       
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label></label><br />
                                            <button type="submit" className="btn btn-success mt-9p">Get Data</button>
                                        </div>
                                    </form>
                                   

                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   {
									(this.state.rowData.length > 0)?
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													components={this.state.components}
													
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                           {/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
										</div>
										: ""
									}
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }



  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}