import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

var redirectURL = require('../redirectURL');

class Logout extends Component {

    constructor(props) {
        super(props);
        
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"manual"

        }
        redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)

            var loginType = localStorage.getItem("is_enmovil_transporter");
            localStorage.removeItem('token');
            localStorage.removeItem('userid');
            localStorage.removeItem('dept_code');
            localStorage.removeItem('firstname');
            localStorage.removeItem('lastname');
            localStorage.removeItem('email');
            //localStorage.setItem('menuText',[]);
            delete axios.defaults.headers.common['Authorization'];
            this.setState({
                redirectToReferrer:''
            });
            //console.log("logout successfully",localStorage.getItem('token'));
            if(loginType == 1)
            {
                window.location.href="/tptlogin";
            }
            else
            {
                window.location.reload();
            }
        })
    }
}


export default Logout;
