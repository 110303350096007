import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import save from 'material-ui/svg-icons/content/save';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditNewTransitReturns extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoChaId:"",
            other_docs:"",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            pagetype : 1,
            rowId: ""
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
        var inputEventval = window.atob(this.props.match.params.rowId);
        var reqparams = {
            rowId : inputEventval,
        }
        redirectURL.post("/consignments/gettransitbidetails",reqparams).then((response) =>{
        // console.log("datefield",response.data)
        var rtgp_dt ="";
        if(response.data[0].rtgp_dt !==undefined && response.data[0].rtgp_dt !=='')
        {
            rtgp_dt = response.data[0].rtgp_dt;
            if(rtgp_dt.includes("T") === true)
            {
                rtgp_dt = rtgp_dt.split("T");
                let rtgpDt = rtgp_dt[0];
                rtgp_dt = rtgpDt.split("-")[2]+"-"+rtgpDt.split("-")[1]+"-"+rtgpDt.split("-")[0];
            }
        }        
        var month_and_year_of_return ="";
        if(response.data[0].month_and_year_of_return !==undefined && response.data[0].month_and_year_of_return !=='')
        {
            month_and_year_of_return = response.data[0].month_and_year_of_return;
            if(month_and_year_of_return.includes("T") === true)
            {
                let monthAndYearOfReturn = month_and_year_of_return.split("T");
                let monthYearReturn = monthAndYearOfReturn[0];
                month_and_year_of_return = monthYearReturn.split("-")[2]+"-"+monthYearReturn.split("-")[1]+"-"+monthYearReturn.split("-")[0];
            }
        }
        var invoice_date ="";
        if(response.data[0].invoice_date !==undefined && response.data[0].invoice_date !=='')
        {
            invoice_date = response.data[0].invoice_date;
            if(invoice_date.includes("T") === true)
            {
                invoice_date = invoice_date.split("T");
                let invoiceDate = invoice_date[0];
                invoice_date = invoiceDate.split("-")[2]+"-"+invoiceDate.split("-")[1]+"-"+invoiceDate.split("-")[0];
            }
        }
        var acc_date ="";
        if(response.data[0].acc_date !==undefined && response.data[0].acc_date !=='')
        {
            acc_date = response.data[0].acc_date;
            if(acc_date.includes("T") === true)
            {
                acc_date = acc_date.split("T");
                let accDate = acc_date[0];
                acc_date = accDate.split("-")[2]+"-"+accDate.split("-")[1]+"-"+accDate.split("-")[0];
            }
        }

        $("#rtgp_dt").val(rtgp_dt);
        $("#month_and_year_of_return").val(month_and_year_of_return);
        $("#rtgp_no").val(response.data[0].rtgp_no)
        $("#stkyrd").val(response.data[0].stkyrd)
        $("#financial_year_of_return").val(response.data[0].financial_year_of_return)
        $("#bveh").val(response.data[0].bveh)
        $("#model").val(response.data[0].model)
        $("#prefix").val(response.data[0].prefix)
        $("#chass_no").val(response.data[0].chass_no)
        $("#vin").val(response.data[0].vin)
        $("#cate").val(response.data[0].cate)
        $("#source").val(response.data[0].source)
        $("#invc_amt").val(response.data[0].invc_amt)
        $("#dept").val(response.data[0].dept)
        $("#trtr").val(response.data[0].trtr)
        $("#trtr_name").val(response.data[0].trtr_name)
        $("#trtp").val(response.data[0].trtp)
        $("#regn").val(response.data[0].regn)
        $("#acc_time").val(response.data[0].acc_time)
        $("#acc_place").val(response.data[0].acc_place)
        $("#acc_state").val(response.data[0].acc_state)
        $("#region").val(response.data[0].region)
        $("#reason").val(response.data[0].reason)
        $("#acdt_detail").val(response.data[0].acdt_detail)
        $("#resp_tpt_code").val(response.data[0].resp_tpt_code)
        $("#responsible_transporter").val(response.data[0].responsible_transporter)
        $("#for_code").val(response.data[0].for_code)
        $("#for_desc").val(response.data[0].for_desc)
        $("#acc_date").val(acc_date)
        $("#invoice_date").val(invoice_date)
     })
        this.setState({
            rowId : inputEventval,           
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });    
}
changeHandler = (event) => {
    // console.log("Select", event.target.value);
    let name = event.target.name;
    // console.log(name);
    let value = event.target.value;
    // console.log("value", value)
    //this.setState({[name]:value});
    // if(name == "status")
    // {   
    //     if(value == "Exam")
    //     {
    //         this.setState({
    //             loadshow : ""
    //         })
    //     }else{
    //         this.setState({
    //             loadshow : "show-n"
    //         })
    //         $("#exam_date").val("");
    //     }
    // }
    // if(name == "demmurage_charges")
    // {
    //     if(value == "yes")
    //     {
    //         $("#rsndel").show();
    //     }
    //     else
    //     {
    //         $("#rsndel").hide();
    //         $("#reason_for_delay").val("");
    //     }
    // }
    // if(name == "lpc" || name == "interest")
    // {
    //     var lpc = $("#lpc").val();
    //     var interest = $("#interest").val();
    //     if(lpc > 0 || interest > 0)
    //     {
    //         $("#lpcdel").show();
    //     }
    //     else
    //     {
    //         $("#lpcdel").hide();
    //         $("#lpc_reason_for_delay").val("");
            
    //     }
    // }
    // if(name == "demmurage_charges")
    // {
    //     if(value == "no")
    //     {
    //         $("#demmurage_charges_amount").val(0);
    //     }
    // }
    // if(name == "demmurage_charges_amount")
    // {
    //     var demmurage_charges = $("#demmurage_charges").val();
    //     if(demmurage_charges == "no")
    //     {
    //         $("#demmurage_charges_amount").val(0);
    //     }
    // }
}

formHandler = (event) =>{
    event.preventDefault();
    var rtgp_dt = $("#rtgp_dt").val();
    if(rtgp_dt === "__-__-____" || rtgp_dt === undefined)
    {
        rtgp_dt="";
    }else{         
        rtgp_dt = rtgp_dt.split("-")[2]+"-"+rtgp_dt.split("-")[1]+"-"+rtgp_dt.split("-")[0];
    }
    var month_and_year_of_return = $("#month_and_year_of_return").val();
    if(month_and_year_of_return === "__-__-____" || month_and_year_of_return === undefined)
    {
        month_and_year_of_return="";
    }else{
        month_and_year_of_return = month_and_year_of_return.split("-")[2]+"-"+month_and_year_of_return.split("-")[1]+"-"+month_and_year_of_return.split("-")[0];
    }
    var invoice_date = $("#invoice_date").val();
    if(invoice_date === "__-__-____" || invoice_date === undefined)
    {
        invoice_date="";
    }else{
        invoice_date = invoice_date.split("-")[2]+"-"+invoice_date.split("-")[1]+"-"+invoice_date.split("-")[0];
    }
    var acc_date = $("#acc_date").val();
    if(acc_date === "__-__-____" || acc_date === undefined)
    {
        acc_date="";
    }else{
        acc_date = acc_date.split("-")[2]+"-"+acc_date.split("-")[1]+"-"+acc_date.split("-")[0];
    }
    
    var rtgp_no = $("#rtgp_no").val();
    var stkyrd = $("#stkyrd").val();
    var financial_year_of_return = $("#financial_year_of_return").val();
    var bveh = $("#bveh").val();
    var model = $("#model").val();
    var prefix = $("#prefix").val();
    var chass_no = $("#chass_no").val();
    var vin = $("#vin").val();
    var cate = $("#cate").val();
    var source = $("#source").val();
    var invc_amt = $("#invc_amt").val();
    var dept = $("#dept").val();
    var trtr = $("#trtr").val();
    var trtr_name = $("#trtr_name").val();
    var trtp = $("#trtp").val();
    var regn = $("#regn").val();
    var acc_time = $("#acc_time").val();
    var acc_place = $("#acc_place").val();
    var acc_state = $("#acc_state").val();
    var region = $("#region").val();
    var reason = $("#reason").val();
    var acdt_detail = $('#acdt_detail').val();
    var resp_tpt_code = $('#resp_tpt_code').val();
    var responsible_transporter = $('#responsible_transporter').val();
    var for_code = $("#for_code").val()
    var for_desc = $("#for_desc").val()
    let params = [
        {rowId: this.state.rowId},
        {
        "rtgp_no" : rtgp_no,
        "stkyrd" : stkyrd,
        "rtgp_dt" : rtgp_dt,
        "month_and_year_of_return" : month_and_year_of_return,
        "financial_year_of_return" : financial_year_of_return, 
        "bveh" : bveh,
        "model" : model,
        "prefix" : prefix,
        "chass_no" : chass_no,
        "vin" : vin,
        "cate" : cate,
        "source" : source,
        "invc_amt" : invc_amt,
        "dept" : dept,
        "trtr" : trtr,
        "trtr_name" : trtr_name,
        "trtp" : trtp,
        "regn" : regn,
        "acc_date" : acc_date,
        "acc_time" : acc_time,
        "acc_place" : acc_place,
        "acc_state" : acc_state,
        "region" : region,
        "reason" : reason,
        "acdt_detail" : acdt_detail,
        "resp_tpt_code" : resp_tpt_code,
        "responsible_transporter" : responsible_transporter,
        "for_code" : for_code,
        "for_desc" : for_desc,
        "invoice_date " : invoice_date 
        }
    ]
    // let saveData = new FormData();  
    // saveData.append("rtgp_no", rtgp_no);
    // saveData.append("stkyrd", stkyrd);
    // saveData.append("rtgp_dt", rtgp_dt);
    // saveData.append("month_and_year_of_return", month_and_year_of_return);
    // saveData.append("financial_year_of_return", financial_year_of_return);
    // saveData.append("bveh", bveh);
    // saveData.append("model", model);
    // saveData.append("prefix", prefix);
    // saveData.append("chass_no", chass_no);
    // saveData.append("vin", vin);
    // saveData.append("cate", cate);
    // saveData.append("source", source);
    // saveData.append("invc_amt", invc_amt);
    // saveData.append("dept", dept);
    // saveData.append("trtr", trtr);
    // saveData.append("trtr_name", trtr_name);
    // saveData.append("trtp", trtp);
    // saveData.append("regn", regn);
    // saveData.append("acc_date", acc_date);
    // saveData.append("acc_time", acc_time);
    // saveData.append("acc_place", acc_place);
    // saveData.append("acc_state", acc_state);
    // saveData.append("region", region);
    // saveData.append("reason", reason);
    // saveData.append("acdt_detail", acdt_detail);
    // saveData.append("resp_tpt_code", resp_tpt_code);
    // saveData.append("responsible_transporter", responsible_transporter);

    // console.log(saveData,"saveData");
    console.log("params" , params)
    redirectURL.post("/consignments/updatetransitreturns",params).then((response)=>{  
        console.log(response)
        if(response.data.status === "success"){
            $("input[type=text], textarea").val("");
        }
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}


render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            {/* <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Add New Transit Return</span>
								</h5>
                            <a href="/transitreturnsbidashboard">
                             <button className="btn btn-success pull-right cs-btn" id="addUserItem" onClick={this.changeScreen} style={{color:"white"}}> <i className="icon-plus"></i> Back</button>
                            </a>
				   			</div> */}
                               <div className="card-header space-between">
                                <h5 className="float-left">
                                    <i className="icon-map cus-i"></i><span>Edit Transit Return</span>
                                </h5>
                                <div>
                                <a href="/transitreturnsbidashboard">
                                <button className="btn btn-warning pull-right cs-btn" id="addUserItem" onClick={this.changeScreen} style={{color:"white"}}> <i className="icon-fa fa-arrow-left"></i> Back</button>
                                </a>
                                   
                                </div>                        
                            </div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler} encType="multipart/form-data">
                                    <div className="row col-xl-12">                                        
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Rtgp No </label>
                                            <input type="text" name="rtgp_no" id="rtgp_no" className="form-control"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">stkyrd </label>
                                            <input type="text" name="stkyrd" id="stkyrd" className="form-control"  onChange={this.changeHandler} required />
                                        </div>    
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Rtgp Date</label>
                                            <input type="text" name="rtgp_dt" id="rtgp_dt" className="datetimepicker_date form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Month And Year Of Return</label>
                                            <input type="text" name="month_and_year_of_return" id="month_and_year_of_return" className="datetimepicker_date form-control"  onChange={this.changeHandler} required />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Financial Year Of Return</label>
                                            <input type="text" name="financial_year_of_return" id="financial_year_of_return" className="form-control"  onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">bveh </label>
                                            <input type="text" name="bveh" id="bveh" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">model</label>
                                            <input type="text" class="form-control" name="model" id="model"  onChange={this.changeHandler}
                                            />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">prefix</label>
                                            <input type="text" name="prefix" id="prefix" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Chass No</label>
                                            <input type="text" name="chass_no" id="chass_no" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">vin</label>
                                            <input type="text" name="vin" id="vin" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">cate</label>
                                            <input type="text" name="cate" id="cate" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Source</label>
                                            <input type="text" name="source" id="source" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Invoice Amount</label>
                                            <input type="text" name="invc_amt" id="invc_amt" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Department</label>
                                            <input name="dept" id="dept" className="form-control"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Trtr</label>
                                            <input name="trtr" id="trtr" className="form-control" onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Trtr Name</label>
                                            <input type="text" name="trtr_name" id="trtr_name" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Trtp</label>
                                            <input type="text" name="trtp" id="trtp" className="form-control"  onChange={this.changeHandler} /> 
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Regn</label>
                                            <input type="text" name="regn" id="regn" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Acc Date</label>
                                            <input type="text" name="acc_date" id="acc_date" className="datetimepicker_date form-control" placeholder="" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Acc Time</label>
                                            <input type="text" name="acc_time" id="acc_time" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3" id="lpcdel">
                                            <label className="col-xl-12 col-lg-12">Acc Place</label>
                                            <input type="text" name="acc_place" id="acc_place" className="form-control" onChange={this.changeHandler}></input>
                                        </div>
                                        <div className="col-xl-3" id="lpcdel">
                                            <label className="col-xl-12 col-lg-12">For Code</label>
                                            <input type="text" name="for_code" id="for_code" className="form-control" onChange={this.changeHandler}></input>
                                        </div>
                                        <div className="col-xl-3" id="lpcdel">
                                            <label className="col-xl-12 col-lg-12">For Desc</label>
                                            <input type="text" name="for_desc" id="for_desc" className="form-control" onChange={this.changeHandler}></input>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Acc State</label>
                                            <input type="text" className="form-control" name="acc_state" id="acc_state"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">region</label>
                                            <input type="text" name="region" id="region" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">reason</label>
                                            <input type="text" name="reason" id="reason" className="form-control" onChange={this.changeHandler} />
                                        </div>                                       
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Acdt Detail</label>
                                            <input type="text" name="acdt_detail" id="acdt_detail" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Resp TptCode</label>
                                            <input type="text" name="resp_tpt_code" id="resp_tpt_code" className="form-control"  onChange={this.changeHandler} />
                                        </div>                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Responsible Transporter</label>
                                            <input type="text" name="responsible_transporter" id="responsible_transporter" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice Date</label>
                                            <input type="text" name="invoice_date" id="invoice_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>                                    
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

// function cleanTruckNo(value){
//     var result =  /\s/g.test(value);
//     var cleanedValue="";
//     try{
//         if(result == true){
//             cleanedValue = value.replace(/[^\w\s]/gi, '');
//             cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
//         }
//         else{
//             cleanedValue = value.toUpperCase();
//         }
//     }
//     catch(e){  }
//     return cleanedValue;
// }


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

