import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import $ from 'jquery';

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class DrawMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
		  returnTripsDashboardRowData: []
		};
	}
	componentDidMount(){
		
		this.renderMap()
	}
	componentWillReceiveProps(newprops){
		if(newprops.truckno != this.state.proptruckno)
		{
			this.renderMap()
			this.setState({
				proptruckno:newprops.truckno
			})
		}
		if(newprops.selectedorigin != this.state.selectedorigin && newprops.selecteddestination != this.state.selecteddestination)
		{
			this.renderMap()
		}
		
	}
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
}


	initMap = () => 
	{
		var currentwindow;
		var dealer='';
		var tolls = this.props.tolls;
		var chokepoints = this.props.chokepoints;
		var arr=[];
		var locInfo = this.props.locInfo;
		//console.log(locInfo,"indrawmap")
		if(this.props.mapFor == "load_management")
		{
			//console.log(this.props.coords,"coords1")
			var arr = this.props.coords;
			// if(arr.length > 0)
			// {
			// 	arr.map(function(a,index){
			// 		var indo = (index+4)/(1000);
			// 		console.log(indo,"indo")
			// 		a.lat = parseFloat(a.lat)+parseFloat(indo);
			// 		a.lng = parseFloat(a.lng)+parseFloat(indo);
			// 	})
			// }
			console.log("coords",arr)
		}

		//console.log("mapFor ",this.props.mapFor)
		
		if( arr == undefined)
		{
			var lt=28.4519751;
			var ln=77.0310713;
		}
		

		var routeinfo ;
		
		try{
			var mapOptions = {
				zoom: 12,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
		catch(e){
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels:true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751,77.0310713),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};
					
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		}catch(e){}
		
		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
			// Create our info window content
		var currentinfowindow = null;
		var line=new window.google.maps.Polyline(
		{
			map:map,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: [{
					icon: {
							path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							strokeColor:'#ff8c52',
							fillColor:'#ff8c52',
							fillOpacity:1,
							strokeWeight: 2
					},
					repeat:'100px',
					path:[]
				}]
		});
		if(arr == undefined){

		}
		else{
			try{
				if(arr.length > 0)
				{

					// LOAD MANAGEMENT
					if(this.props.mapFor == "load_management")
					{
						console.log(arr,"arr")
						var bounds = new window.google.maps.LatLngBounds();
						var markers = [];
						if(this.props.mapType != 3)
						{
							if(this.props.mapType == 1)
							{						
								//this.plotMultiColorPolyline(arr,map,bounds);
								var directionsService = new window.google.maps.DirectionsService;
								var directionsDisplay = new window.google.maps.DirectionsRenderer();
								directionsDisplay.setMap(map);
								var loadingLocation = this.props.loadingLocation;
								console.log(loadingLocation,"loadingLocation")
								if(loadingLocation.length > 0)
								{
									var startlng= new window.google.maps.LatLng( parseFloat(loadingLocation[0].lat),parseFloat(loadingLocation[0].lng));
								}
								else
								{
									var startlng= new window.google.maps.LatLng( parseFloat("28.495907"),parseFloat("77.06939799999998"));
								}	
								console.log(loadingLocation.length,"startlng")							
								var endlng= "";
								var waypts = [];
								arr.map(function(e,index){
									var latLng = new window.google.maps.LatLng( parseFloat(e.lat),parseFloat(e.lng));
									waypts.push({
										location: latLng,
										//stopover: true
									});
									if(index == (arr.length - 1))
									{
										endlng = new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
									}

									bounds.extend(latLng);
									addMarker(latLng,index+1,e);
								})
								if (waypts.length > 0)
								{
									waypts.pop();
								}
								directionsService.route({
									origin: startlng,
									destination: endlng,
									waypoints: waypts,
									travelMode: 'DRIVING'
								}, function(response, status) {
									if (status === 'OK') {
									directionsDisplay.setDirections(response);
									var totalDistance = 0;
									var totalDuration = 0;
									var legs = response.routes[0].legs;

										for(var i=0; i<legs.length; ++i) {
											totalDistance += legs[i].distance.value;
											totalDuration += legs[i].duration.value;
										}
									
														
									$('.ttlDuration').html("  Total Duration : " + Math.round(totalDuration/60/60,0) + " Hrs");
						
									for (var x=0;x < markers.length;x++)
									{
										markers[x].set('labelContent', x+1);
									}
									
									// var route = response.routes[0];
									} else {
										window.alert('Directions request failed due to ' + status);
									}
								});
							}
							else
							{
								//console.log(arr,"map marker")
								arr.map(function(e,index){
									//console.log(e.lat,e.lng)
									// var oemMarker = new window.google.maps.Marker({
									// 	position: new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng)),
									// 	icon: require('../../assets/icons/business.png'),
									// 	map: map,
									// });
									var latLng=new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
									bounds.extend(latLng);
									addMarker(latLng,index+1,e);
								})	
								map.setZoom(8);	
							}
							map.fitBounds(bounds);
							openAllClusters();

							function openAllClusters() {
								var markers =  []; //oms.markersNearAnyOtherMarker();
					
								$.each(markers, function (i, marker) {
									window.google.maps.event.trigger(markers[i], 'click');
								});
							}

							function addMarker(location,lbl,item) {
								var marker = new window.google.maps.Marker({
									position: location,
									label:lbl,
									icon: require('../../assets/icons/business.png'),
									map: map
								});
								
								markers.push(marker);
						
								var infowindow = new window.google.maps.InfoWindow({
										content: "<table><tr><th>Consignee</th><th>Transport Mode</th><th>CMT</th></tr><tr><td>" + item['dealer_code'] + "</td><td>" + item['truck_capacity'] + "</td><td>" + item['load_cmt'] + "</td></tr></table>",
										maxWidth: 350
									});
						
								marker.addListener('click', function() {
									infowindow.open(map, marker);
								});
								//oms.addMarker(marker);
							}						
						}	
						else
						{
							arr.map(function(e,index){
								var latLng=new window.google.maps.LatLng(parseFloat(e.lat),parseFloat(e.lng));
								bounds.extend(latLng);
								addMarker(latLng,index+1,e);
							})	
							map.setZoom(8);
							map.fitBounds(bounds);
							openAllClusters();

							function openAllClusters() {
								var markers =  []; //oms.markersNearAnyOtherMarker();
					
								$.each(markers, function (i, marker) {
									window.google.maps.event.trigger(markers[i], 'click');
								});
							}
							function addMarker(location,lbl,item) {
								var marker = new window.google.maps.Marker({
									position: location,
									label:lbl,
									icon: require('../../assets/icons/business.png'),
									map: map
								});
								
								markers.push(marker);
								var infowindow = new window.google.maps.InfoWindow({
									//content: this.props.locInfo,
									content: locInfo,
									maxWidth: 350
								});
					
								marker.addListener('click', function() {
									infowindow.open(map, marker);
								});
								//oms.addMarker(marker);
							}	
						}	
					}
				}
			}
			catch(e){

			}
		}
	}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;

	
    return (
           
			<div className="col-xl-12 col-md-12">
				

				{/* map div  */}
				
				<div id="map" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div>

				

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-8 col-lg-8 col-md-8"} style={{padding:'20px', background:'#fff'}}>
	                		
	                	</div>
	                </div>
	           </Modal>			
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}

export default DrawMap;