import React from 'react';

 
const SecondMapButton = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.formHandlerSecond(props.data);
    };

    return (
        <div className="map-icon-component">	
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="Recent Five Locations"><i className="icofont icofont-map-pins f25" style={{color: 'red'}}></i> </button>
        </div>
    );
};

export default SecondMapButton;
