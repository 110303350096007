import React,{Component} from'react'

export default class CustomTooltip extends Component {
    render() {
        const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
        console.log(data.carrier_rejection_reason,'carrier_rejection_reason')
        return (
            <div className="custom-tooltip" style={{backgroundColor: this.props.color || 'white'}}>
                <p><span>{data.carrier_rejection_reason}</span></p>
            </div>
        );
    }
 }