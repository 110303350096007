/* eslint-disable no-unreachable */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from "jquery";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import DateFormater from './dateComponent';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    catch (e) { }

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


export default class PlantTranshipmentMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            eventAction: null,
            allRowData: [],
            sliderTranslate: '',
            loadshow: 'show-n',
            showDiv: 'show-n',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            overly: 'show-n',
            alerterrshow: 'fade',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            modules: AllModules,
            filterstatess: {},
            columnsdatas: {},
            rowgroupdcols: [],
            pivotmodeis: false,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: true
            },
            rownode: [],
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            selectedRows: ''

        }
    }

    componentDidMount() {
        let itemM = localStorage.getItem("m")
        if(itemM !== ""  &&  itemM !== undefined) var loguser = decode(itemM);
        var loginplant = "";
        try {
            var luser = JSON.parse(loguser);
            loginplant = luser.is_plant_code;
            // console.log("logplant", loginplant)
            this.setState({
                loginplant: loginplant,
                // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

            })
        }
        catch (e) {

        }
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        let pagetitle = 'Plant Transshipment Mapping';
        if (this.props.match.path == "/planttransshipmentMapping") {
            var dept_code = "SNDG"
        }
        this.setState({
            loadshow: 'show-m',
            // eventAction:eventAction,
            dept_code: dept_code,
            pagetitle: pagetitle
        });

        var params = {
            // dept_code: encode(dept_code),
        }

        params.transporter_code = localStorage.getItem("transportercode")
        let urlPath = "/consignments/getDataForPlantTransMapping"
        this.loadPageData(urlPath, params)
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    loadPageData = (urlPath, params) => {
        redirectURL.post(urlPath, params)
            .then(response => {
                // console.log("response", response.data)
                let rowData = response.data
                this.setState({
                    rowData,
                    loadshow: 'show-n'
                })

            })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onRowSelection(event) {
        // var containerslist=[];
        // //console.log("rowselect", event)
        // var rwCount = event.api.getSelectedNodes();
        // console.log("rwCount ", rwCount)
        // 	//var containerlist=[];
        // 	console.log("rowselect", event.data)
        // 	var rowCount =  event.data
        // 	//console.log("rowCount ", rowCount)

        // 	rwCount.map((item) => {
        // 		containerslist.push(item.data.truck_no)
        // 	});

        // 	this.setState({
        // 		containerslist:containerslist
        // 	})
        // 	 console.log("checkedlist ", containerslist)

    }

    onCellRowClicked = e => {
        if (e.colDef.field == "update") {
            // console.log("e", e.data)
            let params = e.data
            if (params.plant_code !== "" && params.warehouse_transshipment !== "") {
                redirectURL.post("/consignments/updatePlantTranshipmentMapping", params)
                    .then((response) => {
                        if (response.data.message == "Successfully Updated") {
                            this.setState({
                                basicTitle: "Successfully Updated",
                                basicType: "success",
                                show: true,
                                overly: "show-n",
                                loadshow: "show-n",
                            })
                            //window.location.reload();
                            // this.onLoadDashboardData();
                        }                      
                        else {
                            this.setState({
                                basicTitle: "Failed to update.",
                                basicType: "warning",
                                show: true
                            })
                        }
                    })
            }
            else {
                this.setState({
                    basicTitle: "Please Enter Values to update.",
                    basicType: "warning",
                    show: true
                })
            }
        }
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderCommentTranslate: "",
            slideBar: ''
        });

    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    addNewRow = () => {
        // console.log(this.gridApi);
        this.gridApi.insertItemsAtIndex(0, [{ dept_code: "", plant_code: "", plant_name: "", tvp_transshipment: "", warehouse_transshipment: "" }])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }

    render() {
        const columnwithDefs = [
            {
                headerName: "Plant Code",
                field: "plant_code",
                // pinned: 'left',
                width: 140,
                editable: false,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant Transshipment Mapping",
                field: "plant_name",
                width: 160,
                // pinned: 'left',
                editable: false,
                filter: true,
                resizable: true
            },
            {
                headerName: "Warehouse Transshipment",
                width: 140,
                field: "warehouse_transshipment",
                // pinned: 'left',
                filter: true,
                resizable: true,
                // headerClass:"cellstylegridY",
                // cellClass:'cellstylegridY',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes", "No"],
                },
            },
            {
                headerName: "Tvp Transshipment",
                field: "tvp_transshipment",
                width: 140,
                // pinned: 'left',
                filter: true,
                resizable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Yes", "No"],
                },
            },
            {
                headerName: "Update",
                field: "update",
                width: 120,
                filter: false,
                resizable: true,
                cellRenderer: function (params) {
                    var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Update</button>';
                    return htmloption;
                },


            },
        ]
        const { defaultColDef, modules, statusBar, sideBar, paginationPageSize, rowSelection, rowData, pagetitle, show, basicType, basicTitle, overly, loadshow } = this.state
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className='col-xl-12 col-lg-12'>
                        <div className='card'>
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{pagetitle} </span>                                 
                                </h5>
                            </div>
                            <div className="card-body pt-10px" >
                                <div className="row">
                                    <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={defaultColDef}
                                            rowData={rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={statusBar}
                                            sideBar={sideBar}
                                            paginationPageSize={paginationPageSize}
                                            pagination={true}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            suppressRowClickSelection={true}
                                            onCellClicked={this.onCellRowClicked}
                                        // onRowSelected={this.onRowSelection}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        //floatingFilter={true}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>

            </div>

        )
    }



}