import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY, getHyphenDDMMMYYYY } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import EditButton from "./editbutton";
import redirectURL from '../redirectURL';
import ArrivalMap from "./arrivalMap";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import DrawMap from "../common/drawmap";


var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class ReturnPallet extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            vehicleList:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            gr_lr_no : "",
            consignee : "",
            truck_no : "",
            pallets : "",
            cover : "",
            anyother : "",
            remarks : "",
            dispatch_date:"",
            filteredData : [],
            showTrucksList:"show-n",
            plantsList : [],
            //components : {datepicker:getDatePicker()}
            frameworkcomponents : {
                EditButton : EditButton,
                ArrivalMap : ArrivalMap,
            },
            sliderRouteTranslate:"",
            showDiv:'show-n',
            mapinfo:"",
            dealer:"",
            consignment_code:"",
            sliderTruckNo : "",
            maptruckno:"",
            routeTruck:	"",
            rownode:"",
            leg_no:0,
            rowId:"",
            csvData:[],
            palletData:[],
            vListData:[]
        }
        this.editForm = this.editForm.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle='Return Pallets';
        var transporter_code = "";
        var transportercode = localStorage.getItem('transportercode');
        if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
        {
            var tptCode = JSON.parse(transportercode);
            var transporter_code = tptCode[0];
        }
        var params = {
            transporter_code : transporter_code
        }
        redirectURL.post("/importscoils/getVehicleNo",params).then((response)=>{
            var plantsData = response.data.consignees;
            var plants = [];
            plantsData.map(function(params){
                plants.push(params.plant_code);
            });
            var geofencelist = response.data.vehicles;
            var geoFenceArray = [];
            geofencelist.map(function(params){
                geoFenceArray.push(params.truck_no);
            });
            this.setState({
                pagetitle:pagetitle,
                vehicleList:geoFenceArray,
                rowData : response.data.pallets,
                plantsList : plants,
                vListData : geofencelist
            });
        }).catch(function(error){
            console.log("error");
        });
        

    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
    vehicleOptions(){
        let arr=[];
      
            this.state.vehicleList.map((item,index) =>{
            arr.push({value:item,label:item});
        });
        
        return arr;
       
    }
    consigneeOptions(){
        let arr=[];
        var plants = this.state.plantsList;
        plants.map(function(e){
            arr.push({label:e,value:e})
        })
        return arr;
    }
    changeHandler = (event) => {
    	console.log("Select", event);
    	let name = event.target.name;
        let value = event.target.value;
        console.log(name,value)
        if(name !='dispatch_date')
        {
            this.setState({[name]:value});
        }
    }
    
    changeSelectedConsignee(e){
        this.setState({
            consignee : {"value":e.value,"label":e.value},
        })
    }
    changeSelectedTruck(e){
        this.setState({
            truck_no : e.value,
        })
    }
   

insertReturnPallet(event){
    event.preventDefault();
    let eventLabel = googleAnalytics.page.action.insertReturnPallet;
    let eventOptions = {
        "category": this.state.pagetitle,
        "action": this.state.eventAction,
        "label": eventLabel,
    }
    googleAnalytics.logEvent(eventOptions);
    var dispatch_date_time = $("#dispatch_date").val();
    console.log(dispatch_date_time);
    var dispatch_date = dispatch_date_time.split(" ")[0].split("-");
    var dispatch_time = dispatch_date_time.split(" ")[1]
    dispatch_date = dispatch_date[2]+"-"+dispatch_date[1]+"-"+dispatch_date[0]+" "+dispatch_time+":00";
    
    var reqparams = 
    {
        truck_no : $("#inputTruck").val(),
        dispatch_date : dispatch_date,
        no_of_pallets : this.state.pallets,
        no_of_covers : this.state.cover,
        gr_lr_no : this.state.gr_lr_no,
        remarks : this.state.remarks,
        anyother_part : this.state.anyother,
        consignee_code : this.state.consignee.value,
        status : 2,
    } ;
    var transportercode = localStorage.getItem('transportercode');
    if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
    {
        var tptCode = JSON.parse(transportercode);
        reqparams.transporter_code = tptCode[0];
    }
    reqparams.created_by = localStorage.getItem('username');
    reqparams.created_on = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    console.log(reqparams);
    if(this.state.consignee.value != "" && this.state.consignee != null && this.state.consignee.value != undefined)
    {
        if($("#dispatch_date").val() != "__-__-____" && $("#inputTruck").val() != "" && reqparams.consignee != "")
        {
            this.setState({
                loadshow:'show-m',
                overly : "show-m"
            });
            redirectURL.post("/importscoils/insertReturnPallet",{"reqparams":reqparams,"is_edit":$("#editinputform").val(),"id":this.state.rowId}).then((response)=>{
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle : "Consignment Created Successfully..!!!",
                        basicType : "success",
                        show : true,
                        rowId : "",
                        loadshow:'show-n',
                        overly : "show-n"
                    })
                }
                else{
                    this.setState({
                        basicTitle : "Consignment Already Exists.. !!!",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly : "show-n"
                    })
                }
                console.log(response.data);

            }).catch((e)=>{
                console.log(e);
            })
        }
        else{
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true
            })
        }
    }
    else{
        this.setState({
            basicTitle : "Please Select Consignee",
            basicType : "warning",
            show : true
        })
    }
    
}
    

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
exportTruckList = () => {
    var params = {
        columnKeys: ['truck_no']
    };
    this.gridApi.exportDataAsCsv(params);
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Vehicle No',
                inputName: 'truck_no',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Consignee',
                inputName: 'consignee_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'GR / LR No',
                inputName: 'gr_lr_no',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Dispatch Date',
                inputName: 'dispatch_date',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'No. of Pallets',
                inputName: 'no_of_pallets',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'No. of Covers',
                inputName: 'no_of_covers',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Any Other Part',
                inputName: 'anyother_part',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Remarks',
                inputName: 'remarks',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },          
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        
        console.log(csvData.data,"validated");
        this.setState({
            palletData:csvData.data
        });
    })
    .catch(err => {
        console.log(err,"error")
    })
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

async uploadBulkFormHandler(e){
    e.preventDefault();
    var fileData = this.state.palletData;
    var flag=0;
    console.log(fileData,"fileData");
    
    if(flag == 0)
    {
        if(fileData.length > 0)
        {
            var vehicleList = this.state.vehicleList;
            var listflag = 0;
            var filevalid = fileData.map(async function(f){
                if(f.truck_no !=undefined && f.truck_no !='')
                {
                    if(vehicleList.includes(f.truck_no) == false)
                    {
                        listflag = 1;
                    }
                }
            })
            Promise.all([filevalid]).then(()=>{
                if(listflag == 0)
                {
                    var reqParams = {
                        csvData : fileData,
                    }
                    var transportercode = localStorage.getItem('transportercode');
                    if(transportercode !=undefined && transportercode !="undefined" && transportercode !="")
                    {
                        var tptCode = JSON.parse(transportercode);
                        reqParams.transporter_code = tptCode[0];
                    }
                    reqParams.created_by = localStorage.getItem('username');
                    reqParams.created_on = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
                    console.log("reqParams",reqParams)
                    this.setState({
                        loadshow:'show-m',
                        overly : "show-m"
                    });
                    redirectURL.post("/importscoils/insertBulkReturnPallet",reqParams).then((response)=>{
                        var resp=response.data.message;
                        console.log(resp)
                        this.setState({
                            basicTitle : resp,
                            basicType : "success",
                            show : true,
                            bulkslide:'',
                            overly:'show-n',
                            file : "",
                            loadshow:'show-n',
                            overly : "show-n"
                        })
                        //window.location.reload();
                    }).catch((e)=>{
                        console.log(e);
                    })
                }
                else
                {
                    this.setState({
                        basicTitle : "Invalid Truck No's",
                        basicType : "warning",
                        show : true,
                        file : "",
                    })
                    $('#upform')[0].reset();
                }            
            })
        }
        else
        {
            this.setState({
                basicTitle : "Invalid File",
                basicType : "warning",
                show : true,
                file : "",
            })
        }
        $('#upform')[0].reset();
    }
    else
    {
        $("#bulkUploadID").val();
        this.setState({
            basicTitle : "Fields Should not be Empty",
            basicType : "warning",
            show : true,
            file : "",
        })
        $('#upform')[0].reset();
    }
};
closeAlert = () => {
    this.setState({
        show: false
    });
    window.location.reload();
}
onShowRouteDiv = (e) => {
    console.log("E ",e)
    this.setState({
        overly : "show-m",
        loadshow : "show-m"
    })
    if (googleAnalytics.page.enableGA) {
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventGridAction,
            "label": googleAnalytics.page.action.viewRoute,
        }
        googleAnalytics.logEvent(eventOptions);
    }
    var sdate = moment.parseZone(new Date(e.dispatch_date)).format("YYYY-MM-DD HH:mm:ss");
    var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
    var parameters = {
        first_instance : sdate,
        recent_instance : edate,
        truck_no : e.vehicle_no,
        
    }
    console.log(parameters);
    if(e.vehicle_no != "" && e.vehicle_no != null)
    {
        redirectURL.post("/reports/nightdriveroutes",parameters)
        .then((response) => {
            console.log("Map Routes ", response.data)
            var records = response.data;
            this.setState({
                loadshow:'show-m'
            });
            if(records.coords.length == 0)
            {
                //console.log("records", records.coords);
                this.setState({
                    show: true,
                    basicTitle:'No Route data available',
                    basicType:"danger",
                    loadshow:'show-n',
                    overly : "show-n"
                });
            }
            else
            {
                if(records != '')
                {
                    
                    this.setState({
                        sliderRouteTranslate:"slider-translate-60p",
                        showDiv:'show-m',
                        mapinfo:records,
                        sliderTruckNo : e.vehicle_no,
                        maptruckno:e.vehicle_no,
                        routeTruck:	{"truck_no":e.vehicle_no,"startTime":sdate,"endTime": edate},
                        loadshow:'show-n',
                        overly:'show-n',
                        rownode:e,
                        leg_no:0,
                    });
                    // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                }
            }
           
        })
        .catch(function(e){
            console.log("Error ", e)
        })
    }
    else{
        this.setState({
            show:true,
            basicTitle:"No data available",
            basicType:"danger",
            overly:'show-n',
            loadshow:'show-n'
        });
    }
    
    
}
// AUTOMCOMPLETE DROPDOWN
initalList =(event)=>{
    $(".trucks-dropdown").removeClass("show-n");
    var dataset = this.state.vehicleList;
    dataset = dataset.slice(0,100);
    this.setState({
        filteredData : dataset,
        showTrucksList : "show-m",
    })
}

handlerForm = (event) => {
    //console.log("Select", event.target.value);
    // let name = event.target.name;
    // let value = event.target.value;
    // this.setState({[name]:value});
    try
    {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.vehicleList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        if(filteredResult.length > 0 )
        {
            this.setState({
                filteredData : filteredResult,
                showTrucksList : "show-m",
            })
        }
        else{
            this.setState({
                filteredData : filteredResult,
                showTrucksList : "show-n",
            })
        }
        
    }catch(e){}
    
}

onClickTruck(event)
{
     $("#inputTruck").val($(this).text());
     console.log($("#inputTruck").val());
}
hideList(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        showTrucksList : "show-n",
    });
}
onCloseRouteDiv(){
    this.setState({
        sliderRouteTranslate : "",
        loadshow : "show-n",
        overly : "show-n",
        bulkslide : ""
    })
}
editForm(params)
{
    console.log(params);
    if(dispatch_date != "")
    {
        var dispatch_date = moment.parseZone(new Date(params.dispatch_date)).format("DD-MM-YYYY HH:MM");
    }
    $("#inputTruck").val(params.truck_no)
    $("#editinputform").val("yes");
    this.setState({
        overly:'show-m',
        gr_lr_no : params.gr_lr_no,
        truck_no : params.truck_no,
        pallets : params.no_of_pallets,
        cover : params.no_of_covers,
        anyother : params.anyother_part,
        remarks : params.remarks,
        consignee : {"label":params.consignee,"value":params.consignee},
        dispatch_date : dispatch_date,
        bulkslide:"slider-translate-40p",
        rowId : params._id
    })
}
onClickHideAll(){
    console.log("cliekc");
    $("#editinputform").val("")
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:'',
        showTrucksList : "show-n",
        gr_lr_no : "",
        consignee : "",
        truck_no : "",
        pallets : "",
        cover : "",
        anyother : "",
        remarks : "",
        dispatch_date:"",
        rowId : "",
        sliderRouteTranslate : ""

    });
    
}
    render(){
        const columnwithDefs = [
            {
                headerName : "",
                field : "_id",
                cellRenderer : "ArrivalMap",
                width : 60
            },
            {
                headerName: "",
                field: "_id",
                filter: true,
                width : 80,
                resizable: true,
                cellRenderer : "EditButton"
            },
            {
                headerName: "Vehicle No",
                field: "truck_no",
                filter: true,
                width : 140,
                resizable: true,
            },
            {
                headerName: "Status",
                field: "status",
                filter: true,
                width : 140,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.status == 2)
                    {
                        return "In Transit"
                    }
                }
            },
            {
                headerName: "Transporter Code",
                width:140,
                field: "transporter_code",
                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Name",
                width:140,
                field: "transporter_name",
                filter: true,
                resizable: true,
            },
            {
                headerName: "Consignee",
                width:140,
                field: "consignee",
                filter: true,
                resizable: true,
            },
            {
                headerName: "GR/LR No",
                field: "gr_lr_no",
                filter: true,
                resizable: true,
                width : 140,
            },
            {
                headerName: "No. of Pallets",
                field: "no_of_pallets",
                width:140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "No. of Covers",
                field: "no_of_covers",
                width:140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Dispatch Date",
                field: "dispatch_date",
                width:140,
                filter: true,
                resizable: true,
                valueGetter : function(params){
                    console.log(params.data.dispatch_date)
                    if(params.data.dispatch_date != "")
                    {
                        
                        return getHyphenDDMMMYYYY(params.data.dispatch_date)
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Anyother Part",
                field: "anyother_part",
                width:140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width:140,
                filter: true,
                resizable: true,
            },
            
        ]
        const columnwithTruckDefs = [
            {
                headerName : "Truck No",
                field : "truck_no",
                width : 60
            },
        ]
        var listData = [] 
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    
                    <div className="form-theme col-xl-12 col-lg-12 row">
                        {/* <div className="form-group col-xl-10 col-lg-10 ">
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                        </h5>
                        </div> */}
                        {/* <div className="col-xl-2 col-lg-2" style={{paddingTop:"40px"}}>
                            <button className="float-right f15 custom-btn btn-primary" style={{color:"white",padding:"5px 20px"}} onClick={this.onClickShowBulkUpload.bind(this)}>
                                Add Return Pallet
                            </button>    
                        </div>                 */}
                    </div>
                </div>
                {/* <div id="myGrid" style={{width:"100%",height:"380px"}} className="ag-theme-balham">    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        enableRangeSelection={false}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        enableRangeSelection={true}
                        components={this.state.components}
                        frameworkComponents={this.state.frameworkcomponents}
                        required />

                </div> */}
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>  

                {/* <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"scroll"}}>
					<h3 className="subH">Add Return Pallet
                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                    </h3> */}
                    
					{/* <div className="slide-r-body" style={{position:"relative"}}> */}
						<div class="card row col-xl-12 col-lg-12">
                            <div className="card-body col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                        <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Bulk Upload</button>
                                    </h5>
                                </div>
                                <form method="POST" id="upform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.insertReturnPallet.bind(this)}>
                                    <div className="row">
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label >Vehicle No : </label>
                                            <div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"65px",width:"95%",left:"14px"}}>
                                                <ul>
                                                    {listData}
                                                </ul>
                                            </div>
                                            <input type="text" className="col-xl-12 col-lg-12 form-control col-xl-7" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} required />
                                                        
                                            
                                        </div>
                                        <div className="form-group col-xl-4 col-lg-4">
                                            <label >Select Consignee : </label>
                                            <Select 
                                            value={this.state.consignee}
                                            placeholder={"Select "}
                                            closeMenuOnSelect={true}
                                            onChange={this.changeSelectedConsignee.bind(this)}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.consigneeOptions()}
                                            id="consignee_id"
                                            required />            
                                        </div>
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >GR / LR No :</label>
                                            <input type="text" name="gr_lr_no" value={this.state.gr_lr_no} autoComplete="off" onChange={this.changeHandler.bind(this)} className="col-xl-12 col-lg-12 form-control col-xl-7" style={{height:"41px"}} required />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >Dispatch Date</label>
                                            <input type="text" name="dispatch_date" autoComplete="off" className="col-xl-12 col-lg-12 form-control datetimepicker_mask col-xl-7" id="dispatch_date" style={{height:"41px"}} required />
                                        </div>
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >No. of Pallets :</label>
                                            <input type="number" name="pallets" value={this.state.pallets} min="0" autoComplete="off" onChange={this.changeHandler.bind(this)} className="col-xl-12 col-lg-12 form-control col-xl-7" style={{height:"41px"}} required />
                                        </div>
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >No. of Covers :</label>
                                            <input type="number" name="cover" min="0" value={this.state.cover} autoComplete="off" onChange={this.changeHandler.bind(this)} className="col-xl-12 col-lg-12 form-control col-xl-7" style={{height:"41px"}} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >Any Other Part :</label>
                                            <input type="text" name="anyother" autoComplete="off" value={this.state.anyother} onChange={this.changeHandler.bind(this)} className="col-xl-12 col-lg-12 form-control col-xl-7" style={{height:"41px"}} />
                                        </div>
                                        <div className={"form-group col-xl-4 col-lg-4"}>
                                            <label >Remarks :</label>
                                            <textarea autoComplete="off" onChange={this.changeHandler.bind(this)} value={this.state.remarks} name="remarks" className="col-xl-12 col-lg-12 form-control col-xl-7" style={{height:"41px"}}  />
                                        </div>
                                        <input type="hidden" id="editinputform" />
                                    </div>
                                    <div class="form-group col-xl-12 col-lg-12" style={{textAlign:"center"}}>
                                        <button type="submit" class="btn btn-primary" style={{width:"14em"}}>
                                            Submit
                                        </button>
                                    </div>
                                </form>		
                            </div>
                        </div>
					{/* </div> */}
				{/* </div> */}

                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Truck Number : {(this.state.sliderTruckNo)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
                             {(this.state.mapinfo != "")?
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                
                                <DrawMap context={this} mapFor={"loadattach"}  truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} />
								
                               
								
                                <div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != "")?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											
										
										</div>
									</div>
								</div>

							</div> :""}
						</div>
						
					</div>
				:""	
				}

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Bulk Upload</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                            
                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                <p style={{color:"red"}}>Note: Format should be same as given in sample template***</p>
                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/sample_return_pallets.csv')} target="_blank">Sample Template</a>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary" onClick={this.exportTruckList.bind(this)}>Truck List</button>
                            </div>
                            <div id="myGrid" style={{width:"100%",height:"0"}} className="ag-theme-balham">    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithTruckDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.vListData}
                                    enableCharts={false}
                                    enableRangeSelection={false}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    enableRangeSelection={true}
                                    components={this.state.components}
                                    frameworkComponents={this.state.frameworkcomponents}
                                    required />

                            </div>		
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> 
                        
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})