import React, { Component } from 'react';
var redirectURL = require('../redirectURL');

 
const KpiDeviationMapActions = (props) => {
   
    const handleDeviateClick = (e) => {
        e.stopPropagation();
        props.agGridReact.props.gridOptions.context.componentParent.onClickShowDeviationMap(props.data);
    };

    return (
        <div>
			<button onClick={handleDeviateClick} className="custom-btn f22 label label-success" title="Deviation"><i className="icofont icofont-map-pins"></i>  &nbsp;</button>
       
        </div>
    );
};

export default KpiDeviationMapActions;