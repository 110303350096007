/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import CountUp from 'react-countup';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

var redirectURL = require('../redirectURL');
var moment = require('moment');

var sliderColumnsList = [
    {
        headerName: "Truck No",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "truck_no",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Transporter Name",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "transporter_name",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Transporter Code",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "transporter_code",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Remarks",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "Remarks",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
]

export default class GpsFleetSummaryStatusFromAPI extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        dept_code: "",
        totalTrucks: [],
        truckscnt: 0,
        uniqueActiveConsignments: [],
        totalActiveConsignmentsCount: 0,
        ldata: [],
        ondata: [],
        parkdata: [],
        rowDataOntrips: [],
        rowDataPark: [],
        rowDataOff: [],
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],

        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        intransit: [],
        inreturn: [],
        inplant: [],
        inparkinglist: [],
        devicenrd: [],
        atdealership: [],
        unloaded: [],
        total: [],
        usergridstate: [],
        allData: [],
        offTrip: [],
        apiData: [],
        sliderCriticalTranslate: '',
        sliderTitle: '',
        apiFleetSummary: [],
        dealer_df: [],
        empty_return_to_plant_df: [],
        intransit_df: [],
        non_reporting_30_45days_df: [],
        non_reporting_45_90days_df: [],
        non_reporting_90days_df: [],
        nrd_trucks_df: [],
        parking_df: [],
        return_in_movement_df: [],
        oem_10km_df: [],
        gpsFleetStatus: [],
        rowCriticalData : [],
        total_df : [],
        sliderColumns1 : [],
        oem_unloading_df : []
    };



    componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly: 'show-m'
        });

        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                //console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
        this.loadData();
    }

    loadData = () => {
        var deptcode = "";
        if (this.props.match.path == "/sndconsolidate") {
            deptcode = "SNDG";
        }
        this.setState({
            dept_code: deptcode
        })

        redirectURL.post("/dashboard/getGpsfleetStatusSummary")
            .then((response) => {
                // let result = JSON.parse(JSON.stringify(response)).data;
                // let result = response.data.fleetSummary
                let apiData = (response.data)
                console.log('apiData',apiData)

                let fleetStatus = JSON.parse(apiData.fleet_status)
                // console.log("fleetStatusapi ", fleetStatus)
                let fleetSummary = JSON.parse(apiData.fleet_summary)
                // console.log("fleetSummaryapi ", fleetSummary)
                var dealer_df = JSON.parse(apiData.dealer_df)
                // console.log('dealer_df',dealer_df)

                var empty_return_to_plant_df = JSON.parse(apiData.empty_return_to_plant_df)
                // console.log('empty_return_to_plant_df',empty_return_to_plant_df)

                var intransit_df = JSON.parse(apiData.intransit_df)
                // console.log('intransit_df',intransit_df)

                var non_reporting_30_45days_df = JSON.parse(apiData.non_reporting_30_45days_df)
                // console.log('non_reporting_30_45days_df',non_reporting_30_45days_df)

                var non_reporting_45_90days_df = JSON.parse(apiData.non_reporting_45_90days_df)
                // console.log('non_reporting_45_90days_df',non_reporting_45_90days_df)

                var non_reporting_90days_df = JSON.parse(apiData.non_reporting_90days_df)
                // console.log('non_reporting_90days_df',non_reporting_90days_df)

                var nrd_trucks_df = JSON.parse(apiData.nrd_trucks_df)
                // console.log('nrd_trucks_df',nrd_trucks_df)

                var parking_df = JSON.parse(apiData.parking_df)
                // console.log('parking_df',parking_df)

                var return_in_movement_df = JSON.parse(apiData.return_in_movement_df)
                // console.log('return_in_movement_df',return_in_movement_df)

                let oem_10km_df = JSON.parse(apiData.oem_10km_df)
                // console.log('oem_10km_df',oem_10km_df)

                let total_df = JSON.parse(apiData.total_data)
                console.log('total_df',total_df)

                let oem_unloading_df  = JSON.parse(apiData.oem_unloading_df )
                // console.log('oem_unloading_df',oem_unloading_df)


                let gpsFleetStatus = [
                    {
                        remarks: 'Not Reported 30-45 Days',
                        count: non_reporting_30_45days_df.length
                    },
                    {
                        remarks: 'Not Reported 46-90 Days',
                        count: non_reporting_45_90days_df.length
                    },
                    {
                        remarks: 'Not Reported >90 Days',
                        count: non_reporting_90days_df.length
                    },
                    {
                        remarks: 'In Transit',
                        count: intransit_df.length
                    },
                    {
                        remarks: 'At Dealership',
                        count: dealer_df.length
                    },
                    {
                        remarks: 'Reported in Parking',
                        count: parking_df.length
                    },
                    {
                        remarks: 'In Return to Plant',
                        count: empty_return_to_plant_df.length
                    },
                    {
                        remarks: 'Device NRD',
                        count: nrd_trucks_df.length
                    },
                    {
                        remarks: 'Return In Movement',
                        count : return_in_movement_df.length
                    },
                    {
                        remarks: 'Oem Unloading',
                        count : oem_unloading_df.length
                    },
                    {
                        remarks: 'Oem (0-10)Km',
                        count : oem_10km_df.length
                    },
                    {
                        remarks: 'Total',
                        count : total_df.length
                    },
                ]

                this.setState({
                    apiData: fleetStatus,
                    apiFleetSummary: fleetSummary,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    dealer_df,
                    empty_return_to_plant_df,
                    intransit_df,
                    non_reporting_30_45days_df,
                    non_reporting_45_90days_df,
                    non_reporting_90days_df,
                    nrd_trucks_df,
                    parking_df,
                    return_in_movement_df,
                    oem_10km_df,
                    gpsFleetStatus,
                    oem_unloading_df,
                    total_df
                });
            })
    }

    restoreGridStates() {
        if (this.state.usergridstate.length > 0) {
            var windowstates = this.state.usergridstate;
            this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
            this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
            this.gridApi.setSortModel(windowstates[0].gridcolsort);
            this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        }
    }

    onClickSaveGridState() {
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();
        let screenpage = '';
        if (this.props.match.path == "/gpsfleetstatus") {
            screenpage = 'gps fleet summary';
        }
        // else if (this.props.match.path == "/prtforceclosure") {
        //     screenpage = 'prt force closure';
        // }
        // else if (this.props.match.path == "/tnpforceclosure") {
        //     screenpage = 'tnp force closure';
        // }


        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        //console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    onClickHideAll() {
        this.setState({
            sliderZoneTranslate: "",
            sliderCriticalTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    }

    resetState = () => {
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })
    }

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    }

    onGridReady1 = params => {
        this.gridApi1 = params.api;
        //console.log("Grid is: ", params);
        this.gridApi1.sizeColumnsToFit();
        this.gridColumnApi1 = params.columnApi;
    }

    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState1 = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState1= this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns1 = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState1 = this.gridApi.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

    setStateForSlider = (rowData, sliderTitle,sliderColumns1) =>{
        this.setState({
            sliderZoneTranslate: "slider-translate-60p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: rowData,
            sliderTitle: sliderTitle,
            'sliderColumns1':sliderColumns1
        })
    }
    showSliderRowData = (item) => {
        // console.log("item", item)
        const remarks = item.data.remarks
        if(remarks === 'In Transit'){
            var sliderColumns1 = [...sliderColumnsList,
                {
                    headerName: "Consignment Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignment_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Invoice No",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "invoice_no",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Invoice Time",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "invoice_time",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series",
                    valueGetter : params =>{
                        if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                        }
                        else {
                            return "NA";
                        }
                    }
                },
                {
                    headerName: "Consignee Area",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_area",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee City",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_city",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee Name",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_name",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                }, {
                    headerName: "Consigner Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consigner_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Zone",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "zone",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },]
            this.setStateForSlider(this.state.intransit_df,'In Transit Details',sliderColumns1 )
        }
        else if (remarks === 'Not Reported 30-45 Days'){
           sliderColumns1 = [...sliderColumnsList, {
                                 headerName : 'Last Invoice time',
                                field: 'invoice_time',
                                width : 100,
                                valueGetter : (param) =>{
                                    if(param.data.invoice_time !== undefined && param.data.invoice_time !== '') return getHyphenDDMMMYYYYHHMM(param.data.invoice_time)
                                    return ''
                                   }
                                },
                                {
                                        headerName : 'Current Location',
                                        field: 'last_known_address',
                                        width : 200,
                                }
                                ,{
                                    headerName : 'Last Packet time',
                                    field: 'timestamp',
                                    width : 100,
                                    valueGetter : (param) =>{
                                        return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                                    }
                        }]
            this.setStateForSlider(this.state.non_reporting_30_45days_df,'Not Reported 30-45 Days' ,sliderColumns1)

        }else if(remarks === 'Not Reported 46-90 Days'){
            sliderColumns1 = [...sliderColumnsList, {
                headerName : 'Last Invoice time',
               field: 'invoice_time',
               width : 140,
               valueGetter : (param) =>{
                if(param.data.invoice_time !== undefined && param.data.invoice_time !== '') return getHyphenDDMMMYYYYHHMM(param.data.invoice_time)
                return ''
               }
               },{
                       headerName : 'Current Locatoin',
                       field: 'last_known_address',
                       width : 200,
               }
               ,{
                   headerName : 'Last Packet time',
                   field: 'timestamp',
                   width : 140,
                   valueGetter : (param) =>{
                    if(param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                    return ''
                   }
             }]
            this.setStateForSlider(this.state.non_reporting_45_90days_df,'Not Reported 46-90 Days',sliderColumns1 )
        }
        else if(remarks === 'Not Reported >90 Days'){
            sliderColumns1 = [...sliderColumnsList,{
                headerName : 'Last Invoice time',
               field: 'invoice_time',
               width : 100,
               valueGetter : (param) =>{
                if(param.data.invoice_time !== undefined && param.data.invoice_time !== '') return getHyphenDDMMMYYYYHHMM(param.data.invoice_time)
                    return ''
               }
               },{
                       headerName : 'Current Location',
                       field: 'last_known_address',
                       width : 200,
               }
               ,{
                   headerName : 'Last Packet time',
                   field: 'timestamp',
                   width : 140,
                   valueGetter : (param) =>{
                    if(param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                    return ''
                   }
       }]
            this.setStateForSlider(this.state.non_reporting_90days_df,'Not Reported >90 Days',sliderColumns1 )            
        }
        else if(remarks === 'At Dealership'){
            sliderColumns1 = [...sliderColumnsList,
                {
                    headerName: "Consignment Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignment_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Invoice No",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "invoice_no",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Invoice Time",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "invoice_time",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series",
                    valueGetter : params =>{
                        if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                        }
                        else {
                            return "NA";
                        }
                    }
                },
                {
                    headerName: "Consignee Area",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_area",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee City",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_city",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Consignee Name",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consignee_name",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                }, {
                    headerName: "Consigner Code",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "consigner_code",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },
                {
                    headerName: "Zone",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "zone",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series"
                },]
            this.setStateForSlider(this.state.dealer_df,'At Dealership',sliderColumns1 )            
        }
        else if(remarks === 'Reported in Parking'){
            sliderColumns1 = [...sliderColumnsList, {
                headerName: "Fence Name",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "fence_name",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            }, {
                headerName: "Plant Code",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "plant_code",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            }, {
                headerName: "Reporting In Date",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "reporting_in_date",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter : params =>{
                    if (params.data.reporting_in_date != '' && params.data.reporting_in_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.reporting_in_date);
                    }
                    else {
                        return "NA";
                    }
                }
            }]
            this.setStateForSlider(this.state.parking_df,'Reported in Parking',sliderColumns1 )            
        }
        else if(remarks === 'In Return to Plant'){
            sliderColumns1 = [...sliderColumnsList]
            this.setStateForSlider(this.state.empty_return_to_plant_df,'In Return to Plant',sliderColumns1 )
            
        }else if(remarks === 'Device NRD'){
            sliderColumns1 = [...sliderColumnsList, {
                    headerName: "Last Packet Time",
                    // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                    field: "last_packet_time",
                    width: 150,
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    chartDataType: "series",
                    valueGetter : params =>{
                        if (params.data.last_packet_time != '' && params.data.last_packet_time != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.last_packet_time);
                        }
                        else {
                            return "NA";
                        }
                    }
                }, {
                    headerName : 'Truck Status',
                    field: 'trip_status',
                    width : 100,
                },
                {
                    headerName : 'Invoice no',
                    field: 'trip_status',
                    width : 100,
                },
                {
                    headerName : 'Consignment Code',
                    field: 'consignment_code',
                    width : 100,
                },
                {
                    headerName : 'Plant Code',
                    field: 'consigner_code',
                    width : 100,
                },
                {
                    headerName : 'Dealer City',
                    field: 'consignee_city',
                    width : 100,
                },
                {
                    headerName : 'Dealer Code',
                    field: 'consignee_code',
                    width : 100,
                },
                {
                    headerName : 'Dealer Name',
                    field: 'consignee_name',
                    width : 100,
                }]
            this.setStateForSlider(this.state.nrd_trucks_df,'Device NRD',sliderColumns1 )       
        }
        else if(remarks === 'Return In Movement'){
            sliderColumns1 = [...sliderColumnsList,{
                                    headerName : 'Last Packet time',
                                    field: 'timestamp',
                                    width : 140,
                                    valueGetter : (param) =>{
                                        if(param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                                        return ''
                                    }
                                },{
                                    headerName : 'OEM In time(5 Km)',
                                    field: '5km_geofence_gate_in',
                                    width : 140,
                                    valueGetter : (param) =>{
                                        if(param.data['5km_geofence_gate_in'] !== undefined && param.data['5km_geofence_gate_in'] !== ""){
                                            return getHyphenDDMMMYYYYHHMM(param.data['5km_geofence_gate_in'])
                                        }
                                        return ''
                                    }
                            },{
                                headerName : 'OEM Out time(5 Km)',
                                field: '5km_geofence_gate_out',
                                width : 140,
                                valueGetter : (param) =>{
                                    if(param.data['5km_geofence_gate_out'] !== undefined && param.data['5km_geofence_gate_out'] !== ''){
                                        return getHyphenDDMMMYYYYHHMM(param.data['5km_geofence_gate_out'])
                                    }
                                    return  ''
                                }
                                }
                            ,{
                                headerName : 'OEM Location Name',
                                field: 'geofence_name',
                                width : 140,           
                            }
                            ,{
                                headerName : 'Current Location',
                                field: 'last_known_address',
                                width : 200,           
                            }  ,{
                                headerName : 'Stoppage Duration',
                                field: 'comp_5kmgeofence_time_duration',
                                width : 150,
                                valueGetter: function (params) {
                                    // console.log(" ETA ", params.data.comp_5kmgeofence_time_duration)
                                    if (params.data.comp_5kmgeofence_time_duration !== '' && params.data.comp_5kmgeofence_time_duration !== undefined) {
                                        let sec = params.data.comp_5kmgeofence_time_duration
                                        return secondsToDhms(sec)
                                    }
                                },   
                            }     ]
            this.setStateForSlider(this.state.return_in_movement_df,'Return In Movement' ,sliderColumns1)  
        }
        else if(remarks === 'Oem Unloading'){
            sliderColumns1 = [...sliderColumnsList,{
                        headerName : 'Last Packet time',
                        field: 'timestamp',
                        width : 140,
                        valueGetter : (param) =>{
                            if(param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                             return ''
                           }
                },{
                    headerName : 'OEM In time',
                    field: '5km_geofence_gate_in',
                    width : 140,
                    valueGetter : (param) =>{
                        if(param.data['5km_geofence_gate_in'] !== undefined && param.data['5km_geofence_gate_in'] !== ""){
                            return getHyphenDDMMMYYYYHHMM(param.data['5km_geofence_gate_in'])
                        }
                        return ''
                    }
              },{
                headerName : 'OEM Out time',
                field: '5km_geofence_gate_out',
                width : 140,
                valueGetter : (param) =>{
                    if(param.data['5km_geofence_gate_out'] !== undefined && param.data['5km_geofence_gate_out'] !== ''){
                        return getHyphenDDMMMYYYYHHMM(param.data['5km_geofence_gate_out'])
                    }
                    return  ''
                }
                }
            ,{
                headerName : 'OEM Location Name',
                field: 'geofence_name',
                width : 140,           
            }
            ,{
                headerName : 'Current Location',
                field: 'last_known_address',
                width : 200,           
            }
            ,{
                headerName : 'Stoppage Duration',
                field: 'comp_5kmgeofence_time_duration',
                width : 150,
                valueGetter: function (params) {
                    // console.log(" ETA ", params.data.comp_5kmgeofence_time_duration)
                    if (params.data.comp_5kmgeofence_time_duration !== '' && params.data.comp_5kmgeofence_time_duration !== undefined) {
                        let sec = params.data.comp_5kmgeofence_time_duration
                        return secondsToDhms(sec)
                    }
                },   
            }
            ]
            this.setStateForSlider(this.state.oem_unloading_df, 'Oem Unloading', sliderColumns1)
        }
        else if(remarks == 'Oem (0-10)Km'){
                    sliderColumns1 = [...sliderColumnsList,{
                        headerName : 'Last Packet time',
                        field: 'timestamp',
                        width : 140,
                        valueGetter : (param) =>{
                            if(param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                            return ''
                        }
                },{
                    headerName : 'OEM In time',
                    field: '10km_geofence_gate_in',
                    width : 140,
                    valueGetter : (param) =>{
                        if(param.data['10km_geofence_gate_in'] !== undefined && param.data['10km_geofence_gate_in'] !== ""){
                            return getHyphenDDMMMYYYYHHMM(param.data['10km_geofence_gate_in'])
                        }
                        return ''
                    }
            },{
                headerName : 'OEM Out time',
                field: '10km_geofence_gate_out',
                width : 140,
                valueGetter : (param) =>{
                    if(param.data['10km_geofence_gate_out'] !== undefined && param.data['10km_geofence_gate_out'] !== ''){
                        return getHyphenDDMMMYYYYHHMM(param.data['10km_geofence_gate_out'])
                    }
                    return  ''
                }
                }
            ,{
                headerName : 'OEM Location Name',
                field: 'geofence_name',
                width : 140,           
            }
            ,{
                headerName : 'Current Location',
                field: 'last_known_address',
                width : 200,           
            }
            ,{
                headerName : 'Stoppage Duration',
                field: 'comp_10kmgeofence_time_duration',
                width : 150,
                valueGetter: function (params) {
                    // console.log(" ETA ", params.data.comp_5kmgeofence_time_duration)
                    if (params.data.comp_10kmgeofence_time_duration !== '' && params.data.comp_10kmgeofence_time_duration !== undefined) {
                        let sec = params.data.comp_10kmgeofence_time_duration
                        return secondsToDhms(sec)
                    }
                },   
            }
            ]
            this.setStateForSlider(this.state.oem_10km_df, 'Oem (0-10)Km', sliderColumns1)
        }
        else if(remarks === 'Total'){
            sliderColumns1 = [...sliderColumnsList,
                            {
                                headerName: "Consignment Code",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consignment_code",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName: "Invoice No",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "invoice_no",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName: "Invoice Time",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "invoice_time",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series",
                                valueGetter : params =>{
                                    if (params.data.invoice_time != ' ' && params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                                    }
                                    else {
                                        return "NA";
                                    }
                                }
                            },
                            {
                                headerName: "Dealer Area",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consignee_area",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName: "Dealer City",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consignee_city",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName: "Dealer Code",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consignee_code",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName: "Dealer Name",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consignee_name",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            }, {
                                headerName: "Plant Code",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "consigner_code",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },
                            {
                                headerName : 'Current Location',
                                field: 'last_known_address',
                                width : 200,
                            },
                            {
                                headerName: "Zone",
                                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                                field: "zone",
                                width: 150,
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                chartDataType: "series"
                            },{
                                headerName : 'Last Packet time',
                                field: 'timestamp',
                                width : 140,
                                valueGetter : (param) =>{
                                    if(param.data.timestamp != ' ' && param.data.timestamp !== 'undefined' && param.data.timestamp !== undefined && param.data.timestamp !== '') return getHyphenDDMMMYYYYHHMM(param.data.timestamp)
                                    return ''
                                }
                            },
                            {
                                headerName : 'Truck Status',
                                field: 'trip_status',
                                width : 100,
                            },
                            {
                                headerName : 'OEM In time',
                                field: '10km_geofence_gate_in',
                                width : 140,
                                valueGetter : (param) =>{
                                    if(param.data['10km_geofence_gate_in'] !== ' ' && param.data['10km_geofence_gate_in'] !== undefined && param.data['10km_geofence_gate_in'] !== ""){
                                        return getHyphenDDMMMYYYYHHMM(param.data['10km_geofence_gate_in'])
                                    }
                                    return ''
                                }
                            },
                            {
                                headerName : 'OEM Out time',
                                field: '10km_geofence_gate_out',
                                width : 140,
                                valueGetter : (param) =>{
                                    if(param.data['10km_geofence_gate_out'] !== ' ' && param.data['10km_geofence_gate_out'] !== undefined && param.data['10km_geofence_gate_out'] !== ''){
                                        return getHyphenDDMMMYYYYHHMM(param.data['10km_geofence_gate_out'])
                                    }
                                    return  ''
                                }
                                }
                            ,{
                                headerName : 'OEM Location Name',
                                field: 'geofence_name',
                                width : 140,           
                            }
                            ,{
                                headerName : 'Current Location',
                                field: 'last_known_address',
                                width : 200,           
                            }
                            ,{
                                headerName : 'Stoppage Duration',
                                field: 'comp_10kmgeofence_time_duration',
                                width : 150,
                                valueGetter: function (params) {
                                    // console.log(" ETA ", params.data.comp_5kmgeofence_time_duration)
                                    if (params.data.comp_10kmgeofence_time_duration !== '' && params.data.comp_10kmgeofence_time_duration !== undefined && params.data.comp_10kmgeofence_time_duration !== ' ') {
                                        let sec = params.data.comp_10kmgeofence_time_duration
                                        return secondsToDhms(sec)
                                    }
                                },   
                            }  ]
            this.setStateForSlider(this.state.total_df,'Total Data',sliderColumns1)
        }
    }


    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCloseSliderDiv = () => {
        this.setState({
            sliderZoneTranslate: "",
            sliderCriticalTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    };

    render() {
        const gpsColDef = [
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
            },
            {
                headerName: "Not Reported  30-45 Days",
                field: "Non Reporting 30-45 days",
                width: 140,
            },
            {
                headerName: "Not Reported  46-90 Days",
                field: "Non Reporting 46-90 days",
                width: 140,
            },
            {
                headerName: "Not Reported  >90 Days",
                field: "Non Reporting > 90 days",
                width: 140,
            },
            {
                headerName: "In Transit",
                field: "In Transit",
                width: 140,
            },
            {
                headerName: "At Dealership",
                field: "At Dealership",
                width: 140,
            },
            {
                headerName: "Reported In Parking",
                field: "Parking",
                width: 140,
            },
            {
                headerName: "In Return To Plant",
                field: "Return To Plant",
                width: 140,
            },
            {
                headerName: "In Return In Movement",
                field: "Return In Movement",
                width: 140,
            },
            {
                headerName: "Device NRD",
                field: "Device NRD",
                width: 140,
            },
            {
                headerName: "Waiting For Oem Unloading",
                field: "OEM Unloading",
                width: 140,
            },
            {
                headerName: "OEM (0-10 KM)",
                field: "(0-10km)OEM",
                width: 140,
            },
        ]

        const apiFleetSummaryColDef = [
            {
                headerName: "Away From Plant",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 300,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Plants",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "consigner_name",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "0 - 100",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "0-100",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "101 - 200",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "101-200",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "201  - 300",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "201-300",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "> 300",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: ">300",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                ]
            },
        ]

        const gpsFleetSummaryColDef = [
            {
                headerName: "Away From Plant",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 300,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Remarks",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "remarks",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Total Trucks in GPS fleet summary",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "count",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                ]
            }
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>


                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card height-equal equal-height-lg">
                            <div className="card-header" style={{ padding: '10px' }}>
                                <h5>GPS FLeet Status Summary</h5>
                            </div>
                            <div className="col-xl-12">
                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>
                            </div>

                            {/* GPS FLeet Status Summary */}
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div id="myGrid" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={gpsColDef}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.apiData}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={this.state.statusBar}
                                        pagination={true}
                                        rowHeight={35}
                                    //sideBar={this.state.sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>
                            </div>

                            {/* GPS FLeet Summary */}
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="card-header" style={{ padding: '10px' }}>
                                            <h5>GPS FLeet Summary</h5>
                                        </div>
                                        <div id="myGrid" style={{ height: "400px",}} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={gpsFleetSummaryColDef}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.gpsFleetStatus}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                statusBar={this.state.statusBar}
                                                pagination={true}
                                                groupHeaderHeight={40}
                                                headerHeight={40}
                                                floatingFiltersHeight={50}
                                                pivotGroupHeaderHeight={30}
                                                pivotHeaderHeight={50}
                                                rowHeight={40}
                                                onGridReady={this.onGridReady1}
                                                onGridState={this.onGridState1}
                                                onCellClicked={this.showSliderRowData}
                                            // sideBar={this.state.sideBar}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="card-header" style={{ padding: '10px' }}>
                                            <h5>In Return To Plant</h5>
                                        </div>
                                        <div id="myGrid" style={{ height: "400px"}} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={apiFleetSummaryColDef}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.apiFleetSummary}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                statusBar={this.state.statusBar}
                                                pagination={true}
                                                groupHeaderHeight={40}
                                                headerHeight={40}
                                                floatingFiltersHeight={50}
                                                pivotGroupHeaderHeight={30}
                                                pivotHeaderHeight={50}
                                                rowHeight={40}
                                                onGridReady={this.onGridReady1}
                                                onGridState={this.onGridState1}
                                            //sideBar={this.state.sideBar}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* slider div */}
                            <div
                                className={"slide-r " + this.state.sliderZoneTranslate}
                                style={{ overflow: "auto" }}
                            >
                                <div className="slide-r-title">
                                    <h4>
                                        {this.state.sliderTitle}
                                        <span
                                            className="float-right closebtn"
                                            style={{ marginRight: "25px" }}
                                            onClick={this.onCloseSliderDiv}
                                        >
                                            X
                                        </span>
                                    </h4>
                                </div>
                                <div className="slide-r-body" style={{ position: "relative" }}>
                                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                        <div
                                            id="myGrid"
                                            style={{
                                                height: "600px",
                                                width: "100%"
                                            }}
                                            className="ag-theme-balham"
                                        >
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.sliderColumns1}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowCriticalData}
                                                paginationPageSize={this.state.paginationPageSize}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                // pivotHeaderHeight={this.state.pivotHeaderHeight}
                                                // headerHeight={this.state.headerHeight}
                                                // floatingFiltersHeight={this.state.floatingFiltersHeight}
                                                // pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                                                onCellClicked={this.getZoneLegData}
                                                rowHeight={this.state.rowHeight}
                                                // onGridReady={this.onGridReady}
                                                // onGridState={this.onGridState}
                                                pagination={true}
                                                masterDetail={true}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                suppressRowClickSelection={true}
                                            // onCellEditingStopped={this.onCellUpdateData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* slider div end */}

                        </div>
                    </div>

                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>    </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
        );
    }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
        }
        catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

function refActions() {
    alert("helloo")
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}


function secondsToMinutesSeconds(seconds) {
	// var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numhours + " hours " + numminutes + " minutes " + numseconds + ' seconds';
}





