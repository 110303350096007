import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridnosidebarComponent';
import Modal from 'react-responsive-modal';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageSNDConsignments extends Component {

	constructor(props){
		super(props);
		this.state={ 
			pageTitle: "Manage Consignees List",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false,
			// customer_type:'',
			customer_name:'',
			customer_code:'',
			latitude:'',
			longitude:'',
			customer_city:'',
			customer_state:'',
			customer_address:'',
			customer_pincode:'',
			customer_zone:'',
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			sliderTicketTranslate: '',
			tabsliderTranslate: '',
			inboxSlider: '',
			showsecond: 'show-n'
		}
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		 /*Consignments List*/	
		 this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

	    redirectURL.post('/consignments/getprtconsignees')    
		  .then((response) => {
            var records = JSON.parse(JSON.stringify(response)).data;
            // console.log("records", records);
            this.setState({
                rowData:records
            });
		  })
		  .catch(function (error) {
		    console.log(error);
            });
	};
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			overly:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			overly:'show-n',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			// customer_type:'',
			customer_name:'',
			customer_code:'',
			latitude:'',
			longitude:'',
			customer_city:'',
			customer_state:'',
			customer_address:'',
			customer_pincode:'',
			customer_zone:'',
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	saveConsignmentData = async (event) => {
		event.preventDefault();
		// var customer_type = this.state.customer_type
		var  customer_name = this.state.customer_name
		var customer_code=this.state.customer_code
		var latitude=this.state.latitude
		var longitude=this.state.longitude
		var customer_city=this.state.customer_city
		var customer_state=this.state.customer_state
		var customer_address=this.state.customer_address
		var customer_pincode=this.state.customer_pincode
		var customer_zone=this.state.customer_zone

		if(customer_name!=""&&customer_code!=""&&customer_city!=""&&latitude!=""&&longitude!=""){
			var params={
				// customer_type:customer_type,
				customer_name:customer_name,
				customer_code:customer_code,
				latitude:latitude,
				longitude:longitude,
				customer_city:customer_city,
				customer_state:customer_state,
				customer_address:customer_address,
				customer_pincode:customer_pincode,
				customer_zone:customer_zone,
			}
			console.log(params)
			redirectURL.post("/dashboard/saveCustomers",params).then(async (response)=>{
				console.log(response.data.status,"159")
				if(response.data.status=="success"){
					this.setState({
						show:true,
						basicType:'success',
						basicTitle:'Successfully Created The Customer',
								// customer_type:'',
						customer_name:'',
						customer_code:'',
						latitude:'',
						longitude:'',
						customer_city:'',
						customer_state:'',
						customer_address:'',
						customer_pincode:'',
						customer_zone:'',
						loadshow:"show-n",
						overly:"show-n",

					});
					this.componentDidMount();
				}else{
					if(response.data.message=="Customer Data Already Exists"){
						this.setState({
							show: true,
							basicType: "warning",
							basicTitle: "Customer Data Already Exists",
							loadshow:"show-n",
							overly:"show-n",
						})
					}else if(response.data.message=="Please Enter Mondatory Fields"){
						console.log("danger")
						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Please Enter Mondatory Fields",
							loadshow:"show-n",
							overly:"show-n",
						})
					}
					else{
						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Failed To Create Customer",
							loadshow:"show-n",
							overly:"show-n",
						})
					}
				}
			})
			
			this.setState({
				// customer_type:'',
				customer_name:'',
				customer_code:'',
				latitude:'',
				longitude:'',
				customer_city:'',
				customer_state:'',
				customer_address:'',
				customer_pincode:'',
				customer_zone:'',
				loadshow: 'show-n',
				overly: 'show-n',
				sliderRouteTranslate: '',
				sliderTicketTranslate: '',
				tabsliderTranslate: '',
				inboxSlider: '',
				showsecond: 'show-n',
				sliderTranslate:''
			})
		}else{
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please Enter Mondatory Fields",
				// loadshow:"show-n",
				// overly:"show-n",
			})

		}
		

	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			sliderTicketTranslate: '',
			tabsliderTranslate: '',
			inboxSlider: '',
			showsecond: 'show-n',
			sliderTranslate:'',
			// customer_type:'',
			customer_name:'',
			customer_code:'',
			latitude:'',
			longitude:'',
			customer_city:'',
			customer_state:'',
			customer_address:'',
			customer_pincode:'',
			customer_zone:'',
		});

	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}

	validateInput = (event) => {
		var name = event.target.name;
		var value = event.target.value;
		const characterRegex = /^[a-zA-Z\s]+$/;
		console.log(!characterRegex.test(value),"910")
		if (!characterRegex.test(value)) {
			if(value == ""){
				this.setState({
					[name]: "",
				  });
			}
		 
		} else {
			this.setState({ [name]: value })
		}
	};

	validatelatitudeInput = (event) => {
		var name = event.target.name;
		var inputValue = event.target.value;
		// const inputValue = event.target.value.trim();
		const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
		if(inputValue !=undefined && inputValue !="")
		{
			if (!floatRegex.test(inputValue)) {
			
			} else {
			this.setState({
				[name]: inputValue,
			});
			}
		}else{
			this.setState({
				[name]: "",
			});
		}
	  };
	validateAlphaNumInput = (event) => {
		var name = event.target.name;
		var inputValue = event.target.value;
		// const inputValue = event.target.value.trim();
		const floatRegex = /^[a-zA-Z0-9]*$/;
		if(inputValue !=undefined && inputValue !="")
		{
			if (!floatRegex.test(inputValue)) {
			
			} else {
			this.setState({
				[name]: inputValue,
			});
			}
		}else{
			this.setState({
				[name]: "",
			});
		}
	};
	validateNumInput = (event) => {
		var name = event.target.name;
		var inputValue = event.target.value;
		// const inputValue = event.target.value.trim();
		const floatRegex =  /^[0-9]+$/;
		if(inputValue !=undefined && inputValue !="")
		{
			if (!floatRegex.test(inputValue)) {
			
			} else {
			this.setState({
				[name]: inputValue,
			});
			}
		}else{
			this.setState({
				[name]: "",
			});
		}
	};
	validateAlphaInput = (event) => {
		var name = event.target.name;
		var inputValue = event.target.value;
		// const inputValue = event.target.value.trim();
		const floatRegex =  /^[A-Za-z]+$/;
		if(inputValue !=undefined && inputValue !="")
		{
			if (!floatRegex.test(inputValue)) {
			
			} else {
			this.setState({
				[name]: inputValue,
			});
			}
		}else{
			this.setState({
				[name]: "",
			});
		}
	};
	validateAlphaInputZone = (event) => {
		var name = event.target.name;
		var inputValue = event.target.value.toUpperCase();
		// const inputValue = event.target.value.trim();
		const floatRegex =  /^[A-Za-z]+$/;
		if(inputValue !=undefined && inputValue !="")
		{
			if (!floatRegex.test(inputValue)) {
			
			} else {
			this.setState({
				[name]: inputValue,
			});
			}
		}else{
			this.setState({
				[name]: "",
			});
		}
	};

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		const columnwithDefs = [
				// {
				// 	headerName: "Customer Type",
				// 	field: "location_type",
				// 	width: 150,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
				// },
	  	        {
	  	          headerName: "Customer Name",
	  	          field: "consignee_name",
	  	          width: 150,
	    	          editable:false,
	    	          filter: "agSetColumnFilter"
		        },
		        {
	  	          headerName: "Customer Code",
	  	          field: "consignee_code",
	  	          width: 150,
	    	          editable:false,
	    	          filter: "agSetColumnFilter"
				},
				{
	  	          	headerName: "Latitude",
	  	          	field: "consignee_lat",
	  	          	width: 150,
					editable:false,
					// valueGetter:function(params){
					// 	console.log("lattitute",params.data)
					// 	try{
					// 		var corrds = params.data.coordinates;
					// 		var cordinates = corrds.replace(/'/g, '"')
					// 		return JSON.parse(cordinates).lat;
					// 	}
					// 	catch(e){
					// 		return {"lat":0, "lng":0};
							
					// 	}
						
					// },
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Longitude",
	  	          	field: "consignee_lng",
	  	          	width: 150,
					editable:false,
					// valueGetter:function(params){
					// 	try{
					// 		console.log(params.data)
					// 		var corrds = params.data.coordinates;
					// 		var cordinates = corrds.replace(/'/g, '"')
					// 		return JSON.parse(cordinates).lng;
					// 	}
					// 	catch(e){
					// 		return {"lat":0, "lng":0};
					// 	}
						
					// },
					filter: "agSetColumnFilter"
		        },
				// {
	  	        //   	headerName: "Customer Code",
	  	        //   	field: "customer_code",
	  	        //   	width: 150,
				// 	editable:false,
				// 	filter: "agSetColumnFilter"
				// 	,
				// 	valueGetter: (params)=>{
				// 		console.log("dateparams",params.data)
				// 	}
		        // },
				{
	  	          	headerName: "Customer City",
	  	          	field: "consignee_city",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"

					
		        },
				{
	  	          	headerName: "Customer State",
	  	          	field: "consignee_state",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Customer Address",
	  	          	field: "consignee_address",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter"
		        },
				{
	  	          	headerName: "Customer Pincode",
	  	          	field: "pincode",
	  	          	width: 150,
					editable:false,
					filter: "agSetColumnFilter",
					// valueGetter: (params)=>{
					// 	console.log()
					// 	var dataParams = params.data.consignee_address
					// 	var lenAdd = dataParams.length - 6
					// 	var updData = dataParams.slice(lenAdd)
					// 	var finalData = parseInt(updData)
						
					// 	if(!isNaN(finalData)){
					// 		return finalData
					// 	}else{
					// 		return ""
					// 	}

					// }
		        },
				{
					headerName: "Customer Zone",
					field: "zone",
					width: 150,
					editable:false,
					filter: "agSetColumnFilter"
				}
	  	        
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									
									<button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn btn">  Add Customer</button>
				       				
								</h5>
								
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Add Customers   
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative",overflowY:'auto'}}>
						
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveConsignmentData.bind(this)}>
								
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Type</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.changeHandler.bind(this)}
										value={this.state.customer_type}
										autoComplete="off"
										name="customer_type"
										placeholder='Enter Customer Type'
									/>
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Name<span style={{color:'red'}}>*</span></label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateInput}
										value={this.state.customer_name}
										autoComplete="off"
										name="customer_name"
										placeholder='Enter Customer Name'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Code<span style={{color:'red'}}>*</span></label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateAlphaNumInput}
										value={this.state.customer_code}
										autoComplete="off"
										name="customer_code"
										placeholder='Enter Customer Code'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Latitude<span style={{color:'red'}}>*</span></label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validatelatitudeInput}
										value={this.state.latitude}
										autoComplete="off"
										name="latitude"
										placeholder='Enter Latitude'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Longitude<span style={{color:'red'}}>*</span></label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validatelatitudeInput}
										value={this.state.longitude}
										autoComplete="off"
										name="longitude"
										placeholder='Enter Longitude'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer City<span style={{color:'red'}}>*</span></label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateInput}
										value={this.state.customer_city}
										autoComplete="off"
										name="customer_city"
										placeholder='Enter Customer City'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer State</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateInput}
										value={this.state.customer_state}
										autoComplete="off"
										name="customer_state"
										placeholder='Enter Customer State'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Address</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.changeHandler.bind(this)}
										value={this.state.customer_address}
										autoComplete="off"
										name="customer_address"
										placeholder='Enter Customer Address'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Pincode</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateNumInput}
										value={this.state.customer_pincode}
										autoComplete="off"
										name="customer_pincode"
										placeholder='Enter Customer Pincode'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Zone</label>
									<input
										type="text"
										className="form-control ml-3"
										onChange={this.validateAlphaInputZone}
										value={this.state.customer_zone}
										autoComplete="off"
										name="customer_zone"
										placeholder='Enter Customer Zone'
									/>
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
						
				 	</div>
				 </div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " + (this.state.loadshow)}>
							<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
						</div>
						<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
              	
		);
	}
}