import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewmllattachments";
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLRSOPOD extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                uploaddoc:UploadPODReceit,
                viewattachments:ViewAttachments
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"RSO POD",
            sliderRouteTranslate:'',
            file:'',
            csvcontent:[],
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            headerHeight:80,
            formatno:"MLL/AOB/RW/01",
            date_of_placement:"",
            loading_station_name:"",
            sourceplants:[],
            destplants:[],
            fnrno:"",
            showform:"show-n",
            fnrvins:[],
            sources:[],
            sliderForceDestination:"",
            soureplant:"",
            destinationplant:"",
            vinno:"",
            vins:[],
            vehicle_no:"",
            nvrr_no:"",
            dispatch_plant:"",
            nvrr_date:"",
            plant_station:"",
            lr_no_date:"",
            model:"",
            rso_stn_date:"",
            engine_no:"",
            diesel_qty:"",
            form_22:"",
            kilometer_reading:"",
            owner_manual:"",
            colour:"",
            service_book_available:"",
            battery_make:"",
            road_permit_endorsed:"",
            tyre_make:"",
            road_permit_no:"",
            stockyard_incharge:"",
            stockyard_incharge_name:"",
            transporter_driver:"",
            uploads:[],
            files:[],
            shwdata:"show-n",
            sliderDefectData:"",
            defect_type:"",
            defect_location:"",
            defect_remarks:"",
            defect_documents:""
            
            
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onShowDefects = this.onShowDefects.bind(this);
	}
	
    componentDidMount(){
        loadDateTimeScript()
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadWagonData(parameters)

	}
    
    onLoadWagonData(parameters)
    {
		redirectURL.post("/mllconsignments/mllrsovins")
		.then((response) => {
            var records = response.data.records;
           
     		this.setState({
                vins:records,
                loadshow:"show-n",
                overly:"show-n"
			});
        })
    }
    
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",
            sliderDefectData:""

		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
	
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
	
	onAddRow() {
        // var newItem = 
        // {
        //     sr_no:"",
        //     defect_type:"",
        //     defect_location:"",
        //     defect_remarks:""
        // };
        //console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderDefectData:"slider-translate",
            overly:'show-m',
        })
    }
   
    selectvins(){
		let items = [];
		var vins = this.state.vins;
		if(vins.length > 0)
		{
			vins.map((item) => {
				items.push({"value":item.vehicle_id,"label":item.vehicle_id})
			})
		}    
		return items;
    }
    
    onChangeVINs(vinno){
		this.setState(
			{ vinno },
			() => console.log(`Option selected:`, this.state.vinno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }

    onClickGetVinData(){
        if(this.state.vinno != "")
        {
            var vin = this.state.vinno.value
            var parameters = {
                vin_no:vin
            }
            redirectURL.post("/mllconsignments/mllvinpods", parameters)
            .then((response) => {
                console.log("response ", response.data)
                var records = response.data.records;
                var rsopod = response.data.rsopod;
                var defects = response.data.poddefects;
                if(rsopod.length > 0)
                {
                    var nvrr_date = document.getElementById("nvrr_date").value = rsopod[0].nvrr_date;
                    var lr_no_date = document.getElementById("lr_no_date").value = rsopod[0].lr_no_date;
                    var rso_stn_date = document.getElementById("rso_stn_date").value =rsopod[0].rso_stn_date;
                    var nvrr_no = rsopod[0].nvrr_no;
                    var form_22 = rsopod[0].form_22;
                    var kilometer_reading = rsopod[0].kilometer_reading;
                    var service_book_available = rsopod[0].service_book_available;
                    var battery_make  = rsopod[0].battery_make;
                    var road_permit_endorsed = rsopod[0].road_permit_endorsed;
                    var tyre_make= rsopod[0].tyre_make;
                    var road_permit_no = rsopod[0].road_permit_no;
                    var stockyard_incharge = rsopod[0].stockyard_incharge;
                    var transporter_driver = rsopod[0].transporter_driver;
                }
                else
                {
                    document.getElementById("nvrr_date").value = "";
                    document.getElementById("lr_no_date").value = "";
                    document.getElementById("rso_stn_date").value ="";
                    var nvrr_no = "";
                    var kilometer_reading = "";
                    var service_book_available = "";
                    var battery_make  = "";
                    var road_permit_endorsed = "";
                    var tyre_make = "";
                    var road_permit_no= "";
                    var stockyard_incharge="";
                    var transporter_driver = "";
                    var form_22 = "";
                }

                if(records.length > 0)
                {
                    this.setState({
                        vehicle_no:records[0].vehicle_no,
                        dispatch_plant:records[0].loadingyard,
                        plant_station:records[0].vehicle_no,
                        model:records[0].group_code,
                        engine_no:records[0].enigne_no,
                        diesel_qty:records[0].qty,
                        owner_manual:records[0].ref_doc,
                        colour:records[0].colour,                      
                        shwdata:"show-m",
                        rowData:defects,

                        nvrr_no:nvrr_no,
                        form_22:form_22,
                        kilometer_reading:kilometer_reading,
                        service_book_available:service_book_available,
                        battery_make:battery_make,
                        road_permit_endorsed:road_permit_endorsed,
                        tyre_make:tyre_make,
                        road_permit_no:road_permit_no,
                        stockyard_incharge_name:stockyard_incharge,
                        transporter_driver:transporter_driver, 
                    })
                }
            })
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Please select VIN no",
                basicType:"danger"
            })
        }
        
    }

    onShowDefects(){
        var vin = this.state.vinno.value
        var parameters = {
            vin_no:vin
        }
        redirectURL.post("/mllconsignments/mllpoddefects",parameters)
        .then((response) => {
            var records = response.data.records
            this.setState({
                rowData:records
            })
        })
    }
    formHandler()
    {
        // event.preventDefault();

        
        var records = []
        this.gridApi.forEachNode(node => records.push(node.data));
        console.log("records ",records)
        var vehicle_no = this.state.vehicle_no;
        var nvrr_no = this.state.nvrr_no;
        var dispatch_plant = this.state.dispatch_plant;
        var nvrr_date = document.getElementById("nvrr_date").value;
        var plant_station = this.state.plant_station;
        var lr_no_date = document.getElementById("lr_no_date").value;
        var model = this.state.model;
        var rso_stn_date = document.getElementById("rso_stn_date").value;
        var engine_no = this.state.engine_no;
        var diesel_qty = this.state.diesel_qty;
        var form_22 = this.state.form_22;
        var kilometer_reading = this.state.kilometer_reading;
        var owner_manual = this.state.owner_manual;
        var colour = this.state.colour;
        var service_book_available = this.state.service_book_available;
        var battery_make = this.state.battery_make;
        var road_permit_endorsed = this.state.road_permit_endorsed;
        var tyre_make = this.state.tyre_make;
        var road_permit_no = this.state.road_permit_no;
        var stockyard_incharge = this.state.stockyard_incharge_name;
        var transporter_driver = this.state.transporter_driver;
        var parameters = {
            vehicle_id:this.state.vinno.value,
            vehicle_no:vehicle_no,
            nvrr_no:nvrr_no,
            dispatch_plant:dispatch_plant,
            nvrr_date:nvrr_date,
            plant_station:plant_station,
            lr_no_date:lr_no_date,
            model:model,
            rso_stn_date:rso_stn_date,
            engine_no:engine_no,
            diesel_qty:diesel_qty,
            form_22:form_22,
            kilometer_reading:kilometer_reading,
            owner_manual:owner_manual,
            colour:colour,
            service_book_available:service_book_available,
            battery_make:battery_make,
            road_permit_endorsed:road_permit_endorsed,
            tyre_make:tyre_make,
            road_permit_no:road_permit_no,
            stockyard_incharge:stockyard_incharge,
            transporter_driver:transporter_driver,
            username:localStorage.getItem("username"),
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email")
        }
        console.log("Parameters ", parameters)
        if(nvrr_no != "" && nvrr_date != "" && lr_no_date != "" && rso_stn_date != "" && form_22 != ""
        && kilometer_reading != "" && battery_make != "" && road_permit_no != "" && road_permit_endorsed != ""
         && stockyard_incharge != "" && transporter_driver != "")
         {
            
            redirectURL.post("/mllconsignments/savemllrsopod", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                if(response.data.status == "success")
                {
                    this.setState({
                        show1:true,
                        basicType1:"success",
                        basicTitle1:"Successfully update data"
                    })
                }
            })
         }
        else
        {
            this.setState({
                show1:true,
                basicType1:"danger",
                basicTitle1:"All * fields are mandatory"
            })
        }
    }

    onClickShowUploadModal(params)
	{
		this.setState({
			open:true
		})
    }
    onCloseModal = () => {
		this.setState({ open: false, rejectopen:false });
	 };
 
	changeFileHandler = async (e) => {
		console.log(e.target.files)
		var files = Array.from(e.target.files);
		this.setState({
            files:files,
            open:false
		})
	}

    formDefect(event)
    {
        event.preventDefault();
        if(this.state.vinno != "")
        {
            var vin = this.state.vinno.value
        }
        else
        {
            var vin = "";
        }
        if(this.state.defect_type != "" && this.state.defect_location != "")
        {
            var formdata = new FormData()
            var ftype = "";
            var fname = "";
            for(var x = 0; x<this.state.files.length; x++) {
                console.log("this.state.files[x] ", this.state.files[x].type)
                ftype = this.state.files[x].type
                fname = this.state.files[x].name


                formdata.append('file', this.state.files[x])
            }

            formdata.append("vin" , vin)
            formdata.append("defect_type" , this.state.defect_type)
            formdata.append("defect_location" , this.state.defect_location)
            formdata.append("defect_remarks" , this.state.defect_remarks)
            //formdata.append("username" ,localStorage.getItem("username")),
           // formdata.append("userid" ,localStorage.getItem("userid")),
            //formdata.append("email" ,localStorage.getItem("email"))
            var ft = fname.split(".")
            var extens = ft[parseInt(ft.length)-1];
            console.log("extension ", extens)
            if(extens == "pdf" || extens == "xls" || extens == "xlsx" || extens == "XLS" 
            || extens == "XLSX" || extens == "doc" || extens == "docx" || extens == "pdfx" || extens == "ppt"
            || extens == "pptx" || extens == "csv" || extens == "xls" || extens == "txt")
            {
                redirectURL.post("/mllconsignments/savemllrsopoddefects", formdata,{
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then((response) => {
                    // console.log("resj ", response.data)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show1:true,
                            basicType1:"success",
                            basicTitle1:"Successfully update data",
                            defect_type:"",
                            defect_location:"",
                            defect_remarks:"",
                            file:[],
                            files:[],
                            sliderDefectData:"",
                            overly:'show-n',
                        })
                        // $("form#defectForm").reset();
                        this.onShowDefects()
                    }
                    else{
                        this.setState({
                            show1:true,
                            basicType1:"danger",
                            basicTitle1:"Failed to update data"
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show1:true,
                    basicType1:"danger",
                    basicTitle1:"File formats allowed only pdf, xls,xlsx, doc,docx, txt, csv, ppt"
                })
            }

            // console.log("formdata ",formdata)
            
        }
        else
        {
            this.setState({
                show1:true,
                basicType1:"danger",
                basicTitle1:"All * fields are mandatory"
            })
        }   
    }

    onClickDownload(params){
        console.log("params ",params.data)
        if(this.state.vinno != "")
        {
            var vin = this.state.vinno.value
        }
        else
        {
            var vin = "";
        }
        if(vin != "")
        {
            var parameters = {
                vin:vin,
                rowid:params.data._id
            }
            redirectURL.post("/mllconsignments/downloadmllfile",parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.defect_document;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })
        }
        

        // var reqObj = new XMLHttpRequest();
        // reqObj.open('POST','http://localhost:3002/foxtrot/mllconsignments/downloadmllfile',true);     // 'getpdf' is the URI to recongize your request at the server
        // reqObj.send();

        // reqObj.onreadystatechange = function() {
        //     var resObj = this;
        //     if(resObj.readyState == resObj.DONE) {
        //         if (resObj.status != 200) {
        //             console.log(200);
        //         } else if (resObj.status == 200){
        //             var resTxt = reqObj.responseText;
        //             window.location.assign(resTxt);    // Opens the pdf download prompt
        //         }
        //     }
        // }

    }
    render(){   
        const modalStyles  = {
			width:'300px !important',
		}
		const { open } = this.state;
        var columnwithDefs = [
			{
				headerName:"SR. NO",
				field:"sr_no",
				width:100,
                resizable:true,
                editable:false,
                valueGetter:function(params){
					try{
                       return (parseInt(params.node.id)+1)
                    }
                    catch(e){
                        return "";
                    }
				}
            },
            {
				headerName:"DEFECT TYPE",
				field:"defect_type",
				width:150,
                resizable:true,
                editable:false,
			},
            {
				headerName:"DEFECT LOCATION",
				field:"defect_location",
				width:150,
                resizable:true,
                editable:false,
			},
            {
				headerName:"DEFECT REMARKS",
				field:"defect_remarks",
				width:300,
                resizable:true,
                editable:false,
			},
			
            {
				headerName:"DOCUMENTS",
				field:"defect_documents",
				width:250,
                resizable:true,
                editable:false,
                cellRendererSelector:function(params){
                    return {
                        component:"viewattachments"
                    }
                }
                // cellRenderer:function(params){
                //     // var aa = <a href={"../../../../api/routes/controller/csv/sample_bulk_mark_rso.3d9034e7.csv"}>View</a>
                //     // return aa;

                //     const a = document.createElement('a');
                //     a.innerText = a.href = "C:\Users/venkatesh.jalla/Desktop/foxtrot_app/api/routes/controller/csv/sample_bulk_mark_rso.3d9034e7.csv";
                //     a.target = '_blank';
                //     // Prevent click from reaching AgGrid
                //     a.addEventListener('click', event => { event.stopPropagation() });
                //     return a;
                // }
                
			},
            // {
			// 	headerName:"Upload Document",
			// 	field:"upload_docs",
			// 	width:250,
            //     resizable:true,
            //     editable:false,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"uploaddoc"
            //         }
            //     }
			// },
		]
       
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>RSO POD  </span>
                                </h5>
				   			</div>
				   			<div className="card-body">
                                <div className="row">
                                    <div className="row col-xl-12 col-lg-12">
                                        <div className="col-xl-6 col-lg-6 form-group" style={{padding:"0px"}}>
                                            <label className="col-xl-12 col-lg-12">Select VIN NO:</label>
                                            <div className="col-xl-12 col-lg-12">
                                                <Select 
                                                    placeholder={"Select VIN No"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.vinno}
                                                    onChange={this.onChangeVINs.bind(this)} 
                                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.selectvins()} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 ml-15p form-group">
                                            <button type="button" className="btn btn-warning mt-30p" onClick={this.onClickGetVinData.bind(this)}>Get Data</button>
                                        </div>
                                    </div>
                                    
                                    <div className={"row col-xl-12 col-lg-12 "+(this.state.shwdata) }>
                                        <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">
                                            Add Defect
                                        </button>
                                        
                                        <div className={"row col-xl-12 col-lg-12 " }>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Vehicle SR No:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <span className="form-control">
                                                        {this.state.vehicle_no}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">NVRR No <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="nvrr_no"
                                                        id="nvrr_no"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.nvrr_no} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Dispatch Plant:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="dispatch_plant"
                                                        id="dispatch_plant"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.dispatch_plant}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">NVRR Date & Time <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="nvrr_date"
                                                        id="nvrr_date"
                                                        className="datetimepicker_mask form-control"
                                                        id="nvrr_date" 
                                                         />
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Plant Station:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="plant_station"
                                                        className="form-control"
                                                        id="plant_station"
                                                        value={this.state.plant_station} 
                                                        />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">LR NO. / Date <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="lr_no_date"
                                                        id="lr_no_date"
                                                        className="datetimepicker_mask form-control"
                                                        onChange={this.changeHandler} 
                                                         />
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Model:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="model"
                                                        id="model"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.model} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">RSO STN. / Date <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="rso_stn_date"
                                                        id="rso_stn_date"
                                                        className="datetimepicker_mask form-control"
                                                        onChange={this.changeHandler} 
                                                         />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Engine No:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="engine_no"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.engine_no} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Diesel Qty:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="diesel_qty"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.diesel_qty} />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Form 22 <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="form_22"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.form_22} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Kilometer Reading <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="kilometer_reading"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.kilometer_reading} />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Owner Manual:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="owner_manual"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.owner_manual} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Colour:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="colour"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.colour} />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Service Book Available:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="service_book_available"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.service_book_available} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Battery Make <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="battery_make"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.battery_make} />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Road Permit Endorsed <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="road_permit_endorsed"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.road_permit_endorsed} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Tyre Make <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="tyre_make"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.tyre_make} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Road Permit No <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="road_permit_no"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.road_permit_no} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                &nbsp;
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Stockyard Incharge Name <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="stockyard_incharge_name"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.stockyard_incharge_name} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 form-group">
                                                <label className="col-xl-12 col-lg-12">Transporter Driver <span style={{color:"#FF0000"}}>*</span>:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        name="transporter_driver"
                                                        className="form-control"
                                                        onChange={this.changeHandler} 
                                                        value={this.state.transporter_driver} />
                                                </div>
                                            </div>

                                        </div>  
                                        {/* <div className={"col-xl-12 col-lg-12 "}>
                                            <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add Row</button>
                                        </div> */}
                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                />
                                                <button type="button" onClick={this.formHandler.bind(this)} className="btn btn-success float-right">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
				 </div>

				

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            	<Modal open={open} onClose={this.onCloseModal} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                        
                        <div className="col-xl-12 col-lg-12" style={{marginLeft:"22px"}}>
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data">
                                
                              
                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile[]" multiple={true} onChange={this.changeFileHandler.bind(this)}  className="form-control" required  />
                                </div>
                               
                            </form>
                        </div>

                    </div>
                </Modal>



                <div className={"slide-r "+(this.state.sliderDefectData)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Defect Information
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDefect.bind(this)}>
                            
                            <div className="form-group mt-20p">
                                <label className="">Defect Type <span style={{color:"#FF0000"}}>*</span></label> 
                                <input type="text" name="defect_type" value={this.state.defect_type} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                            </div>
                            
                            <div className="form-group mt-20p">
                                <label className="">Defect Location <span style={{color:"#FF0000"}}>*</span></label> 
                                <input type="text" name="defect_location" value={this.state.defect_location} onChange={this.changeHandler.bind(this)}  className="form-control" required  />
                            </div>
                            
                            <div className="form-group mt-20p">
                                <label className="">Defect Remarks</label> 
                                <textarea name="defect_remarks" value={this.state.defect_remarks} onChange={this.changeHandler.bind(this)} rows="4" className="form-control" ></textarea>
                            </div>
                            
                            <div className="form-group mt-20p">
                                <label className="">Upload File <span style={{color:"#FF0000"}}>*</span></label> 
                                <input type="file" id="defect_documents" name="defect_documents" onChange={this.changeFileHandler.bind(this)}  className="form-control" required  />
                            </div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						    <div className="col-xl-12 col-lg-12 form-group">
                                Note: File formats allowed s: pdf, xls, xlsx, doc, docx, txt, csv, ppt
                            </div>
                        </div>
					</div>
				</div>

            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#nvrr_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });

    $("#lr_no_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#nvrr_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });
    
    $("#lr_no_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });
    
    
	// $('.datetimepicker_date').datetimepicker({
	// 	mask:'39-19-9999',
	// 	format:'d-m-Y',
	// 	timepicker:false
	// });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}