import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../redirectURL');

export default class BiTransitReturns extends Component {
  	  constructor(props) {
	    super(props);
	    this.state = {
            rowData:[],
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:"show-n"
	    };
	   
	  }

	
    componentDidMount(){
		this.setState({
            loadshow:"show-n"
        });
        var params = {
            userid:this.state.userid,
            useremail:localStorage.getItem("email")

        }
       /* redirectURL.post("/dashboard/userzohoanalytics",params)
        .then((response) => {
            console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                rowData:records
            })
        })*/
    }
	
    render() {
	  
	    return (
	        <div className="container-fluid">
            <SweetAlert
                  show={this.state.show}
                  type={this.state.basicType}
                  title={this.state.basicTitle}
                  onConfirm={this.closeAlert}
              >
              </SweetAlert>
              <div className="row">
                  
                     
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">                      
                                {/* <div className="card-header">
                                    <h5>
                                        <i className="icon-map cus-i"></i> <span>Volume &amp; Freight - Dashboard</span>
                                         &nbsp;&nbsp;| &nbsp;&nbsp;<span><i className="icofont icofont-ui-edit"></i> Edit</span> 
                                    </h5>
                                </div> */}
                                <div className="row card-body">
                                <iframe frameborder="0" width="100%" height="2200" src="https://autometrics.in:8443/open-view/13979/4bdb15881e36e84f782837b9471c674f"></iframe>
                                </div>
                            </div>
                        </div>
                    
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
	    );
	  }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } 
      catch (e) {
      /*Ignore*/
      }
    }
    return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function refActions()
{
	alert("helloo")
}


                                
                                
                                
                                
                                
                                