
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


const ReportCarrier = (props) => {

    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickReportCarrierAction(props.data);
    };
    return (
        <div className="map-icon-component">
			     <button onClick={handleRouteClick} className="btn btn-success btn-xs" title="Report Carrier">Report Carrier </button>
        </div>
    );
};

export default ReportCarrier;
