'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class UserLogReport extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        showUserType:"show-m",
        plants:[],
        plant_name:"",
        cellPlants:[],
        sliderTranslate:'',
        //components : {datepicker:getDatePicker()}
    }
  }

componentDidMount(){
    loadDateTimeScript();
    var edate = moment.parseZone().format('DD-MM-YYYY');
		var sdate = moment.parseZone().subtract(1, 'days').format('DD-MM-YYYY')
    $("#fromDate").val(sdate);
   
    $("#toDate").val(edate);
    var deptParams  = {
        dept_code : localStorage.getItem("dept_code")
    }
    redirectURL.post("/consignments/getUserLogReport",deptParams)    
    .then((response) => {
        var records = response.data;
        //console.log("records",records)
        if(records.length > 0)
        {
            redirectURL.post("/consignments/fetchUserLogReport",{"userData":records}).then((response)=>{
                //console.log(response.data)
                this.setState({
                    rowData : response.data,
                    loadshow:'show-n',
                    overly:'show-n',
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
            })
        }
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    redirectURL.post("/consignments/getLspApiUsers",deptParams)    
    .then((response) => {
        var usersList = response.data;
        //console.log("usersList ", usersList)
        var items = [];
        if(usersList.length > 0)
        {
            usersList.map((item) =>{
                items.push({"value":item._id,"label":item.username})
            })
        }
        this.setState({
            usersList : items
        })
    })

    redirectURL.post("/consignments/getDepartments")    
    .then((response) => {
        var departments = response.data;
        //console.log("departments ", departments)
        var items = [];
        if(departments.length > 0)
        {
            departments.map((item) =>{
                items.push({"value":item.dept_code,"label":item.dept_name})
            })
        }
        this.setState({
            departments : items
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}



selectusers(){
    let items = [];    
    try{
        if(this.state.usersList.length > 0)
        {
            this.state.usersList.map((item) =>{
                items.push({"value":item._id,"label":item.username})
            })
        }
    }
    catch(e){

    }
    
    return items;
}

onChangeUserItem(userId){
    this.setState(
        { userId },
        () => console.log(`User Option selected:`, this.state.userId)
      );
    //   this.setState({
    //     showUserType:"show-n",
    //     userType:"",
    //   });
      //$("#userType").val("");
}

onChangeDepartment(deptId){
    this.setState(
        { deptId },
        () => console.log(`User Option selected:`, this.state.deptId)
      );
      this.setState({
        showUserType:"show-n",
        userType:"",
      });
      $("#userType").val("");
      var selValue=deptId.value;
        var reqparams = {
            dept_code : selValue
        }
      redirectURL.post("/consignments/getUsersByDepartments",reqparams)    
    .then((response) => {
        var records = response.data;
        var items = [];
        if(records.length > 0)
        {
            records.map((item) =>{
                items.push({"value":item._id,"label":item.username})
            })
        }
        console.log("items",items);
        this.setState({
            userType : selValue,
            usersList : items,
            loadshow:'show-n',
            overly:'show-n',
        });        
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onChangeUserType = (event) => {
    var selValue=event.target.value;
    var reqparams = {
        userType : selValue
    }
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    redirectURL.post("/consignments/getUsersByUserType",reqparams)    
    .then((response) => {
        var records = response.data;
        var items = [];
        if(records.length > 0)
        {
            records.map((item) =>{
                items.push({"value":item._id,"label":item.username})
            })
        }
        console.log("items",items);
        this.setState({
            userType : selValue,
            usersList : items,
            loadshow:'show-n',
            overly:'show-n',
        });        
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    
}
onClickFilterReset = () => {
    window.location.reload();
}
onClickFilterUserReport = () => {
    var userId = "";
    if(this.state.userId !='' && this.state.userId !=undefined)
    {
        console.log(this.state.userId.value);
        var userId = this.state.userId.value;
    }
    var deptId = localStorage.getItem("dept_code");
    // if(this.state.deptId !='' && this.state.deptId !=undefined)
    // {
    //     console.log(this.state.deptId.value);
    //     var deptId = this.state.deptId.value;
    // }
    // const userType = $("#userType").val();
    const fromDate = $("#fromDate").val();
    const toDate = $("#toDate").val();
    if(fromDate !='' && toDate !='')
    {
        var reqparams = {
            deptId : deptId,
            userId : userId,
            // userType : userType,
            fromDate : fromDate,
            toDate : toDate,
        }
        console.log("reqparams ", reqparams)
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        redirectURL.post("/consignments/getUserLogReportByParams",reqparams)    
        .then((response) => {
            var records = response.data;
            //console.log("records ", records)
            redirectURL.post("/consignments/fetchUserLogReport",{"userData":records}).then((response)=>{
                console.log(response.data)
                this.setState({
                    rowData : response.data,
                    loadshow:'show-n',
                    overly:'show-n',
                })
            }).catch((e)=>{
                console.log(e);
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        alert("Please select dates to continue");
    }
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'created_on',
          sort: 'desc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}

  render() {
    
    var columnwithDefs = [    
        {
            headerName: "User Name",
            field: "username",
            width:250,
            filter: true,
            resizable: true,
            editable: false, 
            valueGetter:function(params){
                console.log("params ", params)
                try{
                    return params.data.users[0].username;
                }
                catch(e){

                }
            }       
        },
        {
            headerName: "Email",
            field: "email",
            width:250,
            filter: true,
            resizable: true,
            editable: false,        
        },
        {
            headerName: "User Type",
            field: "user_type",
            width:120,
            filter: true,
            resizable: true,
            editable: false,           
        },
        {
            headerName: "Logged in at",
            colId  : "created_on",
            field: "createdon",
            width:150,
            filter: true,
            resizable: true,
            editable: false,
            valueGetter:function(params){
                if(params.data.createdon != ''){
                return getHyphenDDMMMYYYYHHMM(params.data.createdon);
                }
                else{
                return "";
                }
            },
            // filter: "agDateColumnFilter",
            // comparator: dateComparator,
            // filterParams: {
            //     browserDatePicker: true,
            //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            //     // provide comparator function
            //     comparator: function(filteredDate,cellValue,secondDate) {
            //         cellValue = cellValue.replace(/\//g,"-")
            //         if(cellValue != "" && cellValue != " "){
            //             cellValue = cellValue.split(" ")[0].split("-");
            //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            //             var cellDate = new Date(cellValue);
            //             if(filteredDate.getMonth() < 10){
            //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             else{
            //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            //             }
            //             if(filterValue.split("-")[2].length < 2)
            //             {
            //                 var temp = filterValue
            //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            //             }
            //             var filteredDate = new Date(filterValue);
            //             cellDate = cellDate.getTime();
            //             filteredDate = filteredDate.getTime();
            //             // console.log(cellDate,filteredDate);
            //             if(cellDate === filteredDate)
            //             {
            //                 return 0;
            //             }
            //             if(cellDate < filteredDate){
            //                 return -1;
            //             }
            //             if(cellDate > filteredDate)
            //             {
            //                 return 1;
            //             }
            //         }
            //     }  
            // }    
        },
        {
            headerName: "Logged out at",
            field: "loggedOutTime",
            width:150,
            filter: true,
            resizable: true,
            editable: false,
            valueGetter:function(params){
                if(params.data.loggedOutTime != 1){
                return getHyphenDDMMMYYYYHHMM(params.data.loggedOutTime);
                }
                else{
                return "";
                }
            }        
        },
        {
            headerName: "Session Duration (HH:MM:SS)",
            field: "sessionDuration",
            width:140,
            filter: true,
            resizable: true,
            editable: false,          
        },
        {
            headerName: "Logout Type",
            field: "logoutype",
            width:120,
            filter: true,
            resizable: true,
            editable: false,          
        }, 
    ]

    
    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>User Log Report</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="row theme-form">
                                {/* <div class={" col-xl-2 col-lg-2 form-group"}>
                                    <label className="col-xl-12 col-lg-12">Filter By Department </label>
                                    <Select 
                                    placeholder={"Department"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeDepartment.bind(this)}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.departments} />
                                </div> */}
                                {/* <div class={this.state.showUserType+" col-xl-2 col-lg-2 form-group"}>
                                    <label className="col-xl-12 col-lg-12">Filter By User Type </label>
                                    <select onChange={this.onChangeUserType.bind(this)} className={"col-xl-12 col-lg-12 form-control border-radius-0"} id="userType" style={{borderRadius:"0px"}}>
                                        <option value="">Select User Type</option>
                                        <option value="DCTUSER">DCTUSER</option>
                                        <option value="ORGUSER">ORGUSER</option>
                                        <option value="TRANSPORTER">TRANSPORTER</option>
                                    </select>
                                </div> */}
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label className="col-xl-12 col-lg-12">Filter By User </label>
                                    <Select 
                                    placeholder={"Select User"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeUserItem.bind(this)}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.usersList} />
                                </div>
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label className="col-xl-12 col-lg-12">From Date</label>
                                    <input type="text" name="date" id="fromDate" className="datetimepicker_date form-control" />
                                </div>
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label className="col-xl-12 col-lg-12">To Date</label>
                                    <input type="text" name="date" id="toDate" className="datetimepicker_date form-control" />
                                </div>
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <label>&nbsp;</label>
                                    <br />
                                    <button type="submit" onClick={this.onClickFilterUserReport.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <i title="Reset" style={{cursor:"pointer"}} onClick={this.onClickFilterReset.bind(this)} class="icofont icofont-refresh"></i>
                                </div>
                                {/* <div class="col-xl-1 col-lg-1 form-group">
                                    <label>&nbsp;</label>
                                    <br />
                                    <button type="button" onClick={this.onClickFilterReset.bind(this)} class="btn btn-danger">Reset</button>
                                </div> */}
                            </div>
                            {/* <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Holiday</button> */}
                            <SweetAlert
                                show={this.state.show}
                                type={this.state.basicType}
                                title={this.state.basicTitle}
                                onConfirm={this.closeAlert}
                            >
                            </SweetAlert>
                            
                            <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus= {true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection= {true}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                            </div>
                            {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                            
                            <div className={"dataLoadpage " +(this.state.loadshow)}>
                            </div>
                            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                            </div>
                            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                        </div>
                    </div>
                </div>
            </div>

        
            
        </div>
        
       
    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#toDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#toDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#fromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};


