import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

var redirectURL = require('../redirectURL');
export default class PivotdataGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                  {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel"
                  },
                  {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel"
                  }
                ]
            },
            animateRows: true,
            rowData: null,            
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
            frameworkComponents: {
               
            },
            ///usergridstate:[],
            screenurl:"",
            screentitle:"",
            columnwithDefs:[],
        };
        // this.createColumns = this.createColumns.bind(this);
        // this.createRowData = this.createRowData.bind(this);
        
    }
    componentDidMount =async() =>{
        // await redirectURL.post("/consignments/alltruckAvailabledata")
        // .then((response) => {
        //     var records = response.data;
        //     console.log("respnse ", records)
        //     var categories = [];
        //     if(records.length > 0)
        //     {
        //         records.map((item) => {
        //             categories.push({
        //                 category:item.truck_category,
        //                 truck_type:item.truck_type
        //             });
        //            // categories.push(item.truck_category);
        //         })
        //     }
        //     console.log("categoriescategories ", categories)
        //     this.setState({
        //         categories:categories,
        //         transporters:records
        //     })
        // })
        // await this.createColumns();
        // await this.createRowData();
    }

    // createColumns()
    // {
    //     var columns=[]
    //     var transporters = this.state.transporters;
    //     columns.push({
    //         headerName:"Transporter Name",
    //         field:"transporter_name",
    //         width:180
    //     })
    //     var categories = this.state.categories;
    //     console.log("categories ",categories)
    //     if(categories.length > 0)
    //     {
    //         // categories.map((item)=>{
    //         //     var def = {
    //         //         headerName:item
    //         //     }
    //         // })
    //     }

    // }
    // createRowData(){

    // }
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
     }
     
    
    render(){
    	var columnwithDefs  = [
            {
				headerName: "",
				headerClass:["cellstylegridW","cellTopHeader"],			
				children: [
					{
                        headerName: "Cluster",
                        field: "destination",
                        width:130,
                        filter: true,
                        resizable: true,
                        valueGetter: function(params){
                            if(params.data.destination == "Total result")
                            {
                                return "Grand Total";
                            }
                            else
                            {
                                return params.data.destination
                            }
                        }
                    }
				]
            },
            {
				headerName: "Gujarat",
				headerClass:["cellstylegridW","cellTopHeader"],			
				children: [
					{
                        headerName: "No. of Vins",
                        field: "vins_loaded_gujarat",
                        width:130,
                        filter: true,
                        resizable: true,
                        headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
                    },
                    {
                        headerName: "No. of Rakes",
                        field: "rakes_from_gujarat",
                        width:130,
                        filter: true,
                        resizable: true, 
                        headerClass:"cellstylegridW",
						cellClass:'cellstylegridW',
                    }
				]
			},
            {
				headerName: "Haryana",
				headerClass:["cellstylegridB","cellTopHeader"],		
				children: [
					{
                        headerName: "No. of Vins",
                        field: "vins_loaded_haryana",
                        width:130,
                        filter: true,
                        resizable: true,
                        headerClass:"cellstylegridB",
						cellClass:'cellstylegridB' 
                    },
                    {
                        headerName: "No. of Rakes",
                        field: "rakes_from_haryana",
                        width:130,
                        filter: true,
                        resizable: true,
                        headerClass:"cellstylegridB",
						cellClass:'cellstylegridB'
                    }
				]
			},
            {
				headerName: "",
				headerClass:["cellstylegridW","cellTopHeader"],			
				children: [
					{
                        headerName: "Total No. of Vins",
                        field: "total_vins",
                        width:130,
                        filter: true,
                        resizable: true, 
                    }
				]
            },
            {
				headerName: "",
				headerClass:["cellstylegridW","cellTopHeader"],			
				children: [
					{
                        headerName: "Total No. of Rakes",
                        field: "total_rakes",
                        width:130,
                        filter: true,
                        resizable: true, 
                    }
				]
            },
        ]
        
        var columnwithDefsForPivot  = [
            {
                headerName: "Rail Cluster",
                field: "rail_cluster",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Gujarat",
                field: "gujarat_share",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Haryana",
                field: "haryana_share",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Overall Share",
                field: "total_share",
                width:130,
                filter: true,
                resizable: true,           
            },
            {
                headerName: "Rakes planned",
                field: "avg_rakes",
                width:130,
                filter: true,
                resizable: true,           
            },
        ]
        return ( 
        		<div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div id="myGrid" style={{ width: "100%",height:"500px"}}  className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.props.clusterPlantwiseVins}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                            />
                        </div>

                        <div id="myGrid1" style={{ width: "100%",height:"500px", marginTop: "3%"}}  className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForPivot}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.props.clusterPlantwiseRakes}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                            />
                        </div>
                    </div>
      		    </div>
                    
			);
		}
}















