import React from 'react';

const CalledForLoading = props => {

    const calledForLoad = e => {
        e.stopPropagation();
        props.context.componentParent.onClickCalledForLoading(props.data);
    };
    console.log("carrier_reporting_status",props.data.carrier_reporting_status)

    return (
        <div className="map-icon-component">
			     <button onClick={calledForLoad} className="btn btn-success btn-xs" title="Attached Truck">Attached Truck</button>
        </div>
    );
};

export default CalledForLoading;