'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Modal from 'react-responsive-modal';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class ManagementDashboard extends Component {
  constructor(props) {
    super(props);
      this.state = {

        eventAction: null,
        show: false,
        
        basicTitle:'',
        basicType:"default",
        overly:"show-n",
        loadshow:"show-n",
        eventGridAction: "gridAction",
        optionlist:[]
        
    }
    
  }

    componentDidMount(){
        var options = []
        if(this.props.match.path == "/managementdashboard")
        {
            options.push({
                title:"On Time Performance",
                icon:"performance.png",
                url:"/dashboardsummary"
            });
            options.push({
                title:"Imports Vessels Status",
                icon:"bar-chart.png",
                url:"/msilvesselschedule"
            });
            options.push({
                title:"Safety Report (All Locations)",
                icon:"cloudy.png",
                url:"/msilvesselschedule"
            });
            options.push({
                title:"Carrier Arrival Report (SND)",
                icon:"truckmsil.png",
                url:"#"
            });
            // options.push({
            //     title:"Daily Running",
            //     icon:"delivery_truck.png",
            //     url:"/dailyrunningmanagement"
            // });
            // options.push({
            //     title:"Loading Dashboard",
            //     icon:"truckmsil.png",
            //     url:"/loadingdashboard"
            // // });
            // options.push({
            //     title:"Truck Location (Map)",
            //     icon:"truck_inside.png",
            //     url:"/livetrucksinfo"
            // });
           
           
            options.push({
                title:"SND Planning Status",
                icon:"marketing-strategy.png",
                url:"#"
            });
            options.push({
                title:"Rail Stock Status",
                icon:"train-msil.png",
                url:"#"
            });
            
            options.push({
                title:"Logistics Health App Status",
                icon:"heartbeat.png",
                url:"#"
            })
        }
        if(this.props.match.path == "/sndmanagementdashboard")
        {
            options.push({
                title:"Sales and Dispatch Dashboard",
                icon:"performance.png",
                url:"/snddashboardtemplate"
            });
            options.push({
                title:"Daily Running",
                icon:"delivery_truck.png",
                url:"/dailyrunningdashboard"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/sndloadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/sndunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/sndtransitanalytics"
            });
            options.push({
                title:"Transporter Daily Running",
                icon:"delivery_truck.png",
                url:"/sndtransportdashboard"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/sndgpsdashboard"
            });
            options.push({
                title:"On Time Performance",
                icon:"clock.png",
                url:"/ontimedashboard"
            });
        }
        if(this.props.match.path == "/prtmanagementdashboard")
        {
            options.push({
                title:"Spare Parts Dashboard",
                icon:"performance.png",
                url:"/prtdashboardtemplate"
            });
            options.push({
                title:"Daily Running",
                icon:"delivery_truck.png",
                url:"/prtdailyrunningdashboard"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/prtloadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/prtunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/prttransitanalytics"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/prtgpsdashboard"
            });
        }
        if(this.props.match.path == "/tnpmanagementdashboard")
        {
            options.push({
                title:"Trin and Production Dashboard",
                icon:"performance.png",
                url:"/tnpdashboardtemplate"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/tnploadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/tnpunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/tnptransitanalytics"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/tnpgpsdashboard"
            });
        }
        if(this.props.match.path == "/scmanagementdashboard")
        {
            
            options.push({
                title:"Supply Chain Dashboard",
                icon:"performance.png",
                url:"/scdashboardtemplate"
            });
        }

        this.setState({
            optionlist:options
        })
    }
   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        
        });
        
    }


    render(){
        return (
            <div class="container-fluid">   
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12 col-md-12">
                    <div className="card-header">
                        <h5>
                        <span className="float-right f12" style={{marginTop:"-20px"}}> 
                                            <a href="/managementdashboard">Back</a>
                                        </span>
                        </h5>
                    </div>
                    <div className="card-body">
                        
                        <h4>Page is under construction</h4>
                    </div>
                </div>

            </div>
        );
  }
}

