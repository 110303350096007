import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CourierDelayedRemarks = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickGetRemarks(props);
    };
    
//    // if(props.data.msil_transit_delay == 1)
//     //{
//    //     var colr = 'redfont'
//    // }
//    // else{
       
//             var colr = 'txt-secondary'
      
//     //}
    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-success custom-btn f12 label label-success" 
            title="Courier Delay Remarks" >Remarks</button>    
        </div>
    );
};

export default CourierDelayedRemarks;
