
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const CheckboxItem = (props) => {
    
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.context.componentParent.revertSavedItem(props);
    };
    const handleApproveClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.context.componentParent.approveSavedItem(props);
    };
    //console.log("truck_no ", props.data.truck_no)
    //console.log("props.data.billing_status ", props.data.billing_status)
    if(props.data.tpt_billing_status == 1)
    {
        var bstatus = 1
    }
    else if(props.data.tpt_billing_status == 0){
        var bstatus = 0
    }
    return (
        <div>
            {(parseInt(props.data.tpt_confirm_status) == 1 || parseInt(props.data.enmovil_status) == 1)?
                (props.data.tpt_billing_status == 1)?"Approved":"Disapproved"
            :
                (parseInt(props.data.tpt_billing_status) == 1)?
            <button 
                title="Make Disapprove" 
                onClick={handleClick}
                className="btn-pill btn btn-warning btn-xs">
                    Disapprove
            </button>
    
        :
            <button 
                title="Make Approve" 
                onClick={handleApproveClick}
                className="btn-pill btn btn-success btn-xs">
                    Approve
            </button>
        }
           
        </div>
    );
};

export default CheckboxItem;
