
import React, { Component } from 'react';
import deviationIcon from '../../assets/icons/detour.png';

 
const KpiDeviationActions = (props) => {
   
    const handleDeviateClick = (e) => {
        e.stopPropagation();
        console.log("Deviation ",props);
        props.context.componentParent.onShowDeviate(props);
    };

    return (
        <div>
			{([1,1.0,"1","1.0"].includes(props.data.deviation_identified))?
                <img title="Deviation Route" src={deviationIcon} onClick={handleDeviateClick} />
            : 
                <img  title="Deviation Route" src={deviationIcon} style={{opacity:"0.2"}} />
            }
        </div>
    );
};

export default KpiDeviationActions;

