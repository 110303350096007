import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator';
import SaveWagonRow from "./savewagonrow";

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class AddMllTrainUnLoadCheck extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                savewagonrow:SaveWagonRow
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"MLL Train Load Check Sheet",
            sliderRouteTranslate:'',
            file:'',
            csvcontent:[],
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            headerHeight:80,
            formatno:"MLL/AOB/RW/03",
            load_date:"",
            load_start_time:"",
            load_end_time:"",
            loading_station_name:"",
            unloading_station_name:"",
            sourceplants:[],
            destplants:[],
            fnrno:"",
            showform:"show-n",
            fnrvins:[],
            sources:[],
            wagons:[],
            loading_start_time:"",
            loading_date:""
            
        };
        this.onLoadWagonData = this.onLoadWagonData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
	}
	
    componentDidMount(){
        loadDateTimeScript() 
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadWagonData(parameters)

	}
    
    onLoadWagonData(parameters)
    {
        redirectURL.post("/consignments/mllloaddestpoints")
		.then((response) => {
            var records = response.data.records;
            var sourceplants=[]
            var destplants=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    sourceplants.push(item.geofence_name)
                    destplants.push(item.geofence_name)
                })
            }
			this.setState({
                sources:records,
                sourceplants:sourceplants,
                destplants:destplants
			});
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
       redirectURL.post("/consignments/mllwagondata")
		.then((response) => {
            var records = response.data.records;
            var wagons=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    wagons.push(item.wagon_sr_no)
                })
            }
			this.setState({
                wagons:wagons
			});
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
       
		redirectURL.post("/mllconsignments/mlltrainunloadings")
		.then((response) => {
            var records = response.data.records;
            var sheets = response.data.sheets;
            var date_of_placement = ""
            var loading_station_name = "";
            var unloading_station_name = "";
            if(sheets.length > 0)
            {
                var date = getDDMMYYYYHHMMSS(records[0].date_of_placement)
                var sp = date.split(" ");

                loading_station_name = records[0].loading_station_name
                unloading_station_name = records[0].unloading_station_name
                // document.getElementById("loading_date").value = sp[0];
                // document.getElementById("load_start_time").value = sp[1];
            }
			this.setState({
                // rowData:records,
                // loading_station_name:loading_station_name
			});
        })
        redirectURL.post("/consignments/mllfnrnos")
		.then((response) => {
            var records = response.data.records;
            this.setState({
                fnrnos:records
            })
            
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }
    
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",

		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
    
    
    onChangeLoadingStations(loading_station_name){
		this.setState(
			{ loading_station_name },
			() => console.log(`Option selected:`, this.state.loading_station_name)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    
    onChangeUnLoadingStations(unloading_station_name){
		this.setState(
			{ unloading_station_name },
			() => console.log(`Option selected:`, this.state.unloading_station_name)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    // onClickShowView(view)
    // {
    //    // console.log("View ",view)
    //     if(view == "grid")
    //     {
    //         this.setState({
    //             gridview:'show-m',
    //             calcview:'show-n'
    //         })
    //     }
    //     if(view == "calender")
    //     {
    //         this.setState({
    //             gridview:'show-n',
    //             calcview:'show-m',
    //             calview:"timeGridWeek"
    //         })
    //     }
    // }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	// changeFileHandler = async (e) => {
	// 	//console.log(e.target.files[0])
	// 	var dt = '';
	// 	// const config = {
	// 	// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
	// 	// }

	// 	this.setState({
	// 		file:e.target.files[0]
	// 	});
	// 	//console.log("e.target.files[0].type ", e.target.files[0].type);

	// 	// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
	// 	// {
	// 	// }
	// 	// else{
	// 	// 	e.target.value = null;
	// 	// 	this.setState({
	// 	// 		uploadFile:'',
	// 	// 		show: true, 
	// 	// 		basicType:'danger', 
	// 	// 		basicTitle:'Please upload file having extensions .csv only.',
	// 	// 	});
	// 	// }
		
	// }

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
	
	 onAddRow() {
        var newItem = 
        {
            "s_no":"",
            "vin_no":"",
            "wagon_no":"",
            "handover_ba_fuel_qty_sop":"",
            "handover_ba_km_reading_at_station":"",
            "during_loading_key":"",
            "manual_book":"",
            "tool_kit":"",
            "jack_rod":"",
            "floor_mat":"",
            "audio_pannel":"",
            "reflector":"",
            "other_tools":"",
            "key_sealing":"",
            "during_loading_damge_remakers":""

        };
        //console.log("newItem",newItem);
        var res = this.gridApi.updateRowData({ add: [newItem] });
        
    }

    formHandler(rownode)
    {
        
        let records = [rownode];
        // this.gridApi.forEachNode(node => records.push(node.data));
        // console.log("records ",records)
        var formatno = this.state.formatno;
        var loading_date = this.state.loading_date;
        var load_start_time = this.state.load_start_time;
        var load_end_time = document.getElementById("load_end_time").value;
        if(this.state.loading_station_name != "")
        {
            var loading = this.state.loading_station_name.value
            var loading_label = this.state.loading_station_name.label
        }
        else
        {
            var loading = ""
            var loading_label = "";
        }
        var loading_station_name = loading;
        if(this.state.unloading_station_name != "")
        {
            var unloading = this.state.unloading_station_name.value
            var unloading_label = this.state.unloading_station_name.label
        }
        else
        {
            var unloading = ""
            var unloading_label = "";
        }
        var unloading_station_name = unloading;
        if(loading != "" && loading_date != "" && load_start_time != "" && load_end_time != "" && load_end_time != "__:__")
        {
            var parameters = {
                formatno:formatno,
                fnrno:this.state.fnrno.value,
                unloading_date:loading_date,
                unload_start_time:load_start_time,
                unload_end_time:load_end_time,
                loading_station_code:loading_station_name,
                loading_station_name:loading_label,
                unloading_station_code:unloading_station_name,
                unloading_station_name:unloading_label,
                rowitems:records,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username"),
                userid:localStorage.getItem("userid")
            }
            // console.log("parameters ", parameters)
            redirectURL.post("/mllconsignments/savemlltrainunloadsheet",parameters)
            .then((response) => {
                // console.log("Response ", response.data)
                // if(response.data.status == "success")
                // {
                    if(response.data.status == "nowagon")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Wagon data not exists",
                            basicType:"danger"
                        })
                    }
                    else
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Successfully added",
                            basicType:"success"
                        })
                    }
                // }
                // else{
                //     this.setState({
                //         show:true,
                //         basicTitle:"Failed to add  data",
                //         basicType:"danger"
                //     })
                //  }

            })
        }
        else
        {
            this.setState({
                show1:true,
                basicTitle1:"Please choose Unloading End Time",
                basicType1:"danger"
            })
        }
    }

    
    
    onChangeFNRNos(fnrno){
		this.setState(
			{ fnrno },
			() => console.log(`Option selected:`, this.state.fnrno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    
    selectfnrnos(){
		let items = [];    
		var fnrs = this.state.fnrnos
		try{
			if(fnrs.length > 0)
			{
				fnrs.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}
		
		return items;
    }
    
    onClickFNRGetData(){
        if(this.state.fnrno != "")
        {
            var fnrno = this.state.fnrno.value
            var parameters = {
                fnr_no:fnrno
            }
            redirectURL.post("/mllconsignments/mlltrainunloadings", parameters)
            .then((response) => {
                if(response.data.status == "success")
                {
                    var records = response.data.records;
                    var sheets = response.data.sheets;
                    if(records.length > 0)
                    {
                        var edate = moment.parseZone(records[0].rake_delivery_point_reached_on).format("DD-MM-YYYY HH:mm")
                        var sp = edate.split(" ");

                        // $("#loading_date").val(sp[0])
                        // $("#load_start_time").val(sp[1])
                        var vins = []
                        var recarr = []
                        var endtime = '';
                        if(records.length > 0)
                        {
                            records.map((itm, index) => {
                                vins.push(itm.vehicle_id)
                                // if(itm.untrainloadingsheets != undefined)
                                // {
                                //     var trainloadingsheets = itm.untrainloadingsheets;
                                //     if(trainloadingsheets.length > 0)
                                //     {
                                //         endtime = itm.untrainloadingsheets[0].unload_end_time
                                //         recarr.push({
                                //             "s_no":(index+1),
                                //             "vin_no":itm.vehicle_id,
                                //             "wagon_no":trainloadingsheets[0].wagon_no,
                                //             "during_loading_key":trainloadingsheets[0].during_loading_key,
                                //             "handover_ba_fuel_qty_sop":trainloadingsheets[0].handover_ba_fuel_qty_sop,
                                //             "handover_ba_km_reading_at_station":trainloadingsheets[0].handover_ba_km_reading_at_station,
                                //             "manual_book":trainloadingsheets[0].manual_book,
                                //             "tool_kit":trainloadingsheets[0].tool_kit,
                                //             "jack_rod":trainloadingsheets[0].jack_rod,
                                //             "floor_mat":trainloadingsheets[0].floor_mat,
                                //             "audio_pannel":trainloadingsheets[0].audio_pannel,
                                //             "reflector":trainloadingsheets[0].reflector,
                                //             "other_tools":trainloadingsheets[0].other_tools,
                                //             "key_sealing":trainloadingsheets[0].key_sealing,
                                //             "during_loading_damge_remakers":trainloadingsheets[0].during_loading_damge_remakers
                                //         })
                                //     }
                                //     else
                                //     {
                                //         recarr.push({
                                //             "s_no":(index+1),
                                //             "vin_no":itm.vehicle_id,
                                //             "wagon_no":"",
                                //             "handover_ba_fuel_qty_sop":"",
                                //             "handover_ba_km_reading_at_station":"",
                                //             "during_loading_key":"",
                                //             "manual_book":"",
                                //             "tool_kit":"",
                                //             "jack_rod":"",
                                //             "floor_mat":"",
                                //             "audio_pannel":"",
                                //             "reflector":"",
                                //             "other_tools":"",
                                //             "key_sealing":"",
                                //             "during_loading_damge_remakers":""
                                //         })
                                //     }
                                // }
                                // else
                                // {
                                //     recarr.push({
                                //         "s_no":(index+1),
                                //         "vin_no":itm.vehicle_id,
                                //         "wagon_no":"",
                                //         "handover_ba_fuel_qty_sop":"",
                                //         "handover_ba_km_reading_at_station":"",
                                //         "during_loading_key":"",
                                //         "manual_book":"",
                                //         "tool_kit":"",
                                //         "jack_rod":"",
                                //         "floor_mat":"",
                                //         "audio_pannel":"",
                                //         "reflector":"",
                                //         "other_tools":"",
                                //         "key_sealing":"",
                                //         "during_loading_damge_remakers":""
                                //     })
                                // }
                                
                            })


                            if(sheets.length > 0)
                            {
                                sheets.map((itm ,index) => {
                                    endtime = itm.unload_end_time
                                    recarr.push({
                                        "s_no":(index+1),
                                        "vin_no":itm.vin_no,
                                        "wagon_no":itm.wagon_no,
                                        "during_loading_key":itm.during_loading_key,
                                        "handover_ba_fuel_qty_sop":itm.handover_ba_fuel_qty_sop,
                                        "handover_ba_km_reading_at_station":itm.handover_ba_km_reading_at_station,
                                        "manual_book":itm.manual_book,
                                        "tool_kit":itm.tool_kit,
                                        "jack_rod":itm.jack_rod,
                                        "floor_mat":itm.floor_mat,
                                        "audio_pannel":itm.audio_pannel,
                                        "reflector":itm.reflector,
                                        "other_tools":itm.other_tools,
                                        "key_sealing":itm.key_sealing,
                                        "during_loading_damge_remakers":itm.during_loading_damge_remakers
                                    })
                                })
                                    
                            }
                            else
                            {
                                records.map((itm,index) => {
                                    recarr.push({
                                        "s_no":(index+1),
                                        "vin_no":itm.vehicle_id,
                                        "wagon_no":"",
                                        "handover_ba_fuel_qty_sop":"",
                                        "handover_ba_km_reading_at_station":"",
                                        "during_loading_key":"",
                                        "manual_book":"",
                                        "tool_kit":"",
                                        "jack_rod":"",
                                        "floor_mat":"",
                                        "audio_pannel":"",
                                        "reflector":"",
                                        "other_tools":"",
                                        "key_sealing":"",
                                        "during_loading_damge_remakers":""
                                    })
                                })
                                
                            }
                        }
                        document.getElementById("load_end_time").value=endtime
                        this.setState({
                            showform:"show-m",
                            fnrvins:vins,
                            rowData:recarr,
                            loading_date:sp[0],
                            load_start_time:sp[1],
                            loading_station_name:{"value":records[0].rake_loading_yard,"label":records[0].rake_loading_yard_name},
                            unloading_station_name:{"value":records[0].rake_destination_point,"label":records[0].rake_destination_point_name},
                        })
                    }
                    else
                    {
                        this.setState({
                            show1:true,
                            basicTitle1:"No data found",
                            basicType1:"danger"
                        })
                    }
                    
                }
                else
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"No data found",
                        basicType1:"danger"
                    })
                }
                
            })

        }
        else
        {
            this.setState({
                show1:true,
                basicTitle1:"Please choose FNR No",
                basicType1:"danger"
            })
        }
        
    }

    
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
        // }
        
        // vin_no_handover_to_ba 	handover_ba_fuel_qty_sop 	handover_ba_km_reading_at_station 	
        // vehicle_handover_ok_condition 	handover_ba_damage_remarks 	during_loading_vin 	wagon_no
             // during_loading_key 	manual_book 	tool_kit 	jack_rod 	floor_mat 
                 // audio_pannel 	reflector 	other_tools 	key_sealing 	
                //  during_loading_damge_remakers 
// 
		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'wagon_no',
					inputName: 'wagon_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'handover_ba_fuel_qty_sop',
					inputName: 'handover_ba_fuel_qty_sop',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'handover_ba_km_reading_at_station',
					inputName: 'handover_ba_km_reading_at_station',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                
                { 
					name: 'during_loading_key',
					inputName: 'during_loading_key',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'manual_book',
					inputName: 'manual_book',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'tool_kit',
					inputName: 'tool_kit',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'jack_rod',
					inputName: 'jack_rod',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'floor_mat',
					inputName: 'floor_mat',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'audio_pannel',
					inputName: 'audio_pannel',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'reflector',
					inputName: 'reflector',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'other_tools',
					inputName: 'other_tools',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'key_sealing',
					inputName: 'key_sealing',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'during_loading_damge_remakers',
					inputName: 'during_loading_damge_remakers',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
                
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}
    onClickShowBulkUpload(){
        
        var load_end_time = document.getElementById("load_end_time").value;
        console.log("load_end_time ", load_end_time)
        if(load_end_time != "" && load_end_time != "__:__")
        {
            this.setState({
                sliderForceDestination:'slider-translate',
                overly:"show-m"
            })
        }
        else
        {
            this.setState({
                show1:true,
                basicTitle1:"Please choose Unloading End Time",
                basicType1:"danger"
            })
        }
        
    }
    formBulkUpload(event){
        event.preventDefault();
        var records = this.state.csvcontent
        var formatno = this.state.formatno;
        var loading_date = this.state.loading_date;
        var load_start_time = this.state.load_start_time;
        var load_end_time = document.getElementById("load_end_time").value;
        if(this.state.loading_station_name != "")
        {
            var loading = this.state.loading_station_name.value
            var loading_label = this.state.loading_station_name.label
        }
        else
        {
            var loading = ""
            var loading_label = "";
        }
        var loading_station_name = loading;
        if(this.state.unloading_station_name != "")
        {
            var unloading = this.state.unloading_station_name.value
            var unloading_label = this.state.unloading_station_name.label
        }
        else
        {
            var unloading = ""
            var unloading_label = "";
        }
        var unloading_station_name = unloading;
        if(loading != "" && loading_date != "" && load_start_time != "" && load_end_time != "")
        {
            var parameters = {
                formatno:formatno,
                fnrno:this.state.fnrno.value,
                unloading_date:loading_date,
                unload_start_time:load_start_time,
                unload_end_time:load_end_time,
                loading_station_code:loading_station_name,
                loading_station_name:loading_label,
                unloading_station_code:unloading_station_name,
                unloading_station_name:unloading_label,
                rowitems:records,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username"),
                userid:localStorage.getItem("userid")
            }
            // console.log("parameters ", parameters)
            redirectURL.post("/mllconsignments/savemllbulktrainunloadsheet",parameters)
            .then((response) => {
                // console.log("Response ", response.data)
                if(response.data.status == "nodata")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Invalid data uploaded",
                        basicType1:"danger"
                    })
                }
                else if(response.data.status == "notexists")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Uploaded vehicle ids are not exists",
                        basicType1:"danger"
                    })
                }
                else if(response.data.status == "success")
                {
                    // window.location.href = "/mlltrainunloadchecksheet"
                    this.setState({
                        show:true,
                        basicTitle:"Successfully added data",
                        basicType:"success"
                    })
                }
                else
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Failed to add data",
                        basicType1:"danger"
                    })
                }

            })
        }
    }

    render(){
        var columnwithDefs = [
			{
				headerName:"SNo",
				field:"s_no",
				width:80,
                resizable:true,
                editable:false, 
                valueGetter:function(params){
					try{
                       return (parseInt(params.node.id)+1)
                    }
                    catch(e){
                        return "";
                    }
				}
            },
    
            {
                headerName:"VIN No",
                field:"vin_no",
                width:150,
                resizable:true,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: this.state.fnrvins,
                }
            },
            {
                headerName:"Wagon No",
                field:"wagon_no",
                width:150,
                resizable:true,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: this.state.wagons,
                }   
                
            },
            {
                headerName:"Fuel Quantity As per SOP",
                field:"handover_ba_fuel_qty_sop",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            
            {
                headerName:"KM reading At Station",
                field:"handover_ba_km_reading_at_station",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            
            {
                headerName:"Key",
                field:"during_loading_key",
                width:150,
                resizable:true,
                editable:true   
                
            },
            {
                headerName:"Manual book",
                field:"manual_book",
                width:150,
                resizable:true,
                editable:true  
                
            },
            {
                headerName:"Tool Kit",
                field:"tool_kit",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Jack and Jack rod",
                field:"jack_rod",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Floor Mat",
                field:"floor_mat",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Audio Pannel",
                field:"audio_pannel",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Reflector",
                field:"reflector",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Other Tools",
                field:"other_tools",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Key Sealing",
                field:"key_sealing",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Other Damage Remarks",
                field:"during_loading_damge_remakers",
                width:200,
                resizable:true,
                editable:true,
                
            },
            {
                headerName:"Save/Update",
                field:"_id",
                width:140,
                pinned:"right",
                resizable:true,
                editable:false,
                cellRendererSelector:function(params){
                    return {component:"savewagonrow"}
                }
            }
			
		]
       
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                 
            		<div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Train Unloading Check Sheet  </span>
                                    {/* <a href="/mlltrainunloadchecksheet" className="float-right btn btn-danger float-right">Back </a> */}
                               
								</h5>
				   			</div>
				   			<div className="card-body row">
                                   <div className="row col-xl-12 col-lg-12">
                                        <div className="col-xl-6 col-lg-6 form-group">
                                            <label className="col-xl-12 col-lg-12">Select FNR NO:</label>
                                            <div className="col-xl-12 col-lg-12">
                                                <Select 
                                                    placeholder={"Select FNR No"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.fnrno}
                                                    onChange={this.onChangeFNRNos.bind(this)} 
                                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.selectfnrnos()} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 form-group">
                                            <button type="button" 
                                            className="btn btn-warning mt-30p" 
                                            onClick={this.onClickFNRGetData.bind(this)}>Get Data</button>
                                        </div>
                                   </div>
                                    <div className={"col-xl-12 col-lg-12 "+(this.state.showform)}>
                                        <div className="row mb-20p">
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="col-xl-12 col-lg-12">Format No:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    {/* <input 
                                                        type="text" 
                                                        name="formatno" 
                                                        value={this.state.formatno}
                                                        onChange={this.changeHandler.bind(this)}
                                                        className="form-control"
                                                    /> */}
                                                    <span className="form-control">
                                                        {this.state.formatno}
                                                    </span>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div className="row mb-20p">
                                            <div className="col-xl-2 col-lg-2">
                                                <label className="col-xl-12 col-lg-12">Unloading Start Time:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    {/* <input 
                                                        type="text" 
                                                        id="load_start_time" 
                                                        name="loading_start_time" 
                                                        className="datetimepicker_mask form-control" 
                                                        required 
                                                    /> */}
                                                    <span className="form-control">{this.state.load_start_time}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-2 col-lg-2">
                                                <label className="col-xl-12 col-lg-12">Unloading End Time:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        id="load_end_time" 
                                                        name="load_end_time" 
                                                        className="datetimepicker_mask form-control" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="col-xl-12 col-lg-12">Loading Name:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    {/* <Select 
                                                        placeholder={"Select Loading Station"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.loading_station_name}
                                                        onChange={this.onChangeLoadingStations.bind(this)} 
                                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                                        style={{borderRadius:"0px"}}
                                                        options={this.selectsources()} 
                                                    /> */}
                                                    <span className="form-control">{this.state.loading_station_name.label}</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3">
                                                <label className="col-xl-12 col-lg-12">Destination:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    {/* <Select 
                                                        placeholder={"Select UnLoading"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.unloading_station_name}
                                                        onChange={this.onChangeUnLoadingStations.bind(this)} 
                                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                                        style={{borderRadius:"0px"}}
                                                        options={this.selectsources()} 
                                                    />
                                     */}
                                                    <span className="form-control">{this.state.unloading_station_name.label}</span> 
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-2">
                                                <label className="col-xl-12 col-lg-12">Unloading Date:</label>
                                                <div className="col-xl-12 col-lg-12">
                                                    {/* <input 
                                                        type="text" 
                                                        id="loading_date" 
                                                        name="loading_date" 
                                                        className="datetimepicker_mask form-control" 
                                                        required 
                                                    /> */}
                                                    <span className="form-control">{this.state.loading_date}</span>
                                                </div>
                                            </div>
                                    
                                           

                                            <div className={"col-xl-12 col-lg-12 mb-20p mt-30p"}>
                                                {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                                <button type="button" onClick={this.onClickShowBulkUpload.bind(this)} className="btn btn-danger float-right">Bulk Upload</button>
                                             </div>
                                            
                                        </div>
                                    </div>
                                       
                                
                                    <div className={"col-xl-12 col-lg-12 "+(this.state.showform)}>
                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                headerHeight={this.state.headerHeight}
                                                frameworkComponents={this.state.frameworkComponents}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                masterDetail={true}
                                            />
                                            {/* <button type="button" onClick={this.formHandler.bind(this)} className="btn btn-success float-right">Save</button> */}
                                        </div>
                                    </div>
                              
                                </div>
				   		    </div>	
				   	    </div>
				 </div>

				

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            
                <div className={"slide-r "+(this.state.sliderForceDestination)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpload.bind(this)}>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_bulk_trainunloading.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	// $('#loading_date').datetimepicker({
	// 	mask:'39-19-9999',
	// 	format:'d-m-Y',
	// 	timepicker:false
    // });
    // $('#load_start_time').datetimepicker({
	// 	mask:'29:59',
    //     format:'H:i',
    //     datepicker:false,
	// 	timepicker:true
	// });
    $('#load_end_time').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false,
		timepicker:true
	});


	$(".styles_modal__gNwvD").css({width:"450px"})
};

document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#load_end_time').datetimepicker({
		mask:'29:59',
		format:'H:i',
        datepicker:false,
		timepicker:true
    });
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}