import React, { Component } from "react";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import './ErrorMessage.css'; // Import CSS file for styling
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { successLogin: 0 };
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);
            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {

                        this.setState({
                            "successLogin": 1,
                        })

                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });


							localStorage.setItem('token', response.data.token);
							localStorage.setItem('userid', response.data.userid);
							let deptCode = JSON.stringify(response.data.user.dept_code);
							localStorage.setItem('dept_code', deptCode);
							localStorage.setItem('is_admin', response.data.user.is_admin);
							localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
							localStorage.setItem('is_global', response.data.user.is_global);
							localStorage.setItem('firstname', response.data.user.firstname);
							localStorage.setItem('username', response.data.user.username);
							localStorage.setItem('lastname', response.data.user.lastname);
							var useremailat = response.data.user.email.replace("[at]", "@")
							var useremail = useremailat.replace("[dot]", ".")
							localStorage.setItem('email', useremail);
							localStorage.setItem('user_type', response.data.user.user_type);							
							localStorage.setItem('prt_transporter_code', JSON.stringify(response.data.user.prt_transporter_code));
							localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
							localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
							var transporterCode = ""
							if( response.data.user.transporter_code!=undefined&& response.data.user.transporter_code!=null && response.data.user.transporter_code!=""){
								var transporterCode =  JSON.stringify(response.data.user.transporter_code)
							}
							localStorage.setItem('transportercode', transporterCode);
							localStorage.setItem('roles', response.data.user.roles);
							localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
							localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
							localStorage.setItem('cha_code', response.data.user.cha_code);
							localStorage.setItem('system_password_change', response.data.user.system_password_change);
							var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
							var m = md(JSON.stringify(response.data.user))
							localStorage.setItem('m', m);
							localStorage.setItem('supplier_code', response.data.user.supplier_code);
							try {
								localStorage.setItem('zone', response.data.user.zone);
								if (response.data.user.vehicle_group != undefined) {
									localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
								}
								else {
									localStorage.setItem('vehicle_group', "");
								}
							}
							catch (e) { }
							try {
								if (response.data.user.user_type == "COURIER_CONSIGNEE") {						
									let consigneeCode = response.data.user.consignee_code;							
									localStorage.setItem('consignee_code', consigneeCode);
									console.log("consigneeCodeOnLogin", consigneeCode)
								}
							} catch(e) {
								console.log("Setting Consignee Code to Local Storage Error", e)
							}
							sessionStorage.setItem('ref', 1);

                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg,
                            "successLogin": 1,
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {

		
		let dashboardUrl = "dashboard/default";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0];
		} else {
			currentDepartmentCode = departmentCode;
		}
		var userroles = localStorage.getItem("roles");
		if (this.props.is_load_management == 1) {
			var userroles = localStorage.getItem("roles");
			dashboardUrl = "/lmwelcome";
		}
		else {
			switch (currentDepartmentCode) {
				case "SNDG":
					if (localStorage.getItem("user_type") == 'TRANSPORTER') {
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_rail") >= 0) {
							dashboardUrl = "/railconsignments";
						}
						else if (userroles.indexOf("transporter_tvp_warehouse") >= 0) {
							dashboardUrl = "/tvpTransshipment";
						}
						else {
							dashboardUrl = "/sndtransporterconsignments";
						}
					}
					else if(localStorage.getItem('user_type') == 'ZONE'){
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("zone_wise") >= 0) {
							dashboardUrl = "/atdealershipdashboard";
						}
					}
					else {
						var userroles = localStorage.getItem("roles");
						try {

							if (userroles.indexOf("AG2") >= 0) {
								dashboardUrl = "/sndconsignments";
							}
							else if (userroles.indexOf("parking") >= 0) {
								dashboardUrl = "/sndparkings";
							}
							else if (userroles.indexOf("parking_prt") >= 0) {
								dashboardUrl = "/prtparkings";
							}
							else if (userroles.indexOf("led") >= 0) {
								dashboardUrl = "/livemaptrucklocation";
							}
							else if (userroles.indexOf("GPSUSER") >= 0) {
								dashboardUrl = "/mlltruckgps";
							}
							else if (userroles.indexOf("Plant") >= 0 || userroles.indexOf("RSO") >= 0
								|| userroles.indexOf("Loading Officer") >= 0
								|| userroles.indexOf("Unloading Officer") >= 0
								|| userroles.indexOf("BA") >= 0) {
								dashboardUrl = "/mllsummary";
							}
							else {
								dashboardUrl = "/sndtrucks";
							}
						}
						catch (e) {
							dashboardUrl = "/sndtrucks";
						}
					}
					break;

				case "LOG-PRT":
					//dashboardUrl="/dashboard/prtdefault";
					if (localStorage.getItem("user_type") == 'TRANSPORTER' ) {
						var userroles = localStorage.getItem("roles");
							dashboardUrl = "/prtconsignments";
					}
					else if (localStorage.getItem("roles") == "PACC") {
						dashboardUrl = "/prtallconsignments";
					}
					else {
						dashboardUrl = "/prtconsignments";
					}

					break;
					case "prt_transporter":
						dashboardUrl = "/prtconsignments";
					break;
				case "LOG-TNP":
					//dashboardUrl="/dashboard/tnpdefault";
					var userroles = localStorage.getItem("roles");
					if (userroles.indexOf("TNP DDVM") > 0) {
						dashboardUrl = "/managementdashboard";
					}
					else if (userroles.indexOf("tnp_rp") >= 0) {
						dashboardUrl = "/returnemptypallets";
					}
					else {
						dashboardUrl = "/tnpdashboardtemplate";
					}
					break;
				case "LOG-SC":
					//dashboardUrl="/dashboard/scdefault";
					if (localStorage.getItem("user_type") == 'TRANSPORTER') {
						var userroles = localStorage.getItem("roles");
						dashboardUrl = "/prtconsignments";
					}
					else if (localStorage.getItem("user_type") == 'COURIER_CONSIGNEE') {
						var userroles = localStorage.getItem("roles");
						dashboardUrl = "/prtconsignments";
					}
					else {
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("sc_ppc") >= 0) {
							dashboardUrl = "/imports/consignments";
						}
						else if (userroles.indexOf("sc_exports") >= 0) {
							dashboardUrl = "/exports/consignments";
						}
						else if (userroles.indexOf("actl") >= 0) {
							dashboardUrl = "/actlcontainers";
						}
						else if (userroles.indexOf("sc_container_alloys") >= 0) {
							dashboardUrl = "/imports/consignments";
						}
						else if (userroles.indexOf("air_cargo_form") >= 0) {
							dashboardUrl = "/aircargoform";
						}
						else if (userroles.indexOf("ff_air_cargo") >= 0) {
							dashboardUrl = "/ffaircargos";
						}
						else if (userroles.indexOf("cha_air_cargo") >= 0) {
							dashboardUrl = "/chaaircargos";
						}
						else if (userroles.indexOf("transportation_air_exports_cargo") >= 0) {
							dashboardUrl = "/transportationaircargoexports";
						}
						else if (userroles.indexOf("cha_air_exports_cargo") >= 0) {
							dashboardUrl = "/chaaircargoexports";
						}
						else if (userroles.indexOf("ff_air_exports_cargo") >= 0) {
							dashboardUrl = "/ffaircargoexports";
						}
						else if (userroles.indexOf("air_cargo_exports_form") >= 0 || userroles.indexOf("air_exports_form") >= 0) {
							dashboardUrl = "/aircargoexportsform";
						}
						else {
							dashboardUrl = "/imports/consignments";
						}
					}


					break;
				case "SUP-CHN":
					//dashboardUrl="/dashboard/scdefault";
					dashboardUrl = "/imports/consignments";
					break;

				case "Customs Coils":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl = "/chacoils";
					break;
				case "Customs Containers":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl = "/chanhavasheva";
					break;
				case "AD":
					//dashboardUrl="/dashboard/scdefault";
					var userroles = localStorage.getItem("roles");
					if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0) {
						dashboardUrl = "/lmloadsummary";
					}
					else if (userroles.indexOf("SITE_ADMIN") >= 0 || userroles.indexOf("SR MGMT") >= 0) {
						dashboardUrl = "/managementdashboard";
					}

					else if (userroles.indexOf("MahindraAdmin") >= 0) {
						dashboardUrl = "/mllsummary";
					}
					else {
						dashboardUrl = "/livetrucksinfo";
					}
					break;
				case "LM":
					var userroles = localStorage.getItem("roles");
					//var usermenus = localStorage.getItem("usermenus");
					// if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
					// {

					// }
					if (userroles.indexOf("lm_site_admin") >= 0) {
						dashboardUrl = "/lmdepartments";
					}
					else {
						dashboardUrl = "/lmwelcome";
					}
					break;
				case "SND DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl = "/dashboardsummary";
					break;
				case "TNP DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl = "/dashboardsummary";
					break;

				case "PRT DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl = "/dashboardsummary";
					break;

				case "SC DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl = "/dashboardsummary";
					break;
				default:
					let isTmsUser = false;
					currentDepartmentCode = currentDepartmentCode.trim();
					if (currentDepartmentCode !== "SA") {
						// TMS users don't belong to any department
						let userMenuCodes = JSON.parse(localStorage.getItem("usermenucodes"));
						if (Array.isArray(userMenuCodes)) {
							isTmsUser = userMenuCodes.includes("tms");
						} else if (userMenuCodes == "tms") {
							isTmsUser = true;
						}
					} else {
						console.log("IN default, ELSE, currentDepartmentCode = ", currentDepartmentCode);
					}
					if (isTmsUser) {
						dashboardUrl = "/ticketmanagementsystem";
					} else {
						dashboardUrl = "/dashboard/default";
					}

			}
		}

		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    render() {
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            var successLogin = this.state.successLogin;

            if (successLogin == 1) {
                let dashboardUrl = this.getUserDashboardUrl();

                

                return <Redirect to={dashboardUrl} push={true} />
            }
        }
        return (
            <div>
                {/* Loader ends */}
                {(this.state.loginMessage)?
                <div className="error-container">
                    {/* SVG for error icon */}
                    <svg
                    className="error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        fill="red"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v8h-2zm0 10h2v2h-2z"
                    />
                    </svg>
                    {/* Error message */}
                    <p className="error-message">{this.state.loginMessage}</p>
                </div>
                :
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default EnmUserLogin;