import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from "jquery";
var redirectURL = require('../../redirectURL');



export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            distance:0,
            duration:0,
            nightRules : "",
            dept_code:"",
            short_haul_enroute_duration : 30,
            short_haul_distance_covered : 5,
            long_haul_enroute_duration : 120,
            long_haul_distance_covered : 5,
            short_haul_no_entry_rules : "",
            long_haul_no_entry_rules : "",
            short_haul_night_rules : "",
            long_haul_night_rules : "",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
        };
        
		
    }
    componentDidMount(){
        var dept_code = this.props.deptCode;
        // var dept_code = "SNDG";
        var formdata = {
            config_type:'enroutestoppages',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                if (dept_code != "LOG-TNP")
                {
                    this.setState({
                        duration:response.data[0].min_stoppage_duration,
                        // time:(response.data[0].threshold_time/60),
                        distance : response.data[0].min_distance_travelled,
                        nightRules : response.data[0].night_driving_rules,
                        dept_code : dept_code
                    });
                }
                else
                {
                    this.setState({
                        short_haul_enroute_duration : response.data[0].short_haul_enroute_duration,
                        short_haul_distance_covered : response.data[0].short_haul_distance_covered,
                        long_haul_enroute_duration : response.data[0].long_haul_enroute_duration,
                        long_haul_distance_covered : response.data[0].long_haul_distance_covered,
                        short_haul_night_rules : response.data[0].short_haul_night_driving_rules,
                        short_haul_no_entry_rules : response.data[0].short_haul_no_entry_rules,
                        long_haul_night_rules  : response.data[0].long_haul_night_driving_rules,
                        long_haul_no_entry_rules  : response.data[0].long_haul_no_entry_rules,
                        dept_code : dept_code
                    });
                }
                
            }
            else
            {
                this.setState({
                    duration:"",
                    // time:(response.data[0].threshold_time/60),
                    distance : "",
                    nightRules : "",
                    dept_code : dept_code
                });
            }
            console.log(typeof(this.state.duration),this.state.distance,this.state.nightRules);

        })
    }
   
	formHandler = (event) => {
        event.preventDefault(); 
        if(this.state.dept_code == "LOG-TNP")
        {
            var short_haul_enroute_duration = document.getElementById("short_haul_enroute_duration").value;
            var short_haul_distance_covered = document.getElementById("short_haul_distance_covered").value;
            var long_haul_enroute_duration = document.getElementById("long_haul_enroute_duration").value;
            var long_haul_distance_covered = document.getElementById("long_haul_distance_covered").value;
            var sndrules = $('input[name=sndrules]:checked', '.formid').val()
            var snoentryrules = $('input[name=snoentry]:checked', '.formid').val()
            var lndrules = $('input[name=lndrules]:checked', '.formid').val()
            var lnoentryrules = $('input[name=lnoentry]:checked', '.formid').val()
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                config_type:"enroutestoppages",
                short_haul_enroute_duration : short_haul_enroute_duration,
                short_haul_distance_covered : short_haul_distance_covered,
                long_haul_enroute_duration : long_haul_enroute_duration,
                long_haul_distance_covered : long_haul_distance_covered,
                short_haul_night_driving_rules : sndrules,
                short_haul_noentryrules : snoentryrules,
                long_haul_night_driving_rules : lndrules,
                long_haul_noentryrules : lnoentryrules
                // threshold_time:(this.state.time*60)
            }
        }
        else
        {
            var duration = document.getElementById("durationID").value;
            var distance = document.getElementById("distanceID").value;
            var ndrules = $('input[name=ndrules]:checked', '.formid').val()
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                min_stoppage_duration:duration,
                config_type:"enroutestoppages",
                min_distance_travelled:distance,
                night_driving_rules : ndrules
                // threshold_time:(this.state.time*60)
            }
        }
        console.log(formdata)
        redirectURL.post('/manage/saveESKPIConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            var res = response.data;
            if(res.result.result.ok == 1)
            {
                this.props.context.showAlert();
            }
            else{
                this.props.context.showErrorAlert();
            }
        })
    }
    checkDuration = (event) =>{
        console.log(Number(event.target.value));
        // if((event.target.value))
        this.setState({
            duration : event.target.value
        })
    }
    checkInput = (event) =>{
        this.setState({
            distance : event.target.value,
        })
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
	 render() {
         let nightDrivingRules;
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h5>Enroute Stoppages Settings</h5>
                </div>
                <form className="formid theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    {(this.state.dept_code != "LOG-TNP")?
                        <div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Minimum Stoppage Duration (Hours) *:</label>
                                    <input type="number" name="duration" min="1" id="durationID" value={this.state.duration} className="form-control validateNumber" placeholder="Duration in Hours" autoComplete="off" onChange={this.checkDuration.bind(this)} required />
                                </div>
                                <div className="error-msg" style={{color:"red",fontsize:"14px"}}></div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Minimum Distance Travelled for Exception Closure (KM)*:</label>
                                    <input type="number" name="distanace" id="distanceID" value={this.state.distance} autoComplete="off" className="form-control validateNumber1" placeholder="Distance In KM" onChange={this.checkInput.bind(this)} required />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                        
                                <div className="form-group">
                                    <label className="c-lbl">Night driving rules to be applied * : </label>
                                    {(this.state.nightRules != "")?
                                        <span>
                                            {(this.state.nightRules == "yes")?
                                                <span>
                                                &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="yes" checked /> Yes 
                                                &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="no" /> No 
                                                </span>
                                            :
                                                <span>
                                                    &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="yes" /> Yes 
                                                    &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="no" checked /> No 
                                                </span>
                                            }
                                            
                                        </span>
                                    :
                                        <span>
                                            &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="yes" /> Yes 
                                            &nbsp; <input type="radio" name="ndrules" id="ndrulesID" value="no" /> No 
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    :
                        <div>
                            <label className="c-lbl col-xl-12 col-lg-12"><b>Short Haul</b></label>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Enroute Duration (mins) *:</label>
                                    <input type="number" name="duration" min="30" id="short_haul_enroute_duration" value={this.state.short_haul_enroute_duration} onChange={e => this.setState({"short_haul_enroute_duration" :e.target.value})} className="form-control validateNumber" placeholder="Duration in Hours" autoComplete="off" required />
                                </div>
                                <div className="error-msg" style={{color:"red",fontsize:"14px"}}></div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Distance Covered (kms)*:</label>
                                    <input type="number" min="5" name="distanace" id="short_haul_distance_covered" value={this.state.short_haul_distance_covered} autoComplete="off" onChange={e => this.setState({"short_haul_distance_covered" :e.target.value})} className="form-control validateNumber1" placeholder="Distance In KM" required />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                        
                                <div className="form-group">
                                    <label className="c-lbl">Night driving rules to be applied * : </label>
                                    {(this.state.short_haul_night_rules != "")?
                                        <span>
                                            {(this.state.short_haul_night_rules == "yes")?
                                                <span>
                                                &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="yes" checked /> Yes 
                                                &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="no" /> No 
                                                </span>
                                            :
                                                <span>
                                                    &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="yes" /> Yes 
                                                    &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="no" checked /> No 
                                                </span>
                                            }
                                            
                                        </span>
                                    :
                                        <span>
                                            &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="yes" /> Yes 
                                            &nbsp; <input type="radio" name="sndrules" id="sndrulesID" value="no" /> No 
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">No entry timings to be applied * : </label>
                                    {(this.state.short_haul_no_entry_rules != "")?
                                        <span>
                                            {(this.state.short_haul_no_entry_rules == "yes")?
                                                <span>
                                                    &nbsp; <input type="radio" name="snoentry" id="snoentry" value="yes" checked /> Yes 
                                                    &nbsp; <input type="radio" name="snoentry" id="snoentry" value="no"/> No
                                                </span>
                                            :
                                                <span>
                                                    &nbsp; <input type="radio" name="snoentry" id="snoentry" value="yes" /> Yes 
                                                    &nbsp; <input type="radio" name="snoentry" id="snoentry" value="no" checked/> No
                                                </span>
                                            }
                                            
                                        </span>
                                    :
                                        <span>
                                            &nbsp; <input type="radio" name="snoentry" id="snoentry" value="yes"  /> Yes 
                                            &nbsp; <input type="radio" name="snoentry" id="snoentry" value="no" /> No
                                        </span>
                                    }
                                </div>
                            </div>
                            <label className="c-lbl col-xl-12 col-lg-12"><b>Long Haul</b></label>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Enroute Duration (mins) *:</label>
                                    <input type="number" name="duration" min="120" id="long_haul_enroute_duration" value={this.state.long_haul_enroute_duration} className="form-control validateNumber" onChange={e => this.setState({"long_haul_enroute_duration" :e.target.value})} placeholder="Duration in Hours" autoComplete="off"  required />
                                </div>
                                <div className="error-msg" style={{color:"red",fontsize:"14px"}}></div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">Distance Covered (kms)*:</label>
                                    <input type="number" name="distanace" min="5" id="long_haul_distance_covered" value={this.state.long_haul_distance_covered} autoComplete="off" onChange={e => this.setState({"long_haul_distance_covered" :e.target.value})} className="form-control validateNumber1" placeholder="Distance In KM"
                                     required />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                        
                                <div className="form-group">
                                    <label className="c-lbl">Night driving rules to be applied * : </label>
                                    {(this.state.long_haul_night_rules != "")?
                                        <span>
                                            {(this.state.long_haul_night_rules == "yes")?
                                                <span>
                                                &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="yes" checked /> Yes 
                                                &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="no" /> No 
                                                </span>
                                            :
                                                <span>
                                                    &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="yes" /> Yes 
                                                    &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="no" checked /> No 
                                                </span>
                                            }
                                            
                                        </span>
                                    :
                                        <span>
                                            &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="yes" /> Yes 
                                            &nbsp; <input type="radio" name="lndrules" id="lndrulesID" value="no" /> No 
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                    <label className="c-lbl">No entry timings to be applied * : </label>
                                    {(this.state.long_haul_no_entry_rules != "")?
                                        <span>
                                            {(this.state.long_haul_no_entry_rules == "yes")?
                                                <span>
                                                    &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="yes" checked /> Yes 
                                                    &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="no"/> No
                                                </span>
                                            :
                                                <span>
                                                    &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="yes" /> Yes 
                                                    &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="no" checked/> No
                                                </span>
                                            }
                                            
                                        </span>
                                    :
                                        <span>
                                            &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="yes"  /> Yes 
                                            &nbsp; <input type="radio" name="lnoentry" id="lnoentry" value="no" /> No
                                        </span>
                                    }
                                </div>
                            </div>
                            
                        </div>
                    }
                    
                    
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}
$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1))
        }
    });
    $(document).on('keyup',".validateNumber1",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1))
        }
    });
    $(document).on("keyup","#durationID","#distanceID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
        else if($(this).val() == ""){
            $(".error-msg").empty();
        }
        else{
            $(".error-msg").empty();
        }
    })
    $(document).on("keyup","#distanceID",function(){
        if($(this).val() == 0){
            // alert("Value Should be Greater than 0");
            // $(".error-msg").empty();
            // $(".error-msg").append("Value Should be greater than 0");
            $(this).val(" ");
        }
        else if($(this).val() == ""){
            $(".error-msg").empty();
        }
        else{
            $(".error-msg").empty();
        }
    })
});

