
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Containerlegmap = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.agGridReact.props.gridOptions.context.componentParent.onShowLegRouteMap(props.data);
    };

    return (
        <div>
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f20"></i> </button>
       
        </div>
    );
};

export default Containerlegmap;
