import React from 'react';
 
const UpdateAction = (props) => {

    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCloseUploadDiv(e);
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClickUpdateGridData(props.data);
    };

    return (
        <div className="map-icon-component">
            {(props.data.is_parking_out != 1)?<button onClick={handleRouteClick} className="btn btn-success btn-xs" title="Update">Update </button>
            :""}
        </div>
    );
};

export default UpdateAction;