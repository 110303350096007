import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText,Alert, UncontrolledAlert } from 'reactstrap';
import _ from "lodash";
import Select from 'react-select';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname:'',
            lastname:'',
            email:'',
            password:'',
            dept_code:'',
            role:'',
            alldepartments:[],
            alertFade:true,
            formMessage:'',
            formStatus:'',
            loadingports : [],
            seletedLoadingPort: [],
        };
        this.changeHandler=this.changeHandler.bind(this);
        this.departmentDropdownOptions = this.departmentDropdownOptions.bind(this);
        this.formHandler = this.formHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        redirectURL.post('/lmconsignees/getLmDepartments',{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
        .then((response) => {
            this.setState({
                alldepartments:response.data
            });
        })
        .catch((error) => {
            console.log(error)
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then((response)=>{
            //console.log(response.data);
            var lps = response.data;
            var lpsNames = [];
            if(lps.length > 0)
            {
                lps.map((item) =>{
                    lpsNames.push({
                        value:item.loading_port_code,
                        label:item.city,
                    })
                })
            }
            this.setState({
                loadingports : lpsNames
            })
        }).catch((e)=>{
            console.log(e);
        })
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
    departmentDropdownOptions(){
        var options=[];
        this.state.alldepartments.map((item) =>{
            options.push(<option key={item.department_name} value={item.department_name}>{item.department_name}</option>)
        });
        return options;
    }
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    formHandler(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);
        var lpc = this.state.seletedLoadingPort;
        var data = new FormData();
        data.append('firstname',this.state.firstname)
        data.append('lastname',this.state.lastname)
        data.append('email',this.state.email)
        data.append('password',this.state.password)
        data.append('dept_code',this.state.dept_code)
        var loading_port_code = []
        lpc.map(function(item){
            loading_port_code.push(item.value)
        })
        data.append('loading_port_code',loading_port_code)
       // console.log("Form data ",data);
       var email = this.state.email.replace(/\s/g,"").toLowerCase();
       var formdata = {
        firstname:this.state.firstname,
        lastname:this.state.lastname,
        email: email,
        password:this.state.password,
        dept_code:this.state.dept_code,
       }
       console.log("formdata ", formdata);
        redirectURL.post('/lmconsignees/saveLoadManagementUser',formdata)
        .then((response) => {
            this.setState({
                formMessage:response.data.message,
                formStatus:response.data.status,
                alertFade:false,
                firstname:'',
                lastname:'',
                email:'',
                password:'',
                dept_code:'',
                role:'',
            });
        
        })
        .catch((error) => {
            console.log(error)
        })
    }
    onChangeLoadingPort(seletedLoadingPort){
        this.setState(
            { seletedLoadingPort },
            () => console.log(`Loading Port Option selected:`, this.state.seletedLoadingPort)
        );      
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={{ backgroundImage: "url("+background+")" }}>
                                        <div className="text-center">
                                        <img src={require('../../assets/icons/logo.png')}
                                                    className="logo-login" style={{width:'75%'}} alt=""/>
                                               <hr/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3 className="text-center">NEW USER</h3>
                                            <h6 className="text-center">Enter your Username and Password For Login or Signup</h6>
                                            <div className="card mt-4 p-4">
                                            
                                                {this.state.formStatus != ""?
                                                        <div>
                                                                {(this.state.formStatus == 'Success')?
                                                                    <Alert color="secondary" fade={this.state.alertFade}>
                                                                        
                                                                    <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                    {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                                </Alert>
                                                                :
                                                                <Alert color="danger" fade={this.state.alertFade}>
                                                                    <i className="icon-alert"></i>  {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                </Alert>
                                                                }
                                                        </div>
                                                    :""
                                                }
                                                <form className="theme-form" onSubmit={this.formHandler}>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">First Name</label>
                                                                <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeHandler} placeholder="Enter First Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Last Name</label>
                                                                <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeHandler} placeholder="Enter Last Name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Email Address</label>
                                                        <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeHandler} placeholder="Email Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input type="password" required className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} placeholder="**********"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Department</label>
                                                        <div className="form-row">
                                                            <div className="col-sm-12">
                                                                <select required className="form-control mb-1" name="dept_code" value={this.state.dept_code} onChange={this.changeHandler}>
                                                                    <option value=''>Select</option>
                                                                    {this.departmentDropdownOptions()}
                                                                </select>
                                                            </div>
                                                             
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Department</label>
                                                        <div className="form-row">
                                                            <div className="col-sm-12">
                                                            <Select required
                                                                placeholder={"Select Loading Port"}
                                                                isMulti={true}
                                                                closeMenuOnSelect={true}
                                                                onChange={this.onChangeLoadingPort.bind(this)} 
                                                                className={"border-radius-0"}
                                                                style={{borderRadius:"0px"}}
                                                                value={this.state.seletedLoadingPort}
                                                                options={this.state.loadingports} />
                                                            </div>
                                                             
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-sm-3">
                                                            <button type="submit" className="btn btn-secondary">Register
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="text-left mt-2 m-l-20">
                                                                Are you already user?&nbsp;&nbsp;<a href={"/"} className="btn-link text-capitalize">Login</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default Register;