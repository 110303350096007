import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import download from 'js-file-download';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditFFCargoEvent extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            loadetdshow:'show-n',
            loadatdshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoFFId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            ringi_file : "",
            other_docs : "",
            erpData : [{}],
            cargoData : [{}],
            ringiData: [{}],
            airlines: [],
            pagetype: 1
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getAirCargosAirlines").then((response)=>{
            this.setState({
                airlines : response.data
            });
        })

        redirectURL.post("/consignments/getAirCargosByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
            var erpData=[{}];
            if(response.data[0].erpData !='' && response.data[0].erpData !=undefined)
            {
                var erpData = JSON.parse(response.data[0].erpData);
            }
            var cargoData=[{}];
            if(response.data[0].cargoData !='' && response.data[0].cargoData !=undefined)
            {
                var cargoData = JSON.parse(response.data[0].cargoData);
            }
            var cargo_date ="";
            if(response.data[0].cargo_date !=undefined && response.data[0].cargo_date !='')
            {
                cargo_date = response.data[0].cargo_date;
                if(cargo_date.includes("T") == true)
                {
                    cargo_date = cargo_date.split("T");
                    cargo_date = cargo_date[0];
                    cargo_date = cargo_date.split("-")[2]+"-"+cargo_date.split("-")[1]+"-"+cargo_date.split("-")[0];
                }
            }
			this.setState({
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,
                erpPO : response.data[0].erpPO,
                erpData : erpData,
                cargo_delivery_plant : response.data[0].cargo_delivery_plant,
                no_of_packets : response.data[0].no_of_packets,
                cargoData : cargoData,
                supplier_name : response.data[0].supplier_name,
                supplier_address : response.data[0].supplier_address,
                supplier_contact_person : response.data[0].supplier_contact_person,
                country : response.data[0].country,
                email : response.data[0].email,
                phone : response.data[0].phone,
                delivery_terms : response.data[0].delivery_terms,
                pick_up_address : response.data[0].pick_up_address,
                port : response.data[0].port,
                airport_name : response.data[0].airport_name,
                cargo_date : cargo_date,
                lc : response.data[0].lc,
                lc_no : response.data[0].lc_no,
                nature : response.data[0].nature,
                free_trade : response.data[0].free_trade,
                benefit_certificate : response.data[0].benefit_certificate
            })
            
            if(response.data[0].counterpart_details !=undefined && response.data[0].counterpart_details !="undefined")
            {
                $("#counterpart_details").val(response.data[0].counterpart_details);
            }
            
            var cargo_reciving_date ="";
            if(response.data[0].cargo_reciving_date !=undefined && response.data[0].cargo_reciving_date !='')
            {
                cargo_reciving_date = response.data[0].cargo_reciving_date;
                if(cargo_reciving_date.includes("T") == true)
                {
                    cargo_reciving_date = cargo_reciving_date.split("T");
                    cargo_reciving_date = cargo_reciving_date[0];
                    cargo_reciving_date = cargo_reciving_date.split("-")[2]+"-"+cargo_reciving_date.split("-")[1]+"-"+cargo_reciving_date.split("-")[0];
                }
            }
            $("#cargo_reciving_date").val(cargo_reciving_date);
            $("#airlines_name").val(response.data[0].airlines_name);
            $('#direct_flight option[value='+response.data[0].direct_flight+']').attr("selected", "selected");
            if(response.data[0].direct_flight == "no")
            {
                this.setState({loadshow : ""});
                // if(response.data[0].etd_port !='')
                // {
                //     $("#etd_port").attr("disabled",true);
                // }
                // if(response.data[0].etd_port_date !='')
                // {
                //     $("#etd_port_date").attr("disabled",true);
                // }
                // if(response.data[0].eta_transit_port !='')
                // {
                //     $("#eta_transit_port").attr("disabled",true);
                // }
                // if(response.data[0].eta_transit_port_date !='')
                // {
                //     $("#eta_transit_port_date").attr("disabled",true);
                // }
                // if(response.data[0].etd_transit_port !='')
                // {
                //     $("#etd_transit_port").attr("disabled",true);
                // }
                // if(response.data[0].etd_transit_port_date !='')
                // {
                //     $("#etd_transit_port_date").attr("disabled",true);
                // }
                // if(response.data[0].eta_igi_airport !='')
                // {
                //     $("#eta_igi_airport").attr("disabled",true);
                // }
                // if(response.data[0].eta_igi_airport_date !='')
                // {
                //     $("#eta_igi_airport_date").attr("disabled",true);
                // }
            }
            var pickup_date ="";
            if(response.data[0].pickup_date !=undefined && response.data[0].pickup_date !='')
            {
                pickup_date = response.data[0].pickup_date;
                if(pickup_date.includes("T") == true)
                {
                    pickup_date = pickup_date.split("T");
                    pickup_date = pickup_date[0];
                    pickup_date = pickup_date.split("-")[2]+"-"+pickup_date.split("-")[1]+"-"+pickup_date.split("-")[0];
                }
            }
            var etd_port_date ="";
            if(response.data[0].etd_port_date !=undefined && response.data[0].etd_port_date !='')
            {
                etd_port_date = response.data[0].etd_port_date;
                if(etd_port_date.includes("T") == true)
                {
                    etd_port_date = etd_port_date.split("T");
                    etd_port_date = etd_port_date[0];
                    etd_port_date = etd_port_date.split("-")[2]+"-"+etd_port_date.split("-")[1]+"-"+etd_port_date.split("-")[0];
                }
            }
            var atd_port_date ="";
            if(response.data[0].atd_port_date !=undefined && response.data[0].atd_port_date !='')
            {
                atd_port_date = response.data[0].atd_port_date;
                if(atd_port_date.includes("T") == true)
                {
                    atd_port_date = atd_port_date.split("T");
                    atd_port_date = atd_port_date[0];
                    atd_port_date = atd_port_date.split("-")[2]+"-"+atd_port_date.split("-")[1]+"-"+atd_port_date.split("-")[0];
                }
            }
            
            if(etd_port_date !=atd_port_date)
            {
                this.setState({
                    loadetdshow:'show-m',
                });
            }
            else
            {
                this.setState({
                    loadetdshow:'show-n',
                });
            }
            var eta_igi_airport_date="";
            if(response.data[0].eta_igi_airport_date !=undefined && response.data[0].eta_igi_airport_date !='')
            {
                eta_igi_airport_date = response.data[0].eta_igi_airport_date;
                if(eta_igi_airport_date.includes("T") == true)
                {
                    eta_igi_airport_date = eta_igi_airport_date.split("T");
                    eta_igi_airport_date = eta_igi_airport_date[0];
                    eta_igi_airport_date = eta_igi_airport_date.split("-")[2]+"-"+eta_igi_airport_date.split("-")[1]+"-"+eta_igi_airport_date.split("-")[0];
                }
            }
            var ata_igi_airport_date="";
            if(response.data[0].ata_igi_airport_date !=undefined && response.data[0].ata_igi_airport_date !='')
            {
                ata_igi_airport_date = response.data[0].ata_igi_airport_date;
                if(ata_igi_airport_date.includes("T") == true)
                {
                    ata_igi_airport_date = ata_igi_airport_date.split("T");
                    ata_igi_airport_date = ata_igi_airport_date[0];
                    ata_igi_airport_date = ata_igi_airport_date.split("-")[2]+"-"+ata_igi_airport_date.split("-")[1]+"-"+ata_igi_airport_date.split("-")[0];
                }
            }
            if(eta_igi_airport_date !=ata_igi_airport_date)
            {
                this.setState({
                    loadatdshow:'show-m',
                });
            }
            else
            {
                this.setState({
                    loadatdshow:'show-n',
                });
            }
            var eta_transit_port_date="";
            if(response.data[0].eta_transit_port_date !=undefined && response.data[0].eta_transit_port_date !='')
            {
                eta_transit_port_date = response.data[0].eta_transit_port_date;
                if(eta_transit_port_date.includes("T") == true)
                {
                    eta_transit_port_date = eta_transit_port_date.split("T");
                    eta_transit_port_date = eta_transit_port_date[0];
                    eta_transit_port_date = eta_transit_port_date.split("-")[2]+"-"+eta_transit_port_date.split("-")[1]+"-"+eta_transit_port_date.split("-")[0];
                }
            }
            var ata_transit_port_date="";
            if(response.data[0].ata_transit_port_date !=undefined && response.data[0].ata_transit_port_date !='')
            {
                ata_transit_port_date = response.data[0].ata_transit_port_date;
                if(ata_transit_port_date.includes("T") == true)
                {
                    ata_transit_port_date = ata_transit_port_date.split("T");
                    ata_transit_port_date = ata_transit_port_date[0];
                    ata_transit_port_date = ata_transit_port_date.split("-")[2]+"-"+ata_transit_port_date.split("-")[1]+"-"+ata_transit_port_date.split("-")[0];
                }
            }
            var etd_transit_port_date="";
            if(response.data[0].etd_transit_port_date !=undefined && response.data[0].etd_transit_port_date !='')
            {
                etd_transit_port_date = response.data[0].etd_transit_port_date;
                if(etd_transit_port_date.includes("T") == true)
                {
                    etd_transit_port_date = etd_transit_port_date.split("T");
                    etd_transit_port_date = etd_transit_port_date[0];
                    etd_transit_port_date = etd_transit_port_date.split("-")[2]+"-"+etd_transit_port_date.split("-")[1]+"-"+etd_transit_port_date.split("-")[0];
                }
            }
            var atd_transit_port_date="";
            if(response.data[0].atd_transit_port_date !=undefined && response.data[0].atd_transit_port_date !='')
            {
                atd_transit_port_date = response.data[0].atd_transit_port_date;
                if(atd_transit_port_date.includes("T") == true)
                {
                    atd_transit_port_date = atd_transit_port_date.split("T");
                    atd_transit_port_date = atd_transit_port_date[0];
                    atd_transit_port_date = atd_transit_port_date.split("-")[2]+"-"+atd_transit_port_date.split("-")[1]+"-"+atd_transit_port_date.split("-")[0];
                }
            }
            var hawb_date="";
            if(response.data[0].hawb_date !=undefined && response.data[0].hawb_date !='')
            {
                hawb_date = response.data[0].hawb_date;
                if(hawb_date.includes("T") == true)
                {
                    hawb_date = hawb_date.split("T");
                    hawb_date = hawb_date[0];
                    hawb_date = hawb_date.split("-")[2]+"-"+hawb_date.split("-")[1]+"-"+hawb_date.split("-")[0];
                }
            }
            $("#port_name").val(response.data[0].port_name);
            $("#pickup_date").val(pickup_date);
            $("#etd_port_date").val(etd_port_date);
            $("#atd_port_date").val(atd_port_date);
            $("#eta_igi_airport_date").val(eta_igi_airport_date);
            $("#ata_igi_airport_date").val(ata_igi_airport_date);
            $("#eta_transit_port_date").val(eta_transit_port_date);
            $("#ata_transit_port_date").val(ata_transit_port_date);
            $("#etd_transit_port_date").val(etd_transit_port_date);
            $("#atd_transit_port_date").val(atd_transit_port_date);
            $("#if_etd_atd_not_same").val(response.data[0].if_etd_atd_not_same);
            $("#if_atd_ata_not_same").val(response.data[0].if_atd_ata_not_same);
            $("#mawb_no").val(response.data[0].mawb_no);
            $("#hawb_no").val(response.data[0].hawb_no);
            $("#hawb_date").val(hawb_date);
            $("#no_of_pkt").val(response.data[0].no_of_pkt);
            $("#gross_wt").val(response.data[0].gross_wt);
            $("#chargeble_weight").val(response.data[0].chargeble_weight);
            $("#invoice_no").val(response.data[0].invoice_no);
            //$("#invoice_currency").val(response.data[0].invoice_currency);
            $("#airfreight_amount").val(response.data[0].airfreight_amount);
            $("#fca_exw_amount").val(response.data[0].fca_exw_amount);
            $("#airfreight_currency").val(response.data[0].airfreight_currency);
            $("#otr_docs").html(response.data[0].other_docs);
            var ringiData=[{}];
            if(response.data[0].invoiceData !=undefined && response.data[0].invoiceData !="")
            {
                var ringiData = JSON.parse(response.data[0].invoiceData);
            }
            this.setState({
                hiddeninvoice : response.data[0].invoice_value,
                hiddenotherdocs :response.data[0].other_docs,
                ringiData : ringiData,
            })
            // $("#hidden_invoice_value").val(response.data[0].invoice_value);
            // $("#hidden_other_docs").val(response.data[0].other_docs);
            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            //console.log(resparams)
            // redirectURL.post("/consignments/getAirCargosFreightForwardDetailsById",resparams).then((response1)=>{
            //     console.log(response1.data);
            //     this.setState({
            //         airCargoFFId : response.data[0]._id,
            //     });
                

            // }).catch(function(error){
            //     console.log(error);
            // })
        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/ffaircargos";
    }
    else
    {
        window.location.href="/aircargos";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
    if(name == "direct_flight")
    {   
        if(value == "yes")
        {
            this.setState({
                loadshow : "show-n"
            })
        }else{
            this.setState({
                loadshow : ""
            })
        }
    }
}
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
    };
    this.setState({
        ringiData: [...this.state.ringiData, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringiData.splice(idx, 1);
    this.setState({ ringiData: this.state.ringiData });
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
        }).catch(function(error){
            console.log(error);
        })
    }
};

handleRemoveRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/removeRingiFile",rngParam).then((response)=>{
            $(".rnFile_"+idx).html("");
            this.hide();
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

formHandler(event){
    event.preventDefault();
    var pickupdate = $("#pickup_date").val();
    if(pickupdate == "__-__-____" || pickupdate == undefined)
    {
        var pickupdate="";
    }
    var etd_port_date = $("#etd_port_date").val();
    if(etd_port_date == "__-__-____" || etd_port_date == undefined)
    {
        var etd_port_date="";
    }
    var atd_port_date = $("#atd_port_date").val();
    if(atd_port_date == "__-__-____" || atd_port_date == undefined)
    {
        var atd_port_date="";
    }
    var eta_igi_airport_date = $("#eta_igi_airport_date").val();
    if(eta_igi_airport_date == "__-__-____" || eta_igi_airport_date == undefined)
    {
        var eta_igi_airport_date="";
    }
    var ata_igi_airport_date = $("#ata_igi_airport_date").val();
    if(ata_igi_airport_date == "__-__-____" || ata_igi_airport_date == undefined)
    {
        var ata_igi_airport_date="";
    }
    var eta_transit_port_date = $("#eta_transit_port_date").val();
    if(eta_transit_port_date == "__-__-____" || eta_transit_port_date == undefined)
    {
        var eta_transit_port_date="";
    }
    var ata_transit_port_date = $("#ata_transit_port_date").val();
    if(ata_transit_port_date == "__-__-____" || ata_transit_port_date == undefined)
    {
        var ata_transit_port_date="";
    }
    var etd_transit_port_date = $("#etd_transit_port_date").val();
    if(etd_transit_port_date == "__-__-____" || etd_transit_port_date == undefined)
    {
        var etd_transit_port_date="";
    }
    var atd_transit_port_date = $("#atd_transit_port_date").val();
    if(atd_transit_port_date == "__-__-____" || atd_transit_port_date == undefined)
    {
        var atd_transit_port_date="";
    }
    var hawb_date = $("#hawb_date").val();
    if(hawb_date == "__-__-____" || hawb_date == undefined)
    {
        var hawb_date="";
    }
    var cargo_reciving_date = $("#cargo_reciving_date").val();
    if(cargo_reciving_date == "__-__-____" || cargo_reciving_date == undefined)
    {
        var cargo_reciving_date="";
    }
    var counterpart_details = $("#counterpart_details").val();
    var airlines_name = $("#airlines_name").val();
    var direct_flight = $("#direct_flight").val();
    var port_name = $("#port_name").val();
    var if_etd_atd_not_same = $("#if_etd_atd_not_same").val();
    var if_atd_ata_not_same = $("#if_atd_ata_not_same").val();
    var mawb_no = $("#mawb_no").val();
    var hawb_no = $("#hawb_no").val();
    var no_of_pkt = $("#no_of_pkt").val();
    var gross_wt = $("#gross_wt").val();
    var chargeble_weight = $("#chargeble_weight").val();
    var invoice_no = $("#invoice_no").val();
    //var invoice_currency = $("#invoice_currency").val();
    var airfreight_amount = $("#airfreight_amount").val();
    var fca_exw_amount = $("#fca_exw_amount").val();
    var airfreight_currency = $("#airfreight_currency").val();
    var hidden_invoice_value = $("#hidden_invoice_value").val();
    var hidden_other_docs = $("#hidden_other_docs").val();

    let saveData = new FormData();
    //console.log("file ",this.state.file)
    
    var rngLength=$("#rngLength").val();
    console.log(rngLength,"rngLength")
    saveData.append("rngLength", rngLength);
    saveData.append("file", this.state.ringi_file);
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        if(ringiFile !='')
        {
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
        else
        {
            var ringiFile=$("#hiddenRingiFile_"+r).val();
            var rngtemp = {"invoice_file":ringiFile}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    console.log(ringiData,"ringiData")
    saveData.append("other_docs", this.state.other_docs);
    for(var rn=0;rn<rngLength;rn++)
    {
        var ringiFile="ringi_file_"+rn;
        
        saveData.append(ringiFile,this.state[ringiFile]);
        console.log(this.state[ringiFile]);

        var hdringiFile = $("#hiddenRingiFile_"+rn).val();
        var hdringiName = "hiddenRingiFile_"+rn;
        saveData.append(hdringiName,hdringiFile);
        console.log(hdringiFile);
    }
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    saveData.append("pickup_date", pickupdate);
    saveData.append("counterpart_details", counterpart_details);
    saveData.append("airlines_name", airlines_name);
    saveData.append("direct_flight", direct_flight);
    saveData.append("port_name", port_name);
    saveData.append("etd_port_date", etd_port_date);
    saveData.append("atd_port_date", atd_port_date);
    saveData.append("eta_igi_airport_date", eta_igi_airport_date);
    saveData.append("ata_igi_airport_date", ata_igi_airport_date);
    saveData.append("eta_transit_port_date", eta_transit_port_date);
    saveData.append("ata_transit_port_date", ata_transit_port_date);
    saveData.append("etd_transit_port_date", etd_transit_port_date);
    saveData.append("atd_transit_port_date", atd_transit_port_date);
    saveData.append("if_etd_atd_not_same", if_etd_atd_not_same);
    saveData.append("if_atd_ata_not_same", if_atd_ata_not_same);
    saveData.append("mawb_no", mawb_no);
    saveData.append("hawb_no", hawb_no);
    saveData.append("hawb_date", hawb_date);
    saveData.append("no_of_pkt", no_of_pkt);
    saveData.append("gross_wt", gross_wt);
    saveData.append("chargeble_weight", chargeble_weight);
    saveData.append("invoice_no", invoice_no);
    saveData.append("ringiData",ringiData);
    //saveData.append("invoice_currency", invoice_currency);
    saveData.append("airfreight_amount", airfreight_amount);
    saveData.append("fca_exw_amount", fca_exw_amount);
    saveData.append("airfreight_currency", airfreight_currency);
    saveData.append("cargo_reciving_date", cargo_reciving_date);
    saveData.append("airCargoFFId", this.state.airCargoFFId);
    saveData.append("hidden_invoice_value",hidden_invoice_value);
    saveData.append("hidden_other_docs",hidden_other_docs);
    saveData.append("updated_by_email",localStorage.getItem("email"));
    //console.log(this.state.airCargoFFId);
    
    redirectURL.post("/consignments/saveffCargoData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}


render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO : </label>
                                            <input className="form-control" value={this.state.erpPO} disabled />
                                        </div>
                                    </div>
                                    {this.state.erpData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO No : </label>
                                            <input type="text" value={item.erpPoNo} className="form-control" disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO Date : </label>
                                            <input type="text" value={item.erPoDate} className="form-control" disabled />
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Delivery Plant : </label>
                                            <input className="form-control" value={this.state.cargo_delivery_plant} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No Of Packets : </label>
                                            <input className="form-control" value={this.state.no_of_packets} disabled />
                                        </div>
                                    </div>
                                    {this.state.cargoData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Item Description : </label>
                                            <input type="text" value={item.item_description} className="form-control" disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight : </label>
                                            <input type="text" value={item.cargo_gross_weight} className="form-control" disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Dimensions (cms) : </label>
                                            <input type="text" value={item.dimensions} className="form-control" disabled/>
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Name : </label>
                                            <input className="form-control" type="text" value={this.state.supplier_name} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Address : </label>
                                            <textarea className="form-control" value={this.state.supplier_address} disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Contact Person : </label>
                                            <input className="form-control" type="text" value={this.state.supplier_contact_person} disabled />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Country : </label>
                                            <input className="form-control" type="text" value={this.state.country} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Email ID : </label>
                                            <input className="form-control" type="text" value={this.state.email} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Phone No : </label>
                                            <input className="form-control" type="text" value={this.state.phone} disabled />
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Delivery Terms : </label>
                                            <input className="form-control" type="text" value={this.state.delivery_terms} disabled />
                                        </div>
                                        {(this.state.delivery_terms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick Up Address : </label>
                                                <textarea className="form-control" value={this.state.pick_up_address} disabled/>
                                            </div>
                                        :(this.state.delivery_terms == "FCA")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                                <input className="form-control" type="text" value={this.state.port} disabled />
                                            </div>
                                        :
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Airport Name : </label>
                                                <input className="form-control" type="text" value={this.state.airport_name} disabled />
                                            </div>
                                        }
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date : </label>
                                            <input className="form-control" type="text" value={this.state.cargo_date} disabled />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LC Available : </label>
                                            <input className="form-control" type="text" value={this.state.lc} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">LC No : </label>
                                            <input className="form-control" type="text" value={this.state.lc_no} disabled />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Nature : </label>
                                            <input className="form-control" type="text" value={this.state.nature} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Free Trade Agreement : </label>
                                            <input className="form-control" type="text" value={this.state.free_trade} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">R&D Benefit Certificate : </label>
                                            <input className="form-control" type="text" value={this.state.benefit_certificate} disabled />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Counterpart Details : </label>
                                            <input type="text" name="counterpart_details" id="counterpart_details" className="form-control" required onChange={this.changeHandler} />
                                        </div>
                                        {(this.state.delivery_terms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Pick up date : </label>
                                                <input type="text" name="pickup_date" id="pickup_date" className="datetimepicker_date form-control"  onChange={this.changeHandler} />
                                            </div>                                        
                                        :
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Cargo reciving date : </label>
                                                <input type="text" name="cargo_reciving_date" id="cargo_reciving_date" className="datetimepicker_date form-control" disabled={this.state.disableERP}  onChange={this.changeHandler} />
                                            </div>
                                        }
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Airlines Name : </label>
                                            <select name="airlines_name" id="airlines_name" className="form-control"  onChange={this.changeHandler}>
                                                <option value="">Select Airlines</option>
                                                {this.state.airlines.map(function(m){
                                                    return <option value={m.airlines_name}>{m.airlines_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Direct Flight : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="direct_flight" id="direct_flight"  onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No (In direct Flight)</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Port Name: </label>
                                            <input type="text" name="port_name" id="port_name" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETD Port date : </label>
                                            <input type="text" name="etd_port_date" id="etd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATD Port Date : </label>
                                            <input type="text" name="atd_port_date" id="atd_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ETA Transit Port Date : </label>
                                            <input type="text" name="eta_transit_port_date" id="eta_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ATA Transit Port Date : </label>
                                            <input type="text" name="ata_transit_port_date" id="ata_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ETD Transit Port Date : </label>
                                            <input type="text" name="etd_transit_port_date" id="etd_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+(this.state.loadshow)}>
                                            <label className="col-xl-12 col-lg-12">ATD Transit Port Date : </label>
                                            <input type="text" name="atd_transit_port_date" id="atd_transit_port_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>                                      
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ETA IGI Airport Date : </label>
                                            <input type="text" name="eta_igi_airport_date" id="eta_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">ATA IGI Airport Date : </label>
                                            <input type="text" name="ata_igi_airport_date" id="ata_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                   
                                    <div className="row col-xl-12">
                                        <div id="etdSame" className={"col-xl-6 "+(this.state.loadetdshow)}>
                                            <label className="col-xl-12 col-lg-12">IF ETD and ATD not same : </label>
                                            <textarea name="if_etd_atd_not_same" id="if_etd_atd_not_same" className="form-control"  onChange={this.changeHandler}></textarea>
                                        </div>
                                        <div id="atdSame" className={"col-xl-6 "+(this.state.loadatdshow)}>
                                            <label className="col-xl-12 col-lg-12">If ETA and ATA not same : </label>
                                            <textarea name="if_atd_ata_not_same" id="if_atd_ata_not_same" className="form-control" onChange={this.changeHandler}></textarea>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>

                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MAWB No : </label>
                                            <input type="text" name="mawb_no" id="mawb_no" className="form-control num-cls"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HAWB No : </label>
                                            <input type="text" name="hawb_no" id="hawb_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">HAWB date : </label>
                                            <input type="text" name="hawb_date" id="hawb_date" placeholder="" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No. of pkt : </label>
                                            <input type="text" name="no_of_pkt" id="no_of_pkt" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Gross wt(In Kgs) : </label>
                                            <input type="text" name="gross_wt" id="gross_wt" className="form-control" placeholder="In Kgs"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Chargeable Weight(In Kgs) : </label>
                                            <input type="text" name="chargeble_weight" id="chargeble_weight" placeholder="in kg" className="form-control"  onChange={this.changeHandler} />
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice No : </label>
                                            <input type="text" className="form-control" name="invoice_no" id="invoice_no"  onChange={this.changeHandler} />
                                        </div>
                                        {this.state.ringiData.map((item, idx) => (
                                        <div className="col-xl-4" key={idx}>
                                            <div className="col-xl-12">
                                                <label className="col-xl-12 col-lg-12">Pre alert documents (Invoice AWD &#38; Tracking) : </label>
                                                <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control" onChange={this.changeFileHandler} />

                                                {((item.invoice_file != "") && (item.invoice_file != undefined)) ?  
                                                <div className="row col-xl-12">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile_"+idx}>{item.invoice_file}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                        
                                                        {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.invoice_file))}>Test </a> */}
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleRemoveRingiFile(idx)}><i title="Delete" className="fa fa-trash"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.invoice_file} />
                                                </div>
                                                :
                                                ""
                                                }
                                                {/* <FileBase64
                                                onDone={ this.changeFileHandler.bind(this) } /> */}
                                            </div>
                                            <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                                <h6 style={{marginTop:"1em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                        ))}
                                        <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                        <div class="row col-sm-12">
                                            <div className="row col-xl-12 m-top-1">
                                                <div class="col-xl-12">
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="hr-dashed"></div>
                                        {/* <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Invoice Currency : </label>
                                            <input type="text" name="invoice_currency" id="invoice_currency" className="form-control"  onChange={this.changeHandler} />
                                        </div> */}
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Air freight Amount : </label>
                                            <input type="text" name="airfreight_amount" id="airfreight_amount" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">FCA/EXW Amount : </label>
                                            <input type="text" name="fca_exw_amount" id="fca_exw_amount" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Air freight Currecny : </label>
                                            <input type="text" name="airfreight_currency" id="airfreight_currency" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Any other docs : </label>
                                            <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} />
                                            <div id="otr_docs"></div>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <input type="hidden" name="hidden_invoice_value" id="hidden_invoice_value" value={this.state.hiddeninvoice}/>
                                        <input type="hidden" name="hidden_other_docs" id="hidden_other_docs" value={this.state.hiddenotherdocs}/>
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
        timepicker:false,
        onClose: function(datesel) {
            var etd_port_date = $("#etd_port_date").val();
            var atd_port_date = $("#atd_port_date").val();
            var eta_igi_airport_date = $("#eta_igi_airport_date").val();
            var ata_igi_airport_date = $("#ata_igi_airport_date").val();
            console.log(etd_port_date,"etd_port_date");
            console.log(atd_port_date,"atd_port_date");
            console.log(eta_igi_airport_date,"eta_igi_airport_date");
            console.log(ata_igi_airport_date,"ata_igi_airport_date");
            if(eta_igi_airport_date !=ata_igi_airport_date)
            {
                $("#atdSame").removeClass("show-n");
                $("#atdSame").addClass("show-m");
            }
            else
            {
                $("#atdSame").removeClass("show-m");
                $("#atdSame").addClass("show-n");
                $("#if_atd_ata_not_same").val("");
            }
            
            if(etd_port_date !=atd_port_date)
            {
                $("#etdSame").removeClass("show-n");
                $("#etdSame").addClass("show-m");
            }
            else
            {
                $("#etdSame").removeClass("show-m");
                $("#etdSame").addClass("show-n");
                $("#if_etd_atd_not_same").val("");
            }
        }
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

$(document).on("input", "#hawb_no", function() {
    if (this.value.match(/[^a-zA-Z0-9 ]/g)) {
        this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }
});

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});