import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class SeaImportsForm extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            erpPO:"",
            erpPoNo:[],
            erpPoDate:[],
            ringiNo:"",
            ringiDesc:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            cargoDate:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            benefitCert : "",
            disableERP:"disabled",
            disableLC:"disabled",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            erpPo_date :"",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            fclFields:"show-m",
            request_type:1,
            pagetitle:"Booking Request (Sea Imports - FCL)",
            msds_file : "",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        var pagetitle = "Booking Request (Sea Imports - FCL)";
        $(".requestFields").attr("required",true);
        this.setState({
            pagetitle : pagetitle,
        }) 
        $(".prepaidFields").removeAttr("required",true);
        $("#no_of_packets").val(1);
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        $("#dateField").val(date);
        $("#addMrERp").hide();
        redirectURL.post("/consignments/aircargosuppliers").then((response)=>{
            var ffsuppliers = response.data.ffsuppliers;
            console.log(ffsuppliers,"ffsuppliers")
            this.setState({
                ffsuppliers : ffsuppliers,
            }) 
        }).catch((e)=>{
            console.log(e);
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == "erpPO")
    {   
        if(value == "yes")
        {
            $("#addMrERp").show();
            this.setState({
                disableERP : ""
            })
        }else{
            $("#addMrERp").hide();
            this.setState({
                disableERP : "disabled"
            })
        }
    }
    if(name == "LC")
    {
        if(value == "yes")
        {
            this.setState({
                disableLC : ""
            })
            //$("#LCno").prop("required",true);
        }else{
            this.setState({
                disableLC : "disabled"
            })
            //$("#LCno").removeAttr("required",true);
        }
    }
    // if(name == "deliveryTerms")
    // {
    //     $(".dterms").removeAttr("required",true);
    //     if(value == "Ex Works")
    //     {
    //         $("#pickUpAddr").prop("required",true);
    //     }
    //     else if(value == "FCA")
    //     {
    //         $("#port").prop("required",true);
    //     }
    //     else if(value == "FOB")
    //     {
    //         $("#airportName").prop("required",true);
    //     }
    // }
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var eventTarget = event.target;
    //console.log(this.state.file,"this.state.file");
    var request_type = this.state.request_type;
    var dateField=$("#dateField").val();
    //console.log(dateField);
    if(dateField == "__-__-____")
    {
        dateField = "";
    }
    
    var erPoDate=$("#erpPoDate").val();
    if(erPoDate == "__-__-____")
    {
        erPoDate = "";
    }
    
    var cargoDate=$("#cargoDate").val();
    if(cargoDate == "__-__-____")
    {
        cargoDate = "";
    }

    var eta_igi_airport_date=$("#eta_igi_airport_date").val();

    var erpLength=$("#erpLength").val();
    var erpData=[];
    for(var i=0;i<erpLength;i++)
    {
        var erpno=$("#erpPoNo_"+i).val();
        var erpDate=$("#erpPoDate_"+i).val();
        if(erpno !='' || erpDate !='')
        {
            if(erpDate !=undefined)
            {
                erpDate = erpDate.split("-")[2]+"-"+erpDate.split("-")[1]+"-"+erpDate.split("-")[0];
                erpDate = moment.parseZone(new Date(erpDate+" 00:00:00")).utcOffset("+05:30")._d
            }
            var erptemp = {"erpPoNo":erpno,"erPoDate":erpDate}
            erpData.push(erptemp)
        }
    }
    var rngLength=$("#rngLength").val();
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        var ringiDesc=$("#ringiDesc_"+r).val();
        if(ringiFile !='' || ringiDesc !='')
        {
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
    }
    ringiData=JSON.stringify(ringiData);
    erpData=JSON.stringify(erpData);
    var formfields = {
        date : dateField,
        dept_name : this.state.deptName,
        indenter_name : this.state.indenterName,
        mobile : $("#mobile").val(),
        indenter_email : this.state.indenterEmail,
        erpPo_date : erPoDate,
        cargo_delivery_plant : this.state.cargoDeliveryPlant,
        supplier_name : this.state.supplierName,
        supplier_address : this.state.supplierAddr,
        supplier_contact_person : this.state.supplierCnctPerson,
        country : this.state.country,
        email : this.state.emailID,
        phone : this.state.phone,
        delivery_terms : this.state.deliveryTerms,
        pick_up_address : this.state.pickUpAddr,
        port : this.state.port,
        airport_name : this.state.airportName,
        cargo_date : cargoDate,
        lc : this.state.LC,
        lc_no : this.state.LCno,
        nature : this.state.nature,
        free_trade : this.state.freeTrade,
        benefit_certificate : this.state.benefitCert,
        port_of_dispatch : this.state.port_of_dispatch,
    }
    
    var flag= 0;
    var emailflag = 0;
    for(let key in formfields)
    {
        if(request_type == 1)
        {
            if(key != "no_of_packets" && key != "erpPO" && key != "lc" && key !="lc_no" && key !="erpPo_date" && key !="port" && key !="airport_name" && key !="mobile" && key !="lc no" && key !="pick_up_address")
            {
                if(formfields[key] !=undefined && formfields[key] !='')
                {                
                    if(formfields[key].replace(/\s/g,"").length == 0)
                    {
                        flag = 1;
                        console.log(key,formfields[key]);
                    }
                }
                else
                {
                    flag = 1;
                    console.log(key,formfields[key]);
                }
            }
            else if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
            else
            {
            }
        }
        else
        {
            if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
        }
    }
    if(flag == 0)
    {
        if(emailflag == 0)
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            })
            // console.log(ringiNo)
            let saveData = new FormData();
            saveData.append("request_type", request_type);
            saveData.append("rngLength", rngLength);
            for(var rn=0;rn<rngLength;rn++)
            {
                var ringiFile="ringi_file_"+rn;
                
                saveData.append(ringiFile,this.state[ringiFile]);
                console.log(this.state[ringiFile]);
            }
            saveData.append("date",dateField);
            saveData.append("dept_name",this.state.deptName);
            saveData.append("indenter_name",this.state.indenterName);
            saveData.append("mobile",$("#mobile").val());
            saveData.append("indenter_email",this.state.indenterEmail);
            saveData.append("erpPO",this.state.erpPO);
            saveData.append("erpData",erpData);
            saveData.append("ringiData",ringiData);
            saveData.append("item_desc",this.state.item_desc);
            saveData.append("gross_weight",this.state.gross_weight);
            saveData.append("dimensions_of_package",this.state.dimensions_of_package);
            saveData.append("gross_cargo_volume",this.state.gross_cargo_volume);
            saveData.append("size_of_container",this.state.size_of_container);
            saveData.append("no_of_containers",this.state.no_of_containers);
            saveData.append("shipment_type",this.state.shipment_type);
            saveData.append("cargo_type",this.state.cargo_type);
            saveData.append("msds_file",this.state.msds_file);
            saveData.append("type_of_container",this.state.type_of_container);
            saveData.append("handling_type",this.state.handling_type);
            saveData.append("type_of_special_handling",this.state.type_of_special_handling);
            saveData.append("cargo_delivery_plant",this.state.cargoDeliveryPlant);
            saveData.append("supplier_name",this.state.supplierName);
            saveData.append("supplier_address",this.state.supplierAddr);
            saveData.append("supplier_contact_person",this.state.supplierCnctPerson);
            saveData.append("country",this.state.country);
            saveData.append("email",this.state.emailID);
            saveData.append("phone",this.state.phone);
            saveData.append("delivery_terms",this.state.deliveryTerms);
            saveData.append("pick_up_address",this.state.pickUpAddr);
            saveData.append("port",this.state.port);
            saveData.append("airport_name",this.state.airportName);
            saveData.append("cargo_date",cargoDate);
            saveData.append("lc",this.state.LC);
            saveData.append("lc_no",this.state.LCno);
            saveData.append("port_of_dispatch",this.state.port_of_dispatch);
            saveData.append("nature",this.state.nature);
            saveData.append("free_trade",this.state.freeTrade);
            saveData.append("benefit_certificate",this.state.benefitCert);
            saveData.append("epcg",this.state.epcg);
            saveData.append("eta_igi_airport_date",eta_igi_airport_date);
            //var ff_code=$("#assign_to_ff").val();
            //var assign_to_ff=$("#assign_to_ff_custom").val();
            //saveData.append("ff_code",ff_code);
            saveData.append("assign_to_ff",this.state.assign_to_ff_custom);
            saveData.append("ff_contact_person_details",this.state.ff_contact_person_details);
            saveData.append("created_by",localStorage.getItem("email"));
            saveData.append("created_by_user",localStorage.getItem("username"));
            // saveData.append("ringiNo",this.state.ringiNo);
            
            
            // var saveData={
            //     "formfields" : formfields,
            //     "ringiNo" : this.state.ringiNo,  
            // }
            
            redirectURL.post("/consignments/seaimportsFormData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{   
                this.setState({
                    basicTitle:"Success",
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                });
                window.location.reload();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else{
            this.setState({
                basicTitle:"Invalid Email. Must be maruti.co.in",
                basicType : "warning",
                show : true
            })
        }        
    }
    else if(flag = 1)
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
    else if(flag = 3)
    {
        this.setState({
            basicTitle:"Mobile Number should be 10 Digits",
            basicType : "warning",
            show : true
        })
    }
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        rows: [...this.state.rows, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

onClickTab(pageType){
    if(pageType == "brequest")
    {
        var brequest = "activet";
        var lclrequest = "";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 1;
        var fclFields = "show-m";
        var pagetitle = "Booking Request (Sea Imports - FCL)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
        $(".fclFields").attr("required",true);
        $("#cargo_type").removeAttr("disabled",true);
        this.setState({
            cargo_type : ""
        });
    }
    else if(pageType == "lclrequest")
    {
        var brequest = "";
        var lclrequest = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 2;
        var fclFields = "show-n";
        var pagetitle = "Booking Request (Sea Imports - LCL)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
        $(".fclFields").removeAttr("required",true);
        $("#cargo_type").val("Non Hazardous");
        $("#cargo_type").attr("disabled",true);
        this.setState({
            cargo_type : "Non Hazardous"
        });
    }
    else if(pageType == "prepaid")
    {
        var brequest = "";
        var lclrequest = "";
        var prepaid = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-n";
        var prepaidFields = "show-m";
        var addresstxt = "(To be mentioned on BOE)";
        var request_type = 3;
        var pagetitle = "Booking Request For Prepaid Shipments (Sea Imports)";
        $(".requestFields").removeAttr("required",true);
        $(".prepaidFields").attr("required",true);
    }
    else if(pageType == "track")
    {
        var brequest = "";
        var lclrequest = "";
        var prepaid = "";
        var track = "activet";
        var requestData = "show-n";
        var trackData = "show-m";
        var requestFields = "show-n";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = "";
        var pagetitle ="";
    }
    this.setState({
        brequest:brequest,
        lclrequest:lclrequest,
        prepaid:prepaid,
        track:track,
        requestData:requestData,
        trackData:trackData,
        requestFields:requestFields,
        prepaidFields:prepaidFields,
        addresstxt:addresstxt,
        request_type:request_type,
        fclFields:fclFields,
        pagetitle:pagetitle
    })
}

trackHandler(event){
    event.preventDefault();
    var indenter_email = $("#indenter_email").val();
    if(indenter_email !=undefined && indenter_email !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        var params = {
            indenter_email : indenter_email
        }
        redirectURL.post("/consignments/getAirCargosStatusByIntender",params)    
        .then((response) => {
            var records = response.data;
            this.setState({
                rowData : records,
                trackResData : 'show-m',
                loadshow:'show-n',
                overly:'show-n',
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please enter Indenter Email",
            basicType : "warning",
            show : true
        })
    }
}

render(){
    var columnwithDefs = [
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "ATA IGI Airport Date",
            field: "ata_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return moment.parseZone(params.data.ooc_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "Status",
            field: "status_text",
            width:250,
            pinned:"right",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.rejected == 1)
                {
                    return "Rejected";
                }
                else if(params.data.actual_delivery_at_msil !=undefined && params.data.actual_delivery_at_msil !="")
                {
                    return "Delivered To MSIL";
                }
                else if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return "Custom Cleared";
                }
                else if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return "Flight Arrived At Destination Airport";
                }
                else if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return "Flight Booked";
                }
                else if(params.data.ff_code == undefined || params.data.ff_code == '')
                {
                    return "Pending At Imports";
                }
                else
                {
                    return "Pending From Freight Forwarder";
                }
            }          
        }
    ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                <li onClick={this.onClickTab.bind(this,"brequest")} className={"lttabs "+(this.state.brequest)}>
                                    <button type="button" className="btn">Sea Imports - FCL</button>
                                </li>
                                
                                <li onClick={this.onClickTab.bind(this,"lclrequest")} className={"lttabs "+(this.state.lclrequest)}>
                                    <button type="button" className="btn">Sea Imports - LCL</button>
                                </li>

                                {/* <li onClick={this.onClickTab.bind(this,"prepaid")} className={"lttabs "+(this.state.prepaid)}>
                                    <button type="button" className="btn">Air Prepaid Shipments</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"track")} className={"lttabs "+(this.state.track)}>
                                    <button type="button" className="btn">Booking Status</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
            		<div className={"col-xl-12 col-lg-12 "+this.state.requestData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="date" id="dateField" className="datetimepicker_date form-control" />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department Name* : </label>
                                            <input type="text" name="deptName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Name* : </label>
                                            <input type="text" name="indenterName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Mobile No* : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control num-cls" pattern="[0-9]{10}"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Email ID* : </label>
                                            <input type="email" name="indenterEmail" id="indenterEmail" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ERP PO* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="erpPO" required onChange={this.changeHandler} required>
                                                <option value="">-- Select ERP PO --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                    <div className="">
                                        {this.state.rows.map((item, idx) => (
                                        <div className="row col-xl-12" key={idx}>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">ERP PO No : </label>
                                                <input type="text" name={"erpPoNo_"+idx} id={"erpPoNo_"+idx}className="form-control" disabled={this.state.disableERP} required/>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">ERP PO Date : </label>
                                                {/* <input type="text" name={"erpPoDate_"+idx} id={"erpPoDate_"+idx}className="datetimepicker_date form-control" disabled={this.state.disableERP} /> */}
                                                <Datetime 
                                                    required={true}
                                                    disableCloseOnClickOutside={false} 
                                                    closeOnSelect={true} 
                                                    timeFormat={false}
                                                    minDate={true}
                                                    inputProps={{ placeholder: 'Date', name: "erpPoDate_"+idx, id : "erpPoDate_"+idx, autoComplete:'off', disabled:this.state.disableERP }} 
                                                    dateFormat="DD-MM-YYYY"
                                                    //format="y-MM-dd"
                                                    //isValidDate={this.validation}
                                                    name={"erpPoDate_"+idx}
                                                    id={"erpPoDate_"+idx}
                                                />
                                            </div>
                                            <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                                <h6 style={{marginTop:"2.5em"}} onClick={() => this.handleRemoveRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                        ))}
                                        <input type="hidden" id="erpLength" value={this.state.rows.length}/>
                                        <div class="row col-sm-12">
                                            <div className="row col-xl-12 input_fields_wrap m-top-1" id="addMrERp">
                                                <div class="col-xl-12">
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRow}>Add More Fields +</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringirows.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi Attachment* : </label>
                                            <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No* : </label>
                                            <input type="text" name={"ringiDesc_"+idx} id={"ringiDesc_"+idx} className="form-control" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length}/>
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Item Description* : </label>
                                            <input type="text" name={"item_desc"} id={"item_desc"}className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight (Metric Tons)* : </label>
                                            <input type="text" name={"gross_weight"} id={"gross_weight"}className="form-control num-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Dimensions of Package (LXBXH)* : </label>
                                            <input type="text" name={"dimensions_of_package"} id={"dimensions_of_package"} placeholder="l x b x h" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Gross Cargo Volume (CBM)* : </label>
                                            <input type="text" name={"gross_cargo_volume"} id={"gross_cargo_volume"} className="form-control fclFields"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "+this.state.fclFields}></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Size Of Container required* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name="size_of_container" id="size_of_container" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Size --</option>
                                                <option value="20 FT">20 FT</option>
                                                <option value="40 FT">40 FT</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Type Of Container* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name="type_of_container" id="type_of_container" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Type --</option>
                                                <option value="GP">General Purpose (GP)</option>
                                                <option value="FR">Flat Rack (FR)</option>
                                                <option value="HC">High Cube (HC)</option>
                                                <option value="OT">Open Top (OT)</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">No Of Containers required* : </label>
                                            <input type="text" name={"no_of_containers"} id={"no_of_containers"} className="form-control num-cls fclFields"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+this.state.fclFields}>
                                            <label className="col-xl-12 col-lg-12">Shipment is : </label>
                                            <select class="form-control col-xl-12 col-lg-12 fclFields" name="shipment_type" id="shipment_type" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Shipment is --</option>
                                                <option value="In Gauge">In Gauge</option>
                                                <option value="Out Gauge">Out Gauge</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Cargo Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargo_type" id="cargo_type" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Cargo Type --</option>
                                                <option value="Hazardous">Hazardous</option>
                                                <option value="Non Hazardous">Non Hazardous</option>
                                            </select>
                                        </div>
                                        {(this.state.cargo_type == "Hazardous")?
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12">MSDS* : </label>
                                                <input type="file" name={"msds_file"} id={"msds_file"} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                            </div>
                                        :""
                                        }
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Handling Type* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="handling_type" id="handling_type" onChange={this.changeHandler}  required>
                                                <option value="">-- Select Handling Type --</option>
                                                <option value="Normal">Normal</option>
                                                <option value="Special">Special</option>
                                            </select>
                                        </div>
                                        {(this.state.handling_type == "Special")?
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12"> Type of Special Handling* : </label>
                                                <input className="form-control tsplh" name="type_of_special_handling" id="type_of_special_handling" onChange={this.changeHandler} required/>
                                            </div>
                                        :""
                                        }
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Delivery in MSIL Plant* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargoDeliveryPlant" required onChange={this.changeHandler}>
                                                <option value="">-- Delivery in MSIL Plant --</option>
                                                <option value="MSIL-G">MSIL-G</option>
                                                <option value="MPT Manesar">MPT Manesar</option>
                                                <option value="Manesar Vehicle Plant">Manesar Vehicle Plant</option>
                                                <option value="HO">HO</option>
                                                <option value="MSIL-Rohtak">MSIL-Rohtak</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Name* : </label>
                                            <input type="text" name="supplierName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Supplier Address {this.state.addresstxt}* : </label>
                                            <textarea name="supplierAddr" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Supplier Contact Person* : </label>
                                            <input type="text" name="supplierCnctPerson" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Country of Supplier* : </label>
                                            <select name="country" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Country</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="China">China</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Dubai">Dubai</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Romania">Romania</option>
                                                <option value="S. Korea">S. Korea</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                                <option value="Vietnam">Vietnam</option>
                                            </select> 
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Email ID* : </label>
                                            <input type="email" name="emailID" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Phone No* : </label>
                                            <input type="text" name="phone" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>                                
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">In co Terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="deliveryTerms"  onChange={this.changeHandler}  required>changeHandler
                                                <option value="">-- Select Terms --</option>
                                                <option value="FOB">FOB</option>
                                                <option value="CIF">CIF</option>
                                                <option value="DAP">DAP</option>
                                                <option value="DDP">DDP</option>
                                                <option value="DDU">DDU</option>
                                                <option value="Ex Works">Ex Works</option>
                                            </select>
                                        </div>
                                        {(this.state.deliveryTerms == "Ex Works")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12"> Address* : </label>
                                                <textarea className="form-control dterms" name="pickUpAddr" id="pickUpAddr" onChange={this.changeHandler} required/>
                                            </div>
                                        :""
                                        }
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date* : </label>
                                            <input type="text" name="cargoDate" id="cargoDate" className="readiness_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">ETA IGI Airport Date* : </label>
                                            <input type="text" name="eta_igi_airport_date" id="eta_igi_airport_date" className="datetimepicker_date form-control prepaidFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className={"row col-xl-12"}>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">LC Available* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="LC"  onChange={this.changeHandler} required>
                                                <option value="">-- Select LC --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">LC No* : </label>
                                            <input type="text" name="LCno" id="LCno" className="form-control requestFields" disabled={this.state.disableLC} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Port Of Dispatch* : </label>
                                            <input type="text" name="port_of_dispatch" id="port_of_dispatch" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>

                                        <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">Frieght Forwarder: </label>
                                            <input type="text" name="assign_to_ff_custom" className="form-control prepaidFields" onChange={this.changeHandler} />
                                        </div>

                                        <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">FF Contact person Details* : </label>
                                            <input type="text" name="ff_contact_person_details" className="form-control prepaidFields" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Nature Of Shipment* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="nature"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Nature --</option>
                                                <option value="capital">Capital</option>
                                                <option value="revenue">Revenue</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Free Trade Agreement* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="freeTrade"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Free Trade Agreement --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">R&D Benefit Certificate* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="benefitCert"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Benefit Certificate --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">EPCG* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="epcg" id="epcg" onChange={this.changeHandler} required>
                                                <option value="">-- Select Type --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.trackData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Booking Status</span>
								</h5>
				   			</div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="email" name="indenter_email" id="indenter_email" className="form-control" placeholder="Indenter Email" required/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 "+this.state.trackResData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
    
    
	$('.readiness_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });
$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});
// $(document).on("change", "#cargoDate", function(e) {
//     e.preventDefault();
//     var bookingDate=$("#dateField").val();
//     var cargoDate=$("#cargoDate").val();
//     var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
//     var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

//     var startDate = new Date(bookingDate);
//     var endDate = new Date(cargoDate);
//     var startTime = startDate.getTime();
//     var endTime = endDate.getTime();
//     console.log(startTime,endTime);
//     if(startTime >= endTime)
//     {
//         $("#cargoDate").val("");
//         alert("Date should be greater than Booking Date");
//     }
// })