/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import CountUp from 'react-countup';
import { getHyphenDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import { includes } from 'lodash';
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class TPTTrucksGPSStatus extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Vehicle Tracking Report",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				// showtptgpsmap:ShowTPTGpsMap,
                // UpdateButton:UpdateButton,
                // UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:2000,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
				]
			},
            seriesData: [],
            categories: [],
            ownseriesData: [],
            overallseriesData: [],
            mvsoseriesData: [],
            pinnedTotalBottomRowData:[],
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
            marketTrucks : [],
            ownTrucks : [],
            marketGpsIntegrated : [],
            marketGpsNotIntegrated : [],
            marketDeviceWorking : [],
            marketDeviceNotWorking : [],
            ownGpsIntegrated :[],
            ownGpsNotIntegrated : [],
            ownDeviceWorking : [],
            ownDeviceNotWorking : [],
            last_date:"",
            hide_for_GDM_P : false,
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        var from_date = moment().startOf('month').format('YYYY-MM-DD');
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        // $("#osfromDate").val(from_date);
		// $("#ostoDate").val(to_date);
        // var truck_type = this.props.match.params.truck_type;
        // var pageTitle = "";
        // if(truck_type == "owntrucks")
        // {
        //     pageTitle = "Own Trucks GPS Status Report";
        // }
        // else
        // {
        //     pageTitle = "Total Trucks GPS Status Report";
        // }
        // this.setState({
        //     pageTitle:pageTitle
        // })
         this.onLoadPageData();
    }
    
    onLoadPageData = async () => {
        loadDateTimeScript();
        this.setState({
            // loadshow:"show-m"
        })
        // if(localStorage.getItem("plant_code")=="GDM_P"){
        //     this.setState({hide_for_GDM_P : true})
        // }
		var params = {}

        // if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
        // {
        //     params.plant_code = localStorage.getItem("pc")
        // }
       var transporterCode ;
        if (localStorage.getItem("transportercode") != "" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != null){
            transporterCode = JSON.parse(localStorage.getItem("transportercode"))
        }
        // if (localStorage.getItem("email")!="" && localStorage.getItem("email")!=undefined && localStorage.getItem("email")!="undefined"){
        //     params.email = localStorage.getItem("email") 
        // }
         console.log("params", params)
        await redirectURL.post("/consignments/getTrucksGPSIntegrationStatus",params)
        .then(async(response) =>{
            var rowData = response.data || [];
            if(transporterCode){
                var rowData = rowData.filter(data => transporterCode.includes(data.transporter_code))
            }
            console.log(response.data, "responsedata")
            var marketTrucks = rowData

            // if(localStorage.getItem("pc")!="" && localStorage.getItem("pc")!=undefined){
            //     rowData=rowData.filter(e=>e.plant_code==localStorage.getItem("pc"))
            // }
        
            if(rowData !=undefined && rowData !="")
            {
                if(rowData.length > 0)
                {
                   
                    
                    var marketGpsIntegrated = [];
                    var marketGpsNotIntegrated = [];
                    var marketDeviceWorking = [];
                    var marketDeviceNotWorking = [];
                    await marketTrucks.map(async(e) => {
                        if(e.last_date != undefined && e.last_date !=null && e.last_date !="")
                        {
                            marketGpsIntegrated.push(e);
                            var lastthreeDaysDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss');
                            // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                            var lastDateOrig = moment.parseZone(new Date(e.last_date)).utcOffset("-05:30")._d;
                            var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                            var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                            if(dateChk == true)
                            {
                                marketDeviceWorking.push(e);
                            }
                            else
                            {
                                marketDeviceNotWorking.push(e);
                            }
                        }
                        else
                        {
                            marketGpsNotIntegrated.push(e);
                        }
                    });
                   
                    
                    // if (localStorage.getItem("email")=="AJAY.KUMAR@ext.amns.in" || localStorage.getItem("email")=="Securitygate.PuneSC@amns.in" || localStorage.getItem("email")=="sachin.tiwari@ext.amns.in" || localStorage.getItem("email")=="Uttam.Pakhare@amns.in" || localStorage.getItem("email")=="Securitycontrolroom.AMNSPune@amns.in" || localStorage.getItem("email")=="AJAY.KUMAR@ext.amns.in"|| localStorage.getItem("email")=="Balaji.Kothawale@ext.amns.in"||localStorage.getItem("email")=="Ravindra.Ade@ext.amns.in" || localStorage.getItem("email")=="security.ssc@amns.in" ){
                    //     rowData=[]
                    // }
                    // else{
                    //     rowData=rowData
                    // }
                    // if(localStorage.getItem("pc")!="HZ" && localStorage.getItem("pc")!="KHP_P" && localStorage.getItem("pc")!=""){
                    //     rowData = ownTrucks 
                    // }
                    // else{
                    //     rowData = rowData
                    // }
                     var last_date=""
                    rowData.sort(GetSortDescOrder("last_date"));
                    var records = rowData.filter(e=>e.last_date!="" && e.last_date!=undefined&&e.last_date!=null)
                    console.log(records,"12123")
                    if(records.length>0){
                        last_date = getHyphenDDMMYYYYHHMMSS(records[0].last_date);
                    }
                    else{
                        last_date = ""
                    }
                    
                    
                    
                    await this.setState({
                        rowData:rowData,
                        originalrowData:response.data,
                        marketTrucks : marketTrucks,
                        // ownTrucks : ownTrucks,
                        marketGpsIntegrated : marketGpsIntegrated,
                        marketGpsNotIntegrated : marketGpsNotIntegrated,
                        marketDeviceWorking : marketDeviceWorking,
                        marketDeviceNotWorking : marketDeviceNotWorking,
                        loadshow:"show-n",
                        last_date:last_date
                    })
                }
                else
                {
                    this.setState({
                        loadshow:"show-n"
                    })
                }
            }
            else
            {
                this.setState({
                    loadshow:"show-n"
                })
            }
        })
    }
    

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };

    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    onClickFilterData(){
        var truck_no =$("#truck_no").val();
        if(truck_no != undefined && truck_no != "")
        {
            var originalrowData = this.state.originalrowData;
            var filteredData = [];
            originalrowData.map((e) => {
                if(e.truck_no.indexOf(truck_no) != -1){
                    console.log(truck_no + " found");
                    filteredData.push(e);
                }
            });
            console.log(filteredData,"filteredData")
            this.setState({
                rowData : filteredData,
                hide_for_GDM_P:false
            });
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:'Enter Truck No',
                basicType:"danger",
                loadshow:'show-n',
            })
        }
    }
    onClickFilterReset = () => {
        window.location.reload();
    }
    onClickCounterShowData(params) {
        if(params == "marketTrucks")
        {
            this.setState({
                rowData : this.state.marketTrucks
            });
        }
        
        if(params == "marketGpsIntegrated")
        {
            this.setState({
                rowData : this.state.marketGpsIntegrated
            });
        }
        if(params == "marketGpsNotIntegrated")
        {
            this.setState({
                rowData : this.state.marketGpsNotIntegrated
            });
        }
        if(params == "marketDeviceWorking")
        {
            this.setState({
                rowData : this.state.marketDeviceWorking
            });
        }
        if(params == "marketDeviceNotWorking")
        {
            this.setState({
                rowData : this.state.marketDeviceNotWorking
            });
        }
        

    }
    render(){
        console.log(this.state.last_date,"222")
        var showforAdmin = true
        if(localStorage.getItem("roles")=="SITE_ADMIN" || localStorage.getItem("email")=="rahul@enmovil.in"){
            showforAdmin = false
        }
        var showforSecurity=false
        if (localStorage.getItem("email")=="AJAY.KUMAR@ext.amns.in" || localStorage.getItem("email")=="Securitygate.PuneSC@amns.in" || localStorage.getItem("email")=="sachin.tiwari@ext.amns.in" || localStorage.getItem("email")=="Uttam.Pakhare@amns.in" ||  localStorage.getItem("email")=="security.ssc@amns.in"|| localStorage.getItem("email")=="Ravindra.Ade@ext.amns.in"|| localStorage.getItem("email")=="Uttam.Pakhare@amns.in "|| localStorage.getItem("email")=="Balaji.Kothawale@ext.amns.in"|| localStorage.getItem("email")=="Securitycontrolroom.AMNSPune@amns.in"){
            showforSecurity = true  
        }
        
		var columnwithDefs= [
            {
				headerName: "Truck No",
				field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
                sortable:true,
                pinned:"left"
			},
            {
				headerName: "Transporter Name",
				field: "transporter_name",
                width: 200,
                filter: true,
                resizable: true,
                sortable:true,
            },
            {
				headerName: "Transporter Code",
				field: "transporter_code",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
            },
            // {
			// 	headerName: "Truck Type",
			// 	field: "truck_type",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
            // },
            {
				headerName: "GPS Status",
				field: "gps_status",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                hide:showforSecurity,
                valueGetter: function(params){
                    if(params.data.gps_status == "Gps Available")
                    {
                        return "GPS Available";
                    }
                    else if(params.data.gps_status == "Gps Not Available")
                    {
                        return "GPS Not Available";
                    }
                    else
                    {
                        return "GPS Not Available";
                    }
                },
                // cellClass: function (params) {
                //     var lastthreeDaysDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss');
                //     // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                //     var lastDateOrig = moment.parseZone(new Date(params.data.last_date)).utcOffset("-05:30")._d;
                //     var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                //     var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                //     if (localStorage.getItem("email")!="" && localStorage.getItem("email")=="bgsgps@enmovil.in"){
                //         if(dateChk == true)
                //     {
                //         return 'bgColorSuccessDark';
                //     }
                //     else
                //     {
                //         return "bgColorDangerDark";
                //     }
                //     }
                    
                //     }
            },
            {
				headerName: "Device Status",
				field: "",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.plant_code=="HZ"){
                        var lastthreeDaysDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss');
                    // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                    var lastDateOrig = moment.parseZone(new Date(params.data.last_date)).utcOffset("-05:30")._d;
                    var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                    var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                    }
                    else{
                        var lastthreeDaysDate = moment.parseZone().subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                    var lastDateOrig = moment.parseZone(new Date(params.data.last_date)).utcOffset("-05:30")._d;
                    var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                    var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                    }

                    // var lastthreeDaysDate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss');
                    // // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                    // var lastDateOrig = moment.parseZone(new Date(params.data.last_date)).utcOffset("-05:30")._d;
                    // var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                    // var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                    if(dateChk == true)
                    {
                        return "Working";
                    }
                    else
                    {
                        return "Not Working";
                    }
                },
                cellClass: function (params) {
                    var lastthreeDaysDate = moment.parseZone().subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss');
                    // var lastActiveOn = getHyphenDDMMYYYYHHMMSS(params.data.last_date);
                    var lastDateOrig = moment.parseZone(new Date(params.data.last_date)).utcOffset("-05:30")._d;
                    var lastActiveOn = moment.parseZone(new Date(lastDateOrig)).format("YYYY-MM-DD HH:mm:ss")
                    var dateChk = moment(lastActiveOn).isAfter(lastthreeDaysDate);
                    if (localStorage.getItem("email")!="" && localStorage.getItem("email")=="Balaji.Kothawale@ext.amns.in" ||
                     localStorage.getItem("email")=="Uttam.Pakhare@amns.in" ||
                      localStorage.getItem("email")=="Securitycontrolroom.AMNSPune@amns.in"||
                      localStorage.getItem("email")=="AJAY.KUMAR@ext.amns.in" || localStorage.getItem("email")=="Securitygate.PuneSC@amns.in" ||
                       localStorage.getItem("email")=="sachin.tiwari@ext.amns.in" 
                       || localStorage.getItem("email")=="security.ssc@amns.in"
                       ||localStorage.getItem("email")=="Ravindra.Ade@ext.amns.in" 
                       || localStorage.getItem("email")=="sudeep.singh@amns.in"
                       ||localStorage.getItem("email")=="gandhidham.security@amns.in" ){
                        if(dateChk == true)
                    {
                        return 'bgColorSuccessDark';
                    }
                    else
                    {
                        return "bgColorDangerDark";
                    }
                    }
                    
                    }
            },
            {
				headerName: "Last Active On",
				field: "last_date",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.last_date !=undefined && params.data.last_date !="undefined" && params.data.last_date !=null && params.data.last_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.last_date);
                    }
                    else
                    {
                        return "Not Available";
                    }
                },
				comparator: dateComparator,
			},
            {
				headerName: "GPS Data Provider",
				field: "gps_data_provider",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                
			},
            // {
			// 	headerName: "Plant Code",
			// 	field: "plant_code",
            //     width: 150,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
            //     // hide :showforAdmin
			// },

        ];
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	{this.state.pageTitle}
                        </h5>
                    </div>
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>	
                        <div class="row">
                            {/* <div class="col-xl-2 col-lg-2 form-group">
                                <label>From Date</label>
                                <input type="text" name="date" id="osfromDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/>
                            </div>
                            <div class="col-xl-2 col-lg-2form-group">
                                <label>To Date</label>
                                <input type="text" name="date" id="ostoDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/>
                            </div> */}
                           
                            <div class="col-xl-3 col-lg-3 form-group">
                                <label>Truck No</label>
                                {/* <input type="text" name="truck_no" value={this.state.inputValue} id="truck_no" autoComplete="off" className="form-control" onChange={this.handleChange}  /> */}
                                <input type="text" name="truck_no" id="truck_no" autoComplete="off" className="form-control" />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>&nbsp;</label>
                                <br />
                                <button type="submit" onClick={this.onClickFilterData.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <i title="Reset" style={{ cursor: "pointer" }} onClick={this.onClickFilterReset.bind(this)} class="icofont icofont-refresh"></i>
                            </div>

                            {/* {this.state.last_date!=undefined && this.state.last_date!=""}{
                                <div class=" col-xl-7 col-lg-7">
                                    
                                    <h6 className='pull-right'>Last GPS Time: {this.state.last_date}</h6>
                                </div>
                            } */}
                            {(this.state.last_date!=undefined && this.state.last_date!="")? 
                             <div class=" col-xl-7 col-lg-7">
                                    
                             <h6 className='pull-right'>Last GPS Time: {this.state.last_date}</h6>
                         </div>:""}
                           
                            
                            
                        </div>
                       
                       <div className="row">
                                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                                    <div className="card">
                                        <div className="card-body" id="counterCardBody">
                                            <div className="crm-numbers pb-0">
                                                
                                                    <div className="row">
                                                        <div className="col cursorPointer">
                                                                <span className="f13" onClick={this.onClickCounterShowData.bind(this, "marketTrucks")}><i className="icofont icofont-truck f22 txt-info"></i><br />Total Trucks</span>
                                                                <h4 className="txt-info f35" onClick={this.onClickCounterShowData.bind(this, "marketTrucks")}><span className="counter"><CountUp end={this.state.marketTrucks.length} /></span></h4>
                                                                <div className="row">
                                                                    <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "marketGpsIntegrated")}>
                                                                        <span className="f10">GPS Integrated</span>
                                                                        <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.marketGpsIntegrated.length} /></span></h4>
                                                                    </div>
                                                                    <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "marketGpsNotIntegrated")}>
                                                                        <span className="f10">GPS Not Integrated</span>
                                                                        <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.marketGpsNotIntegrated.length} /></span></h4>
                                                                    </div>
                                                                    <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "marketDeviceWorking")}>
                                                                        <span className="f10">Device Working</span>
                                                                        <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.marketDeviceWorking.length} /></span></h4>
                                                                    </div>
                                                                    <div className="col cursorPointer" onClick={this.onClickCounterShowData.bind(this, "marketDeviceNotWorking")}>
                                                                        <span className="f10">Device Not Working</span>
                                                                        <h4 className="txt-info f35"><span className="counter"><CountUp end={this.state.marketDeviceNotWorking.length} /></span></h4>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                    
                                            </div>	
                                        </div>	
                                    </div>	
                                </div>
                            </div>
                        </div>
                        
                        
                        {this.state.hide_for_GDM_P == true ? "":
                        <div id="myGrid" style={{width:"100%",height:"580px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={true}
                            enableRangeSelection={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            rowSelection={this.state.rowSelection}
                            stopEditingWhenGridLosesFocus={true}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            suppressRowClickSelection={true}
                            />
                        </div>}
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
            </div>
              
        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function GetSortDescOrder(prop) {    

    return function(a, b) {    

        if (a[prop] < b[prop]) {    

            return 1;    

        } else if (a[prop] > b[prop]) {    

            return -1;    

        }    

        return 0;    

    }    

}
 function GetSortAscOrder(prop) {    

      return function(a, b) {    

          if (a[prop] > b[prop]) {    

              return 1;    

          } else if (a[prop] < b[prop]) {    

              return -1;    

          }    

          return 0;    

      }    

    }

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}