import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { TabContent, TabPane } from 'reactstrap';
import CKEditor from "react-ckeditor-component";
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYY,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import CountUp from 'react-countup';
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class DetentionReport extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                enableValue: true,
                enableRowGroup: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            rowData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            
			animateRows: true,
            dept_code:"",
            pagetitle:"",
            total_detention_inr:0,
            disableMailBtn: "disable",
            inboxSlider:'',
            showleftmailside:'',
            sendmaildiv:"",
            draftlist:[],
            SelectedTab: "1",
            toEmail: [],
            emailSubject:"",
            emailContent:"",
            ccEmail:"",
            insideICD: "activet",
            delivered: "",
            complete_data: [],
            total_containers_counter: 0,
            total_detention_inr_counter : 0,
            counter1: "Total Inside ICD Containers in Delay",
            counter2: "Total Inside ICD Containers Detention INR",
            shipping_wise_inr_counter : 0,
            ground_charges_counter: 0

        };
        // this.getEmailBodyContent = this.getEmailBodyContent.bind(this)
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/consignments/getDetentionReportData").then((response)=>{
            console.log(response.data)
            var total_detention_inr_counter = 0;
            response.data.map(function(e){
                if(e.status == "Inside ICD")
                {
                    total_detention_inr_counter = total_detention_inr_counter + Number(e.total_detention)
                }
            })
            console.log(total_detention_inr_counter);
            var inside_total_containers = response.data.filter(function(e){
                return e.status == "Inside ICD"
            })
            var shipping_wise_inr_counter = 0;
            var ground_charges_counter = 0;
            inside_total_containers.map(function(e){
                shipping_wise_inr_counter = shipping_wise_inr_counter + e.shipping_line_cost_inr
                ground_charges_counter = ground_charges_counter + e.icd_cost
            })
            console.log(shipping_wise_inr_counter,ground_charges_counter)
            this.setState({
                rowData: inside_total_containers,
                complete_data: response.data,
                total_detention_inr_counter: total_detention_inr_counter,
                total_containers_counter: inside_total_containers.length,
                shipping_wise_inr_counter: shipping_wise_inr_counter,
                ground_charges_counter : ground_charges_counter,
                loadshow : "show-n",
                overly : "show-n"
            })
        }).catch((e)=>{
            console.log(e)
        })
        
    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
            inboxSlider:"",
			overly:'show-n',
            loadshow:'show-n',
            disableMailBtn: "disable",
            toEmail:'',
            ccEmail:'',
            emailContent:'',
            emailSubject:'',
        });
        this.gridApi.deselectAll()
		
    }
    onClickCounterShowData(params){
		console.log(" params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		
		if(params == "exitloads")
		{
			this.gridApi.setRowData(this.state.exitWithoutLoads);
			this.gridApi.onFilterChanged();
        }
        if(params == "all")
        {
            this.gridApi.setRowData(this.state.totalRecords);
			this.gridApi.onFilterChanged();
        }
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onCellClicked(e){
        if(e.colDef.field == "updateFields")
        {
            var icd_arrival = e.data.icd_arrival;
            icd_arrival = icd_arrival.split("-")[2]+"-"+icd_arrival.split("-")[1]+"-"+icd_arrival.split("-")[0];
            var params = {
                shipping_charge_usd: e.data.shipping_charge_usd,
                delay_reason : e.data.delay_reason,
                exchange_rate: e.data.exchange_rate,
                user_dept: e.data.user_dept,
                user_email: e.data.user_email,
                container_no: e.data.container_no,
                icd_arrival: icd_arrival,
                updated_by: localStorage.getItem("userid"),
            }
            redirectURL.post("/consignments/updateDetentionDetails",params).then((response)=>{
                window.location.reload();
            }).catch((e)=>{console.log(e)})
        }
    }
    onEditStopped(cell){
        var icd_arrival = cell.data.icd_arrival;
        icd_arrival = icd_arrival.split("-")[2]+"-"+icd_arrival.split("-")[1]+"-"+icd_arrival.split("-")[0];
        if(cell.colDef.field == "delay_reason" && cell.value != "" && cell.value.replace(/\s/g,"") != "")
        {
            var params = {
                delay_reason : cell.value,
                updated_by: localStorage.getItem("userid"),
                container_no: cell.data.container_no,
                icd_arrival: icd_arrival
            }
            console.log(cell)
            redirectURL.post("/consignments/updateDetentionDelayReason",params).then((response)=>{
                console.log(response);
            }).catch((e)=>{
                console.log(e)
            })
        }
        if(cell.colDef.field == "shipping_charge_usd" && cell.value != "" && cell.value.replace(/\s/g,"") != "")
        {
            var params = {
                shipping_charge_usd: cell.value,
                container_no: cell.data.container_no,
                icd_arrival: icd_arrival,
                type: 1
            }
            redirectURL.post("/consignments/updateDetentionDetails",params).then((response)=>{

            }).catch((e)=>{console.log(e)})
        }
        if(cell.colDef.field == "exchange_rate"  && cell.value != "" && cell.value.replace(/\s/g,"") != "")
        {
            var params = {
                exchange_rate: cell.value,
                container_no: cell.data.container_no,
                icd_arrival: icd_arrival,
                type: 2
            }
            redirectURL.post("/consignments/updateDetentionDetails",params).then((response)=>{

            }).catch((e)=>{console.log(e)})
        }
        else
        {
            
        }
        if(cell.colDef.field == "user_dept"  && cell.value != "" && cell.value.replace(/\s/g,"") != "")
        {
            var params = {
                user_dept: cell.value,
                container_no: cell.data.container_no,
                icd_arrival: icd_arrival,
                type: 3
            }
            redirectURL.post("/consignments/updateDetentionDetails",params).then((response)=>{

            }).catch((e)=>{console.log(e)})
        }
        else{
            
        }
        if(cell.colDef.field == "user_email"  && cell.value != "" && cell.value.replace(/\s/g,"") != "")
        {
            var params = {
                user_email: cell.value,
                container_no: cell.data.container_no,
                icd_arrival: icd_arrival,
                type: 4
            }
            redirectURL.post("/consignments/updateDetentionDetails",params).then((response)=>{

            }).catch((e)=>{console.log(e)})
        }
    }
    onRowSelected(row){
        var selectedNodes = this.gridApi.getSelectedNodes();
        var user_depts = []
        var flag=0;
        // for(var i=0;i<selectedNodes.length;i++)
        // {
        //     if(user_depts.length == 0)
        //     {
        //         user_depts.push(selectedNodes[i].data.user_dept)
        //     }
        //     else{
        //         if(user_depts.indexOf(selectedNodes[i].data.user_dept) == -1)
        //         {
        //             flag = 1;
        //             break;
        //         }
        //         else
        //         {
        //             user_depts.push(selectedNodes[i].data.user_dept)
        //         }
        //     }
        // }
        if(selectedNodes.length == 0)
        {
            this.setState({
                disableMailBtn : "disable"
            })
        }
        else
        {
            this.setState({
                disableMailBtn : ""
            })
        }
    }
    
    async openMailSlider(){
        console.log(this.gridApi.getSelectedNodes())
        var selectedRows = this.gridApi.getSelectedNodes()
        var toEmail =[]
        selectedRows.map(function(e){
            if(e.data.user_email != undefined)
            {   
                toEmail.push(e.data.user_email)
            }
        })
        toEmail = toEmail.filter((item, i, ar) => ar.indexOf(item) === i)
        var reportrows = []
        selectedRows.map(function(e){
            reportrows.push(e.data)
        })
        var message_body = getEmailBodyContent(reportrows)
        console.log(message_body)
        this.setState({					
            inboxSlider:'slider-translate-75p',
            showleftmailside:'show-n',
            sendmaildiv:"col-xl-12",
            overly:'show-m',
            // emailformat:records,
            // toEmail: toEmail,
            // ccEmail:records[0].ccEMail,
            // mail_escalation:records[0].escalation,
            emailContent: message_body,
            emailSubject: "Container Detention Charges -"+moment().format("YYYY-MM-DD"),
            // ticketMailID:records[0]._id,
            // ticketslist:records[0].ticket_numbers
        });
    }
    onClickDiscardAllMail(){
		var reqparams = {
			userid:localStorage.getItem("userid")
		};
		redirectURL.post("/tms/discardallmail",reqparams)
		.then((response) => {
			//console.log(" response ", response)
			redirectURL.post("/tms/discardmail")
			.then((response) => {
				
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:"Successfully discarded mails",
					basicType:"success",
					totalDrafts:0
				});
				
			})
		})
		.catch(function(e){
			console.log("Error ",e)
		})
    }
    onClickDiscardMail(){
        if(Array.isArray(this.state.toEmail))
		{
            var to_mail = this.state.toEmail.join(",");
        }
        else
        {
            var to_mail = this.state.toEmail
        }
		if(Array.isArray(this.state.ccEmail))
		{
			var ccEmails = this.state.ccEmail.join(",");
		}
		else
		{
            var ccEmails = this.state.ccEmail
		}
		var mailData = {
			ticketId: this.state.ticketMailID,
			tickets:this.state.ticketslist,
			toEmail:to_mail,
			ccEmail:ccEmails,
			ticketEmailContent:this.state.ticketEmailContent,
			ticketSubject:this.state.ticketSubject,
			userId : localStorage.getItem('userid')
		}
		//console.log("mailData ", mailData)
		//console.log("mailData Userid ", localStorage.getItem('userid'))
		redirectURL.post("/tms/discardmail",mailData)
		.then((response) => {
			//console.log(" response ", response)
			var old = this.state.totalDrafts
			if(response.data.ok == 1)
			{
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:response.data.message,
					basicType:"success",
					totalDrafts:(old-1)
				});
			}
			else
			{
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:response.data.message,
					basicType:"danger"
				});
			}
			
			
			//window.location.reload();
		})
    }
    onClickDraftItem = (params) =>{
		//console.log("params ", params)
		var reqparams={
			ticketemailid:params._id
		}
		redirectURL.post("/tms/ticketemailinfo", reqparams)
		.then((response) => {
			console.log("Draft ", response.data)
			var records = response.data
			this.setState({
				emailformat:records,
				toEmail:records[0].toEmail,
				ccEmail:records[0].ccEMail,
				ticketEmailContent:records[0].body,
				ticketSubject:records[0].subject,
				ticketMailID:records[0]._id,
				ticketslist:records[0].ticket_numbers,
				showdiscard:'show-m'
			});
		})
    }
    onClickSaveMailNow = async (e) =>{
		e.preventDefault();
		var mailData = await {
			ticketId: this.state.ticketMailID,
			tickets:this.state.ticketslist,
			toEmail:this.state.toEmail,
			ccEmail:this.state.ccEmail,
			ticketEmailContent:this.state.ticketEmailContent,
			ticketSubject:this.state.ticketSubject,
			userId : localStorage.getItem('userid')
		}
		//console.log("mailData ", this.state.ticketEmailContent)
		//console.log("mailData Userid ", mailData)
		setTimeout(
			await redirectURL.post("/tms/saveTicketMail",mailData)
		.then((response) => {
			this.setState({					
				show: true,
				basicTitle:"Mail saved successfully",
				basicType:"success"
			});
			//window.location.reload();
		}), 1000);
		
    }
    onClickSaveAndSendMailNow(){
		console.log("this.state.toEmail ", this.state.toEmail)
		if(this.state.toEmail != "")
		{
            if(Array.isArray(this.state.toEmail))
            {
                var to_mail = this.state.toEmail.join(",");
            }
            else
            {
                var to_mail = this.state.toEmail
            }
            if(Array.isArray(this.state.ccEmail))
            {
                var ccEmails = this.state.ccEmail.join(",");
            }
            else
            {
                var ccEmails = this.state.ccEmail
            }
			var mailData = {
				toEmail: to_mail,
				ccEmail: ccEmails,
				emailContent:this.state.emailContent,
                emailSubject:this.state.emailSubject,
                userId: localStorage.getItem("userid")
			}
			console.log("mailData ", mailData)
			//console.log("mailData Userid ", localStorage.getItem('userid'))
			redirectURL.post("/consignments/saveandsendmail",mailData)
			.then((response) => {
                //console.log(" response ", response)
                if(response.data.status == "Failure")
                {
                    var basictype = "warning";
                }
                else
                {
                    var basictype = "success";   
                }
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					toEmail:'',
					ccEmail:'',
					emailContent:'',
					emailSubject:'',
					show: true,
					basicTitle: response.data.message,
                    basicType:basictype,
                    disableMailBtn: "disable"
                });
                this.gridApi.deselectAll()
				
			})
		}
		else{
			this.setState({					
				show: true,
				basicTitle:"To Mail Should not be empty",
				basicType:"danger"
			});
		}
    }

    onBlur = async (evt) =>{
		console.log("onBlur event called with event info: ", evt.editor.getData());
		await this.setState({
            emailContent:  evt.editor.getData()
        })
    }
    afterPaste(evt){
		console.log("afterPaste event called with event info: ", evt);
		this.setState({
            emailContent:  evt.editor.getData()
        })
    }
    updateContent(newContent) {
		console.log("newContent ",newContent)
        this.setState({
            emailContent:  newContent.editor.getData()
        })
    }
    onChangeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    onClickTab(dept){
		if(dept == 'inside')
		{
		   var insideICD = 'activet'
           var delivered = ''
           var inside_row_Data = this.state.complete_data.filter(function(e){
               return e.status == "Inside ICD"
           });
           var total_detention_inr_counter = 0;
           inside_row_Data.map(function(e){
                total_detention_inr_counter = total_detention_inr_counter + Number(e.total_detention)
           })
           var shipping_wise_inr_counter = 0;
            var ground_charges_counter = 0;
            inside_row_Data.map(function(e){
                shipping_wise_inr_counter = shipping_wise_inr_counter + e.shipping_line_cost_inr;
                ground_charges_counter = ground_charges_counter + e.icd_cost
            })
            console.log(shipping_wise_inr_counter,ground_charges_counter)
           this.setState({
               rowData : inside_row_Data,
               total_containers_counter: inside_row_Data.length,
               total_detention_inr_counter: total_detention_inr_counter,
               shipping_wise_inr_counter: shipping_wise_inr_counter,
               ground_charges_counter: ground_charges_counter,
               counter1: "Total Inside ICD Containers in Delay",
               counter2: "Total Inside ICD Containers Detention INR"
           })
		}
		if(dept == 'delivered')
		{
		   var insideICD = ''
           var delivered = 'activet'
           var delivered_row_Data = this.state.complete_data.filter(function(e){
                return e.status == "Delivered"
            });
            var total_detention_inr_counter = 0;
            delivered_row_Data.map(function(e){
                total_detention_inr_counter = total_detention_inr_counter + Number(e.total_detention)
            })
            var shipping_wise_inr_counter = 0;
            var ground_charges_counter = 0;
            delivered_row_Data.map(function(e){
                shipping_wise_inr_counter = shipping_wise_inr_counter + e.shipping_line_cost_inr
                ground_charges_counter = ground_charges_counter + e.icd_cost
            })
            console.log(shipping_wise_inr_counter,ground_charges_counter)
            this.setState({
                rowData : delivered_row_Data,
                total_containers_counter: delivered_row_Data.length,
                total_detention_inr_counter: total_detention_inr_counter,
                shipping_wise_inr_counter:shipping_wise_inr_counter,
                ground_charges_counter: ground_charges_counter,
                counter1: "Total Containers in Delay",
                counter2: "Total Containers Detention INR"
            })
		}
		
		this.setState({
           insideICD: insideICD,
           delivered: delivered
	   })
	}
    render(){
        const showOnlyLeft = (this.state.counter1 == "Total Inside ICD Containers in Delay")?true:false;
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                checkboxSelection: true,
                width: 60
            },
            {
                headerName : "ICD Arrival",
                field : "icd_arrival",
                filter : true,
                resizable :true,
                width:140,
                editable:false,
                valueGetter: function(params)
                {
                    if(params.data.icd_arrival != "" && params.data.icd_arrival != undefined)
                    {
                        var icd_arrival = params.data.icd_arrival;
                        icd_arrival = icd_arrival.split("-")[2]+"-"+icd_arrival.split("-")[1]+"-"+icd_arrival.split("-")[0]
                        return getHyphenDDMMMYYYY(icd_arrival)
                    }
                    else
                    {
                        return params.data.icd_arrival
                    }
                }
            },
            {
                headerName : "Container No",
                field : "container_no",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "BL No(s)",
                field : "bl_nos",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
            },
            {
                headerName : "Supplier",
                field : "supplier",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "Cargo Description",
                field : "cargo_description",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
            },
            {
                headerName : "Shipping Line",
                field : "shipping_line",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "Container Size",
                field : "container_size",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
            },
            {
                headerName : "Plant",
                field : "plant",
                filter : true,
                resizable :true,
                editable:false,
                width:110,
            },
            {
                headerName : "ICD Name",
                field : "icd_source",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
            },
            {
                headerName : "Dispatch Date",
                field : "dispatched_on",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
                hide: showOnlyLeft,
                valueGetter: function(params){
                    if(params.data.dispatched_on != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.dispatched_on);
                    }
                    else{return ""}
                }
            },
            {
                headerName : "Empty Return On",
                field : "delivered_on",
                filter : true,
                editable:false,
                resizable :true,
                width:120,
                hide: showOnlyLeft,
                valueGetter: function(params){
                    if(params.data.delivered_on != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.delivered_on);
                    }
                    else{return ""}
                }
            },
            {
                headerName : "Total Waiting Period (Days)",
                field : "duration",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "Detention Period (shipping line)",
                field : "shipping_detention_period",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "Detention Rate (USD)",
                field : "shipping_charge_usd",
                filter : true,
                resizable :true,
                editable:true,
                cellClass:'cellstylegridG',	
                width:140,
            },
            {
                headerName : "Exchange Rate (INR)",
                field : "exchange_rate",
                filter : true,
                resizable :true,
                editable:true,
                cellClass:'cellstylegridG',	
                width:110,
            },
            {
                headerName : "Total Detention Rate in INR (Shipping Line)",
                field : "shipping_line_cost_inr",
                filter : true,
                resizable :true,
                editable:false,
                width:180,
            },
            {
                headerName : "Detention Period (ICD)",
                field : "icd_detention_period",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName : "Total ground rent charges in INR payable",
                field : "icd_cost",
                filter : true,
                resizable :true,
                editable:false,
                width:180,
            },
            {
                headerName : "Total Detention Cost (INR)",
                field : "total_detention",
                filter : true,
                resizable :true,
                editable:false,
                width:140,
            },
            {
                headerName: "Status",
                field: "status",
                width: 130,
            },
            {
                headerName : "User Dept.",
                field : "user_dept",
                filter : true,
                resizable :true,
                editable:true,
                cellClass:'cellstylegridG',	
                width:140,
            },
            {
                headerName : "User E-Mail",
                field : "user_email",
                filter : true,
                resizable :true,
                editable:true,
                cellClass:'cellstylegridG',	
                width:140,
            },
            {
                headerName : "Delay Reason",
                field : "delay_reason",
                filter : true,
                resizable :true,
                editable:true,
                cellClass:'cellstylegridG',	
                width:140,
            },
            {
                headerName: "",
                field: "updateFields",
                width: 130,
                cellRenderer: function(params){
                    return "<button class='btn btn-success' style='padding-top:1px'>Update</button>"
                }
            },
            
        ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="col-xl-12 col-lg-12 f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">
                        <li onClick={this.onClickTab.bind(this,"inside")} className={"ttabs "+(this.state.insideICD)}>
                            <button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"sndg")}>Inside ICD</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"delivered")} className={"ttabs "+(this.state.delivered)}>
                            <button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"prt")} href="">Left ICD / Delivered</button>
                        </li>
                    </ul>

                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f24 greenfont"></i><br /> {this.state.counter1}</span>
                                            <h4 className="greenfont f40"><span className="counter"><CountUp end={this.state.total_containers_counter}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer">
                                            <span className="f13"><i className="fa fa-rupee txt-success f24"></i><br /> Shipping Line Charges INR</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.shipping_wise_inr_counter}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer">
                                            <span className="f13"><i className="fa fa-rupee txt-primary f24"></i><br /> ICD Ground Rent Charges INR</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={this.state.ground_charges_counter}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer">
                                            <span className="f13"><i className="fa fa-rupee txt-info f24"></i><br />{this.state.counter2}</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.total_detention_inr_counter}/></span></h4>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div> 
                        <div className="card">
                            <div className="card-header">
                                <h5 style={{float:"left",width:"30%"}}>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Detention Report</span>
                                </h5>
                                <span style={{color:"red",float:"left"}}>* containers arrived on / after Oct 1st 2020 are considered for this report</span>
                                <div className="float-right">
                                    <button
                                        type="button" 
                                        title="Send EMail"
                                        onClick={this.openMailSlider.bind(this)}
                                        className={"btn se-ico btn-warning dropdown-toggle text-muted"}
                                        type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        disabled={this.state.disableMailBtn}
                                        aria-expanded="false">
                                        Send Mail
                                    </button>
                                </div>
                            </div>	
                            <div className="card-body pt-15px" >
                                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        // stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        // onCellEditingStopped={this.onEditStopped.bind(this)}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        rowSelection={"multiple"}
                                        onRowSelected={this.onRowSelected.bind(this)}
                                        suppressRowClickSelection={true}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>`
                    </div>
                </div>
                <div className={"slide-r "+(this.state.inboxSlider)} style={{overflow:"hidden"}}>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            {/* Inbox Start  */}
                            <div className="email-wrap">
                                <div className="row">
                                    {/* <div className={"col-xl-3 col-md-6 "+(this.state.showleftmailside)}>
                                        <div className="email-right-aside">
                                            <div className="card email-body">
                                                <div className="pr-0 b-r-light">
                                                    <div className="email-top n-p-0">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5>
                                                                    Draft
                                                                    <button type="button"
                                                                        onClick={this.onClickDiscardAllMail.bind(this)}
                                                                            className={"btn custom-btn text-center mb-0 mt-0 mr-5p fright "}>
                                                                        <i className="fa fa-trash mr-2"></i> DISCARD ALL
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>

                                                    <div className="inbox">
                                                        {this.state.draftlist.map((item, index) => (
                                                        
                                                            <div className="media cpointer brd-top" onClick={this.onClickDraftItem.bind(this,item)}>
                                                                <div className="media-body">
                                                                    <h6 className="mt-0" style={{textAlign:"left"}}>
                                                                        {item.subject}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className={" col-md-12 "+(this.state.sendmaildiv)}>
                                        <div className="email-right-aside">
                                            <div className="card email-body radius-left">
                                                <div className="pl-0">
                                                    <TabContent className="tab-content" activeTab={this.state.SelectedTab}>
                                                        <TabPane tabId="1">
                                                                <div className="email-compose">
                                                                    <div className="email-top compose-border n-p-0">
                                                                        <div className="row">
                                                                            <div className="col-sm-5 xl-30">
                                                                                <h4 className="mb-0">Send Mail</h4>
                                                                            </div>
                                                                            <div className="col-sm-7 btn-middle xl-70">
                                                                                {/* <button type="button"
                                                                                    onClick={this.onClickSaveMailNow.bind(this)}
                                                                                        className="btn btn-secondary btn-mail text-center mb-0 mt-0 fright">
                                                                                    <i className="fa fa-save mr-2"></i> SAVE
                                                                                </button> */}
                                                                                <button type="button"
                                                                                    onClick={this.onClickSaveAndSendMailNow.bind(this)}
                                                                                        className="btn btn-primary btn-mail text-center mb-0 mt-0 mr-5p fright">
                                                                                    <i className="fa fa-paper-plane mr-2"></i> SEND
                                                                                </button>
                                                                                {/* <button type="button"
                                                                                    onClick={this.onClickDiscardMail.bind(this)}
                                                                                        className={"btn btn-square btn-outline-danger-2x btn-mail red text-center mb-0 mt-0 mr-5p fright "+(this.state.showdiscard)}>
                                                                                    <i className="fa fa-trash mr-2"></i> DISCARD
                                                                                </button> */}
                                                                                
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="email-wrapper">
                                                                        <form className="theme-form">
                                                                            <div className=" row form-group">
                                                                                <label htmlFor="exampleInputEmail1"
                                                                                    className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
                                                                                <input type="email" className="form-control col-xl-10 col-lg-10"
                                                                                    id="exampleInputEmail1" name="toEmail" value={this.state.toEmail}
                                                                                    onChange={this.onChangeHandler} aria-describedby="emailHelp" />
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <label htmlFor="exampleInputEmail1"
                                                                                    className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
                                                                                <input type="email" className="form-control col-xl-10 col-lg-10"
                                                                                    id="exampleInputEmail1" name="ccEmail" value={this.state.ccEmail}
                                                                                        onChange={this.onChangeHandler} aria-describedby="emailHelp" />
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <label
                                                                                    htmlFor="exampleInputPassword1" className=" col-xl-2 col-lg-2">Subject</label>
                                                                                <input type="text" className="form-control col-xl-10 col-lg-10" value={this.state.emailSubject}
                                                                                    onChange={this.onChangeHandler} name="ticketSubject" id="exampleInputPassword1" />
                                                                            </div>
                                                                            <div className="form-group mb-0">
                                                                                <label className="text-muted">Message</label>
                                                                                    <CKEditor
                                                                                        config={ {height : "700px"}}
                                                                                        activeClass="p10"
                                                                                        content={this.state.emailContent}
                                                                                        events={{
                                                                                            "blur": this.onBlur.bind(this),
                                                                                            "afterPaste": this.afterPaste.bind(this),
                                                                                            "updateContent": this.updateContent.bind(this)
                                                                                        }}
                                                                                    />
                                                                            </div>
                                                                            
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                        </TabPane>
                                                        
                                                    </TabContent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Inbox End  */}


                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function getEmailBodyContent(selectedRows){
    var message_body = "<p>Dear Sir/Madam, </p><p>Please find below the containers that are inside the ICD for more than the allowed free period and attracting detention costs as on " + moment().format("YYYY-MM-DD") + ". Please provide the reasons for the delay</p>"

    message_body = message_body + "<table style='color:#333;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;font-size:15px;text-align:center' border='1' cellpadding='3'><thead><th>Sl No.</th><th>ICD Arrival</th><th>Container No</th><th>BL No(s)</th><th>Supplier</th><th>Cargo Description</th><th>Shipping Line</th><th>Container Size</th><th>Plant</th><th>ICD Name</th><th>Total Waiting Period (Days)</th><th>Detention Period (Shipping Line)</th><th>Detention Unit Rate USD (Shipping Line)</th><th>Detention Cost USD (Shipping Line)</th><th>Exchange Rate (INR)</th><th>Total Detention Rate INR (Shipping Line)</th><th>Detention Period (ICD)</th><th>Total Ground Rent Charges in INR Payable  (ICD)</th><th>Total Detention Cost in INR</th><th>User Dept.</th><th>User Email</th><th>Delay Reason</th></thead>"

    selectedRows.map(function(item,index){
        message_body = message_body + "<tr><td>" + (index+1).toString() +"</td>"
        message_body = message_body + "<td>" + item['icd_arrival'] + "</td>"
        message_body = message_body + "<td>" + item['container_no'].toString()  + "</td>"
        message_body = message_body + "<td>" + item['bl_nos'].toString() + "</td>"
        message_body = message_body + "<td>" + item['supplier'] + "</td>"
        message_body = message_body + "<td>" + item['cargo_description'] + "</td>"
        message_body = message_body + "<td>" + item['shipping_line'].toString() + "</td>"
        message_body = message_body + "<td>" + item['container_size'].toString() + "</td>"
        message_body = message_body + "<td>" + item['plant'] + "</td>"
        message_body = message_body + "<td>" + item['icd_source'] + "</td>"
        message_body = message_body + "<td>" + item['duration'].toString() + "</td>"
        message_body = message_body + "<td>" + item['icd_detention_period'].toString() + "</td>"
        message_body = message_body + "<td>" + item['shipping_charge_usd'].toString() + "</td>"
        message_body = message_body + "<td>" + item['shipping_line_cost_usd'].toString() + "</td>"
        message_body = message_body + "<td>" + item['exchange_rate'].toString() + "</td>"
        message_body = message_body + "<td>" + item['shipping_line_cost_inr'].toString() + "</td>"
        message_body = message_body + "<td>" + item['shipping_detention_period'].toString() + "</td>"
        message_body = message_body + "<td>" + item['icd_cost'].toString() + "</td>"
        message_body = message_body + "<td>" + item['total_detention'].toString() + "</td>"
        var userdept = item['user_dept']
        if(userdept == undefined)
        {
            userdept = ""
        }
        message_body = message_body + "<td>" + userdept + "</td>"
        var useremail = item['user_email']
        if(useremail == undefined)
        {
            useremail = ""
        }
        message_body = message_body + "<td>" + useremail + "</td>"
        var delayreason = item['delay_reason']
        if(delayreason == undefined)
        {
            delayreason = ""
        }
        message_body = message_body + "<td>" + delayreason + "</td>"
        message_body = message_body + "</tr>"
    })

    message_body = message_body + "</table><p>Thank you</p>"
    return message_body;
}