/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css"; import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnChartLoadingDashboard from './columnchartdailydashboard';
import ColumnDrillDownDailyRun from './columndrilldowndailyrun';
import LineLoadingDashboard from './linedailyloading';
import ColumnChartDailyPrt from './columnchartdailyprt';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

export default class DailyRunningDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowData: [],
            rowDataExcluded: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            locations: [],
            transporters: [],
            transporter: { value: "all", label: "All" },
            location: { value: "all", label: "All" },
            consigneestates: [],
            dept_code: '',
            departmentarray: [],
            originaldepartmentarray: [],
            scatterdepartmentarray: [],
            categories: [],
            scattercategories: [],
            transporterdata: [],
            transdrilldown: [],
            transportergpsdata: [],
            transgpsdrilldown: [],
            showtranschart: 'show-m',
            showgpschart: 'show-n',
            loadcheck: true,
            gpsloadcheck: false,
            loadshow: 'show-n',
            loadshow2: 'show-n',
            loadshow3: 'show-n',
            loadshow4: 'show-n',
            loadshow5: 'show-n',
            loadshow6: 'show-n',
            overly: 'show-n',
            monthlydata: [],
            monthlyexpdata: [],
            chartdrildown: [],
            showexclude: 0,
            startDate: '',
            endDate: '',
            defaultsdate: '',
            defaultddate: '',
            yearlydata: [],
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            pagetitle: "Daily Running Dashboard",
            eventGridAction: "gridAction",
            transporterwisedata: [],
            plantwisedata: [],
            prtmonthlydata: [],
            consignee_states: { value: "all", label: "All" },
            zone: [{ value: "all", label: "All" }],
            zones: [],
            showcompletedata: [],
            showgriddata: "show-n",
            showchart: "show-m",
            areaList: [],
            areaVal: { value: "all", label: "All" },
            showLineGridData: "show-n",
            showLineChart: "show-m",
            showScatteredPlotChart: "show-m",
            showScatteredPlotGridData: "show-n",
            monthlyRowData: [],
            YearlyRowData: []

        }
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch (e) { }

    }
    componentWillReceiveProps(newprops) {
        if (newprops.dept_code != this.props.dept_code) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction });


            this.setState({
                loadshow: 'show-m',
                loadshow1: 'show-m',
                loadshow2: 'show-m',
                loadshow3: 'show-m',
                overly: 'show-m',
                transporter: { "value": "all", "label": 'All' },
                location: { "value": "all", "label": 'All' },
                showexclude: newprops.showexclude
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var dept_code = encode(newprops.dept_code);
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            var preqparams = {
                dept_code: dept_code
            }
            this.setState({
                dept_code: dept_code,
                startDate: sdate,
                endDate: edate,
                defaultsdate: sdate,
                defaultedate: edate,
                locations: [],
                transporters: []
            })
            var preqparams = {
                dept_code: dept_code
            }
            redirectURL.post("/dashboard/zones", preqparams)
                .then((response) => {
                    var records = response.data.zones;
                    var zones = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            zones.push({
                                value: item,
                                label: item
                            })
                        })
                    }
                    this.setState({
                        zones: zones
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
            redirectURL.post("/consignments/plants", preqparams)
                .then((response) => {
                    var records = response.data;
                    //console.log("records ", records)
                    var locations = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            locations.push({
                                code: item.plant_code,
                                label: item.plant_name,
                            })
                        })
                    }
                    this.setState({
                        locations: locations
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })

            redirectURL.post("/masters/transportersbydept", {
                deptcode: dept_code
            })
                .then((response) => {
                    var records = response.data;
                    var transporters = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            transporters.push({
                                code: item.transporter_code,
                                label: item.transporter_name,
                            })
                        })
                    }
                    this.setState({
                        transporters: transporters
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                });


            redirectURL.post("/consignments/consigneestates", preqparams)
                .then((response) => {
                    var records = response.data;
                    //console.log("records ", records)
                    var cstates = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            if (item != '') {
                                cstates.push({
                                    value: item,
                                    label: item,
                                })
                            }

                        })
                    }
                    this.setState({
                        consigneestates: cstates
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })

            redirectURL.post("/consignments/getAreaListForAreaFilter",).then(response => {
                var records = response.data;
                //console.log("records ", records)
                var areaArr = [];
                if (records.length > 0) {
                    records.map((item) => {
                        if (item != '') {
                            areaArr.push({
                                value: item,
                                label: item,
                            })
                        }

                    })
                }
                this.setState({
                    areaList: areaArr
                })
            })
                .catch(function (e) {
                    console.log("Error ", e)
                })  

            //getPlantWiseLoadingDelayAnalysis
            //console.log(" this.state.transporter ",this.state.transporter)
            var allzones = this.state.zones;
            var zones = []
            if (allzones.length > 0) {
                allzones.map((item) => {
                    zones.push(item.value)
                })
            }
            var parameters = {
                dept_code: dept_code,
                startDate: sdate,
                endDate: edate,
                transporter: [{ "value": "all", "label": 'All' }],
                location: [{ "value": "all", "label": 'All' }],
                consignee_states: [{ "value": "all", "label": 'All' }],
                consignee_areas: [{ "value": "all", "label": 'All' }],
                zones: zones
            }
            if (dept_code != "LOG-PRT") {
                this.onLoadShowDashboard(parameters);
            }
            else {
                this.onLoadShowPRTDashboard(parameters);
            }
        }

    }

    onLoadShowDashboard = (parameters) => {
        redirectURL.post("/dashboard/dailyrunning", parameters)
            .then((response) => {
                //console.log("DASD ", response.data)
                var records = JSON.parse(response.data.zone_wise_data);
                var monthlydata = JSON.parse(response.data.monthly_zone_wise_data)
                var completedata = JSON.parse(response.data.complete_data)
                // var outlier_data = JSON.parse(response.data.outlier_data)
                var route_trans_wise_data = JSON.parse(response.data.zone_trans_wise_data)
                var zone_area_dealer_wise_data = JSON.parse(response.data.zone_area_dealer_wise_data)
                var zone_area_wise_data = JSON.parse(response.data.zone_area_wise_data);
                var zone_area_lsp_wise_data = JSON.parse(response.data.zone_area_lsp_wise_data);
                // console.log("records ", records);
                // console.log("zone_area_wise_data ", zone_area_wise_data);

                var departmentarray = [];
                var loadingarr = [];
                var gpsloadingarr = [];
                var transdrildownarr = [];
                var categories = [];
                records.forEach((item, key) => {
                    //var categories = [];

                    if (item.expected_average_days > 0) {
                        loadingarr.push(Math.round(item.expected_average_days));
                    }
                    if (item.actual_average_days > 0) {
                        categories.push(item.zone)
                        gpsloadingarr.push({
                            name: item.zone,
                            y: Math.round(item.actual_average_days),
                            drilldown: item.zone,
                            trucks: item.no_of_trips
                        });
                    }
                })
                zone_area_wise_data = zone_area_wise_data.sort(GetSortDescOrder("actual_average_days"));
                zone_area_wise_data = groupBy(zone_area_wise_data, rdata => rdata.zone);

                zone_area_wise_data.forEach((item, key) => {

                    var trandrilseries = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            trandrilseries.push({
                                name: itm.consignee_area,
                                y: Math.round(itm.actual_average_days),
                                trucks: itm.no_of_trips,
                                drilldown:key+"-"+itm.consignee_area
                            })
                        })
                    }
                    transdrildownarr.push({
                        name: key,
                        id: key,
                        data: trandrilseries,
                        dataLabels: {
                            enabled: true
                        }
                    })

                })
                // console.log("zone_area_dealer_wise_data ", zone_area_dealer_wise_data)
                zone_area_lsp_wise_data = zone_area_lsp_wise_data.sort(GetSortDescOrder("actual_average_days"));
                zone_area_lsp_wise_data = groupBy(zone_area_lsp_wise_data, rdata => rdata.zone);

                zone_area_lsp_wise_data.forEach((item, key) => {
                   var z = groupBy(item, rdata => rdata.consignee_area);

                    z.forEach((i, k) => {
                        var trandrilseries = [];
                        if (i.length > 0) {
                            i.map((itm) => {
                                if(key == itm.zone)
                                {
                                    trandrilseries.push({
                                        name: itm.transporter_name, 
                                        y:Math.round(itm.actual_average_days),
                                        trucks: itm.no_of_trips
                                    })
                                }
                                
                            })
                        }
                        transdrildownarr.push({
                            name: key+"-"+k,
                            id: key+"-"+k,
                            data: trandrilseries,
                            dataLabels: {
                                enabled: true
                            }
                        })
                    });

                })

                // console.log("transdrildownarr ",transdrildownarr)

                departmentarray.push({

                    series: gpsloadingarr,
                    categories: categories,
                    yaxistitle: "Avg. KM/Day",
                    isfilter: "no",
                    chartdrildown: transdrildownarr,
                })

                var monthcategories = [];
                var monthlydataarr = [];
                var monthlydatagroup = groupBy(monthlydata, rdata => rdata.zone);
                var monthlydatasort = monthlydata.sort(GetSortOrder("invoice_month_order"));
                var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.invoice_month + " " + rdata.invoice_year));
                mncolgroups.forEach((it, ky) => {
                    monthcategories.push(ky)
                })

                var monthgrparr = [];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr = []
                monthlydatagroup.forEach((item, key) => {
                    // var monthloadarr = [];
                    // var monthgpsloadarr = [];
                    // var itemdata = item.sort(GetSortOrder("invoice_month_order"));
                    // itemdata.map((val) => {                        
                    //     //colgroups.forEach((ci,ky) => {
                    //      //   console.log("CI ",ky)
                    //         if(key == val.route_id)
                    //         {
                    //             monthloadarr.push(val.actual_average_days)
                    //             monthgpsloadarr.push(val.expected_average_days)
                    //         }
                    //    // })

                    // });
                    // //console.log(key);
                    // //console.log(item);
                    // //console.log("timeloadarr ", timeloadarr)

                    // monthgrparr.push({
                    //     name:key,
                    //     data:monthloadarr
                    // })
                    // monthgrpgpsarr.push({
                    //     name:key,
                    //     data:monthgpsloadarr
                    // })

                    var monthloadarr = [];
                    var monthgpsloadarr = [];

                    let newArray = [];
                    item.map((mt) => {
                        newArray.push(mt.invoice_month + " " + mt.invoice_year)
                    })

                    if (item.length == monthcategories.length) {
                        item.map((val) => {
                            //console.log("VAL ",val); 

                            if (key == val.zone) {
                                monthloadarr.push(val.actual_average_days)
                                monthgpsloadarr.push(val.expected_average_days)
                            }
                            // })

                        });
                        monthgrparr.push({
                            name: key,
                            data: monthloadarr
                        })
                        monthgpsloadarr.push({
                            name: key,
                            data: monthgpsloadarr
                        })

                    }
                    else {
                        monthcategories.forEach((mn, ky) => {
                            //console.log("MN ", mn)
                            if (newArray.indexOf(mn) >= 0) {
                                item.map((val) => {
                                    if (ky == val.zone) {
                                        var yr = val.invoice_month + " " + val.invoice_year;

                                        if (mn == yr) {
                                            // eslint-disable-next-line no-unused-vars
                                            var tt = mn + "==" + yr + ": " + key + " == " + val.zone
                                            //console.log(tt)
                                            monthloadarr.push(val.actual_average_days)
                                            monthgpsloadarr.push(val.expected_average_days)
                                        }

                                    }

                                })
                            }
                            else {
                                monthloadarr.push(0)
                                monthgpsloadarr.push(0)
                            }
                        })

                        monthgrparr.push({
                            name: key,
                            data: monthloadarr
                        })
                        monthgpsloadarr.push({
                            name: key,
                            data: monthgpsloadarr
                        })
                    }

                })
                // mncolgroups.sort(GetSortOrder("month_order"));

                monthlydataarr.push({
                    categories: monthcategories,
                    lineseries: monthgrparr
                })
                monthlygpsdataarr.push({
                    categories: monthcategories,
                    lineseries: monthgrpgpsarr
                })

                //console.log("monthlydataarr ",monthlydataarr)
                this.setState({
                    departmentarray: departmentarray,
                    originaldepartmentarray: departmentarray,
                    monthlydata: monthlydataarr,
                    monthlyexpdata: monthlygpsdataarr,
                    loadshow: 'show-n',
                    loadshow1: 'show-n',
                    loadshow2: 'show-n',
                    overly: 'show-n',
                    rowData: completedata,
                    // rowDataExcluded:outlier_data
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })


        redirectURL.post("/dashboard/dailyRunningRouteWiseAnalysis", parameters)
            .then((response) => {
                var yearly_route_wise_data = JSON.parse(response.data.yearly_zone_wise_data);
                console.log("yearly_route_wise_data", yearly_route_wise_data)
                var yearcategories = [];
                var yeardataarr = [];
                var yearlydatagroup = groupBy(yearly_route_wise_data, rdata => rdata.zone);
                //var yeardatasort = monthlydata.sort(GetSortOrder("invoice_month_order"));
                var mncolgroups = groupBy(yearly_route_wise_data, rdata => (rdata.year));

                var monthcategories = [];
                var monthlydataarr = [];
                var mcates = []
                var existsmonths = []
                mncolgroups.forEach((mon, ky) => {
                    existsmonths.push(ky)
                })
                var yearlydataarr = [];
                var monthgrparr = [];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr = []
                yearlydatagroup.forEach((item, key) => {
                    var monthloadarr = [];
                    var monthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("year"));

                    if (item.length == existsmonths.length) {
                        itemdata.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.year;

                            if (key == val.zone) {
                                monthloadarr.push(val.actual_average_days)
                            }
                            // })

                        });
                        monthgrparr.push({
                            name: key,
                            data: monthloadarr
                        })

                    }
                    else {
                        existsmonths.forEach((mn, ky) => {
                            //console.log(ky)
                            itemdata.map((val) => {
                                var yr = val.year;

                                if (mn == yr) {
                                    monthloadarr.push(val.actual_average_days)
                                }
                                else {
                                    monthloadarr.push(0)
                                }
                            })
                        })

                        monthgrparr.push({
                            name: key,
                            data: monthloadarr
                        })

                    }

                })
                // mncolgroups.sort(GetSortOrder("month_order"));
                //console.log("mncolgroups ",mncolgroups)
                mncolgroups.forEach((it, ky) => {
                    monthcategories.push(ky)
                })
                yearlydataarr.push({
                    categories: monthcategories,
                    lineseries: monthgrparr
                })
                // monthlygpsdataarr.push({
                //     categories:monthcategories,
                //     lineseries:monthgrpgpsarr
                // })
                this.setState({
                    yearlydata: yearlydataarr
                });

            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }



    onLoadShowPRTDashboard = (parameters) => {
        redirectURL.post("/dashboard/dailyrunning", parameters)
            .then((response) => {
                console.log("DASD ", response.data)
                var records = JSON.parse(response.data.dealer_state_wise_data);
                var plant_wise_data = JSON.parse(response.data.plant_wise_data)
                var completedata = JSON.parse(response.data.complete_data)
                // var outlier_data = JSON.parse(response.data.outlier_data)
                var route_trans_wise_data = JSON.parse(response.data.dealer_state_trans_wise_data)
                var trans_wise_data = JSON.parse(response.data.trans_wise_data);
                var prtmonthlydata = JSON.parse(response.data.monthly_dealer_state_wise_data);
                var departmentarray = [];
                var loadingarr = [];
                var gpsloadingarr = [];
                var transdrildownarr = [];
                var categories = [];
                records.forEach((item, key) => {
                    //var categories = [];
                    categories.push(item.consignee_state)
                    if (item.expected_average_days > 0) {
                        loadingarr.push(Math.round(item.expected_average_days));
                    }
                    if (item.actual_average_days > 0) {
                        gpsloadingarr.push({
                            name: item.consignee_state,
                            y: Math.round(item.actual_average_days),
                            drilldown: item.consignee_state
                        });
                    }
                })
                var route_trans_wise_data = route_trans_wise_data.sort(GetSortDescOrder("actual_average_days"));
                route_trans_wise_data = groupBy(route_trans_wise_data, rdata => rdata.consignee_state);

                route_trans_wise_data.forEach((item, key) => {

                    var trandrilseries = [];
                    if (item.length > 0) {
                        item.map((itm) => {
                            trandrilseries.push([itm.transporter_name, Math.round(itm.actual_average_days)])
                        })
                    }
                    transdrildownarr.push({
                        name: key,
                        id: key,
                        data: trandrilseries,
                        dataLabels: {
                            enabled: true
                        }
                    })

                })

                //console.log("transdrildownarr ",transdrildownarr)

                departmentarray.push({

                    series: gpsloadingarr,
                    categories: categories,
                    yaxistitle: "Avg. KM/Day",
                    isfilter: "no",
                    chartdrildown: transdrildownarr,
                })
                var transloadingarr = []
                var transgpsloadingarr = []
                var transcategories = []
                trans_wise_data.forEach((item, key) => {
                    //var categories = [];
                    if (item.expected_average_days > 0) {
                        transloadingarr.push(Math.round(item.expected_average_days));
                    }
                    if (item.actual_average_days > 0) {
                        transcategories.push(item.transporter_name)
                        transgpsloadingarr.push(Math.round(item.actual_average_days));
                    }
                })
                var transporterwisedata = []

                transporterwisedata.push({

                    series: [{
                        name: "Transporters",
                        data: transgpsloadingarr,
                        dataLabels: {
                            enabled: true
                        }
                    }],
                    categories: transcategories,
                    yaxistitle: "Avg. KM/Day",
                    isfilter: "no",
                    chartdrildown: [],
                })
                //console.log("transporterwisedata ", transporterwisedata)

                var plantwisedata = []
                var plantcats = []
                var plantloadingarr = []
                var plantgpsloadingarr = []
                plant_wise_data.forEach((item, key) => {
                    //var categories = [];
                    plantcats.push(item.plant_location)
                    if (item.expected_average_days > 0) {
                        plantloadingarr.push(Math.round(item.expected_average_days));
                    }
                    if (item.actual_average_days > 0) {
                        plantgpsloadingarr.push(Math.round(item.actual_average_days));
                    }
                })
                plantwisedata.push({

                    series: [{
                        name: "Plants",
                        data: plantgpsloadingarr,
                        dataLabels: {
                            enabled: true
                        }
                    }],
                    categories: plantcats,
                    yaxistitle: "Avg. KM/Day",
                    isfilter: "no",
                    chartdrildown: [],
                })
                var prtmonthcategories = [];
                var prtmonthlydataarr = [];
                var prtmonthlydatagroup = groupBy(prtmonthlydata, rdata => rdata.consignee_state);
                var prtmonthlydatasort = prtmonthlydata.sort(GetSortOrder("gate_out_month_order"));
                var prtmncolgroups = groupBy(prtmonthlydatasort, rdata => (rdata.gate_out_month + " " + rdata.gate_out_year));


                var prtmonthgrparr = [];
                var prtmonthgrpgpsarr = [];
                var prtmonthlygpsdataarr = []
                // prtmonthlydatagroup.forEach((item, key) =>{
                //     var prtmonthloadarr = [];
                //     var prtmonthgpsloadarr = [];
                //     var itemdata = item.sort(GetSortOrder("gate_out_month_order"));
                //     itemdata.map((val) => {                        
                //         //colgroups.forEach((ci,ky) => {
                //          //   console.log("CI ",ky)
                //             if(key == val.consignee_state)
                //             {
                //                 prtmonthloadarr.push(val.actual_average_days)
                //                 prtmonthgpsloadarr.push(val.expected_average_days)
                //             }
                //        // })

                //     });
                //     //console.log(key);
                //     //console.log(item);
                //     //console.log("timeloadarr ", timeloadarr)

                //     prtmonthgrparr.push({
                //         name:key,
                //         data:prtmonthloadarr
                //     })
                //     prtmonthgrpgpsarr.push({
                //         name:key,
                //         data:prtmonthgpsloadarr
                //     })
                // })
                var timecategories = [];
                var timeslotarrdata = [];
                var timeslotarrgpsdata = [];

                var timeslotgrparr = []
                var timeslotgrpgpsarr = []

                prtmncolgroups.forEach((it, ky) => {
                    timecategories.push(ky)
                })
                prtmonthlydatagroup.forEach((item, key) => {
                    var timeloadarr = [];
                    var timegpsloadarr = [];

                    let newArray = [];
                    item.map((mt) => {
                        newArray.push(mt.gate_out_month + " " + mt.gate_out_year)
                    })
                    //console.log("item.length " , item.length)
                    if (item.length == timecategories.length) {
                        item.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.gate_out_month + " " + val.gate_out_year;

                            if (key == val.consignee_state) {
                                timeloadarr.push(val.actual_average_days)
                                timegpsloadarr.push(val.expected_average_days)
                            }
                            // })

                        });
                        timeslotgrparr.push({
                            name: key,
                            data: timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name: key,
                            data: timegpsloadarr
                        })

                    }
                    else {
                        timecategories.forEach((mn, ky) => {
                            //console.log("MN ", mn)
                            if (newArray.indexOf(mn) >= 0) {
                                item.map((val) => {
                                    if (key == val.consignee_state) {
                                        var yr = val.gate_out_month + " " + val.gate_out_year;

                                        if (mn == yr) {
                                            var tt = mn + "==" + yr + ": " + key + " == " + val.consignee_state
                                            //console.log(tt)
                                            timeloadarr.push(val.actual_average_days)
                                            timegpsloadarr.push(val.expected_average_days)
                                        }
                                        // else{
                                        //     var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                        //     console.log("Elskey ", key)
                                        //     console.log("newArray ", newArray)    
                                        //     if(newArray.indexOf(mn) >= 0)
                                        //     {
                                        //         console.log("Here ", mn)
                                        //     }
                                        //     else{
                                        //         console.log("Else ",tt)
                                        //         if(key == val.consigner_code)
                                        //         {
                                        //             timeloadarr.push(0)
                                        //             timegpsloadarr.push(0)
                                        //         }
                                        //     }
                                        // }
                                    }

                                })
                            }
                            else {
                                timeloadarr.push(0)
                                timegpsloadarr.push(0)
                            }
                        })

                        timeslotgrparr.push({
                            name: key,
                            data: timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name: key,
                            data: timegpsloadarr
                        })

                    }
                })
                timeslotarrdata.push({
                    categories: timecategories,
                    lineseries: timeslotgrparr
                })
                timeslotarrgpsdata.push({
                    categories: timecategories,
                    lineseries: timeslotgrpgpsarr
                })
                // mncolgroups.sort(GetSortOrder("month_order"));
                //console.log("mncolgroups ",mncolgroups)
                //    prtmncolgroups.forEach((it,ky) =>{
                //     prtmonthcategories.push(ky)
                //     })
                //     prtmonthlydataarr.push({
                //         categories:prtmonthcategories,
                //         lineseries:prtmonthgrparr
                //     })
                //     prtmonthlygpsdataarr.push({
                //         categories:prtmonthcategories,
                //         lineseries:prtmonthgrpgpsarr
                //     })
                //console.log("prtmonthlydataarr ", timeslotarrdata)
                //console.log("prtmonthlygpsdataarr ", timeslotarrgpsdata)
                this.setState({
                    departmentarray: departmentarray,
                    originaldepartmentarray: departmentarray,
                    transporterwisedata: transporterwisedata,
                    plantwisedata: plantwisedata,
                    prtmonthlydata: timeslotarrdata,
                    //monthlyexpdata:monthlygpsdataarr,
                    loadshow: 'show-n',
                    loadshow1: 'show-n',
                    loadshow2: 'show-n',
                    overly: 'show-n',
                    rowData: completedata,
                    // rowDataExcluded:outlier_data
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })


        // redirectURL.post("/dashboard/dailyRunningRouteWiseAnalysis", parameters)
        // .then((response) => {
        //     var yearly_route_wise_data = JSON.parse(response.data.yearly_route_wise_data);

        //     console.log("dailyRunningRouteWiseAnalysis ", yearly_route_wise_data)

        //     var yearcategories = [];
        //     var yeardataarr = [];
        //     var yearlydatagroup = groupBy(yearly_route_wise_data, rdata => rdata.route_id);
        //     //var yeardatasort = monthlydata.sort(GetSortOrder("invoice_month_order"));
        //     var mncolgroups = groupBy(yearly_route_wise_data, rdata => (rdata.year));

        //     console.log("mncolgroups ", mncolgroups)

        //     var monthcategories = [];
        //     var monthlydataarr = [];
        //     var mcates = []
        //     var existsmonths = []
        //     mncolgroups.forEach((mon,ky) => {
        //         existsmonths.push(ky)
        //     })
        //     var yearlydataarr = [];
        //     var monthgrparr=[];
        //     var monthgrpgpsarr = [];
        //     var monthlygpsdataarr=[]
        //     yearlydatagroup.forEach((item, key) =>{
        //         var monthloadarr = [];
        //         var monthgpsloadarr = [];
        //         var itemdata = item.sort(GetSortOrder("year"));

        //         if(item.length == existsmonths.length)
        //         {
        //             itemdata.map((val) => {
        //                 //console.log("VAL ",val); 
        //                 var yr = val.year;

        //                 if(key == val.route_id)
        //                 {
        //                     monthloadarr.push(val.actual_average_days)
        //                 }
        //                 // })

        //             });
        //             monthgrparr.push({
        //                 name:key,
        //                 data:monthloadarr
        //             })

        //         }
        //         else{
        //             existsmonths.forEach((mn, ky)=>{
        //                 //console.log(ky)
        //                 itemdata.map((val) => { 
        //                     var yr = val.year;

        //                     if(mn == yr)
        //                     {
        //                         monthloadarr.push(val.actual_average_days)
        //                     }
        //                     else{
        //                         monthloadarr.push(0)
        //                     }
        //                 })
        //             })

        //             monthgrparr.push({
        //                 name:key,
        //                 data:monthloadarr
        //             })

        //         }

        //     })
        //     // mncolgroups.sort(GetSortOrder("month_order"));
        //     //console.log("mncolgroups ",mncolgroups)
        //     mncolgroups.forEach((it,ky) =>{
        //         monthcategories.push(ky)
        //     })
        //     yearlydataarr.push({
        //         categories:monthcategories,
        //         lineseries:monthgrparr
        //     })
        //     // monthlygpsdataarr.push({
        //     //     categories:monthcategories,
        //     //     lineseries:monthgrpgpsarr
        //     // })
        //     console.log("yearlydataarr ", yearlydataarr)
        //     this.setState({
        //         yearlydata:yearlydataarr
        //     });

        // })
        // .catch(function(e){
        //     console.log("Error ", e)
        // })

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onSelectLocation = location => {
        this.setState(
            { location },
            () => console.log(`Location Option selected:`, this.state.location)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectZone = zone => {
        this.setState(
            { zone },
            () => console.log(`zone Option selected:`, this.state.zone)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectTransporter = transporter => {
        this.setState(
            { transporter }
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectArea = areaVal => {
        this.setState(
            { areaVal }, () => console.log(this.state.areaVal)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onSelectConsigneeState = consignee_states => {
        this.setState(
            { consignee_states }
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    consigneestatesoptions() {
        var items = [];
        var consigneestates = this.state.consigneestates;
        if (consigneestates.length > 0) {
            items.push({ value: 'all', label: 'All' });
            consigneestates.forEach(item => {
                items.push({ value: item.value, label: item.label });
            });
        }
        return items;
    }
    locationoptions() {
        var items = [];
        // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if (locations.length > 0) {
            items.push({ value: 'all', label: 'All' });
            locations.forEach(item => {
                items.push({ value: item.code, label: item.label });
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions() {
        var items = []
        var transporters = this.state.transporters;
        items.push({ value: 'all', label: 'All' });
        if (transporters.length > 0) {
            transporters.forEach(item => {
                items.push({ value: item.code, label: item.label });
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var startdate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = (d.getMonth() + 1)
        if (mm < 10) {
            var month = "0" + mm
        }
        else {
            var month = mm
        }

        var edate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            endDate: edate
        });
    }
    onClickGetLoadingData = (event) => {
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Filters",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m',
            overly: "show-m",
            departmentarray: []
        })
        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var startDate = this.state.defaultsdate;
        }
        else {
            var startDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
            var endDate = this.state.defaultedate;
        }
        else {
            var endDate = this.state.endDate;
        }
        if (this.state.location == '' || this.state.location == null) {
            var location = [{ "value": "all", "label": "All" }];
        }
        else {
            var location = this.state.location;
        }
        if (this.state.consignee_states == '' || this.state.consignee_states == null) {
            var consignee_states = [{ "value": "all", "label": "All" }];
        }
        else {
            var consignee_states = this.state.consignee_states;
        }

        if (this.state.transporter == '' || this.state.transporter == null) {
            var transporter = [{ "value": "all", "label": "All" }];
        }
        else {
            var transporter = this.state.transporter;
        }
        if (this.state.areaVal == '' || this.state.areaVal == null) {
            var areaVal = [{ "value": "all", "label": "All" }];
        }
        else {
            var areaVal = this.state.areaVal;
        }

        if (Array.isArray(location) == true) {
            var locations = location
        }
        else {
            var locations = [location]
        }
        if (Array.isArray(transporter) == true) {
            var transporters = transporter
        }
        else {
            var transporters = [transporter]
        }
        if (Array.isArray(areaVal) == true) {
            var consignee_areas = areaVal
        }
        else {
            var consignee_areas = [areaVal]
        }

        if (Array.isArray(consignee_states) == true) {
            var consigneestates = consignee_states
        }
        else {
            var consigneestates = [consignee_states]
        }
        var allzones = this.state.zone;
        // console.log("allzone ", allzones);
        var zones = []
        if (allzones != null) {
            if (allzones.length > 0) {
                allzones.map((item) => {
                    zones.push(item.value)
                })
            }
        }
        else {
            zones.push('all');
        }

        var parms = {
            startDate: startDate,
            endDate: endDate,
            location: locations,
            transporter: transporters,
            dept_code: this.state.dept_code,
            consignee_states: consigneestates,
            zones: zones,
            consignee_areas,
        }
        if (this.state.dept_code == "LOG-PRT") {
            this.onLoadShowPRTDashboard(parms)
        }
        else {
            this.onLoadShowDashboard(parms)
        }

    }


    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        //    ChartMenu.prototype.saveChart = function (e) {
        // 	   var event = {
        // 	      data: this.gridOptionsWrapper.getApi(),
        // 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
        // 	    };
        // 	   //console.log("Testing", event);
        // 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
        // 	   this.dispatchEvent(event);
        // 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

        //    };


    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }

    onChangeChartView(params) {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Change Chart View",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (params == 'gpsloading') {
            this.setState({
                gpsloadcheck: true,
                loadcheck: false,
                showgpschart: 'show-m',
                showtranschart: 'show-n'
            })
        }
        if (params == 'loading') {
            this.setState({
                gpsloadcheck: false,
                loadcheck: true,
                showgpschart: 'show-n',
                showtranschart: 'show-m'
            })
        }
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',

        });

    }
    onClickShowChartGrid() {
        this.setState({
            showgriddata: "show-m",
            showchart: "show-n"
        })
    }

    onClickShowMonthlyChartGrid = () => {
        this.setState({
            showLineGridData: "show-m",
            showLineChart: "show-n"
        })
    }
    onClickShowChartView() {
        this.setState({
            showgriddata: "show-n",
            showchart: "show-m"
        })
    }

    onClickShowMonthlyChartView = () => {
        this.setState({
            showLineGridData: "show-n",
            showLineChart: "show-m"
        })
    }

    onClickShowYearlyChartGrid = () => {
        this.setState({
            showScatteredPlotGridData: "show-m",
            showScatteredPlotChart: "show-n"
        })
    }

    onClickShowYearlyChartView = () => {
        this.setState({
            showScatteredPlotGridData: "show-n",
            showScatteredPlotChart: "show-m"
        })
    }
    render() {
        var hideinvoice = (this.state.dept_code == 'LOG-PRT') ? true : false;
        var showprt = (this.state.dept_code == 'LOG-PRT') ? false : false;
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },

            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Area",
                field: "consignee_area",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    //console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            // {
            //     headerName:"Cluster",
            //     field:"cluster",
            //     width:120,
            //     hide:hideinvoice
            // },
            {
                headerName: "Zone",
                field: "zone",
                width: 120,
                hide: hideinvoice
            },
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Dealer City",
                field: "consignee_city",
                width: 150,
                hide: showprt
            },
            {
                headerName: "Dealer State",
                field: "consignee_state",
                width: 150,
                hide: showprt
            },
            {
                headerName: "Trip Distance (KM)",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            // {
            //     headerName:" Trip Duration ( + 0.25 days)",
            //     field:"trip_completion_days",
            //     width:150
            // },
            {
                headerName: " Trip Distance Range (KM)",
                field: "standard_distance_bin",
                width: 150,
                hide: hideinvoice
            },
            {
                headerName: "Standard Avg. Distance/Day (KM)",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day (KM)",
                field: "actual_average_days",
                width: 150
            }
        ]

        let monthlyColumnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },

            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Area",
                field: "consignee_area",
                width: 150
            },
        ]

        let yearlyColumnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },

            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Area",
                field: "consignee_area",
                width: 150
            },
        ]

        var columnwithDefsExclude = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    // console.log(params.data.gate_out_time)
                    if (typeof params.data.gate_out_time != 'undefined') {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return '';
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                // 	browserDatePicker: true,
                // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                // 	// provide comparator function
                // 	comparator: function(filteredDate,cellValue,secondDate) {
                // 		cellValue = cellValue.replace(/\//g,"-")
                // 		if(cellValue != "" && cellValue != " "){
                // 			cellValue = cellValue.split(" ")[0].split("-");
                // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                // 			var cellDate = new Date(cellValue);
                // 			if(filteredDate.getMonth() < 10){
                // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			else{
                // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                // 			}
                // 			if(filterValue.split("-")[2].length < 2)
                // 			{
                // 				var temp = filterValue
                // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                // 			}
                // 			var filteredDate = new Date(filterValue);
                // 			cellDate = cellDate.getTime();
                // 			filteredDate = filteredDate.getTime();
                // 			// console.log(cellDate,filteredDate);
                // 			if(cellDate === filteredDate)
                // 			{
                // 				return 0;
                // 			}
                // 			if(cellDate < filteredDate){
                // 				return -1;
                // 			}
                // 			if(cellDate > filteredDate)
                // 			{
                // 				return 1;
                // 			}
                // 		}
                // 	}
                // }, 
            },
            // {
            //     headerName:"Invoice Time",
            //     field:"invoice_time",
            //     width:150,
            //     valueGetter:function(params){
            //         return getDDMMYYYYHHMMSS(params.data.invoice_time)
            //     }
            // },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 180
            },
            {
                headerName: "Plant Name",
                field: "plant_location",
                width: 150
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 120
            },
            {
                headerName: "Route",
                field: "route_id",
                width: 120
            },
            {
                headerName: "Consignee Code",
                field: "consignee_code",
                width: 150
            },
            {
                headerName: "Trip Distance",
                field: "trip_distance",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.trip_distance)
                }
            },
            {
                headerName: "Transit Time (Days)",
                field: "transit_time_days",
                width: 150,
                valueGetter: function (params) {
                    return parseInt(params.data.transit_time_days)
                }
            },
            {
                headerName: "Trip Closure Time",
                field: "trip_closure_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_closure_time)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: " Trip Duration ( + 0.25 days)",
                field: "trip_completion_days",
                width: 150
            },
            {
                headerName: " Trip Distance Range (KM)",
                field: "standard_distance_bin",
                width: 150
            },
            {
                headerName: "Standard Avg. Distance/Day (KM)",
                field: "expected_average_days",
                width: 150
            },
            {
                headerName: "Actual Avg Distance/Day (KM)",
                field: "actual_average_days",
                width: 150
            }
        ]

        return (
            <div className="row pt-20px tab-brd">
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Start Date: </label>
                        <Datetime value={this.state.startDate}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                            dateFormat="YYYY-MM-DD"
                            name="startDate"
                            onChange={this.handlerStartDateTime} />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>End Date: </label>
                        <Datetime value={this.state.endDate}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                            dateFormat="YYYY-MM-DD"
                            name="endDate"
                            onChange={this.handlerEndDateTime} />
                    </div>
                    {(this.state.dept_code == "LOG-PRT") ?
                        <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                            <label>Consignee State: </label>
                            <Select placeholder={"All"} isMulti={true} value={this.state.consignee_states} className="border-radius-0" onChange={this.onSelectConsigneeState} style={{ borderRadius: "0px" }} options={this.consigneestatesoptions()} required />
                        </div>
                        : ""}

                    <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                        <label>Zone: </label>
                        <Select placeholder={"All"}
                            isMulti={true}
                            value={this.state.zone}
                            className="border-radius-0"
                            onChange={this.onSelectZone}
                            style={{ borderRadius: "0px" }}
                            options={this.state.zones}
                            required />
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                        <label>Plant: </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation} style={{ borderRadius: "0px" }} options={this.locationoptions()} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Transporter: </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter} style={{ borderRadius: "0px" }} options={this.transporteroptions()} required />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>Area: </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.areaVal} className="border-radius-0" onChange={this.onSelectArea} style={{ borderRadius: "0px" }} options={this.state.areaList} />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData}>Submit</button>
                    </div>
                </div>
                <div className="row col-xl-12 col-lg-12">
                    <div className="row col-xl-12 col-lg-12 mb-40p">
                        {this.state.departmentarray.length > 0 ?

                            this.state.departmentarray.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <span className={this.state.showchart}>
                                        <ColumnDrillDownDailyRun
                                            title={"Daily Avg. Running (KM)"}
                                            subtitle={"Avg. distance traveled by the carriers per day"}
                                            context={this}
                                            series={item.series}
                                            drildown={item.chartdrildown}
                                            categories={item.categories}
                                            yaxistitle={item.yaxistitle}
                                            isfilter={item.isfilter}
                                            dept_code={this.state.dept_code}
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showchart)}
                                        onClick={this.onClickShowChartGrid.bind(this)}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showgriddata)}
                                        onClick={this.onClickShowChartView.bind(this)}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showgriddata)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Daily Avg. Running (KM)</h4>
                                        <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>Avg. distance traveled by the carriers per day</h6>
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />

                                        </div>
                                    </div>
                                </div>


                            )
                            :
                            <div className="col-xl-12 col-lg-12">
                                <ColumnDrillDownDailyRun
                                    title={"Daily Avg. Running (KM)"}
                                    subtitle={"Avg. distance traveled by the carriers per day"}
                                    context={this}
                                    series={[]}
                                    drildown={[]}
                                    categories={[]}
                                    yaxistitle={""}
                                    isfilter={""}
                                    dept_code={this.state.dept_code}
                                />
                            </div>
                        }

                        {this.state.monthlydata.length > 0 ?

                            this.state.monthlydata.map(item =>
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showLineChart}>
                                        <LineLoadingDashboard
                                            title={'Daily Avg. Running (Monthly)'}
                                            subtitle={"Monthly Avg. distance traveled by the carriers per day"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. KM/Day"}
                                            pointpostfix={"Days"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showLineChart)}
                                        onClick={this.onClickShowMonthlyChartGrid}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showLineGridData)}
                                        onClick={this.onClickShowMonthlyChartView}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showLineGridData)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                        Daily Avg. Running (Monthly)</h4>
                                        <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                        Monthly Avg. distance traveled by the carriers per day</h6>
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                // columnDefs={monthlyColumnwithDefs}
                                                // defaultColDef={this.state.defaultColDef}
                                                // rowData={this.state.monthlyRowData}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            )
                            : ""}

                        {this.state.yearlydata.length > 0 ?

                            this.state.yearlydata.map(item =>
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showScatteredPlotChart}>
                                        <LineLoadingDashboard
                                            title={'Daily Avg. Running (Yearly)'}
                                            subtitle={"Yearly Avg. distance traveled by the carriers per day"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. KM/Day"}
                                            pointpostfix={"Days"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showScatteredPlotChart)}
                                        onClick={this.onClickShowYearlyChartGrid}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showScatteredPlotGridData)}
                                        onClick={this.onClickShowYearlyChartView}
                                        style={{ position: "absolute", right: "30px", top: "20px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showScatteredPlotGridData)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                        Daily Avg. Running (Yearly</h4>
                                        <h6 style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                        Yearly Avg. distance traveled by the carriers per day</h6>
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                // columnDefs={yearlyColumnwithDefs}
                                                // defaultColDef={this.state.defaultColDef}
                                                // rowData={this.state.YearlyRowData}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            )
                            : ""}
                        <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                    </div>
                    <div className="row col-xl-12 col-lg-12 mb-40p">
                        {this.state.transporterwisedata.length > 0 ?

                            this.state.transporterwisedata.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <ColumnChartDailyPrt
                                        title={"Transporter wise Daily Avg. Running (KM)"}
                                        subtitle={"Avg. distance traveled by the carriers per day"}
                                        context={this}
                                        series={item.series}
                                        // drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        yaxistitle={item.yaxistitle}
                                        prefex={"KM/DAY"}
                                        isfilter={item.isfilter}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>

                            )
                            : ""
                        }
                    </div>
                    <div className="row col-xl-12 col-lg-12 mb-40p">
                        {this.state.plantwisedata.length > 0 ?

                            this.state.plantwisedata.map(item =>
                                <div className="col-xl-12 col-lg-12">
                                    <ColumnChartDailyPrt
                                        title={"Warehouse Daily Avg. Running (KM)"}
                                        subtitle={"Avg. distance traveled by the carriers per day"}
                                        context={this}
                                        series={item.series}
                                        // drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        yaxistitle={item.yaxistitle}
                                        prefex={"KM/DAY"}
                                        isfilter={item.isfilter}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>

                            )
                            : ""
                        }
                    </div>
                    {this.state.prtmonthlydata.length > 0 ?

                        this.state.prtmonthlydata.map(item =>
                            <div className={"col-xl-12 col-lg-12 "}>
                                <LineLoadingDashboard
                                    title={'Daily Avg. Running (Monthly)'}
                                    subtitle={"Monthly Avg. distance traveled by the carriers per day"}
                                    context={this}
                                    series={item.lineseries}
                                    categories={item.categories}
                                    xaxistitle={""}
                                    yaxistitle={"Avg. KM/Day"}
                                    pointpostfix={"Days"}
                                    dept_code={this.state.dept_code}
                                />
                            </div>

                        )
                        : ""}
                    <div className={"row col-xl-12 col-lg-12 " + (this.state.showtranschart)}>
                        <h5>Trip wise Details</h5>
                        <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                            />

                        </div>
                        <div className={"dataLoadpage " + (this.state.loadshow1)}></div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow1)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                    </div>
                    {(this.state.showexclude == 1) ?
                        <div className={"row col-xl-12 col-lg-12 " + (this.state.showtranschart)}>
                            <h5>Excluded Trips</h5>
                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowDataExcluded}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                />

                            </div>
                            <div className={"dataLoadpage " + (this.state.loadshow2)}></div>
                            <div className={"dataLoadpageimg " + (this.state.loadshow2)}>
                                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                            </div>
                        </div>
                        : ""}


                    <div className={"overlay " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                </div>

            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


$(document).ready(function () {

    $(".loadclick").click(function () {
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#" + id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}


