
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiActions = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        console.log("Overspeed ",props);
        props.context.componentParent.onShowOverspeedKpi(props);
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Overspeed"><i className="fa fa-tachometer txt-success"></i> &nbsp;</button>
            
        </div>
    );
};

export default KpiActions;
