'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import download from 'js-file-download';
import FFgridExportsData from "./ffexportsgriddata";
import ChagridExportsData from "./chaexportsgriddata";
import TransExportsgridData from "./transexportsgriddata";
import ClosureExportsgridData from "./closureexportsgriddata";
import PreclosureExportsgridData from "./preclosureexportsgridData";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class AirCargoExportListComponent extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        erpData:[],
        ringiData:[],
        pickupData:[],
        sliderTitle:"",
        erpLength : 0,
        ringiLength : 0,
        cargoLength : 0,
        allCnt : 0,
        pendingCnt : 0,
        ffCnt : 0,
        chaCnt : 0,
        transCnt : 0,
        closureCnt : 0,
        pending:"activet",
        ffupdates:"",
        chaupdates:"",
        tranupdates:"",
        closure:"",
        preclosure:"",
        alldetails:"",
        dataType:0,
        pendingData:"show-m",
        ffData:"show-n",
        chaData:"show-n",
        tranData:"show-n",
        closureData:"show-n",
        preclosureData:"show-n",
        country:[],
        criteria:[],
        dataType:0,
        rejectRowId:"",
        rejectedreason:0
        //components : {datepicker:getDatePicker()}
    }
    this.downloadRingiFile = this.downloadRingiFile.bind(this);
    this.rejectCargoRequest = this.rejectCargoRequest.bind(this);
  }

componentDidMount(){
    loadDateTimeScript();
    this.onLoadPageData(0);
    
}
onLoadPageData(dataType){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
        dataType : dataType,
    });
    var country = this.state.country;
    var criteria = this.state.criteria;
    var startDate = $("#from_date").val();
    var endDate = $("#to_date").val();
    var query = {};
    var check = 0;
    if(country !=undefined && country !='')
    {
        query.country = country.value;
    }
    if(criteria !=undefined && criteria !='' && startDate !=undefined && startDate !='' && endDate !=undefined && endDate !='')
    {
        query.criteria = criteria.value;
        query.startDate = startDate;
        query.endDate = endDate;
    }
    redirectURL.post("/consignments/getAirCargoExports",query)    
    .then((response) => {
        if(dataType == 0)
        {  
            var results =  response.data.pendingData;
        }
        else if(dataType == 1)
        {
            var results =  response.data.ffData;
        }
        else if(dataType == 2)
        {
            var results =  response.data.chaData;
        }
        else if(dataType == 3)
        {
            var results =  response.data.transData;
        }
        else if(dataType == 4)
        {
            var results =  response.data.closureData;
        }
        else if(dataType == 5)
        {
            var results = response.data.allData
        }
        else if(dataType == 6){
            var results =  response.data.preClosureData;
        }
        var records = results.records;
        var ringiLength = results.ringiLength;
        var cargoLength = results.cargoLength;
        var allCnt = response.data.allData.records.length;
        var pendingCnt = response.data.pendingData.records.length;
        var ffCnt = response.data.ffData.records.length;
        var chaCnt = response.data.chaData.records.length;
        var transCnt = response.data.transData.records.length;
        var closureCnt = response.data.closureData.records.length;
        this.setState({
            rowData : records,
            ringiLength : ringiLength,
            cargoLength : cargoLength,
            allCnt : allCnt,
            pendingCnt : pendingCnt,
            ffCnt : ffCnt,
            chaCnt : chaCnt,
            transCnt : transCnt,
            closureCnt : closureCnt,
            loadshow:'show-n',
            overly:'show-n',
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
    
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        erpData:[],
        ringiData:[],
        pickupData:[],
        rejectedreason:"",
    });
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-60"
    })
}

resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}

viewDetails(e){
    if(e.colDef.field == "ringiData")
    {
        var ringiData = JSON.parse(e.data.ringiData);
        var sliderTitle = e.data.commercial_invoice+" - Payment Ringi Details";
        this.setState({
            overly :"show-m",
            ringiData:ringiData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "pickupData")
    {
        var pickupData = JSON.parse(e.data.pickupData);
        var sliderTitle = e.data.commercial_invoice+" - Cargo Details";
        this.setState({
            overly :"show-m",
            pickupData:pickupData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    else if(e.colDef.field == "closed")
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        });
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var params = {
                rowId : rowId
            }
            redirectURL.post("/consignments/closeAirCargoRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Successfully marked as Closed",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                    })
                    //window.location.reload();
                    this.onLoadPageData(4);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as closed.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
    }
    else if(e.colDef.field == "reject")
    {
        var rowId = e.data._id;
        if(rowId !=undefined && rowId !='')
        {
            var sliderTitle = e.data.job_no+" - Reject Reason";
            $("#reject_reason").val("");
            this.setState({
                rejectRowId : rowId,
                rejectedreason : 1,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                sliderTitle : sliderTitle
            });
        }
    }
    else if(e.colDef.field == "file")
    {
        var ringiFile = e.value;
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}
rejectCargoRequest(event){
    event.preventDefault();
    var rejectRowId = this.state.rejectRowId;
    var reject_reason = $("#reject_reason").val();
    if(rejectRowId !=undefined && rejectRowId !='')
    {
        if(reject_reason !=undefined && reject_reason !='')
        {
            var params = {
                rowId : rejectRowId,
                reject_reason : reject_reason,
            }
            redirectURL.post("/consignments/rejectAirCargoExportRequest",params)    
            .then((response) => {
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Marked as Rejected",
                        basicType : "success",
                        show : true,
                        overly:"show-n",
                        loadshow:"show-n",
                        rejectedreason:0,
                        bulkslide:"",
                    })
                    //window.location.reload();
                    this.onLoadPageData(0);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to mark as Rejected.",
                        basicType : "warning",
                        show : true,
                        loadshow:'show-n',
                        overly:'show-n',
                        bulkslide:"",
                    })
                }
            })
            .catch(function(e){
                console.log("Error ",e)
            })
        }
        else
        {
            this.setState({
                basicTitle:"Reason can not be empty.",
                basicType : "warning",
                show : true,
                loadshow:'show-n',
            })
        }
    }
    else
    {
        this.setState({
            basicTitle:"Something went wrong, Please try again.",
            basicType : "warning",
            show : true,
            loadshow:'show-n',
            overly:'show-n',
            rejectedreason:0,
            bulkslide:"",
        })
    }
}
downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

async onClickDataExport(){
    var expKeys = ['job_no_1', 'date_1', 'dept_name_1', 'indenter_name_1', 'mobile_1', 'indenter_email_1'];
    
    var ringiLength = this.state.ringiLength;
    for(var r=0;r<ringiLength;r++)
    {
        var rIndex = r+1;
        var rngFile = "ringi_file"+rIndex;
        var rngDesc  = "ringi_desc"+rIndex;
        expKeys.push(rngFile);
        expKeys.push(rngDesc);
    }
    expKeys.push('pickup_plant_1',"no_of_packets_1");
    var cargoLength = this.state.cargoLength;
    for(var c=0;c<cargoLength;c++)
    {
        var cIndex = c+1;
        var itemDesc = "item_description"+cIndex;
        var cgw  = "cargo_gross_weight"+cIndex;
        var dimensions  = "dimensions"+cIndex;
        expKeys.push(itemDesc);
        expKeys.push(cgw);
        expKeys.push(dimensions);
    }
    expKeys.push("supplier_name_1","supplier_address_1","supplier_contact_person_1","country_1","email_1","phone_1","delivery_terms_1","pick_up_address_1","port_1","airport_name_1","cargo_date_1","lc_1","lc_no_1","nature_1","free_trade_1","benefit_certificate_1");
    var params = {
        columnKeys: expKeys
    };
   
    //console.log(params,"expparams")
    await this.gridApi.exportDataAsExcel(params);
}
onClickTab(pageType){
    if(pageType == "pending")
    {
        var pending = "activet";
        var ffupdates = "";
        var chaupdates = "";
        var tranupdates = "";
        var closure = "";
        var alldetails = "";
        var preclosure = "";
        var pendingData = "show-m";
        var ffData = "show-n";
        var chaData = "show-n";
        var tranData = "show-n";
        var closureData = "show-n";
        var preclosureData = "show-n";
        this.onLoadPageData(0);
    }
    else if(pageType == "ffupdates")
    {
        var pending = "";
        var ffupdates = "activet";
        var chaupdates = "";
        var tranupdates = "";
        var closure = "";
        var alldetails = "";
        var preclosure = "";
        var pendingData = "show-n";
        var ffData = "show-m";
        var chaData = "show-n";
        var tranData = "show-n";
        var closureData = "show-n";
        var preclosureData = "show-n";
        this.onLoadPageData(1);
    }
    else if(pageType == "chaupdates")
    {
        var pending = "";
        var ffupdates = "";
        var chaupdates = "activet";
        var tranupdates = "";
        var closure = "";
        var alldetails = "";
        var preclosure = "";
        var pendingData = "show-n";
        var ffData = "show-n";
        var chaData = "show-m";
        var tranData = "show-n";
        var closureData = "show-n";
        var preclosureData = "show-n";
        this.onLoadPageData(2);
    }
    else if(pageType == "tranupdates")
    {
        var pending = "";
        var ffupdates = "";
        var chaupdates = "";
        var tranupdates = "activet";
        var closure = "";
        var alldetails = "";
        var preclosure = "";
        var pendingData = "show-n";
        var ffData = "show-n";
        var chaData = "show-n";
        var tranData = "show-m";
        var closureData = "show-n";
        var preclosureData = "show-n";
        this.onLoadPageData(3);
    }
    else if(pageType == "closure")
    {
        var pending = "";
        var ffupdates = "";
        var chaupdates = "";
        var tranupdates = "";
        var closure = "activet";
        var alldetails = "";
        var preclosure = "";
        var pendingData = "show-n";
        var ffData = "show-n";
        var chaData = "show-n";
        var tranData = "show-n";
        var closureData = "show-m";
        var preclosureData = "show-n";
        this.onLoadPageData(4);
    }
    else if(pageType == "all")
    {
        var pending = "";
        var ffupdates = "";
        var chaupdates = "";
        var tranupdates = "";
        var closure = "";
        var alldetails = "activet";
        var preclosure = "";
        this.onLoadPageData(5);
    }
    else if(pageType == "preclosure")
    {
        var pending = "";
        var ffupdates = "";
        var chaupdates = "";
        var tranupdates = "";
        var closure = "";
        var alldetails = "";
        var preclosure = "activet";
        var pendingData = "show-n";
        var ffData = "show-n";
        var chaData = "show-n";
        var tranData = "show-n";
        var closureData = "show-n";
        var preclosureData = "show-m";
        this.onLoadPageData(6);
    }
    this.setState({
        pending:pending,
        ffupdates:ffupdates,
        chaupdates:chaupdates,
        tranupdates:tranupdates,
        closure:closure,
        alldetails:alldetails,
        preclosure:preclosure,
        pendingData:pendingData,
        ffData:ffData,
        chaData:chaData,
        tranData:tranData,
        closureData:closureData,
        preclosureData:preclosureData,
    })
}
getCountryListItems = country =>{
    this.setState(
      { country },
      () => console.log(this.state.country)
    );
    
    this.setState({
        errorMessage:'',
        successMessage:''
    })
}
selectCountryOptionsItems(){
    var countries = ["Algeria", "Australia", "Austria", "Belgium", "Canada", "Chile", "Czech Republic", "China", "Denmark", "Dubai", "France", "Germany", "Hong Kong", "Hungary", "Indonesia", "Italy", "Japan", "Malaysia", "Myanmar", "Nigeria", "Netherlands", "Poland", "Romania", "S. Korea", "Serbia", "Singapore", "Slovakia", "South Africa", "Spain", "Sweden", "Switzerland", "Taiwan", "Thailand", "Turkey", "UK", "USA", "Vietnam"];
    let items = [];     
    //items.push({value:'all',label:'All'}); 
    countries.forEach(item =>{
        items.push({value:item,label:item});
    }); 
    return items;
}

getCriteriaListItems = criteria =>{
    this.setState(
      { criteria },
      () => console.log(this.state.criteria)
    );
    
    this.setState({
        errorMessage:'',
        successMessage:''
    })
}
selectCriteriaOptionsItems(){
    let items = [
        {value:'ata_igi_airport_date',label:'Actual Time of Departure (ATD) IGI Airport Date'},
        {value:'ooc_date',label:'Actual Flight Date (AFD)'},
        {value:'actual_delivery_at_msil',label:'Actual Delivery Date (ADD)'},
    ];
    return items;
} 
onClickGetData = (event) =>{
    var country = this.state.country;
    var criteria = this.state.criteria;
    var startDate = $("#from_date").val();
    var endDate = $("#to_date").val();
    var check = 0;
    if(country !=undefined && country !='')
    {
        check = 1;
    }
    if(criteria !=undefined && criteria !='' && startDate !=undefined && startDate !='' && endDate !=undefined && endDate !='')
    {
        check = 1;
    }
    if(check == 1)
    {
        var dataType = this.state.dataType;
        this.onLoadPageData(dataType)
    }
    else
    {
        this.setState({
            basicTitle:"Select options to continue.",
            basicType : "warning",
            show : true,
            loadshow:'show-n',
            overly:'show-n',
        })
    }
}
  render() {
    var dataType = this.state.dataType;
    console.log(dataType,"dataType")
    
    if(dataType == 4)
    {
        var hideEdit = true;
        var hideClose = false;
    }
    else
    {
        var hideEdit = false;
        var hideClose = true;
    }
    var columnwithDefs = [    
        {
            field:'',
            headerName:'',
            colId:"_id",
            hide:hideEdit,
            //pinned:"left",
            width: 72,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                if(params.data.request_type == 3)
                {
                    var htmloption = '<a href="/editffnewaircargoform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                }
                else
                {
                    var htmloption = '<a href="/editaircargoexportsform/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                }
                return htmloption;
            }            
        },
        {
            headerName: "",
            field: "reject",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<button class="btn btn-primary" style="padding:0 9px">Reject</button>';
                return htmloption;
            }        
        },
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Commercial Invoice No",
            field: "commercial_invoice",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Booking Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:120,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Request Type",
            field: "request_type",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.request_type == 1)
                {
                    return "Booking Request";
                }
                else if(params.data.request_type == 2)
                {
                    return "Prepaid Shipment";
                }
                else
                {
                    return "FF New Request";
                }
            }           
        },
        {
            headerName: "Submitted By",
            field: "created_by_user",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Payment Ringi Data",
            field: "ringiData",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-danger" style="padding-top:0px">View</button>';
                return htmloption;
            }
        },
        {
            headerName: "Pick Up Plant",
            field: "pickup_plant",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "No Of Packets",
            field: "no_of_packets",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Cargo Data",
            field: "pickupData",
            width:130,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                let basestring = params.data.batch_name;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            }         
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Consignee Address",
            field: "consignee_address",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Consignee contact person",
            field: "consignee_contact_person",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Invoice",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.invoice_file;
            }
        },
        {
            headerName: "Packing List",
            field: "file",
            width:130,
            filter: true,
            resizable: true,    
            valueGetter: function(params){
                return params.data.packing_list_file;
            }
        },
        {
            headerName: "SQC List",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.sqc_list_file;
            }
        },
        {
            headerName: "Annex II",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.annex_file;
            }
        },
        {
            headerName: "GR wavier",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.gr_wavier_file;
            }
        },
        {
            headerName: "MSDS",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.msds_file;
            }
        },
        {
            headerName: "IIP certificate (Un packing)",
            field: "file",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                return params.data.iip_file;
            }
        },
        {
            headerName: "Country",
            field: "country",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Destination Airport",
            field: "destination_airport",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Email id",
            field: "email",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Phone No",
            field: "phone",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Delivery/Inco Terms",
            field: "delivery_terms",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Delivery address",
            field: "pick_up_address",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.pick_up_address !=undefined && params.data.pick_up_address !="undefined")
                    {
                        return params.data.pick_up_address;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Proposed cargo readiness Date",
            field: "cargo_date",
            width:180,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.cargo_date !=undefined && params.data.cargo_date !='')
                {
                    return moment.parseZone(params.data.cargo_date).format("DD-MM-YYYY")
                }
            }           
        },
        {
            headerName: "Commercial Invoice Date",
            field: "commercial_invoice_date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.commercial_invoice_date !=undefined && params.data.commercial_invoice_date !='')
                {
                    return moment.parseZone(params.data.commercial_invoice_date).format("DD-MM-YYYY")
                }
            }             
        },
        {
            headerName: "Payment Terms",
            field: "payment_terms",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.payment_terms !=undefined && params.data.payment_terms !="undefined")
                    {
                        return params.data.payment_terms;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Shipment Return",
            field: "shipment_return",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.shipment_return !=undefined && params.data.shipment_return !="undefined")
                    {
                        return params.data.shipment_return;
                    }
                }
                catch(e){

                }
            }          
        },
        {
            headerName: "Certificate of origin",
            field: "cert_of_origin",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.cert_of_origin !=undefined && params.data.cert_of_origin !="undefined")
                    {
                        return params.data.cert_of_origin;
                    }
                }
                catch(e){

                }
            }         
        },
        {
            headerName: "DG",
            field: "dg",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.dg !=undefined && params.data.dg !="undefined")
                    {
                        return params.data.dg;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="undefined")
                    {
                        return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY")
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "Freight Forworder",
            field: "assign_to_ff",
            width:130,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.assign_to_ff !=undefined && params.data.assign_to_ff !="undefined")
                    {
                        return params.data.assign_to_ff;
                    }
                }
                catch(e){

                }
            }           
        },
        {
            headerName: "FF Contact person Details",
            field: "ff_contact_person_details",
            width:150,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ff_contact_person_details !=undefined && params.data.ff_contact_person_details !="undefined")
                    {
                        return params.data.ff_contact_person_details;
                    }
                }
                catch(e){

                }
            }           
        }
    ]
    // var erpLength = this.state.erpLength;
    // for(var e=0;e<erpLength;e++)
    // {
    //     var eIndex = e+1;
    //     var erpPO = "erpPoNo"+eIndex;
    //     var erpPODate = "erPoDate"+eIndex;
    //     var erpData = {
    //         headerName: "ERP PO No "+"-"+eIndex,
    //         field: erpPO,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,           
    //     };
    //     columnwithDefs.push(erpData);
    //     var erpDate = {
    //         headerName: "ERP PO Date "+"-"+eIndex,
    //         field: erpPODate,
    //         hide:true,
    //         width:130,
    //         filter: true,
    //         resizable: true,
    //         valueGetter: function(params){
    //             if(params.data[erpPODate] !=undefined && params.data[erpPODate] !='')
    //             {
    //                 return moment.parseZone(params.data[erpPODate]).format("DD-MM-YYYY")
    //             }
    //         }           
    //     };
    //     columnwithDefs.push(erpDate);
    // }
    var ringiLength = this.state.ringiLength;
    for(var r=0;r<ringiLength;r++)
    {
        var rIndex = r+1;
        var ringiFile = "ringi_file"+rIndex;
        var ringiDesc = "ringi_desc"+rIndex;
        var ringiData = {
            headerName: "Ringi File"+"-"+rIndex,
            field: ringiFile,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(ringiData);
        var ringiDescData = {
            headerName: "Ringi No"+"-"+rIndex,
            field: ringiDesc,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(ringiDescData);
    }
    var cargoLength = this.state.cargoLength;
    for(var c=0;c<cargoLength;c++)
    {
        var cIndex = c+1;
        var itemDesc = "item_description"+cIndex;
        var cargogw = "cargo_gross_weight"+cIndex;
        var dimensions = "dimensions"+cIndex;
        var pickupData = {
            headerName: "Item Description"+"-"+cIndex,
            field: itemDesc,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(pickupData);
        var cargogrossData = {
            headerName: "Cargo Gross Weight"+"-"+cIndex,
            field: cargogw,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(cargogrossData);
        var cargodmData = {
            headerName: "Dimensions"+"-"+cIndex,
            field: dimensions,
            hide:true,
            width:130,
            filter: true,
            resizable: true,           
        };
        columnwithDefs.push(cargodmData);
    }
    var airCargoDefs = columnwithDefs;
    return (
        <div class="container-fluid">          
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className="row">
            <div className="row col-xl-12 col-lg-12">
                <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
					<div className="form-group col-xl-2 col-lg-2" style={{borderRight:"1px solid #aaa"}}>
						<label>Destination Country: </label>
						<Select placeholder={"Select Country"} closeMenuOnSelect={true} value={this.state.country} className={"border-radius-0"} onChange={this.getCountryListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCountryOptionsItems()} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select Criteria: </label>
						<Select placeholder={"Select Criteria"} closeMenuOnSelect={true} value={this.state.criteria} className={"border-radius-0"} onChange={this.getCriteriaListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCriteriaOptionsItems()} />
                                       
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>From Date</label>
						<input type="text" placeholder="Date" id="from_date" value={this.state.startDate} autocomplete="off" className="datetimepicker_date form-control"  required />
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>To Date</label>
						<input type="text" placeholder="Date" id="to_date" value={this.state.endDate} autocomplete="off" className="datetimepicker_date form-control"  required />
					</div>
					<div className="form-group col-xl-2 col-lg-2">
						<label>&nbsp;</label><br />
						<button type="button" className="btn btn-success" style={{padding:"5px 10px",marginRight:"2%"}} onClick={this.onClickGetData.bind(this)}>Submit</button>
						<a href="/aircargos" className="btn btn-primary" style={{padding:"5px 10px"}}>Reset</a>
					
					</div>
					</form>
				</div>
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body n-p-0">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d">
                                        <span className="f13">ALL</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.allCnt)?this.state.allCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"pending")}>
                                        <span className="f13">Pending For Assignment</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.pendingCnt)?this.state.pendingCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"tranupdates")}>
                                        <span className="f13">Pending For Transportation Updates</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.transCnt)?this.state.transCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"chaupdates")}>
                                        <span className="f13">Pending For CHA Updates</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.chaCnt)?this.state.chaCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"ffupdates")}>
                                        <span className="f13">Pending For FF Updates</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.ffCnt)?this.state.ffCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"closure")}>
                                        <span className="f13">Closures</span>
                                        <h5 className="txt-info f35 mt-20p">
                                            <span className="counter">
                                                <CountUp end={(this.state.closureCnt)?this.state.closureCnt:0}/>
                                            </span>
                                        </h5>
                                        
                                    </div>

                                </div>                                
                            </div>
                        
                        </div>
                    </div>
                </div>
           
            </div>
            <div className="row">					
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Air Exports </span>
                                <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                            </h5>
                        </div>
                        
                        <div className="card-body">
                            <div className="f16" style={{marginBottom:"10px"}}>
                                <ul className="d-tabs">

                                    {/* <li onClick={this.onClickTab.bind(this,"all")} className={"lttabs "+(this.state.alldetails)}>
                                        <button type="button" className="btn">ALL</button>
                                    </li> */}

                                    <li onClick={this.onClickTab.bind(this,"pending")} className={"lttabs "+(this.state.pending)}>
                                        <button type="button" className="btn">Pending for Assignment</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"tranupdates")} className={"lttabs "+(this.state.tranupdates)}>
                                        <button type="button" className="btn">Pending for Transportation</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"chaupdates")} className={"lttabs "+(this.state.chaupdates)}>
                                        <button type="button" className="btn">Pending for CHA</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"ffupdates")} className={"lttabs "+(this.state.ffupdates)}>
                                        <button type="button" className="btn">Pending for FF</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"closure")} className={"lttabs "+(this.state.closure)}>
                                        <button type="button" className="btn">Closure</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"preclosure")} className={"lttabs "+(this.state.preclosure)}>
                                        <button type="button" className="btn">Closures Pending for LSP</button>
                                    </li>
                                </ul>
                            </div>
                            <div className={this.state.pendingData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={airCargoDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        onCellClicked={this.viewDetails.bind(this)}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div>
                            <div className={"ag-theme-balham"+this.state.ffData}>
                                {(this.state.ffData == "show-m") ?
                                    <FFgridExportsData ffRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.chaData}>
                                {(this.state.chaData == "show-m") ?
                                    <ChagridExportsData chaRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.tranData}>
                                {(this.state.tranData == "show-m") ?
                                    <TransExportsgridData transRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.closureData}>
                                {(this.state.closureData == "show-m") ?
                                    <ClosureExportsgridData closureRowData={this.state.rowData}/>
                                : ""}
                            </div>
                            <div className={"ag-theme-balham"+this.state.preclosureData}>
                                {(this.state.preclosureData == "show-m") ?
                                    <PreclosureExportsgridData ffRowData={this.state.rowData}/>
                                : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                <h3 className="subH">
                    {this.state.sliderTitle}
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                </h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                    {(this.state.erpData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>ERP PO No</th>
                                                <th>ERP PO Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.erpData.map((e, index) => (
                                            <tr>
                                                <td>{e.erpPoNo}</td>
                                                <td>{e.erPoDate}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.ringiData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Ringi No</th>
                                                <th>Ringi (File)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ringiData.map((e, index) => (
                                            <tr>
                                                <td>{e.ringi_desc}</td>
                                                <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.ringi_file)}>{e.ringi_file}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.pickupData.length > 0) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered tptinfo">
                                        <thead>
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Cargo Gross Weight</th>
                                                <th>Dimensions (cms)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.pickupData.map((e, index) => (
                                            <tr>
                                                <td>{e.item_description}</td>
                                                <td>{e.cargo_gross_weight}</td>
                                                <td>{e.dimensions}</td>
                                            </tr>
                                             ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    : ""}
                    {(this.state.rejectedreason == 1) ?                         
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <form method="POST" id="rejectform" className="theme-form" onSubmit={this.rejectCargoRequest}>
                                        <div className="form-group mt-20p">
                                            <label className="">Reason</label> 
                                            <textarea name="reject_reason" id="reject_reason" className="form-control" required></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Reject</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    : ""}
                </div>
            </div>
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};