import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var map;
var infoBox = require('../common/google-infowindow');

export default class OEMBreaks extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            detailCellRendererParams: {},
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/dashboard/getOEMBreaks").then((response)=>{
            this.setState({
                rowData: response.data
            })
             this.renderMap()

        }).catch((e)=>{
            console.log(e)
        })

    }
    

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
			{
			  colId: 'no_of_breaks',
			  sort: 'desc',
			},
		  ];
		this.gridApi.setSortModel(sort);
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    renderMap = () =>
    {    	
    loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
    window.initMap = this.initMap
    }

    initMap = () => {
        
        var bounds = new window.google.maps.LatLngBounds();
        if(this.state.lat != "" && this.state.long != "")
        {
            map = new window.google.maps.Map(document.getElementById('oemMap'), {
                zoom: 8,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                center: new window.google.maps.LatLng(28.497236,77.062363),
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
            });
            
            this.state.rowData.map(function(e){
                bounds.extend(new window.google.maps.LatLng(JSON.parse(e.comp_coords).lat,JSON.parse(e.comp_coords).lng));

                var oemMarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(JSON.parse(e.comp_coords).lat,JSON.parse(e.comp_coords).lng),
                    map: map,
                    icon: require("../../assets/icons/business.png"),
                });
                var infowindow = new window.google.maps.InfoWindow();
                //var l;
                window.google.maps.event.addListener(oemMarker, 'mouseover', (function(oemMarker) {
                    return function() 
                    {
                        var contentarr = []
                        var header = "OEM"
                        contentarr.push({"key":"OEM", "value":e.comp_name})
                        var contentString = infoBox(oemMarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, oemMarker);
                    }
                })(oemMarker));
                window.google.maps.event.addListener(oemMarker, 'mouseout', function() {
                    infowindow.close();
                    });
            })

            this.state.rowData.map(function(e){
                e.break_clusters.map(function(k){
                    bounds.extend(new window.google.maps.LatLng(k.lat,k.lng));
                    var breakMarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(k.lat,k.lng),
                        map: map,
                        icon: require("../../assets/icons/truck_7_32.png"),
                    });
                    var infowindow = new window.google.maps.InfoWindow();
                    //var l;
                    window.google.maps.event.addListener(breakMarker, 'mouseover', (function(breakMarker) {
                        return function() 
                        {
                            var contentarr = []
                            var header = e.comp_name
                            contentarr.push({"key":"No. of breaks", "value": k.no_of_breaks})
                            contentarr.push({"key":"Distance from OEM", "value": k.distance_from_comp_location_km})
                            var contentString = infoBox(breakMarker.icon, header, contentarr)
                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, breakMarker);
                        }
                    })(breakMarker));
                    window.google.maps.event.addListener(breakMarker, 'mouseout', function() {
                        infowindow.close();
                        });
                })
            })
      
                
            map.fitBounds(bounds)
        }
        
        this.setState({
            overly : "show-n",
            loadshow : "show-n",
        })
        
    }
   async onCellClicked(row){
        if(row.colDef.field == "showbreaks")
        {
            console.log(row.node.expanded);
            let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
        
            var transitedelaydata=[];
            
           await this.setState({
                    
                detailCellRendererParams:{
                    suppressRefresh: true,
                    detailGridOptions: {
                        headerHeight:50,
                        columnDefs: [
                            { 
                                headerName: "",
                                field: "bounce_break",
                                width:60,
                                resizable: true,
                                cellRenderer: function(params){
                                    return '<button class="btn btn-success" id="bounce_break" value="'+params.data.lat+','+params.data.lng+'" style="padding-top: 0em;width: 1em;padding-left: 1em;color: black;background: none !important;border: none !important;"><i class="icofont icofont-map-pins f25"></i></button>'
                                }
                            },
                            { 
                                headerName: "No of breaks",
                                field: "no_of_breaks",
                                width:100,
                                sortable: true,
                                resizable: true,
                            },
                            {
                                headerName: "Distance From OEM (KM)",
                                field: "distance_from_comp_location_km",
                                resizable : true,
                                sortable: true,
                                width: 100
                            },
                            {
                                headerName: "OEM Latitude",
                                field: "lat",
                                resizable : true,
                                width: 130,
                            },
                            
                            {
                                headerName: "OEM Longitude",
                                field: "lng",
                                resizable : true,
                                sortable: true,
                                width: 130,
                            },
                        						
                        ],
                        overlayNoRowsTemplate: 'No rows to show',						
                    },
                    getDetailRowData: function(params) {
                        //console.log("Step 4 ",transitedelaydata)
                        
                        params.successCallback(params.data.break_clusters);
                        
                    }
                }
            });        
            row.node.setExpanded(!row.node.expanded);
            var bounds = new window.google.maps.LatLngBounds();
            row.data.break_clusters.map(function(k){
                bounds.extend(new window.google.maps.LatLng(k.lat,k.lng));
            })
            map.fitBounds(bounds)
        }
    }
    onRowClicked(row){
        console.log(row)
    }
    render(){
    
        const columnwithDefs = [
            {
                headerName : "",
                field : "showbreaks",
                colId: "showbreaks",
                filter : true,
                resizable :true,
                width:70,
                editable:false,
                cellRenderer: function(params)
                {
                    return "<i class='icofont icofont-map-pins f25'></i>";
                },
            },
            {
                headerName : "OEM Name",
                field : "comp_name",
                colId: "comp_name",
                filter : true,
                resizable :true,
                width:250,
                editable:false,
            },
            {
                headerName : "No of breaks",
                field: "",
                colId: "no_of_breaks",
                width: 130,
                valueGetter: function(e){
                    return e.data.break_clusters.length;
                }
            }
        ]
        const dStyles={
            width:'100%',
            height:'550px'
        }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="row">
                    <div className="col-xl-7 col-lg-7">
                        <div className="row card">
                            <div className="col-sm-12 col-lg-12 card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>OEM Breaks</span>
                                </h5>
                            </div>
                            <div id="oemMap" className="" style={dStyles}></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                    <div className="col-sm-5 col-sm-5 card-body pt-15px " >
                                <div id="myGrid" style={{ height: "580px",width: "47em"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        // stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        onRowClicked={this.onRowClicked.bind(this)}
                                        // rowSelection={this.state.rowSelection}
                                        // suppressRowClickSelection={true}
                                        
                                    />
                                    
                                </div>
                            </div>
                    </div>
                </div>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

$(document).on("click","#bounce_break",function(e){
    var location = $(this).val();
    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(parseFloat(location.split(",")[0]),parseFloat(location.split(",")[1])));
    map.fitBounds(bounds)
    map.setZoom(20)
})