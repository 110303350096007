'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');




export default class SOBchangelogs extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        logHistory : [],
    }
    this.onCellClicked = this.onCellClicked.bind(this)
  }

componentDidMount(){
    this.setState({
        logHistory : this.props.logHistory
    })
}

componentWillReceiveProps(){
    this.setState({
        logHistory : this.props.logHistory
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
   
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}

 
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Location",
            field: "location",
            width:120,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Item Type",
            field: "item_type",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transporter",
            field: "transporter",
            width:120,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Revision",
            field: "revision",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Plan Date",
            field: "plan_date",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Old Value",
            field: "old_value",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Updated Value",
            field: "plan_share",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Updated By",
            field: "updated_by",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Updated On",
            field: "updated_on",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.updated_on !=undefined && params.data.updated_on !="")
                {
                    return getHyphenDDMMMYYYYHHMM(params.data.updated_on)
                }
            }
        },
    ]
    return (
        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.logHistory}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                onCellClicked={this.onCellClicked}
                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                />
        </div>
            

    );
  }
}
