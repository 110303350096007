import React, { Component } from 'react';
import CountUp from 'react-countup';
// import axios from 'axios';
// var redirectURL = require('../redirectURL');

export default class LoadArrivalCounters extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            records:[],
            exitWithoutLoads:0,
            totalRecords : 0,
            gps_present : 0,
            inside_plant_vicinty : 0,
            inside_vicinity : 0,
            outside_vicinity : 0,
            no_gps_data : 0,
            no_gps_data_total : 0 ,
            outside_plant_vicinity :0,
            total_exceptions : 0,
            gps_outside_vicinity:0
    	}
    
    }
    
    componentWillReceiveProps(newprops){
        console.log("Newporps ", newprops)
        var total_exceptions = newprops.no_gps_data_total + newprops.outside_vicinity
       this.setState({
        exitWithoutLoads : newprops.exitWithoutLoads,
        totalRecords : newprops.totalRecords,
        gps_present : newprops.gps_present,
        inside_plant_vicinty : newprops.inside_plant_vicinty,
        no_gps_data : newprops.no_gps_data,
        no_gps_data_total : newprops.no_gps_data_total,
        inside_vicinity : newprops.inside_vicinity,
        outside_vicinity : newprops.outside_vicinity,
        outside_plant_vicinity : newprops.outside_plant_vicinity,
        total_exceptions : total_exceptions,
        gps_outside_vicinity : newprops.gps_outside_vicinity
       })

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        
        return (
            <div className="row">

                {/* <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                        <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Arrivals</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalRecords != '')?this.state.totalRecords:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"gps_present")}>
                                        <span className="f13"><i className="icofont icofont-social-google-map f24 txt-info-dark"></i><br />No GPS Data</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.gps_present != '')?this.state.gps_present:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"entered_plant_vicinity")}>
                                        <span className="f13"><i className="icofont icofont-truck-loaded f24 txt-success-dark"></i><br /> Entered Plant Vicinity</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.inside_plant_vicinty != '')?this.state.inside_plant_vicinty:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"exitloads")}>
                                        <span className="f13"><i className="icofont icofont-truck-alt f24 greenfont"></i><br /> Left Without Loads</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.exitWithoutLoads != '')?this.state.exitWithoutLoads:0}/></span></h4>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="crm-numbers pb-0">
                        <div className="row">
                            <div className="col col-xl-2 cirlce-d cpointer p-5px" onClick={this.onClickCounter.bind(this,"all")}>
                                        
                                <div className="card n-p-0">
                                    <div className="card-header">
                                        <span className="f12">Total Arrivals</span>
                                    </div>
                                    <div className="row card-body pt-35px pb-35px">
                                        <div className="col col-xl-12 col-lg-12" onClick={this.onClickCounter.bind(this,"all")}> 
                                            {/* <span className="f12"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /><br /></span> */}
                                            <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.totalRecords != '')?this.state.totalRecords:0}/></span></h4>
                                        
                                            {/* <div className=" f12">
                                                <div className="counter txt-success-medium">
                                                Cars: &nbsp;
                                                    <CountUp end={(this.state.totalloadscars != '')?this.state.totalloadscars:0}/>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div> 
                                </div>
                            </div> 
                            <div className="col col-xl-3 cirlce-d cpointer p-5px">
                                        
                                <div className="card n-p-0">
                                    <div className="card-header">
                                        <span className="f12">No GPS</span>
                                    </div>
                                    <div className="row card-body pt-15px pb-15px">
                                        <div className="col col-xl-6 col-lg-6 cpointer" onClick={this.onClickCounter.bind(this,"no_gps_data_total")}> 
                                            <span className="f12">
                                                {/* <i className="icofont icofont-social-google-map f24 txt-grey-light"></i><br />  */}
                                                Total </span>
                                            <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.no_gps_data_total != '')?this.state.no_gps_data_total:0}/></span></h4>
                                        </div>
                                        <div className="col col-xl-6 col-lg-6" onClick={this.onClickCounter.bind(this,"no_gps_data")}>  
                                            <span className="f12">
                                                {/* <i className="icofont icofont-social-google-map f24 txt-grey-light"></i><br /> */}
                                                Load Attached </span>
                                            <h4 className="txt-danger f26"><span className="counter">
                                                <CountUp end= {(this.state.no_gps_data != 0)?this.state.no_gps_data:0}/>
                                                </span></h4>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className={"col cpointer p-5px col-xl-4 col-lg-4"}>
                                <div className="card n-p-0">
                                    <div className="card-header">
                                        <span className="f12">With GPS Data</span>
                                    </div>
                                    <div className="row card-body p-10px">    
                                        
                                        <div className="col col-xl-4 col-lg-4 cpointer" onClick={this.onClickCounter.bind(this,"inside_vicinity")}>
                                            <span className="f12">
                                                {/* <i className="icofont icofont-social-google-map f24 txt-danger-dark"></i><br /> */}
                                                Within Vicinity </span>
                                            <h4 className="txt-secondary f26"><span className="counter">
                                                <CountUp end= {(this.state.inside_vicinity != "")?this.state.inside_vicinity:0}/>
                                                </span></h4>
                                        </div>
                                        <div className="col col-xl-4 col-lg-4" onClick={this.onClickCounter.bind(this,"gps_outside_vicinity")}>  
                                            <span className="f12">
                                                {/* <i className="icofont icofont-social-google-map f24 txt-danger-dark"></i><br />  */}
                                                Outside Vicinity </span>
                                            <h4 className="txt-danger f26"><span className="counter">
                                                <CountUp end= {(this.state.gps_outside_vicinity)?this.state.gps_outside_vicinity:0}/>
                                                </span></h4>
                                        </div>
                                        <div className="col col-xl-4 col-lg-4" onClick={this.onClickCounter.bind(this,"outside_vicinity")}>  
                                            <span className="f12">
                                                {/* <i className="icofont icofont-social-google-map f24 txt-danger-dark"></i><br />  */}
                                                Load Attached <br /> Outside Vicinity </span>
                                            <h4 className="txt-danger f26"><span className="counter">
                                                <CountUp end= {(this.state.outside_vicinity)?this.state.outside_vicinity:0}/>
                                                </span></h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            
                            {/* <div className={"col cpointer p-5px col-xl-3"}>
                                <div className="card">
                                    <div className="card-header">
                                        <span className="f12">Entered Plant Vicinity</span>
                                    </div>
                                    <div className="row card-body p-10px">  
                                        <div className="col cpointer col-xl-6 col-lg-6" onClick={this.onClickCounter.bind(this,"entered_plant_vicinity")}>
                                            <span className="f12"><i className="icofont icofont-truck-loaded f26 txt-success-dark"></i><br /><br /></span>
                                            <h4 className="txt-info-dark f26">
                                                <span className="counter"><CountUp end={(this.state.inside_plant_vicinty != "")?this.state.inside_plant_vicinty:0}/></span></h4>
                                        </div>
                                        <div className="col col-xl-6 col-lg-6" onClick={this.onClickCounter.bind(this,"outside_plant_vicinity")}>  
                                            <span className="f12"><i className="icofont icofont-truck-loaded f26 txt-success-dark"></i><br /> <br /> </span>
                                            <h4 className="txt-primary f26"><span className="counter">
                                                <CountUp end= {(this.state.outside_plant_vicinity != "")?this.state.outside_plant_vicinity:0}/>
                                                </span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className={"col cpointer p-5px col-xl-2"}>
                                <div className="card">
                                    <div className="card-header">
                                        <span className="f12">Left Without Loads</span>
                                    </div>
                                    <div className="row card-body pt-35px pb-35px">  
                                        <div className="col cpointer col-xl-12" onClick={this.onClickCounter.bind(this,"exitloads")}>
                                            {/* <span className="f12"><i className="icofont icofont-truck-alt f26 greenfont"></i><br /><br /></span> */}
                                            {/* <h4 className="txt-info-dark f26">
                                                <span className="counter"><CountUp end={(this.state.exitWithoutLoads != "")?this.state.exitWithoutLoads:0}/></span></h4>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>  */}
                            <div className={"col cpointer p-5px col-xl-3"}>
                                <div className="card">
                                    <div className="card-header">
                                        <span className="f12">Total Exceptions</span>
                                    </div>
                                    <div className="row card-body pt-35px pb-35px">  
                                        <div className="col cpointer col-xl-12" onClick={this.onClickCounter.bind(this,"total_exceptions")}>
                                            {/* <span className="f12"><i className="icofont icofont-warning f26 greenfont"></i><br /><br /></span> */}
                                            <h4 className="txt-danger-dark f26">
                                                <span className="counter"><CountUp end={(this.state.total_exceptions != "")?this.state.total_exceptions:0}/></span></h4>
                                        
                                            {/* <div className=" f12">
                                                <div className="counter txt-success-medium">
                                                Cars: &nbsp;
                                                    <CountUp end={(this.state.gateincars != '')?this.state.gateincars:0}/>
                                                </div>
                                            </div> */}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                
                </div>
            
            </div>
        );
    }
}






