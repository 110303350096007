/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import UpdateItem from './updateActionComponent';
import StatusChange from './statusUpdateActionComponent';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";

import SweetAlert from 'react-bootstrap-sweetalert';
import DateFormater from '../layouts/dateComponent';
var redirectURL = require('../redirectURL');
var selectRowItemFromAgGrid='';
var url=redirectURL;
var googleAnalytics = require("../common/googleanalytics");
export default class GridComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
			pageTitle: "Manage Trucks",
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				  editable: true,
				  resizable: true
      		},
			rowData: null,

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		
			frameworkComponents: {
				updateItem:UpdateItem,
				dateFormater:DateFormater,
				customLoadingCellRenderer: CustomLoadingCellRenderer
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2
		};
        
	}
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
	 
	 deleteRowHandler = () => {
		 var selectedData = this.gridApi.getSelectedRows();
		    var res = this.gridApi.updateRowData({ remove: selectedData });
		    //printResult(res);
		    console.log("Remove to : ",res.remove[0].data._id)
		    var actionCall = this.props.actionCall;
		    var actionMethod = this.props.actionMethod;
		    console.log(actionMethod);
		    var fdata = {
		    		rownode:res.remove[0].data
		    }
		    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
				headers:{
					'Content-Type': 'application/json'
				}
			}).
			then(
				(response)=>{
					console.log(response.data);
					
				}
			)
			.catch(function(error){
				console.log(error);
			});
	 }
	 deleteItem = (e) => {
		 console.log("Here",e)
	 }
	 
	 onSelectionChanged() {
	    var selectedRows = this.gridApi.getSelectedRows();
	    selectRowItemFromAgGrid=selectedRows;
	  }

	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		pathToMatch = (pathToMatch) ? pathToMatch.toLowerCase() : "";
		switch(pathToMatch) {
			case "/manage/sndtrucks":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/manage/prttrucks":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptrucks":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctrucks":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return departmentName;
	}
	 updateRowHandler(cell)
	 {
		 //console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
		    let pageTitle = this.state.pageTitle;
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			if (currentDepartmentTitle) {
				pageTitle = pageTitle + currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}


		var actionCall=this.props.actionCall;
		var actionMethod=this.props.actionMethod;
		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).then((response)=>{
				//console.log("update response is : ",response.data);
				// console.log("Update Response ",response.data.result)
				if(response.data.result.nModified == 1)
				{
					this.setState({
						show: true,
						basicTitle:'Successfully update data',
						basicType:"success",
					});
				}
				else if(response.data.result.nModified == 0)
				{
					this.setState({
						show: true,
						basicTitle:'No Changes update',
						basicType:"info",
					});
				}
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

		 /*
		 
		 var data=cell.data;
		 var table=this.props.table;
		 var actionMethod=this.props.actionMethod;
		 var actionCall =  this.props.actionRoute;
		 var fdata ={
				 data:data,
				 table:table
		 }
		 redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
				headers:{
					'Content-Type': 'application/json'
				}
			}).
			then(
				(response)=>{
					console.log(response.data);
					
				}
			)
			.catch(function(error){
				console.log(error);
			});
		*/
	 }


	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    buttonExample = (type) => {
        switch (type) {
            case 'custom':
                this.setState({
                    alert: (
                        <SweetAlert
                            custom
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnText="No"
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="default"
                            customIcon="thumbs-up.jpg"
                            title="Do you like thumbs?"
                            onConfirm={this.hideAlert}
                            onCancel={this.hideAlert}
                        >
                            You will find they are up!
                        </SweetAlert>
                    )
                });
                break;
            case 'input':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            showCancel
                            cancelBtnBsStyle="default"
                            title="An input!"
                            placeHolder="Write something"
                            onConfirm={this.onRecieveInput}
                            onCancel={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            case 'password':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            required
                            inputType="password"
                            title="Enter Password"
                            validationMsg="You must enter your password!"
                            onConfirm={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            default:
                this.setState({
                    alert: (
                        <SweetAlert
                            showCancel
                            confirmBtnText="Continue"
                            confirmBtnBsStyle={type}
                            type={type}
                            title="Are you sure?"
                            onCancel={this.hideAlert}
                            onConfirm={this.hideAlert}
                        >
                            You will not be able to recover this imaginary file!
                        </SweetAlert>
                    )
                });
                break;
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

	/*End*/

    render(){
    	
        
        return ( 
        		<div className="row">
					<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
        			{/*<span className="pull-right" style={{position:"absolute",top:"1.4%",right:"15%"}}>
        				<button type="button" className="btn btn-danger" onClick={this.deleteRowHandler.bind(this)}>Delete</button>
        			</span>
        			*/}
        			
        			
	                 <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
	      		          <AgGridReact
	      		            modules={this.state.modules}
	      		            columnDefs={this.props.columnwithDefs}
	      		            defaultColDef={this.state.defaultColDef}
	      		            rowData={this.props.rowData}
	      	                rowSelection={this.state.rowSelection}
							  enableCharts={false}
							//  paginationAutoPageSize={true}
							paginationPageSize={this.state.paginationPageSize}
	      		            pagination={true}
	      		            enableRangeSelection={true}
	      		            onGridReady={this.onGridReady}
	      		            onGridState={this.onGridState}
	      		            deleteRowHandler={this.deleteRowHandler}
	      		            onSelectionChanged={this.onSelectionChanged.bind(this)}
	      	                frameworkComponents={this.state.frameworkComponents}
	      	                //editType={this.state.editType}
	      		          	stopEditingWhenGridLosesFocus={true}
	      		          	floatingFilter={true}
	      		          	enableCellChangeFlash={true}
	      		          	suppressCellFlash={true}
							gridOptions={{
								context: { componentParent: this }
							}}
	      		            //updateRowHandler={this.updateRowHandler}
	      		          	//onCellEditingStopped={this.updateCellData.bind(this)}
	      		          />
	  		        </div>
      		    </div>
                    
			);
		}
}


const CustomBtn = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.handleStateChange(props.data);
    };

    return (
        <div>
            <button onClick={handleClick}>
               Change State
            </button>
        </div>
    );
};








