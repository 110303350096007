/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import Select from 'react-select';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import MarkerClusterer from "@google/markerclusterer";
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import MapButton from "../layouts/consgineeMapButtonComponent";
import SecondMapButton from '../layouts/consigneeMapSecondBtn';
import ShowLegs from "./consigneelocationlegs";
import RoutemapAction from '../manage/routemapActionComponent';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;

export default class ConsigneeVicintyComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			sidebarSubHeader: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rowData: [],

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				MapButton: MapButton,
				ShowLegs: ShowLegs,
				SecondMapButton
				// customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			fencelist: [],
			startDate: '',
			endDate: '',
			consignee_code: '',
			consigneeCoordinate: [],
			consignments: [],
			mwidth: "col-xl-12 col-lg-12",
			dealers: [],
			picklat: '',
			picklng: '',
			consigneeRowData: [],
			routeTruck: [],
			rownode: []

		}
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.onChangeHandle = this.onChangeHandle.bind(this);
		this.cipher = this.cipher.bind(this);
		this.onShowLegs = this.onShowLegs.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch (e) {

		}

	}


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		//console.log("Props ", this.props.match)
		this.logPageView(); // Log page view to GA
		var urlpath;
		var dpt;
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			loadshow: 'show-m'
		});
		var hText = 'All';
		if (this.props.match.path == "/mllsndvicinity") {
			urlpath = '/consignees/sndvicinity';
			dpt = 'SNDG';
			hText = '';
		}
		else if (this.props.match.path == "/sndvicinity") {
			urlpath = '/consignees/sndvicinity';
			dpt = 'SNDG';
			hText = '( Sales and Dispatch )';
		}
		else if (this.props.match.path == "/prtvicinity") {
			urlpath = '/consignees/prtvicinity';
			dpt = 'LOG-PRT';
			hText = '( Spare Parts )';
		}
		else if (this.props.match.path == "/tnpvicinity") {
			urlpath = '/consignees/tnpvicinity';
			dpt = 'LOG-TNP';
			hText = '( Train and Production )';
		}
		else {
			urlpath = '/consignees';
			dpt = 'all';
		}
		//console.log(dpt);

		let pageTitle = "Dealer Unloading Locations " + hText + "";

		this.setState({
			deptcode: dpt,
			pageTitle: pageTitle
		});

		var reqparams = {
			dept_code: encode(dpt)
		}
		//console.log(reqparams)
		redirectURL.post(urlpath, reqparams)
			.then((response) => {
				var records = response.data;
				//console.log("records", records);
				var listarr = [];
				var dealers = [];
				var consigneeRowData = []
				records.map((item) => {
					listarr.push(item.consignee_code);
					dealers.push({ code: item.consignee_code, coordinates: item.consignee_coordinates })
					for (var key in item) {
						if (consigneeRowData.length == 0) {
							consigneeRowData.push(item)
						}
						else {
							var searchConsignee = consigneeRowData.find(obj => {
								return obj.consignee_code === item.consignee_code
							});
							if (searchConsignee == undefined) {
								consigneeRowData.push(item)
							}
						}
					}
				})
				// console.log("listarr ",consigneeRowData);
				// console.log(consigneeRowData.length)
				var uniquefencelist = listarr.filter(function (value, index, self) {
					return self.indexOf(value) === index;
				});
				console.log(uniquefencelist.length, uniquefencelist);
				this.setState({
					//rowData:rowarray,
					loadshow: 'show-n',
					fencelist: uniquefencelist,
					consigneeRowData: consigneeRowData,
					dealers: dealers
				});
				this.renderMap()
			})
			.catch(function (error) {
				console.log(error);
			});


	};


	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		//console.log("Deptcode " ,this.state.deptcode)

	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: ''
		});

	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}


	selectOptionsItems() {
		let items = [];
		//console.log(this.state.trucks);
		this.state.fencelist.forEach(item => {
			if (item != '' && item != null) {
				//items.push(<option key={item} value={item}>{item}</option>);
				items.push({ "value": item, "label": item });
			}

		});
		return items;
	}

	handlerForm = consignee_code => {
		//console.log("Select", event.target.value);
		this.setState(
			{ consignee_code },
			() => console.log(`Option selected:`, this.state.consignee_code)
		);

	}

	onChangeHandle(event) {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	//onShowRouteDiv
	formHandler = (params) => {
		console.log("params.consignee_coordinates ", params.consignee_coordinates);
		// event.preventDefault();
		// console.log(event)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		});
		//console.log("this.state.consignee_code ", this.state.consignee_code.value)
		//var formdata = new FormData(event.target);
		// /rlatitude
		var picklatlng = params.consignee_coordinates;
		// if(this.state.dealers.length > 0)
		// {
		// 	this.state.dealers.map((item) => {
		// 		if(item.code == this.state.consignee_code.value)
		// 		{
		// 			picklatlng=item.coordinates
		// 		}
		// 	})
		// }
		if (picklatlng != '') {
			var ltln = JSON.parse(picklatlng);
			//console.log("ltln ", ltln)
			this.setState({
				picklat: ltln.lat,
				picklng: ltln.lng
			});

		}

		console.log("picklatlng ", picklatlng)
		var formdata = {
			consignee_code: params.consignee_code,
			dept_code: this.state.deptcode
		}
		//console.log("formdata ", formdata)
		redirectURL.post("/consignees/consigneeCoordinates", formdata, {
			headers: {
				//'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': "*",
				'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				// 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2'
			}
		})
			//.then(res => res.json())
			.then(
				(result) => {
					console.log(result)
					if (result.data.body == '') {
						this.setState({
							loadshow: 'show-n',
							show: true,
							basicType: "danger",
							basicTitle: "No Unloading Locations Data."
						});
					}
					else {

						var records = JSON.parse(result.data.body);

						//console.log("rowarray ", records)
						var consigneeCoordinate = [];
						var consignmentsarr = []
						records.map((item) => {
							consigneeCoordinate.push(item.coordinates)
							/*item.consignments.map((consignment) => {
								consignmentsarr.push(consignment)
							})*/

						});
						//console.log("consigneeCoordinate ", consigneeCoordinate)

						this.setState({
							rowData: records,
							loadshow: 'show-n',
							mwidth: "col-xl-7 col-lg-7",
							consigneeCoordinate: consigneeCoordinate
							//consigneeCoordinate:consigneeCoordinate,
							//consignments:consignmentsarr
						});

						this.renderMap();


					}

				})
			.catch(function (error) {
				console.log(error);
			});
	}

	formHandlerSecond = (params) => {
		console.log("params.consignee_coordinates ", params.consignee_coordinates);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		});
		var picklatlng = params.consignee_coordinates;

		if (picklatlng != '') {
			var ltln = JSON.parse(picklatlng);
			//console.log("ltln ", ltln)
			this.setState({
				picklat: ltln.lat,
				picklng: ltln.lng
			});
		}

		console.log("picklatlng ", picklatlng)
		var formdata = {
			consignee_code: params.consignee_code,
			dept_code: this.state.deptcode
		}
		//console.log("formdata ", formdata)
		redirectURL.post("/vicinity/getRecentUnloadingLocationsForConsignee", formdata, {
			headers: {
				//'Content-Type': 'multipart/form-data',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': "*",
				'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
				// 'authorization': 'Zoho-oauthtoken 1000.ecb6dbd17a44125ce5434f5f45148278.a54f59a27d9e9db5383f85ba3002ccf2'
			}
		}).then((result) => {
					console.log(result)
					if (result.data.body == '') {
						this.setState({
							loadshow: 'show-n',
							show: true,
							basicType: "danger",
							basicTitle: "No Unloading Locations Data."
						});
					}
					else {

						var records = JSON.parse(result.data.body);

						//console.log("rowarray ", records)
						var consigneeCoordinate = [];
						var consignmentsarr = []
						records.map((item) => {
							consigneeCoordinate.push(item.coordinates)
							/*item.consignments.map((consignment) => {
								consignmentsarr.push(consignment)
							})*/

						});
						//console.log("consigneeCoordinate ", consigneeCoordinate)

						this.setState({
							rowData: records,
							loadshow: 'show-n',
							mwidth: "col-xl-7 col-lg-7",
							consigneeCoordinate: consigneeCoordinate
							//consigneeCoordinate:consigneeCoordinate,
							//consignments:consignmentsarr
						});

						this.renderMap();
					}
				})
			.catch(function (error) {
				console.log(error);
			});
	}

	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		if (this.state.consigneeCoordinate.length == 0) {
			//console.log("Yes");
			var lt = 28.4519751;
			var ln = 77.0310713;
		}
		else {
			//console.log("No");
			var lt = this.state.consigneeCoordinate[0].lat;
			var ln = this.state.consigneeCoordinate[0].lng;
		}
		allcords = this.state.rowData;
		//console.log("allcords ", allcords)
		map = new window.google.maps.Map(document.getElementById('map_canvas1'), {
			zoom: 13,
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			  },
			disableDefaultUI: true,
			zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function () {

			searchBox.setBounds(map.getBounds());
		});

		var markers = [];
		//console.log("searchBox",searchBox);
		searchBox.addListener('places_changed', function () {
			var places = searchBox.getPlaces();

			if (places.length == 0) {
				return;
			}

			// Clear out the old markers.
			markers.forEach(function (marker) {
				console.log("Search Marker ", marker)
				marker.setMap(null);
			});
			markers = [];
			// For each place, get the icon, name and location.
			var bounds = new window.google.maps.LatLngBounds();
			places.forEach(function (place) {
				if (!place.geometry) {
					console.log("Returned place contains no geometry");
					return;
				}
				var icon = {
					url: place.icon,
					size: new window.google.maps.Size(71, 71),
					origin: new window.google.maps.Point(0, 0),
					anchor: new window.google.maps.Point(17, 34),
					scaledSize: new window.google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				//console.log("Plae Lat", place.geometry.location.lat());
				document.getElementById("latitude").value = place.geometry.location.lat();

				document.getElementById("longitude").value = place.geometry.location.lng();

				markers.push(new window.google.maps.Marker({
					map: map,
					icon: icon,
					title: place.name,
					position: place.geometry.location
				}));

				if (place.geometry.viewport) {
					// Only geocodes have viewport.
					bounds.union(place.geometry.viewport);
				} else {
					bounds.extend(place.geometry.location);
				}
			});
			map.fitBounds(bounds);
		});

		circle = new window.google.maps.Circle({
			map: map
		});

		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			// 	//position: {lat: 28.48, lng: 77.06}
		});

		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Pick Lat ", this.state.picklat)
		if (this.state.picklat != '' && this.state.picklng != '') {
			var mainmark = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(parseFloat(this.state.picklat), parseFloat(this.state.picklng)),
				map: map
			});
			mainmark.setMap(map);
		}
		//console.log("state Lat ",this.state.rlatitude)
		/*if(lt != '' && ln != '')
		{
			//var geoFencingObj = allcords;
			//console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
			markerLatlng1 = new window.google.maps.LatLng(lt,ln);
			//console.log("markerLatlng", markerLatlng1)
			var circle = new window.google.maps.Circle({
				strokeColor: '#71caab',
				strokeOpacity: 0.7,
				strokeWeight: 1,
				fillColor: '#71caab',
				fillOpacity: 0.25,
				map: map,
				center: markerLatlng1,
				//radius: Math.sqrt(this.state.radius) * 10000
			});

		}
		var mainmark = new window.google.maps.Marker({
			position: new window.google.maps.LatLng(parseFloat(lt), parseFloat(ln)),
			map: map
		});
		mainmark.setMap(map);
		*/
		var markers = allcords.map((marker) => {
			var image = require('../../assets/icons/truck-end.png');
			var headerTitle = 'Consignee';
			var contentarr = [];
			// contentarr.push({"key":"Truck No","value":marker.truck_no});
			contentarr.push({ "key": "Consignee Code", "value": marker.consignee_code });
			contentarr.push({ "key": "Reached at Dealer", "value": marker.reached_dealer });
			contentarr.push({ "key": "Distance (Kms)", "value": marker.distance_in_km + " Kms" });
			var contentString = infoBox(image, headerTitle, contentarr, '')
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			var latlng = marker.coordinates;
			//console.log("latlng ", latlng)
			var markerLatlng = new window.google.maps.LatLng(parseFloat(latlng.lat), parseFloat(latlng.lng));
			//console.log("markerLatlng ", markerLatlng)

			var mark = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: marker.consignee_code,
				icon: image
			});
			mark.addListener('click', function () {
				infowindow.open(map, mark);
			});
			mark.setMap(map);
			return mark
		});
		var markerCluster = new MarkerClusterer(map, markers,
			{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });

		// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);

	}
	popmarker(e) {
		console.log("OnClick Table ", e.data)
		var clickcoordinate = e.data.coordinates;
		console.log(clickcoordinate.lat)
		var lat = parseFloat(clickcoordinate.lat);
		var lng = parseFloat(clickcoordinate.lng);
		var data = e.data;
		map.setCenter(new window.google.maps.LatLng(lat, lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image = require('../../assets/icons/truck-end.png');

		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title: "Consignee",
			icon: image
		});


		var image = require('../../assets/icons/truck-end.png');
		var headerTitle = 'Consignee';
		var contentarr = [];
		// contentarr.push({"key":"Truck No","value":data.truck_no});
		contentarr.push({ "key": "Consignee Code", "value": data.consignee_code });
		contentarr.push({ "key": "Gate out time", "value": data.gate_out_time });
		contentarr.push({ "key": "Reached at Dealer", "value": data.reached_dealer });
		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});

		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function () {
			infowindow.open(map, marker);
		});

		marker.setMap(map);


	}
	onShowLegs = async (params) => {
		//console.log(e);
		console.log("Consign Overspeed ", params);
		if (params != 0) {

			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "GR No", width: 140, field: "consignment_code", filter: true, resizable: true },
							{ headerName: "Truck No", width: 140, field: "truck_no", filter: true, resizable: true },
							{ headerName: "Transporter Code", width: 140, field: "transporter_code", filter: true, resizable: true },
							{ headerName: "Transporter Name", width: 140, field: "transporter_name", filter: true, resizable: true },
							{ headerName: "Plant Code", width: 140, field: "consigner_code", filter: true, resizable: true },
							{ headerName: "Plant Name", width: 140, field: "plant_name", filter: true, resizable: true },
							{ headerName: "Invoice No", width: 140, field: "invoice_no", filter: true, resizable: true },
							{
								headerName: "Invoice Time", field: "gate_out_time", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
											return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},
							{
								headerName: "Distance From Unloading Point To Dealer(Km)", width: 200, field: "distance_from_unloading_point_to_dealer", filter: true, resizable: true
							},
							{
								headerName: "Reached Time", field: "locked_dealer_reported", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.locked_dealer_reported != "" && params.data.locked_dealer_reported != undefined) {
											return getDDMMYYYYHHMMSS(params.data.locked_dealer_reported);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							}

						],

					},
					getDetailRowData: function (param) {
						console.log("param ", param)
						redirectURL.post('/consignees/dealerlockedlocation', {
							consignee_code: param.data.consignee_code
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'legs') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							// { headerName: "Truck No", field: "truck_no" },
							// { headerName: "Speed", field: "speed" },
							// { headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowRouteMap(rownode) {
		console.log("rownode", rownode);
		//console.log("rownode.consignment_code ", rownode.consignment_code)


		this.setState({
			loadshow: 'show-m'
		})
		try {
			console.log("rownode.drop_coordinates ", rownode.drop_coordinates)
			if (rownode.drop_coordinates != '') {
				var lt = rownode.drop_coordinates.split(",")
				var ltt = lt[0].split(":");
				var lat = parseFloat(ltt[1]);
				var lnt = lt[1].split(":");
				var lng = parseFloat(lnt[1]);
			}
			else {
				var lat = "";
				var lng = "";
			}
			if (lat != "" && lng != "") {
				var coords = [{ lat: lat, lng: lng }]
				var breaks = []
				var route_details = {}
				console.log("coords", coords)
				this.setState({
					mapinfo: { coords: coords, breaks: breaks, route_details: route_details },
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					rownode: rownode,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": rownode.gate_out_time,
						"endTime": rownode.total_travelled_trip_dist_km
					},
					overly: 'show-m',
					loadshow: 'show-n',
				});
			}
			else {
				this.setState({
					loadshow: "show-n",
					overly: "show-n",
					show: true,
					basicTitle: "No data found",
					basicType: "danger"
				})
			}


		}
		catch (e) {

		}

	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}

	render() {
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
			{
				headerName: "",
				field: "",
				width: 60,
				filter: false,
				resizable: true,
				cellRenderer: "MapButton"
			},
			{
				headerName: "",
				field: "",
				width: 60,
				filter: false,
				resizable: true,
				cellRenderer: "SecondMapButton"
			},
			{
				headerName: "",
				field: "legs",
				width: 60,
				filter: false,
				resizable: true,
				cellRenderer: "ShowLegs"
			},
			{
				headerName: "Consignee Code",
				field: "consignee_code",
				width: 100,
				filter: true,
				resizable: true
			},
			{
				headerName: "Consignee Name",
				field: "consignee_name",
				width: 150,
				filter: true,
				resizable: true
			},
			{
				headerName: "Consignee City",
				field: "consignee_city",
				width: 150,
				filter: true,
				resizable: true
			},

			// {
			// 	headerName: "Gate Out Time",
			// 	field: "gate_out_time",
			// 	width: 150,
			// 	filter: true,
			//     Routeresizable: true,
			//     valueGetter:function(params){
			//         return getDDMMYYYYHHMMSS(params.data.gate_out_time)
			//     }
			// },

			// {
			// 	headerName: "Reached Destination",
			// 	field: "reached_dealer",
			// 	width: 150,
			// 	filter: true,
			//     Routeresizable: true,
			//     valueGetter:function(params){
			//         return getDDMMYYYYHHMMSS(params.data.reached_dealer)
			//     }
			// },

			// {
			// 	headerName: "Distance (Km)",
			// 	field: "distance_in_km",
			// 	width: 150,
			// 	filter: true,
			//     Routeresizable: true
			// },
		];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: '0px !important'

		}

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
								</h5>
							</div>
							<div className="card-body">
								{/* <div className="row col-xl-12 col-lg-12">
                                    <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
										<Select name="consignee_code" placeholder="Select Consignee" className="rselect" value={this.state.consignee_code} onChange={this.handlerForm.bind(this)} options={this.selectOptionsItems()} />

										</div>

                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                </div> */}
								<div className="row col-xl-12 col-lg-12">

									<div className={"dataLoadpage " + (this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " + (this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									{/* {
									(this.state.rowData.length > 0)? */}
									<div className="col-xl-6 col-lg-6 sidediv">


										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.consigneeRowData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												gridOptions={{
													context: { componentParent: this }
												}}
												sideBar={this.state.sideBar}
												detailCellRendererParams={this.state.detailCellRendererParams}
												masterDetail={true}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												floatingFilter={true}
												frameworkComponents={this.state.frameworkComponents}
											//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
											// onCellClicked={this.popmarker}
											/>
										</div>
									</div>
									{/* : ""
									} */}
									<div id="map_canvas1" className="col-xl-6 col-lg-6" style={dStyles}></div>

								</div>
							</div>
						</div>
					</div>

				</div>

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								View Location
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									mapFor={"truck"}
									consignercords={[]}
									tolls={[]}
									consigneecords={[]}
									rownode={this.state.rownode}
									truckno={this.state.maptruckno}
									dealer={[]}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}

							</div>
						</div>

					</div>
					: ""
				}

				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			</div>

		);
	}
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1 / 180;
		var radlat2 = Math.PI * lat2 / 180;
		var theta = lon1 - lon2;
		var radtheta = Math.PI * theta / 180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180 / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit == "K") { dist = dist * 1.609344 }
		if (unit == "N") { dist = dist * 0.8684 }
		return dist;
	}
}
