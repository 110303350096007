import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

//test commit again with the team

// ** Import custom components **
import store from './store';
import App from './components/app';

// Super Admin Screens
import AdminMenus from './components/superadmin/menus'
import AdminRoles from './components/superadmin/roles'
import AdminUserRoles from './components/superadmin/userroles'
//import AdminRoleMenus from './components/superadmin/assignrolemenus'

import AdminRoleMenus from './components/superadmin/assignmenustoroles'
import AdminViewSubMenus from './components/superadmin/view-submenus'
import AdminMenuReOrder from './components/superadmin/menu-reorder'

// Dashboards
import Default from './components/dashboard/default/index.component'
import Overview from './components/dashboard/default/overview'
import Ecommerce from './components/dashboard/ecommerce/index.component'

import Login from './components/login/login'
import Logout from './components/login/logout'
import Register from './components/login/register'
import LmRegister from './components/login/lmregister'
import LmLogin from './components/login/lmlogin'
import AuthCallBack from './components/login/authcallback'
import ForgetPassword from './components/pages/forget-password'
import Comingsoon from './components/pages/comingsoon'
import Token from './components/zoho/token'
import Accounts from './components/zoho/accounts'
import Folders from './components/zoho/folders'
import ZohoMessages from './components/zoho/messages'
import AttachmentInfo from './components/zoho/attachmentinfo'
import Attachment from './components/zoho/attachment'
import RefreshToken from './components/zoho/refreshToken'
import Assets from './components/assets/assets'
import GeoFence from './components/geofence/geofence-list'
import TptGeoFence from './components/tptlogin/tpt-geofence-list'
import AddGeoFence from './components/geofence/add-geofence'
import AddTptGeoFence from './components/tptlogin/add-tpt-geofence'
import EditGeoFence from './components/geofence/edit-geofence'
import EditTptGeoFence from './components/tptlogin/edit-tpt-geofence'
import ChangeGeoFence from './components/geofence/change-geofence'
import ManageTrucks from './components/manage/trucks'
import ManageConsignments from './components/manage/consignments'
import ManageSNDConsignments from './components/manage/sndconsignments'
import ManagePRTConsignments from './components/manage/prtconsignments'
import ManageMOVEConsignments from './components/manage/moveconsignments'
import ManagePRTConsigners from './components/manage/prtconsigners'
import ManagePRTTransitTimes from './components/manage/prttransittimes'
import ManageSNDTransitTimes from './components/manage/sndtransittimes'
import ManageTransporters from './components/manage/transporters'
import ManageDepartments from './components/manage/departments'
import ManageConfigurations from './components/configurations/settings'
//import VicinityScreen from './components/vicinity/view-vicinity'
import VicinityScreen from './components/layouts/truckwithingeofence'
import TptVicinityScreen from './components/tptlogin/tpttruckwithingeofence'

import ReportOverspeed from './components/reports/overspeed'
import ReportEnrouteStoppages from './components/reports/enroutestoppages'
import ManageChaformat from './components/manage/chaformat'
import ManageImportsConsignments from './components/manage/importsconsignments'
import ManageImportsDeliveredContainers from './components/manage/importsdeliveredcontainersComponent'
import ManageImportsConsignmentsFreightForward from './components/manage/importsconsignmentfreightforward'
import ManageNearByConsignmentTrucks from './components/manage/consignment-nearby-trucks'
import ManageNearByTransporterTrucks from './components/tptlogin/transporter-nearby-trucks'
import ManageCourierConsignments from './components/layouts/courierconsignmentsComponent'
import ConsignementDashboard from './components/layouts/dashboardconsignmentComponent'
import ConsignementComponent from './components/layouts/consignmentComponent'
import ContainerDashboard from './components/layouts/dashboardcontainerComponent'
import WeatherComponent from './components/layouts/weathercomponent';
import EditWeatherEvent from "./components/layouts/editweathercomponent";
import ConsignmentsTodayDashboard from './components/layouts/consignmentsToday.component';
import ConsignmentsGeofence from './components/layouts/consignmentgeofence';

import ConsigneeVicinity from './components/layouts/consigneevicinityComponent';
import ConsignmentsKpi from './components/layouts/consignmentskpi.component';
import ManageTruckConsignments from './components/layouts/truckswithconsignmentsComponent';
import ManageExportsConsignments from './components/manage/exportsconsignments.component';
//import TranshipmentExports from './components/manage/transhipmentexports'
import TranshipmentExports from './components/manage/chatranshipmentexports'
import ExportsDeliveredConsignments from './components/manage/deliveredconsignmentComponent';
import ExportsPRTDeliveredConsignments from './components/manage/exportsprtdeliveredconsignmentComponent';
import PipavaMudraRoadPlan from './components/layouts/pipavamundraroadplanComponent'
import ViewPipavaMudraRoadPlan from './components/layouts/viewpipavamundraroadplanComponent'
import IcdGarhiContainerPlan from './components/layouts/icdgarhicontainerplanComponent'
import CHADocumentReceits from './components/layouts/chadocumentreceitComponent';
import CHANhavaSheva from './components/layouts/chanhavashevaComponent';
import CHADestinationPort from './components/layouts/chadestinationport';
import SparesCHADestinationPort from './components/layouts/spareschadestinationportComponent';
import DCTContaierUpdates from './components/layouts/dctcontainerupdates';
import DCTSparesContaierUpdates from './components/layouts/dctsparescontainerupdates';
import DeliveredConsignmentsComponent from './components/manage/deliveredconsignments.component'
import TMS from './components/layouts/ticketmanagementsystemComponent';
import TMSInvalid from './components/layouts/ticketmanagementsystemComponent';
import ConsignmentsAllComponent from './components/manage/consignmentsall.component'
import TrucksGPSData from './components/layouts/trucksgpsdataComponent';
import TptTrucksGPSData from './components/tptlogin/tpttrucksgpsdataComponent';
import Maintenance from './components/pages/maintenance';
import EmptyReturnedTrucks from './components/layouts/trucksemptyreturngeofenceComponent';
import OnTripGPSReport from './components/layouts/ontripgpsgpsreport';
import ManageImportsCoils from './components/manage/importscoils.component';
import CoilImports from './components/manage/coilimports';
import CoilImportsDelivered from './components/manage/coilimportsdelivered';
import ManageUserMailMap from './components/manage/usermailmap';
import ChangePasswordComponent from './components/manage/changepassword.component';
import ResetPassword from './components/login/reset-password';
import AssignRoles from './components/superadmin/assignroles';
import AndriodMap from './components/common/andriodmap';
import DepartmentTMS from './components/layouts/departmenttmsComponent';
import CHACoilsImports from './components/layouts/chacoilsimportsComponent';
import CoilsDispatch from './components/layouts/coilsdispatchComponent';
import ManageImportsContainer from './components/manage/importscontainersComponent';
import DashboardTemplate from './components/layouts/dashboardtemplateComponent';
import DashboardTemplate1 from './components/layouts/dashboardtemplateComponentnew';
import DashboardSummary from './components/layouts/dashboardsummaryComponent';
import DashboardDelayResponse from './components/layouts/dashboardDelayResponse';
import DashboardTickets from './components/layouts/dashboardTicketsComponent';
import DepartmentDashboard from './components/layouts/dashboarddepartmentComponent';
import PRTDepartmentDashboard from './components/layouts/prtdashboardComponent';
import RakesConsignmentComponent from './components/layouts/rakeComponent';
import ForceClosureComponent from './components/layouts/forceclosureComponent';
import TranshipmentComponent from './components/layouts/transhipmentComponent';
import TranshipmentReportComponent from './components/layouts/transhipmentReport.js';
import ManageRailConsignments from './components/layouts/railconsignmentsComponent';
import RoadPickUpComponent from './components/layouts/roadPickUpComponent';
import LoadingDashboard from './components/layouts/loadingDashboardComponent';
import DailyRunningDashboard from './components/layouts/dailyrunningdashboardComponent';
import TransshipmentTransporterComponent from './components/layouts/transshipmenttransporterComponent';
import TransporterComponent from './components/layouts/transporterconsignmentsComponent';
import TransporterTickets from './components/layouts/transporterTicketComponent';
import RailTranshipment from './components/layouts/railtranshipmentComponent';
import AlloybarComponent from "./components/layouts/alloybarsComponent";
import UnLoadingDashboard from './components/layouts/unloadingdashboardComponent';
import TransitDashboardComponent from './components/layouts/transitdashboardComponent';
import DailyRunningTransporterDashboardComponent from './components/layouts/dailrunningtransporterdashboardComponent';
import GPSDashboard from "./components/layouts/gpsdashboardComponent";
import ManualExports from "./components/layouts/manualExportsComponent";
import SegmentSideBar from "./components/layouts/segmentComponent";
import HolidayComponent from "./components/layouts/holidayComponent";
import GPSTransporter from "./components/layouts/gpstransporterdashboardComponent";
import TransporterIntegrationDashboardComponent from './components/layouts/transporterintegrationdashboardComponent';
import Error404 from './components/pages/error-404';
import OnTimeDashboardComponent from './components/layouts/ontimedashboardComponent';
import ShiftTimings from "./components/layouts/shiftTimingsComponent";
import AirCargoListComponent from "./components/layouts/airCargoListComponent";
import AirCargoForm from "./components/layouts/airCargoComponent";
import EditCargoEvent from "./components/layouts/editaircargoform";
import EditFFNewAircargo from "./components/layouts/editffnewaircargoform";
import FFAirCargoListComponent from "./components/layouts/ffAirCargoListComponent";
import EditFFCargoEvent from "./components/layouts/editffaircargoform";
import CreateFFCargoEvent from "./components/layouts/createffaircargoform";
import ChaAirCargoListComponent from "./components/layouts/chaAirCargoListComponent";
import TransporterAirCargoListComponent from "./components/layouts/transporterAirCargoListComponent";
import EditChaCargoEvent from "./components/layouts/editchaaircargoform";
import EditTransporterCargoEvent from "./components/layouts/edittransporteraircargoform";
import EditClosureCargoEvent from "./components/layouts/editclosureaircargoform";
import LoadAttachment from "./components/layouts/loadattachmentComponent";
import PossibleTransshipments from "./components/layouts/possibleTransshipments";
import LoadAttachmentExit from "./components/layouts/loadattachmentexitComponent";
import ChaDocumentHistory from "./components/layouts/chadocumenthistory.js";
import RailVinConsignments from "./components/layouts/railvinconsignmentsComponent.js";
import RailVINTranshipment from './components/layouts/railvintranshipmentComponent';
import VinRoadPickUpComponent from './components/layouts/vinroadpickupComponent';
import StoppageReport from "./components/layouts/stoppageReport";
import ConsigneEexceptionComponent from "./components/layouts/consignmentconsigneeexceptionComponent";
import LoadArrivals from "./components/layouts/loadArrivalsComponent";
import TransitTimeSummary from "./components/layouts/transitTimeSummary";
import GoogleAnalytics from "./components/layouts/googelanalyticsComponent";
import ReturnPallet from "./components/layouts/returnPallets";
import SNDUnloadingDashboard from "./components/layouts/sndUnloadingDashboardComponent";
import DistanceTravelledReport from "./components/layouts/distancetravellreportComponent";
import ProbableAccidentComponent from "./components/layouts/probableAccidentComponent"
import RakesScheduleComponent from './components/layouts/rakescheduleComponent';
import DealerCompensation from "./components/layouts/dealercompensation";
import InsideMSIL from "./components/manage/insidemislvicinity";
import ManualLocationUpdate from "./components/layouts/manuallocationupdateComponent";
import AddManualLocationUpdate from "./components/layouts/addlocation";
import EditManualLocationUpdate from "./components/layouts/editlocation";
import LocalConsignments from "./components/layouts/localconsignmentsComponent";
import PRTTurnAroundTime from "./components/layouts/prtturnaroundtime";
import UserLogReport from "./components/layouts/userlogreport";
import ACTLContainers from "./components/layouts/actlcontainersComponent";
import DealerCompensationOldData from "./components/layouts/dealerCompOld"
import AssignACTLContainers from "./components/layouts/assignactlcontainersComponent";
import LiveTruckLocationMap from "./components/layouts/livetrucklocationmapComponent";
import LiveTruckMap from "./components/layouts/livetruckmapComponent";
import BreaksClusterComponent from "./components/layouts/breakClusterComponent";
import LiveTrucksInfo from "./components/layouts/livetrucksinfoComponent";
import UniqueTrucksTrips from "./components/layouts/uniquetruckstrips";
import MSILDashboard from "./components/layouts/msildashboardtabs";
import PrimaryDashboard from "./components/layouts/primarydashboardtabs";
import SecondaryDashboard from "./components/layouts/secondarydashboardtabs";
import StockyardDashboard from "./components/layouts/stockyarddashboardtabs";
import CatalyticMissingBreakClusters from "./components/layouts/catalyticMissingBreaksCluster";
import ACTLContainerPlan from "./components/layouts/actlcontainerplanComponent";
import SndRegionWiseReport from "./components/layouts/sndregionwisereport";
import Billing from "./components/layouts/billingComponent";
import BillingSC from "./components/layouts/billingsupplychainComponent";
import BatchWiseClusters from "./components/layouts/batchwiseclusters";
import BatchWiseCatalysis from "./components/layouts/batchwisecatalysisreport";
import UpdateRailAnaytics from "./components/layouts/railanalyticsupdateComponent";
import RailAnaytics from "./components/layouts/railanalyticsdashboardComponent";
import AddLclImports from "./components/layouts/addlclimports";
import LclImports from "./components/layouts/lclimports";
import ImportsShipments from "./components/layouts/importsshipments";
import RouteDeviationMap from "./components/layouts/routeDeviationComponent";
import DeviationRoutes from "./components/layouts/routeDeviationreportComponent";
import OptimizeDeviationRoutes from "./components/layouts/routedeviationoptimizeComponent";
//import WeatherHeatMap from "./components/layouts/weatherHeatMap";
import StateBorderGeofenceMap from "./components/layouts/stateBordersGeofence"
import GoldeZoneDashboard from "./components/layouts/goldenzonedashboardComponent";
import ReturnTrips from "./components/layouts/returntripsComponent";
import NewDeviationRoutes from "./components/layouts/routeDeviationReportNewComponent";
import TruckAvailability from "./components/layouts/truckavailabilityComponent";
import ReturnTripsDashboardChart from "./components/layouts/returnTripsDashboardComponent";
import BillingDashboard from "./components/layouts/billingDashboardComponent";
import ViewBillAction from "./components/layouts/viewbillaction";
import LmStates from "./components/layouts/lmstates";
import LmVehicleCapacity from "./components/layouts/lmvehiclecapacity";
import LmFreights from "./components/layouts/lmfreights";
import LmRouteConfig from "./components/layouts/lmrouteconfig";
import LmConsignees from "./components/layouts/lmconsignees";
import LmBaseConfig from "./components/layouts/lmbaseconfig";
import LmLoadManager from "./components/layouts/lmloadmanager";
import DetentionReport from "./components/layouts/detentionReport"
import USDRates from "./components/layouts/usdratesComponent";
import ICDRates from "./components/layouts/icdratesComponent";
import AutomotiveGeoFence from "./components/geofence/automotivegeofences";
import AddAutomotiveGeoFence from "./components/geofence/addautomotivegeofence";
import EditAutomotiveGeoFence from "./components/geofence/editautomotivegeofence";
import LmTransporters from "./components/layouts/lmtransporters";
import LmAddTransporters from "./components/layouts/lmaddtransporters";
import AltoRates from "./components/layouts/altoRatesComponent";
import LmShareOfBusiness from "./components/layouts/lmshareofbusiness";
import LmCurrentShareOfBusiness from "./components/layouts/lmcurrentsob";
import LmLoadShare from "./components/layouts/lmloadshare";
import LmSobZones from "./components/layouts/lmsobzones";
import RailInventory from "./components/layouts/railinventoryComponent";
import OEMBreaks from "./components/layouts/oemBreaksComponent";
import PRTOptimizeDeviationRoutes from "./components/layouts/routedeviationprtoptimizeComponent";
import PRTDeviationRoutes from "./components/layouts/routedeviationprtreportComponent";
import MarkDeliveredRailConsignments from "./components/layouts/railconsignmentsmarkdeliverComponent";
import LmLoadSummary from "./components/layouts/lmloadsummary";
import ViewLoadSummaryDetails from "./components/layouts/viewLoadSummaryDetails";
import ReturnTripsBreakClusters from "./components/layouts/returnTripsBreaksClusters";
import RoutePlanning from "./components/layouts/routesndplanningComponent";
import TNPOptimizeDeviationRoutes from "./components/layouts/routedeviationtnpoptimizeComponent";
import TNPDeviationRoutes from "./components/layouts/routedeviationtnpreportComponent";
import RailPlanning from "./components/layouts/railplanningComponent";
import LmTruckAvailability from "./components/layouts/lmtruckavailability";
import LmLoadingPorts from "./components/layouts/lmloadingports";
import TransitAnalytics from "./components/layouts/transitAnalyticsDashboard";
import ExportsContainerTracking from "./components/layouts/exportscontainertrackingComponent";
import SparesExportsContainerTracking from "./components/layouts/sparesexportscontainertracking";
import SparesExportsContainerDelivered from "./components/layouts/sparesexportscontainerdelivered";
import SparesExportsDestinationPorts from "./components/layouts/sparesexportsdestinationpoints";
import PRTUnutilizedTrucks from "./components/layouts/prtUnutilizedTrucks";
import AnalyticsDashboard from "./components/layouts/analyticsdashboardComponent";
import RailLoadOptimizationComponent from "./components/layouts/railLoadOptmizationcomponent"
import GetLoadOptimizationReport from "./components/layouts/getOptimizedLoadReport";
import MSILVesselSchedule from "./components/layouts/msilvesselscheduleComponent";
import EmptyTrucksVicinity from "./components/layouts/emptytrucksvicinityComponent";
// import TptEmptyTrucksVicinity from "./components/tptlogin/tptemptytrucksvicinity";
import RakeArrivals from "./components/layouts/rakearrivals";
import ManagementDashboard from "./components/layouts/managementDashboard";
import RakeStockStatus from "./components/layouts/rakestockstatus";
import UnderConstruction from "./components/layouts/undeconstruction";
import PlanningStatus from "./components/layouts/planningstatus";
import SafetyReport from "./components/layouts/safetyreport";
import DivisionHealthReport from "./components/layouts/divisionhealthreportComponent";
import LoadFactors from "./components/layouts/loadFactorsComponent";
// import MLLRailSummaryComponent from "./components/mll/mllsummaryComponent";
import MLLRailSummaryComponent from "./components/mll/mllmainComponent";
import MLLConsignments from "./components/mll/mllconsignmentsComponent";
import MLLCreateBatch from "./components/mll/mllcreatebatchComponent";
import MllMarkArrivalLoadingYard from "./components/mll/mllmarkarrivalloadingyardComponent";
import LmVOutput from "./components/layouts/lmvoutput";
import LmVOutputDetails from "./components/layouts/lmvoutputdetails";
import MllWagonCheck from "./components/mll/mllwagonchecksheetComponent";
import MllTrainLoadCheck from "./components/mll/mlltrainloadingchecksheetComponent";
import MllTrainUnLoadCheck from "./components/mll/mlltrainunloadingchecksheetComponent";
import AddMllWagonCheck from "./components/mll/addmllwagonchecksheetComponent";
import AddMllTrainLoadCheck from "./components/mll/addmlltrainloadingchecksheetComponent";
import WeatherMap from "./components/layouts/weathermap";
import LmDepartments from "./components/layouts/lmdepartments";
import GeofenceMap from "./components/mll/geofencemapComponent";
import LmUsers from "./components/layouts/lmusers";
import LmRoles from "./components/layouts/lmroles";
import LmUserActivities from "./components/layouts/lmuseractivities";
import MLLAllConsignmentsComponent from "./components/mll/mllallconsignmentsComponent";
import LmWelcome from "./components/layouts/lmwelcome";
import LmPageAccess from "./components/layouts/lmpageaccess";
import LmLoadingPortAccess from "./components/layouts/lmloadingportaccess";
import MLLTransporters from "./components/mll/mlltransportersComponent";
import MLLTrucks from "./components/mll/mlltrucksComponent";
import MLLPlants from "./components/mll/mllplantComponent";
import MLLDealers from "./components/mll/mlldealerComponent";
import LmUserLogReport from "./components/layouts/lmuserlogreport";
import AddMllTrainUnLoadCheck from "./components/mll/addmlltrainunloadingchecksheetComponent";
import MLLLastMileBatch from "./components/mll/mlllastmilebatchComponent";
import MLLTrucksGPS from "./components/mll/mlltrucksgpsComponent";
import MllTrucksWithInGeofence from "./components/mll/mlltruckwithingeofenceComponent";
import MLLLoadingYardArrival from "./components/mll/mllloadingyardarrivalComponent";
import RegisteredDevices from "./components/layouts/registeredDevices";
// import TptRegisteredDevices from "./components/tptlogin/tptregisteredDevices";
// import TptPendingInstallations from "./components/tptlogin/tptpendinginstallations";
// import tptGpsIntegration from "./components/tptlogin/tptGpsIntegration";
// import tptGpsIntegration from "./components/tptlogin/tptintegrationwithtabsComponent";
import BRMDashboard from "./components/layouts/brmDashboard";
import MLLLoadingOfficers from "./components/mll/mllloadingofficersComponent";
import MLLFirstMileTransshipments from "./components/mll/mllfirstmiletransshipmentComponent";
import MLLLastMileTransshipments from "./components/mll/mlllastmiletransshipmentComponent";
import MLLFirstMileRetrivals from "./components/mll/mllfirstmileretrivalComponent";
import MLLHandOverRetrivals from "./components/mll/mllgpsdeicehandretrivalComponent";
import MLLRSOPOD from "./components/mll/mllrsopodComponent";
import PRTOnTimeComponent from "./components/layouts/prtOntimeDashboard";
import MLLRakeRetrivals from "./components/mll/mllgpsdevicerakeretrivalsComponent";
import MLLRSORetrivals from "./components/mll/mllrsoretrivalsComponent";
import MLLDeviceDashboard from "./components/mll/mlldevicedashboardComponent";
import MLLIssueToBA from "./components/mll/mllissuedevicetobaComponent";
import BRMDashboardMgmt from "./components/layouts/brmdashboardmgmt";
import BRMDashboardMgmtMobile from "./components/layouts/brmdashboardmgmtmobile";
import MLLGPSDevicePlanning from "./components/mll/mllgpsdeviceplanningComponent";
import MLLNotifications from "./components/mll/mllnotificationComponent";
// import TptReportOverspeed from "./components/tptlogin/overspeed";
// import TPTGpsEodAnalysis from "./components/tptlogin/tptgpseodanalysisComponent";
import MLLFrieghtFirstMileRates from "./components/mll/mllfreightfirstmileratesComponent";
import MLLGPSDevices from "./components/mll/mllgpsdevicesComponent";
import MLLRakeShipToRSORetrivals from "./components/mll/mllgpsdevicerakeshiptorsoComponent";
import MLLShipToPlantRSO from "./components/mll/mllshiptoplantrsoComponent";
import CreateUser from "./components/layouts/createuser";
import MllAssignRoles from './components/mll/mllassignroles';
import MllAdminUserRoles from './components/mll/mlluserroles';
import MLLLastMileRSOBatch from './components/mll/mlllastmilersobatchComponent';
import MllConsignmentNearByTrucks from "./components/mll/mllconsignmentnearybytruckComponent";
import MllKPIDashboard from "./components/mll/mllkpidashboardComponent";
import MllDamageAnalysis from "./components/mll/mlldamageanalysisComponent";
import GPSBilling from "./components/layouts/gpsbillingComponent";
// import TPTGPSBilling from "./components/tptlogin/tptgpsbillingComponent";
// import TPTGPSBillingNonMSIL from "./components/tptlogin/tptgpsbillingnonmsilComponent";
// import TPTSubUsers from "./components/tptlogin/tptsubusers";
import SecondaryPaymentSummary from "./components/layouts/secondarypaymentsummaryComponent";
import DamageDashboard from "./components/layouts/damageDashboardComponent";
import GPSBillingDashboard from "./components/layouts/gpsbillingdashboardComponent";
import ViewGPSBillAction from "./components/layouts/viewgpsbilling";
// import TPTGPSBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardComponent";
// import TPTViewGPSBillAction from "./components/tptlogin/tptviewgpsbilling";
// import TPTGPSNonMSILBillingDashboard from "./components/tptlogin/tptgpsbillingdashboardnonmsil";
// import TPTViewGPSNonMSILBillAction from "./components/tptlogin/tptviewgpsbillingnonmsil";
import AndriodGridMap from './components/common/andriodgridmap';
import MobileTruckWithinGeoFence from './components/common/mobileTruckWithInGeoFence';
import TruckLocationForMobile from './components/common/truckLocationForMobile';
import TPTTrucksDistanceMatrix from './components/tptlogin/tpttrucksdistancematrixComponent';
import LoadPlanToDashboard from './components/layouts/loadplanningtodashboardComponent';
// import Mantis from './components/common/mantis';
// import VehicleRegister from './components/tptlogin/tptvehicleregisterComponent';
// import TPTTrucks from "./components/tptlogin/tpttrucksComponent";
// import TPTCarrier from "./components/tptlogin/tptcarrierComponent";
// import TPTGroup from "./components/tptlogin/tptgroupComponent";
import TransporterPerformanceDashboard from "./components/layouts/transporterperformancedashboard";
// import TPTGeofenceIncursion from "./components/tptlogin/tptgeofenceincurssionsComponent";
// import TPTDrivers from "./components/tptlogin/tptdriversComponent";
// import TPTNightDriving from "./components/tptlogin/tptnightdrivingComponent";
import TransporterInfo from "./components/transporter/transporterinfo";
import RailOperationsMonitoring from "./components/layouts/railoperationsmonitoring";
import ExitStatus from "./components/layouts/exitstatus";
import RetrivalExitReport from "./components/layouts/retrivalexitreport";
import CityWiseDieselPrices from "./components/layouts/cityWiseDieselPricesComponent";
// import TPTRailSummary from "./components/tptlogin/tptrailsummaryComponent";

// import TPTConsignments from "./components/tptlogin/tptrailconsignmentsComponent";
// import TPTCreateBatch from "./components/tptlogin/tptcreatebatchComponent";
// import TPTMarkArrivalLoadingYard from "./components/tptlogin/tptmarkarrivalloadingyardComponent";
// import TPTLoadingYardArrival from "./components/tptlogin/tptloadingyardarrivalComponent";
// import TPTAddWagonCheck from "./components/tptlogin/tptaddwagonchecksheetComponent";
// import TPTAddTrainLoadCheck from "./components/tptlogin/tptaddtrainloadingchecksheetComponent";
// import TPTAddTrainUnLoadCheck from "./components/tptlogin/tptaddtrainunloadingchecksheetComponent";
// import TPTLastMileBatch from "./components/tptlogin/tptlastmilebatchComponent";
// import TPTDevices from "./components/tptlogin/tptdevicesComponent";
// import TPTLoadingOfficers from './components/tptlogin/tptloadingofficersComponent';
// import TPTTransporters from "./components/tptlogin/tpttransportersComponent";
// import TPTOEMMaster from "./components/tptlogin/tptoemmasterComponent";
// import TPTOEMLocations from "./components/tptlogin/tptoemlocationComponent";
// import TPTOEMDealers from "./components/tptlogin/tptoemdealersComponent";
// import TPTOEMTransitTimes from "./components/tptlogin/tptoemtransittimesComponent";
// import TPTOEMConsignments from "./components/tptlogin/tptoemconsignmentsComponent";
// import TPTHarshBreaking from "./components/tptlogin/tptharshbreakComponent";
// import TPTGPSSingleScreen from "./components/tptlogin/tptgpsintegrationsinglescreenComponent";
import DamageAnalysis from "./components/layouts/damageanalysis";
import ImportsCoilTracking from "./components/layouts/importscoiltracking";
import AndriodTPTRailMap from "./components/common/andriodtptrailmap";
import RailLoadPlanAnalysis from "./components/layouts/railPlanComponent";
// import CreateAnalyticFrames from "./components/dashboard/analyticsdashboardComponent";
//import CreateAnalyticFrames from "./components/dashboard/createanalyticsframe";
//import EditAnalyticFrames from "./components/dashboard/editanalyticsframe";
import ShareOfBusiness from "./components/layouts/shareofbusiness"
import ZohoAnalyticDashboard from "./components/dashboard/analyticsdashboardComponent";
import FreightMaster from "./components/layouts/freightmaster";
import OverviewAnalytics from "./components/dashboard/default/analyticsdashboard";
import Railautoplaning from "./components/layouts/railautoplaning";
import Coilvendors from './components/layouts/coilvendors';
import Coilmsps from './components/layouts/coilmsps';
import SobCompleteData from './components/layouts/shareofbusinesscompletedata';
import AccidentalProneZones from "./components/layouts/accidentalProneZoneTabs";
// import AccidentalProneZonesTab from "./components/layouts/accidentalProneZonesTab";
import AddAccidentalProneZone from "./components/layouts/addAccidentalProneZoneForm"
import EditAccidentProneZone from "./components/layouts/editAccidentalProneZone"
import NoParkingZones from "./components/layouts/noparkingzones";
import AddNoParkingZones from "./components/layouts/addnoparkingzones";
import EditNoParkingZones from "./components/layouts/editnoparkingzones";
import BIDashboardRailStock from "./components/dashboard/railstockdashboardComponent";
import AirCargoExportForm from "./components/layouts/aircargoexportsform";
import AirCargoExportListComponent from "./components/layouts/aircargoexportslist";
import EditCargoExport from "./components/layouts/editaircargoexportsform";
import TransporterAirCargoExportsListComponent from "./components/layouts/transporteraircargoexportslist";
import EditTransporterExportsCargoEvent from "./components/layouts/edittransporteraircargoexportsform";
import ChaAirCargoExportsListComponent from "./components/layouts/chaaircargoexportslist";
import EditChaExportsCargoEvent from "./components/layouts/editchaaircargoexportsform";
import Error405 from './components/pages/error-405';
import SafetyIncidents from "./components/layouts/safetyincidents";
import ShareOfBusinessInterPant from "./components/layouts/shareofbusinessinterplant";
import SNDStockDashboard from "./components/dashboard/sndstockdashboardComponent";
import TnpUserItemsTabs from "./components/layouts/tnpuseritemstab";
import FFAirCargoExportsListComponent from "./components/layouts/ffaircargoexportslist";
import EditFFCargoExports from "./components/layouts/editffaircargoexportsform";
import ContractMonitoringSystem from "./components/layouts/contractmonitoring";
import AddContract from "./components/layouts/addcontract";
import EditContract from "./components/layouts/editcontract";
import PrtVolumeFreightFileUpload from "./components/layouts/prtVolumeFreightFileUpload";
import TransitReturnsBiDashboard from "./components/layouts/transitreturnsbidashboard";
import AddNewTransitReturns from "./components/layouts/addnewtransitbidashboard";
import EditNewTransitReturns from "./components/layouts/edittransitbireturns";
import SeaImportsForm from "./components/layouts/seaimportsform";
import CmsAutoMailConfig from "./components/layouts/cmsautomailconfig";
import BiTransitReturnDashboard from "./components/dashboard/transitreturnsbidashboard";
import RouteViewMap from "./components/common/routemapview";
import CreateConsignment from "./components/manage/consignmentcreationComponent";
import Parkings from './components/layouts/parkinglotsComponent'
import ParkingOutReport from './components/layouts/parkingOutReport'
import OverSpeedReport from './components/layouts/overSpeedReport'
import NightDrivingReport from './components/layouts/nightdrivingreport'
import EnrouteStoppageReport from './components/layouts/enroutestoppagereport'
import DealerZoneWiseRoutes from "./components/layouts/dealerZoneWiseRoutes"
import MonthlyDistanceTravelled from './components/layouts/monthlyDistanceTravelled'
import ConsolidateReport from './components/dashboard/default/conslidatereportComponent';
import FleetSummaryStatus from './components/layouts/fleetsummarystatuscomponent';
import DeviationForDeler from './components/layouts/routeDeviationForDealer';
import GPSNRD from './components/dashboard/default/gpsnrdComponent';
import DeviationRoutesData from "./components/layouts/deviationRoutesData";
import PlantTranshipmentMapping from './components/layouts/plantTranshipmentMapping';
import LspTvpTranshipmentMapping from './components/layouts/lsptvpMapping';
import SndWareHouseTranshipmentComponent from './components/layouts/sndWareHouseTranshipments';
import TvpWareHouseTranshipmentComponent from './components/layouts/tvpTranshipmentMapping';
import LspWhMapping from './components/layouts/lspwhmapping';
import TollsInfo from './components/layouts/tollsInfo';
import TopBaseRouteSummary from "./components/layouts/topBaseRoutesSummary";
import SavedBaseRoutes from "./components/layouts/savedBaseRoutes";
import IgnoredTrucks from './components/manage/ignoredTrucks';
import IgnoredRoutes from './components/layouts/getIgnoredRoutes';
import TruckMonthlyBillingInfo from './components/manage/truckMonthlyBillingInfo';
import UsersList from './components/layouts/userslist';
import PrtDeviaitonAnalysis from './components/layouts/deviationanalysisforPRTscreen';
import AtDealerShipDashboard from './components/manage/atDealerShipDashboard'
import VehicleArrivalDashboard from './components/manage/vehicleArrivalDashboard';
import GpsFleetSummaryStatusFromAPI from './components/layouts/fleetSummaryStatusFromAPI';
import DailyRunningDashboardCharts from './components/layouts/dailyrunningroutewisedashboard';
import TPTTrucksGPSStatus from './components/reports/tptTrucksGpsStatus';
import EnmUserLogin from './components/login/enmuserlogin'
// import CourierConsignments from './components/courier/courierconsignmentsComponent';



class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>

                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                            <Route exact path={`${process.env.PUBLIC_URL}/tptlogin`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                            <Route exact path={`${process.env.PUBLIC_URL}/loadmanagement/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/loadmanagement/logout`} component={Logout} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                            <Route path={`${process.env.PUBLIC_URL}/lmregister`} component={LmRegister} />
                            <Route path={`${process.env.PUBLIC_URL}/lmlogin`} component={LmLogin} />
                            <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/token`} component={Token} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/accounts`} component={Accounts} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/folders/:accountId`} component={Folders} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/messages/:accountId/:folderId/:folderName`} component={ZohoMessages} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/attachmentinfo/:accountId/:folderId/:messageId/:subject/:folderName`} component={AttachmentInfo} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/attachment/:accountId/:folderId/:messageId/:attachmentId/:attachmentName/:subject`} component={Attachment} />
                            <Route path={`${process.env.PUBLIC_URL}/zoho/refreshToken`} component={RefreshToken} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                            <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />


                            <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />

                            <Route path={`${process.env.PUBLIC_URL}/usermailmap/:type/:ticketid`} component={ManageUserMailMap} />
                            <Route path={`${process.env.PUBLIC_URL}/map/:type/:code/:leg`} component={AndriodMap} />
                            <Route path={`${process.env.PUBLIC_URL}/livemaptruck`} component={LiveTruckMap} />
                            <Route path={`${process.env.PUBLIC_URL}/goldenzonedashboard`} component={GoldeZoneDashboard} />

                            <Route path={`${process.env.PUBLIC_URL}/msilvesselstatus`} component={MSILVesselSchedule} />
                            <Route path={`${process.env.PUBLIC_URL}/managemsilvesselstatus`} component={MSILVesselSchedule} />
                            <Route path={`${process.env.PUBLIC_URL}/railstock`} component={RakeStockStatus} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtplanningstatus`} component={PlanningStatus} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtemptytruckvicinity`} component={EmptyTrucksVicinity} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtlivetrucksinfo`} component={LiveTrucksInfo} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtdashboardsummary`} component={DashboardSummary} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtsafetyreport`} component={SafetyReport} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtdivisionhealthreport`} component={DivisionHealthReport} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtweathermap`} component={WeatherMap} />
                            <Route path={`${process.env.PUBLIC_URL}/mgmtbrmdashboard`} component={BRMDashboardMgmtMobile} />
                            <Route path={`${process.env.PUBLIC_URL}/mobile/:screen/:user/:code/:sdate/:edate/:frequency`} component={AndriodGridMap} />
                            <Route path={`${process.env.PUBLIC_URL}/mobilemapview/:screen/:user/:dept/:code/:trip_type`} component={AndriodGridMap} />
                            <Route path={`${process.env.PUBLIC_URL}/mobilemap/:screen/:truckno`} component={AndriodGridMap} />
                            <Route path={`${process.env.PUBLIC_URL}/mobilemaptruckwithingeofence/:searchType/:selectType/:selectOption/:truckWithGps/:radius/:dept_code/:predefgeo`} component={MobileTruckWithinGeoFence} />                        
                            <Route path={`${process.env.PUBLIC_URL}/trucklocationmobilemap/:searchType/:param1/:dept/:trip_type`} component={TruckLocationForMobile} />
                            <Route path={`${process.env.PUBLIC_URL}/mapview/:screen/:user/:code/:sdate/:edate`} component={AndriodGridMap} />
                            <Route path={`${process.env.PUBLIC_URL}/railmap/:vehicle_id/:dealer_code/:truck_no/:sdate/:edate`} component={AndriodTPTRailMap} />
                            <Route path={`${process.env.PUBLIC_URL}/route`} component={RouteViewMap} />
                            

                            <App>
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/snddefault`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/prtdefault`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/tnpdefault`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/scdefault`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/overview`} component={Overview} />
                                <Route path={`${process.env.PUBLIC_URL}/assets`} component={Assets} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/geofences`} component={GeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sndgeofences`} component={GeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/prtgeofences`} component={GeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/tnpgeofences`} component={GeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/scgeofences`} component={GeoFence} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofences`} component={TptGeoFence} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/manage/addgeofence`} component={AddGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sndaddgeofence`} component={AddGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/prtaddgeofence`} component={AddGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/tnpaddgeofence`} component={AddGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/scaddgeofence`} component={AddGeoFence} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptgeofence`} component={AddTptGeoFence} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} component={EditGeoFence} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/editTptGeofence/:geofencename`} component={EditTptGeoFence} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/editweatherevent/:eventname/:pageType`} component={EditWeatherEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/batchwisecatalysis/:batchname`} component={BatchWiseCatalysis} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/trucks`} component={ManageTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sndtrucks`} component={ManageTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/prttrucks`} component={ManageTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/tnptrucks`} component={ManageTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sctrucks`} component={ManageTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/consignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/sndconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/insidevevicinty`} component={InsideMSIL} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/transporters`} component={ManageTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sndtransporters`} component={ManageTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/prttransporters`} component={ManageTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/tnptransporters`} component={ManageTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/sctransporters`} component={ManageTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/departments`} component={ManageDepartments} />
                                <Route path={`${process.env.PUBLIC_URL}/sndconsignees`} component={ManageSNDConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconsignees`} component={ManagePRTConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/moveconsignments`} component={ManageMOVEConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconsigners`} component={ManagePRTConsigners} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtransittimes`} component={ManageSNDTransitTimes} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransittimes`} component={ManagePRTTransitTimes} />
                                <Route path={`${process.env.PUBLIC_URL}/snddepartmentvicinity/sndg`} component={VicinityScreen} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdepartmentvicinity/log-prt`} component={VicinityScreen} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdepartmentvicinity/log-tnp`} component={VicinityScreen} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/truckwithingeofence`} component={TptVicinityScreen} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/sndconfigurations`} component={ManageConfigurations} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconfigurations`} component={ManageConfigurations} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpconfigurations`} component={ManageConfigurations} />
                                <Route path={`${process.env.PUBLIC_URL}/scconfigurations`} component={ManageConfigurations} />
                                <Route path={`${process.env.PUBLIC_URL}/overspeed`} component={ReportOverspeed} />
                                <Route path={`${process.env.PUBLIC_URL}/enroutestop`} component={ReportEnrouteStoppages} />
                                <Route path={`${process.env.PUBLIC_URL}/chaformat`} component={ManageChaformat} />
                                <Route path={`${process.env.PUBLIC_URL}/imports/consignments`} component={ManageImportsConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/imports/deliveredContainers`} component={ManageImportsDeliveredContainers} />
                                <Route path={`${process.env.PUBLIC_URL}/imports/consignmentsbeforemar`} component={ManageImportsConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/imports/freightforwards`} component={ManageImportsConsignmentsFreightForward} />
                                <Route path={`${process.env.PUBLIC_URL}/nearbytrucks`} component={ManageNearByConsignmentTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/nearbysndtrucks`} component={ManageNearByConsignmentTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/nearbyprttrucks`} component={ManageNearByConsignmentTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/nearbysctrucks`} component={ManageNearByConsignmentTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/nearbytnptrucks`} component={ManageNearByConsignmentTrucks} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/carrierlocationonmap`} component={ManageNearByTransporterTrucks} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/courierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/activecourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/deliveredcourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/billingreportcourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/invalidcourierconsignments`} component={ManageCourierConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/consignment-dashboard`} component={ConsignementDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/consignment/:keyword`} component={ConsignementComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/container-dashboard`} component={ContainerDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/sndconsignmentstoday`} component={ConsignmentsTodayDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconsignmentstoday`} component={ConsignmentsTodayDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtgeofence`} component={ConsignmentsGeofence} />
                                <Route path={`${process.env.PUBLIC_URL}/sndgeofence`} component={ConsignmentsGeofence} />
                                <Route path={`${process.env.PUBLIC_URL}/scgeofence`} component={ConsignmentsGeofence} />
                                <Route path={`${process.env.PUBLIC_URL}/prtvicinity`} component={ConsigneeVicinity} />
                                <Route path={`${process.env.PUBLIC_URL}/sndvicinity`} component={ConsigneeVicinity} />

                                <Route path={`${process.env.PUBLIC_URL}/prtkpiintransit`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/prtkpinightdriving`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/prtkpioverspeeding`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/prtkpienroute`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/prtkpiunloading`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/prtkpiloading`} component={ConsignmentsKpi} />

                                <Route path={`${process.env.PUBLIC_URL}/sndkpiintransit`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/sndkpinightdriving`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/sndkpioverspeeding`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/sndkpienroute`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/sndkpiunloading`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/sndkpiloading`} component={ConsignmentsKpi} />

                                <Route path={`${process.env.PUBLIC_URL}/tnpkpiintransit`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpkpinightdriving`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpkpioverspeeding`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpkpienroute`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpkpiunloading`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpkpiloading`} component={ConsignmentsKpi} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpnoentrytimes`} component={ConsignmentsKpi} />

                                <Route path={`${process.env.PUBLIC_URL}/superadmin/menus`} component={AdminMenus} />
                                <Route path={`${process.env.PUBLIC_URL}/superadmin/roles`} component={AdminRoles} />
                                <Route path={`${process.env.PUBLIC_URL}/superadmin/usermenus`} component={AdminUserRoles} />
                                <Route path={`${process.env.PUBLIC_URL}/assignmenus`} component={AdminRoleMenus} />
                                <Route path={`${process.env.PUBLIC_URL}/viewSubmenus/:parentmenu`} component={AdminViewSubMenus} />
                                <Route path={`${process.env.PUBLIC_URL}/superadmin/menuposition`} component={AdminMenuReOrder} />
                                <Route path={`${process.env.PUBLIC_URL}/superadmin/assignroles`} component={AssignRoles} />

                                <Route path={`${process.env.PUBLIC_URL}/sndtrucks`} component={ManageTruckConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prttrucks`} component={ManageTruckConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptrucks`} component={ManageTruckConsignments} />

                                <Route path={`${process.env.PUBLIC_URL}/exports/consignments`} component={ManageExportsConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/pm-road-plan`} component={PipavaMudraRoadPlan} />
                                <Route path={`${process.env.PUBLIC_URL}/view-pm-road-plan`} component={ViewPipavaMudraRoadPlan} />
                                <Route path={`${process.env.PUBLIC_URL}/icd-garhi-plan`} component={IcdGarhiContainerPlan} />
                                <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                                <Route path={`${process.env.PUBLIC_URL}/chadocumentreceits`} component={CHADocumentReceits} />
                                <Route path={`${process.env.PUBLIC_URL}/chanhavasheva`} component={CHANhavaSheva} />
                                <Route path={`${process.env.PUBLIC_URL}/chaupdatedestinationport`} component={CHADestinationPort} />
                                <Route path={`${process.env.PUBLIC_URL}/spareschadestinationport`} component={SparesCHADestinationPort} />
                                <Route path={`${process.env.PUBLIC_URL}/dctcontainerupdates`} component={DCTContaierUpdates} />
                                <Route path={`${process.env.PUBLIC_URL}/dctsparescontainerupdates`} component={DCTSparesContaierUpdates} />
                                <Route path={`${process.env.PUBLIC_URL}/deliveredconsignments`} component={DeliveredConsignmentsComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/snddeliveredconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdeliveredconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdeliveredconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/ticketmanagementsystem`} component={TMS} />

                                <Route path={`${process.env.PUBLIC_URL}/allconsignments`} component={ConsignmentsAllComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndallconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtallconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpallconsignments`} component={ManageConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/trucksgps`} component={TrucksGPSData} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/viewcarrierroute`} component={TptTrucksGPSData} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/sndemptyreturntrucks`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/prtemptyreturntrucks`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpemptyreturntrucks`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/sndactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/prtactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpactivetruckswithinrange`} component={EmptyReturnedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/exports/adaniconsignments`} component={ManageExportsConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/exports/transhipmentconsignments`} component={TranshipmentExports} />
                                <Route path={`${process.env.PUBLIC_URL}/railconsignments`} component={ManageRailConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/deliveredrailconsignments`} component={ManageRailConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/ncrconsignments`} component={ManageConsignments} />

                                <Route path={`${process.env.PUBLIC_URL}/sndontripgps`} component={OnTripGPSReport} />

                                <Route path={`${process.env.PUBLIC_URL}/imports/coils`} component={ManageImportsCoils} />

                                <Route path={`${process.env.PUBLIC_URL}/coilimports`} component={CoilImports} />
                                <Route path={`${process.env.PUBLIC_URL}/coilimportsdelivered`} component={CoilImportsDelivered} />

                                <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/prttickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/sctickets`} component={DepartmentTMS} />

                                <Route path={`${process.env.PUBLIC_URL}/sndclosetickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpclosetickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/prtclosetickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/scclosetickets`} component={DepartmentTMS} />
                                <Route path={`${process.env.PUBLIC_URL}/closetickets`} component={TMS} />
                                <Route path={`${process.env.PUBLIC_URL}/invalidtickets`} component={TMSInvalid} />
                                <Route path={`${process.env.PUBLIC_URL}/chacoils`} component={CHACoilsImports} />
                                <Route path={`${process.env.PUBLIC_URL}/coilsdispatch`} component={CoilsDispatch} />
                                <Route path={`${process.env.PUBLIC_URL}/exports/deliveredconsignments`} component={ExportsDeliveredConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/exports/prtdeliveredconsignments`} component={ExportsPRTDeliveredConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/imports/containers`} component={ManageImportsContainer} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate`} component={DashboardTemplate} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate1`} component={DashboardTemplate1} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboardsummary`} component={DashboardSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/criticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/sndcriticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/prtcriticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpcriticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/sccriticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/couriercriticaldelayreasons`} component={DashboardDelayResponse} />
                                <Route path={`${process.env.PUBLIC_URL}/ticketsdashboard`} component={DashboardTickets} />
                                <Route path={`${process.env.PUBLIC_URL}/snddashboardtemplate`} component={DepartmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdashboardtemplate`} component={DepartmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdashboardtemplate`} component={DepartmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/scdashboardtemplate`} component={DepartmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/sndrakes`} component={RakesConsignmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnprakes`} component={RakesConsignmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndforceclosure`} component={ForceClosureComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtforceclosure`} component={ForceClosureComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpforceclosure`} component={ForceClosureComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtranshipments`} component={TranshipmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttranshipments`} component={TranshipmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptranshipments`} component={TranshipmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/roadpickup`} component={RoadPickUpComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboardprt`} component={PRTDepartmentDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/sndweatheralerts`} component={WeatherComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtweatheralerts`} component={WeatherComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/allweatheralerts`} component={WeatherComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtransshipments`} component={TransshipmentTransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransshipments`} component={TransshipmentTransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptransshipments`} component={TransshipmentTransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/snddelivertransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdelivertransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdelivertransporterconsignments`} component={TransporterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/transportertickets`} component={TransporterTickets} />


                                <Route path={`${process.env.PUBLIC_URL}/sndtranshipmentreports`} component={TranshipmentReportComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttranshipmentreports`} component={TranshipmentReportComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptranshipmentreports`} component={TranshipmentReportComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/railtranshipmentreports`} component={TranshipmentReportComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndrailtransshipments`} component={RailTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/prtrailtransshipments`} component={RailTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/tnprailtransshipments`} component={RailTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/alloybarsComponent`} component={AlloybarComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/loadingdashboard`} component={LoadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/sndloadingdashboard`} component={LoadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtloadingdashboard`} component={LoadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnploadingdashboard`} component={LoadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/dailyrunningdashboard`} component={DailyRunningDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/dailyrunningmanagement`} component={DailyRunningDashboard} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/unloadingdashboard`} component={UnLoadingDashboard} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/sndtransitanalytics`} component={TransitDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransitanalytics`} component={TransitDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptransitanalytics`} component={TransitDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptransportdashboard`} component={DailyRunningTransporterDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndgpsdashboard`} component={GPSDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtgpsdashboard`} component={GPSDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpgpsdashboard`} component={GPSDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/manualexports`} component={ManualExports} />
                                <Route path={`${process.env.PUBLIC_URL}/segmentation`} component={SegmentSideBar} />
                                <Route path={`${process.env.PUBLIC_URL}/sndholidaycalendar`} component={HolidayComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtholidaycalendar`} component={HolidayComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpholidaycalendar`} component={HolidayComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/transportergpsdashboard`} component={GPSTransporter} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/transporterdefault`} component={TransporterIntegrationDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/ontimedashboard`} component={OnTimeDashboardComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                                <Route path={`${process.env.PUBLIC_URL}/shifts`} component={ShiftTimings} />

                                <Route path={`${process.env.PUBLIC_URL}/aircargoform`} component={AirCargoForm} />
                                <Route path={`${process.env.PUBLIC_URL}/aircargos`} component={AirCargoListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editaircargoform/:eventname`} component={EditCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/editffnewaircargoform/:eventname`} component={EditFFNewAircargo} />
                                <Route path={`${process.env.PUBLIC_URL}/ffaircargos`} component={FFAirCargoListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editffaircargoform/:eventname/:pagetype`} component={EditFFCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/createffaircargos`} component={CreateFFCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/chaaircargos`} component={ChaAirCargoListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/transportationaircargos`} component={TransporterAirCargoListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editchaaircargoform/:eventname/:pagetype`} component={EditChaCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/edittransporteraircargoform/:eventname/:pagetype`} component={EditTransporterCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/editclosureaircargoform/:eventname/:pagetype`} component={EditClosureCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/loadattachment`} component={LoadAttachment} />
                                <Route path={`${process.env.PUBLIC_URL}/Possibletransshipments`} component={PossibleTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/sndPossibletransshipments`} component={PossibleTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtPossibletransshipments`} component={PossibleTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpPossibletransshipments`} component={PossibleTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/exitloadattachment`} component={LoadAttachmentExit} />
                                <Route path={`${process.env.PUBLIC_URL}/chadocumenthistory`} component={ChaDocumentHistory} />
                                <Route path={`${process.env.PUBLIC_URL}/railvinconsignments`} component={RailVinConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/sndrailvintransshipments`} component={RailVINTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/prtrailvintransshipments`} component={RailVINTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/tnprailvintransshipments`} component={RailVINTranshipment} />
                                <Route path={`${process.env.PUBLIC_URL}/vinroadpickup`} component={VinRoadPickUpComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndStoppageReport`} component={StoppageReport} />
                                <Route path={`${process.env.PUBLIC_URL}/prtStoppageReport`} component={StoppageReport} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpStoppageReport`} component={StoppageReport} />
                                <Route path={`${process.env.PUBLIC_URL}/sndconsigneeexception`} component={ConsigneEexceptionComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtconsigneeexception`} component={ConsigneEexceptionComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/loadarrivals`} component={LoadArrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtransittimesummary`} component={TransitTimeSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/prttransittimesummary`} component={TransitTimeSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptransittimesummary`} component={TransitTimeSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/analytics`} component={GoogleAnalytics} />
                                <Route path={`${process.env.PUBLIC_URL}/returnemptypallets`} component={ReturnPallet} />
                                <Route path={`${process.env.PUBLIC_URL}/sndunloadingdashboard`} component={SNDUnloadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtunloadingdashboard`} component={SNDUnloadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpunloadingdashboard`} component={SNDUnloadingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/snddistancetravelreport`} component={DistanceTravelledReport} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdistancetravelreport`} component={DistanceTravelledReport} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdistancetravelreport`} component={DistanceTravelledReport} />
                                <Route path={`${process.env.PUBLIC_URL}/sndprobableaccidents`} component={ProbableAccidentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/prtprobableaccidents`} component={ProbableAccidentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/sndrakeschedule`} component={RakesScheduleComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/dealercompensation`} component={DealerCompensation} />
                                <Route path={`${process.env.PUBLIC_URL}/sndmanuallocations`} component={AddManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/sndaddlocation`} component={AddManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/editlocation/:dept/:id`} component={EditManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/prtmanuallocations`} component={ManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpmanuallocations`} component={ManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/prtaddlocation`} component={AddManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpaddlocation`} component={AddManualLocationUpdate} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdailyrunningdashboard`} component={DailyRunningDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtlocalconsignments`} component={LocalConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/prtturnaroundtime`} component={PRTTurnAroundTime} />
                                <Route path={`${process.env.PUBLIC_URL}/userlogreport`} component={UserLogReport} />
                                <Route path={`${process.env.PUBLIC_URL}/raildefault`} component={Default} />
                                <Route path={`${process.env.PUBLIC_URL}/railloadattachment`} component={LoadAttachment} />
                                <Route path={`${process.env.PUBLIC_URL}/railexitloadattachment`} component={LoadAttachmentExit} />
                                <Route path={`${process.env.PUBLIC_URL}/actlcontainers`} component={ACTLContainers} />
                                <Route path={`${process.env.PUBLIC_URL}/dealercompolddata`} component={DealerCompensationOldData} />
                                <Route path={`${process.env.PUBLIC_URL}/assignactlcontainers`} component={AssignACTLContainers} />
                                <Route path={`${process.env.PUBLIC_URL}/livemaptrucklocation`} component={LiveTruckLocationMap} />
                                <Route path={`${process.env.PUBLIC_URL}/breakclusters`} component={BreaksClusterComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/livetrucksinfo`} component={LiveTrucksInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/snduniquetrucktrips`} component={UniqueTrucksTrips} />
                                <Route path={`${process.env.PUBLIC_URL}/prtuniquetrucktrips`} component={UniqueTrucksTrips} />
                                <Route path={`${process.env.PUBLIC_URL}/msildashboard`} component={MSILDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/primarydashboard`} component={PrimaryDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/secondarydashboard`} component={SecondaryDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/stockyarddashboard`} component={StockyardDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/catalyticmissingbreakclusters`} component={CatalyticMissingBreakClusters} />
                                <Route path={`${process.env.PUBLIC_URL}/actlplanning`} component={ACTLContainerPlan} />
                                <Route path={`${process.env.PUBLIC_URL}/sndregionwisereport`} component={SndRegionWiseReport} />
                                <Route path={`${process.env.PUBLIC_URL}/sndbilling`} component={Billing} />
                                <Route path={`${process.env.PUBLIC_URL}/prtbilling`} component={Billing} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpbilling`} component={Billing} />
                                <Route path={`${process.env.PUBLIC_URL}/scimportsbilling`} component={BillingSC} />
                                <Route path={`${process.env.PUBLIC_URL}/scexportsbilling`} component={BillingSC} />
                                <Route path={`${process.env.PUBLIC_URL}/sccoilsbilling`} component={BillingSC} />
                                <Route path={`${process.env.PUBLIC_URL}/sndprimarybilling`} component={Billing} />
                                <Route path={`${process.env.PUBLIC_URL}/sndsecondarybilling`} component={Billing} />
                                <Route path={`${process.env.PUBLIC_URL}/batchwiseclusters`} component={BatchWiseClusters} />
                                <Route path={`${process.env.PUBLIC_URL}/updaterailanalytics`} component={UpdateRailAnaytics} />
                                <Route path={`${process.env.PUBLIC_URL}/railanalytics`} component={RailAnaytics} />
                                <Route path={`${process.env.PUBLIC_URL}/addlclimports`} component={AddLclImports} />
                                <Route path={`${process.env.PUBLIC_URL}/lclimports`} component={LclImports} />
                                <Route path={`${process.env.PUBLIC_URL}/importsshipments`} component={ImportsShipments} />
                                <Route path={`${process.env.PUBLIC_URL}/routedeviation`} component={RouteDeviationMap} />
                                <Route path={`${process.env.PUBLIC_URL}/newroutedeviation`} component={RouteDeviationMap} />
                                <Route path={`${process.env.PUBLIC_URL}/deviationroutes`} component={DeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} component={OptimizeDeviationRoutes} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/weatherheatmap`} component={WeatherHeatMap} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/statebordersgeofencemap`} component={StateBorderGeofenceMap} />
                                <Route path={`${process.env.PUBLIC_URL}/returntrips`} component={ReturnTrips} />
                                <Route path={`${process.env.PUBLIC_URL}/deviationroutesnew`} component={NewDeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/sndtruckavailability`} component={TruckAvailability} />
                                <Route path={`${process.env.PUBLIC_URL}/prttruckavailability`} component={TruckAvailability} />
                                <Route path={`${process.env.PUBLIC_URL}/tnptruckavailability`} component={TruckAvailability} />
                                <Route path={`${process.env.PUBLIC_URL}/returnTripsDashboard`} component={ReturnTripsDashboardChart} />
                                <Route path={`${process.env.PUBLIC_URL}/prtreturnTripsDashboard`} component={ReturnTripsDashboardChart} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpreturnTripsDashboard`} component={ReturnTripsDashboardChart} />
                                <Route path={`${process.env.PUBLIC_URL}/billingdashboard`} component={BillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/sndbillingdashboard`} component={BillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtbillingdashboard`} component={BillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpbillingdashboard`} component={BillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/scbillingdashboard`} component={BillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/viewbillaction`} component={ViewBillAction} />
                                <Route path={`${process.env.PUBLIC_URL}/lmstates`} component={LmStates} />
                                <Route path={`${process.env.PUBLIC_URL}/lmvehiclecapacity`} component={LmVehicleCapacity} />
                                <Route path={`${process.env.PUBLIC_URL}/lmfreights`} component={LmFreights} />
                                <Route path={`${process.env.PUBLIC_URL}/lmrouteconfig`} component={LmRouteConfig} />
                                <Route path={`${process.env.PUBLIC_URL}/lmconsignees`} component={LmConsignees} />
                                <Route path={`${process.env.PUBLIC_URL}/lmbaseconfig`} component={LmBaseConfig} />
                                <Route path={`${process.env.PUBLIC_URL}/lmloadmanager`} component={LmLoadManager} />
                                <Route path={`${process.env.PUBLIC_URL}/detentionreport`} component={DetentionReport} />
                                <Route path={`${process.env.PUBLIC_URL}/usdrates`} component={USDRates} />
                                <Route path={`${process.env.PUBLIC_URL}/icdrates`} component={ICDRates} />
                                <Route path={`${process.env.PUBLIC_URL}/automotivegeofences`} component={AutomotiveGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/addautomotivegeofence`} component={AddAutomotiveGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/editautomotive/:rowid`} component={EditAutomotiveGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/lmtransporters`} component={LmTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/lmaddtransporters`} component={LmAddTransporters} />

                                <Route path={`${process.env.PUBLIC_URL}/altorates`} component={AltoRates} />
                                <Route path={`${process.env.PUBLIC_URL}/lmshareofbusiness`} component={LmShareOfBusiness} />
                                <Route path={`${process.env.PUBLIC_URL}/lmcurrentsob`} component={LmCurrentShareOfBusiness} />
                                <Route path={`${process.env.PUBLIC_URL}/lmloadshare/:eventname`} component={LmLoadShare} />
                                <Route path={`${process.env.PUBLIC_URL}/lmsobzones`} component={LmSobZones} />
                                <Route path={`${process.env.PUBLIC_URL}/railinventory`} component={RailInventory} />
                                <Route path={`${process.env.PUBLIC_URL}/oembreaks`} component={OEMBreaks} />
                                <Route path={`${process.env.PUBLIC_URL}/prtoptimizeroutes`} component={PRTOptimizeDeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdeviationroutes`} component={PRTDeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/markdeliveredrailconsignments`} component={MarkDeliveredRailConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/lmloadsummary`} component={LmLoadSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/viewLoadSummaryDetails/:eventname`} component={ViewLoadSummaryDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/sndreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                                <Route path={`${process.env.PUBLIC_URL}/prtreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpreturnrtripclusters`} component={ReturnTripsBreakClusters} />
                                <Route path={`${process.env.PUBLIC_URL}/sndrouteplanning`} component={RoutePlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/prtrouteplanning`} component={RoutePlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/tnprouteplanning`} component={RoutePlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpoptimizeroutes`} component={TNPOptimizeDeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdeviationroutes`} component={TNPDeviationRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/railplanning`} component={RailPlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/lmtruckavailability/:eventname`} component={LmTruckAvailability} />
                                <Route path={`${process.env.PUBLIC_URL}/lmloadingports`} component={LmLoadingPorts} />
                                <Route path={`${process.env.PUBLIC_URL}/sndTransitAnalyticsDashbord`} component={TransitAnalytics} />
                                <Route path={`${process.env.PUBLIC_URL}/exportstracking`} component={ExportsContainerTracking} />
                                <Route path={`${process.env.PUBLIC_URL}/sparesexportstracking`} component={SparesExportsContainerTracking} />
                                <Route path={`${process.env.PUBLIC_URL}/sparesexportsdelivered`} component={SparesExportsContainerDelivered} />
                                <Route path={`${process.env.PUBLIC_URL}/sparesdestinationports`} component={SparesExportsDestinationPorts} />
                                <Route path={`${process.env.PUBLIC_URL}/prtunutilizedtrucks`} component={PRTUnutilizedTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/analyticsdashboard`} component={AnalyticsDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/railloadoptmization`} component={RailLoadOptimizationComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/getOptimizedLoadReport`} component={GetLoadOptimizationReport} />

                                <Route path={`${process.env.PUBLIC_URL}/msilvesselschedule`} component={MSILVesselSchedule} />
                                <Route path={`${process.env.PUBLIC_URL}/managemsilvesselschedule`} component={MSILVesselSchedule} />
                                <Route path={`${process.env.PUBLIC_URL}/sndemptytruckvicinity`} component={EmptyTrucksVicinity} />
                                <Route path={`${process.env.PUBLIC_URL}/sndemptytruckvicinitymgmt`} component={EmptyTrucksVicinity} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/emptytruckvicinity`} component={TptEmptyTrucksVicinity} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/rakearrivals`} component={RakeArrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/lsprakearrivals`} component={RakeArrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/prtemptytruckvicinity`} component={EmptyTrucksVicinity} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpemptytruckvicinity`} component={EmptyTrucksVicinity} />
                                <Route path={`${process.env.PUBLIC_URL}/managementdashboard`} component={ManagementDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/rakestockstatus`} component={RakeStockStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/railstockstatus`} component={RakeStockStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/sndmanagementdashboard`} component={ManagementDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtmanagementdashboard`} component={ManagementDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpmanagementdashboard`} component={ManagementDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/scmanagementdashboard`} component={ManagementDashboard} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/safetyreport`} component={UnderConstruction} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/sndplanningstatus`} component={PlanningStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/sndplanningstatusmgmt`} component={PlanningStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/logisticshealthappstatus`} component={UnderConstruction} />

                                <Route path={`${process.env.PUBLIC_URL}/sndsafetyreport`} component={SafetyReport} />
                                <Route path={`${process.env.PUBLIC_URL}/scsafetyreport`} component={SafetyReport} />
                                <Route path={`${process.env.PUBLIC_URL}/safetyreportmgmt`} component={SafetyReport} />
                                <Route path={`${process.env.PUBLIC_URL}/divisionhealthreport`} component={DivisionHealthReport} />
                                <Route path={`${process.env.PUBLIC_URL}/snddivisionhealthreport`} component={DivisionHealthReport} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdivisionhealthreport`} component={DivisionHealthReport} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpdivisionhealthreport`} component={DivisionHealthReport} />
                                <Route path={`${process.env.PUBLIC_URL}/scdivisionhealthreport`} component={DivisionHealthReport} />
                                <Route path={`${process.env.PUBLIC_URL}/loadfactors`} component={LoadFactors} />
                                <Route path={`${process.env.PUBLIC_URL}/mllsummary`} component={MLLRailSummaryComponent} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/mllconsignments`} component={MLLConsignments} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/mllconsignments/:refno`} component={MLLConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/createmllconsignmentbatch/:refno`} component={MLLCreateBatch} />
                                <Route path={`${process.env.PUBLIC_URL}/mllmarkarrivalloadingyard/:batchno`} component={MllMarkArrivalLoadingYard} />
                                <Route path={`${process.env.PUBLIC_URL}/lmvoutput`} component={LmVOutput} />
                                <Route path={`${process.env.PUBLIC_URL}/lmvoutputdetails/:eventname`} component={LmVOutputDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/mllwagonchecksheet`} component={MllWagonCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/addmllwagonchecksheet`} component={AddMllWagonCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/mlltrainloadchecksheet`} component={MllTrainLoadCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/addmlltrainloadchecksheet`} component={AddMllTrainLoadCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/mlltrainunloadchecksheet`} component={MllTrainUnLoadCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/lmdepartments`} component={LmDepartments} />

                                <Route path={`${process.env.PUBLIC_URL}/mllgeofences`} component={GeofenceMap} />
                                <Route path={`${process.env.PUBLIC_URL}/lmusers`} component={LmUsers} />
                                <Route path={`${process.env.PUBLIC_URL}/lmroles`} component={LmRoles} />
                                <Route path={`${process.env.PUBLIC_URL}/addmlltrainunloadchecksheet`} component={AddMllTrainUnLoadCheck} />
                                <Route path={`${process.env.PUBLIC_URL}/lmuseractivities`} component={LmUserActivities} />
                                <Route path={`${process.env.PUBLIC_URL}/mllallconsignments`} component={MLLConsignments} />
                                <Route path={`${process.env.PUBLIC_URL}/lmwelcome`} component={LmWelcome} />
                                <Route path={`${process.env.PUBLIC_URL}/lmpageaccess`} component={LmPageAccess} />
                                <Route path={`${process.env.PUBLIC_URL}/lmloadingportaccess`} component={LmLoadingPortAccess} />
                                <Route path={`${process.env.PUBLIC_URL}/mlltransporters`} component={MLLTransporters} />
                                <Route path={`${process.env.PUBLIC_URL}/mlltrucks`} component={MLLTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/mllplants`} component={MLLPlants} />
                                <Route path={`${process.env.PUBLIC_URL}/mlldealers`} component={MLLDealers} />
                                <Route path={`${process.env.PUBLIC_URL}/mllsnddepartmentvicinity/sndg`} component={MllTrucksWithInGeofence} />
                                <Route path={`${process.env.PUBLIC_URL}/mllsndvicinity`} component={ConsigneeVicinity} />
                                <Route path={`${process.env.PUBLIC_URL}/mllnearbysndtrucks`} component={MllConsignmentNearByTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/mllsndgeofence`} component={ConsignmentsGeofence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/mllsndgeofences`} component={GeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/manage/mllsndaddgeofence`} component={AddGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/mlleditGeofence/:geofencename`} component={EditGeoFence} />
                                <Route path={`${process.env.PUBLIC_URL}/lmuserlogreport`} component={LmUserLogReport} />
                                <Route path={`${process.env.PUBLIC_URL}/mlllastmilebatch/:refno`} component={MLLLastMileBatch} />
                                <Route path={`${process.env.PUBLIC_URL}/mlltruckgps`} component={MLLTrucksGPS} />
                                <Route path={`${process.env.PUBLIC_URL}/mllloadingyardarrival/:refno`} component={MLLLoadingYardArrival} />
                                <Route path={`${process.env.PUBLIC_URL}/deviceslist`} component={RegisteredDevices} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/deviceslist`} component={TptRegisteredDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/pendinginstallations`} component={TptPendingInstallations} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/brmdashboard`} component={BRMDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/brmdashboardmgmt`} component={BRMDashboardMgmt} />
                                <Route path={`${process.env.PUBLIC_URL}/railbrmdashboard`} component={BRMDashboard} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpsintegrationstatus`} component={tptGpsIntegration} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/mllloadingofficers`} component={MLLLoadingOfficers} />
                                <Route path={`${process.env.PUBLIC_URL}/mllfirstmiletransshipments`} component={MLLFirstMileTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/mlllastmiletransshipments`} component={MLLLastMileTransshipments} />
                                <Route path={`${process.env.PUBLIC_URL}/mllfirstmileretrivals`} component={MLLFirstMileRetrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/mllhandoverretrivals`} component={MLLHandOverRetrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/mllrsopod`} component={MLLRSOPOD} />
                                <Route path={`${process.env.PUBLIC_URL}/prtontimedashboard`} component={PRTOnTimeComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/mllrakeretrivals`} component={MLLRakeRetrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/mllrsoretrivals`} component={MLLRSORetrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/mlldevicedashboard`} component={MLLDeviceDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/mllissuedevicetoba`} component={MLLIssueToBA} />
                                <Route path={`${process.env.PUBLIC_URL}/gpsdeviceplanning`} component={MLLGPSDevicePlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/viewdeviceplanning/:refno`} component={MLLGPSDevicePlanning} />
                                <Route path={`${process.env.PUBLIC_URL}/mllnotifications`} component={MLLNotifications} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/overspeed`} component={TptReportOverspeed} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpseodanalysis`} component={TPTGpsEodAnalysis} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/monthlydistancetraveledreport`} component={TPTGpsEodAnalysis} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/frieghtfirstmilerates`} component={MLLFrieghtFirstMileRates} />
                                <Route path={`${process.env.PUBLIC_URL}/loadingunioncharges`} component={MLLFrieghtFirstMileRates} />
                                <Route path={`${process.env.PUBLIC_URL}/rrfreightcharges`} component={MLLFrieghtFirstMileRates} />
                                <Route path={`${process.env.PUBLIC_URL}/freightlastmilerates`} component={MLLFrieghtFirstMileRates} />
                                <Route path={`${process.env.PUBLIC_URL}/frieghtrsotodealerrates`} component={MLLFrieghtFirstMileRates} />
                                <Route path={`${process.env.PUBLIC_URL}/mllgpsdevices`} component={MLLGPSDevices} />
                                <Route path={`${process.env.PUBLIC_URL}/mllshiptorso`} component={MLLRakeShipToRSORetrivals} />
                                <Route path={`${process.env.PUBLIC_URL}/mllshippedtosource`} component={MLLShipToPlantRSO} />
                                <Route path={`${process.env.PUBLIC_URL}/createuser`} component={CreateUser} />
                                <Route path={`${process.env.PUBLIC_URL}/mllassignroles`} component={MllAssignRoles} />
                                <Route path={`${process.env.PUBLIC_URL}/mllusermenus`} component={MllAdminUserRoles} />
                                <Route path={`${process.env.PUBLIC_URL}/mlllastmilerso/:refno`} component={MLLLastMileRSOBatch} />
                                <Route path={`${process.env.PUBLIC_URL}/mllkpidashboard`} component={MllKPIDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/mlldamageanalysis`} component={MllDamageAnalysis} />
                                <Route path={`${process.env.PUBLIC_URL}/gpsbilling/:month/:year`} component={GPSBilling} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbilling/:month/:year`} component={TPTGPSBilling} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingnonmsil/:month/:year`} component={TPTGPSBillingNonMSIL} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/subusers`} component={TPTSubUsers} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/secondaypaymentsummary`} component={SecondaryPaymentSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/damagedashboard`} component={DamageDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/raildamagedashboard`} component={DamageDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/gpsbillingdashboard`} component={GPSBillingDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/viewgpsbillaction`} component={ViewGPSBillAction} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsbillingdashboard`} component={TPTGPSBillingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsbillaction`} component={TPTViewGPSBillAction} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptgpsnonmsilbillingdashboard`} component={TPTGPSNonMSILBillingDashboard} />
                    <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptviewgpsnonmsilbillaction`} component={TPTViewGPSNonMSILBillAction} />*/}
                                <Route path={`${process.env.PUBLIC_URL}/trucksdistancematrix`} component={TPTTrucksDistanceMatrix} />
                                <Route path={`${process.env.PUBLIC_URL}/loadplandashboard`} component={LoadPlanToDashboard} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/vehicleregister`} component={VehicleRegister} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/trucks`} component={TPTTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/carriers`} component={TPTCarrier} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/groups`} component={TPTGroup} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/transporterperformancedashboard`} component={TransporterPerformanceDashboard} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/geofenceincursion`} component={TPTGeofenceIncursion} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/managedrivers`} component={TPTDrivers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/nightdriving`} component={TPTNightDriving} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/transporterinformtion`} component={TransporterInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/railoperationsmonitoring`} component={RailOperationsMonitoring} />
                                <Route path={`${process.env.PUBLIC_URL}/exitstatus`} component={ExitStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/retrivalexitreport`} component={RetrivalExitReport} />
                                <Route path={`${process.env.PUBLIC_URL}/citywisedieselprices`} component={CityWiseDieselPrices} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptrailsummary`} component={TPTRailSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptconsignments/:refno/:railno`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/createtptconsignmentbatch/:refno`} component={TPTCreateBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptmarkarrivalloadingyard/:batchno`} component={TPTMarkArrivalLoadingYard} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptloadingyardarrival/:refno`} component={TPTLoadingYardArrival} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtptwagonchecksheet`} component={TPTAddWagonCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainloadchecksheet`} component={TPTAddTrainLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/addtpttrainunloadchecksheet`} component={TPTAddTrainUnLoadCheck} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptallconsignments`} component={TPTConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/tptlastmilebatch/:refno`} component={TPTLastMileBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/devices`} component={TPTDevices} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/loadingofficers`} component={TPTLoadingOfficers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/transporters`} component={TPTTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemmaster`} component={TPTOEMMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemlocations`} component={TPTOEMLocations} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemdealers`} component={TPTOEMDealers} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemtransittimes`} component={TPTOEMTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/oemconsignments`} component={TPTOEMConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/harshbreak`} component={TPTHarshBreaking} />
                        <Route path={`${process.env.PUBLIC_URL}/tptlogin/gpssingletracking`} component={TPTGPSSingleScreen} />     */}
                                <Route path={`${process.env.PUBLIC_URL}/damageanalysis`} component={DamageAnalysis} />
                                <Route path={`${process.env.PUBLIC_URL}/importscoiltracking`} component={ImportsCoilTracking} />
                                <Route path={`${process.env.PUBLIC_URL}/railloadplananalysis`} component={RailLoadPlanAnalysis} />
                                {/*
                        <Route path={`${process.env.PUBLIC_URL}/createanalticslayout`} component={CreateAnalyticFrames} />
                        <Route path={`${process.env.PUBLIC_URL}/editanalticslayout/:dashboard`} component={EditAnalyticFrames} />
                        */}
                                <Route path={`${process.env.PUBLIC_URL}/bivolumefrieghts`} component={ZohoAnalyticDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/freightmaster`} component={FreightMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/overviewanalytics`} component={OverviewAnalytics} />
                                <Route path={`${process.env.PUBLIC_URL}/railautoplaning`} component={Railautoplaning} />
                                <Route path={`${process.env.PUBLIC_URL}/coilvendors`} component={Coilvendors} />
                                <Route path={`${process.env.PUBLIC_URL}/coilmsps`} component={Coilmsps} />
                                <Route path={`${process.env.PUBLIC_URL}/shareofbusiness`} component={ShareOfBusiness} />
                                <Route path={`${process.env.PUBLIC_URL}/sobcompletedata`} component={SobCompleteData} />
                                <Route path={`${process.env.PUBLIC_URL}/accidentalProneZones`} component={AccidentalProneZones} />
                                <Route path={`${process.env.PUBLIC_URL}/addAccidentalProneZone`} component={AddAccidentalProneZone} />
                                <Route path={`${process.env.PUBLIC_URL}/editAccidentalProneZones/:rowId`} component={EditAccidentProneZone} />



                                {/* <Route path={`${process.env.PUBLIC_URL}/accidentalpronezones`} component={accidentalProneZones} /> */}
                                {/* <Route path={`${process.env.PUBLIC_URL}/addaccidentalpronezone`} component={AddAccidentalProneZone} /> */}
                                {/* <Route path={`${process.env.PUBLIC_URL}/editaccidentalpronezones/:rowId`} component={EditAccidentProneZone} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/tnpnoparkingzones`} component={NoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/addtnpnoparkingzones`} component={AddNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/edittnpnoparkingzones/:rowId`} component={EditNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/prtnoparkingzones`} component={NoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/addprtnoparkingzones`} component={AddNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/editprtnoparkingzones/:rowId`} component={EditNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/sndnoparkingzones`} component={NoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/addsndnoparkingzones`} component={AddNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/editsndnoparkingzones/:rowId`} component={EditNoParkingZones} />
                                <Route path={`${process.env.PUBLIC_URL}/birailstockstatus`} component={BIDashboardRailStock} />
                                <Route path={`${process.env.PUBLIC_URL}/aircargoexportsform`} component={AirCargoExportForm} />
                                <Route path={`${process.env.PUBLIC_URL}/aircargoexports`} component={AirCargoExportListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editaircargoexportsform/:eventname`} component={EditCargoExport} />
                                <Route path={`${process.env.PUBLIC_URL}/transportationaircargoexports`} component={TransporterAirCargoExportsListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/edittransporteraircargoexportsform/:eventname/:pagetype`} component={EditTransporterExportsCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/chaaircargoexports`} component={ChaAirCargoExportsListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editchaaircargoexportsform/:eventname/:pagetype`} component={EditChaExportsCargoEvent} />
                                <Route path={`${process.env.PUBLIC_URL}/error-405`} component={Error405} />
                                <Route path={`${process.env.PUBLIC_URL}/safetyincidents`} component={SafetyIncidents} />
                                <Route path={`${process.env.PUBLIC_URL}/shareofbusinessinterplant`} component={ShareOfBusinessInterPant} />
                                <Route path={`${process.env.PUBLIC_URL}/bisndstock`} component={SNDStockDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/tnpuseritems`} component={TnpUserItemsTabs} />
                                <Route path={`${process.env.PUBLIC_URL}/ffaircargoexports`} component={FFAirCargoExportsListComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/editffaircargoexportsform/:eventname/:pagetype`} component={EditFFCargoExports} />
                                <Route path={`${process.env.PUBLIC_URL}/contractmonitoring`} component={ContractMonitoringSystem} />
                                <Route path={`${process.env.PUBLIC_URL}/addcontract`} component={AddContract} />
                                <Route path={`${process.env.PUBLIC_URL}/editcontract/:rowId`} component={EditContract} />
                                <Route path={`${process.env.PUBLIC_URL}/prtvolumefreightupload`} component={PrtVolumeFreightFileUpload} />
                                <Route path={`${process.env.PUBLIC_URL}/transitreturnsbidashboard`} component={TransitReturnsBiDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/addnewtransitreturns`} component={AddNewTransitReturns} />
                                <Route path={`${process.env.PUBLIC_URL}/edittransitreturns/:rowId`} component={EditNewTransitReturns} />
                                <Route path={`${process.env.PUBLIC_URL}/cmsautomailconfig`} component={CmsAutoMailConfig} />
                                <Route path={`${process.env.PUBLIC_URL}/seaimportsform`} component={SeaImportsForm} />
                                <Route path={`${process.env.PUBLIC_URL}/bitransitreturns`} component={BiTransitReturnDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/prtcreateconsignment`} component={CreateConsignment} />
                                <Route path={`${process.env.PUBLIC_URL}/parkings`} component={Parkings} />
                                <Route path={`${process.env.PUBLIC_URL}/sndparkings`} component={Parkings} />
                                <Route path={`${process.env.PUBLIC_URL}/prtparkings`} component={Parkings} />
                                <Route path={`${process.env.PUBLIC_URL}/sndparkingreport`} component={ParkingOutReport} />
                                <Route path={`${process.env.PUBLIC_URL}/overspeedreport`} component={OverSpeedReport} />
                                <Route path={`${process.env.PUBLIC_URL}/nightdrivingreport`} component={NightDrivingReport} />
                                <Route path={`${process.env.PUBLIC_URL}/enroutestoppagereport`} component={EnrouteStoppageReport} />
                                <Route path={`${process.env.PUBLIC_URL}/dealerzonewiseroutes`} component={DealerZoneWiseRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/distancetravelled`} component={MonthlyDistanceTravelled} />
                                <Route path={`${process.env.PUBLIC_URL}/sndconsolidate`} component={ConsolidateReport} />
                                <Route path={`${process.env.PUBLIC_URL}/fleetsummary`} component={FleetSummaryStatus} />
                                <Route path={`${process.env.PUBLIC_URL}/deviationForDealer`} component={DeviationForDeler} />
                                <Route path={`${process.env.PUBLIC_URL}/gpsnrd`} component={GPSNRD} />
                                <Route path={`${process.env.PUBLIC_URL}/deviationanalysisdata`} component={DeviationRoutesData} />
                                <Route path={`${process.env.PUBLIC_URL}/planttransshipmentMapping`} component={PlantTranshipmentMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/lspTvpTransshipmentMapping`} component={LspTvpTranshipmentMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/warehouseTransshipment`} component={SndWareHouseTranshipmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/tvpTransshipment`} component={TvpWareHouseTranshipmentComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/lspWhMapping`} component={LspWhMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/tollsDetails`} component={TollsInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/topbaseroutessummary`} component={TopBaseRouteSummary} />
                                <Route path={`${process.env.PUBLIC_URL}/vesaproutes`} component={SavedBaseRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/ignoredtrucks`} component={IgnoredTrucks} />
                                <Route path={`${process.env.PUBLIC_URL}/ignoredroutes`} component={IgnoredRoutes} />
                                <Route path={`${process.env.PUBLIC_URL}/monthlytruckbillinginfo`} component={TruckMonthlyBillingInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/userslist`} component={UsersList} />
                                <Route path={`${process.env.PUBLIC_URL}/prtdeviationanalysis`} component={PrtDeviaitonAnalysis} />
                                <Route path={`${process.env.PUBLIC_URL}/atdealershipdashboard`} component={AtDealerShipDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/vehiclearrivaldashboard`} component={VehicleArrivalDashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/gpsfleetstatus`} component={GpsFleetSummaryStatusFromAPI} />
                                <Route path={`${process.env.PUBLIC_URL}/dailyrunningdelivereddashboard`} component={DailyRunningDashboardCharts} />
                                <Route path={`${process.env.PUBLIC_URL}/tpttruckgpsstatus`} component={TPTTrucksGPSStatus} />
                            </App>

                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
