'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class LoadFactors extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            width : 70
        },
        eventAction: null,
        show: false,
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        rowData: [],
        statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        overly:"show-n",
        loadshow:"show-n",
    }
  }


componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    loadDateTimeScript();
    
    this.logPageView(); // Log page view to GA
    let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    this.setState({eventAction: eventAction});

    redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    .then( async (resp) => {
        //console.log("resp ", resp)
        await this.setState({
            usergridstate:resp.data
        });
        await this.restoreGridStates();
    });
    redirectURL.post("/dashboard/getLoadFactors").then((response)=>{
        this.setState({
            rowData: response.data,
            overly: "show-n",
            loadshow: "show-n"
        })
    })

}

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    
    });
    
}



 
onClickSaveGridState(){
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Save Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    window.colState = this.gridColumnApi.getColumnState();
    window.groupState = this.gridColumnApi.getColumnGroupState();
    window.sortState = this.gridApi.getSortModel();
    window.filterState = this.gridApi.getFilterModel();
    
    let screenpage = '';

    if(this.props.match.path == "/sndPossibletransshipments")
    {
        screenpage='snd possible transhipment';
    }
    else if(this.props.match.path == "/prtPossibletransshipments")
    {
        screenpage='prt possible transhipment';
    }
    else if(this.props.match.path == "/tnpPossibletransshipments")
    {
        screenpage='tnp possible transhipment';
    }

    let reqparams = {
        gridcolumns:window.colState,
        gridgroup:window.groupState,
        gridcolsort:window.sortState,
        gridcolfilter:window.filterState,
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:screenpage
    }
    //console.log("reqparams ", reqparams)
    redirectURL.post("/consignments/saveGridStates", reqparams)
    .then((response) => {
        //console.log("State response ",response.data)
        this.setState({
            show:true,
            basicTitle:"Successfully saved grid layout",
            basicType:"success",
            screenurl:window.location.pathname,
            screentitle:screenpage
        })
    })
    .catch(function(e){
        console.log("Error ", e)
    })
}

restoreGridStates()
{

    if(this.state.usergridstate.length > 0)
    {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        
    }
}

resetState = () => {
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Reset Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    //console.log('column state reset');
    var reqparams = {
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:this.state.screenpage
    }

    redirectURL.post("/consignments/removeUserGridState", reqparams)
    .then((response) => {
        this.setState({
            show:true,
            basicTitle:"Successfully reset default grid layout",
            basicType:"success"
        })
    })

};

onCellClicked(cell){
    if(cell.colDef.field == "update")
    {
        var rowvalues = cell.data;
        console.log(rowvalues);
        var flag = 0;
        
        if(rowvalues.sbvh == "" || rowvalues.car_name == "")
        {
            flag = 1;
        }
        
        rowvalues['length'] = parseInt(rowvalues['length'])
    
        rowvalues['width'] = parseInt(rowvalues['width'])
    
        rowvalues['height'] = parseInt(rowvalues['height'])
    
        rowvalues['load_factor'] = parseFloat(rowvalues['load_factor'])
        for(var k in rowvalues)
        {
            if(rowvalues[k] == NaN){flag = 1}
        }
        console.log(rowvalues)
        if(flag == 0){
            redirectURL.post("/dashboard/updateSbvh",{"formdata": rowvalues}).then((response)=>{
                this.setState({
                    basicTitle: "Updated Successfully",
                    basicType: "success",
                    show: true
                })
            }).catch((e)=>{console.log(e)})
        }else
        {
            this.setState({
                basicTitle: "Fields Should not be Empty and Only Numbers are Allowed in Dimesion Fields.",
                basicType: "warning",
                show: true
            })
        }
        
    }
}

addNewRow(){
    const rows = {
        "sbvh":"",
        "car_name":"",
        "length":"",
        "width":"",
        "height":"",
        "load_factor":"",
        "update": ""
    }
    if(this.state.rowData.length > 0)
    {
        var lastindex = this.state.rowData.length
    }
    else{
        var lastindex = 0
    }
    
    this.gridApi.insertItemsAtIndex(0,[rows])
//    this.gridApi.updateRowData({add: rows})
}



render() {
    const listyle = {
        borderRight: "1px solid black !important",
        border: "none !important",
        paddingLeft: "1em",
        paddingRight: "1em"
    }
    const pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    }
    var columnwithDefs = [
        {
            headerName: "SBVH",
            field: "sbvh",
            width:100,
            filter: true,
            resizable: true,
            editable: function(params){
                if(params.data.sbvh == ""){return true}else{return false}
            },            
        },
        {
            headerName: "Car Name",
            field: "car_name",
            width:100,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Length",
            field: "length",
            width:100,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Width",
            field: "width",
            width:100,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Height",
            field: "height",
            width:100,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Load Factor",
            field: "load_factor",
            width:100,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Update",
            field: "update",
            width:100,
            filter: true,
            resizable: true,
            cellRenderer: function(params){
                return "<button class='btn btn-success' style='padding:1px 20px 1px 20px'>Update</button>"
            }           
        },
    ]
    return (
    <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div className={"row"}>
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5><i className="icofont icofont-vehicle-delivery-van cus-i"></i>Load Factors</h5>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                    <div className="col-xl-2 col-lg-2" style={{paddingLeft:"0px"}}>
                        <button onClick={this.addNewRow.bind(this)} className="btn btn-success">Add New Row</button>
                    </div>
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        onCellClicked={this.onCellClicked.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                    </div>
                </div>
            </div>

        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
    </div>
    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// $('.datetimepicker_mask').datetimepicker({
	// 	mask:'39-19-9999 29:59',
	// 	format:'m-Y'
	// });
	// $('.datetimepicker_date').datetimepicker({
	// 	mask:'39-19-9999',
    //     format:'m-Y',
    //     validateOnBlur: false,
    //     timepicker:false,
    //     monthpicker:true,
	// });
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	// //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	// script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)

    $("#dispatch_dashboard_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("dispatch_dashboard_date");
        }
    });
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};