import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import SaveNotification from "./savenotification";
import CSVFileValidator from 'csv-file-validator'
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLNotifications extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:"show-n",
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                savenotification:SaveNotification
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Summary Dashboard",
            sliderRouteTranslate:'',
            overspeeding:"",
            convoy_trailer_distance_loading_yard:"",
            eta_delay_duration:"",
            eway_bill_expiry:"",
            configrow:""
            
        };
        
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
        this.saveItem = this.saveItem.bind(this);
	}
	
    componentDidMount(){
        loadDateTimeScript() 
        this.onLoadRakePlanningData()    
	}
    
    onLoadRakePlanningData()
    {
		redirectURL.post("/mllconsignments/mllnotifications")
		.then((response) => {
			var records = response.data.records;
            this.setState({
				rowData:records,
                loadshow:"show-n",
                overly:"show-n"
			});
        })
        redirectURL.post("/mllconsignments/mllconfigparams")
		.then((response) => {
            var records = response.data.records;
            if(records.length > 0)
            {
                var overspeeding = records[0].overspeeding;
                var convoy_trailer_distance_loading_yard = records[0].convoy_trailer_distance_loading_yard;
                var eta_delay_duration = records[0].eta_delay_duration;
                var eway_bill_expiry = records[0].eway_bill_expiry;
                var night_driving_start_time = records[0].night_driving_start_time;
                var night_driving_end_time = records[0].night_driving_end_time;
                $("#night_driving_start_time").val(night_driving_start_time);
                $("#night_driving_end_time").val(night_driving_end_time);
                this.setState({
                    configrow:records[0]._id,
                    overspeeding:overspeeding,
                    convoy_trailer_distance_loading_yard:convoy_trailer_distance_loading_yard,  
                    eta_delay_duration:eta_delay_duration,
                    eway_bill_expiry:eway_bill_expiry
                })
            }
            
        })
        
    }
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    onChangeSourceItem(refno){
		this.setState(
			{ refno },
			() => console.log(`Option selected:`, this.state.refno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    onOpenModal = () => {
		this.setState({ open: true });
	};
	
	onCloseModal = () => {
        this.gridApi.deselectAll();
	    this.setState({ open: false });
    };
	
    saveItem(rownode)
    {
        // console.log("rownode ", rownode)
        if(rownode.event_title != "" && rownode.send_notification != "" &&
        rownode.to_mails != "" && rownode.cc_mails != "" && rownode.notify_ba != "")
        {
            if(rownode._id == undefined)
            {
                var rowid = ""
            }
            else
            {
                var rowid = rownode._id
            }
            var tmls = rownode.to_mails;
            var stma = tmls.split(",")
            var cmls = rownode.cc_mails;
            var ctma = cmls.split(",")
            // console.log("stma ", stma)
            var tflag = 0;
            var flag = 0;
            if(stma.length > 0)
            {
                stma.map((item) => {
                    // console.log("item ", item)
                    if(item.indexOf("@") >= 0)
                    {
                        // let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                        // console.log("item.match(regexEmail) asd ", regexEmail.test(item))
                        if (regexEmail.test(item) == true) 
                        {
                            tflag = 0
                        }
                        else
                        {
                            tflag = 1
                        }
                    }
                    else
                    {
                        tflag=1
                    }
                })
            }
            if(ctma.length > 0)
            {
                ctma.map((item) => {
                    if(item.indexOf("@") >= 0)
                    {
                        
                        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        // console.log("item.match(regexEmail) ", item.match(regexEmail))
                        if (item.match(regexEmail)) {
                            flag = 0
                        }
                        else
                        {
                            flag = 1
                        }
                    }
                    else
                    {
                        flag=1
                    }
                })
            }
            // console.log("Flag ", flag)
            if(flag == 0 && tflag == 0)
            {
                var parameters = {
                    rowid:rowid,
                    event_title:rownode.event_title,
                    send_notification:rownode.send_notification,
                    to_mails:rownode.to_mails,
                    cc_mails:rownode.cc_mails,
                    notify_ba:rownode.notify_ba,
                }
                redirectURL.post("/mllconsignments/savenotification", parameters)
                .then((response) => {
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Successfully added item",
                            basicType:"success"
                        })
                    }
                    else
                    {
                        this.setState({
                            show1:true,
                            basicTitle1:"Failed to add item",
                            basicType1:"danger"
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Please enter valid email addresses",
                    basicType1:"danger"
                })
            }
        }
        else
        {
            this.setState({
                show1:true,
                basicTitle1:"Fields should not be empty",
                basicType1:"danger"
            })
        }
        
    }
    addNewRow(){
        var newItem = 
        {
            "event_title":"",
            "send_notification":"",
            "to_mails":"",
            "cc_mails":"",
            "notify_ba":""
        };
        var res = this.gridApi.updateRowData({ add: [newItem] });
       
    }
    onSubmitConfigs(event)
    {
        event.preventDefault();
        var overspeeding = this.state.overspeeding;
        var convoy_trailer_distance_loading_yard = this.state.convoy_trailer_distance_loading_yard;
        var eta_delay_duration = this.state.eta_delay_duration;
        var eway_bill_expiry = this.state.eway_bill_expiry;
        var night_driving_start_time = $("#night_driving_start_time").val();
        var night_driving_end_time = $("#night_driving_end_time").val();
        if(overspeeding != "" && convoy_trailer_distance_loading_yard != "" && eta_delay_duration != ""
        && eway_bill_expiry != "" && night_driving_start_time != "" && night_driving_end_time != "" && 
        night_driving_end_time != "--:--" && night_driving_start_time != "--:--")
        {
            if(this.state.configrow == "")
            {
                var configrow = ""
            }
            else
            {
                var configrow = this.state.configrow;
            }
            var parameters = {
                rowid:configrow,
                overspeeding:overspeeding,
                convoy_trailer_distance_loading_yard:convoy_trailer_distance_loading_yard,
                eta_delay_duration:eta_delay_duration,
                eway_bill_expiry:eway_bill_expiry,
                night_driving_start_time:night_driving_start_time,
                night_driving_end_time:night_driving_end_time
            }
            redirectURL.post("/mllconsignments/savemllconfigs", parameters)
            .then((response) => {
                if(response.data.status == "success")
                {
                    this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully add/update item"
                    })
                }
                else
                {
                    this.setState({
                        show:true,
                        basicType:"danger",
                        basicTitle:"Failed to add/update item"
                    })
                }
            })
        }
        else{
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"Please enter all config parameters"
            })
        }
    }
    render(){
        var columnwithDefs = [
			{
                headerName:"Event",
                field:"event_title",
                width:340,
                editable:true
            },
            {
                headerName:"Send Notification (Yes/No)",
                field:"send_notification",
                width:140,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["Yes","No"],
                }
            },
            {
                headerName:"To (Comma Separated)",
                field:"to_mails",
                width:340,
                editable:true
            },
            {
                headerName:"CC (Comma Separated)",
                field:"cc_mails",
                width:340,
                editable:true
            },
            {
                headerName:"Notify BA (Yes/No)",
                field:"notify_ba",
                width:140,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: ["Yes","No"],
                }
            },
            {
                headerName:"Action",
                field:"",
                pinned:"left",
                width:140,
                editable:false,
                cellRendererSelector:function(params){
                    return {
                        component:"savenotification"
                    }
                }
            },
		]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12">
                    	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-notification cus-i"></i> <span>Notifications  </span>
                                    <button type="button" onClick={this.addNewRow.bind(this)} className="btn btn-warning float-right">Add New Event</button>
                                               
								</h5>
				   			</div>
				   			<div className="card-body row">
                            
                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
											masterDetail={true}
                                        />

                                    </div>
                                </div>
                                	
                            </div>

                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ui-settings cus-i"></i> <span>Config Parameters  </span>
                                </h5>
				   			</div>
				   			<div className="card-body row">
                                <div className={"row col-xl-12 col-lg-12 "}>
                                    <form method="post" className="col-xl-12 col-lg-12" >
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            Overspeeding
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="overspeeding"
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                                value={this.state.overspeeding}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            km/h
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            Night Driving Start Time
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="night_driving_start_time"
                                                className="form-control"
                                                id="night_driving_start_time"
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            Time
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            Night Driving End Time
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="night_driving_end_time"
                                                className="form-control"
                                                id="night_driving_end_time"
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            Time
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                        Convoy/Trailer Distance for Loading yard pre arrival notification
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="convoy_trailer_distance_loading_yard"
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                                value={this.state.convoy_trailer_distance_loading_yard}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            km
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            ETA Delay Duration
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="eta_delay_duration"
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                                value={this.state.eta_delay_duration}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            hrs
                                        </div>
                                    </div>
                                    
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            Eway bill expiry advance notification
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            <input 
                                                type="text"
                                                name="eway_bill_expiry"
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                                value={this.state.eway_bill_expiry}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2">
                                            days
                                        </div>
                                    </div>
                                    <div className="row form-group col-xl-12 col-lg-12">
                                        <div className="col-xl-3 col-lg-3">
                                            <button type="button" onClick={this.onSubmitConfigs.bind(this)} className="btn btn-success">Submit</button>
                                        </div>
                                        
                                    </div>
                                    </form>
                                </div>
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#night_driving_start_time').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false
	});
	$('#night_driving_end_time').datetimepicker({
		mask:'29:59',
		format:'H:i',
        datepicker:false
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#night_driving_start_time').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false
	});
	$('#night_driving_end_time').datetimepicker({
		mask:'29:59',
		format:'H:i',
        datepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }