import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import CountUp from 'react-countup';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM } from '../../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../../redirectURL');
var moment = require('moment');

export default class ConsolidateReport extends Component {
  	  constructor(props) {
	    super(props);
	    this.state = {
            rowData:[],
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:"show-n",
            overly:"show-n",
            dept_code:"",
            totalTrucks: [],
            truckscnt: 0,
            uniqueActiveConsignments:[],
            totalActiveConsignmentsCount: 0,
            ldata:[],
            ondata:[],
            parkdata:[],
            rowDataOntrips:[],
            rowDataPark:[],
            rowDataOff:[],
            rowData:[],
            pivotmodeis:false,
  	      pivotcols:[],

  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
		},
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
            
	    };
        this.loadData = this.loadData.bind(this);
        this.loadParkings = this.loadParkings.bind(this);
        this.generatData = this.generatData.bind(this);
	  }

	
    componentDidMount(){
		this.setState({
            loadshow:"show-n"
        });
        this.loadData();
        this.loadParkings();
        this.generatData();
    }
    loadData(){
        var deptcode="";
        if(this.props.match.path == "/sndconsolidate")
		{
            deptcode = "SNDG";
        }
        this.setState({
            dept_code:deptcode
        })
        var params = {
            deptcode:deptcode,
            defaulttab:"",
            israil:0
        }
        redirectURL.post("/dashboard/getTrucks",params)
        .then((response) => {
            let result = JSON.parse(JSON.stringify(response)).data;
            console.log("result ", result)

            let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
            let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;
            let totalTrucks = []
            let totalOnTrucks = []
            if(result.uniqueActiveConsignments.length > 0)
            {
                result.uniqueActiveConsignments.map((item) =>{
                    if(item.actual_lspuser != "AXESTRACK_PRT")
                    {
                        totalOnTrucks.push(item)
                    }
                })
            }
            if(result.uniqueTrucks.length > 0)
            {
                result.uniqueTrucks.map((item) =>{
                    if(item.actual_lspuser != "AXESTRACK_PRT")
                    {
                        try{
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(item.truckloc[0].timestamp).format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var s = Math.floor(d.asHours());
                            // console.log("d1 ", d1)
                            // console.log("d2 ", d2)
                            // console.log("Hrs ", s)
                            item.travelstatus = s;
                            if(s >= 24)
                            {
                                item.gps_status = "Inactive";
                            }
                            else {
                                item.gps_status = "Active";
                            }
                        }
                        catch(e){
        
                        }
                        if(item.elaped_time_from_last_moved_seconds != "" && item.elaped_time_from_last_moved_seconds != undefined)
                        {
                            if(item.elaped_time_from_last_moved_seconds > 300)
                            {
                                item.travel_status = "Idle ("+secondsToDhms(item.elaped_time_from_last_moved_seconds)+")";
                            }
                            else{
                                item.travel_status = "Running";
                            }
                             
                        }
                        else
                        {
                            try{
                                var d1 = moment.parseZone(new Date()).format("x")
                                var d2 = moment.parseZone(item.last_moved_time).format("x")
                                var dif  = (d1-d2)/1000;
                                if(dif > 300)
                                {
                                    item.travel_status = "Idel ("+secondsToDhms(dif)+")";
                                }
                                else
                                {
                                    item.travel_status = "Running";
                                }
                            }
                            catch(e){
            
                            }
                        }
                        totalTrucks.push(item)
                    }
                })
            }
            // console.log("totalTrucks ", totalTrucks)
            this.setState({
                ondata: totalOnTrucks,
                ldata:totalTrucks,
                loadshow:"show-m",
                overly:"show-m"
            });
            this.generatData();
        })
    }
	loadParkings(){

        var parkurl ='/dashboard/parkings';
		if(this.props.match.path == "/sndconsolidate")
		{
			parkurl ='/dashboard/sndparkings'; 
		}
		if(this.props.match.path == "/prtconsolidate")
		{
			parkurl ='/dashboard/prtparkings'; 
		}
		
		redirectURL.post(parkurl)
		.then((response) => {
			var parkings = response.data.records;
            console.log("Parkings ", parkings);
            this.setState({
                parkdata:parkings,
                loadshow:"show-m",
                overly:"show-m"
            })
            this.generatData();
            
		})
    }
    generatData(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var tottrips = this.state.ldata;
        var ontriprucks = this.state.ondata;
        // var ontripslist = []
        // if(ontriprucks.length > 0)
        // {
        //     ontriprucks.map((item) => {
        //         try{
        //             var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
        //             var d2 = moment.parseZone(item.truckloc[0].timestamp).format("YYYY-MM-DD HH:mm:ss")
        //             var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
        //             var d = moment.duration(ms);
        //             var s = Math.floor(d.asHours());
        //             // console.log("d1 ", d1)
        //             // console.log("d2 ", d2)
        //             // console.log("Hrs ", s)
        //             item.travelstatus = s;
        //             if(s >= 2)
        //             {
        //                 item.gps_status = "Inactive";
        //             }
        //             else {
        //                 item.gps_status = "Active";
        //             }
        //             ontripslist.push(item);
        //         }
        //         catch(e){

        //         }
        //     })
        // }
        var notontriptrucks = [];
        tottrips.map((e)=>{
            // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
            if(ontriprucks.filter(k => k.truck_no == e.truck_no).length == 0)
            {
                notontriptrucks.push(e)
            }
        })
        
        // console.log("ontrips ", ontriprucks)
        // console.log("notontriptrucks ",notontriptrucks);
        
        var bothonandpark = ontriprucks;
        var parkdata = this.state.parkdata;
        var parkingtrucks = [];
        notontriptrucks.map((e)=>{
            // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
            if(parkdata.filter(k =>( k.truck_no == e.truck_no && k.is_active == 1)).length > 0)
            {
                parkingtrucks.push(e)
                bothonandpark.push(e)
            }
        })
        
        var offtrucksarr = [];
        var offtrucks = [];
        tottrips.map((e)=>{
            // console.log("HHERE ", parkdata.filter(k => k.truck_no == e.truck_no))
            if(bothonandpark.filter(k => k.truck_no == e.truck_no).length == 0)
            {
                offtrucksarr.push(e)
            }
        })

        this.setState({
            rowData:tottrips,
            rowDataOntrips:ontriprucks,
            rowDataPark:parkingtrucks,
            rowDataOff:offtrucksarr,
            loadshow:"show-n",
            overly:"show-n"
        })
    }
    onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
		});

    }
    
    render() {
	  var columnDefontrip=[
        {
            headerName: "Truck No",
            field: "truck_no",
            width: 120,


          },
          {
            headerName: "LSP Code",
            field: "transporter_code",
            width: 120,

        },
        {
          headerName: "LSP Name",
          field: "transporter_name",
          width: 180,

        },
          {
          headerName: "GPS Provider",
          field: "actual_lspuser",
          width: 200,

        },
        
        {
            headerName: "GPS Status",
            field: "gps_status",
            width: 100,
            valueGetter:function(params)
            {
                try{
                        if(params.data.gps_status != "" && params.data.gps_status != undefined)
                        {
                        if(params.data.gps_status == "Inactive")
                        {
                            return "Inactive";
                        }
                        else {
                            return "Active";
                        }

                        }
                        else
                        {
                        if(params.data.truckloc.length > 0)
                        {
                            if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined)
                            {
                            return "Inactive";
                            }
                            else {
                            return "Active";
                            }
                        }
                        else {
                            return "";
                        }
                        }

                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                try{
                    if(params.data.gps_status != "" && params.data.gps_status != undefined)
                    {
                        if(params.data.gps_status == "Inactive")
                        {
                        return ["maroonbg","fbold"];
                        }
                        else {
                        return ["greenbg", "white", "fbold"];
                        }

                    }
                    else
                    {
                        if(params.data.truckloc.length > 0)
                        {
                            if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined)
                            {
                                return ["maroonbg","fbold"];
                            }
                            else {
                                return ["greenbg","white","fbold"];
                            }
                        }
                        else {
                        return "";
                        }
                    }

                    }
                    catch(e){
                        return "";
                    }
                }
            
        },
        {
            headerName: "Travel Status",
            field: "travel_status",
            width: 200
            
        },
        {
            headerName: "Last Known City/Town",
            field: "area",
            width: 200,
        },
        {
          headerName: "Last Known State",
          field: "state",
          width: 200,
        },
          {
            headerName: "Last Packet Time",
            field: "timestamp",
            width: 200,
            valueGetter:function(params){
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
            }
          
        },
        {
            headerName: "Data Received On",
            field: "modified_date",
            width: 200,
            valueGetter:function(params){
                return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
            }
            
        },
      ]
	    return (
	        <div className="container-fluid">
            <SweetAlert
                  show={this.state.show}
                  type={this.state.basicType}
                  title={this.state.basicTitle}
                  onConfirm={this.closeAlert}
              >
              </SweetAlert>
                <div className="row">                  
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="dashboardCounterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">

                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-primary f24"></i>
                                            <br /> Total Trucks
                                        </span>
                                        <h4 className="txt-primary f50">
                                            <span className="counter">
                                                <CountUp end={(this.state.rowData)?this.state.rowData.length:0}/>
                                            </span>
                                        </h4>
                                        
                                    </div>
                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                            <br /> Total Ontrips
                                        </span>
                                        <h4 className="txt-info f50">
                                            <span className="counter">
                                                <CountUp end={(this.state.rowDataOntrips)?this.state.rowDataOntrips.length:0}/>
                                            </span>
                                        </h4>
                                        
                                    </div>

                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                            <br /> Total Offtrips
                                        </span>
                                        <h4 className="txt-danger f50">
                                            <span className="counter">
                                                <CountUp end={(this.state.rowDataOff)?this.state.rowDataOff.length:0}/>
                                            </span>
                                        </h4>
                                        
                                    </div>      

                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-warning f24"></i>
                                            <br /> Total Parking
                                        </span>
                                        <h4 className="txt-warning f50">
                                            <span className="counter">
                                                <CountUp end={(this.state.rowDataPark)?this.state.rowDataPark.length:0}/>
                                            </span>
                                        </h4>
                                        
                                    </div>                                
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                   
                   
                    <div className="col-xl-6 col-lg-12">
                        <div className="card height-equal equal-height-lg">
                            <div className="card-header" style={{padding:'10px'}}>
                                <h5>Ontrips Trucks</h5>
                            </div>
                            <div className="card-body" style={{padding:'10px'}}>
                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                        modules={this.state.modules}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        columnDefs={columnDefontrip}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowDataOntrips}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            //sideBar={this.state.sideBar}
                                            pagination={true}
                                        />
                                    </div>
                            </div>

                        </div>

                    </div>
                    
                    <div className="col-xl-6 col-lg-12">
                        <div className="card height-equal equal-height-lg">
                            <div className="card-header" style={{padding:'10px'}}>
                                <h5>Offtrips Trucks</h5>
                            </div>
                            <div className="card-body" style={{padding:'10px'}}>
                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                        modules={this.state.modules}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        columnDefs={columnDefontrip}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowDataOff}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            //sideBar={this.state.sideBar}
                                            pagination={true}
                                        />
                                    </div>
                            </div>

                        </div>

                    </div>
                    
                    <div className="col-xl-12 col-lg-12">
                        <div className="card height-equal equal-height-lg">
                            <div className="card-header" style={{padding:'10px'}}>
                                <h5>Parkings</h5>
                            </div>
                            <div className="card-body" style={{padding:'10px'}}>
                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                        modules={this.state.modules}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        columnDefs={columnDefontrip}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowDataPark}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        frameworkComponents={this.state.frameworkComponents}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            //sideBar={this.state.sideBar}
                                            pagination={true}
                                        />
                                    </div>
                            </div>

                        </div>

                    </div>





                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>
	    );
	  }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } 
      catch (e) {
      /*Ignore*/
      }
    }
    return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function refActions()
{
	alert("helloo")
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}



                                
                                
                                
                                
                                
                                