import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import PRTOnTimeChart from './prtOnTimeChart';
import SweetAlert from 'react-bootstrap-sweetalert';

import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;
const couriercolumns= [
    {
        headerName: "Consignment Code",
        field: "consignment_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consigner Code",
        field: "consigner_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Vehicle Mode",
        field: "vehicle_mode",
        width:110,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "MGPA Date Time",
        field: "mgpa_date_time",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.mgpa_date_time)
            {
                return getHyphenYYYYMMDD(params.data.mgpa_date_time)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Consignee State",
        field: "consignee_state",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee City",
        field: "consignee_city",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Excpected ETA",
        field: "expected_eta",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.expected_eta)
            {
                return getHyphenYYYYMMDD(params.data.expected_eta)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Delivery Date",
        field: "delivery_datetime",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.delivery_datetime)
            {
                return getHyphenYYYYMMDD(params.data.delivery_datetime)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "MSIL Transit Days",
        field: "expected_transit_days",
        width:110,
        resizable: true,
        ediatable: false,
    },
    {
        headerName: "Actual Transit Days",
        field: "",
        width:110,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            const oneDay = 24 * 60 * 60 * 1000
            var startDate = params.data.mgpa_date_time ;
            var endDate = params.data.delivery_datetime;
            return Math.round(Math.abs((new Date(moment.parseZone(startDate).format("YYYY-MM-DD HH:MM")) - new Date(moment.parseZone(endDate).format("YYYY-MM-DD HH:MM"))) / oneDay));
        }
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Service Provider",
        field: "service_provider",
        width:130,
        resizable: true,
        ediatable: false
    },

]

const prtcolumns = [
    {
        headerName: "Truck No",
        field: "truck_no",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignment Code",
        field: "consignment_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consigner Code",
        field: "consigner_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Gate Out Time",
        field: "gate_out_time",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.gate_out_time)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Consignee State",
        field: "consignee_state",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Consignee City",
        field: "consignee_city",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Zone",
        field: "zone",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Expected ETA",
        field: "expected_trip_end",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.expected_trip_end)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "Reached Destination At",
        field: "recent_dealer_reported",
        width:130,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            if(params.data.recent_dealer_reported)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
            }
            else
            {
                return ""
            }
        }
    },
    {
        headerName: "MSIL Transit Days",
        field: "transit_time",
        width:110,
        resizable: true,
        ediatable: false,
    },
    {
        headerName: "Actual Transit Days",
        field: "",
        width:110,
        resizable: true,
        ediatable: false,
        valueGetter: function(params)
        {
            const oneDay = 24 * 60 * 60 * 1000
            var startDate = params.data.gate_out_time ;
            var endDate = params.data.recent_dealer_reported;
            return Math.round(Math.abs((new Date(moment.parseZone(startDate).format("YYYY-MM-DD HH:MM")) - new Date(moment.parseZone(endDate).format("YYYY-MM-DD HH:MM"))) / oneDay));
        }
    },
    {
        headerName: "Transporter Code",
        field: "parent_transporter_code",
        width:130,
        resizable: true,
        ediatable: false
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width:130,
        resizable: true,
        ediatable: false
    },

]

export default class PRTOnTimeComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-m',
            overly:'show-m',
            couriercolumns: couriercolumns,
            prtcolumns: prtcolumns,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
            showToolPanel: false,
            griddata:[],
			rowSelection: "single" ,
			context: { componentParent: this },
            
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Road On Time Performance",
            start_date: "",
            end_date: "",
            selected_lsps: [{"label": "ALL", "value": "ALL"}],
            selected_cities: [{"label": "ALL", "value": "ALL"}],
            lspOptions: [],
            cityOptions: [],
            courierLSPOptions: [],
            courierCityOptions: [],
            firstChartArr : [],
            secondChartArr : [],
            thirdChartArr : [],
            fourthChartArr : [],
            prtCourierActive: "",
            prtActive: "activet",
            prtSurfaceCourierActive: "",
            firstChartQuery: "",
            secondChartQuery: "",
            thirdChartQuery: "",
            fourthChartQuery: "",
            columnwithdefs: [],
            showChartData: "show-m",
            showGridData:  "show-n",
            gridTab: "",
            chartTab: "activet",
            roadDispatchOptions:[{"label": "ALL", "value": "ALL"},{"label": "Interplant", "value": "Interplant"},{"label": "Local Loads", "value": "Local Loads"}],
            courierDispatchOptions:[{"label": "ALL", "value": "ALL"},{"label": "Local Loads", "value": "Local Loads"}],
            selectedDispatchType: {"value": "ALL", "label": "ALL"},
            dispatchTypeOptions: [{"label": "ALL", "value": "ALL"},{"label": "Interplant", "value": "Interplant"},{"label": "Local Loads", "value": "Local Loads"}],
            statusTypeOptions: [{"value": "ALL", "label": "ALL"}, {"value": "Delivered", "label": "Delivered"}],
            selectedStatusType: {"value": "Delivered", "label": "Delivered"}
        };
        this.getFirstandThirdChartData = this.getFirstandThirdChartData.bind(this);
        this.getSecondChartData = this.getSecondChartData.bind(this);
        this.getFourthChartData = this.getFourthChartData.bind(this);
        this.getInitialData = this.getInitialData.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            
		});
		
    }
    
    async componentDidMount(){
       var edate = moment.parseZone().format('YYYY-MM-DD');
       var sdate = moment.parseZone(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
       
       this.setState({
           start_date: sdate,
           end_date: edate
       })
       var params = {
           start_date: sdate,
           end_date: edate
       }
       await this.getInitialData(params)
       var firstChartQuery = {
            from_date: sdate+" 00:00:00",
            to_date: edate+" 23:59:59",
            chart_mode: "prt",
            chart_type: "zone",
            api_name: "prtZoneWiseOnTimeAPI",
            courier_type: "",
            "status_type": "ALL",
            "dispatch_type": "ALL"
       }
       
       var q = {"from_date": sdate+" 00:00:00",
                "to_date": edate+" 23:59:59", 
                "chart_type": "prt",
                "consignee_cities": [],
                "lsp_names": [],
                "status_type": "ALL",
                "dispatch_type": "ALL"}
 
        redirectURL.post("/dashboard/prtontimegriddata",q).then((response)=>{
            this.setState({
                rowData: response.data,
                columnwithdefs: this.state.prtcolumns
            })
        }).catch((e)=>{
            console.log(e)
        })

       var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
       Promise.all([firstchart]).then(async ()=>{
        var secondChartQuery = {
            from_date: sdate+" 00:00:00",
            to_date: edate+" 23:59:59",
            chart_type: "prt",
            api_name: "prtStateWiseOnTimeAPI",
            courier_type: "",
            "status_type": "ALL",
            "dispatch_type": "ALL"
        }
        
        var secondchart = await this.getSecondChartData(secondChartQuery)
        Promise.all([secondchart]).then(async ()=>{
            var fourthChartQuery = {
                from_date: sdate+" 00:00:00",
                to_date: edate+" 23:59:59",
                chart_type: "prt",
                api_name: "prtLspWiseOnTimeAPI",
                courier_type: "",
                "status_type": "ALL",
                "dispatch_type": "ALL"
            }
            
            var fourthchart = await this.getFourthChartData(fourthChartQuery)
            Promise.all([fourthchart]).then(async ()=>{
                var thirdChartQuery = {
                    from_date: sdate+" 00:00:00",
                    to_date: edate+" 23:59:59",
                    chart_mode: "prt",
                    chart_type: "consigner_code",
                    api_name: "prtZoneWiseOnTimeAPI",
                    courier_type: "",
                    "status_type": "ALL",
                    "dispatch_type": "ALL"
                }
                
                await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
                this.setState({
                    firstChartQuery: firstChartQuery,
                    secondChartQuery: secondChartQuery,
                    thirdChartQuery: thirdChartQuery,
                    fourthChartQuery: fourthChartQuery,
                })
            })
        })
       })
        
    }

    getFirstandThirdChartData(params, statevar)
    {
        redirectURL.post("/dashboard/prtOnTimePerformanceChart",params).then((response)=>{
            console.log(response.data)
            var first_level = JSON.parse(response.data.on_time_df)
            var second_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "Zones",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.zone)
                series[0]['data'].push({
                    name: e.zone,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.zone
                })
                var filtered_data = second_level.filter(function(k){
                    return k.zone == e.zone
                })
                var drilldata = {
                    "name": e.zone,
                    "id": e.zone,
                    "data": []
                }
                filtered_data.map(function(j){
                    drilldata['data'].push({name: j.transporter_name,
                                         y: Math.round(j.on_time_percentage),
                                         total_records: j.total_records,
                                         on_time_records: j.on_time_records,})
                })
                drilldown.push(drilldata)
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                console.log(arr, "first chart data")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    [statevar]: arr
                })
            })
            
        }).catch((e)=>{console.log(e)})
    }
    
    getSecondChartData(params)
    {
        
        redirectURL.post("/dashboard/prtOnTimePerformanceChart",params).then((response)=>{
            var first_level = JSON.parse(response.data.state_wise_df)
            var second_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "States",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.state)
                series[0]['data'].push({
                    name: e.state,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.state
                })
                var filtered_data = second_level.filter(function(k){
                    return k.state == e.state
                })
                var drilldata = {
                    "name": e.state,
                    "id": e.state,
                    "data": []
                }
                filtered_data.map(function(j){
                    drilldata['data'].push({name: j.consignee_city,y: Math.round(j.on_time_percentage),
                                            total_records: j.total_records,
                                            on_time_records: j.on_time_records})
                })
                drilldown.push(drilldata)
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                console.log(arr, "first chart data")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    secondChartArr: arr
                })
            })
            
        }).catch((e)=>{console.log(e)})
    }

    getFourthChartData(params)
    {
        redirectURL.post("/dashboard/prtOnTimePerformanceChart",params).then((response)=>{
            var first_level = JSON.parse(response.data.lsp_df)
            var arr = [];
            var categories = [];
            var series = [{
                name: "Transporter Name",
                colorByPoint: true,
                data: []
            }];

            var drilldown = [];
            var top_chart = first_level.map(function(e){
                categories.push(e.transporter_name)
                series[0]['data'].push({
                    name: e.transporter_name,
                    y: Math.round(e.on_time_percentage),
                    total_records: e.total_records,
                    on_time_records: e.on_time_records,
                    drilldown: e.transporter_name
                })
                
            })
            Promise.all([top_chart]).then(()=>{
                arr.push({
                    categories: categories,
                    series: series,
                    drilldown: drilldown
                })
                console.log(arr, "fourth chart data")
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    fourthChartArr: arr
                })
            })
            
        }).catch((e)=>{console.log(e)})
    }

    getOptions(lst)
    {
        var lstval = [{"label": "ALL", "value": "ALL"}]
        lst.map(function(e){
            lstval.push({"label": e, "value": e})
        })
        return lstval
    }
    getInitialData(params)
    {
        redirectURL.post("/dashboard/getInitialDataFORPRTOnTime", params).then((response)=>{

            var lspOptions = this.getOptions(response.data.transporter_list);
            var cityOptions = this.getOptions(response.data.cities_list);
            console.log(response.data,"initial response")
            var courierLSPOptions = this.getOptions(response.data.courier_transporter_list);
            var courierCityOptions = this.getOptions(response.data.courier_cities_list);

            this.setState({
                lspOptions: lspOptions,
                cityOptions: cityOptions,
                courierLSPOptions: courierLSPOptions,
                courierCityOptions: courierCityOptions
            })
        })
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    
	onGridReady = params => {
		this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};
	onGridState = () => {
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
    handlerGridStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		start_date: startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerGridEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		end_date : edate
    	});
    }

    async onClickFilterDashboard(){
        console.log(this.state.selectedDispatchType,"selectedDispatchType")
        this.setState({
            firstChartArr: [],
            secondChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            loadshow: "show-m",
            overly: "show-m",
            rowData: ""
        })

        var lsp_names = [];
        if(this.state.selected_lsps != undefined && this.state.selected_lsps != null)
        {
            if(this.state.selected_lsps.indexOf("ALL") == -1)
            {
                this.state.selected_lsps.map(function(e){
                    if(e.value != "ALL")
                    {
                        lsp_names.push(e.value)
                    }
                })
                if(lsp_names.length > 0)
                {
                    lsp_names = JSON.stringify(lsp_names)
                }
            }
        }
        
        
        var consignee_cities = [];
        if(this.state.selected_cities != undefined && this.state.selected_cities != null)
        {
            if(this.state.selected_cities.indexOf("ALL") == -1)
            {
                this.state.selected_cities.map(function(e){
                    if(e.value != "ALL")
                    {
                        consignee_cities.push(e.value)
                    }
                })
                if(consignee_cities.length > 0)
                {
                    consignee_cities = JSON.stringify(consignee_cities)
                }
            }
        }

        var dispatch_type = "ALL";
        if(this.state.selectedDispatchType != undefined && this.state.selectedDispatchType != null)
        {
            if(this.state.selectedDispatchType.value != "ALL")
            {
                dispatch_type = this.state.selectedDispatchType.value
            }
        }

        var status_type = "ALL";
        if(this.state.selectedStatusType != undefined && this.state.selectedStatusType != null)
        {
            if(this.state.selectedStatusType.value != "ALL")
            {
                status_type = this.state.selectedStatusType.value
            }
        }
        
        var chart_type = ""
        var courier_type = ""
        if(this.state.prtActive != "")
        {
            chart_type = "prt";
        }
        else
        {
            chart_type = "prtcourier"
            if(this.state.prtCourierActive != "")
            {
                courier_type = "Air"
            }
            else
            {
                courier_type = "Surface"
            }
        }


        var q = {"from_date": this.state.start_date+" 00:00:00",
                "to_date": this.state.end_date+" 23:59:59", 
                "chart_type": chart_type,
                "consignee_cities": consignee_cities,
                "courier_type": courier_type,
                "lsp_names": lsp_names,
                "status_type": status_type,
                "dispatch_type": dispatch_type}
        
        var cols = this.state.prtcolumns

        if(chart_type != "prt")
        {
            cols = this.state.couriercolumns
        }
        redirectURL.post("/dashboard/prtontimegriddata",q).then((response)=>{
            console.log(response.data)
            this.setState({
                rowData: response.data,
                columnwithdefs: cols
            })
        }).catch((e)=>{
            console.log(e)
        })

        var firstChartQuery = {
            from_date: this.state.start_date+" 00:00:00",
            to_date: this.state.end_date+" 23:59:59",
            chart_mode: chart_type,
            chart_type: "zone",
            api_name: "prtZoneWiseOnTimeAPI",
            "courier_type": courier_type,
            "status_type": status_type,
            "dispatch_type": dispatch_type
        }
        if(lsp_names.length > 0)
        {
            firstChartQuery['lsp_names'] = lsp_names
        }
        if(consignee_cities.length > 0)
        {
            firstChartQuery['consignee_cities'] = consignee_cities
        }
        
        if(this.state.prtActive != "")
        {
            var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
        }
        var secondChartQuery = {
            from_date: this.state.start_date+" 00:00:00",
            to_date: this.state.end_date+" 23:59:59",
            chart_type: chart_type,
            api_name: "prtStateWiseOnTimeAPI",
            "courier_type": courier_type,
            "status_type": status_type,
            "dispatch_type": dispatch_type
        }
        
        if(lsp_names.length > 0)
        {
            secondChartQuery['lsp_names'] = lsp_names
        }
        if(consignee_cities.length > 0)
        {
            secondChartQuery['consignee_cities'] = consignee_cities
        }

        var secondchart = await this.getSecondChartData(secondChartQuery)
        
        var thirdChartQuery = {
            from_date: this.state.start_date+" 00:00:00",
            to_date: this.state.end_date+" 23:59:59",
            chart_mode: chart_type,
            chart_type: "consigner_code",
            api_name: "prtZoneWiseOnTimeAPI",
            "courier_type": courier_type,
            "status_type": status_type,
            "dispatch_type": dispatch_type
        }

        if(lsp_names.length > 0)
        {
            thirdChartQuery['lsp_names'] = lsp_names
        }
        if(consignee_cities.length > 0)
        {
            thirdChartQuery['consignee_cities'] = consignee_cities
        }

        await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
        
        
        var fourthChartQuery = {
            from_date: this.state.start_date+" 00:00:00",
            to_date: this.state.end_date+" 23:59:59",
            chart_type: chart_type,
            api_name: "prtLspWiseOnTimeAPI",
            "courier_type": courier_type,
            "status_type": status_type,
            "dispatch_type": dispatch_type
        }
        
        if(lsp_names.length > 0)
        {
            fourthChartQuery['lsp_names'] = lsp_names
        }
        if(consignee_cities.length > 0)
        {
            fourthChartQuery['consignee_cities'] = consignee_cities
        }

        var fourthchart = await this.getFourthChartData(fourthChartQuery)

        this.setState({
            firstChartQuery: firstChartQuery,
            secondChartQuery: secondChartQuery,
            thirdChartQuery: thirdChartQuery,
            fourthChartQuery: fourthChartQuery,
        })
    }

   async onClickTab(tab, courier_type)
    {
        this.setState({
            firstChartArr: [],
            secondChartArr: [],
            thirdChartArr: [],
            fourthChartArr: [],
            loadshow: "show-m",
            overly: "show-m",
            showChartData : "show-m",
            showGridData: "show-n",
            chartTab: "activet",
            gridTab: "",
            rowData: ""
        })
        var screen_type = ""
        if(tab == "prt")
        {
            screen_type = "prt"
            this.setState({
                prtActive: "activet",
                prtCourierActive: "",
                prtSurfaceCourierActive: "",
                cityOptions: this.state.cityOptions,
                lspOptions: this.state.lspOptions,
                pagetitle: "Road On Time Performance",
                
            })
        }
        else if(tab == "prt-courier")
        {
            screen_type = "prtcourier";
            var pagetitle=""
            if(courier_type == "Air")
            {
                pagetitle = "Air - Courier On Time Performance"
            }
            else
            {
                pagetitle = "Surface - Courier On Time Performace"
            }
            this.setState({
                prtActive: "",
                prtCourierActive: "activet",
                prtSurfaceCourierActive: "",
                cityOptions: this.state.courierCityOptions,
                lspOptions: this.state.courierLSPOptions,
                pagetitle: pagetitle
            })
        }
        else if(tab == "prt-surface-courier")
        {
            screen_type = "prtcourier";
            var pagetitle=""
            if(courier_type == "Air")
            {
                pagetitle = "Air - Courier On Time Performance"
            }
            else
            {
                pagetitle = "Surface - Courier On Time Performace"
            }
            this.setState({
                prtActive: "",
                prtSurfaceCourierActive: "activet",
                prtCourierActive: "",
                cityOptions: this.state.courierCityOptions,
                lspOptions: this.state.courierLSPOptions,
                pagetitle: pagetitle
            })
        }

        var firstChartQuery = this.state.firstChartQuery;
        firstChartQuery['chart_mode'] = screen_type;
        delete firstChartQuery['consignee_cities']
        delete firstChartQuery['lsp_names']

        var cols = this.state.prtcolumns

        if(screen_type != "prt")
        {
            cols = this.state.couriercolumns
        }
        var dispatch_type = "ALL";
        if(this.state.selectedDispatchType != undefined && this.state.selectedDispatchType != null)
        {
            if(this.state.selectedDispatchType.value != "ALL")
            {
                dispatch_type = this.state.selectedDispatchType.value
            }
        }

        var status_type = "ALL";
        if(this.state.selectedStatusType != undefined && this.state.selectedStatusType != null)
        {
            if(this.state.selectedStatusType.value != "ALL")
            {
                status_type = this.state.selectedStatusType.value
            }
        }

        redirectURL.post("/dashboard/prtontimegriddata",{"from_date": firstChartQuery.from_date,
         "to_date": firstChartQuery.to_date,"chart_type": screen_type,"courier_type": courier_type,
         "consignee_cities": [],dispatch_type: dispatch_type,status_type: status_type,
         "lsp_names": []}).then((response)=>{
            this.setState({
                rowData: response.data,
                columnwithdefs: cols
            })
        }).catch((e)=>{
            console.log(e)
        })

        this.setState({
            selected_cities: [{"label": "ALL", "value": "ALL"}],
            selected_lsps: [{"label": "ALL", "value": "ALL"}]
        }) 


        var secondChartQuery = this.state.secondChartQuery;
        secondChartQuery['chart_type'] = screen_type;
        delete secondChartQuery['consignee_cities']
        delete secondChartQuery['lsp_names']

        var thirdChartQuery = this.state.thirdChartQuery;
        thirdChartQuery['chart_mode'] = screen_type;
        delete thirdChartQuery['consignee_cities']
        delete thirdChartQuery['lsp_names']

        var fourthChartQuery = this.state.fourthChartQuery;
        fourthChartQuery['chart_type'] = screen_type;
        delete fourthChartQuery['consignee_cities']
        delete fourthChartQuery['lsp_names']
        

        if(tab == "prt")
        {
            firstChartQuery['chart_mode'] = "prt"
            firstChartQuery['courier_type'] = courier_type
            firstChartQuery['dispatch_type'] = dispatch_type
            firstChartQuery['status_type'] = dispatch_type
            var firstchart = await this.getFirstandThirdChartData(firstChartQuery, "firstChartArr")
        }
        secondChartQuery['courier_type'] = courier_type
        secondChartQuery['dispatch_type'] = dispatch_type
        secondChartQuery['status_type'] = dispatch_type
        var secondchart = await this.getSecondChartData(secondChartQuery)
        
        fourthChartQuery['courier_type'] = courier_type
        fourthChartQuery['dispatch_type'] = dispatch_type
        fourthChartQuery['status_type'] = dispatch_type
        var fourthchart = await this.getFourthChartData(fourthChartQuery)
        
        thirdChartQuery['courier_type'] = courier_type
        thirdChartQuery['dispatch_type'] = dispatch_type
        thirdChartQuery['status_type'] = dispatch_type
        await this.getFirstandThirdChartData(thirdChartQuery, "thirdChartArr")
    }
    onClickDataTab(tab)
    {
        if(tab == "chart")
        {
            this.setState({
                showChartData: "show-m",
                showGridData:  "show-n",
                chartTab: "activet",
                gridTab: ""
            })
        }
        else
        {
            this.setState({
                showGridData: "show-m",
                showChartData: "show-n",
                chartTab: "",
                gridTab: "activet"
            })
        }
    }
    render(){
        
        return (
            <div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">                   
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Start Date: </label>
                        <Datetime 
                        value={this.state.start_date} 
                        disableCloseOnClickOutside={false} 
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                        dateFormat="YYYY-MM-DD" 
                        name="startDate" 
                        onChange={this.handlerGridStartDateTime.bind(this)} />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>End Date: </label>
                        <Datetime value={this.state.end_date} 
                        disableCloseOnClickOutside={false} 
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                        dateFormat="YYYY-MM-DD" 
                        name="endDate" 
                        onChange={this.handlerGridEndDateTime.bind(this)} />   
                    </div>
                    <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>Dispatch Type: </label>
                            <Select placeholder={"All"}
                            isMulti={false}
                            value={this.state.selectedDispatchType}
                            className="border-radius-0"
                            onChange={(e)=>this.setState({selectedDispatchType: e})}
                            style={{borderRadius:"0px"}}
                            options={this.state.dispatchTypeOptions} required />
                    </div>
                    {/* <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>Status Type: </label>
                            <Select placeholder={"All"}
                            isMulti={false}
                            value={this.state.selectedStatusType}
                            className="border-radius-0"
                            onChange={(e)=>this.setState({selectedStatusType: e})}
                            style={{borderRadius:"0px"}}
                            options={this.state.statusTypeOptions} required />
                    </div> */}
                    <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                        <label>Location: </label>
                            <Select placeholder={"All"} isMulti={true} value={this.state.selected_cities} className="border-radius-0" onChange={(e)=>this.setState({selected_cities: e})} style={{borderRadius:"0px"}} options={this.state.cityOptions} required />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>Service Provider: </label>
                        <Select placeholder={"All"} isMulti={true} value={this.state.selected_lsps} className="border-radius-0" onChange={(e)=>this.setState({selected_lsps: e})} style={{borderRadius:"0px"}} options={this.state.lspOptions} required />
                    </div>
                    <div className="form-group col-xl-1 col-lg-1">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" 
                        // style={{position:"absolute", top:"2.2em", "left": "15em"}}
                        onClick={this.onClickFilterDashboard.bind(this)}>Submit</button>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <button className={"btn btn-default "+this.state.prtActive} onClick={this.onClickTab.bind(this, "prt", "")}>Road On Time Performance</button>
                    <button className={"btn btn-default "+this.state.prtCourierActive} onClick={this.onClickTab.bind(this, "prt-courier","Air")}>Air - Courier On Time Performace</button>
                    <button className={"btn btn-default "+this.state.prtSurfaceCourierActive} onClick={this.onClickTab.bind(this, "prt-surface-courier", "Surface")}>Surface - Courier On Time Performace</button>

                </div>
                
            	<div className={"row card col-xl-12 col-lg-12 "}>
                    <div className="card-header col-xl-12 col-lg-12">
                        <h4 >{this.state.pagetitle}</h4>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <button className={"btn btn-default "+this.state.chartTab} onClick={this.onClickDataTab.bind(this, "chart")}>Chart Data</button>
                        <button className={"btn btn-default "+this.state.gridTab} onClick={this.onClickDataTab.bind(this, "complete")}>Complete Data</button>
                    </div>
                    <div className={"card-body col-xl-12 col-lg-12 "+this.state.showChartData}>
                        {this.state.fourthChartArr.length > 0?  
                            this.state.fourthChartArr.map(item => 
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={(this.state.prtActive == "activet")?
                                                "Transporter Wise On Time Performance": 
                                                "Service Provider Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                                
                            )
                        :""}

                        
                        {this.state.secondChartArr.length > 0?  
                            this.state.secondChartArr.map(item => 
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={"State Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                                
                            )
                        :""}        

                        {this.state.thirdChartArr.length > 0?  
                            this.state.thirdChartArr.map(item => 
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={"Consigner Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                                
                            )
                        :""} 

                        {this.state.firstChartArr.length > 0?  
                            this.state.firstChartArr.map(item => 
                                <div className="col-xl-12 col-lg-12">
                                    <PRTOnTimeChart
                                        title={"Zone Wise On Time Performance"}
                                        subtitle={""}
                                        context={this}
                                        series={item.series}
                                        drilldown = {item.drilldown}
                                        categories={item.categories}
                                        yaxistitle={"On Time %"}
                                    />
                                </div>
                                
                            )
                        :""}
            
                    </div>
                    <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham col-xl-12 col-lg-12 "+this.state.showGridData}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={this.state.columnwithdefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                    </div>
                </div>

                

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}