'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import LoadSummaryDrawmap from '../layouts/loadSummaryDrawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import LoadSummaryColumnChart from "./loadSummaryColumnChart";
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LoadSummaryRecommondations extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        summary: "activet",
        trucks: "",
        courier: "",
        recommendations: "",
        dropped: "",
        summaryData: "",
        trucksData: "show-n",
        courierData: "show-n",
        recommendationsData: "show-n",
        droppedData: "show-n",
        seriesData: [],
        categories: [],
        costData: [],
        costCategories: [],
        drpData : [],
        drpCategories : [],
        fileName : "",
        clustersData : [],
        couriersData : [],
        recommData : [],
        droppedPData : [],
        transactionId: "",
        latLngArray: [],
        mapType: "",
        sliderTitle: "",
        rowIds: [],
        chkOutputFile: 1,
        loading_port_code : "",
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){

    var transactionId = this.props.transactionId;
    var recommData = this.props.recommData;
    var loading_port_code = this.props.loading_port_code;
    var chkOutputFile = this.props.chkOutputFile;
    console.log(transactionId,"transactionId-Recomm")
    this.setState({
        transactionId : transactionId,
        recommData : recommData,
        chkOutputFile : chkOutputFile,
        loading_port_code : loading_port_code,
    })
}
onClickSendRecommend(){
    // var transactionId = this.props.transactionId;
    // var rowData = [];
    // this.gridApi.forEachNode(node => rowData.push(node.data));
    // console.log(rowData,"rowData");
    // var recommends = "";
    // rowData.map(function(e){
    //     var cluster = e.cluster;
    //     var recommendation = e.recommendation.replace(/ /g, "_");;
    //     var prop_truck = e.prop_truck;
    //     var rec_courier = e.rec_courier;
    //     if(prop_truck !='' && prop_truck !=undefined)
    //     {
    //         recommends = cluster+"-"+recommendation+"-"+prop_truck+"-"+rec_courier+'#'+recommends
    //     }
    // })
    // console.log(recommends,"recommends")
    // if(recommends !='')
    // {
    //     var loading_port_code = this.state.loading_port_code;
    //     var query = {
    //         transactionId : transactionId,
    //         recommends : recommends,
    //         loading_port_code : loading_port_code,
    //     }
        
    //     redirectURL.post("/lmconsignees/generateLoadByRecommendations",query).then((response)=>{
    //         console.log(response,"responseFromProcess")
    //         var statusCode = response.data.status;
    //         if(statusCode > 3)
    //         {
    //             this.setState({
    //                 processErrMsg : response.data.message,
    //                 processErrShow : 1,
    //                 transactionId : response.data.transactionId,
    //                 droppedList : response.data.droppedList,
    //                 loadshow:'show-n',
    //                 overly:'show-n',
    //             });
    //         }
    //         else
    //         {
    //             this.setState({                    
    //                 basicTitle : response.data.message,
    //                 basicType : "success",
    //                 show : true,
    //                 loadshow:'show-n',
    //                 overly:'show-n',
    //                 bulkslide:"",
    //             });
    //         }
    //         window.location.href="/lmloadsummary";
    //     });
    // }
    // else
    // {
    //     this.setState({                    
    //         basicTitle : "Please Enter Proposed Truck CMT",
    //         basicType : "warning",
    //         show : true,
    //         loadshow:'show-n',
    //         overly:'show-n',
    //         bulkslide:"",
    //     });
    // }
}

onClickAcceptRecommend()
{
    var transactionId = this.props.transactionId;
    var recommends = this.state.rowIds;
    console.log(recommends,"recommends")
    if(recommends.length > 0 )
    {
        var loading_port_code = this.state.loading_port_code;
        var query = {
            transactionId : transactionId,
            recommends : recommends,
            loading_port_code : loading_port_code,
        }
        
        redirectURL.post("/lmconsignees/generateLoadByRecommendations",query).then((response)=>{
            console.log(response,"responseFromProcess")
            var statusCode = response.data.status;
            if(statusCode > 3)
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                    droppedList : response.data.droppedList,
                    loadshow:'show-n',
                    overly:'show-n',
                });
            }
            else
            {
                this.setState({                    
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                    bulkslide:"",
                });
            }
            window.location.href="/lmloadsummary";
        });
    }
    else
    {
        this.setState({                    
            basicTitle : "Please Select Recommendation to continue",
            basicType : "warning",
            show : true,
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
        });
    }
}
onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'cluster',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
    })
}

showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

closeAlert = () => {
    this.setState({
        show: false
    });
}

onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data.cluster)
    });
    console.log(rowIds,"selrowIds")
    this.setState({
        rowIds:rowIds
    })
}

  render() {
    var chkOutputFile = this.state.chkOutputFile;
    var hideActions = false;
    if(chkOutputFile == 1)
    {
        hideActions = true;
    }
    var columnwithDefsForRecommended = [      
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster+" - "+params.data.zone
            }
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Current Transport Mode",
            field: "truck_capacity",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Recommended Transport Mode",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "recommendation",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "prop_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
            valueGetter: function(params){
                if(params.data.prop_truck == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.prop_truck
                }
            },
            valueSetter: function(params){
                //console.log(params)
                var newValue= params.newValue;
                var initial_load= params.data.tripVolume;
                if (parseFloat(newValue) > parseFloat(initial_load))
                {
                    return false;
                }
                else
                {
                    params.data.prop_truck = newValue;
                    var courier_cmt= Math.round((initial_load - newValue) * 100)/100;
                    
                    var cost_percmtcourier = params.data.courier_cost;
                    var courier_cost = courier_cmt * 212 * cost_percmtcourier;
                    var cost_percmt = params.data.proposed_truck_cost;
                    var truck_cost = newValue * cost_percmt;
                    var total_cost = courier_cost + truck_cost;

                    params.data.rec_courier = courier_cmt;
                    params.data.rec_courier_cost = Math.round(courier_cost * 100)/100;                   
                    params.data.rec_truck_cost = Math.round(truck_cost * 100)/100;
                    params.data.rec_total_cost = Math.round(total_cost * 100)/100;
                    return true;
                }
            }
        },
        {
            headerName: "Proposed Cost/CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "proposed_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Cost/Kg by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Cost Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            // valueGetter: function(params){
            //     return 0;
            // }
            valueGetter: function(params){
                if(params.data.rec_truck_cost == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.rec_truck_cost
                }
            },
        },
        {
            headerName: "Proposed Cost by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Total Cost",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_total_cost",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]

    var columnwithDefsForRecommendedNew = [    
        {
            headerName: "Actions",
            field: "cluster",
            width: 40,
            pinned: 'left',
            filter: false,
            resizable: true,
            headerCheckboxSelection: true,
            //headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            hide : hideActions
        },  
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster+" - "+params.data.zone
            }
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Recommended Transport Mode",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "truck_capacity",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Truck CMT",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "tripVolume",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
            valueGetter: function(params){
                if(params.data.tripVolume == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.tripVolume
                }
            },
            valueSetter: function(params){
                //console.log(params)
                var newValue= params.newValue;
                var initial_load= params.data.tripVolume;
                if (parseFloat(newValue) > parseFloat(initial_load))
                {
                    return false;
                }
                else
                {
                    var cost_percmt = params.data.proposed_truck_cost;
                    var truck_cost = params.data.tripVolume * cost_percmt;
                    return truck_cost;
                }
            }
        },
        {
            headerName: "Proposed Truck Freight",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "tripCost",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Actual Payable Truck Freight",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "actualCost",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Courier CMT",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "tripVolume",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Courier Freight",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "trip_courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.trip_courier_cost !=undefined && params.data.trip_courier_cost !='')
                {
                    return Math.round(params.data.trip_courier_cost);
                }
            }
        },
        {
            headerName: "Difference",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "difference",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                //console.log(params)
                var courier_cost= params.data.trip_courier_cost;
                var tripCost= params.data.tripCost;
                var actualCost= params.data.actualCost;
                if(courier_cost !=undefined && courier_cost !='' && actualCost !=undefined && actualCost !='')
                {
                    return Math.round(parseFloat(courier_cost)-parseFloat(actualCost));
                }
                else
                {
                    return "";
                }
            }
        },        
    ]
    
    return (
        <div class="container-fluid">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            {(this.state.chkOutputFile == 0) ?
            <div className="col-xl-12">
                {(this.state.loading_port_code == "GUR-3") ?
                <button type="button" className="btn btn-info" onClick={this.onClickAcceptRecommend.bind(this)} style={{marginBottom:"1em"}}>Accept Recommendations</button>
                : ""}
            </div>
            : ""}
            <div className="col-xl-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>
                <AgGridReact
                    modules={this.state.modules}
                    //columnDefs={columnwithDefsForRecommended}
                    columnDefs={columnwithDefsForRecommendedNew}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.recommData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onRecommGridReady}
                    onGridState={this.onRecommGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    rowSelection={this.state.rowSelection}
                    onRowSelected={this.onRowSelection.bind(this)}
                    />
                </div>
            </div>
        </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    var charCode = getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};