/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';


const ParkingoutCarrier = (props) => {

    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickParkingoutCarrierAction(props.data);
    };
    return (
        <div className="map-icon-component">
			     <button onClick={handleRouteClick} className="btn btn-info btn-xs" title="Parking Out">Parking Out</button>
        </div>
    );
};

export default ParkingoutCarrier;
