import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText,Alert, UncontrolledAlert } from 'reactstrap';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");

class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname:'',
            lastname:'',
            email:'',
            password:'',
            dept_code:'',
            role:'',
            alldepartments:[],
            alertFade:true,
            formMessage:'',
            formStatus:'',
            show : false,
            basicTitle : '',
            basicType : '',
            showPassword : "password"
        };
        this.changeHandler=this.changeHandler.bind(this);
        this.formHandler = this.formHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
   
    changeHandler(event){
        console.log(event.target,"event");
        let name = event.target.name;
        var flag = true;
        let value = event.target.value;
        if(value !=undefined && value !="")
        {
            if(['firstname', 'lastname'].includes(name))
            {
                var regex = /^[A-Za-z]+$/
                flag = regex.test(value);
            }
        }
        if(flag){
            this.setState({
                [name]:value
            });
        }
        // else
        // {
        //     this.setState({
        //         basicTitle: "Only characters allowed",
        //         basicType: "warning",
        //         show: true
        //     })
        // }
    }
    closeAlert = () => {
        this.setState({
            show: false,
        });
    }
    formHandler(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

   
        // console.log("Form data ",data);
        var email = this.state.email.replace(/\s/g,"").toLowerCase();
        var check = 0;
        var user_created_by = localStorage.getItem('email');
        if(email !=undefined && email !="")
        {
            if(email.includes("@") == true)
            {
                var emailCheck = email.split("@")[1];
                
                if(emailCheck == "heromotocorp.com" || emailCheck == "herocorp.in"){
                    check = 1;
                    if(this.state.password !=undefined && this.state.password !="")
                    {
                        var verifypwd = CheckPassword(this.state.password);
                        console.log(verifypwd,"verifypwd");
                        if(verifypwd == true)
                        {
                            check = 1;
                            var verifyconspwd = CheckConsecutivePassword(this.state.password);
                            console.log(verifyconspwd,"verifyconspwd");
                            if(verifyconspwd == false)
                            {
                                check = 3;
                            }
                        }
                        else
                        {
                            check = 2;
                        }
                    }
                }
                else{
                    check = 0;
                }
            }
           console.log(emailCheck,"emailCheck"); 
        }
        
        console.log(check,"check")
        if(check == 1)
        {
            var formdata = {
                firstname:this.state.firstname,
                lastname:this.state.lastname,
                email: email,
                password:this.state.password,
                roles:["LOGPRTUSER"],
                dept_code:["LOG-PRT"],
                uiuser : 1,
                verified : 1,
                user_type : "ORGUSER",
                login_attempts : 0,
                user_created_by:user_created_by,
            }
            console.log("formdata of saved user", formdata);
            redirectURL.post('/dashboard/saveuser',formdata)
            .then((response) => {
                // console.log('res[pse',response)
                let resp = response.data.status
                if(resp === 'Fail'){
                    this.setState({
                        show: true,
                        basicTitle: 'Email ID already registered',
                        basicType: 'warning'
                    })
                }else{
                    this.setState({
                        formMessage:response.data.message,
                        formStatus:response.data.status,
                        alertFade:false,
                        firstname:'',
                        lastname:'',
                        email:'',
                        password:'',
                        dept_code:'',
                        roles:'',
                        show: true,
                        basicTitle : 'User Created Successfully',
                        basicType: 'success'
                    })
                }
                
            
            
            })
            .catch((error) => {
                console.log(error)
            })
        }
        else if(check == 0)
        {
            this.setState({
                show: true,
                basicTitle : 'Please enter valid email id',
                basicType: 'warning'
            })
        }
        else if(check == 3)
        {
            this.setState({
                show: true,
                basicTitle : 'Please enter valid password',
                basicType: 'warning'
            })            
        }
        else
        {
            this.setState({
                show: true,
                basicTitle : 'Please enter valid password',
                basicType: 'warning'
            })
        }
    }
    handleClickShowPassword = () => {
        var oldPassword = this.state.showPassword;
        if(oldPassword == "text")
        {
            var showPassword = "password";
        }
        else
        {
            var showPassword = "text";
        }
        this.setState({
            showPassword : showPassword
        });
      };
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')
        const {show, basicType, basicTitle} = this.state
        
        return (
            <div>
                 <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        
                    </div>
                   
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                
                                <div className="row col-md-12 p-0">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-6 p-20">
                                        <div className="authentication-box">
                                            <h3 className="text-center">ADD NEW USER</h3>
                                            <div className="card mt-4 p-4">
                                            
                                                {this.state.formStatus != ""?
                                                        <div>
                                                                {(this.state.formStatus == 'Success')?
                                                                    <Alert color="secondary" fade={this.state.alertFade}>
                                                                        
                                                                    <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                    {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                                </Alert>
                                                                :
                                                                <Alert color="danger" fade={this.state.alertFade}>
                                                                    <i className="icon-alert"></i>  {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                </Alert>
                                                                }
                                                        </div>
                                                    :""
                                                }
                                                <form className="theme-form" onSubmit={this.formHandler}>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">First Name</label>
                                                                <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeHandler} autocomplete="off" placeholder="Enter First Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Last Name</label>
                                                                <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeHandler} autocomplete="off" placeholder="Enter Last Name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Email Address</label>
                                                        <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeHandler} autocomplete="off" placeholder="Email Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <div className="input-group">
                                                            <input type={this.state.showPassword} required className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} autocomplete="off" placeholder="**********"/>
                                                            <div className='input-group-append'>
                                                                <span className={(this.state.showPassword == "password") ? 'input-group-text fa fa-eye' : 'input-group-text fa fa-eye-slash'} onClick={this.handleClickShowPassword}></span>
                                                            </div>
                                                            <span style={{"color":"red"}}>Password should contain atleast 8 characters, atleast 1 uppercase character, atleast 1 lowercase character, atleast 1 digit and atleast 1 special Character</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label className="col-form-label">Role</label>
                                                        <div className="form-row">
                                                            <div className="col-sm-12">
                                                                <select required className="form-control mb-1" name="roles" value={this.state.roles} onChange={this.changeHandler}>
                                                                    <option value=''>Select</option>
                                                                    <option value="Plant">Plant</option>
                                                                    <option value="RSO">RSO</option>
                                                                    <option value="Loading Officer">Loading Officer</option>
                                                                    <option value="Unloading Officer">Unloading Officer</option>
                                                                    <option value="BA">BA</option>
                                                                    <option value="MahindraAdmin">Admin</option>
                                                                </select>
                                                            </div>
                                                             
                                                        </div>
                                                    </div> */}
                                                    <div className="form-row">
                                                        <div className="col-sm-3">
                                                            <button type="submit" className="btn btn-secondary">Register
                                                            </button>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
function CheckPassword(inputtxt) 
{ 
	var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if(inputtxt.match(decimal)) 
	{ 
		return true;
	}
	else
	{ 
		return false;
	}
}
function CheckConsecutivePassword(input)
{
    if (/(.)\1\1/.test(input)) {
        return false;
    }
    else
    {
        return true;
    }
}
function CheckField(inputtxt) 
{ 
    console.log("inputtxt ", inputtxt)
    // var decimal=  /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9]+$/;
    // console.log("inputtxt.match(decimal ",inputtxt.match(decimal))
	// if(inputtxt.match(decimal)) 
	// { 
	// 	return true;
	// }
	// else
	// { 
	// 	return false;
    // }
    var regex = /^[A-Za-z]+$/
 
    //Validate TextBox value against the Regex.
    var isValid = regex.test(inputtxt);
    console.log("isValid ", isValid)
    if (!isValid) {
        alert("Contains Special Characters.");
    } else {
        alert("Does not contain Special Characters.");
    }

    return isValid;
} 
export default CreateUser;