var axios = require('axios');

var axiosInstance = axios.create({
  // baseURL: 'https://ve-autometrics.in/foxtrot/'
  // baseURL: 'http://34.131.39.195:5000/foxtrot/'
  baseURL: 'https://ve-autometrics.in/foxtrot/'
  /* other custom settings */
});

module.exports = axiosInstance;
