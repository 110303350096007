'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import LoadSummaryDrawmap from '../layouts/loadSummaryDrawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import LoadSummaryColumnChart from "./loadSummaryColumnChart";
import download from 'js-file-download';
import LoadSummaryRecommondations from "./loadSummaryRecommondations";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmVOutputDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        config : "",
        summary: "activet",
        trucks: "",
        courier: "",
        recommendations: "",
        dropped: "",
        configData: "show-n",
        summaryData: "",
        trucksData: "show-n",
        courierData: "show-n",
        recommendationsData: "show-n",
        droppedData: "show-n",
        seriesData: [],
        categories: [],
        costData: [],
        costCategories: [],
        drpData : [],
        drpCategories : [],
        fileName : "",
        configurationData : [],
        clustersData : [],
        couriersData : [],
        recommData : [],
        droppedPData : [],
        transactionId: "",
        latLngArray: [],
        mapType: "",
        sliderTitle: "",
        loadingLocation:[],
        //components : {datepicker:getDatePicker()}
    }
}
componentDidMount(){
    var transactionId = this.props.match.params.eventname;
    console.log(transactionId,"transactionId")
    var query = {
        "transactionId" : transactionId
    }
    
    redirectURL.post("/lmconsignees/getLoadVOutputRunDetails",query).then((response)=>{
        var loadprocess = response.data;
        var configurationData = [];
        if(loadprocess.length > 0)
        {
            var fileName = loadprocess[0].filename;
            var configurationData = [];
        }
        this.setState({
            rowData : loadprocess,
            fileName : fileName,
            transactionId : transactionId,
            configurationData : configurationData
        })
    }).catch((e)=>{
        console.log(e);
    })
    this.onLoadSeriesData(transactionId);
    this.onLoadCostSummaryData(transactionId);    
}
onLoadSeriesData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmVOutputChartData",params).then((response)=>{
        console.log(response.data,"chartData");
        var respData = [{
                "name" : "Total Order CMT",
                "showTooltip": 1,
                "y" : parseFloat(response.data.total_orders.toFixed(2)),
            },
            {
                "name" : "Planned By Truck(CMT)",
                "showTooltip": 1,
                "y" : parseFloat(response.data.total_truck_orders.toFixed(2)),
            },
            {
                "name" : "Planned By Courier(CMT)",
                "showTooltip": 1,
                "y" : parseFloat(response.data.total_courier_orders.toFixed(2)),
                "color": "#4c923e"
            },
            {
                "name" : "Dropped Orders(CMT)",
                "showTooltip": 1,
                "y" : parseFloat(response.data.total_dropped_orders.toFixed(2)),
            },
        ];
        var categories = ["Total Order CMT","Planned By Truck(CMT)","Planned By Courier(CMT)","Dropped Orders(CMT)"];
        var droppedReasons = response.data.droppedReasons;
        var drpData = [];
        var drpCategories = [];
        droppedReasons.map(function(k){
            drpData.push({
                "name": k.dropped_reason,
                "showTooltip": 1,
                "y": parseFloat(k.dropped_count.toFixed(2)),
            })
            drpCategories.push(k.dropped_reason)
        });
        this.setState({
            seriesData : respData,
            categories : categories,
            drpData : drpData,
            drpCategories : drpCategories,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onLoadCostSummaryData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmLoadVOutputChartCostSummaryData",params).then((response)=>{
        console.log(response.data,"costData");
        var costResData = response.data;
        var costData = [];
        var costCategories = [];
        var totalCost = 0;
        costResData.map(function(k){
            totalCost = parseFloat(totalCost) + parseFloat(k.vehiCost);
        });
        costData.push({
            "name": "Total Freight Cost",
            "showTooltip": 1,
            "y": parseFloat(totalCost.toFixed(2)),
        })
        costResData.map(function(e){
            if(e.truck_capacity == "Courier")
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                    "color": "#4c923e"
                })
            }
            else
            {
                costData.push({
                    "name": e.truck_capacity,
                    "showTooltip": 1,
                    "y": parseFloat(e.vehiCost.toFixed(2)),
                })
            }
            costCategories.push(e.truck_capacity)
        });
        //console.log(costData,"costData");
        //console.log(costCategories,"costCategories");
        this.setState({
            costData : costData,
            costCategories : costCategories,
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getClustersData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmVOutputClustersData",params).then((response)=>{
        console.log(response.data,"clustersData");
        this.setState({
            clustersData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}    
getCouriersData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmVOutputCouriersData",params).then((response)=>{
        console.log(response.data,"couriersData");        
        this.setState({
            couriersData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getRecommondationsData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmVOutputRecommondationsData",params).then((response)=>{
        console.log(response.data,"recommData");        
        this.setState({
            recommData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
getLmVOutputDroppedData(transactionId){
    var params = {
        "transactionId" : transactionId
    }
    redirectURL.post("/lmconsignees/getLmVOutputDroppedData",params).then((response)=>{
        console.log(response.data,"droppedPData");
        this.setState({
            droppedPData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}
onClickSendRecommend(){
    var transactionId = this.state.transactionId;
    console.log(transactionId)
    var rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    //return rowData;
    console.log(rowData,"rowData")
} 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'cluster',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}

onRecommGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'cluster',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onRecommGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}


closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

handleDownloadOutputFile = (idx) => {
    var outputFile = idx;
    console.log(outputFile)
    if(outputFile !='')
    {
        var rngParam = {
            inputFile : outputFile,
        }
        redirectURL.post("/lmconsignees/downloadInputFile",rngParam).then((response)=>{
            console.log("download response ",response)
            download(response.data, outputFile)
            if(response.data.message == "success")
            {
                alert("success");
            }
            
        }).catch(function(error){
            console.log(error);
        })
    }
}

onClickTab(pageType){
    if(pageType == "config")
    {
        var config = "activet";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var configData = "";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
    }
    if(pageType == "summary")
    {
        var config = "";
        var summary = "activet";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var configData = "show-n";
        var summaryData = "";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
    }
    if(pageType == "trucks")
    {
        var config = "";
        var summary = "";
        var trucks = "activet";
        var courier = "";
        var recommendations = "";
        var dropped = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        if(this.state.clustersData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getClustersData(this.state.transactionId)
        }        
    }
    if(pageType == "courier")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "activet";
        var recommendations = "";
        var dropped = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "";
        var recommendationsData = "show-n";
        var droppedData = "show-n";
        if(this.state.couriersData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getCouriersData(this.state.transactionId)
        }
    }
    if(pageType == "recommendations")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "activet";
        var dropped = "";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "";
        var droppedData = "show-n";
        if(this.state.recommData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getRecommondationsData(this.state.transactionId)
        }
    }
    if(pageType == "dropped")
    {
        var config = "";
        var summary = "";
        var trucks = "";
        var courier = "";
        var recommendations = "";
        var dropped = "activet";
        var configData = "show-n";
        var summaryData = "show-n";
        var trucksData = "show-n";
        var courierData = "show-n";
        var recommendationsData = "show-n";
        var droppedData = "";
        if(this.state.droppedPData.length == 0)
        {
            this.setState({
                overly:"show-m",
                loadshow:"show-m",
            })
            this.getLmVOutputDroppedData(this.state.transactionId)
        }
    }
    this.setState({
        config: config,
        summary: summary,
        trucks: trucks,
        courier: courier,
        recommendations: recommendations,
        dropped: dropped,
        configData: configData,
        summaryData: summaryData,
        trucksData: trucksData,
        courierData: courierData,
        recommendationsData: recommendationsData,
        droppedData: droppedData,
    })
}
onCellClickedGrid(row){
    if(row.colDef.field == "_id"){
        var cluster = row.data.cluster;
        var zone = row.data.zone;
        var tripDistance = row.data.tripDistance;
        var truck_capacity = row.data.truck_capacity;
        var transactionId = this.state.transactionId;        
        var dealer_code = row.data.dealerCode;        
        var load_cmt = row.data.load_cmt;
        // if(truck_capacity == "Courier")
        // {
        //     var latLngArray = [{
        //         lat : row.data.lat,
        //         lng : row.data.lng,
        //         dealer_code : dealer_code,
        //         truck_capacity : truck_capacity,
        //         load_cmt : load_cmt,
        //     }]
        //     this.setState({
        //         latLngArray : latLngArray,
        //         mapType : 2,
        //         bulkslide : "slider-translate-60p",
        //         overly: "show-m",
        //         sliderTitle : sliderTitle
        //     })
        // }
        // else
        // {
            
        // }
        var params = {
            "transactionId" : transactionId,
            "cluster" : cluster,
        }
        redirectURL.post("/lmconsignees/getLmVOutputClustersLatData",params).then(async (response)=>{
            //console.log(response.data,"latLngData");
            var latLngData = response.data;
            var latLngArray = [];
            
            await latLngData.map(function(e){
                latLngArray.push({
                    lat : e.consignees[0].lat,
                    lng : e.consignees[0].lng,
                    dealer_code : e.dealer_code,
                    truck_capacity : e.truck_capacity,
                    load_cmt : e.load_cmt,
                })
            })
            //console.log(latLngArray,"latLngArray")
            var mapType = 1;
            if(truck_capacity == "Courier")
            {
                var mapType = 2;
                var sliderTitle = "Cluster Name : "+cluster+" Total Distance :0 Km";
            }
            else{
                var sliderTitle = "Cluster Name : "+cluster+" Total Distance : "+tripDistance+" Km";
            }
            var loading_port_code = localStorage.getItem("loading_port_code");
            var aparams = {
                loading_port_code : loading_port_code
            }
            await redirectURL.post("/lmconsignees/getLmLoadingPortName",aparams).then((response1)=>{
                //console.log(response1.data,"LLPN");
                var loadingLocation = [];
                 loadingLocation.push({
                    lat : response1.data[0].lat,
                    lng : response1.data[0].lng,
                })
                this.setState({
                    loadingLocation : loadingLocation,
                })
            }).catch((e)=>{
                console.log(e);
            })
            this.setState({
                mapType : mapType,
                latLngArray : latLngArray,
                bulkslide : "slider-translate-60p",
                overly: "show-m",
                sliderTitle : sliderTitle
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
}
onClickShowAllCouriers(){
    var transactionId = this.state.transactionId;
    var params = {
        "transactionId" : transactionId,
    }
    redirectURL.post("/lmconsignees/getAllLmVOutputCouriersLatData",params).then((response)=>{
        //console.log(response.data,"latLngData");
        var latLngData = response.data;
        var latLngArray = [];
        
        latLngData.map(function(e){
            latLngArray.push({
                lat : e.lat,
                lng : e.lng,
                dealer_code : e.dealer_code,
                truck_capacity : e.truck_capacity,
                load_cmt : e.load_cmt,
            })
        })
        console.log(latLngArray,"latLngArray")
      
        this.setState({
            latLngArray : latLngArray,
            mapType : 2,
            bulkslide : "slider-translate-60p",
            overly: "show-m",
            sliderTitle : "All Couriers"
        })
    }).catch((e)=>{
        console.log(e);
    })
}
  render() {
    
    var columnwithDefsForConfigs = [      
        {
            headerName: "Vehicle Type",
            field: "vehicle_cap",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Min Capacity (CMT)",
            field: "min_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Capacity (CMT)",
            field: "max_capacity_cmt",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Min CMT per Consignee",
            field: "min_courier",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Maximum Nodes",
            field: "nodes_per_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Max Distance",
            field: "allowed_distance",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Deviation Distance (%)",
            field: "deviation_allowed",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        }
    ]

    var columnwithDefs = [  
        {
            headerName: "",
            field: "_id",
            width:50,
            filter: true,
            resizable: true,
            editable:false,
            cellRenderer: function(e){
                return '<i class="icofont icofont-map-pins f25"></i>'
            }

        },    
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster
            }
        },
        {
            headerName: "Transporter",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                if(params.data.transporter_name !=undefined && params.data.transporter_name !='')
                {
                    return params.data.transporter_name                   
                }
                else
                {
                    return "Not Assigned";
                }
            }
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:160,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transport Mode",
            field: "truck_capacity",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },	
        {
            headerName: "Distance (KM)",
            field: "tripDistance",
            width:180,
            filter: true,
            resizable: true,
            editable:false
        },
        
    ]

    var columnwithDefsForCouriers = [ 
        {
            headerName: "",
            field: "_id",
            width:50,
            filter: true,
            resizable: true,
            editable:false,
            cellRenderer: function(e){
                return '<i class="icofont icofont-map-pins f25"></i>'
            }

        },
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster
            }
        },
        {
            headerName: "Zone",
            field: "zone",
            width:80,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.zone
            }
        },
        {
            headerName: "Dealer Code",
            field: "dealerCode",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:200,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Transport Mode",
            field: "truck_capacity",
            width:250,
            filter: true,
            resizable: true,
            editable:false
        },
    ]

    var columnwithDefsForRecommended = [      
        {
            headerName: "Cluster",
            field: "cluster",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function(params){
                return params.data.cluster+" - "+params.data.zone
            }
        },
        {
            headerName: "No Of Orders",
            field: "total_consignments",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Cost",
            field: "tripCost",
            width:100,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Current Transport Mode",
            field: "truck_capacity",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Recommended Transport Mode",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "recommendation",
            width:130,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "prop_truck",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: NumericCellEditor,
            valueGetter: function(params){
                if(params.data.prop_truck == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.prop_truck
                }
            },
            valueSetter: function(params){
                //console.log(params)
                var newValue= params.newValue;
                var initial_load= params.data.tripVolume;
                if (parseFloat(newValue) > parseFloat(initial_load))
                {
                    return false;
                }
                else
                {
                    params.data.prop_truck = newValue;
                    var courier_cmt= Math.round((initial_load - newValue) * 100)/100;
                    
                    var cost_percmtcourier = params.data.courier_cost;
                    var courier_cost = courier_cmt * 212 * cost_percmtcourier;
                    var cost_percmt = params.data.proposed_truck_cost;
                    var truck_cost = newValue * cost_percmt;
                    var total_cost = courier_cost + truck_cost;

                    params.data.rec_courier = courier_cmt;
                    params.data.rec_courier_cost = Math.round(courier_cost * 100)/100;                   
                    params.data.rec_truck_cost = Math.round(truck_cost * 100)/100;
                    params.data.rec_total_cost = Math.round(total_cost * 100)/100;
                    return true;
                }
            }
        },
        {
            headerName: "Proposed Cost/CMT by Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "proposed_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Total CMT by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Cost/Kg by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Proposed Cost Truck",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_truck_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            // valueGetter: function(params){
            //     return 0;
            // }
            valueGetter: function(params){
                if(params.data.rec_truck_cost == undefined)
                {
                    return 0;       
                }
                else
                {
                    return params.data.rec_truck_cost
                }
            },
        },
        {
            headerName: "Proposed Cost by Courier",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_courier_cost",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Proposed Total Cost",
            headerClass:["cellstylegridB"],
            cellClass:"cellstylegridB",
            field: "rec_total_cost",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },
    ]

    var columnwithDefsForDropped = [     
        
        {
            headerName: "Dealer Code",
            field: "dealerCode",
            width:350,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Total Load (CMT)",
            field: "tripVolume",
            width:350,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Dropped Reasons",
            field: "dropped_reasons",
            width:420,
            filter: true,
            resizable: true,
            editable:false
        }
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Load Manager - {this.state.fileName}</span>

                    <a href="javascript:;" onClick={() => this.handleDownloadOutputFile(this.state.fileName)} style={{float:"right",marginRight:"10px"}} className="btn btn-danger"> Download Output File</a>

                    <a href={"/lmtruckavailability/"+window.btoa(this.state.transactionId)} style={{float:"right",marginRight:"10px"}} className="btn btn-info"> Truck Availability</a>
                </h5>
            </div>
            <div className="card-body col-xl-12 col-lg-12">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"config")} className={"lttabs "+(this.state.config)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"config")}>Configuration</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"summary")} className={"lttabs "+(this.state.summary)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"summary")}>Summary</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"trucks")} className={"lttabs "+(this.state.trucks)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"trucks")} href="">Planed Loads By Trucks</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"courier")} className={"lttabs "+(this.state.courier)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"courier")} href="">Planed Loads By Courier</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"recommendations")} className={"lttabs "+(this.state.recommendations)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"recommendations")} href="">Recommendations</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"dropped")} className={"lttabs "+(this.state.dropped)}>
                            <button type="button" className="btn" onClick={this.onClickTab.bind(this,"dropped")} href="">Loads Dropped</button>
                        </li>
                    </ul>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.configData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForConfigs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.configurationData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                <div className={"row mt-40p"+" "+this.state.summaryData}>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="column"
                            title={"Orders Planned For Load"}
                            subtitle={""}
                            context={this}
                            series={this.state.seriesData}
                            categories={this.state.categories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="column"
                            title={"Cost Summary"}
                            subtitle={""}
                            context={this}
                            series={this.state.costData}
                            categories={this.state.costCategories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div>
                            <LoadSummaryColumnChart
                            chartType="pie"
                            title={"Dropped Nodes Summary"}
                            subtitle={""}
                            context={this}
                            series={this.state.drpData}
                            categories={this.state.drpCategories}
                            seriesName=""
                            yaxistitle="Orders For Load Plan"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.trucksData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.clustersData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.courierData}>
                <button type="button" className="btn btn-info" onClick={this.onClickShowAllCouriers.bind(this)} style={{marginBottom:"1em"}}>View All Couriers</button>  
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForCouriers}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.couriersData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
                </div>
                
                <div class={this.state.recommendationsData}>
                    <LoadSummaryRecommondations recommData={this.state.recommData} transactionId={this.state.transactionId}/>
                </div>

                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.droppedData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefsForDropped}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.droppedPData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    />
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">{this.state.sliderTitle} <span className="ttlDuration"></span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>                
                <LoadSummaryDrawmap
                context={this} coords={this.state.latLngArray} loadingLocation={this.state.loadingLocation} stepcoords={1} mapType={this.state.mapType} mapFor={"load_management"}  />
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};