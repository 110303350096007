'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
import Showlmvalue from "./showlmvalue";
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmLoadShare extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions,
          Showlmvalue:Showlmvalue
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        trnsportersList : [],
        statesList : [],
        vehicleList: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        transdata:[],
        transactionId:"",
        pinnedRegionBottomRowData:[],
        zonesList:[],
        zoneStates:[],
        sliderType:1,
        zonewise:"activet",
        statewise:"",
        dataType:0,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.onLoadShowData = this.onLoadShowData.bind(this);
    this.createColumns = this.createColumns.bind(this);
    this.createRows = this.createRows.bind(this);
    this.onClickResetData = this.onClickResetData.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
 }

componentDidMount(){
    loadDateTimeScript();
    var transactionId = "";
    if(this.props.match.params.eventname !='default')
    {
        var transactionId = window.atob(this.props.match.params.eventname);
    }
    this.setState({
        transactionId : transactionId,
        dataType : 0
    });
    this.pageAccess();
}
async pageAccess(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var currentUrl = "/lmloadshare/default";
    console.log(currentUrl,"currentUrl")
    var is_dept_admin = localStorage.getItem("is_department_admin");
    var role = localStorage.getItem("roles");
    if(role.indexOf("lm_site_admin") >= 0 || is_dept_admin == 1 || role.indexOf("lm_admin") >= 0)
    {
        this.setState({
            pageAccess : 3,
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then(async (response2)=>{
            //console.log(response1.data,"LLPN");
            var lpData = response2.data;
            var lpNames = [];
            if(lpData.length > 0)
            {
                lpData.map((item) => {
                    if(item.comp_short_name != '' && item.comp_short_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.comp_short_name,
                        })
                    }
                })
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = lpData.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    var loading_port_code = lpData[0].loading_port_code;
                    comp_code = lpData[0].comp_code;
                }
                this.setState({
                    comp_code : comp_code,
                    loading_port_code : loading_port_code,
                    loadshow:'show-n',
                    overly:'show-n',
                    lpNames:lpNames
                });
                console.log(lpData[0].loading_port_code,"lpData[0].loading_port_code")
                await this.onLoadShowData();
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        var query = {
            "userid" : localStorage.getItem("userid"),
            "menu_url" : currentUrl,
        }
        redirectURL.post("/lmconsignees/checkUserCurrentPageAccess",query).then(async (response)=>{
            var result = response.data;
            if(result.length > 0)
            {                
                var pageAccess = 2;
                var lpNames = [];
                await result.map(async function(item){
                    if(item.loading_port_name != '' && item.loading_port_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.loading_port_name,
                        })
                    }
                })
                
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = result.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {                        
                        if(result1[0].access !='' && result1[0].access !=undefined)
                        {
                            var pageAccess = result1[0].access;                    
                        }
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    if(result[0].access !='' && result[0].access !=undefined)
                    {
                        var pageAccess = result[0].access;                    
                    }
                    var loading_port_code = result[0].loading_port_code;
                    comp_code = result[0].comp_code;
                }
                //console.log(lpNames,"lpNames")
                this.setState({
                    pageAccess : pageAccess,
                    loading_port_code : loading_port_code,
                    comp_code : comp_code,
                    lpNames : lpNames,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(loading_port_code,"loading_port_code")
                await this.onLoadShowData();
            }
            else
            {
                alert("You Don't have access to this screen, Please request access")
                window.location.href="/lmpageaccess";
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}
onLoadShowData = async() =>{
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    })
    var dataType = this.state.dataType;
    var loading_port_code = this.state.loading_port_code;
    var params = {
        loading_port_code : loading_port_code
    }
    console.log(dataType,"dataType")
    if(dataType == 0)
    {
        await redirectURL.post("/lmconsignees/getLoadShare",params).then((response)=>{
            //console.log(response.data);
            this.setState({
                transdata : response.data,
                dataType : dataType
            })
        }).catch((e)=>{
            console.log(e);
        })

        await redirectURL.post("/lmconsignees/getLmSobUniqueZones",params).then((response)=>{
            var statesList = response.data;
            this.setState({
                statesList : statesList,
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        await redirectURL.post("/lmconsignees/getStatesLoadShare",params).then((response)=>{
            console.log(response.data,"statewise");
            this.setState({
                transdata : response.data,
                dataType : dataType
            })
        }).catch((e)=>{
            console.log(e);
        })

        await redirectURL.post("/lmconsignees/getLmSobUniqueStates",params).then((response)=>{
            var statesList = response.data;
            this.setState({
                statesList : statesList,
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

    await redirectURL.post('/lmconsignees/prtUniqueTransportersListWithNames',params)
    .then((response) => {
        //console.log(response.data);
        var transporters = response.data;
        this.setState({
            trnsportersList:transporters,
        })
    })
    .catch(function(error){
        console.log(error)
    });

    await this.createColumns();
    await this.createRows();
} 
createColumns(){
   
    var transporters = this.state.trnsportersList;
    var states = this.state.statesList
    var columns = []
    columns.push({
        headerName:"Transporter Name",
        field:"transporter_name",
        width:300,
        resize:true,
        filter:true,
        editable:false
    })
    columns.push({
        headerName:"Transporter Code",
        field:"transporter_code",
        width:180,
        resize:true,
        filter:true,
        editable:false,
        cellStyle: {borderLeft: '1px solid #ccc'}
    })
    states.map((trans) => {
        //console.log("trans ", trans)
        var defs = {}
        states.map((ste) => {
          //  console.log("ste ", ste)
          
            var ss = trans
            defs.headerName = trans;
            defs.field = ss;
            var dataType = this.state.dataType;
            if(dataType == 0)
            {
                defs.width = 120;
            }
            else
            {
                defs.width = 150;
            }
            defs.editable = true;
            defs.cellStyle = {borderLeft: '1px solid #ccc'};
            defs.cellRendererSelector = function(params){
                    
                var rendComponent = {
                    component: 'Showlmvalue'
                };
                return rendComponent
            }
        })
        
        columns.push(defs)
    })
    this.setState({
        columnwithDefs:columns,
        overly:"show-n",
        loadshow:"show-n",
    })
}
createRows(){
   var statesList = this.state.statesList;
   var transporters = this.state.trnsportersList;
   var transdata =this.state.transdata;
    var rowdefs = []
    var totalCnt = 0
    transporters.map((ste) =>{
        var rows = {}
        rows.transporter_name=ste.transporter_name;
        rows.transporter_code=ste.transporter_code;
        var rowcnt = 0
        if(transdata.length > 0)
        {        
            var transportername = groupBy(transdata, rdata => rdata.transporter_code);
            transportername.forEach((trans, transkey) =>{
                trans.map((t) => {  
                    rows["zone"] = transkey;
                    if(t.transporter_code == ste.transporter_code)
                    {
                        // if(Number.isInteger(t.load)  == false)
                        // {
                        //     rows[t.zone] = t.load.toFixed(2);
                        // }
                        // else
                        // {
                            rows[t.zone] = t.load
                        //}
                       rowcnt = rowcnt+parseFloat(t.load)
                    }                   
                })
                
            })
        }
        var totalRowCnt = rowcnt;
        totalCnt = totalCnt+parseInt(rowcnt);
        if(Number.isInteger(totalRowCnt)  == false)
        {
            var totalRowCnt = rowcnt.toFixed(2);
        }
        rowdefs.push(rows)
    })
    var pinnedBottomRowData = {}
    var temp = {};
    if(rowdefs.length > 0)
    {
        var gridkeys = Object.keys(rowdefs[0]);
        gridkeys.map(function(e){
            temp[e] = 0
        })
    }
    else
    {
        var gridkeys = [];
    }
    var bhncnt = 0; 
    gridkeys = gridkeys.filter(function(e){return e != "transporter_name" && e !="transporter_code" && e !="zone"})
    rowdefs.map(function(e, index){
        gridkeys.map(function(k)
        {
            temp[k] = temp[k]+parseFloat(e[k]);
        })
    })
    temp['Total'] = totalCnt;
    temp['transporter_name'] = "Total";
    temp['transporter_code'] = "Total";
    // var zoneDetails = {};
    // zoneDetails.transporter_name = "";
    // zoneDetails.transporter_code = "";
    // statesList.map(function(s){
    //     zoneDetails[s] = "View States";
    // });
    // rowdefs.push(zoneDetails)
    this.setState({
        rowData:rowdefs,
        pinnedRegionBottomRowData:[temp],

    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'transporter_name',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    //console.log(e);
    if(e.value == "View States")
    {
        var zone = e.colDef.field;
        if(zone !=undefined && zone !='')
        {
            var zonesList = this.state.zonesList;
            var zoneStates = zonesList.filter(function(e){return e.zone == zone});
            console.log(zonesList,"zonesList")
            console.log(zoneStates,"zoneStates")
            $(".subH").html(zone+" States");
            this.setState({
                sliderType:2,
                zoneStates:zoneStates,
                overly :"show-m",
                bulkslide : "slider-translate"
            })
        }
    }
}

// onCellUpdateData = (params) => {
//     console.log("Params ", params)
//     var colData = params.data;
//     var objects = Object.keys(colData);
//     var rowTotal = 0;
//     objects.map(function(e){
//         if(parseInt(params.data[e]).toString() != "NaN")
//         {
//             rowTotal = parseInt(rowTotal)+parseInt(params.data[e]); 
//         }
//     })
//     objects.map(function(f,index){
//         if(f == "total")
//         {
//             var currentField = params.data.total;
//             console.log(currentField,index)
//             params.data[currentField] = rowTotal;
//         }
//     })
// }

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    zoneStates:[],
    sliderType:1,
    bulkslide:"",
    
    });
    
}
updateLmStates(params)
{
    //console.log(params);
    if(params.transporter_name !='' && params.transporter_name !=undefined && params.state !='' && params.state !=undefined && params.sob !='' && params.sob !=undefined)
    {
        redirectURL.post("/lmconsignees/savelmshareofbusiness",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty.",
            basicType : "warning",
            show : true
        })
    }
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{transporter_name:"",state:"",sob:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['id', 'transporter_name', 'state', 'sob']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    $(".subH").html("Bulk Upload");
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        zoneStates:[],
        sliderType:1,
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Transporter Name',
                inputName: 'transporter_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Transporter Code',
                inputName: 'transporter_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Zone',
                inputName: 'zone',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'State',
                inputName: 'state',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Load Share',
                inputName: 'sob',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },                   
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}
uploadBulkFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvData;
    var flag=0;
    console.log(fileData,"fileData");
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
   console.log("verifycsvdata ", vdata)
    if(vdata == true)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
    else{
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    loading_port_code : this.state.loading_port_code,
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/lmconsignees/savebulknewlmloadshare",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        overly:'show-n',
                    })
                    window.location.reload();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    }
};

onClickResetData = () => {
    console.log("clicked")
    var loading_port_code = this.state.loading_port_code;
    var reqParams = {
        loading_port_code : loading_port_code
    }
    redirectURL.post("/lmconsignees/resetLmLoadShare",reqParams).then((response)=>{
        var resp=response.data.message;
        console.log(resp)
        this.setState({
            basicTitle : resp,
            basicType : "success",
            show : true,
            bulkslide:'',
            overly:'show-n',
            loadshow:'show-n',
        })
        var dataType = this.state.dataType;
        this.onLoadShowData();
    }).catch((e)=>{
        console.log(e);
    })
}

syncData = () => {
    console.log("syncData")
    var loading_port_code = this.state.loading_port_code;
    var reqParams = {
        loading_port_code : loading_port_code
    }
    this.setState({
        overly:'show-m',
        loadshow:'show-m',
    });
    redirectURL.post("/lmconsignees/syncLmLoadShare",reqParams).then((response)=>{
        var resp=response.data.message;
        console.log(resp)
        this.setState({
            basicTitle : resp,
            basicType : "success",
            show : true,
            bulkslide:'',
            overly:'show-n',
            loadshow:'show-n',
        })
        var dataType = this.state.dataType;
        this.onLoadShowData();
    }).catch((e)=>{
        console.log(e);
    })
}

onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

updateGridData()
{
    var allrows = []
    this.gridApi.forEachNode((node, index) => {
        //console.log("node ",node)
        allrows.push(node.data)
    })
    //console.log("allrows ", allrows)
    var records = []
    if(allrows.length > 0)
    {
        allrows.forEach((item) => {
            //console.log("item ", item)
            var row = {}
            row.transporter_code = item.transporter_code
            row.transporter_name = item.transporter_name
            var zones = []
            for(var x in item)
            {
                //console.log("Item key ", x)
                if(x == "transporter_name" || x == "zone" || x == "transporter_code" || x == "total")
                {

                }
                else
                {
                    zones.push({
                        "zone":x,
                        "sob":parseFloat(item[x])
                    })
                }
            }
            row.zones = zones
            records.push(row)
        })
    }
    //console.log("records ",records)
    var loading_port_code = this.state.loading_port_code;
    var parameters = {
        records : records,
        loading_port_code : loading_port_code,
    }
    redirectURL.post("/lmconsignees/updatelmloadshare", parameters)
    .then((response) => {
        console.log(response);
            if(response.data.message == "success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                var dataType = this.state.dataType;
                this.onLoadShowData();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
    })
}

processRunSob(){
    var loadQry = {
        "transaction_id" : this.state.transactionId,
        "loading_port_code" : this.state.loading_port_code
    }
    redirectURL.post("/lmconsignees/generateLoadBySOB",loadQry).then((response)=>{
        console.log(response,"responseFromProcess")
        var statusCode = response.data.status;
        if(statusCode > 3)
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
                droppedList : response.data.droppedList,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
        else
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
                bulkslide:"",
            });
        }
        window.location.href="/lmloadsummary";
    });
}

async onClickTab(pageType){
    if(pageType == "zonewise")
    {
        var zonewise = "activet";
        var statewise = "";
        var dataType = 0;
    }
    else if(pageType == "statewise")
    {
        var zonewise = "";
        var statewise = "activet";
        var dataType = 1;
    }
    console.log(dataType,"dataType")
    await this.setState({
        zonewise:zonewise,
        statewise:statewise,
        dataType:dataType
    })
    await this.onLoadShowData();

}
onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        this.pageAccess();
    })
    .catch(function(e){
        console.log("Error ",e)
    })
} 
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Transporter",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.trnsportersList,
            },            
        },

        {
            headerName: "State",
            field: "state",
            width:290,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.statesList,
            },
        },         
        {
            headerName: "Share Of Business",
            field: "sob",
            width:100,
            filter: true,
            resizable: true,
            editable:true,
        },         
        {
            pinned:"left",
            headerName : "Update",
            field:"_id",
            width:150,
            resizable : true,
            editable : false,
            cellRendererSelector:function(params){       
                var rendComponent = {
                    component: 'UpdateButton'
                };
                return rendComponent;
            },
        },
        {
            pinned:"left",
            headerName: "id",
            field: "id",
            width:100,
            filter: true,
            resizable: true,
            editable:false,            
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>                
                    <span>Load Share</span>
                    {(this.state.transactionId !="" && this.state.transactionId !='') ? 
                        <button className="btn btn-success" style={{float:"right"}} onClick={this.processRunSob.bind(this)}>Load Process Run SOB</button>
                    : ""
                    }
                    {(this.state.pageAccess != 2) ?
                    <button className="btn btn-warning" style={{float:"right",marginRight:"10px"}} onClick={this.syncData.bind(this)}>Sync Data</button>
                    : ""}
                    {(this.state.pageAccess != 2) ?
                    <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                    : ""}
                    {(this.state.pageAccess != 2) ?
                    <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickResetData.bind(this)}>Reset</button>
                    : ""}
                </h5>
            </div>
            {(this.state.lpNames.length > 0) ? 
                <div className="row col-xl-12 col-lg-12" style={{marginTop:"2%"}}>
                    <div className="col-xl-12 col-lg-12">
                    {this.state.lpNames.length == 1 ?
                    <span style={{fontSize:"16px"}}>Loading Plant : {(this.state.lpNames[0].loading_port)}</span>
                    :
                    <span style={{fontSize:"16px"}}>Loading Plant : 
                        <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                            {(this.state.lpNames).map(function(obj){
                                return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                            })}                                           
                        </select>
                    </span>
                    }
                    </div>
                </div>
            :""}
            <div className="card-body col-xl-12 col-lg-12">
                <div className="f16" style={{marginBottom:"10px"}}>
                    <ul className="d-tabs">

                        <li onClick={this.onClickTab.bind(this,"zonewise")} className={"lttabs "+(this.state.zonewise)}>
                            <button type="button" className="btn">Zone Wise SOB</button>
                        </li>

                        <li onClick={this.onClickTab.bind(this,"statewise")} className={"lttabs "+(this.state.statewise)}>
                            <button type="button" className="btn">State Wise SOB</button>
                        </li>
                    </ul>
                </div>
                <div id="myGrid" style={{width:"100%",height:"500px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={this.state.columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                        gridOptions={{
                            context: { componentParent: this },
                            getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                                  return { 'font-weight': 'bold','font-size':'16px' };
                                }
                              },
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        onCellClicked={this.onCellClicked.bind(this)}
                        //onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                </div>
                {(this.state.dataType == 1 && this.state.pageAccess != 2) ?
                <div style={{float:"right",marginTop:"1%"}}>
                    <button type="button" onClick={this.updateGridData.bind(this)} className="btn btn-warning">Assign</button>
                </div>
                : ""}
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Bulk Upload</h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.sliderType == 2) ?
                <div className="container-fluid">
                    <div className="row">
                        <table className="table">
                            <tbody>
                                {this.state.zoneStates.map(function(zs){
                                return <tr>
                                    <td style={{padding:"5px",border:"none"}}>{zs.state}</td>
                                </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/sample_lmloadshare.csv')} target="_blank">Sample Template</a>
                    </div>	
                </div>
                }
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}