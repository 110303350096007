import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import datetimepicker from 'jquery-datetimepicker';
import Editrakeitem from "./editrakeitem";
import Deleterakeitem from "./deleterakeitem";
import ApproveButton from "./approvemsilbutton";
import Rakemapaction from "./rakemapaction";
import FullCalendar, { parseDragMeta } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import RakePlanCounter from "./rakeplanningcounter";

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class RakesScheduleComponent extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                editrakeitem:Editrakeitem,
                deleterakeitem:Deleterakeitem,
                rakemapaction:Rakemapaction,
                ApproveButton: ApproveButton
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Rake Schedule",
            rakeslist:[],
            loadingpoints:[],
            destinationpoints:[],
            transporters:[],
            transporter:'',
            arrival_Date:'',
            msilrakeno:'',
            msilactualrake:'',
            rakeEvents:[],
            gridview:'show-m',
            calcview:'show-m',
            selectedtransporter:'',
            selectedrake:'',
            selectedload:'',
            selecteddestination:'',
            transporterinfo:'',
            formtype:"add",
            rowitem:[],
            dactminDate:"",
            dactmaxDate:"",
            dminDate:"",
            dmaxDate:"",
            destinationloadcapacity:'270',
            sliderRouteTranslate:'',
            rakeitem:"",
            loadpoint:"",
            destinationpoint:"",
            statustext:"",
            calview:"timeGridDay",
            invoicedcnt:0,
            exposedinvoicedcnt:0,
            plannedcnt:0,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            maprakeno:"",
            maprakestart:"",
            maprakeend:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
            actual_rake_records : [],
            revisedCounter : [],
            msilrakenoexists:'',
            to_be_approved_list : [],
            sndloadinvoiceCounts:0,
            exportsCounts:0,
        };
        this.editGridRakeData = this.editGridRakeData.bind(this);
        this.editRakeData = this.editRakeData.bind(this);
        this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.applyFormAction = this.applyFormAction.bind(this);
        this.approveSuggestedMsilrake = this.approveSuggestedMsilrake.bind(this);
        this.rejectSuggestedMsilrake = this.rejectSuggestedMsilrake.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
	}
	
    componentDidMount(){
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        this.setState({
            gridview:'show-m',
            calcview:'show-n',
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        if(this.props.match.path == "/sndrakeschedule")
		{
			dpt='SNDG';
        }
        if(this.props.match.path == "/tnprakeschedule")
		{
			dpt='LOG-TNP';
        }
        this.setState({
			dept_code:dpt,
        });

        redirectURL.post("/consignments/alltransporters")
        .then((response) => {
            this.setState({
                transporters:response.data
            })
        })
        .catch(function(e){
            console.log("Error ", e)
        })


        redirectURL.post("/consignments/rakeloaddestpoints", {
			transportercode:localStorage.getItem("transportercode")
		})
		.then((resp) => {
			//console.log("Rakes ", resp.data)
			//console.log(JSON.parse(localStorage.getItem("transportercode")))
			
			
			var allrakes=[]
			try{
				var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
				//console.log(loggedusercode[0].trim())
				if(resp.data.rakes.length > 0)
				{
					resp.data.rakes.map((item) => {
						//console.log("item ",item)
						if(item.truck[0].transporter_code == loggedusercode[0])
						{
							//console.log("Item ", item)
							allrakes.push(item)
						}
					})
				}
			}
			catch(e){
				allrakes=resp.data.rakes
			}
			
			//console.log("allrakes ",allrakes)
			this.setState({
				rakeslist:allrakes,
				loadingpoints:resp.data.loadingpoints,
				destinationpoints:resp.data.destinationpoints
			});
        })
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadRakePlanningData(parameters)

	}
    
    onLoadRakePlanningData(parameters)
    {
        this.setState({
            overly:'show-m',
            loadshow:'show-m'
        })
        redirectURL.post("/consignments/replanningrakes", parameters)
        .then((response) => {
            console.log("response ", response.data)
            var records = response.data.records;
            var counters = response.data.counters;
            var actual_rake_records = response.data.actual_records;
            var sndloadinvoiceCounts = response.data.sndloadinvoiceCounts;
            var exportsCounts = response.data.exportsCounts;
            console.log("counters ",revisedCounter)
            var events = []
            var recordset = []
            var invoicedcnt = 0;
            var exposedinvoicedcnt = 0;
            var plannedcnt = 0;
            if(counters.length > 0)
            {
                counters.map((item) => {
                    
                    if(item.invoiced == 1)
                    {
                        if(item.transporter_code == 'T226')
                        {
                            exposedinvoicedcnt = parseInt(exposedinvoicedcnt)+parseInt(item.quantity)
                        }
                    }
                })
            }
            if(records.length > 0)
            {
                
                records.map((item) => {
                    if(item.loading_point.indexOf("Railyard") >= 0)
                    {
                        var loadng = item.loading_point.replace("Railyard","");
                    }
                    else{
                        var loadng = item.loading_point;
                    }
                    if(item.destination_point.indexOf("Railyard") >= 0)
                    {
                        var destination = item.destination_point.replace("Railyard","");
                    }
                    else{
                        var destination = item.destination_point;
                    }
                    if(item.status_text == "Completed")
                    {
                        var eventcolor = "#378006";
                        var ecustom = 'egcolor';
                    }
                    if(item.status_text == "Planning")
                    {
                        var eventcolor = "#A0522D";
                        var ecustom = 'ebcolor';
                    }
                    if(item.status_text == "Planning Yet to Start")
                    {
                        var eventcolor = "#0004FF";
                        var ecustom = 'eblcolor';
                    }
                    events.push({
                        title:item.transporter_code+"/"+loadng+"-"+destination,
                        date:item.arrival_Date,
                        rowid:item._id,
                        color:eventcolor,
                        className : ecustom,
                    })

                    //plannedcnt = parseInt(plannedcnt)+parseInt(item.load_capacity)
                    if(item.load_capacity != undefined)
                    {
                        plannedcnt = parseInt(plannedcnt)+parseInt(item.load_capacity)
                    }
                    //console.log('item.no_of_vins_invoiced ', item.no_of_vins_invoiced)
                    if(item.no_of_vins_invoiced != undefined)
                    {
                        invoicedcnt = parseInt(invoicedcnt)+parseInt(item.no_of_vins_invoiced)
                    }
                    
                    var totalplanned=0
                    var totalinvoiced = 0;
                    totalinvoiced = parseInt(totalinvoiced)+parseInt(item.no_of_vins_invoiced)
                    // if(item.loads.length > 0)
                    // {
                    //     item.loads.map((rec) => {
                    //         totalplanned = parseInt(totalplanned)+parseInt(rec.quantity)
                    //         if(rec.invoiced == 1)
                    //         {
                    //             totalinvoiced = parseInt(totalinvoiced)+parseInt(rec.quantity)
                    //             // if(rec.transporter_code == 'T226')
                    //             // {
                    //             //     exposedinvoicedcnt = parseInt(exposedinvoicedcnt)+parseInt(rec.quantity)
                                    
                    //             // }
                    //             // else{
                    //                 //invoicedcnt = parseInt(invoicedcnt)+parseInt(rec.quantity)   
                                   
                    //             // }
                    //         }
                            
                    //     })
                    // }
                    // else{
                    //     totalplanned = 0
                    //     totalinvoiced = 0
                    // }
                    var msilarrivaldate = ''
                    var msilarrivaldate1 = ''
                    var msilarrivaldate2 = ''
                    // if(item.msil_actual_arrival_date != undefined && item.msil_actual_arrival_date != "undefined" && item.msil_actual_arrival_date != "" && item.msil_actual_arrival_date != "Invalid date" )
                    // {
                    //     //console.log("S1")
                    //     //console.log("msil_actual_arrival_date ",typeof item.msil_actual_arrival_date);
                    //     msilarrivaldate = moment.parseZone(item.msil_actual_arrival_date).format("YYYY-MM-DD HH:mm:ss");
                    // }
                    // else{
                        //console.log("S2")
                       // console.log("TImestamp ", item.truck[0].timestamp);
                        var days = parseInt(item.distance_from_loading_yard/25);
                       // console.log("days ", days)
                        var hours = (item.distance_from_loading_yard/25);
                        var d = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
                        var dt = new Date(d)
                       // console.log("dt ", dt);

                       //console.log("hours ", parseInt(hours));
                        var sethour = dt.setHours(dt.getHours()+parseInt(hours))
                       //console.log("setHour ", sethour);
                        msilarrivaldate = moment.parseZone(new Date(sethour)).format("YYYY-MM-DD HH:mm:ss");
                        
                    //}

                    //console.log("msilarrivaldate ", msilarrivaldate)
                    //console.log("msilarrivaldate1 ", msilarrivaldate1)
                    //console.log("msilarrivaldate2 ", msilarrivaldate2)
                    
                    var isExists = actual_rake_records.filter(function(arr){
                        if(arr.truck.length)
                        {
                            return arr.truck[0].truck_no == item.actual_rake_no
                        }
                        
                    });
                    if(isExists.length)
                    {
                        var truckinfo = isExists[0].truck;
                        var curr_truck_info = item.truck;
                       // console.log(truckinfo,curr_truck_info);
                        if(curr_truck_info.length)
                        {
                            var curr_timestamp = item.truck[0].timestamp;
                            var timestamp = truckinfo[0].timestamp;
                            if(new Date(curr_timestamp).getTime() != new Date(timestamp).getTime())
                            {
                                var diff_timestamp = 1;
                            }
                            else{var diff_timestamp=0;}
                        }else{var diff_timestamp=0;}
                    
                    }
                    else
                    {
                        var truckinfo = item.truck
                        var diff_timestamp=0;
                    }
                    recordset.push({
                        _id:item._id,
                        arrival_Date:item.arrival_Date,
                        replanned_date : item.replanned_date,
                        suggested_msil_rake : item.suggested_msil_rake,
                        to_be_approved : item.to_be_approved,
                        rejected_msil_rake : item.rejected_msil_rake, 
                        create_date:item.create_date,
                        destination_point:item.destination_point,
                        distance_from_loading_yard:item.distance_from_loading_yard,
                        distance_from_destination_yard:item.distance_from_destination_yard,
                        load_capacity:item.load_capacity,
                        loading_point:item.loading_point,
                        msil_actual_dispatch_date:item.msil_actual_dispatch_date,
                        destination_yard_reached_on:item.destination_yard_reached_on,
                        loading_yard_distance_updated_on:item.loading_yard_distance_updated_on,
                        //loads:item.loads,
                        totalplanned:totalplanned,
                        totalinvoiced:totalinvoiced,
                        modified_date:item.modified_date,
                        msil_actual_arrival_date:msilarrivaldate,
                        msil_rake_no:item.msil_rake_no,
                        rakeno:item.rakeno,
                        quantity:item.quantity,
                        no_of_vins_loaded:item.no_of_vins_loaded,
                        no_of_vins_invoiced:item.no_of_vins_invoiced,
                        no_of_vins_planned:item.no_of_vins_planned,
                        status_text:item.status_text,
                        transporter_code:item.transporter_code,
                        transporter_name:item.transporter_name,
                        truck:truckinfo,
                        actual_rake_no : item.actual_rake_no,
                        actual_rake_loading_point : item.actual_rake_loading_point,
                        actual_rake_delivery_point : item.actual_rake_delivery_point,
                        diff_timestamp : diff_timestamp,
                        revisedCounter : revisedCounter,
                        load_attach_date : item.load_attach_date
                    })
                   
                });
            }
            var sdate = moment.parseZone(new Date(parameters.startdate +"  00:00:00"))._d;
            var edate = moment.parseZone(new Date(parameters.enddate +"  23:59:59"))._d;
            var revisedCounter = 0;
            recordset.map(function(e){
                if(e.replanned_date != "" && e.replanned_date != undefined)
                {
                    var replanneddate = moment.parseZone(new Date(e.replanned_date)).format("YYYY-MM-DD HH:MM:SS");
                    replanneddate = moment.parseZone(new Date(replanneddate))._d;
                    if(replanneddate.getTime() >= sdate.getTime() && replanneddate.getTime() <= edate.getTime())
                    {
                        revisedCounter = revisedCounter+Number(e.load_capacity);
                    }
                    else
                    {
                        console.log("null")
                    }
                }
                
            })

            // console.log("invoicedcnt ", invoicedcnt)
            // console.log("plannedcnt ", plannedcnt);
            var to_be_approved_list = recordset.filter(function(e){
                return e.to_be_approved == 1;
            })
            this.setState({
                rowData:recordset,
                actual_rake_records : actual_rake_records,
                overly:'show-n',
                loadshow:'show-n',
                rakeEvents:events,
                invoicedcnt:invoicedcnt,
                exposedinvoicedcnt:exposedinvoicedcnt,
                plannedcnt:plannedcnt,
                revisedCounter : revisedCounter,
                to_be_approved_list : to_be_approved_list,
                sndloadinvoiceCounts : sndloadinvoiceCounts,
                exportsCounts :  exportsCounts,
            })
        })
        .catch(function(e){
            console.log("Error ", e)
        })
    }
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		if(this.props.match.path == "/sndrakeschedule")
		{
			screenpage='Rake Schedules';
		}
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
    }
    approveSuggestedMsilrake = async (params)=>{
        redirectURL.post("/consignments/approverejectSuggestedMsilRake",{"rowdata": params,"type":"approve"}).then(()=>{
            this.setState({
                basicTitle : "Approved",
                basicType : "success",
                show : true
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    rejectSuggestedMsilrake = async (params)=>{
        redirectURL.post("/consignments/approverejectSuggestedMsilRake",{"rowdata": params,"type":"reject"}).then(()=>{
            this.setState({
                basicTitle : "Rejected",
                basicType : "success",
                show : true
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    editRakeData = async (params) =>{
        // console.log("Edit ", params)
        // console.log("Edit rowData ", this.state.rowData)
        
        if(params != '')
        {
            this.setState({
                overly:'show-m',
                loadshow:'show-m',
                statustext:params.status_text
            });
            var transp = '';
            await redirectURL.post("/consignments/transporterrakes",{
                transporter_code:[params.transporter_code]
              })
              .then((response) => {
                  this.setState({
                    rakeslist:response.data
                  })
              })
              await redirectURL.post("/consignments/transporteritem",{
                transportercode:[params.transporter_code]
              })
              .then((response) => {
                  var recc = response.data;
                  //console.log("recc ", recc)
                  transp = recc[0].firstname;
                  this.setState({
                    transporterinfo:recc[0].firstname
                  })
                  params.transporter_name=recc[0].firstname;
                  var allData = this.state.rowData;
                  var rdata = []
                  if(allData.length > 0)
                  {
                    allData.map((m) => {
                        if(m.loading_point.indexOf("Railyard") >= 0)
                        {
                            var loadng = m.loading_point;
                        }
                        else{
                            var loadng = m.loading_point;
                        }
                        if(m.destination_point.indexOf("Railyard") >= 0)
                        {
                            var destination = m.destination_point;
                        }
                        else{
                            var destination = m.destination_point;
                        }
                        
                        var title = m.transporter_code+"/"+loadng+"-"+destination
                        m.title = title;
                        if(m._id == params._id)
                        {
                            rdata.push(m);
                        }
                    })
                  }
                  //console.log("rdata ", rdata)
                  //console.log("transp",params)
                    this.setStateParams(rdata);
              })
            
            //this.onChangeTransporterItem(params.transporter_code)
        }
       
	
    }

    editGridRakeData = async (params) =>{
        // console.log("Edit ", params)
        // console.log("Edit rowData ", this.state.rowData)
        
        if(params != '')
        {
            this.setState({
                overly:'show-m',
                loadshow:'show-m',
                statustext:params.status_text
            });
            var transp = '';
            await redirectURL.post("/consignments/transporterrakes",{
                transporter_code:[params.transporter_code]
              })
              .then((response) => {
                  this.setState({
                    rakeslist:response.data
                  })
              })
              await redirectURL.post("/consignments/transporteritem",{
                transportercode:[params.transporter_code]
              })
              .then((response) => {
                  var recc = response.data;
                  //console.log("recc ", recc)
                  transp = recc[0].firstname;
                  this.setState({
                    transporterinfo:recc[0].firstname
                  })
                  params.transporter_name=recc[0].firstname;
                  var allData = this.state.rowData;
                  var rdata = []
                  if(allData.length > 0)
                  {
                    allData.map((m) => {
                        if(m.loading_point.indexOf("Railyard") >= 0)
                        {
                            var loadng = m.loading_point;
                        }
                        else{
                            var loadng = m.loading_point;
                        }
                        if(m.destination_point.indexOf("Railyard") >= 0)
                        {
                            var destination = m.destination_point;
                        }
                        else{
                            var destination = m.destination_point;
                        }
                        
                        var title = m.transporter_code+"/"+loadng+"-"+destination
                        m.title = title;
                        if(m._id == params._id)
                        {
                            rdata.push(m);
                        }
                    })
                  }
                  //console.log("rdata ", rdata)
                  //console.log("transp",params)
                    this.setGridStateParams(params);
              })
            
            //this.onChangeTransporterItem(params.transporter_code)
        }
       
	
    }


    deleteRakeData = async (params) =>{
        //console.log("Delete ", params)
        var confirm = await window.confirm("Are you sure you want to delete item?")
        if(confirm == true)
        {
            var reqparams = {
                rowid : params._id
            }
            await redirectURL.post("/consignments/deleteRakePlan", reqparams)
            .then((response) => {
                // console.log("Delete response ", response.data)
                if(response.data.deletedCount == 1)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Delete item successfully",
                        basicType:"success"
                    });
                }
            })
        }
       
	
    }

    setGridStateParams(params){
       // console.log("this.state.transporterinfo ", this.state.transporterinfo)
       if(params.arrival_Date == "" || params.arrival_Date == undefined || params.arrival_Date == "Invalid date")
       {
           var arrival_Date = "";
       }
       else
       {
         var arrival_Date = moment.parseZone(params.arrival_Date).format("YYYY-MM-DD");
       }

       if(params.msil_actual_arrival_date == "" || params.msil_actual_arrival_date == undefined || params.msil_actual_arrival_date == "Invalid date")
       {
            var msilactual = "";
       }
       else{
           var msilactual = moment.parseZone(params.msil_actual_arrival_date).format("YYYY-MM-DD")
       }
        this.setState({
            rowitem:params._id,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            transporter:{"value":params.transporter_code,"label":this.state.transporterinfo},
            rakeitem:{"value":params.rakeno,"label":params.rakeno},
            loadpoint:{"value":params.loading_point,"label":params.loading_point},
            destinationpoint:{"value":params.destination_point,"label":params.destination_point},
            destinationloadcapacity:params.load_capacity,
            msilrakeno:params.msil_rake_no,
            msilrakenoexists:params.msil_rake_no,
            arrival_Date:arrival_Date,
            //msilactualrake:msilactual,
            formtype:"edit",
            eventData:params,
           // open:true,
           // overly:'show-n',
            loadshow:'show-n'
        })
    }


    setStateParams(params){
        // console.log("this.state.transporterinfo ", this.state.transporterinfo)
        if(params.arrival_Date == "" || params.arrival_Date == undefined || params.arrival_Date == "Invalid date")
        {
            var arrival_Date = "";
        }
        else
        {
          var arrival_Date = moment.parseZone(params.arrival_Date).format("YYYY-MM-DD");
        }
 
        if(params.msil_actual_arrival_date == "" || params.msil_actual_arrival_date == undefined || params.msil_actual_arrival_date == "Invalid date")
        {
             var msilactual = "";
        }
        else{
            var msilactual = moment.parseZone(params.msil_actual_arrival_date).format("YYYY-MM-DD")
        }
         this.setState({
             rowitem:params._id,
            // sliderRakeTranslate:"slider-translate",
             //overly:'show-m',
             transporter:{"value":params.transporter_code,"label":this.state.transporterinfo},
             rakeitem:{"value":params.rakeno,"label":params.rakeno},
             loadpoint:{"value":params.loading_point,"label":params.loading_point},
             destinationpoint:{"value":params.destination_point,"label":params.destination_point},
             destinationloadcapacity:params.load_capacity,
             msilrakeno:params.msil_rake_no,
             arrival_Date:arrival_Date,
             //msilactualrake:msilactual,
             formtype:"edit",
             eventData:params,
             open:true,
             overly:'show-n',
             loadshow:'show-n'
         })
     }

    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
    formRakeHandler = async (event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Add or Edit Rake Planning",
			}
			googleAnalytics.logEvent(eventOptions);
        }
        
        var transcode='';
        var transname='';
        var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
        if(localStorage.getItem("user_type") != "Transporter")
        {
            // if(splitrole.indexOf("tpt_rail") == -1)
            // {
                if(Array.isArray(this.state.transporter.value))
                {
                    transcode = this.state.transporter.value[0]
                    transname = this.state.transporter.label
                }
                else
                {
                    transcode = this.state.transporter.value
                    transname = this.state.transporter.label
                }
                
            // }
        }
        else
        {
            transcode =splitrole[0]
        }
        if(transcode != '' && this.state.arrival_Date != ""  && this.state.arrival_Date != "NaN-NaN-NaN" && this.state.loadpoint != "" && this.state.destinationpoint != '')
        {
            if(this.state.msilrakeno != '')
            {
                if(this.state.statustext != "Completed")
                {
                    var statustext = "Planning";
                }
                else{
                    var statustext = "Completed";
                }
                
            }
            else{
                var statustext = this.state.statustext
            }
            if(statustext == "")
            {
               var statustxt = "Planning Yet to Start"
            }
            else{
                var statustxt = statustext
            }
            if(this.state.formtype == 'add')
            {
                if(this.state.msilrakeno != '')
                {
                    var checkparams = {
                        msil_rake_no:this.state.msilrakeno,
                        exists_msil_rake_no:""
                    }
                    redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                    .then((response) => {
                       // console.log("response ", response.data)
                        var records =response.data.records
                       // console.log("records ", records)
                       if(records.length > 0)
                        {
                            this.setState({
                                show1:true,
                                basicTitle1:"Already exists rake no",
                                basicType1:"info"
                            })
                        }
                        else
                        {
                            this.applyFormAction();
                        }
                         
                    })
                    .catch(function(e){
            
                    })
        
                }
                else
                {
                    this.applyFormAction();
                }
            }
            else
            {
                if(this.state.msilrakenoexists != '' && this.state.msilrakenoexists != this.state.msilrakeno)
                {
                    var checkparams = {
                        msil_rake_no:this.state.msilrakeno,
                        exists_msil_rake_no:this.state.msilrakenoexists
                    }
                    redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                    .then((response) => {
                        //console.log("Edit response ", response.data)
                
                        var records =response.data.records
                        console.log("records ", records)
                        if(records.length > 0)
                        {
                            this.setState({
                                show1:true,
                                basicTitle1:"Already exists rake no",
                                basicType1:"info",
                                msilrakeno:this.state.msilrakenoexists
                            })
                        }
                        else
                        {
                            this.applyFormAction();
                        }
                    })
                    .catch(function(e){
            
                    })
                }
                else
                {
                    if(this.state.msilrakeno != '')
                    {
                        var checkparams = {
                            msil_rake_no:this.state.msilrakeno,
                            exists_msil_rake_no:""
                        }
                        redirectURL.post("/consignments/checkmsilrakenoexists", checkparams)
                        .then((response) => {
                            //console.log("Edit response ", response.data)
                    
                            var records =response.data.records
                            console.log("records ", records)
                            if(records.length > 0)
                            {
                                this.setState({
                                    show1:true,
                                    basicTitle1:"Already exists rake no",
                                    basicType1:"info",
                                    msilrakeno:this.state.msilrakenoexists
                                })
                            }
                            else
                            {
                                this.applyFormAction();
                            }
                        })
                        .catch(function(e){
                
                        })
                    }
                    else{
                        this.applyFormAction()
                    }
                }
            }
            
        //     var reqparams = {
        //         transporter_code:transcode,
        //         transporter_name:transname,
        //         rakeno:this.state.rakeitem.value,
        //         loadpoint:this.state.loadpoint.value,
        //         destinationpoint:this.state.destinationpoint.value,
        //         arrival_Date:this.state.arrival_Date,
        //         msil_rake_no:this.state.msilrakeno,
        //         //msil_actual_rake:this.state.msilactualrake,
        //         load_capacity:this.state.destinationloadcapacity,
        //         status_text:statustxt
        //     }
        //    // console.log("reqparams ", reqparams)
        //    // console.log("this.state.formtype ", this.state.formtype)
        //     if(this.state.formtype == 'add')
        //     {
        //         var urlpath = "/consignments/storeRakeSchedule";
        //     }
        //     else
        //     {
        //         var urlpath = "/consignments/updateRakeSchedule";
        //         reqparams.rowid = this.state.rowitem;
        //     }
            // redirectURL.post(urlpath,reqparams)
            // .then((response) => {
            //     //console.log("Rake response.data ",response.data)
            //     if(response.data.status == "Success")
            //     {
            //         if(this.state.formtype == 'add')
            //         {
            //             var messageres = 'Successfully added.'
            //         }
            //         else{
            //             var messageres = 'Updated successfully.'
            //         }
            //         this.setState({
            //             show: true, 
            //             basicType:'success', 
            //             basicTitle:messageres,
            //             uploadDivWidth:'0%',
            //             sliderRakeTranslate:'',
            //             showDiv:'show-n',
            //             uploadFile:'',
            //             loadshow:'show-n',
            //             file:''
            //         })
                    
            //     }
            //     else
            //     {
            //         this.setState({
            //             sliderRakeTranslate:"",
            //             loadshow:"show-n",
            //             show: true, 
            //             basicType:'danger',
            //             basicType:"Failed to add" 
            //         })
            //         //window.location.reload();
            //     }
            // })
        }
        else{
            this.setState({
                show1: true, 
                basicType1:'danger', 
                basicTitle1:"Please enter mandatory fields.",
            })  
        }
		
        
    }

    applyFormAction(){
        var transcode='';
        var transname='';
        var userrols = localStorage.getItem("roles");
        var splitrole = userrols.split(",")
        if(localStorage.getItem("user_type") != "Transporter")
        {
            // if(splitrole.indexOf("tpt_rail") == -1)
            // {
                if(Array.isArray(this.state.transporter.value))
                {
                    transcode = this.state.transporter.value[0]
                    transname = this.state.transporter.label
                }
                else
                {
                    transcode = this.state.transporter.value
                    transname = this.state.transporter.label
                }
                
            // }
        }
        else
        {
            // transcode =splitrole[0]
            transcode =localStorage.getItem("transportercode")
            transname =localStorage.getItem("username")
        }
        if(transcode != '' && this.state.arrival_Date != ""  && this.state.arrival_Date != "NaN-NaN-NaN" && this.state.loadpoint != "" && this.state.destinationpoint != '')
        {
            if(this.state.msilrakeno != '')
            {
                if(this.state.statustext != "Completed")
                {
                    var statustext = "Planning";
                }
                else{
                    var statustext = "Completed";
                }
                
            }
            else{
                var statustext = this.state.statustext
            }
            if(statustext == "")
            {
                var statustxt = "Planning Yet to Start"
            }
            else{
                var statustxt = statustext
            }
           
            
            var reqparams = {
                transporter_code:transcode,
                transporter_name:transname,
                rakeno:this.state.rakeitem.value,
                loadpoint:this.state.loadpoint.value,
                destinationpoint:this.state.destinationpoint.value,
                arrival_Date:this.state.arrival_Date,
                msil_rake_no:this.state.msilrakeno,
                //msil_actual_rake:this.state.msilactualrake,
                load_capacity:this.state.destinationloadcapacity,
                status_text:statustxt
            }
            // console.log("reqparams ", reqparams)
            // console.log("this.state.formtype ", this.state.formtype)
            if(this.state.formtype == 'add')
            {
                var urlpath = "/consignments/storeRakeSchedule";
            }
            else
            {
                var urlpath = "/consignments/updateRakeSchedule";
                reqparams.rowid = this.state.rowitem;
            }
            redirectURL.post(urlpath,reqparams)
            .then((response) => {
                //console.log("Rake response.data ",response.data)
                if(response.data.status == "Success")
                {
                    if(this.state.formtype == 'add')
                    {
                        var messageres = 'Successfully added.'
                    }
                    else{
                        var messageres = 'Updated successfully.'
                    }
                    this.setState({
                        show: true, 
                        basicType:'success', 
                        basicTitle:messageres,
                        uploadDivWidth:'0%',
                        sliderRakeTranslate:'',
                        showDiv:'show-n',
                        uploadFile:'',
                        loadshow:'show-n',
                        file:''
                    })
                    
                }
                else
                {
                    this.setState({
                        sliderRakeTranslate:"",
                        loadshow:"show-n",
                        show: true, 
                        basicType:'danger',
                        basicType:"Failed to add" 
                    })
                    //window.location.reload();
                }
            })
        }
        else{
            this.setState({
                show1: true, 
                basicType1:'danger', 
                basicTitle1:"Please enter mandatory fields.",
            })  
        }
        
        
    
    }

    // updateRakeHandler = async (event) => {
    //     event.preventDefault()
    //     var transcode='';
    //     var transname='';
    //     var userrols = localStorage.getItem("roles");
	// 	var splitrole = userrols.split(",")
    //     if(localStorage.getItem("user_type") != "Transporter")
    //     {
    //         if(splitrole.indexOf("tpt_rail") == -1)
    //         {
    //             if(Array.isArray(this.state.transporter.value))
    //             {
    //                 transcode = this.state.transporter.value[0]
    //                 transname = this.state.transporter.label
    //             }
    //             else
    //             {
    //                 transcode = this.state.transporter.value
    //                 transname = this.state.transporter.label
    //             }
                
    //         }
    //     }
    //     else
    //     {
    //         transcode =splitrole[0]
    //     }
    //     if(transcode != '' && this.state.loadpoint != "" && this.state.destinationpoint != '')
    //     {
    //         var arrival_date = document.getElementById("arrival_Date").value;
    //         var dd = arrival_date.split(" ");
    //         var disdate = dd[0].split("-");
    //         var arrivaldate = disdate[2]+"-"+disdate[1]+"-"+disdate[0]+" "+dd[1];

    //         var ac_arrival_date = document.getElementById("msilactualrake").value;
    //        // console.log("ac_arrival_date ", ac_arrival_date)
    //         var acdd = ac_arrival_date.split(" ");
    //         var acdisdate = acdd[0].split("-");
    //         var actualarrivaldate = acdisdate[2]+"-"+acdisdate[1]+"-"+acdisdate[0]+" "+acdd[1];
            
    //         var reqparams = {
    //             transporter_code:this.state.transporter.value,
    //             rakeno:this.state.rakeitem.value,
    //             loadpoint:this.state.loadpoint.value,
    //             destinationpoint:this.state.destinationpoint.value,
    //             arrival_Date:arrivaldate,
    //             msil_rake_no:this.state.msilrakeno,
    //             msil_actual_rake:actualarrivaldate
    //         }
    //         //console.log("reqparams ", reqparams)
    //         redirectURL.post("/consignments/updateRakeSchedule",reqparams)
    //         .then((response) => {
    //             //console.log("Rake response.data ",response.data)
    //             if(response.data.ok == 1)
    //             {
    //                 this.setState({
    //                     show: true, 
    //                     basicType:'success', 
    //                     basicTitle:"Successfully Uploaded.",
    //                     uploadDivWidth:'0%',
    //                     sliderRakeTranslate:'',
    //                     showDiv:'show-n',
    //                     uploadFile:'',
    //                     loadshow:'show-n',
    //                     file:''
    //                 })
                    
    //             }
    //             else
    //             {
    //                 this.setState({
    //                     sliderRakeTranslate:"",
    //                     loadshow:"show-n"
    //                 })
    //                 //window.location.reload();
    //             }
    //         })
    //     }
        
    // }
    selecttransporters(){
		let items = [];    
		items.push({"value":"all","label":"All"})
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.firstname})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
    selectrakeoptins(){
		let items = [];    
		items.push({"value":"NMG","label":"NMG"})
		try{
			if(this.state.rakeslist.length > 0)
			{
				this.state.rakeslist.map((item) =>{
					items.push({"value":item.truck_no,"label":item.truck_no})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	selectloadingpoints(){
		let items = [];    
		try{
			if(this.state.loadingpoints.length > 0)
			{
				this.state.loadingpoints.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		return items;
	}
	selectdestinationpoints(){
		let items = [];    
		try{
			if(this.state.destinationpoints.length > 0)
			{
				this.state.destinationpoints.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
    }
    
    
    onChangeTransporterItem(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
          );
          
          redirectURL.post("/consignments/transporterrakes",{
            transporter_code:transporter.value
          })
          .then((response) => {
              this.setState({
                rakeslist:response.data
              })
          })
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeRakeItem(rakeitem){
		this.setState(
			{ rakeitem },
			() => console.log(`Consigner Option selected:`, this.state.rakeitem)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeLoadingPointItem(loadpoint){
		this.setState(
			{ loadpoint },
			() => console.log(`Consigner Option selected:`, this.state.loadpoint)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationPointItem(destinationpoint){
        if(destinationpoint.value == 'Changsari Railyard')
        {
            this.setState({
                destinationloadcapacity:'300'
            })
        }
        else
        {
            this.setState({
                destinationloadcapacity:'270'
            })
        }
		this.setState(
			{ destinationpoint },
			() => console.log(`Consigner Option selected:`, this.state.destinationpoint)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                    this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
	// dateValidation = (currentDate) => {
    //     //console.log("this.state.dmaxDate ", this.state.dminDate)
	// 	return currentDate.isAfter(moment(this.state.dminDate));
	// };

	// dateActualValidation = (currentDate) => {
	// 	return currentDate.isAfter(moment(this.state.dactminDate));
    // };
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	onClickShowMap = (params) =>{
    //    console.log("ads", params)
      // console.log("params.destination_yard_reached_on ", params.msil_actual_dispatch_date)
        try{

            if(params.msil_actual_dispatch_date == undefined || params.msil_actual_dispatch_date == "")
            {
                var fromdate = getHyphenYYYYMMDDHHMMSS(params.create_date);
            }
            else
            {
                var fromdate = getHyphenYYYYMMDDHHMMSS(params.msil_actual_dispatch_date);
            }
            if(params.destination_yard_reached_on == undefined || params.destination_yard_reached_on == "")
            {
                var todate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
            }
            else
            {
                var todate = getHyphenYYYYMMDDHHMMSS(params.destination_yard_reached_on);
            }
            var loading_point = params.loading_point;
            var destination_point = params.destination_point;
            if(params.actual_rake_no != undefined && params.actual_rake_no != "")
            {
                var rake = params.actual_rake_no;
            }
            else
            {
                var rake = params.rakeno;
            }
            if(rake != '' && rake != null && rake != undefined)
            {
                var parameters = {
                    rake_no : rake,
                    from_date:fromdate,
                    to_date:todate,
                    loading_point:loading_point,
                    destination_point:destination_point
                }
                //console.log("parameters ", parameters)
                redirectURL.post("/consignments/rakemaproute", parameters)
                .then((response) => {
                    //console.log("response ", response.data)
                    var records = response.data;
                    if(records.status == "failure")
                    {
                        this.setState({
                            show: true,
                            basicTitle:"No data found",
                            basicType:"danger"
                        });
                    }
                    else
                    {
                        this.setState({
                            mapinfo:records,
                            maprakeno:rake,
                            // maprakestart:records.route_details.stime,
                            // maprakeend:records.route_details.etime,
                            sliderRouteTranslate:"slider-translate-75p",
                            show: false,
                            overly:'show-m'
                        });
                    }
                    
                })
                .catch(function(e){
                    console.log("Error ",e);
                })
            }
            else
            {
                this.setState({
                    show1: true,
                    basicTitle1:"Rake no has not assigned",
                    basicType1:"danger",
                    overly:'show-m'
                });
            }
            
            // var records = {
            //     route:[{
            //         lat:params.truck[0].latitude,
            //         lng:params.truck[0].longitude
            //     }],
            //     rakeno:params.rakeno,
            //     timestamp:params.truck[0].timestamp
            // }
            // this.setState({
            //     mapinfo:records,
            //     sliderRouteTranslate:"slider-translate-75p",
            //     show: false,
            //     overly:'show-m'
            // });
        }
        catch(e){
            this.setState({
                show:true,
                basicTitle:"No route found",
                basicType: "info"
            });
        }
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'loading_yard',
					inputName: 'loading_yard',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'destination_yard',
					inputName: 'destination_yard',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'planned_departure_date',
					inputName: 'planned_departure_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'load_capacity',
					inputName: 'load_capacity',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'rake_no',
					inputName: 'rake_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'msil_rake_no',
				// 	inputName: 'msil_rake_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
                // },
                
				// { 
				// 	name: 'status_text',
				// 	inputName: 'status_text',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// }
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formBulkUpload = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        console.log("csvcontent ", this.state.csvcontent);
        var reqparams = {
            csvcontent:this.state.csvcontent
        }
        redirectURL.post("/consignments/bulkuploadrake", reqparams)
        .then((response) => {
            this.setState({
                sliderBulkRakeTranslate:"",
                overly:'show-n',
                transporter_code:"",
                transporter_name:"",
                csvcontent:[],
                loadshow:'show-n',
                uploadFile:'',
                file:'',
                show:true,
                basicTitle:"Successfully upload file",
                basicType:"success"
            })
        })
        .catch(function(e){
            console.log("Error ", e)
        })
		
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
     
    render(){

        	
		const modalStyles  = {
			width:'1300px !important',
		}
        const { open } = this.state;
        
        var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
        var columnwithDefs  = [
            
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRenderer:'rakemapaction',
                resizable: true
                
            },
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRenderer:'editrakeitem',
                resizable: true,
                hide: false
            },
            {
                headerName: "",
                field: "_id",
                width: 50,
                cellRenderer:'deleterakeitem',
                resizable: true,
                hide: true                
            },
            {
                headerName : "Planned",
                headerClass:["cellstylegridB","cellTopHeader"],
                children : [
                    {
                        headerName:"Rake No",
                        field:"rakeno",
                        width:100,
                        headerClass : "cellstylegridB",
                        cellClass : "cellstylegridB"
                    },
                    {
                        headerName:"Loading Yard",
                        field:"loading_point",
                        width:150,
                        headerClass : "cellstylegridB",
                        cellClass : "cellstylegridB"
                    },
                    {
                        headerName:"Destination Yard",
                        field:"destination_point",
                        width:150,
                        headerClass : "cellstylegridB",
                        cellClass : "cellstylegridB"
                    },
                ]
            },
            {
                headerName : "Actual",
                headerClass:["cellstylegridG","cellTopHeader"],
                children : [
                    {
                        headerName:"Actual Rake No",
                        field:"actual_rake_no",
                        width:100,
                        headerClass : "cellstylegridG",
                        // cellClass : "cellstylegridG",
                        cellClass : function(params)
                        {
                            if(params.data.actual_rake_no != undefined && params.data.actual_rake_no != "")
                            {
                                if(params.data.rakeno != params.data.actual_rake_no)
                                {
                                    return "cellStyleRed";
                                }
                                else{
                                    return "cellstylegridG";
                                }
                                
                            }
                            
                        }
                    },
                    {
                        headerName:"Actual Loading Yard",
                        field:"actual_rake_loading_point",
                        width:150,
                        headerClass : "cellstylegridG",
                        cellClass : function(params){
                            if(params.data.actual_rake_loading_point != undefined && params.data.actual_rake_loading_point != "")
                            {
                                if(params.data.actual_rake_loading_point != params.data.loading_point)
                                {
                                    return "cellStyleRed";
                                }
                                else{
                                    return "cellstylegridG";
                                }
                            }
                            
                        }
                    },
                    {
                        headerName:"Actual Destination Yard",
                        field:"actual_rake_delivery_point",
                        width:150,
                        headerClass : "cellstylegridG",
                        cellClass : function(params)
                        {
                            if(params.data.actual_rake_delivery_point != undefined && params.data.actual_rake_delivery_point != "")
                            {
                                if(params.data.destination_point != params.data.actual_rake_delivery_point)
                                {
                                    return "cellStyleRed";
                                }
                                else{
                                    return "cellstylegridG";
                                }
                                
                            }
                            
                        }
                    },
                ]
            },
            {
                headerName:"MSIL Rake No",
                field:"msil_rake_no",
                width:100
            },
            {
                headerName:"Suggested MSIL Rake No",
                field:"suggested_msil_rake",
                width:140
            },
            {
                headerName : "Approve / Reject",
                field : "",
                cellRenderer : "ApproveButton"
            },
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                width:100
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:150
            },
            
            {
                headerName:"Load Capacity",
                field:"load_capacity",
                width:100
            },

            {
                headerName:"Last Packet Time",
                field:"truck",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.truck != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp)
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                filter : true
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
            {
                headerName:"Planning Start Time",
                field:"load_attachment_date",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.load_attach_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.load_attach_date)
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                filter : true
            },
            {
                headerName:"Distance from Loading Yard (KM)",
                field:"distance_from_loading_yard",
                width:160,
                valueGetter:function(params)
                {
                    try{
                        if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "Invalid date")
                        {
                            return "";
                        }
                        else
                        {
                            if(params.data.status_text == "Completed")
                            {
                                return "";
                            }
                            else
                            {
                                return params.data.distance_from_loading_yard;
                            }

                        }
                    }
                    catch(e){
                        return "";
                    }
                    
                }
            },

            {
                headerName:"Planned Departure Date",
                field:"arrival_Date",
                width:180,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYY(params.data.arrival_Date)
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator1,
                filter : true
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             else{
                //                 var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             // console.log(cellValue,filterValue);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName:"ETA",
                field:"replanned_date",
                width:180,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYY(params.data.replanned_date)
                },
                comparator: dateComparator1,
                filter : true,
                cellClass : function(params){
                    if(params.data.arrival_Date != undefined && params.data.arrival_Date != "" && params.data.replanned_date != undefined && params.data.replanned_date != "")
                    {
                        var planned = new Date(getHyphenYYYYMMDD(params.data.arrival_Date)).getTime();
                        var eta = new Date(getHyphenYYYYMMDD(params.data.replanned_date)).getTime();
                        console.log(eta,planned)
                        console.log(new Date(getHyphenYYYYMMDD(params.data.arrival_Date)),new Date(getHyphenYYYYMMDD(params.data.replanned_date)))

                        if(eta > planned)
                        {
                            return "cellStyleRed";
                        }
                        else if(eta < planned)
                        {
                            return "brd-txt3";
                        }
                        else
                        {
                            return ""
                        }
                    }
                }
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             else{
                //                 var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             // console.log(cellValue,filterValue);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName:"ATD",
                field:"msil_actual_dispatch_date",
                width:160,
                // cellClass:function(params){
                //     let hourDifference = 0;
                //     let revisedDateOnlyMoment = 0
                //     let expectedDateOnlyMoment  = 0
                //     try{
                //         if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "Invalid date")
                //         {
                //             var msil_actual_dispatch_date = moment.parseZone(params.data.msil_actual_dispatch_date).format("YYYY-MM-DD")
                //             revisedDateOnlyMoment  = moment(msil_actual_dispatch_date);
                //         }
                //         else{
                //             if(params.data.msil_actual_arrival_date != undefined && params.data.msil_actual_arrival_date != "Invalid date")
                //             {
                //                 var msil_actual_arrival_date = moment.parseZone(params.data.msil_actual_arrival_date).format("YYYY-MM-DD");
                //                 revisedDateOnlyMoment  = moment(msil_actual_arrival_date);
                //             }
                //         }
                //         if (params.data.arrival_Date != "") {
                //             var arrival_Date = moment.parseZone(params.data.arrival_Date).format("YYYY-MM-DD");
                //             expectedDateOnlyMoment  = moment(arrival_Date);
                //         }
                //         if(revisedDateOnlyMoment > 0 && expectedDateOnlyMoment > 0)
                //         {
                //             if(revisedDateOnlyMoment > expectedDateOnlyMoment)
                //             {
                //                 return 'bgColorDangerMedium';
                //             }
        
                //             if(revisedDateOnlyMoment < expectedDateOnlyMoment)
                //             {
                //                 return 'bgColorSuccessMedium';
                //             }
                //         }
                //         else{
                //             return '';
                //         }
                //     }
                //     catch(e){
                //         return ''
                //     }
                   
                // },
                cellClass : function(params){
                    if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "" && params.data.arrival_Date != undefined && params.data.arrival_Date != "")
                    {
                        var planned = new Date(getHyphenYYYYMMDD(params.data.arrival_Date)).getTime();
                        var atd = new Date(getHyphenYYYYMMDD(params.data.msil_actual_dispatch_date)).getTime();
                        console.log(atd,planned)
                        console.log(new Date(getHyphenYYYYMMDD(params.data.msil_actual_dispatch_date)),new Date(getHyphenYYYYMMDD(params.data.replanned_date)))
                        if(atd > planned)
                        {
                            return "cellStyleRed";
                        }
                        else if(atd < planned)
                        {
                            return "brd-txt3";
                        }
                        else
                        {
                            return ""
                        }
                    }
                },
                valueGetter:function(params){
                    if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "Invalid date")
                    {

                        return getHyphenDDMMMYYYY(params.data.msil_actual_dispatch_date)
                    }
                    else{
                        if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "Invalid date")
                        {
                            return getHyphenDDMMMYYYY(params.data.msil_actual_dispatch_date);
                            
                        }
                        else
                        {
                            return ""
                        }
                      
                    }
                },
                comparator: dateComparator1,
                filter : true
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             else{
                //                 var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             // console.log(cellValue,filterValue);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },

            // {
            //     headerName:"Actual Departure Date",
            //     field:"destination_yard_reached_on",
            //     width:160,
            //     valueGetter:function(params){
            //         if(params.data.msil_actual_dispatch_date != undefined && params.data.msil_actual_dispatch_date != "Invalid date")
            //         {

            //             return getHyphenDDMMMYYYY(params.data.msil_actual_dispatch_date)
            //         }
            //         else{
            //             return ""
            //         }
            //     }
            // },

            {
                headerName:"Distance to Destination Yard (KM)",
                field:"distance_from_destination_yard",
                width:170,
                valueGetter : function(params){
                    if(params.data.destination_yard_reached_on == undefined)
                    {
                        return params.data.distance_from_destination_yard;
                    }
                    else{
                        return "";
                    }
                }
            },
            
            {
                headerName : "Auto Assigned",
                field : "suggested_msil_rake",
                width : 100,
                valueGetter : function(params)
                {
                    if(params.data.suggested_msil_rake != undefined && params.data.suggested_msil_rake != "")
                    {
                        if(params.data.suggested_msil_rake == params.data.msil_rake_no)
                        {
                            return "Yes";
                        }
                        else
                        {
                            return "No";
                        }
                    }  
                    
                }
            },
            
            {
                headerName:"Status",
                field:"status_text",
                width:200,
               
            },
            {
                headerName:"Total Planned",
                field:"no_of_vins_planned",
                width:150,
               
            },
            {
                headerName:"Total Invoiced",
                field:"no_of_vins_invoiced",
                width:150,
               
            },
            {
                headerName:"Total VINs Loaded",
                field:"no_of_vins_loaded",
                width:150
            },
            {
                headerName:"Destination yard Reached On",
                field:"destination_yard_reached_on",
                width:160,
                valueGetter:function(params){
                    if(params.data.destination_yard_reached_on != undefined && params.data.destination_yard_reached_on != "Invalid date")
                    {

                        return getHyphenDDMMMYYYY(params.data.destination_yard_reached_on)
                    }
                    else{
                        return ""
                    }
                },
                comparator: dateComparator1,
                filter : true,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             else{
                //                 var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             // console.log(cellValue,filterValue);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
        ]

    const { transporter } = this.state;
    const { rakeitem } = this.state;
    const { loadpoint } = this.state;
    const { destinationpoint } = this.state;
    const { calview } = this.state;
    const events = [
        {
            start: '2015-07-20',
            end: '2015-07-02',
            eventClasses: 'optionalEvent',
            title: 'test event',
            description: 'This is a test description of an event',
        },
        {
            start: '2015-07-19',
            end: '2015-07-25',
            title: 'test event',
            description: 'This is a test description of an event',
            data: 'you can add what ever random data you may want to use later',
        },
    ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    <div className="row col-xl-12 col-lg-12">
                        <ul className="col-xl-6 col-lg-6 viewcs">
                            
                            <li>
                                <button onClick={this.onClickShowView.bind(this,"grid")} className="btn btn-success">Grid View</button>
                            </li>
                            <li>
                                <button onClick={this.onClickShowView.bind(this,"calender")} className="btn btn-danger">Calender View</button>
                            </li>
                        </ul>

                        <div className="col-xl-6 col-lg-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                    
                                </div>

                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" className="btn btn-success" onClick={this.onClickFilter.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={"col-xl-12 col-lg-12 "}>
                        <RakePlanCounter
                        invoiced={this.state.invoicedcnt}
                        exposedinvoicedcnt={this.state.exposedinvoicedcnt}
                        planned={this.state.plannedcnt}
                        revisedCounter={this.state.revisedCounter}
                        tobeapprovedcounter={this.state.to_be_approved_list.length}
                        sndloadinvoiceCounts={this.state.sndloadinvoiceCounts}
                        exportsCounts={this.state.exportsCounts}
                        onClickCounterShowData = {this.onClickCounterShowData}
                        />
                    </div>
            		<div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Rake Schedules  </span>
				       						
                                    {/* <button className="float-right f14 custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{marginRight:"5px"}}>
                                        Add Rake Schedule
                                    </button> */}

                                    {/* <button className="float-right f14 white custom-btn btn-info" onClick={this.onClickUploadRakePlanning.bind(this)} style={{marginRight:"5px"}}>
                                        Upload Rake Schedule
                                    </button> */}
                                    <button className={"float-right custom-btn white btn-danger f14 "+(this.state.gridview)} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className={"float-right custom-btn white btn-info f14 "+(this.state.gridview)} style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>				
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "+(this.state.gridview)}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        />

                                    </div>
                                </div>
                                <div style={{ minHeight: "580px",marginTop:"0px"}} className={"row col-xl-12 col-lg-12 "+(this.state.calcview)}>
                                    <div className="col-xl-12 col-lg-12" style={{textAlign:"right",marginBottom:"5px"}}>
                                            <span className="circle cgreen">&nbsp;</span> Completed&nbsp;&nbsp;
                                            <span className="circle cbrown">&nbsp;</span> Planning&nbsp;&nbsp;
                                            <span className="circle cblue">&nbsp;</span> Planning Yet To Start&nbsp;&nbsp;
                                    </div>
                                    <FullCalendar
                                        defaultView={this.state.calview}
                                        header={{
                                            left: "prev,next",
                                            center: "title",
                                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                                        }}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        weekends={true}
                                        editable={true}
                                        eventDrop={this.handleEventDrop}
                                        eventClick={this.handleEventClick}
                                        events={this.state.rakeEvents}
                                    />
                                </div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeHandler.bind(this)}>
                            {(localStorage.getItem("user_type") != "Transporter")?
                                
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Select Transporter *</label>
                                    
                                    <Select 
                                    placeholder={"Select Transporter"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeTransporterItem.bind(this)} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={transporter}
                                    options={this.selecttransporters()} />  
                                </div>
                               
                            :""}
                            
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
								
								<Select 
								placeholder={"Select Loading Yard"}
								closeMenuOnSelect={true}
								onChange={this.onChangeLoadingPointItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={loadpoint}
								options={this.selectloadingpoints()} />  
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
								
								<Select 
								placeholder={"Select Destination Yard"}
								closeMenuOnSelect={true}
								onChange={this.onChangeDestinationPointItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={destinationpoint}
								options={this.selectdestinationpoints()} />  
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Load Capacity</label>
                                    {/* <span className="form-control col-xl-12 col-lg-12 border-radius-0 ml-15p">{this.state.destinationloadcapacity}</span> */}
                                    <input type="text" placeholder="Load Capacity " id="destinationloadcapacity" name="destinationloadcapacity" value={this.state.destinationloadcapacity} className="form-control ml-15p" onChange={this.changeHandler} />
							
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Planned Departure Date *</label>
								
                                {/* <input type="text" placeholder="Expected Arrival Date" id="arrival_Date" name="arrival_Date" value={this.state.arrival_Date} className="datetimepicker_mask form-control" required /> */}
                                <Datetime 
                                    value={this.state.arrival_Date} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Planned Departure Date', name: 'arrival_Date', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"
                                    name="arrival_Date" 
                                    onChange={this.handlerArrivalDate.bind(this)}
                                    className="col-xl-12 col-lg-12"
                                    //isValidDate={this.dateValidation.bind(this)}
								/>
							</div>
                            	
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Rake</label>
								
								<Select 
								placeholder={"Select Rake"}
								closeMenuOnSelect={true}
								onChange={this.onChangeRakeItem.bind(this)} 
								className={"col-xl-12 col-lg-12 border-radius-0"}
								style={{borderRadius:"0px"}}
                                value={rakeitem}
								options={this.selectrakeoptins()} />  
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">MSIL Rake No</label>
								
                                <input type="text" placeholder="Rake No " id="msilrakeno" name="msilrakeno" value={this.state.msilrakeno} className="form-control ml-15p" onChange={this.changeHandler} />
							
							</div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">ETA/ATA Arrival Date</label>
							    <Datetime 
                                    value={this.state.msilactualrake} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'ETA/ATA Arrival Date', name: 'msilactualrake', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"
                                    name="msilactualrake" 
                                    onChange={this.handlerActualArrivalDate.bind(this)}
                                    className="col-xl-12 col-lg-12"
                                   // isValidDate={this.dateActualValidation.bind(this)}
								/>
                            </div> */}

							<div className="col-xl-12 col-lg-12 row form-group">
                                <div className="col-xl-12 col-lg-12">
                                    <label className="col-xl-12 col-lg-12">Is Completed?</label>
                                    <div className="col-xl-12 col-lg-12">
                                        <input onChange={this.changeHandler} name="statustext" checked={this.state.statustext == 'Completed'} type="radio" className="" value="Completed" />Yes
                                        &nbsp;&nbsp;<input onChange={this.changeHandler} name="statustext" checked={this.state.statustext != 'Completed'} type="radio" className="" value="Planning Yet to Start" />No
                                    </div>
                                </div>
                            </div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<DrawMap mapFor={"rakeschedule"} mapinfo={this.state.mapinfo} />
								{(this.state.mapinfo != '')?
								<div className="col-xl-12 col-lg-12">
									<div className="crm-numbers pb-0">
										<h3 className="subH">Route Details</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Rake No</label>
												<div>{this.state.maprakeno}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '' && typeof this.state.mapinfo.route_details.start_time != undefined  && this.state.mapinfo.route_details.start_time != "undefined")?getHyphenDDMMMYYYYHHMM(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '' && typeof this.state.mapinfo.route_details.end_time != undefined && this.state.mapinfo.route_details.end_time != "undefined")?getHyphenDDMMMYYYYHHMM(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
										</div>
									</div>

								</div>
								:""}
								
							</div>
						</div>
					</div>
				:""	
				}

                <div className={"slide-r "+(this.state.sliderBulkRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Upload Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpload.bind(this)}>
							
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rake_planning_bulk_upload_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<p style={{color:"#ff0000",fontSize:"12px;"}}>
                                    Note: Loading / Destination Yards: 1="Farukh Nagar Railyard", 2="Detroj Railyard", 3="Sanand Railyard", 
                                        4="Nidavanda Railyard", 5="Shankarpally Railyard", 6="DLIB Railyard", 7="Changsari Railyard",
                                        8="Melpakkam Railyard", 9="Kalamboli Railyard",10="Jirania Railyard", 11="Indore Railyard", 
                                        12="Durgapur Railyard", 13="Rangapani Railyard", 14="Kathuwas Railyard", 15="Patli Railyard", 
                                        16="Gurgaon Railyard", 17="Bawal Railyard"
                                </p>
							</div>
                        </div>
					</div>
				</div>
                
                    <Modal open={open} onClose={this.onCloseModal} styles={modalStyles}>
                           
                        {(this.state.eventData.length >0)?
                        <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                            
                            <div className="col-xl-12 col-lg-12" style={{marginLeft:"22px"}}>
                                {this.state.eventData.map((item) => 
                                    <div className="row col-xl-12 col-lg-12">
                                        <div className=" evt-htitle">{item.title}</div>
                                        
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group bt br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label ">Rake No</label>
                                            <div className=" col-xl-12 evt-strng">{item.rakeno}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group bt br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Transporter Code</label><br />
                                            <div className=" col-xl-12 evt-strng">{item.transporter_code}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Transporter Name</label><br />
                                            <div className=" col-xl-12 evt-strng">{item.transporter_name}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Loading yard</label><br />
                                            <div className=" col-xl-12 evt-strng">{item.loading_point}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Destination Yard</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.destination_point}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Load Capacity</label><br />
                                            <div className=" col-xl-12 evt-strng">{item.load_capacity}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Last Packet Time</label><br />
                                            <div className=" col-xl-12 evt-strng">{
                                                (item.truck != undefined && item.truck.length > 0)?
                                                    getHyphenDDMMMYYYYHHMM(item.truck[0].timestamp)
                                                :"NA"
                                            }</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Distance from Loading Yard (KM)</label><br />
                                            <div className=" col-xl-12 evt-strng">{
                                                 (item.msil_actual_dispatch_date != undefined && item.msil_actual_dispatch_date != "Invalid date" && item.msil_actual_dispatch_date != "1970-01-01T00:00:00.000Z")?""
                                                 :
                                                    (item.status_text == "Completed")?"-"
                                                 
                                                    :item.distance_from_loading_yard
                                            }</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Planned Departure Date</label><br />
                                            <div className=" col-xl-12 evt-strng">{getHyphenDDMMMYYYY(item.arrival_Date)}</div>
                                        </div>
                                       <div className="col-xl-6 textAlignCenter n-p-0 form-group  br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">ETA/ATA Date</label><br />
                                            <div className=" col-xl-12 evt-strng"> {
                                                 (item.msil_actual_dispatch_date != undefined && item.msil_actual_dispatch_date != "Invalid date" && item.msil_actual_dispatch_date != "1970-01-01T00:00:00.000Z")?
                                                    getHyphenDDMMMYYYY(item.msil_actual_dispatch_date)
                                                 :
                                                    (item.msil_actual_arrival_date != undefined && item.msil_actual_arrival_date != 'Invalid date' && item.msil_actual_arrival_date != "1970-01-01T00:00:00.000Z")?
                                                        getHyphenDDMMMYYYY(item.msil_actual_arrival_date)
                                                    :"NA"
                                            }</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Distance to Destination Yard (KM)</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.distance_from_destination_yard}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">MSIL Rake No</label><br />
                                            <div className=" col-xl-12 evt-strng">{item.msil_rake_no}</div>
                                        </div>
                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Status</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.status_text}</div>
                                        </div>

                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Total Cars Planned</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.totalplanned}</div>
                                        </div>

                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Total Cars Invoiced</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.totalinvoiced}</div>
                                        </div>

                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Total cars Loaded</label><br />
                                            <div className=" col-xl-12 evt-strng"> {item.no_of_vins_loaded}</div>
                                        </div>

                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Destination yard Reached On</label><br />
                                            <div className=" col-xl-12 evt-strng"> 
                                                {
                                                    (item.destination_yard_reached_on != undefined && item.destination_yard_reached_on != "Invalid date" && item.destination_yard_reached_on != "1970-01-01T00:00:00.000Z")?
                                                    getHyphenDDMMMYYYY(item.destination_yard_reached_on)
                                                     :"NA"
                                                }    
                                            </div>
                                        </div>

                                        <div className="col-xl-6 textAlignCenter n-p-0 form-group br bb bl mb-0p">
                                            <label className=" col-xl-12 evt-label">Planning Start Time</label><br />
                                            <div className=" col-xl-12 evt-strng"> 
                                                {
                                                    (item.load_attach_date != undefined && item.load_attach_date != "Invalid date" && item.load_attach_date != "1970-01-01T00:00:00.000Z")?
                                                    getHyphenDDMMMYYYY(item.load_attach_date)
                                                     :"NA"
                                                }    
                                            </div>
                                        </div>
                                    </div>
                                )}
                                    
                            </div>

                        </div>
                        :""}
                    </Modal>

                

            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}