import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM, getHyphenDDMMMYYYYHHMM } from '../common/utils'; 
import {
   
    Modal, ModalHeader, ModalBody, ModalFooter,
   
 } from "reactstrap";

//import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
//mport {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';


var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
var liveRouteColor = ""
class DrawRouteMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: [],
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  //customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
		  modal: false,
		};
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this)
	}
	componentDidMount(){
        this.renderMapKey()
        this.renderMap()
    }
    componentDidUpdate(prevProps){
        if(prevProps.truckno != this.state.proptruckno)
        {
            this.renderMap()
            this.setState({
                proptruckno:prevProps.truckno
            })
        }
		if(prevProps.selectedorigin != this.state.selectedorigin && prevProps.selecteddestination != this.state.selecteddestination)
		{
			this.renderMap()
		}
    }
    renderMapKey = () => {  
		console.log("Hit-1");
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
    }
    renderMap = () => { 
		console.log("Hit-2"); 
        window.initMap = this.initMap
    }

initMap = () => 
{
	var baseRouteCoords = [];
    if(this.props.mapfor == "show_location"){
        var location_coords = this.props.location_coords;		
		baseRouteCoords = this.props.baseRouteCoords;
    }
    var mapOptions = {
        zoom: 15,
        panControl: true,
		zoomControl: true,
		mapTypeControl: true,
		scaleControl: true,
		streetViewControl: true,
		overviewMapControl: true,
		rotateControl: true,
        mapTypeControlOptions: {
            mapTypeIds: ['satellite', 'roadmap'],
        },
        center: new window.google.maps.LatLng(28.4519751,77.0310713),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
    };
				
	try{
		map = new window.google.maps.Map(document.getElementById('deviation-map'), mapOptions);
	}catch(e){}
	
	var bounds = new window.google.maps.LatLngBounds();
		// Create our info window content

		//console.log("Stepa")
	if(location_coords != undefined){
		var cInfo = this.props.mapinfo;
		var dt = moment.parseZone(cInfo.last_active_on).format("x")
		var cdate = moment.parseZone().format("x");

			var diff = Math.floor((parseInt(cdate)-parseInt(dt))/1000);	
		
		var image =  "";
		var truckText =  "";
		if(parseInt(cInfo.status) == 1)
		{
			truckText =  "Inside Plant";
			var color =  "#333333";
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 2)
		{
				truckText =  "Outside Plant For Loading";
			var color =  "#333333";
			
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 3)
		{
				truckText =  "Going To Godown";
			var color =  "#333333";
			//  image =  require('../../assets/icons/truck-red-32.png');
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 4) 
		{
			truckText =  "At Godown";
			var color =  "#333333";
			// image =  require('../../assets/icons/truck-red-32.png');
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 5)
		{
			truckText =  "Coming To Plant";
			var color =  "#333333";
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 6)
		{
			truckText =  "Empty Truck Outside Plant";
			var color =  "#333333";
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else if(parseInt(cInfo.status) == 7)
		{
			truckText =  "Empty Truck";
			var color =  "#333333";
			if(diff > 600)
			{
				image =  "";
			}
			else{
				image =  "";
			}
		}
		else{
				truckText =  cInfo.truck_no;
			var color =  "#333333";
			image =  "";
		}
		
		var marker, l;
		marker = new window.google.maps.Marker({
			position: new window.google.maps.LatLng(location_coords.lat, location_coords.lng),
			//icon:require('../../assets/icons/cf.png'),
			map: map,
		});

		window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
			return function() {
				var contentarr = []
				var headerTitle = cInfo.truck_no
				if(cInfo.deviation == 1)
				{
					contentarr.push({"key":"Truck No", "value":cInfo.truck_no});
					contentarr.push({"key":"Address", "value":cInfo.address});
				}
				else
				{
					contentarr.push({"key":"Truck No", "value":cInfo.truck_no});
					contentarr.push({"key":"Transporter Name", "value":cInfo.transporter_name});
					contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(cInfo.last_active_on)});
					contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(cInfo.last_known_speed)});
					contentarr.push({"key":"Address", "value":cInfo.address});
					// contentarr.push({"key":"State", "value":cInfo.state});
					contentarr.push({"key":"Status", "value":truckText});
				}
				
				var contentString = infoBox(image, headerTitle, contentarr, '')
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				infowindow.open(map, marker);
				console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
			}
		})(marker, l));	
        map.setCenter(new window.google.maps.LatLng(location_coords.lat, location_coords.lng));
        bounds.extend(new window.google.maps.LatLng(location_coords.lat,location_coords.lng));
        map.setZoom(10)	
	}
	else{
		
	}
	
	if(this.props.mapfor == "show_location")
	{
		console.log("show_loc_in_base_routes",baseRouteCoords);
		// BASE ROUTES STARTS
		if(baseRouteCoords.length > 0)
		{
			var bounds = new window.google.maps.LatLngBounds();
			// var poly_line = new window.google.maps.Polyline({
			// 	map:map,
			// 	strokeColor: "green",
			// 	strokeOpacity: 1.0,
			// 	strokeWeight: 2.5,
			// 	icons: [{
			// 			icon: {
			// 					path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			// 					strokeColor:'#ff8c52',
			// 					fillColor:'#ff8c52',
			// 					fillOpacity:1,
			// 					strokeWeight: 2
			// 					},
			// 			repeat:'500px',
			// 			path:[]
			// 		}]
			// });
			// var path = poly_line.getPath().getArray();
			// // console.log("route_coords ", route_coords)
			// for(var i=0;i<baseRouteCoords.length;i++)
			// {
				
			// 	let latLng = new window.google.maps.LatLng(baseRouteCoords[i].lat,baseRouteCoords[i].lng);
			// 	bounds.extend(new window.google.maps.LatLng(baseRouteCoords[i].lat,baseRouteCoords[i].lng));
				
			// 	// if(route_coords[i].set == 1)
			// 	// {
			// 		path.push(latLng);
			// 		poly_line.setPath(path);
			// 	// }
			// 	// else{
			// 	// 	pathreturn.push(latLng);
			// 	// 	returnpoly_line.setPath(pathreturn);
			// 	// }
			// }
			this.plotMultiColorPolyline(baseRouteCoords, map, bounds)
			var infowindow = null;
			var currentwindow = null;
			if(baseRouteCoords.length > 0)
			{
				// Starting point
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(baseRouteCoords[0].lat, baseRouteCoords[0].lng),
					icon:require('../../assets/icons/business.png'),
					map: map,	
				});

				// Ending point
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(baseRouteCoords[(baseRouteCoords.length-1)].lat, baseRouteCoords[(baseRouteCoords.length-1)].lng),
					icon:require('../../assets/icons/customer_pin_38.png'),
					map: map,	
				});				
			}
		}
		// BASE ROUTES END
	}
	map.fitBounds(bounds)
}


plotMultiColorPolyline(arr2,map,bounds, baseroute=false)
{
	if(arr2.length)
	{
		var data_type = ""
		var data_type_list = [...new Set(arr2.map(item => item.type))];
		if(data_type_list.includes("transshipment"))
		{
			data_type = "transshipment"
		}
		if(data_type_list.includes("rake"))
		{
			data_type = "rake"
		}
		this.setState({
			data_type : data_type
		})
	}
	
	if(this.props.stepcoords == undefined)
	{
		if(arr2.length <= 1000)
		{
			var icon_cnt = 60;
			var icon_divisible = 60;
		}
		else
		{
			var icon_cnt = 500;
			var icon_divisible = 500;
		}
	}
	else
	{
		var icon_cnt = 1;
		var icon_divisible = 1;
	}
	//console.log(arr2, "coords")
	
	for(var i=0;i< arr2.length-1;i++)
	{
		var locationLatLng = [];
		locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
		locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
		if(icon_cnt % icon_divisible == 0)
		{
			var lineicon = [{
						// icon: {
						// 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 		strokeColor:'#FF0000',
						// 		fillColor:'#FF0000',
						// 		fillOpacity:1,
						// 		strokeWeight: 2
						// },
						repeat:'100px',
						path:[]
					}];
		}else{lineicon=[]}
		icon_cnt = icon_cnt + 1
		var consignments_missing_route_line = new window.google.maps.Polyline({
			map:map,
			path : locationLatLng,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: lineicon
		});
		liveRouteColor = "#157254";
		if(arr2[i].msgtype == "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2.5,
			})
			liveRouteColor = "#452a68" 
		}
		
		if(arr2[i].set == undefined)
		{
			if(!baseroute)
			{
				consignments_missing_route_line.setOptions({
					strokeColor : "#157254"
				})
				liveRouteColor = "#157254"
			}
			else
			{
				consignments_missing_route_line.setOptions({
					strokeColor : "red"
				})
			}
			
		}
		else if(arr2[i].set == 1)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
			liveRouteColor = "#157254"
		}
		else if(arr2[i].set == 2)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#0000ff",
				strokeWeight: 2.5,
			})
			liveRouteColor = "#0000ff"
		}
		else if(arr2[i].set == 3)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#980000",
				strokeWeight: 2.5,
			});
			liveRouteColor = "#980000"
		}
		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
	}
}




baseRoutePlotMap(arr2,map,bounds, baseroute=false)
{
	
	//console.log(arr2, "coords")
	
	for(var i=0;i< arr2.length-1;i++)
	{
		var locationLatLng = [];
		locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
		locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
		
			var lineicon = [{
						icon: {
								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								strokeColor:'#FF0000',
								fillColor:'#FF0000',
								fillOpacity:1,
								strokeWeight: 2
						},
						repeat:'100px',
						path:[]
					}];
	
		var consignments_missing_route_line = new window.google.maps.Polyline({
			map:map,
			path : locationLatLng,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: lineicon
		});
		liveRouteColor = "#157254";
				consignments_missing_route_line.setOptions({
					strokeColor : "red"
				})
			
		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
	}
}

render () {
        return (
			
			<div className="col-xl-12 col-md-12">
				<div id="deviation-map" className="" style={{width:'100%',height:"100vh"}}></div>
					<div className="legend-overlay">
										
						<span className="onwardrut">Base Route</span>
				</div>
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


export default DrawRouteMap;