import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class AutomotiveGeoFenceList extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Automotive Geofences",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowPlannedData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {},
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            rowSelection: "multiple",
            errorMessage:'',
            successMessage:'',
			usergridstate:[],
			screenurl:"",
			screentitle:"",
    	      
        };
	}
	
    componentDidMount(){		
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
    	redirectURL.post("/consignments/automotivegeofences").
		then(
			(response)=>{
				let records = response.data;
				this.setState({
                    rowData:records,
                    loadshow:"show-n",
                    overly:"show-n"
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }
    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
	
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false,
            pickup_plan_date:''
        });
    }
    render(){
    	const columnwithDefs = [
	  	        {
                    headerName: "GeoFence Name",
                    field: "geofence_name",
                    width: 200	    	          
    	        },
    	       
		        {
	  	          headerName: "Centriod Latitude",
	  	          field: "centroid_lat",
	  	          width: 200	      	          
	  	        },
                {
                    headerName: "Centriod Longitude",
                    field: "centroid_lng",
                    width: 200            
                },
		        {
	  	        	field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var rowid = params.data._id;
						var htmloption = '<a href="/editautomotive/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					} 
		        	
    	        },
		       
	      ];
        return (
            
    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span> {this.state.pageTitle} </span>
				       				<a href={"/addautomotivegeofence"} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
                                    <div className="col-xl-12 col-lg-12">										
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                //sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                rowSelection={this.state.rowSelection}
                                               // onRowSelected={this.onRowSelected.bind(this)}
                                                suppressRowClickSelection={true}
                                            />
                                        </div>                                       
                                    </div>                            
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
            </div>
              
        );
    }
}


