/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import DateFormater from '../layouts/dateComponent';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import CountUp from 'react-countup';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { components } from 'react-select/dist/react-select.cjs.prod';
$.datetimepicker.setLocale('en');
// var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return (text) =>
            text
                .split("")
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join("");
        // eslint-disable-next-line no-unreachable
    } catch (e) { }
};
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const criticalColumns = [
    {
        headerName: "Zone",
        field: "zone",
        width: 80,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Area Office",
        field: "consignee_area",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Invoice No",
        field: "invoice_no",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Consigner Name",
        field: "consigner_name",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Consignee Code",
        field: "consignee_code",
        width: 100,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Consignee Name",
        field: "consignee_name",
        width: 160,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Consignee City",
        field: "consignee_city",
        width: 160,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Truck No",
        field: "truck_no",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "GR No",
        field: "consignment_code",
        resizable: true,
        width: 120,
        filter: true
    },
    {
        headerName: "Range",
        field: "",
        resizable: true,
        width: 120,
        filter: true,
        valueGetter: param => {
            if (param.data.range !== '' && param.data.range !== undefined) return param.data.range
            if (param.data.days_range !== '' && param.data.days_range !== undefined) return param.data.days_range
        }
    },
    {
        headerName: "No Of Days",
        field: "",
        resizable: true,
        width: 120,
        filter: true,
        valueGetter: param => {
            if (param.data.eta_days !== '' && param.data.eta_days !== undefined) return param.data.eta_days
            if (param.data.waiting_days !== '' && param.data.waiting_days !== undefined) return param.data.waiting_days
        }
    },
    {
        headerName: "Invoice Time",
        field: "invoice_time",
        width: 150,
        // pinned: 'left',
        cellRenderer: '', resizable: true,
        comparator: dateComparator,
        valueGetter: function (params) {
            if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
            }
            else {
                return "NA";
            }
        },
    },
    {
        headerName: "Distance Travelled in 24 hrs(Kms)",
        field: "distance_travelled_in_24_hrs_kms",
        width: 120,
        filter: true,
        resizable: true,
    },
    {
        headerName: "Recent Dealer Reported",
        field: "recent_dealer_reported",
        width: 150,
        // pinned: 'left',
        cellRenderer: '', resizable: true,
        comparator: dateComparator,
        valueGetter: function (params) {
            if (params.data.recent_dealer_reported != '' && params.data.recent_dealer_reported != undefined) {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
            }
            else {
                return "NA";
            }
        },
    }

]
export default class AtDealerShipDashboard extends Component {

    state = {
        startDate: moment.parseZone().format('YYYY-MM-DD'),
        endDate: moment.parseZone().format('YYYY-MM-DD'),
        modules: AllModules,
        eventGridAction: "gridAction",
        eventFormAction: "formAction",
        pageTitle: "Transporter Performance Dashboard",
        loadshow: 'show-n',
        overly: "show-n",
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            // editable: true,
            resizable: true
        },
        animateRows: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
        },
        popupParent: document.body,
        loadingCellRenderer: "customLoadingCellRenderer",
        paginationPageSize: 50,
        rowClassRules: {
            "colorForDangerLight": function (params) {
                if (params.data.parameters == "growth") {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        flCity: "",
        flState: "",
        flCluster: "",
        flZone: "",
        flTransporter: "",
        filterCities: [],
        filterStates: [],
        filterClusters: [],
        filterZones: [],
        filterTransporters: [],
        filterConsigneeArea: [],
        filterConsigneeName: [],
        filterConsigneeCode: [],
        plantsList: [],
        inTransitTrucks: 0,
        unloadedCount: 0,
        threeOrMoreCount: 0,
        zeroToTwoDays: 0,
        eastZoneList: [],
        northZoneList: [],
        southZoneList: [],
        westZoneList: [],
        unloadedDetailsList: [],
        waitingAtDealer: [],
        inTransitTruckList: [],
        rowData: [],
        groupHeaderHeight: 50,
        headerHeight: 70,
        floatingFiltersHeight: 50,
        pivotGroupHeaderHeight: 30,
        pivotHeaderHeight: 50,
        rowHeight: 50,
        sliderCriticalTranslate: '',
        rowCriticalData: [],
        sliderTitle: '',
        time: moment.parseZone().format('YYYY-MM-DD HH:mm'),
        sliderColumns: criticalColumns,
        intransit_zero_to_one_days: 0,
        intransit_two_to_three_days: 0,
        intransit_three_and_more: 0,
        zero_to_one_day: 0,
        two_to_three_days: 0,
        three_and_more: 0,
        count_unloaded: 0,
        intransit_today: [],
        prev_unloaded_count: 0,
        totalUnloadedList: [],
        previous_unloaded: [],
        three_or_more_days: [],
        intransit_south: [],
        intransit_north: [],
        intransit_east: [],
        intransit_west: [],
        intransit_central: [],
        dealer_south: [],
        dealer_west: [],
        dealer_north: [],
        dealer_east: [],
        dealer_central: [],
        central_eta_days_0_1: [],
        central_eta_days_2_3: [],
        central_eta_days_3_more: [],
        east_eta_days_0_1: [],
        east_eta_days_2_3: [],
        east_eta_days_3_more: [],
        west_eta_days_0_1: [],
        west_eta_days_2_3: [],
        west_eta_days_3_more: [],
        north_eta_days_0_1: [],
        north_eta_days_2_3: [],
        north_eta_days_3_more: [],
        south_eta_days_0_1: [],
        south_eta_days_2_3: [],
        south_eta_days_3_more: [],
        central_dealer_days_0_1: [],
        central_dealer_days_2_3: [],
        central_dealer_days_3_more: [],
        east_dealer_days_0_1: [],
        east_dealer_days_2_3: [],
        east_dealer_days_3_more: [],
        west_dealer_days_0_1: [],
        west_dealer_days_2_3: [],
        west_dealer_days_3_more: [],
        north_dealer_days_0_1: [],
        north_dealer_days_2_3: [],
        north_dealer_days_3_more: [],
        south_dealer_days_0_1: [],
        south_dealer_days_2_3: [],
        south_dealer_days_3_more: [],
        today_unloaded: [],
        yesterday_unloaded: [],
        today_unloaded_central: [],
        today_unloaded_east: [],
        today_unloaded_north: [],
        today_unloaded_south: [],
        today_unloaded_west: [],
        yesterday_unloaded_central: [],
        yesterday_unloaded_east: [],
        yesterday_unloaded_north: [],
        yesterday_unloaded_south: [],
        yesterday_unloaded_west: [],
        zone: '',
        detailCellRendererParams: {},
        sliderColumns1: [],
        legZone: '',
        overlayNoRowsTemplate: '',
        showUnloaded: false,
        consignee_area_office: ''
    }

    componentDidMount() {
        this.timer = setInterval(
            () => this.setState(prevState => ({ time: prevState.time = moment.parseZone().format('YYYY-MM-DD HH:mm') })), 60000
        )
        let params = {}
        // setInterval(updateClock(), 1000)
        const sdate = moment.parseZone().format('YYYY-MM-DD')
        const edate = moment.parseZone().format('YYYY-MM-DD')
        var zone = localStorage.getItem('zone')
        let consignee_area_office = ''
        if (localStorage.getItem('cha_code') !== undefined && localStorage.getItem('cha_code') !== 'undefined' && localStorage.getItem('cha_code') !== '') {
            consignee_area_office = localStorage.getItem('cha_code')
            params.consignee_areas = JSON.stringify([consignee_area_office])
        }
        // console.log("consignee_area_office",consignee_area_office)
        this.setState({
            startDate: sdate,
            endDate: edate,
            loadshow: 'show-m',
            overly: 'show-m',
            zone,
            consignee_area_office
        })

        this.onLoadGetData(params)
        this.getDashboardGridData(params)
        this.logPageView(); // Log page view to GA

    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onLoadGetData = async (args) => {
        // let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        let dpt = 'SNDG'
        var zone = localStorage.getItem('zone')

        // console.log("zone", zone, zone.length)
        let plants = [{ 'pName': 'Neemrana', 'pCode': 'HM4N' }, { 'pName': 'Haridwar', 'pCode': 'HHHU' }, { 'pName': 'Dharuhera', 'pCode': 'HHHD' }, { 'pName': 'Gurgaon', 'pCode': 'HHHG' }, { 'pName': 'Halol', 'pCode': 'HM5V' }, { 'pName': 'Chittoor', 'pCode': 'HM6C' }]
        let filterQuery = { dept_code: encode(dpt) }
        if (zone !== undefined && zone !== "undefined" && zone !== null && zone !== '') {
            filterQuery['zone'] = zone
        }
        //details for the filters data
        redirectURL.post("/consignments/getConsignmentsNewFilterKeys", filterQuery).then((resp) => {
            // console.log("resp",resp.data)
            var cities = resp.data.cities;
            var filterCities = [{ label: 'ALL', value: 'ALL' }];
            if (cities.length > 0) {
                cities.map(function (c) {
                    filterCities.push({
                        label: c,
                        value: c,
                    });
                });
            }
            var states = resp.data.states;
            var filterStates = [{ label: 'ALL', value: 'ALL' }];
            if (states.length > 0) {
                states.map(function (s) {
                    filterStates.push({
                        label: s,
                        value: s,
                    });
                });
            }
            var clusters = resp.data.clusters;
            var filterClusters = [{ label: 'ALL', value: 'ALL' }];
            if (clusters.length > 0) {
                clusters.map(function (cl) {
                    filterClusters.push({
                        label: cl,
                        value: cl,
                    });
                });
            }
            var zones = resp.data.zones;
            if (zone !== undefined && zone !== "" && zone !== 'null') {
                var filterZones = [{ label: 'ALL', value: 'ALL' }];
                if (zones.length > 0) {
                    zones.map(function (cl) {
                        filterZones.push({
                            label: cl,
                            value: cl,
                        });
                    });
                }
            } else {
                filterZones = [{ label: zone, value: zone }]
            }

            var transporters = resp.data.transporters;
            var filterTransporters = [{ label: 'ALL', value: 'ALL' }];
            if (transporters.length > 0) {
                transporters.map(function (t) {
                    filterTransporters.push({
                        label: t,
                        value: t,
                    });
                });
            }
            var consignee_area = resp.data.consigneeArea;
            var filterConsigneeArea = [{ label: 'ALL', value: 'ALL' }];
            if (consignee_area.length > 0) {
                consignee_area.map(function (t) {
                    filterConsigneeArea.push({
                        label: t,
                        value: t,
                    });
                });
            }
            var consignee_name = resp.data.consigneeName;

            let plantsList = [{ label: 'ALL', value: 'ALL' }]
            plants.map(t => {
                plantsList.push({
                    label: t.pName,
                    value: t.pCode,
                })
            })
            this.setState({
                filterCities: filterCities,
                filterStates: filterStates,
                filterClusters: filterClusters,
                filterZones: filterZones,
                filterTransporters: filterTransporters,
                filterConsigneeArea: filterConsigneeArea,
                plantsList: plantsList,
            });
        })
        // console.log("args", args)
        let query = args
        if (zone !== undefined && zone !== "undefined" && zone !== null && zone !== '') {
            query['zones'] = JSON.stringify([zone])
        }
        //dashboard filters data from api getDealershipData
        // console.log("query", query)
        redirectURL.post('/consignments/getDealershipData', query).then(resp => {
            // console.log("resp", resp.data, '\n message', resp.data.message, typeof(resp.data.message))
            const otpt = resp.data
            if (resp.data.status == 'Failure' && (resp.data.message).includes('consigner')) {
                this.setState({
                    show: true,
                    basicTitle: "No Details Found",
                    basicType: "danger",
                    loadshow: 'show-n',
                    overly: 'show-n'
                })
            } else {
                try {
                    const consignee_info = JSON.parse(otpt.consignee_info)
                    // console.log("consignee_info", consignee_info)
                    var filterConsigneeName = [{ label: 'ALL', value: 'ALL' }];
                    if (consignee_info.length > 0) {
                        consignee_info.map(function (t) {
                            filterConsigneeName.push({
                                label: `${t.consignee_name}-${t.consignee_code}`,
                                value: t.consignee_code,
                            });
                        });
                    }
                } catch (e) { }
                //  for the counter details
                //  intransit counter
                const intransit_two_to_three_days = otpt.intransit_two_to_three_days
                const intransit_three_and_more = otpt.intransit_three_and_more

                //  waiting at dealership
                const zero_to_one_day = otpt.zero_to_one_day
                const two_to_three_days = otpt.two_to_three_days
                // const three_and_more = otpt.three_and_more

                //previous unloaded counter
                const prev_unloaded_count = otpt.prev_unloaded_count
                //  today unloaded count
                const unloadedCount = otpt.count_unloaded

                let totalUnloadedList = []
                // in transit trucks counter 
                const inTransitTrucks = otpt.count_in_transit
                // below are the lists for onClick grid details
                let waitingAtDealer = JSON.parse(otpt.waiting_at_dealer_details)
                // console.log('waitingAtDealer', waitingAtDealer)
                const three_or_more_days = []
                waitingAtDealer.map(e => {
                    if (e.waiting_days > 3) three_or_more_days.push(e)
                })

                let inTransitTruckList = JSON.parse(otpt.in_transit_details)
                // console.log('inTransitTruckList', inTransitTruckList)
                const intransit_today = inTransitTruckList.filter(e => (e.eta_days == 0))
                const intransit_zero_to_one_days = inTransitTruckList.filter(e => e.eta_days == 1)

                // unloaded today list
                let unloadedDetailsList = JSON.parse(otpt.unloaded_details)
                // console.log('unloadedDetailsList', unloadedDetailsList)
                // total unloaded list
                if (unloadedDetailsList !== 0) {
                    totalUnloadedList = [...totalUnloadedList, ...unloadedDetailsList]
                }
                // previous_unloaded list 
                let previous_unloaded = JSON.parse(otpt.previous_unloaded)
                // console.log("previous_unloaded",previous_unloaded)
                if (previous_unloaded !== 0) {
                    totalUnloadedList = [...totalUnloadedList, ...previous_unloaded]
                }
                // on click date filter 
                let all_unloaded_count = otpt.all_unloaded_count
                if (all_unloaded_count > 0) {
                    totalUnloadedList = JSON.parse(otpt.all_unloaded)
                }

                let eastZoneList = JSON.parse(otpt.east_zone)
                // console.log('eastZoneList', eastZoneList)

                let northZoneList = JSON.parse(otpt.north_zone)
                // console.log('northZoneList', northZoneList)

                let southZoneList = JSON.parse(otpt.south_zone)
                // console.log('southZoneList', southZoneList)

                let westZoneList = JSON.parse(otpt.west_zone)
                // console.log('westZoneList', westZoneList)

                let centralZoneList = JSON.parse(otpt.central_zone)
                // console.log('centralZoneList', centralZoneList)
                let rowData = []
                let total = 0

                this.setState({
                    totalUnloadedList,
                    prev_unloaded_count,
                    previous_unloaded,
                    intransit_today,
                    intransit_zero_to_one_days: intransit_zero_to_one_days.length,
                    intransit_two_to_three_days,
                    intransit_three_and_more,
                    filterConsigneeName: filterConsigneeName,
                    inTransitTrucks,
                    unloadedCount,
                    zero_to_one_day,
                    two_to_three_days,
                    three_or_more_days,
                    eastZoneList,
                    northZoneList,
                    southZoneList,
                    westZoneList,
                    unloadedDetailsList,
                    waitingAtDealer,
                    inTransitTruckList,
                    centralZoneList,
                    loadshow: 'show-n',
                    overly: 'show-n'

                })
            }
        })


    }

    getDashboardGridData = async (args1) => {

        let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        let qry = args1
        qry.dept_code = encode(dpt)
        let zone = localStorage.getItem('zone')
        if (zone !== undefined && zone !== "undefined" && zone !== null && zone !== '') {
            qry.zone = (zone)
            // console.log("query", qry)
            // if(qry.zone == undefined || qry.zone == 'undefined') {
            //     delete qry.zone
            // }
        }
        redirectURL.post("/consignments/getInTransitAndDealershipData", qry).then(async resp => {
            // console.log("getInTransitAndDealershipData",resp.data)
            const otpt = resp.data
            let intransit_central = JSON.parse(otpt.Intransit_central)
            // console.log("Intransit_central", intransit_central)

            let intransit_east = JSON.parse(otpt.Intransit_east)
            // console.log("Intransit_east", intransit_east)

            let intransit_north = JSON.parse(otpt.Intransit_north)
            // console.log("Intransit_north", intransit_north)

            let intransit_south = JSON.parse(otpt.Intransit_south)
            // console.log("Intransit_south", intransit_south)

            let intransit_west = JSON.parse(otpt.Intransit_west)
            // console.log("Intransit_west", intransit_west)

            let dealer_central = JSON.parse(otpt.dealer_central)
            // console.log("dealer_central", dealer_central)
            let dealer_east = JSON.parse(otpt.dealer_east)
            // console.log("dealer_east", dealer_east)
            let dealer_north = JSON.parse(otpt.dealer_north)
            // console.log("dealer_north", dealer_north)

            let dealer_south = JSON.parse(otpt.dealer_south)
            // console.log("dealer_south", dealer_south)

            let dealer_west = JSON.parse(otpt.dealer_west)
            // console.log("dealer_west", dealer_west)

            let today_unloaded = []
            let yesterday_unloaded = []

            let today_unloaded_central = JSON.parse(otpt.today_unloaded_central)
            if (today_unloaded_central !== undefined) {
                if (today_unloaded_central.length > 0) {
                    today_unloaded = [...today_unloaded, ...today_unloaded_central]
                }
            }
            let today_unloaded_east = JSON.parse(otpt.today_unloaded_east)
            if (today_unloaded_east !== undefined) {
                if (today_unloaded_east.length > 0) {
                    today_unloaded = [...today_unloaded, ...today_unloaded_east]
                }
            }
            let today_unloaded_north = JSON.parse(otpt.today_unloaded_north)
            if (today_unloaded_north !== undefined) {
                if (today_unloaded_north.length > 0) {
                    today_unloaded = [...today_unloaded, ...today_unloaded_north]
                }
            }
            let today_unloaded_south = JSON.parse(otpt.today_unloaded_south)
            if (today_unloaded_south !== undefined) {
                if (today_unloaded_south.length > 0) {
                    today_unloaded = [...today_unloaded, ...today_unloaded_south]
                }
            }
            let today_unloaded_west = JSON.parse(otpt.today_unloaded_west)
            if (today_unloaded_west !== undefined) {
                if (today_unloaded_west.length > 0) {
                    today_unloaded = [...today_unloaded, ...today_unloaded_west]
                }
            }

            let yesterday_unloaded_central = JSON.parse(otpt.yesterday_unloaded_central)
            if (yesterday_unloaded_central !== undefined) {
                if (yesterday_unloaded_central.length > 0) {
                    yesterday_unloaded = [...yesterday_unloaded, ...yesterday_unloaded_central]
                }
            }
            let yesterday_unloaded_east = JSON.parse(otpt.yesterday_unloaded_east)
            if (yesterday_unloaded_east !== undefined) {
                if (yesterday_unloaded_east.length > 0) {
                    yesterday_unloaded = [...yesterday_unloaded, ...yesterday_unloaded_east]
                }
            }
            let yesterday_unloaded_north = JSON.parse(otpt.yesterday_unloaded_north)
            if (yesterday_unloaded_north !== undefined) {
                if (yesterday_unloaded_north.length > 0) {
                    yesterday_unloaded = [...yesterday_unloaded, ...yesterday_unloaded_north]
                }
            }
            let yesterday_unloaded_south = JSON.parse(otpt.yesterday_unloaded_south)
            if (yesterday_unloaded_south !== undefined) {
                if (yesterday_unloaded_south.length > 0) {
                    yesterday_unloaded = [...yesterday_unloaded, ...yesterday_unloaded_south]
                }
            }
            let yesterday_unloaded_west = JSON.parse(otpt.yesterday_unloaded_west)
            if (yesterday_unloaded_west !== undefined) {
                if (yesterday_unloaded_west.length > 0) {
                    yesterday_unloaded = [...yesterday_unloaded, ...yesterday_unloaded_west]
                }
            }

            // creating a row data
            let rowData = []
            let totalTransitTrucks = 0
            let central_eta_days_0_1 = []
            let central_eta_days_2_3 = []
            let central_eta_days_3_more = []
            intransit_central.map(e => {
                // totalTransitTrucks += 1
                if (e.eta_days <= 1) central_eta_days_0_1.push(e)
                // if(e.eta_days == 0 || e.eta_days == 1) central_eta_days_0_1.push(e)
                if (e.eta_days == 2 || e.eta_days == 3) central_eta_days_2_3.push(e)
                if (e.eta_days > 3) central_eta_days_3_more.push(e)
            })
            let east_eta_days_0_1 = []
            let east_eta_days_2_3 = []
            let east_eta_days_3_more = []
            intransit_east.map(e => {
                if (e.eta_days == 1 || e.eta_days == 0) east_eta_days_0_1.push(e)
                if (e.eta_days == 2 || e.eta_days == 3) east_eta_days_2_3.push(e)
                if (e.eta_days > 3) east_eta_days_3_more.push(e)
            })

            let west_eta_days_0_1 = []
            let west_eta_days_2_3 = []
            let west_eta_days_3_more = []
            intransit_west.map(e => {
                if (e.eta_days == 1 || e.eta_days == 0) west_eta_days_0_1.push(e)
                if (e.eta_days == 2 || e.eta_days == 3) west_eta_days_2_3.push(e)
                if (e.eta_days > 3) west_eta_days_3_more.push(e)
            })

            let north_eta_days_0_1 = []
            let north_eta_days_2_3 = []
            let north_eta_days_3_more = []
            intransit_north.map(e => {
                if (e.eta_days == 1 || e.eta_days == 0) north_eta_days_0_1.push(e)
                if (e.eta_days == 2 || e.eta_days == 3) north_eta_days_2_3.push(e)
                if (e.eta_days > 3) north_eta_days_3_more.push(e)
            })
            let south_eta_days_0_1 = []
            let south_eta_days_2_3 = []
            let south_eta_days_3_more = []
            intransit_south.map(e => {
                if (e.eta_days == 1 || e.eta_days == 0) south_eta_days_0_1.push(e)
                if (e.eta_days == 2 || e.eta_days == 3) south_eta_days_2_3.push(e)
                if (e.eta_days > 3) south_eta_days_3_more.push(e)
            })

            // dealer data
            let totalNearDealerWaitingTrucks = 0
            let central_dealer_days_0_1 = []
            let central_dealer_days_2_3 = []
            let central_dealer_days_3_more = []
            dealer_central.map(e => {
                if (e.waiting_days == 1 || e.waiting_days == 0) central_dealer_days_0_1.push(e)
                if (e.waiting_days == 2 || e.waiting_days == 3) central_dealer_days_2_3.push(e)
                if (e.waiting_days > 3) central_dealer_days_3_more.push(e)
            })
            let east_dealer_days_0_1 = []
            let east_dealer_days_2_3 = []
            let east_dealer_days_3_more = []
            dealer_east.map(e => {
                if (e.waiting_days == 1 || e.waiting_days == 0) east_dealer_days_0_1.push(e)
                if (e.waiting_days == 2 || e.waiting_days == 3) east_dealer_days_2_3.push(e)
                if (e.waiting_days > 3) east_dealer_days_3_more.push(e)
            })
            let west_dealer_days_0_1 = []
            let west_dealer_days_2_3 = []
            let west_dealer_days_3_more = []
            dealer_west.map(e => {
                if (e.waiting_days == 1 || e.waiting_days == 0) west_dealer_days_0_1.push(e)
                if (e.waiting_days == 2 || e.waiting_days == 3) west_dealer_days_2_3.push(e)
                if (e.waiting_days > 3) west_dealer_days_3_more.push(e)
            })
            let north_dealer_days_0_1 = []
            let north_dealer_days_2_3 = []
            let north_dealer_days_3_more = []
            dealer_north.map(e => {
                if (e.waiting_days == 1 || e.waiting_days == 0) north_dealer_days_0_1.push(e)
                if (e.waiting_days == 2 || e.waiting_days == 3) north_dealer_days_2_3.push(e)
                if (e.waiting_days > 3) north_dealer_days_3_more.push(e)
            })
            let south_dealer_days_0_1 = []
            let south_dealer_days_2_3 = []
            let south_dealer_days_3_more = []
            dealer_south.map(e => {
                if (e.waiting_days == 1 || e.waiting_days == 0) south_dealer_days_0_1.push(e)
                if (e.waiting_days == 2 || e.waiting_days == 3) south_dealer_days_2_3.push(e)
                if (e.waiting_days > 3) south_dealer_days_3_more.push(e)
            })

            // unloaded data today and yesterday
            // let today_unloaded_central = 


            let areaOfficeName = 'AQ->'
            if (this.state.consignee_area_office !== undefined && this.state.consignee_area_office !== '' && this.state.consignee_area_office !== 'undefined') {
                areaOfficeName = this.state.consignee_area_office
            }
            // console.log('areaOfficeName',areaOfficeName)
            // central row data
            if (intransit_central.length >= 0 || dealer_central.length >= 0) {
                totalTransitTrucks += intransit_central.length
                totalNearDealerWaitingTrucks += dealer_central.length
                rowData.push({
                    'zone': 'Central',
                    'area_office': areaOfficeName,
                    'transitVehicleCount': intransit_central.length,
                    'eta_0_to_1': central_eta_days_0_1.length,
                    'eta_2_to_3': central_eta_days_2_3.length,
                    'eta_3_or_more': central_eta_days_3_more.length,
                    'atDealerTotalTruck': totalNearDealerWaitingTrucks,
                    'atDealer_0_to_1': central_dealer_days_0_1.length,
                    'atDealer_2_to_3': central_dealer_days_2_3.length,
                    'atDealer_3_or_more': central_dealer_days_3_more.length,
                    'today_unloaded': today_unloaded_central.length,
                    'yesterday_unloaded': yesterday_unloaded_central.length

                })
            }

            // east row data
            if (intransit_east.length >= 0 || dealer_east.length >= 0) {
                totalTransitTrucks += intransit_east.length
                totalNearDealerWaitingTrucks += dealer_east.length
                rowData.push({
                    'zone': 'East',
                    'area_office': areaOfficeName,
                    'transitVehicleCount': intransit_east.length,
                    'eta_0_to_1': east_eta_days_0_1.length,
                    'eta_2_to_3': east_eta_days_2_3.length,
                    'eta_3_or_more': east_eta_days_3_more.length,
                    'atDealerTotalTruck': dealer_east.length,
                    'atDealer_0_to_1': east_dealer_days_0_1.length,
                    'atDealer_2_to_3': east_dealer_days_2_3.length,
                    'atDealer_3_or_more': east_dealer_days_3_more.length,
                    'today_unloaded': today_unloaded_east.length,
                    'yesterday_unloaded': yesterday_unloaded_east.length
                })
            }

            // west row data
            if (intransit_west.length >= 0 || dealer_west.length >= 0) {
                totalTransitTrucks += intransit_west.length
                totalNearDealerWaitingTrucks += dealer_west.length
                rowData.push({
                    'zone': 'West',
                    'area_office': areaOfficeName,
                    'transitVehicleCount': intransit_west.length,
                    'eta_0_to_1': west_eta_days_0_1.length,
                    'eta_2_to_3': west_eta_days_2_3.length,
                    'eta_3_or_more': west_eta_days_3_more.length,
                    'atDealerTotalTruck': dealer_west.length,
                    'atDealer_0_to_1': west_dealer_days_0_1.length,
                    'atDealer_2_to_3': west_dealer_days_2_3.length,
                    'atDealer_3_or_more': west_dealer_days_3_more.length,
                    'today_unloaded': today_unloaded_west.length,
                    'yesterday_unloaded': yesterday_unloaded_west.length
                })
            }

            //  north row data
            if (intransit_north.length >= 0 || dealer_north.length >= 0) {
                totalTransitTrucks += intransit_north.length
                totalNearDealerWaitingTrucks += dealer_north.length
                rowData.push({
                    'zone': 'North',
                    'area_office': areaOfficeName,
                    'transitVehicleCount': intransit_north.length,
                    'eta_0_to_1': north_eta_days_0_1.length,
                    'eta_2_to_3': north_eta_days_2_3.length,
                    'eta_3_or_more': north_eta_days_3_more.length,
                    'atDealerTotalTruck': dealer_north.length,
                    'atDealer_0_to_1': north_dealer_days_0_1.length,
                    'atDealer_2_to_3': north_dealer_days_2_3.length,
                    'atDealer_3_or_more': north_dealer_days_3_more.length,
                    'today_unloaded': today_unloaded_north.length,
                    'yesterday_unloaded': yesterday_unloaded_north.length
                })
            }

            //  south row data
            if (intransit_south.length >= 0 || dealer_south.length >= 0) {
                totalTransitTrucks += intransit_south.length
                totalNearDealerWaitingTrucks += dealer_south.length
                rowData.push({
                    'zone': 'South',
                    'area_office': areaOfficeName,
                    'transitVehicleCount': intransit_south.length,
                    'eta_0_to_1': south_eta_days_0_1.length,
                    'eta_2_to_3': south_eta_days_2_3.length,
                    'eta_3_or_more': south_eta_days_3_more.length,
                    'atDealerTotalTruck': dealer_south.length,
                    'atDealer_0_to_1': south_dealer_days_0_1.length,
                    'atDealer_2_to_3': south_dealer_days_2_3.length,
                    'atDealer_3_or_more': south_dealer_days_3_more.length,
                    'today_unloaded': today_unloaded_south.length,
                    'yesterday_unloaded': yesterday_unloaded_south.length
                })
            }
            if (zone !== undefined && zone !== "undefined" && zone !== null && zone !== '') {

            } else {
                var grandTotal = [{
                    'zone': 'Grand Total',
                    'area_office': '',
                    'transitVehicleCount': (intransit_south.length + intransit_north.length + intransit_east.length + intransit_west.length + intransit_central.length),
                    'eta_0_to_1': (south_eta_days_0_1.length + north_eta_days_0_1.length + east_eta_days_0_1.length + west_eta_days_0_1.length + central_eta_days_0_1.length),
                    'eta_2_to_3': (south_eta_days_2_3.length + north_eta_days_2_3.length + east_eta_days_2_3.length + west_eta_days_2_3.length + central_eta_days_2_3.length),
                    'eta_3_or_more': (south_eta_days_3_more.length + north_eta_days_3_more.length + east_eta_days_3_more.length + west_eta_days_3_more.length + central_eta_days_3_more.length),
                    'atDealerTotalTruck': (dealer_north.length + dealer_south.length + dealer_east.length + dealer_west.length + dealer_central.length),
                    'atDealer_0_to_1': (south_dealer_days_0_1.length + north_dealer_days_0_1.length + east_dealer_days_0_1.length + west_dealer_days_0_1.length + central_dealer_days_0_1.length),
                    'atDealer_2_to_3': (south_dealer_days_2_3.length + north_dealer_days_2_3.length + east_dealer_days_2_3.length + west_dealer_days_2_3.length + central_dealer_days_2_3.length),
                    'atDealer_3_or_more': (south_dealer_days_3_more.length + north_dealer_days_3_more.length + east_dealer_days_3_more.length + west_dealer_days_3_more.length + central_dealer_days_3_more.length),
                    'today_unloaded': today_unloaded.length,
                    'yesterday_unloaded': yesterday_unloaded.length
                }]
            }
            // console.log('rowData',rowData, '\n graT', grandTotal)
            this.setState({
                grandTotal,
                rowData,
                intransit_south,
                intransit_north,
                intransit_east,
                intransit_west,
                intransit_central,
                dealer_south,
                dealer_west,
                dealer_north,
                dealer_east,
                dealer_central,
                central_eta_days_0_1,
                central_eta_days_2_3,
                central_eta_days_3_more,
                east_eta_days_0_1,
                east_eta_days_2_3,
                east_eta_days_3_more,
                west_eta_days_0_1,
                west_eta_days_2_3,
                west_eta_days_3_more,
                north_eta_days_0_1,
                north_eta_days_2_3,
                north_eta_days_3_more,
                south_eta_days_0_1,
                south_eta_days_2_3,
                south_eta_days_3_more,
                central_dealer_days_0_1,
                central_dealer_days_2_3,
                central_dealer_days_3_more,
                east_dealer_days_0_1,
                east_dealer_days_2_3,
                east_dealer_days_3_more,
                west_dealer_days_0_1,
                west_dealer_days_2_3,
                west_dealer_days_3_more,
                north_dealer_days_0_1,
                north_dealer_days_2_3,
                north_dealer_days_3_more,
                south_dealer_days_0_1,
                south_dealer_days_2_3,
                south_dealer_days_3_more,
                today_unloaded,
                yesterday_unloaded,
                today_unloaded_central,
                today_unloaded_east,
                today_unloaded_north,
                today_unloaded_south,
                today_unloaded_west,
                yesterday_unloaded_central,
                yesterday_unloaded_east,
                yesterday_unloaded_north,
                yesterday_unloaded_south,
                yesterday_unloaded_west
                // loadshow: 'show-n',
                // overly: 'show-n'
            })
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        // console.log("event_d", d)    	
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(startdate,"startdate")
            // console.log(this.state.endDate,"endDate")
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    startDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                if (startdate !== NaN - NaN - NaN && startdate !== 'NaN-NaN-NaN' && startdate !== 'Invalid Date') {
                    this.setState({
                        startDate: startdate
                    });
                }
            }
        }
        else {
            if (startdate !== NaN - NaN - NaN && startdate !== 'NaN-NaN-NaN' && startdate !== 'Invalid Date') {
                this.setState({
                    startDate: startdate
                });
            }
        }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerToDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(this.state.startDate,"startdate")
            // console.log(edate,"endDate")
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    endDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                if (edate !== NaN - NaN - NaN && edate !== 'NaN-NaN-NaN' && edate !== 'Invalid Date') {
                    this.setState({
                        endDate: edate
                    });
                }
            }
        }
        else {

            if (edate !== NaN - NaN - NaN && edate !== 'NaN-NaN-NaN' && edate !== 'Invalid Date') {
                this.setState({
                    endDate: edate
                });
            }
        }
    }


    newFilter = (filterType, selOption) => {
        // console.log('filterType', filterType, selOption)
        this.setState({ [filterType]: selOption });
    }

    onClickDashboardDetails = () => {
        const { startDate, endDate, flZone, flTransporter, flPlant, consigneeName, consigneeCity, consigneeState, areaOffice } = this.state

        // console.log("startDate", startDate, '\n endDate', endDate, '\n flZone', flZone, '\n flTransporter', flTransporter, '\n flPlant', flPlant, '\n consigneeName', consigneeName, '\n consigneeCity', consigneeCity, '\n consigneeState', consigneeState, '\n areaOffice', areaOffice)

        const params = {
            // is_filter: 1,
        }
        if (flZone !== undefined && flZone !== null) {
            if (flZone.length > 0) {
                let zone = []
                flZone.map(e => {
                    // if(e.value !== 'ALL'){
                    zone.push(e.value)
                    // }
                })
                params['zones'] = JSON.stringify(zone)
            }
        }
        if (flTransporter !== undefined && flTransporter !== null) {
            if (flTransporter.length > 0) {
                let transporter_names = []
                flTransporter.map(e => {
                    // if(e.value !== 'ALL'){
                    transporter_names.push(e.value)
                    // }
                })
                params['transporter_names'] = JSON.stringify(transporter_names)
            }
        }
        if (flPlant !== undefined && flPlant !== null) {
            if (flPlant.length > 0) {
                let consigner_codes = []
                flPlant.map(e => {
                    // if(e.value !== 'ALL'){
                    consigner_codes.push(e.value)
                    // }
                })
                params['consigner_codes'] = JSON.stringify(consigner_codes)
            }
        }
        if (consigneeName !== undefined && consigneeName !== null) {
            if (consigneeName.length > 0) {
                let consignee_codes = []
                consigneeName.map(e => {
                    // if(e.value !== 'ALL'){
                    consignee_codes.push(e.value)
                    // }
                })
                params['consignee_codes'] = JSON.stringify(consignee_codes)
            }
        }
        if (consigneeCity !== undefined && consigneeCity !== null) {
            if (consigneeCity.length > 0) {
                let consignee_cities = []
                consigneeCity.map(e => {
                    // if(e.value !== 'ALL'){
                    consignee_cities.push(e.value)
                    // }
                })
                params['consignee_cities'] = JSON.stringify(consignee_cities)
            }
        }
        if (consigneeState !== undefined && consigneeState !== null) {
            if (consigneeState.length > 0) {
                let consignee_states = []
                consigneeState.map(e => {
                    // if(e.value !== 'ALL'){
                    consignee_states.push(e.value)
                    // }
                })
                params['consignee_states'] = JSON.stringify(consignee_states)
            }
        }
        if (areaOffice !== undefined && areaOffice !== null) {
            if (areaOffice.length > 0) {
                let consignee_areas = []
                areaOffice.map(e => {
                    // if(e.value !== 'ALL'){
                    consignee_areas.push(e.value)
                    // }
                })
                params['consignee_areas'] = JSON.stringify(consignee_areas)
            }
        }
        if (startDate == '' || startDate == "NaN-NaN-NaN") {
            //var sDate = defaultsdate;
            var sDate = "";
        }
        else {
            params.start_date = startDate
        }
        if (endDate == '' || startDate == "NaN-NaN-NaN") {
            var eDate = "";
        }
        else {
            params.end_date = endDate
        }

        if (startDate == moment.parseZone().format('YYYY-MM-DD')) {
            // window.location.reload();
            this.setState({
                showUnloaded: false
            })
        }
        let chkStartDate = new Date(startDate)
        let chkEndDate = new Date(endDate)
        if (chkStartDate > chkEndDate) {
            alert("To Date should be greater than From Date");
            this.setState({
                loadshow: "show-n",
            });
        } else {
            // console.log("params", params)
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
                showUnloaded: true

            })
            this.onLoadGetData(params)
        }


    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickShowClickableData = (args) => {
        const { previous_unloaded, basicType, basicTitle, startDate, endDate, flZone, flTransporter, filterTransporters, loadshow, overly, flPlant, plantsList, filterConsigneeName, consigneeName, consigneeCode, filterCities, filterStates, filterZones, consigneeCity, consigneeState, filterConsigneeArea, areaOffice, inTransitTrucks, unloadedCount, three_or_more_days, grandTotal, rowData, sliderTitle, time, sliderColumns, intransit_zero_to_one_days, intransit_two_to_three_days, intransit_three_and_more, zero_to_one_day, two_to_three_days, three_and_more, count_unloaded, inTransitTruckList, waitingAtDealer, intransit_today, prev_unloaded_count, totalUnloadedList, unloadedDetailsList } = this.state
        // console.log('waitingAtDealer',waitingAtDealer)
        // console.log('inTransitTruckList',inTransitTruckList)
        if (args == "totalUnloaded") {
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: totalUnloadedList,
                sliderTitle: 'Total Unloaded Data',
                sliderColumns: [...criticalColumns,
                {
                    headerName: "Left Dealer Date",
                    field: "left_dealer_marked",
                    width: 150,
                    // pinned: 'left',
                    cellRenderer: '', resizable: true,
                    comparator: dateComparator,
                    valueGetter: function (params) {
                        if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                        }
                        else {
                            return "NA";
                        }
                    },
                },]
            })
        }

        else if (args == 'yesterdayUnloaded') {
            let columns = [...criticalColumns,
                {
                    headerName: "Left Dealer Date",
                    field: "left_dealer_marked",
                    width: 150,
                    // pinned: 'left',
                    cellRenderer: '', resizable: true,
                    comparator: dateComparator,
                    valueGetter: function (params) {
                        if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                        }
                        else {
                            return "NA";
                        }
                    },
                },]
            columns.splice(10, 2)
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: previous_unloaded,
                sliderTitle: 'Yesterday Unloaded Data',
                sliderColumns: columns
            })
        }

        else if (args == 'todayUnloaded') {
            let columns = [...criticalColumns,
                {
                    headerName: "Left Dealer Date",
                    field: "left_dealer_marked",
                    width: 150,
                    // pinned: 'left',
                    cellRenderer: '', resizable: true,
                    comparator: dateComparator,
                    valueGetter: function (params) {
                        if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                        }
                        else {
                            return "NA";
                        }
                    },
                },]
            columns.splice(10, 2)
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: unloadedDetailsList,
                sliderTitle: 'Today Unloaded Data',
                sliderColumns: columns
            })

        }

        else if (args == 'totalWaitingAtDealer') {
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: waitingAtDealer,
                sliderTitle: 'Total Waiting At Dealer',
                sliderColumns: [...criticalColumns]
            })

        }

        else if (args === 'zeroToOne') {
            const zero_to_one_days = []
            waitingAtDealer.map(e => {
                if (e.waiting_days <= 1) zero_to_one_days.push(e)
            })
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: zero_to_one_days,
                sliderTitle: 'Waiting At Dealer Zero To One',
                sliderColumns: criticalColumns
            })
        }
        else if (args === 'twoToThree') {
            const two_to_three_days = []
            waitingAtDealer.map(e => {
                if (e.waiting_days == 2 || e.waiting_days == 3) two_to_three_days.push(e)
            })
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: two_to_three_days,
                sliderTitle: 'Waiting At Dealer Two To Three',
                sliderColumns: criticalColumns
            })
        }
        else if (args === 'threeOrMore') {
            // const three_or_more_days = []
            // waitingAtDealer.map(e => {
            //     if (e.waiting_days > 3) three_or_more_days.push(e)
            // })
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: three_or_more_days,
                sliderTitle: 'Waiting At Dealer Three Or More',
                sliderColumns: criticalColumns
            })
        }
        else if (args === 'inTransitTrucks') {
            // console.log("columns",criticalColumns.slice(0,criticalColumns.length -1))
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: inTransitTruckList,
                sliderTitle: 'In Transit Trucks',
                sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
            })
        }
        else if (args == 'inTransitToday') {
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: intransit_today,
                sliderTitle: 'In Transit Today Trucks',
                sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
            })
        }
        else if (args == 'inTransit0_1') {

            const intransit_one_day = inTransitTruckList.filter(e => e.eta_days == 1)
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: intransit_one_day,
                sliderTitle: 'In Transit ETA 0-1 Day',
                sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
            })
        }
        else if (args == 'inTransit2_3') {

            const intransit_2_3 = inTransitTruckList.filter(e => (e.eta_days == 2 || e.eta_days == 3))
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: intransit_2_3,
                sliderTitle: 'In Transit ETA 2-3 Days',
                sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
            })
        }
        else if (args == 'inTransit3orMore') {

            const intransit_3_more = inTransitTruckList.filter(e => (e.eta_days > 3))
            this.setState({
                sliderCriticalTranslate: "slider-translate-75p",
                loadshow: "show-n",
                overly: "show-m",
                rowCriticalData: intransit_3_more,
                sliderTitle: 'In Transit ETA 3 or More Days',
                sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
            })
        }


    }

    groupRowAggNodes = nodes => {

    }

    // zone wise filtered data 
    getZoneLegData = item => {
        this.setState({
            detailCellRendererParams: {}
        })
        // console.log("legEvent", item)
        // const {intransit_south,intransit_north,intransit_east, intransit_west,
        //     intransit_central, dealer_south, dealer_west, dealer_north,dealer_east,
        //     dealer_central,legZone} = this.state
        let legZone = this.state.legZone
        let cellClicked = item.column.colId
        let dealerAreaOffice = item.data.consignee_area
        let lsZone = localStorage.getItem('zone')
        // for zone wise 
        if (lsZone !== undefined && lsZone !== 'undefined') legZone = lsZone

        // console.log("lsZone", lsZone, '\n type', typeof(lsZone))
        if (cellClicked.includes('transitVehicleCount')) {
            let list = this.state[`intransit_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone))
            // console.log("clicked",filteredData)            
            let columns = criticalColumns.slice(0, criticalColumns.length - 1)
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    // getDetailRowData : filteredData,
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);
        }
        else if (cellClicked.includes("eta_2_to_3")) {
            let list = this.state[`intransit_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && (e.eta_days == 2 || e.eta_days == 3)))
            // console.log("clicked",filteredData)
            let columns = criticalColumns.slice(0, criticalColumns.length - 1)
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);
        }
        else if (cellClicked.includes("eta_0_to_1")) {
            let list = this.state[`intransit_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && (e.eta_days == 0 || e.eta_days == 1)))
            // console.log("clicked",filteredData)
            let columns = criticalColumns.slice(0, criticalColumns.length - 1)
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);

        }
        else if (cellClicked.includes("eta_3_or_more")) {
            let list = this.state[`intransit_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && e.eta_days > 3))
            // console.log("clicked",filteredData)
            let columns = criticalColumns.slice(0, criticalColumns.length - 1)
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);

        }
        else if (cellClicked.includes("atDealerTotalTruck")) {
            let list = this.state[`dealer_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone))
            // console.log("clicked",filteredData)
            let columns = criticalColumns
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);

        }
        else if (cellClicked.includes("atDealer_0_to_1")) {
            let list = this.state[`dealer_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && e.waiting_days <= 1))
            // console.log("clicked",filteredData)
            let columns = criticalColumns
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);

        }
        else if (cellClicked.includes("atDealer_2_to_3")) {
            let list = this.state[`dealer_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && (e.waiting_days == 2 || e.waiting_days == 3)))
            // console.log("clicked",filteredData)
            let columns = criticalColumns
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);

        }
        else if (cellClicked.includes("atDealer_3_or_more")) {
            let list = this.state[`dealer_${legZone.toLowerCase()}`]
            let filteredData = list.filter(e => (e.consignee_area == dealerAreaOffice && e.zone == legZone && e.waiting_days > 3))
            // console.log("clicked",filteredData)
            let columns = criticalColumns
            this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: columns,
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: (params) => {
                        params.successCallback(filteredData);
                    },
                    masterDetail: true
                }
            })
            item.node.setExpanded(!item.node.expanded);
        }
    }

    getDetailedData = rowData => {

        let areaOffice = groupBy(rowData, rdata => rdata.consignee_area);
        let detailRowData = []
        areaOffice.forEach((item, itemKey) => {
            let totalTransitTrucks = []
            let eta_0_1 = []
            let eta_2_3 = []
            let eta_3_more = []
            let dealerTotal = []
            let dealer_0_1 = []
            let dealer_2_3 = []
            let dealer_3_more = []
            var pushtorow = {}
            item.map(e => {
                // console.log("e",e)
                if (e.status == 2) {
                    totalTransitTrucks.push(e)
                    if (e.eta_days <= 1) eta_0_1.push(e)
                    if (e.eta_days == 2 || e.eta_days == 3) eta_2_3.push(e)
                    if (e.eta_days > 3) eta_3_more.push(e)
                }
                if (e.status > 2) {
                    dealerTotal.push(e)
                    if (e.waiting_days <= 1) dealer_0_1.push(e)
                    if (e.waiting_days == 2 || e.waiting_days == 3) dealer_2_3.push(e)
                    if (e.waiting_days > 3) dealer_3_more.push(e)
                }
            })
            pushtorow = {
                consignee_area: item[0].consignee_area,
                transitVehicleCount: totalTransitTrucks.length,
                eta_0_to_1: eta_0_1.length,
                eta_2_to_3: eta_2_3.length,
                eta_3_or_more: eta_3_more.length,
                atDealerTotalTruck: dealerTotal.length,
                atDealer_0_to_1: dealer_0_1.length,
                atDealer_2_to_3: dealer_2_3.length,
                atDealer_3_or_more: dealer_3_more.length,
            }
            detailRowData.push(pushtorow)
            // console.log('areaOffice',item)
        })

        let commentHeaders = [
            {
                headerName: "Area office",
                // rowGroup: true,
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "consignee_area",
                width: 150,
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Total Trucks",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "transitVehicleCount",
                width: 100,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "bgColorSuccessLight",
                    "cellstylegridM",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'ETA to Reach At Dealership 0-1 Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "eta_0_to_1",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'ETA to Reach At Dealership 2-3 Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "eta_2_to_3",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'ETA to Reach At Dealership 3 or More Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "eta_3_or_more",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: "Total Trucks",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                field: "atDealerTotalTruck",
                width: 100,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "bgColorSuccessLight",
                    "cellstylegridM",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'Waiting At Dealership 0-1 Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                field: "atDealer_0_to_1",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'Waiting At Dealership 2-3 Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                field: "atDealer_2_to_3",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
            {
                headerName: 'Waiting At Dealership 3 or More Days:',
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                field: "atDealer_3_or_more",
                width: 155,
                cellClass: [
                    "cellTopHeaderTemplate16",
                    "cellstylegridW",
                    // "cellstylegrid",
                    "cpointer"
                ],
                chartDataType: "series"
            },
        ]

        let detailCellRendererParams = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'Area Wise',
            },
            getDetailRowData: detailRowData,
            // masterDetail: true
        }
        // console.log("detailCellRendererParams",detailCellRendererParams)
        this.setState({
            sliderZoneTranslate: "slider-translate-75p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: detailRowData,
            sliderTitle: 'Area Wise Data',
            sliderColumns1: commentHeaders
        })
    }

    columnsForUnloaded = (rowData, title) => {
        let columns = [...criticalColumns, {
            headerName: "Left Dealer Date",
            field: "left_dealer_marked",
            width: 150,
            // pinned: 'left',
            cellRenderer: '', resizable: true,
            comparator: dateComparator,
            valueGetter: function (params) {
                if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                }
                else {
                    return "NA";
                }
            },
        },]
        columns.splice(10, 2)
        this.setState({
            sliderCriticalTranslate: "slider-translate-75p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: rowData,
            sliderTitle: title,
            sliderColumns: columns,
        })
    }

    columnsTransit = (rowData, title) => {
        this.setState({
            sliderCriticalTranslate: "slider-translate-75p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: rowData,
            sliderTitle: title,
            sliderColumns: criticalColumns.slice(0, criticalColumns.length - 1)
        })
    }

    columnsDealer = (rowData, title) => {
        this.setState({
            sliderCriticalTranslate: "slider-translate-75p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: rowData,
            sliderTitle: title,
            // sliderColumns: criticalColumns.slice(0,criticalColumns.length-1 )
        })
    }

    onShowLegs = async (event) => {
        const { intransit_south, intransit_north, intransit_east, intransit_west, intransit_central, dealer_south, dealer_west, dealer_north, dealer_east,
            dealer_central, central_eta_days_0_1, central_eta_days_2_3, central_eta_days_3_more,
            east_eta_days_0_1, east_eta_days_2_3, east_eta_days_3_more, west_eta_days_0_1, west_eta_days_2_3, west_eta_days_3_more, north_eta_days_0_1, north_eta_days_2_3, north_eta_days_3_more, south_eta_days_0_1, south_eta_days_2_3, south_eta_days_3_more, central_dealer_days_0_1, central_dealer_days_2_3, central_dealer_days_3_more, east_dealer_days_0_1, east_dealer_days_2_3, east_dealer_days_3_more, west_dealer_days_0_1, west_dealer_days_2_3, west_dealer_days_3_more, north_dealer_days_0_1, north_dealer_days_2_3, north_dealer_days_3_more, south_dealer_days_0_1, south_dealer_days_2_3, south_dealer_days_3_more, today_unloaded,
            yesterday_unloaded, today_unloaded_central, today_unloaded_east, today_unloaded_north,
            today_unloaded_south, today_unloaded_west, yesterday_unloaded_central, yesterday_unloaded_east,
            yesterday_unloaded_north, yesterday_unloaded_south, yesterday_unloaded_west } = this.state
        console.log("event 443", event)
        // console.log('intransit_central',intransit_central,'\n',dealer_central)
        if (event.colDef.field === "today_unloaded" && event.data.zone === "Grand Total") {
            // for the Area Office columns data
            let rowData = today_unloaded
            // let subGridData = this.getDetailedData(rowData)
            this.columnsForUnloaded(rowData, 'Today Unloaded')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "Grand Total") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded
            // let subGridData = this.getDetailedData(rowData)             
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded')

        }
        else if (event.colDef.field === "today_unloaded" && event.data.zone === "Central") {
            // for the Area Office columns data
            let rowData = today_unloaded_central
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'Central'})            
            this.columnsForUnloaded(rowData, 'Today Unloaded Central')

        }
        else if (event.colDef.field === "today_unloaded" && event.data.zone === "North") {
            // for the Area Office columns data
            let rowData = today_unloaded_north
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'North'})           
            this.columnsForUnloaded(rowData, 'Today Unloaded North')

        }
        else if (event.colDef.field === "today_unloaded" && event.data.zone === "South") {
            // for the Area Office columns data
            let rowData = today_unloaded_south
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'South'}) 
            this.columnsForUnloaded(rowData, 'Today Unloaded South')

        }
        else if (event.colDef.field === "today_unloaded" && event.data.zone === "East") {
            // for the Area Office columns data
            let rowData = today_unloaded_east
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'East'})   
            this.columnsForUnloaded(rowData, 'Today Unloaded East')

        }
        else if (event.colDef.field === "today_unloaded" && event.data.zone === "West") {
            // for the Area Office columns data
            let rowData = today_unloaded_west
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'West'})            
            this.columnsForUnloaded(rowData, 'Today Unloaded West')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "Central") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded_central
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'Central'})          
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded Central')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "South") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded_south
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'South'})
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded South')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "North") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded_north
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'North'})
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded North')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "West") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded_west
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'West'})      
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded West')

        }
        else if (event.colDef.field === "yesterday_unloaded" && event.data.zone === "East") {
            // for the Area Office columns data
            let rowData = yesterday_unloaded_east
            // let subGridData = this.getDetailedData(rowData)
            // this.setState({legZone : 'East'})     
            this.columnsForUnloaded(rowData, 'Yesterday Unloaded East')

        }
        else if (event.colDef.field === "area_office" && event.data.zone === "Central") {
            // for the Area Office columns data
            let rowData = [...intransit_central, ...dealer_central]
            let subGridData = this.getDetailedData(rowData)
            this.setState({ legZone: 'Central' })
        }
        else if (event.colDef.field === "area_office" && event.data.zone === "East") {
            let rowData = [...intransit_east, ...dealer_east]
            let subGridData = this.getDetailedData(rowData)
            this.setState({ legZone: 'East' })
        }
        else if (event.colDef.field === "area_office" && event.data.zone === "West") {
            let rowData = [...intransit_west, ...dealer_west]
            let subGridData = this.getDetailedData(rowData)
            this.setState({ legZone: 'West' })
        }
        else if (event.colDef.field === "area_office" && event.data.zone === "North") {
            let rowData = [...intransit_north, ...dealer_north]
            let subGridData = this.getDetailedData(rowData)
            this.setState({ legZone: 'North' })
        }
        else if (event.colDef.field === "area_office" && event.data.zone === "South") {
            let rowData = [...intransit_south, ...dealer_south]
            let subGridData = this.getDetailedData(rowData)
            this.setState({ legZone: 'South' })
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "Grand Total") {
            let gtList = []
            if (intransit_south.length > 0) gtList = [...intransit_south]
            if (intransit_north.length > 0) gtList = [...gtList, ...intransit_north]
            if (intransit_east.length > 0) gtList = [...gtList, ...intransit_east]
            if (intransit_west.length > 0) gtList = [...gtList, ...intransit_west]
            if (intransit_central.length > 0) gtList = [...gtList, ...intransit_central]
            // gtList.map(e =>{
            //     if(e.truck_no == 'HR55R2139' || e.truck_no == 'NL01AA0032' || e.truck_no == 'NL01Q1488'){
            //         console.log("check the sheep", e, '\n type',)
            //     }else{
            //         console.log('item',e)
            //     }
            // })
            this.columnsTransit(gtList, 'In Transit Total Data')
        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "Grand Total") {
            let gtList = []
            if (dealer_south.length > 0) gtList = [...dealer_south]
            if (dealer_west.length > 0) gtList = [...gtList, ...dealer_west]
            if (dealer_north.length > 0) gtList = [...gtList, ...dealer_north]
            if (dealer_east.length > 0) gtList = [...gtList, ...dealer_east]
            if (dealer_central.length > 0) gtList = [...gtList, ...dealer_central]
            this.columnsDealer(gtList, 'At Dealer Total Data')
        }

        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_dealer_days_0_1.length > 0) gtList = [...east_dealer_days_0_1]
            if (west_dealer_days_0_1.length > 0) gtList = [...gtList, ...west_dealer_days_0_1]
            if (north_dealer_days_0_1.length > 0) gtList = [...gtList, ...north_dealer_days_0_1]
            if (south_dealer_days_0_1.length > 0) gtList = [...gtList, ...south_dealer_days_0_1]
            if (central_dealer_days_0_1.length > 0) gtList = [...gtList, ...central_dealer_days_0_1]
            this.columnsDealer(gtList, 'At Dealer 0 to 1 Day Total Data')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_dealer_days_2_3.length > 0) gtList = [...east_dealer_days_2_3]
            if (west_dealer_days_2_3.length > 0) gtList = [...gtList, ...west_dealer_days_2_3]
            if (north_dealer_days_2_3.length > 0) gtList = [...gtList, ...north_dealer_days_2_3]
            if (south_dealer_days_2_3.length > 0) gtList = [...gtList, ...south_dealer_days_2_3]
            if (central_dealer_days_2_3.length > 0) gtList = [...gtList, ...central_dealer_days_2_3]
            this.columnsDealer(gtList, 'At Dealer 2 to 3 Days Total Data')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_dealer_days_3_more.length > 0) gtList = [...east_dealer_days_3_more]
            if (west_dealer_days_3_more.length > 0) gtList = [...gtList, ...west_dealer_days_3_more]
            if (north_dealer_days_3_more.length > 0) gtList = [...gtList, ...north_dealer_days_3_more]
            if (south_dealer_days_3_more.length > 0) gtList = [...gtList, ...south_dealer_days_3_more]
            if (central_dealer_days_3_more.length > 0) gtList = [...gtList, ...central_dealer_days_3_more]
            this.columnsDealer(gtList, 'At Dealer 3 or More Days Total Data')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_eta_days_0_1.length > 0) gtList = [...east_eta_days_0_1]
            if (west_eta_days_0_1.length > 0) gtList = [...gtList, ...west_eta_days_0_1]
            if (north_eta_days_0_1.length > 0) gtList = [...gtList, ...north_eta_days_0_1]
            if (south_eta_days_0_1.length > 0) gtList = [...gtList, ...south_eta_days_0_1]
            if (central_eta_days_0_1.length > 0) gtList = [...gtList, ...central_eta_days_0_1]
            this.columnsTransit(gtList, 'In Transit eta 0 to 1 Data')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_eta_days_2_3.length > 0) gtList = [...east_eta_days_2_3]
            if (west_eta_days_2_3.length > 0) gtList = [...gtList, ...west_eta_days_2_3]
            if (north_eta_days_2_3.length > 0) gtList = [...gtList, ...north_eta_days_2_3]
            if (south_eta_days_2_3.length > 0) gtList = [...gtList, ...south_eta_days_2_3]
            if (central_eta_days_2_3.length > 0) gtList = [...gtList, ...central_eta_days_2_3]
            this.columnsTransit(gtList, 'In Transit eta 2 to 3 Data')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "Grand Total") {
            let gtList = []
            if (east_eta_days_3_more.length > 0) gtList = [...east_eta_days_3_more]
            if (west_eta_days_3_more.length > 0) gtList = [...gtList, ...west_eta_days_3_more]
            if (north_eta_days_3_more.length > 0) gtList = [...gtList, ...north_eta_days_3_more]
            if (south_eta_days_3_more.length > 0) gtList = [...gtList, ...south_eta_days_3_more]
            if (central_eta_days_3_more.length > 0) gtList = [...gtList, ...central_eta_days_3_more]
            this.columnsTransit(gtList, 'In Transit eta 3 or more Total Data')

        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "West") {
            this.columnsDealer(dealer_west, 'At Dealer West Data')
        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "East") {
            this.columnsDealer(dealer_east, 'At dealer East Data')
        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "North") {
            this.columnsDealer(dealer_north, 'At dealer North Data')
        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "South") {
            this.columnsDealer(dealer_south, 'At dealer South Data')
        }
        else if (event.colDef.field === "atDealerTotalTruck" && event.data.zone === "Central") {
            this.columnsDealer(dealer_central, 'At dealer Central Data')
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "West") {
            this.columnsTransit(intransit_west, 'In Transit West Data')
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "East") {
            this.columnsTransit(intransit_east, 'In Transit East Data')
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "North") {
            this.columnsTransit(intransit_north, 'In Transit North Data')
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "South") {
            this.columnsTransit(intransit_south, 'In Transit South Data')
        }
        else if (event.colDef.field === "transitVehicleCount" && event.data.zone === "Central") {
            this.columnsTransit(intransit_central, 'In Transit Central Data')
        }
        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "West") {
            this.columnsDealer(west_dealer_days_0_1, 'West 0-1 Days')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "West") {
            this.columnsDealer(west_dealer_days_2_3, 'West 2- 3 Days')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "West") {
            this.columnsDealer(west_dealer_days_3_more, 'West 3 or more Days')
        }
        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "East") {
            this.columnsDealer(east_dealer_days_0_1, 'East zero to one day')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "East") {
            this.columnsDealer(east_dealer_days_2_3, 'East Two To Three Days')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "East") {
            this.columnsDealer(east_dealer_days_3_more, 'East More than 3 Days')
        }
        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "North") {
            this.columnsDealer(north_dealer_days_0_1, 'North 0 to 1 Day')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "North") {
            this.columnsDealer(north_dealer_days_2_3, 'North 2 to 3 Days')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "North") {
            this.columnsDealer(north_dealer_days_3_more, 'North 3 or More Days')
        }
        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "South") {
            this.columnsDealer(south_dealer_days_0_1, 'South 0 to 1 Day')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "South") {
            this.columnsDealer(south_dealer_days_2_3, 'South 2 to 3 Days')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "South") {
            this.columnsDealer(south_dealer_days_3_more, 'South 3 or More Days')
        }
        else if (event.colDef.field === "atDealer_0_to_1" && event.data.zone === "Central") {
            this.columnsDealer(central_dealer_days_0_1, 'Central 0 to 1 Day')
        }
        else if (event.colDef.field === "atDealer_2_to_3" && event.data.zone === "Central") {
            this.columnsDealer(central_dealer_days_2_3, 'Central 2 to 3 Days')
        }
        else if (event.colDef.field === "atDealer_3_or_more" && event.data.zone === "Central") {
            this.columnsDealer(central_dealer_days_3_more, 'Central 3 or More Days')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "Central") {
            this.columnsTransit(central_eta_days_0_1, 'Central ETA 0-1 day')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "Central") {
            this.columnsTransit(central_eta_days_2_3, 'Central Eta 2 - 3 Days')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "Central") {
            this.columnsTransit(central_eta_days_3_more, 'Central ETA more than 3 days')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "West") {
            this.columnsTransit(west_eta_days_0_1, 'West ETA 0 - 1 Day')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "West") {
            this.columnsTransit(west_eta_days_2_3, 'West ETA 2-3 Days')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "West") {
            this.columnsTransit(west_eta_days_3_more, 'West ETA 3 or more Days')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "East") {
            this.columnsTransit(east_eta_days_0_1, 'East ETA  0 - 1 Day')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "East") {
            this.columnsTransit(east_eta_days_2_3, 'East ETA 2-3 Days')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "East") {
            this.columnsTransit(east_eta_days_3_more, 'East ETA More than 3 Days')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "North") {
            this.columnsTransit(north_eta_days_0_1, 'North ETA 0 to 1 Day')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "North") {
            this.columnsTransit(north_eta_days_2_3, 'North ETA 2 to 3 Days')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "North") {
            this.columnsTransit(north_eta_days_3_more, 'North ETA 3 or More Days')
        }
        else if (event.colDef.field === "eta_0_to_1" && event.data.zone === "South") {
            this.columnsTransit(south_eta_days_0_1, 'South ETA 0 -1 Day')
        }
        else if (event.colDef.field === "eta_2_to_3" && event.data.zone === "South") {
            this.columnsTransit(south_eta_days_2_3, 'South ETA 2 to 3 Days')
        }
        else if (event.colDef.field === "eta_3_or_more" && event.data.zone === "South") {
            this.columnsTransit(south_eta_days_3_more, 'South ETA 3 or More Days')
        }
    }

    onFirstDataRendered = async () => {

    }

    onRowGroupOpened = () => {

    }

    onCloseSliderDiv = () => {
        this.setState({
            sliderZoneTranslate: "",
            sliderCriticalTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    };

    render() {
        const { show, basicType, basicTitle, startDate, endDate, flZone, flTransporter, filterTransporters, loadshow, overly, flPlant, plantsList, filterConsigneeName, consigneeName, consigneeCode, filterCities, filterStates, filterZones, consigneeCity, consigneeState, filterConsigneeArea, areaOffice, inTransitTrucks, unloadedCount, threeOrMoreCount, grandTotal, rowData, sliderTitle, time, sliderColumns, intransit_zero_to_one_days, intransit_two_to_three_days, intransit_three_and_more, zero_to_one_day, two_to_three_days, three_or_more_days, count_unloaded, inTransitTruckList, consignee_area_office, waitingAtDealer, intransit_today, prev_unloaded_count, totalUnloadedList, zone, sliderColumns1, showUnloaded } = this.state

        let gridData = []
        if (zone !== undefined && zone !== "undefined" && zone !== null && zone !== '') {
            gridData = rowData.filter(e => e.zone == zone)
            var isZone = true
        } else {
            isZone = false
            gridData = rowData
        }
        if (consignee_area_office !== 'undefined' && consignee_area_office !== undefined && consignee_area_office !== '') {
            isZone = false
        }

        const columnDefs = [
            {
                headerName: "",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                hide: isZone,
                children: [
                    {
                        headerName: "Zone",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "zone",
                        width: 150,
                        hide: isZone,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                width: 155,
                chartDataType: "excluded",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                children: [
                    {
                        headerName: "Area office",
                        // rowGroup: true,
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "area_office",
                        width: 150,
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },

                ]
                //chartDataType: 'series',
                //hide:true
            },
            {
                headerName: "Trucks At Dealership",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                children: [
                    {
                        headerName: "Total Trucks",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "atDealerTotalTruck",
                        width: 100,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "bgColorSuccessLight",
                            "cellstylegridM",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'Waiting At Dealership 0-1 Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "atDealer_0_to_1",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'Waiting At Dealership 2-3 Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "atDealer_2_to_3",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'Waiting At Dealership 3 or More Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridSk"],
                        field: "atDealer_3_or_more",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "Trucks In Transit",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                children: [
                    {
                        headerName: "Total Trucks",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "transitVehicleCount",
                        width: 100,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "bgColorSuccessLight",
                            "cellstylegridM",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'ETA to Reach At Dealership 0-1 Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "eta_0_to_1",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'ETA to Reach At Dealership 2-3 Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "eta_2_to_3",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'ETA to Reach At Dealership 3 or More Days:',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "eta_3_or_more",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                ]
            },
            {
                headerName: "Unloaded",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                children: [
                    {
                        headerName: "Yesterday Unloaded",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "yesterday_unloaded",
                        width: 100,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "bgColorSuccessLight",
                            "cellstylegridM",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                    {
                        headerName: 'Today Unloaded',
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridM"],
                        field: "today_unloaded",
                        width: 155,
                        cellClass: [
                            "cellTopHeaderTemplate16",
                            "cellstylegridW",
                            // "cellstylegrid",
                            "cpointer"
                        ],
                        chartDataType: "series"
                    },
                ]
            },
        ]



        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="card col-xl-12">
                    {/* filters */}
                    <div className="card-body col-xl-12 col-lg-12">
                        <div className='row col-xl-12 col-lg-12'>
                            {isZone ? '' : <div className='row col-xl-12 col-lg-12'>
                                {(consignee_area_office !== 'undefined' && consignee_area_office !== undefined && consignee_area_office !== '') ? '' : (
                                    <>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>From Date</label>
                                            <Datetime value={startDate}
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                                dateFormat="YYYY-MM-DD"
                                                name="startDate"

                                                onChange={this.handlerStartDateTime} />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>To Date</label>
                                            <Datetime value={endDate}
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: 'To', name: 'toDate', autoComplete: 'off', readOnly: true }}
                                                dateFormat="YYYY-MM-DD"
                                                name="toDate"
                                                onChange={this.handlerToDateTime} />
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2">
                                            <label>Zone :  </label>
                                            <Select
                                                className="border-radius-0"
                                                isMulti={true}
                                                id="selectedCluster"
                                                style={{ borderRadius: "0px" }}
                                                value={flZone}
                                                onChange={this.newFilter.bind(this, "flZone")}
                                                options={filterZones}
                                            />
                                        </div>

                                    </>)}
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Transporter :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="selectedTransporter"
                                        style={{ borderRadius: "0px" }}
                                        value={flTransporter}
                                        onChange={this.newFilter.bind(this, "flTransporter")}
                                        options={filterTransporters}
                                    />
                                </div>
                            </div>}
                            {/* specific filters zonewise and for mainusers also */}
                            <div className='row col-xl-12 col-lg-12'>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Plants :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="flPlant"
                                        style={{ borderRadius: "0px" }}
                                        value={flPlant}
                                        onChange={this.newFilter.bind(this, "flPlant")}
                                        options={plantsList}
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Dealer Name :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="consigneeName"
                                        style={{ borderRadius: "0px" }}
                                        value={consigneeName}
                                        onChange={this.newFilter.bind(this, "consigneeName")}
                                        options={filterConsigneeName}
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Dealer City :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="consigneeCity"
                                        style={{ borderRadius: "0px" }}
                                        value={consigneeCity}
                                        onChange={this.newFilter.bind(this, "consigneeCity")}
                                        options={filterCities}
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Dealer State :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="consigneeState"
                                        style={{ borderRadius: "0px" }}
                                        value={consigneeState}
                                        onChange={this.newFilter.bind(this, "consigneeState")}
                                        options={filterStates}
                                    />
                                </div>
                                {(consignee_area_office !== 'undefined' && consignee_area_office !== undefined && consignee_area_office !== '') ? '' : (<div className="form-group col-xl-2 col-lg-2">
                                    <label>Area Office:  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="areaOffice"
                                        style={{ borderRadius: "0px" }}
                                        value={areaOffice}
                                        onChange={this.newFilter.bind(this, "areaOffice")}
                                        options={filterConsigneeArea}
                                    />
                                </div>)}
                                <div className="form-group col-xl-2 col-lg-2" style={{ marginTop: "30px" }}>
                                    <button type="button" className="btn btn-success" onClick={this.onClickDashboardDetails}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* counters start */}
                    <div className="crm-numbers pb-0">
                        <div className="row">
                            <div className="col cirlce-d">
                                <span className="f20" ><i className="icofont icofont-map-pins txt-dark"></i><br /> In Transit Trucks</span>
                                <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("inTransitTrucks")}><span className="counter"><CountUp end={(inTransitTrucks != 0) ? inTransitTrucks : 0} /></span></h4>
                                <br />
                                <div className="row f16">
                                    <div className="col-xl-6 counter txt-dark cursorPointer" onClick={() => this.onClickShowClickableData("inTransitToday")}>
                                        Today (ETA) : &nbsp;
                                        <CountUp end={(intransit_today.length != '') ? intransit_today.length : 0} />
                                    </div>
                                    <div className="col-xl-6 counter txt-dark cursorPointer" onClick={() => this.onClickShowClickableData("inTransit0_1")}>
                                        Tomorrow (ETA) : &nbsp;
                                        <CountUp end={(intransit_zero_to_one_days != '') ? intransit_zero_to_one_days : 0} />
                                    </div>

                                </div>
                                <div className="row f16">
                                    <div className="col-xl-6 counter txt-dark cursorPointer" onClick={() => this.onClickShowClickableData("inTransit2_3")}>
                                        2 to 3 Days (ETA) : &nbsp;
                                        <CountUp end={(intransit_two_to_three_days != '') ? intransit_two_to_three_days : 0} />
                                    </div>
                                    <div className="col-xl-6 counter txt-dark cursorPointer" onClick={() => this.onClickShowClickableData("inTransit3orMore")}>
                                        More than 3 (ETA) : &nbsp;
                                        <CountUp end={(intransit_three_and_more != '') ? intransit_three_and_more : 0} />
                                    </div>
                                </div>


                            </div>
                            <div className="col cirlce-d">
                                <span className="f20"><i className="icofont icofont-ui-calculator txt-warning"></i><br /> Waiting At DealerShip</span>
                                <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("totalWaitingAtDealer")}><span className="counter"><CountUp end={((waitingAtDealer.length) != '') ? (waitingAtDealer.length) : 0} /></span></h4>
                                <br />
                                <div className="row f16">
                                    <div className="col-xl-4 counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("zeroToOne")}>
                                        0-1 Days : &nbsp;
                                        <CountUp end={(zero_to_one_day != '') ? zero_to_one_day : 0} />
                                    </div>
                                    <div className="col-xl-4 counter txt-warning cursorPointer" onClick={() => this.onClickShowClickableData("twoToThree")}>
                                        2-3 Days : &nbsp;
                                        <CountUp end={(two_to_three_days != '') ? two_to_three_days : 0} />
                                    </div>
                                    <div className="col-xl-4 counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("threeOrMore")}>
                                        More than 3 Days : &nbsp;
                                        <CountUp end={(three_or_more_days.length != '') ? three_or_more_days.length : 0} />
                                    </div>
                                </div>
                            </div>
                            <div className="col cirlce-d" >
                                <span className="f20"><i className="icofont icofont-map-pins txt-primary"></i><br /> Unloaded</span>
                                {showUnloaded ? <h4 className="txt-dark f20"><span className="counter cursorPointer" onClick={() => this.onClickShowClickableData("totalUnloaded")}><CountUp end={((totalUnloadedList.length) != '') ? (totalUnloadedList.length) : 0} /></span></h4> : ""}
                                <br />
                                <div className="row f16 cursorPointer">
                                    <div className="col-xl-6 counter txt-success" onClick={() => this.onClickShowClickableData("yesterdayUnloaded")}>
                                        Yesterday : &nbsp;
                                        <CountUp end={(prev_unloaded_count != '') ? prev_unloaded_count : 0} />
                                    </div>
                                    <div className="col-xl-6 counter txt-info cursorPointer" onClick={() => this.onClickShowClickableData("todayUnloaded")}>
                                        Today : &nbsp;
                                        <CountUp end={(unloadedCount != '') ? unloadedCount : 0} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* counters end */}

                </div>
                <div className='card col-xl-12'>
                    <div className="card-header">
                        <h5>
                            <i className="icon-map cus-i"></i> <span>At DealerShip Dashboard</span>
                            <span className='float-right' >{time}</span>
                        </h5>
                    </div>
                    {/* grid data start*/}

                    <div
                        id="myGrid"
                        style={{
                            height: "425px",
                            // width: "100%"
                        }}
                        className={"ag-theme-balham"}
                    >
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                            sideBar={false}
                            groupUseEntireRow={false}
                            enableRangeSelection={true}
                            groupRowAggNodes={this.groupRowAggNodes}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            pinnedBottomRowData={grandTotal}
                            rowData={gridData}
                            groupHeaderHeight={this.state.groupHeaderHeight}
                            headerHeight={this.state.headerHeight}
                            floatingFiltersHeight={this.state.floatingFiltersHeight}
                            pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                            pivotHeaderHeight={this.state.pivotHeaderHeight}
                            rowHeight={this.state.rowHeight}
                            frameworkComponents={this.state.frameworkComponents}
                            popupParent={this.state.popupParent}
                            enableCharts={true}
                            onFirstDataRendered={this.onFirstDataRendered}
                            suppressChartRanges={true}
                            //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
                            onRowGroupOpened={this.onRowGroupOpened}
                            //suppressCount={true}
                            onCellClicked={this.onShowLegs}
                        // detailCellRendererParams={this.state.detailCellRendererParams}
                        // masterDetail={true}
                        />
                    </div>
                    {/* grid data end */}
                    {/* slider Data */}
                    <div
                        className={"slide-r " + this.state.sliderCriticalTranslate}
                        style={{ overflow: "auto" }}
                    >
                        <div className="slide-r-title">
                            <h4>
                                {sliderTitle}
                                <span
                                    className="float-right closebtn"
                                    style={{ marginRight: "25px" }}
                                    onClick={this.onCloseSliderDiv}
                                >
                                    X
                                </span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <div
                                    id="myGrid"
                                    style={{
                                        height: "600px",
                                        width: "100%"
                                    }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={sliderColumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowCriticalData}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                    // rowHeight={this.state.rowHeight}
                                    // onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* area zone wise grid */}
                    <div
                        className={"slide-r " + this.state.sliderZoneTranslate}
                        style={{ overflow: "auto" }}
                    >
                        <div className="slide-r-title">
                            <h4>
                                {sliderTitle}
                                <span
                                    className="float-right closebtn"
                                    style={{ marginRight: "25px" }}
                                    onClick={this.onCloseSliderDiv}
                                >
                                    X
                                </span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <div
                                    id="myGrid"
                                    style={{
                                        height: "600px",
                                        width: "100%"
                                    }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={sliderColumns1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowCriticalData}
                                        paginationPageSize={this.state.paginationPageSize}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        pivotHeaderHeight={this.state.pivotHeaderHeight}
                                        headerHeight={this.state.headerHeight}
                                        floatingFiltersHeight={this.state.floatingFiltersHeight}
                                        pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                                        onCellClicked={this.getZoneLegData}
                                        rowHeight={this.state.rowHeight}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        pagination={true}
                                        masterDetail={true}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressRowClickSelection={true}
                                    // onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* are zone wise grid end */}
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>

            </div>
        )
    }

}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}