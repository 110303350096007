/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import CountUp from 'react-countup';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid"
import ApprovalDeviationRoute from "./approvaldeviationroute";
import Implementroute from "./implementroute";
import $ from 'jquery';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const map_plants = {"HM4N": "Neemrana", "HHHU": "Haridwar", "HHHD": "Dharuhera", "HHHG": "Gurgaon", "HM5V": "Halol", "HM6C": "Chittoor"}

export default class OptimizeDeviationRoutes extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: 'show-n',
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                autoHeight: true,
                resizable: true
            },
            rowData: [],
            headerHeight: 60,
            rowHeight: 50,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
                MyHeaderComponent: MyHeaderComponent,
                Showtolls: Showtolls,
                ShowTollsList: ShowTollsList,
                ApprovalDeviationRoute: ApprovalDeviationRoute,
                Implementroute: Implementroute
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 1000,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "Route Deviations",
            originaldata: "",
            orignalresponse: "",
            mapinfo: "",
            originoption: { "value": "", "label": "Select Origin" },
            destinationoption: { "value": "", "label": "Select Destination" },
            destinationarr: [],
            selectedorigin: '',
            selecteddestination: '',
            columnDefs: [],
            checkedinputs: [],
            uncheckedinputs: [],
            noofroutes: 3,
            tolls: [],
            tollshow: false,
            plantcoords: "",
            plantname: "",
            sliderTranslate: "",
            viewtolls: [],
            tollrowid: "",
            routeid: "",
            routeapprovestatus: "",
            open: false,
            route_reason: "",
            optimizedroutes: 0,
            identifiedroutes: 0,
            savingrealized: 0,
            estimateannual: 0,
            implemented: 0,
            rejected: 0,
            hold: 0,
            takenimplement: 0,
            implementroutedate: "",
            implementrouteid: '',
            primarydata: [],
            secondarydata: [],
            tabprim: "show-m",
            tabsec: "show-n",
            btnactive: "btn-success",
            btnsecactive: "",
            btnprimactive: "",

            primidentifiedroutes: "",
            primoptimizedroutes: "",
            primtaken_for_implementation: "",
            primimplemented: "",
            primrejected: "",
            primhold: "",

            secidentifiedroutes: "",
            secoptimizedroutes: "",
            sectaken_for_implementation: "",
            secimplemented: "",
            secrejected: "",
            sechold: "",
            primestimateannual: "",
            primsavingrealized: "",
            secestimateannual: "",
            secsavingrealized: "",
            clicktab: "all",
            allidentifiedroutes: "",
            alloptimizedroutes: "",
            alltaken_for_implementation: "",
            allimplemented: "",
            allrejected: "",
            allhold: "",
            allestimateannual: "",
            allsavingrealized: "",
            potential_savings_under_implementation: "",
            primary_potential_savings_under_implementation: "",
            secondary_potential_savings_under_implementation: "",
            potential_savings: "",
            alldata: [],
            originalalldata: [],
            originalprimdata: [],
            originalsecdata: [],
            plant: "",
            plants: [],
            tabOneactive : "activet",
            tabTwoActive : "",
            rowDataForDealer : [],
            tabOneData : "show-m",
            tabTwoData : "show-n",
            originoptions:[],
            destinationOptions: [],
            lspusers_options: [],
            truck_types: [],
            period_types: [],
            selected_provider: {"label": "ALL", "value": "ALL"},
            selected_truck_type: {"label": "ALL", "value": "ALL"},
            selected_period_type: {"label": "ALL", "value": "ALL"},
            originoption: {"label": "ALL", "value": "ALL"},
            destinationoption: {"label": "ALL", "value": "ALL"},
            zonesOptions: [],
            consignee_area_options: [],
            selectedConsArea: {"label": "ALL", "value": "ALL"},
            selectedZone: {"label": "ALL", "value": "ALL"}
        };
        this.onClickRouteImplement = this.onClickRouteImplement.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    componentDidMount() {
        //this.onClickShowRoute()
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        // ms.push(
        //     {value:"F", label:"Gurgoan"},
        //     {value:"G", label:"Gujarat"},
        //     {value:"M", label:"Manesar"},
        //     {value:"N", label:"Nagpur"},
        //     {value:"I", label:"Silguri"},
        //     {value:"B", label:"Banglore"}
        this.setState({
            loadshow: 'show-m',
            overly: "show-m"
        })
        var map_plants = {"HM4N": "Neemrana", "HHHU": "Haridwar", "HHHD": "Dharuhera", "HHHG": "Gurgaon", "HM5V": "Halol", "HM6C": "Chittoor"}
        redirectURL.post("/dashboard/getSourceFromBaseRoutes")
        .then((response) => {
            //console.log("Ress ", response.data)
            var plantdata = response.data;
            var originoptions = [{"label": "ALL", "value": "ALL"}];
            if(plantdata.length > 0)
            {
                plantdata.map((item) => {
                    originoptions.push({
                        value:item,
                        label: map_plants[item]
                    })
                })
            }
            redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": ""}).then((response1)=>{
                var items=[{"label": "ALL", "value": "ALL"}];
                var destinationarr = response1.data;
                var check = destinationarr.map((item) =>{
                    items.push({
                        value:item,label:item 
                    })
                })
                redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", {}).then((response2)=>{
                    console.log(response2.data)
                    var lspusers_options = [];
                    var truck_types = [];
                    var period_types_options = [];
                    // var period_types = sortPeriodTypes(response2.data.period_types);
                    var period_types = response2.data.period_types;
                    console.log(period_types, "period_types")
                    response2.data.lsp_users.map((e)=>{
                        if(e != "NA")
                        {
                            var label = e
                            if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
                            lspusers_options.push({"label": label, "value": e})
                        }
                    })
                    response2.data.truck_types.map((e)=>{
                        truck_types.push({"label": e, "value": e})
                    })
                    period_types.map((e)=>{
                        period_types_options.push({"label": e, "value": e})
                    })
                    console.log(period_types, "period_types options")
                    var zonesOptions = [{"label": "ALL", "value": "ALL"}];
                    response2.data.zones.map(e => zonesOptions.push({"label": e, "value": e}));
                    var consignee_area_options = [{"label": "ALL", "value": "ALL"}];
                    response2.data.consignee_areas.map(e => consignee_area_options.push({"label": e, "value": e}));
                    this.setState({
                        originoptions: originoptions,
                        destinationOptions: items,
                        lspusers_options: lspusers_options,
                        truck_types: truck_types,
                        period_types: period_types_options,
                        zonesOptions: zonesOptions,
                        consignee_area_options: consignee_area_options,
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                    this.selectplants()
                })
                
                
            })
            
        })
        this.onLoadShowPageData();
    }

    onLoadShowPageData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var query = {
            "truck_type": this.state.selected_truck_type.value,
            "period_type": this.state.selected_period_type.value,
            "gps_provider": this.state.selected_provider.value,
        };
        // var consigner_codes = this.state.plant;
        // if (consigner_codes != '' && consigner_codes != undefined) {
        //     if (consigner_codes.value != "all") {
        //         query.consigner_codes = [consigner_codes.value];
        //     }
        // }
        if(["", undefined, null, "ALL"].includes(this.state.originoption.value) == false)
        {
            query['consigner_codes'] = [this.state.originoption.value]
        }
        if(["", undefined, null, "ALL"].includes(this.state.destinationoption.value) == false)
        {
            query['consignee_city'] = this.state.destinationoption.value
        }
        if(["", undefined, null, "ALL"].includes(this.state.selectedConsArea.value) == false)
        {
            query['consignee_area'] = this.state.selectedConsArea.value
        }
        if(["", undefined, null, "ALL"].includes(this.state.selectedZone.value) == false)
        {
            query['zone'] = this.state.selectedZone.value
        }
        // console.log(query,"query")
        redirectURL.post("/consignments/alldeviationroutes", query)
            .then((response) => {
                // console.log("Consignees ", JSON.parse(response.data.data))
                var rec = JSON.parse(response.data.data);
                var records = JSON.parse(rec.optimized_routes_data);
                var identifiedroutes = rec.total_base_routes;
                var optimizedroutes = rec.optimized_routes;
                var taken_for_implementation = rec.taken_for_implementation;
                var implemented = rec.implemented;
                var rejected = rec.rejected;
                var hold = rec.hold;
                // var estimateannual = "--";
                var savingrealized = "--";

                var primidentifiedroutes = rec.primary_trips_count;
                var primoptimizedroutes = rec.primary_trips_count;
                var primtaken_for_implementation = rec.primary_taken_for_implementation;
                var primimplemented = rec.primary_implemented;
                var primrejected = rec.primary_rejected;
                var primhold = rec.primary_hold;

                var secidentifiedroutes = rec.secondary_total_base_routes;
                var secoptimizedroutes = rec.secondary_trips_count;
                var sectaken_for_implementation = rec.secondary_taken_for_implementation;
                var secimplemented = rec.secondary_implemented;
                var secrejected = rec.secondary_rejected;
                var sechold = rec.secondary_hold;

                var potential_savings_under_implementation = rec.potential_savings_under_implementation
                var primary_potential_savings_under_implementation = rec.primary_potential_savings_under_implementation;
                var secondary_potential_savings_under_implementation = rec.secondary_potential_savings_under_implementation;

                var estimateannual = rec.estimated_annual_savings;
                var primestimateannual = rec.primary_estimated_annual_savings;
                var secestimateannual = rec.secondary_estimated_annual_savings
                var all_savings_realized = rec.all_savings_realized;
                var primary_savings_realized = rec.primary_savings_realized;
                var secondary_savings_realized = rec.secondary_savings_realized;
                //console.log("Recr ", records) 
                var recordsarr = []
                var primarydata = [];
                var secondarydata = []
                if (records.length > 0) {

                    records = records.sort(GetSortOrder("base_route_google_distance_difference"));
                    records.map((item) => {
                        console.log("item ", item)
                        // if (parseInt(item.base_route_google_distance_difference) < 0) {
                            item.sourcename = map_plants[item.consigner_code];
                            recordsarr.push(item)

                        // }
                    })
                }
                //console.log("recordsarr ", recordsarr)
                this.setState({
                    rowData: recordsarr,
                    originalalldata: recordsarr,
                    alldata: recordsarr,
                    primarydata: primarydata,
                    secondarydata: secondarydata,
                    overly: 'show-n',
                    loadshow: 'show-n',
                    optimizedroutes: optimizedroutes,
                    identifiedroutes: identifiedroutes,
                    takenimplement: taken_for_implementation,
                    implemented: implemented,
                    rejected: rejected,
                    hold: hold,
                    estimateannual: estimateannual,
                    savingrealized: all_savings_realized,

                    alloptimizedroutes: optimizedroutes,
                    allidentifiedroutes: identifiedroutes,
                    alltaken_for_implementation: taken_for_implementation,
                    allimplemented: implemented,
                    allrejected: rejected,
                    allhold: hold,
                    allestimateannual: estimateannual,
                    allsavingrealized: all_savings_realized,

                    primidentifiedroutes: primidentifiedroutes,
                    primoptimizedroutes: primoptimizedroutes,
                    primtaken_for_implementation: primtaken_for_implementation,
                    primimplemented: primimplemented,
                    primrejected: primrejected,
                    primhold: primhold,
                    primestimateannual: primestimateannual,
                    primsavingrealized: primary_savings_realized,
                    secidentifiedroutes: secidentifiedroutes,
                    secoptimizedroutes: secoptimizedroutes,
                    sectaken_for_implementation: sectaken_for_implementation,
                    secimplemented: secimplemented,
                    secrejected: secrejected,
                    sechold: sechold,
                    secestimateannual: secestimateannual,
                    secsavingrealized: secondary_savings_realized,
                    potential_savings_under_implementation: potential_savings_under_implementation,
                    secondary_potential_savings_under_implementation: secondary_potential_savings_under_implementation,
                    primary_potential_savings_under_implementation: primary_potential_savings_under_implementation,
                    potential_savings: potential_savings_under_implementation,
                    loadshow: "show-n",
                    overly: "show-n"

                })
                console.log(hold, "holdCount")
            })
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            // node.setSelected(true);

        });

    };


    onGridState = () => {
        console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slidertranslate: ''
        });

    }
    onClickRouteImplement(params) {
        //console.log("params ", params.data)
        this.setState({
            slidertranslate: "slider-translate-30p",
            overly: 'show-m',
            implementrouteid: params.data._id
        });
    }

    onCloseRouteDiv = () => {
        this.setState({
            slidertranslate: "",
            overly: 'show-n'
        });
    }

    handlerStartDateTime = (event) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) >= 10 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            implementroutedate: startdate
        });
    }
    onClickShowUpdateData() {
        var implementroutedate = this.state.implementroutedate;
        var implementrouteid = this.state.implementrouteid;
        var parameters = {
            effective_date: implementroutedate,
            id: implementrouteid
        }
        //console.log("parameters ", parameters)
        redirectURL.post("/consignments/setrouteimplementation", parameters)
            .then((response) => {
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Successfully update data",
                    slidertranslate: "",
                    overly: 'show-n'
                })
            }).catch((e) => {

            })

    }
    getBaseRoutesForDealer = () =>{
        redirectURL.post("/consignments/alldeviationroutesForDealers",)
        .then(response =>{
            let resData = JSON.parse(response.data.data)
            console.log("response",resData)
            let optimizedRouteData = JSON.parse(resData.optimized_routes_data)
            console.log("response",optimizedRouteData)
            let recordsarr = []
            
            if (optimizedRouteData.length > 0) {
                optimizedRouteData = optimizedRouteData.sort(GetSortOrder("base_route_google_distance_difference"));
                optimizedRouteData.map((item) => {
                    console.log("item ", item)
                    if (parseInt(item.base_route_google_distance_difference) < 0) {
                        item.sourcename = item.consigner_name;
                        recordsarr.push(item)
                    }
                })
            }
            this.setState({
                rowDataForDealer : recordsarr,
                loadshow : "show-n"
            })

        })
    }

    onClickTab(tab) {
        if (tab == "all") {
            var plants = [];
            plants.push({ "value": "all", "label": "All" })
            plants.push({ "value": "B", "label": "TVP Bangalore" })
            plants.push({ "value": "haryana", "label": "Haryana" })
            plants.push({ "value": "G", "label": "Gujarat" })
            plants.push({ "value": "I", "label": "Siliguri" })
            plants.push({ "value": "N", "label": "TVP Nagpur" })
            this.setState({
                clicktab: tab,
                rowData: this.state.alldata,
                btnactive: "btn-success",
                btnprimactive: "",
                btnsecactive: "",
                optimizedroutes: this.state.alloptimizedroutes,
                identifiedroutes: this.state.allidentifiedroutes,
                takenimplement: this.state.alltaken_for_implementation,
                implemented: this.state.allimplemented,
                rejected: this.state.allrejected,
                hold: this.state.allhold,
                estimateannual: this.state.allestimateannual,
                savingrealized: this.state.allsavingrealized,
                potential_savings: this.state.potential_savings_under_implementation,
                plants: plants,
            })
        }
        if (tab == "prim") {
            // console.log("optimizedroutes ", this.state.primoptimizedroutes)
            // console.log("primimplemented ", this.state.primimplemented)
            // console.log("primrejected ", this.state.primrejected)
            // console.log("primtaken_for_implementation ", this.state.primtaken_for_implementation)
            var plants = [];
            plants.push({ "value": "all", "label": "All" })
            plants.push({ "value": "haryana", "label": "Haryana" })
            plants.push({ "value": "G", "label": "Gujarat" })
            this.setState({
                clicktab: tab,
                rowData: this.state.primarydata,
                btnactive: "",
                btnprimactive: "btn-success",
                btnsecactive: "",
                optimizedroutes: this.state.primoptimizedroutes,
                identifiedroutes: this.state.primidentifiedroutes,
                takenimplement: this.state.primtaken_for_implementation,
                implemented: this.state.primimplemented,
                rejected: this.state.primrejected,
                hold: this.state.primhold,
                estimateannual: this.state.primestimateannual,
                savingrealized: this.state.primsavingrealized,
                potential_savings: this.state.primary_potential_savings_under_implementation,
                plants: plants,
            })
        }
        if (tab == "second") {
            var plants = [];
            plants.push({ "value": "all", "label": "All" })
            plants.push({ "value": "B", "label": "TVP Bangalore" })
            plants.push({ "value": "I", "label": "Siliguri" })
            plants.push({ "value": "N", "label": "TVP Nagpur" })
            this.setState({
                clicktab: tab,
                rowData: this.state.secondarydata,
                btnactive: "",
                btnprimactive: "",
                btnsecactive: "btn-success",
                optimizedroutes: this.state.secoptimizedroutes,
                identifiedroutes: this.state.secidentifiedroutes,
                takenimplement: this.state.sectaken_for_implementation,
                implemented: this.state.secimplemented,
                rejected: this.state.secrejected,
                hold: this.state.sechold,
                estimateannual: this.state.secestimateannual,
                savingrealized: this.state.secsavingrealized,
                potential_savings: this.state.secondary_potential_savings_under_implementation,
                plants: plants,
            })
        }

        if(tab == "plant"){
            this.setState({
                tabOneactive : "activet",
                tabOneData : "show-m",
                tabTwoData : "show-n",
                tabTwoActive : ""
            })
        }
        if(tab == "dealer"){
            this.setState({
                tabOneactive : "",
                tabTwoActive : "activet",
                tabOneData : "show-n",
                tabTwoData : "show-m",
                loadshow: "show-m"
            })
            this.getBaseRoutesForDealer()
        }
    }
    onClickShowClickableData(params) {
        // console.log("Params ", params)
        // console.log("clicktab ", this.state.clicktab)

        if (this.state.clicktab == "all") {
            var records = this.state.originalalldata;
            var takenarr = []
            var rejectarr = []
            var holdarr = []
            var implementarr = []
            if (records.length > 0) {
                records.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        takenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        rejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        holdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        implementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.originalalldata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: takenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: rejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: holdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: implementarr,
                })
            }
        }
        if (this.state.clicktab == "prim") {
            var primrecords = this.state.primarydata;
            var primtakenarr = []
            var primrejectarr = []
            var primholdarr = []
            var primimplementarr = []
            if (primrecords.length > 0) {
                primrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        primtakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        primrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        primholdarr.push(item)
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        primimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.primarydata,
                })
            }
            if (params == "taken") {
                // console.log("primtakenarr ", primtakenarr)
                this.setState({
                    rowData: primtakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: primrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: primholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: primimplementarr,
                })
            }
        }
        if (this.state.clicktab == "second") {
            var secrecords = this.state.secondarydata;
            var sectakenarr = []
            var secrejectarr = []
            var secholdarr = [];
            var secimplementarr = []
            if (secrecords.length > 0) {
                secrecords.map((item) => {
                    if (item.route_approval_status == 'approved') {
                        sectakenarr.push(item)
                    }
                    if (item.route_approval_status == "rejected") {
                        secrejectarr.push(item)
                    }
                    if (item.route_approval_status == "hold") {
                        secholdarr.push(item)
                    }
                    if (params == "hold") {
                        this.setState({
                            rowData: secholdarr,
                        })
                    }
                    if (item.route_approval_status == "implement" || item.implement_status == 1) {
                        secimplementarr.push(item)
                    }
                })
            }
            if (params == 'all') {
                this.setState({
                    rowData: this.state.secondarydata,
                })
            }
            if (params == "taken") {
                this.setState({
                    rowData: sectakenarr,
                })
            }
            if (params == "reject") {
                this.setState({
                    rowData: secrejectarr,
                })
            }
            if (params == "hold") {
                this.setState({
                    rowData: secholdarr,
                })
            }
            if (params == "implement") {
                this.setState({
                    rowData: secimplementarr,
                })
            }
        }
    }
    selectplants() {
        var plants = [];
        plants.push({ "value": "all", "label": "All" })
        plants.push({ "value": "B", "label": "TVP Bangalore" })
        plants.push({ "value": "haryana", "label": "Haryana" })
        plants.push({ "value": "G", "label": "Gujarat" })
        plants.push({ "value": "I", "label": "Siliguri" })
        plants.push({ "value": "N", "label": "TVP Nagpur" })
        this.setState({
            plants: plants,
        });
    }
    onChangePlantItem(plant) {
        this.setState(
            { plant },
            () => console.log(`Plant Option selected:`, this.state.plant)
        );
    }
    formPlantHandler = async (event) => {
        event.preventDefault()
        this.setState({
            "loadshow": "show-m",
            "overly": 'show-m',
        });
        this.onLoadShowPageData();
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
        redirectURL.post("dashboard/getDestinationFromBaseRoutes", {"consigner_code": originoption.value}).then((response)=>{
            var items=[{"label": "ALL", "value": "ALL"}];
            var destinationarr = response.data;
            var check = destinationarr.map((item) =>{
                items.push({
                    value:item,label:item 
                })
            })
            Promise.all([check]).then(()=>{
                this.setState({
                    destinationOptions: items
                })
            })
            
        })
    }

    changeDestinationHandler = destinationoption => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = destinationoption.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     response2.data.lsp_users.map((e)=>{
        //         if(e != "NA")
        //         {
        //             var label = e
        //             if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //             lspusers_options.push({"label": label, "value": e})
        //         }
        //     })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
        //     this.setState({
        //         destinationoption,
        //         // originoptions: originoptions,
        //         // destinationOptions: items,
        //         lspusers_options: lspusers_options,
        //         truck_types: truck_types,
        //         period_types: period_types_options,
        //         // overly: "show-n",
        //         // loadshow: "show-n"
        //     })
        // })
        this.setState(
            { destinationoption }
        );
    
    }
    changeConsigneeArea(selectedConsArea){
        this.setState({selectedConsArea})
    }
    changeZone(selectedZone){
        this.setState({selectedZone})
    }
    changeGpsProviderHandler = selected_provider => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = selected_provider.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var lspusers_options = [];
        //     var truck_types = [];
        //     var period_types_options = [];
        //     // response2.data.lsp_users.map((e)=>{
        //     //     if(e != "NA")
        //     //     {
        //     //         var label = e
        //     //         if(e == "AXESTRACK_SND"){label = "AXESTRACK"}
        //     //         lspusers_options.push({"label": label, "value": e})
        //     //     }
        //     // })
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
        //     var period_types = sortPeriodTypes(response2.data.period_types);
        //     period_types.map((e)=>{
        //         period_types_options.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_provider: selected_provider,
                // originoptions: originoptions,
                // destinationOptions: items,
                // lspusers_options: lspusers_options,
                // truck_types: truck_types,
                // period_types: period_types_options,
                // overly: "show-n",
                // loadshow: "show-n"
            })
        // })
    }

    changePeriodTypeHandler = selected_period_type => {
        // var formdata = {}
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consigner_code'] = this.state.originoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['consignee_city'] = this.state.destinationoption.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['gps_provider'] = this.state.selected_provider.value
        // }
        // if([null, undefined, "ALL", ""].includes(this.state.originoption.value) == false)
        // {
        //     formdata['period_type'] = selected_period_type.value
        // }
        // redirectURL.post("dashboard/getInitialDataForBaseRouteSummary", formdata).then((response2)=>{
        //     var truck_types = [];
        //     response2.data.truck_types.map((e)=>{
        //         truck_types.push({"label": e, "value": e})
        //     })
            this.setState({
                selected_period_type: selected_period_type,
                // truck_types: truck_types,
            })
        // })
    }

    changeTruckTypeHandler = selected_truck_type => {
        this.setState({
            selected_truck_type: selected_truck_type,
        })
    }
    formHandler(){

    }
    render() {
        const columnDefs = [
            // {
            //     headerName: "",
            //     field: "",
            //     width: 110,
            //     cellRenderer: (params) => {
            //         var sourval = params.data.consigner_code;
            //         var sourcename = params.data.sourcename;
            //         var destination = params.data.consignee_city;
            //         return '<a href="/deviationroutes?source=' + sourval + '&sourcename=' + sourcename + '&destination=' + destination + '&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value+'" target="_blank" style="padding:2px;width:6em;" class="btn btn-warning" rel="noopener">View Map</a>'
            //     }
            // },
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer:  (params) => {
                    var sourval = params.data.consigner_code;
                    var sourcename = params.data.sourcename;
                    var destination = params.data.consignee_city;
                    return '<a href="/deviationanalysisdata?source=' + sourval + '&sourcename=' + sourcename + '&destination=' + destination + '&period_type='+ this.state.selected_period_type.value +'&gps_provider='+this.state.selected_provider.value+'&truck_type='+this.state.selected_truck_type.value+'" target="_blank" class="btn btn-warning" style="padding:2px;width:7em;" rel="noopener">View Summary</a>'
                }
            },
            {
                headerName: "Source",
                field: "sourcename",
                width: 120,
            }, {
                headerName: "Destination",
                field: "consignee_city",
                width: 140
            },

            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },

            {
                headerName: "Trips Considered For Analysis",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "VE SAP Distance (Kms)",
                field: "",
                width: 120,
                valueGetter: function(params){
                    console.log(params.data.expected_distance, "params.data.expected_distance")
                    return params.data.expected_distance;
                }
            },
            {
                headerName: "Min. GPS Distance (KM)",
                field: "ref_route_min_google_distance",
                width: 120
            },
            {
                headerName: "Max. GPS Distance (KM)",
                field: "ref_route_max_google_distance",
                width: 120
            },
            {
                headerName: "Average Distance (Kms)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Median GPS Distance (KM)",
                field: "ref_route_median_google_distance",
                width: 120
            },
            {
                headerName: "Google Distance (KM)",
                field: "google_distance_via_waypoints",
                width: 120
            },
            {
                headerName: "Difference Distance (Kms)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            }


        ]

        const columnDefsForDealer = [
            {
                headerName: "",
                field: "",
                width: 110,
                cellRenderer: function (params) {
                    // var cCode = params.data.consignee_code;                    
                    // return '<a href="/deviationForDealer?consignee_code=' + cCode +'" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                    var sourval = params.data.consigner_code;
                    let sourceName = params.data.consigner_name;                
                    var cCode = params.data.consignee_code;  
                    return '<a href="/deviationForDealer?source=' + sourval +'&sourcename=' + sourceName + '&consignee_code=' + cCode + '" target="_blank" class="btn btn-warning" rel="noopener">View</a>'
                }
            },
            {
                headerName: "Source",
                field: "consigner_name",
                width: 120
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 160

            },
            {
                headerName: "Total Source Destination Trips",
                field: "total_trips_btw_source_dest",
                width: 120
            },
            {
                headerName: "Trips Considered For Analysis",
                field: "trips_considered_for_analysis",
                width: 120
            },
            {
                headerName: "VE SAP Distance (Kms)",
                field: "expected_distance",
                width: 120,
                valueGetter: (params)=>{
                    return params.data.expected_distance;
                }
            }, {
                headerName: "Average Distance (Kms)",
                field: "ref_route_avg_google_distance",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.ref_route_avg_google_distance
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Difference Distance (Kms)",
                field: "base_route_google_distance_difference",
                width: 100,
                valueGetter: function (params) {
                    try {
                        return params.data.base_route_google_distance_difference
                    }
                    catch (e) {
                        return "";
                    }

                }
            }
        ]
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        {/*div className="row col-xl-12 col-lg-12">
                            <button 
                                    name="all" 
                                    type="button"
                                    onClick={this.onClickTab.bind(this,"all")}
                                    className={"btn "+this.state.btnactive}
                                >
                                    All
                                </button>
                                &nbsp; <button 
                                    name="primary" 
                                    type="button"
                                    onClick={this.onClickTab.bind(this,"prim")}
                                    className={"btn "+this.state.btnprimactive}
                                >
                                    Primary
                                </button>
                                &nbsp;
                                <button 
                                    name="secondary" 
                                    type="button"
                                    onClick={this.onClickTab.bind(this,"second")}
                                    className={"btn "+this.state.btnsecactive}
                                >
                                    Secondary
                                </button>
                            </div>
                            
                            <div className="row" style={{marginTop:"10px"}}>
                                
                                <div className="col-xl-4 col-lg-4 row form-group">
                                    <label className="col-xl-12 col-lg-12">Select Plant</label>
                                    <Select 
                                    placeholder={"Select Plant"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangePlantItem.bind(this)} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    //value={transporter}
                                    options={this.state.plants} />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group" style={{marginTop:"2.3%"}}>
                                    <button onClick={this.formPlantHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                </div>
                            </div>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"all")}>
                                            <span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Possible Identified Routes</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.optimizedroutes != '')?this.state.optimizedroutes:0}/></span></h4>
                                        </div>
                                        
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Potential Annual Savings (Rs)</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.estimateannual != '')?this.state.estimateannual:0}/></span></h4>
                                        </div>
                                        
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"taken")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 txt-primary"></i><br /> Taken for Implementation</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.takenimplement != '')?this.state.takenimplement:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"reject")}>
                                            <span className="f13"><i className="icofont icofont-ui-close f24 txt-danger"></i><br /> Rejected</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.rejected != '')?this.state.rejected:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"implement")}>
                                            <span className="f13"><i className="icofont icofont-ui-check f24 txt-secondary"></i><br /> Implemented</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.implemented != '')?this.state.implemented:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickShowClickableData.bind(this,"hold")}>
                                            <span className="f13"><i className="icofont icofont-hand f24 txt-danger"></i><br /> Hold</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.hold != '')?this.state.hold:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-calculator f24 txt-warning"></i><br /> Estimated Annual Savings Under Implementation (Rs)</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.potential_savings != '')?this.state.potential_savings:0}/></span></h4>
                                        </div>
                                        <div className="col cirlce-d cursorPointer">
                                            <span className="f13"><i className="icofont icofont-ui-clip-board f24 txt-info"></i><br /> Saving Realized</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.savingrealized != '')?this.state.savingrealized:0}/></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                    <div className="row col-xl-12 col-lg-12">
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Origin: </label>
                            <Select 
                                name="originoption"
                                closeMenuOnSelect={true} 
                                value={this.state.originoption} 
                                className="border-radius-0" 
                                onChange={this.changeOriginHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.originoptions} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Zone: </label>
                            <Select 
                                name="zone"
                                closeMenuOnSelect={true} 
                                value={this.state.selectedZone} 
                                className="border-radius-0" 
                                onChange={this.changeZone.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.zonesOptions} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Consignee Area: </label>
                            <Select 
                                name="consignee_area"
                                closeMenuOnSelect={true} 
                                value={this.state.selectedConsArea} 
                                className="border-radius-0" 
                                onChange={this.changeConsigneeArea.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.consignee_area_options} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Destination: </label>
                            <Select 
                                name="destinationoption"
                                closeMenuOnSelect={true} 
                                value={this.state.destinationoption} 
                                className="border-radius-0" 
                                onChange={this.changeDestinationHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.destinationOptions} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>GPS Provider: </label>
                            <Select 
                                name="GPS Provider"
                                closeMenuOnSelect={true} 
                                value={this.state.selected_provider} 
                                className="border-radius-0" 
                                onChange={this.changeGpsProviderHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.lspusers_options} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Period: </label>
                            <Select 
                                name="Period"
                                closeMenuOnSelect={true} 
                                value={this.state.selected_period_type} 
                                className="border-radius-0" 
                                onChange={this.changePeriodTypeHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.period_types} required
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Truck Type: </label>
                            <Select 
                                name="Truck Type"
                                closeMenuOnSelect={true} 
                                value={this.state.selected_truck_type} 
                                className="border-radius-0" 
                                onChange={this.changeTruckTypeHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.truck_types} required
                            />
                        </div>
                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <button style={{marginTop:"30px"}} onClick={this.onLoadShowPageData.bind(this)} type="button" className="btn btn-success">Submit</button>
                        </div>
                        {/* <div className="form-group col-xl-3 col-lg-3" style={{textAlign: "right"}}>
                        </div> */}
                        
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Optimized Routes  </span>
                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className="f16" style={{ marginBottom: "10px" }}>
                                    <ul className="d-tabs">
                                        <li onClick={this.onClickTab.bind(this, "plant")} className={"lttabs " + (this.state.tabOneactive)}>
                                            <button type="button" className="btn">Plant</button>
                                        </li>

                                        {/* <li onClick={this.onClickTab.bind(this, "dealer")} className={"lttabs " + (this.state.tabTwoActive)}>
                                            <button type="button" className="btn">Dealer</button>
                                        </li>  */}
                                    </ul>
                                </div>

                                {/* 							  
								<div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Origin: </label>
                                        <Select 
                                            name="originoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.originoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeOriginHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.origins()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Destination: </label>
                                        <Select 
                                            name="destinationoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.destinationoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeDestinationHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.destinations()} required
                                        />
                                    </div>
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                   
                                </div> */}

                                <div className={"row col-xl-12 col-lg-12 " + (this.state.tabOneData)}>
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={false}
                                            // enableRangeSelection={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            //onCellClicked={this.popmarker}
                                            headerHeight={this.state.headerHeight}
                                            rowHeight={this.state.rowHeight}
                                        />
                                    </div>                            
                                    {/*
                                    <div className="row">
                                        <div className="f11 col-xl-12 col-lg-12" style={{fontWeight:"600"}}>Disclaimer:</div>
                                        <div className="f11 col-xl-12 col-lg-12" style={{lineHeight:"20px"}}> 
                                        * 30 KM has been added to Avg Trip Distance of Haryana Trips for accomodating club loads
                                        </div>
                                        <div className="f11 col-xl-12 col-lg-12" style={{lineHeight:"20px"}}>
                                            * All Trips from May 1st 2020 that have delivered within 5KM of the dealer location have been considered for the analysis
                                        </div>
                                    </div>
                                    */}
                                </div>
                                <div className={"row col-xl-12 col-lg-12 "  + (this.state.tabTwoData)}>
                                <div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefsForDealer}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowDataForDealer}
                                            enableCharts={false}
                                            // enableRangeSelection={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            //onCellClicked={this.popmarker}
                                            headerHeight={this.state.headerHeight}
                                            rowHeight={this.state.rowHeight}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                <div className={"slide-r " + (this.state.slidertranslate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Implement Route
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Implement Route From:</label>
                                <Datetime
                                    value={this.state.implementroutedate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: '', name: 'implementroutedate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="implementroutedate"
                                    onChange={this.handlerStartDateTime.bind(this)}
                                />
                            </div>

                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{ marginTop: "30px" }} onClick={this.onClickShowUpdateData.bind(this)} type="button" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}



function sortPeriodTypes(periods_list)
{
    var quarters_dict = {"4": ["Jan", "Feb", "Mar"], "1": ["Apr", "May", "Jun"], "2": ["Jul", "Aug", "Sep"], "3": ["Oct", "Nov", "Dec"]};
    var final_list = ["ALL"];
    var distinctYears = [];
    periods_list = periods_list.filter((e) => {
        var matches = e.match(/(\d+)/);
        console.log(matches)
        if(matches != null)
        {
            if(matches.length)
            {
                var year = parseInt(matches[0])
                if(distinctYears.indexOf(year) < 0)
                {
                    distinctYears.push(year)
                }
            }
        }
        
        return e != "ALL"
    })
    distinctYears = distinctYears.sort()
    distinctYears.map((e)=>{
        var filteredData = periods_list.filter(k => k.includes(e.toString()))
        if(filteredData.length)
        {
            var quarters = filteredData.filter(j => j.includes("Q"));
            var resultMonths = filteredData.filter(j => j.includes("Q") == false);
            quarters = quarters.sort()
            if(quarters.length)
            {
                quarters.map(q => {
                    final_list.push(q)
                    var quarter_no = q.split("Q")[1];
                    var quarter_months = quarters_dict[quarter_no];
                    quarter_months.map(m =>{
                        var finalMonth = resultMonths.filter(r => r.includes(m));
                        if(finalMonth.length)
                        {
                            final_list.push(finalMonth[0])
                        }
                    })
                })
            }
        }
    });
    final_list = final_list.filter(e => e != "");
    return final_list
}