/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
import CountUp from 'react-countup';
import {Redirect} from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import MarkerClusterer from "@google/markerclusterer";
// import WeatherMapComponent from "./weathermap";
import Weatheralerts from './weatheralertsaction';
import Modal from 'react-responsive-modal';
import AlternateRoutes from "./alternateRotuesAction";
import ImpactRoutes from "./impactRoutesAction";

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var map;
var selectedRowData="";
var geoFencingArea;
var geoFencingAreaArr = [];
var routePaths = [];
const mapPlants = {'HHHU': 'Haridwar', 'HHHG': 'Gurgaon', 'HHHD': 'Dharuhera',
'HM4N': 'Neemrana', 'HM6C': 'Chittoor', 'HM5V': 'Halol'}

const carrieroptions = [
	  { value: 'On Trip', label: 'On Trip' },
      { value: 'Empty', label: 'Empty' },
      { value: 'Both', label: 'Both' }
	];


export default class WeatherComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
			modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
				editable: false,
				resizable:true
			},
			rowData:[],
			rowGridData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			formap : 0,
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			frameworkComponents: {
				weatheralerts:Weatheralerts,
			},
			pageTitle: "Weather Alerts",
			sliderRouteTranslate : "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
			routemap:'',
			loadshow:'show-n',
			overly:'show-n',
            startAlert:"",
			endAlert:"",
			user_email:"",
			show : false,
			type : "",
			title : "",
			weatherData : "",
            notifyduration : 1,
            notifyfirstto:"",
            notifyfirstcc:"",
        	departments:[],
        	coords:[],
        	geo_name:'',
            geo_type:'',
            endAlertVal:"",
			fence_type:'',
			event:"",
        	dept_code:"",
			notify:'',
			carrier:"",
			erroMessage:'',
			redirectpage:'',
			trucksdata:[],
			trucksGridData:[],
			slidetranslate:'',
			createEventDepts:[],
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			usermanualmodal:false,
			editpageType:1,
			
			triptype :{"value":1,"label":"ALL"},
			showtruckopts:"show-n",
			setshow:false,
			truckscoordinates:[],
			plantLocations : [],		
			weatherData: [],
			displayWeatherForecast: false,
			weatherMarkers: [],
			availableRoutes : [],
			selectedRow: [],
			impacted_routes: [],
			alternate_routes: [],
			firstLoad: 0,
			alternateRoutesRowData: [],
			activeImpactRoute: true,
			availableForCodes: [],
			selectedForCode: {},
			showAlternateRouteMap: false,
			alt_routes_counter: {total: 0, alternateRoutesNa: 0, alternateRoutes: 0, totalCities: 0},
			defaultAlternateRoutesRowData: [],
			shortestAlternateRoute: "",
			showConsolidatedRoutes: false,
			consolidatedRowData: [],
			onTripCount: 0,
			emptyTripCount: 0,
			showTabOne: true,
            showTabTwo: false,
        };
        this.showMap = this.showMap.bind(this);
        this.applyCoords = this.applyCoords.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
        this.checkNotifyHandler = this.checkNotifyHandler.bind(this);
        this.startAlertFormat = this.startAlertFormat.bind(this);
        this.endAlertFormat = this.endAlertFormat.bind(this);
		this.onShowAlternateRouteMap = this.onShowAlternateRouteMap.bind(this);
		this.onShowImpactRoutes = this.onShowImpactRoutes.bind(this)
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){
	
		}
		
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
		loadDateTimeScript();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// this.renderMapWeather();
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		// get department code to filter records by department
        // let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		let deptcode = localStorage.getItem("dept_code");
		let useremail = localStorage.getItem("email");

		var department = deptcode;
		department = department.replace(/"/g,"");
		//console.log(department)
		//console.log(window.location.href);
		if(department != "LOG-PRT" && department != "LOG-SC" && department != "LOG-TNP" && department != "SNDG"){
			if(this.props.match.path == "/sndweatheralerts"){
				department = "SNDG";
			}
			if(this.props.match.path == "/prtweatheralerts"){
				department = "LOG-PRT";
			}
		}
		var editpageType = 2;
		if(this.props.match.path == "/allweatheralerts"){
			department = "ALL";
			var editpageType = 1;
		}
		var reqparams = {
			dept_code : encode(department)
		}
		// console.log(reqparams);
		redirectURL.post("/weather/getWeatherEvents",reqparams).then((response)=>{
			// console.log("hellogrid",response.data);
			this.setState({
				rowData : response.data
			});
			this.tabOneClicked()
		}).catch(function(error){
			console.log("Error");
		})

		redirectURL.post("/weather/getWeatherData").then((response)=>{
			//console.log(response.data);		
			this.setState({
				weatherData : response.data
			})
		  }).catch(function(error){
			console.log(error);
		  })

		  redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		  .then( async (resp) => {
			  this.setState({
				  usergridstate:resp.data
			  });
			  this.restoreGridStates();
		  })
  
		
		this.setState({
			dept_code : department,
			user_email : useremail,
			editpageType : editpageType
		})
		// this.showMap();
		setTimeout(async () => {
			await this.showMap();
			await this.handleFilteronLoad();
		},2000)
	}
   
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		let departmentTitle = null;
		switch(pathToMatch) {
			case "/manage/sndaddgeofence":
				departmentCode='SNDG';
				departmentName = "Sales and Dispatch";
				departmentTitle = " (Sales and Dispatch) ";
				break;
			case "/manage/prtaddgeofence":
				departmentCode='LOG-PRT';
				departmentName = "Spare Parts";
				departmentTitle = " (Spare Parts) ";
				break;
			case "/manage/tnpaddgeofence":
				departmentCode='LOG-TNP';
				departmentName = "Train and Production";
				departmentTitle = " (Train and Production) ";
				break;
			case "/manage/scaddgeofence":
				departmentCode='LOG-SC';
				departmentName = "Supply Chain";
				departmentTitle = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentTitle,
			currentDepartmentCode:departmentCode,
			departments: [{"dept_code": departmentCode,"dept_name": departmentName}],
			dept_code: departmentCode
		});
		return departmentCode;
	}
    
    departmentDropDown(){
		let items = []; 
		//console.log("this.state.departments = ", this.state.departments);
    	this.state.departments.map(item=>{
    		items.push({ value: item.dept_code, label: item.dept_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
		});
		//console.log("IN departmentDropDown items= ", items);
    	return items;
    }
   
    initMap = () => {
		var coordsvalues;
		var allcords=[];
		this.state.truckscoordinates.map((marker) => {
			allcords.push(marker);
		});
    	 map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(28.497236,77.062363),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true
          });
  		
  		//added by nanda for places dropdown...
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });
  		
  		var markers = [];
  		console.log("searchBox",searchBox);
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
  // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };

              // Create a marker for each place.
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location
              }));

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });		
		  //End of adding by nanda
		  
		  	
		  var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";
			
			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
  		
  		var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.70,
            editable: true
          };
          // Creates a drawing manager attached to the map that allows the user to draw
          // markers, lines, and shapes.
          drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
              drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON
              ]
            },
            markerOptions: {
              draggable: true
            },
            polylineOptions: {
              editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
          });

          window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
              if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
              // Switch back to non-drawing mode after drawing a shape.
            //   drawingManager.setDrawingMode(null);
              // To hide:
              drawingManager.setOptions({
                drawingControl: true
              });

              // Add an event listener that selects the newly-drawn shape when the user
              // mouses down on it.
              var newShape = e.overlay;
              newShape.type = e.type;
              window.google.maps.event.addListener(newShape, 'click', function() {
                setSelection(newShape);
              });
              setSelection(newShape);
              //console.log("selectedShape",newShape);
  			  fencingLength = selectedShape.getPath().getLength();
  			  console.log("fencingLength ", fencingLength)
  				var latlngObj=[];
	  			var htmlStr = "";
	  	        for (var i = 0; i < fencingLength; i++) {
	  				var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
	  				var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	  				latlngObj.push(in_latlng_array);
	  				
	  			}
	  			coordinates.push(latlngObj);
            }
              
          });
          
          
          // Clear the current selection when the drawing mode is changed, or when the
          // map is clicked.
  		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
  			clearSelection()
  		});
  		window.google.maps.event.addListener(map, 'click', function(){
  			clearSelection()
  		});
        
        //   window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
        // 		deleteSelectedShape()
		// });
        
        
  		
      //buildColorPalette();
  		map.addListener('zoom_changed', function() {
            mapZoom=map.getZoom();
          });
  		
  		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
  			getSelectedShape()
  		});*/
  		
  		console.log("coordinates", coordinates);
  		
    }
    
    // onGridReady = params => {
	// 	this.gridApi = params.api;
		
	//     //console.log("Grid is: ", params);
	// 	this.gridColumnApi = params.columnApi;
	// 	var sort = [
	// 		{
	// 		  colId: 'start_alert',
	// 		  sort: 'desc',
	// 		},
	// 	  ];
	// 	this.gridApi.setSortModel(sort);
	// };
	onGridReady1 = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		// var sort = [
		// 	{
		// 	  colId: 'start_alert',
		// 	  sort: 'desc',
		// 	},
		//   ];
		// this.gridApi.setSortModel(sort);
	};
	
// 	onGridState = () =>{
// 		//console.log(this.gridApi);
	   
// 		/*Get  Current Columns State and Store in this.colState */
// 		  this.colState = this.gridColumnApi.getColumnState();

// 		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
// 		  this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

// 		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
// 		 * User should enable the Pivot mode.*/
// 		  this.pivotColumns = this.gridColumnApi.getPivotColumns();
				   
// 		/*Get Current Filter State and Store in window.filterState */
// 		  window.filterState = this.gridApi.getFilterModel();
// 		  this.gridApi.setFilterModel(window.filterState);

//    }

   onGridState1 = () =>{
		//console.log(this.gridApi);
	   
		/*Get  Current Columns State and Store in this.colState */
		  this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		  this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		  this.pivotColumns = this.gridColumnApi.getPivotColumns();
				   
		/*Get Current Filter State and Store in window.filterState */
		  window.filterState = this.gridApi.getFilterModel();
		  this.gridApi.setFilterModel(window.filterState);
   }

	onGridAltRouteReady = params => {
		this.gridAltRouteApi = params.api;
		this.gridAltRouteApi.expandAll();
		var sort = [
			{
			  colId: 'impacted_consignments',
			  sort: 'desc',
			},
		  ];
		this.gridAltRouteApi.setSortModel(sort);
		this.gridAltRouteColumnApi = params.columnApi;
	};
	
	onGridAltRouteState = () =>{
		//console.log(this.gridApi);
	   
		/*Get  Current Columns State and Store in this.colState */
		  this.colState = this.gridAltRouteColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		  this.rowGroupState = this.gridAltRouteColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		  this.pivotColumns = this.gridAltRouteColumnApi.getPivotColumns();
				   
		/*Get Current Filter State and Store in window.filterState */
		  window.filterState = this.gridAltRouteApi.getFilterModel();
		  this.gridAltRouteApi.setFilterModel(window.filterState);

   }

   onGridConsRouteReady = params => {
		this.gridConsRouteApi = params.api;
		this.gridConsRouteColumnApi = params.columnApi;
		params.api.forEachNode((node) => {
			node.setSelected(true);
	  	});
	};

	onGridConsRouteState = () =>{
		//console.log(this.gridApi);
	
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridConsRouteColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridConsRouteColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridConsRouteColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridConsRouteApi.getFilterModel();
		this.gridConsRouteApi.setFilterModel(window.filterState);

	}

   
	
    
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }


    changeHandler = (event) => {
    	this.setState({
		  carrier:event.value
		});
    }
    startAlertFormat(event){
        console.log("changed");
        console.log(event.target.value);
        let originalDate = event.target.value
        if(originalDate != "")
        {
            let dateval = originalDate.split(" ")[0].split("-");
            let timeval = originalDate.split(" ")[1];
            let formattedDate = dateval[2]+"-"+dateval[1]+"-"+dateval[0]+" "+timeval+":00";
            let endDate = dateval[1]+"-"+dateval[0]+"-"+dateval[2];
            console.log(endDate);
            var endDateVal = new Date(endDate);
            endDateVal = moment.parseZone(new Date(endDateVal.setDate(endDateVal.getDate()+5))).format('YYYY-MM-DD HH:mm:ss');
            endDateVal = endDateVal.split(" ")[0].split("-")[2]+"-"+endDateVal.split(" ")[0].split("-")[1]+"-"+endDateVal.split(" ")[0].split("-")[0]+" "+timeval ;
			console.log(endDateVal);
			$("#end-alert-id").val("");
			$("#end-alert-id").val(endDateVal);
            this.setState({
                startAlert : formattedDate,
                // endAlertVal : endDateVal
            })
            console.log(formattedDate);
        }
    }
    endAlertFormat = (event) =>{
        console.log("changed");
        console.log(event.target.value);
        let originalDate = event.target.value
        if(originalDate != "")
        {
            let dateval = originalDate.split(" ")[0].split("-");
            let timeval = originalDate.split(" ")[1];
            let formattedDate = dateval[2]+"-"+dateval[1]+"-"+dateval[0]+" "+timeval+":00";
            let endDate = dateval[1]+"-"+dateval[0]+"-"+dateval[2];
            console.log(endDate);
            var endDateVal = new Date(endDate);
            endDateVal = moment.parseZone(new Date(endDateVal.setDate(endDateVal.getDate()+5))).format('YYYY-MM-DD HH:mm:ss');
            endDateVal = endDateVal.split(" ")[0].split("-")[2]+"-"+endDateVal.split(" ")[0].split("-")[1]+"-"+endDateVal.split(" ")[0].split("-")[0]+" "+timeval ;
			console.log(endDateVal);
			//$("#end-alert-id").val("");
			//$("#end-alert-id").val(originalDate);
            this.setState({
                endAlertVal : formattedDate,
                // endAlertVal : endDateVal
            })
            console.log(formattedDate);
        }
    }
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
	}
	saveDeptState = (event)=>{
		var arr=[];
		event.map(function(e){
			arr.push(e.value);
		})
		this.setState({
			createEventDepts : arr
		})
	}
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    changeArrayHandler = dept_code => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { dept_code },
	      () => console.log(`Option selected:`, this.state.dept_code)
	    );
    	
    	
    }
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    selectDept(){
		let arr=[{value:"SNDG",label:"Sales and Dispatch"},
				{value:"LOG-PRT",label:"Spare Parts"},
				// {value:"LOG-SC",label:"Supply Chain"},
				// {value:"LOG-TNP",label:"Train and Productions"}
			];
        return arr;
        
        
    }
	formHandler = (event) => {
			
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// var formdata1 = new FormData(event.target);
		var endalert = $("#end-alert-id").val();
		endalert = endalert.split(" ")[0].split("-")[2]+"-"+endalert.split(" ")[0].split("-")[1]+"-"+endalert.split(" ")[0].split("-")[0]+" "+endalert.split(" ")[1]+":00";
		
		var notifytransporter = $("#notifyTransporter").prop("checked");
		if(notifytransporter){
			notifytransporter = "yes";
		}
		else{
			notifytransporter = "no";
		}
		
		var notifySpoc = $("#notifySpoc").prop("checked");
		if(notifySpoc){
			notifySpoc = "yes";
		}
		else{
			notifySpoc = "no";
		}

		var notifyTrucks = $("#notifyTrucks").prop("checked");
		if(notifyTrucks){
			notifyTrucks = "yes";
		}
		else{
			notifyTrucks = "no";
		}
		
		var depts = [];
		if(this.state.dept_code == "ALL")
		{
			depts = this.state.createEventDepts;	
		}
		else{
			depts.push(this.state.dept_code)
		}
		console.log(coordinates,"coordinates")
		if(coordinates.length > 0)
		{
			if(coordinates[0].length >= 3)
			{
				var reqparams = {
					event : this.state.event,
					dept_code : depts,
					startAlert : this.state.startAlert,
					coordinates : coordinates,
					endAlert : this.state.endAlertVal,
					email : this.state.notifyfirstto,
					emailcc : this.state.notifyfirstcc,
					notifyduration : this.state.notifyduration,
					carrier : this.state.carrier,
					notifytransporter : notifytransporter,
					notifyspoc:notifySpoc,
					notifyTrucks: notifyTrucks,
					user_email : this.state.user_email

				}
				redirectURL.post("/weather/createweatherevent",reqparams).then((response)=>{
					if(response.data.message == "success"){
						this.setState({
							basicType:"success",
							basicTitle : "Event Created. Please check again in 10 minutes for alternate routes.",
							show : true
						});
						window.location.reload();
					}
				}).catch(function(error){
					console.log("Error");
				});
				
				// $(".weather-grid").css("display","flex");
				// $(".weather-alert-card").css("display","none");
				// $("#addGeoFence").css("display","block");
			}
			else
			{
				this.setState({
					show:true,
					basicType : "danger",
					basicTitle : "Select Atleast 3 Coordinates"
				})
			}
		}
		else
		{
			this.setState({
				show:true,
				basicType : "danger",
				basicTitle : "Select Atleast One Area" 
			})
		}
		
		// if(coordinates.length > 0)
		// {
		// 	if(coordinates[0].length >= 3)
		// 	{
		// 		var formdata ={
		// 				geo_name: this.state.geo_name,
		// 				dept_code: [this.state.dept_code],
		// 				geo_type: this.state.geo_type,
		// 				fence_type: this.state.fence_type,
		// 				notify: this.state.notify,
		// 				coordinates: coordinates
		// 		} 
		// 		console.log(formdata);
		// 		console.log("coordinates", coordinates);
				
		// 		redirectURL.post("/geofence/addGeo", formdata,{
		// 			headers:{
		// 				'Content-Type': 'application/json'
		// 			}
		// 		}).
		// 		then(
		// 			(response)=>{
		// 				console.log(response);
		// 				if(response.data.message == 'Success')
		// 				{
		// 					this.setState({
		// 						geo_name:'',
		// 						dept_code:'',
		// 						geo_type:'',
		// 						fence_type:'',
		// 						notify:'',
		// 						redirectpage:'yes'
		// 					});
							
		// 				}
		// 				//window.location.href='/manage/geofences';
						
		// 			}
		// 		)
		// 		.catch(function(error){
		// 			console.log(error);
		// 		});
		// 	}
		// 	else
		// 	{
		// 		this.setState({
		// 			erroMessage:'Select Atleast 3 Coordinates'
		// 		});
		// 	}
		// }
		// else
		// {
		// 	this.setState({
		// 		erroMessage:'Please Add Coordinates'
		// 	});
		// }
		
	}
	onShowWeatherData(params){
		//console.log("params ", params)
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		});
		var parameters = {
			dept_code:encode(params.dept_code),
			request_type:params.carrier_type,
			geofence_coords:params.coordinates,
		}
		redirectURL.post("/weather/weatheralertdata", parameters)
		.then((response) => {
			var records = JSON.parse(response.data.data);
			// console.log("records " , records)
			let emptyTripCount = 0;
			let onTripCount = 0;
			records.map(each =>{
				if(each.request_type === "On Trip"){
					onTripCount = onTripCount +1
				}else{
					console.log("emptytruck", each.request_type)
					emptyTripCount = emptyTripCount +1
				}
			})
			if(records.length > 0)
			{
				this.setState({
					onTripCount,
					emptyTripCount,
					loadshow:'show-n',
					trucksdata:records,
					trucksGridData:records,
					slidetranslate:'slider-translate-75p'
				});
			}
			else{
				this.setState({
					show:true,
					basicType:'danger',
					basicTitle:"No data found.",
					loadshow:'show-n',
					overly:'show-n',
					slidetranslate:''
				});
			}
			
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
	}	
	showChloroMap = () => {
		this.setState({
			sliderRouteTranslate : "slider-translate-60p",
			loadshow:'show-m',
			overly:'show-m',
			formap : 1
		})
		this.renderMapWeather();
	}

	renderMapWeather = () => {    	
		loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMapWeather");
		window.initMapWeather = this.initMapWeather
 	}

	 initMapWeather = () => {
		//console.log("here slidebar")
		var map = new window.google.maps.Map(document.getElementById('mapweather'), {
			zoom: 5,
			center: new window.google.maps.LatLng(28.497236,77.062363),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},            
			disableDefaultUI: true,
			zoomControl: true
		});
		// geo fence 
		console.log(this.state.selectedRow.coordinates, "geofence coords")
		var geoFencingObj = this.state.selectedRow.coordinates;
		for(var i=0;i<geoFencingObj.length;i++)
		  {
			if(geoFencingObj[i].length != 1)
			{
				geoFencingArea = new window.google.maps.Polygon({
					paths: geoFencingObj[i],
					editable: false,
					strokeColor: 'red',
					strokeOpacity: 0.70,
					strokeWeight: 0,
					fillColor: 'red',
					fillOpacity: 0.35,
					draggable:false,
					draggingCursor:null,
					
					});
					geoFencingAreaArr[i] = new window.google.maps.Polygon({
						paths: geoFencingObj[i],
						editable: false,
						strokeColor: 'red',
						strokeOpacity: 0.70,
						strokeWeight: 0,
						fillColor: 'red',
						fillOpacity: 0.35,
						draggable:false,
						draggingCursor:null,
					});
				console.log("geoFencingArea[i] ",geoFencingAreaArr[i]);
				//geoFencingArea.setMap(map);
				geoFencingAreaArr[i].setMap(map);
				fencingLength=geoFencingObj[i];
				console.log("test ",geoFencingArea.getPath());
				// geoFencingAreaArr.push(geoFencingArea);
				console.log("fencingLength ",fencingLength)
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'insert_at',getPolygonCoords);
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'set_at', getPolygonCoords);
			}
			else{
				console.log(geoFencingObj[i][0].lat,geoFencingObj[i][0].lng);
				var marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(geoFencingObj[i][0].lat,geoFencingObj[i][0].lng),
					map: map,
					// icon: iconName,
					// title : ""+params.address+","+params.forecast_date+","+params.description
				});
				geoFencingAreaArr[i] = new window.google.maps.Polygon({
					paths: geoFencingObj[i],
					editable: false,
					strokeColor: 'red',
					strokeOpacity: 0.70,
					strokeWeight: 0,
					fillColor: 'red',
					fillOpacity: 0.35,
					draggable:false,
					draggingCursor:null,
					});
					geoFencingAreaArr[i].setMap(map);
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'insert_at',getPolygonCoords);
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'set_at', getPolygonCoords);
			}
		}
		// routes
		var bounds = new window.google.maps.LatLngBounds();
		if(!this.state.showConsolidatedRoutes)
		{
			var colors = ["#000", "blue", "green"]
			routePaths = []
			this.state.availableRoutes.map((e,index)=>{
				var line = new window.google.maps.Polyline(
				{
					map:map,
					strokeColor: colors[index],
					strokeOpacity: 1.0,
					strokeWeight: 2.5
				});
				var route_coords = eval(e.route_coords)
				new window.google.maps.Marker({
					position: new window.google.maps.LatLng(route_coords[0].lat, route_coords[0].lng),
					icon:require('../../assets/icons/track_start.png'),
					map: map,	
				});
				new window.google.maps.Marker({
					position: new window.google.maps.LatLng(route_coords[(route_coords.length-1)].lat, route_coords[(route_coords.length-1)].lng),
					icon:require('../../assets/icons/truck-end.png'),
					map: map,	
				});
				var path=line.getPath().getArray();
				route_coords.map((k)=>{
					let latLng = new window.google.maps.LatLng(k.lat,k.lng);
					path.push(latLng);
					bounds.extend(new window.google.maps.LatLng(k.lat,k.lng));
				})					
				line.setPath(path);
				routePaths.push(line)
			})
		}
		else
		{
			// consolidated route map
			var colors = ["red", "cornflowerblue"]
			routePaths = []
			for(var i=0; i < 2; i++)
			{
				var routeToPlot;
				if(i == 0)
				{
					routeToPlot = this.state.impacted_routes;
				}
				else
				{
					routeToPlot = this.state.alternate_routes;
				}
				routeToPlot.map((e,index)=>{
					var line = new window.google.maps.Polyline(
					{
						map:map,
						strokeColor: colors[i],
						strokeOpacity: 1.0,
						strokeWeight: 2.5
					});
					var route_coords = eval(e.route_coords)
					new window.google.maps.Marker({
						position: new window.google.maps.LatLng(route_coords[0].lat, route_coords[0].lng),
						icon:require('../../assets/icons/track_start.png'),
						map: map,	
					});
					// new window.google.maps.Marker({
					// 	position: new window.google.maps.LatLng(route_coords[(route_coords.length-1)].lat, route_coords[(route_coords.length-1)].lng),
					// 	icon:require('../../assets/icons/truck-end.png'),
					// 	map: map,	
					// });
					var path=line.getPath().getArray();
					route_coords.map((k)=>{
						let latLng = new window.google.maps.LatLng(k.lat,k.lng);
						path.push(latLng);
						bounds.extend(new window.google.maps.LatLng(k.lat,k.lng));
					})					
					line.setPath(path);
					routePaths.push({"_id": e._id, "line": line})
				})
			}
			
		}
		
		map.addListener('zoom_changed', function() {
			mapZoom=map.getZoom();
		});
		map.fitBounds(bounds)
		this.setState({
			loadshow: "show-n"
		})
    }
	
	
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:"",
			slidetranslate:'',
			formap : 0,
			showAlternateRouteMap: false,
			showConsolidatedRoutes: false
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:"",
			slidetranslate:'',
			overly:'show-n',
			formap : 0
		});
	}
	changeScreen = () =>{
		$(".weather-grid").css("display","none");
		$(".weather-alert-card").css("display","flex");
		$("#addGeoFence").css("display","none");
		$("#chloromap-id").css("display","none");
		$("#previous-screen").css("display","initial");
		
	}
	previousscreen = () =>{
		$(".weather-grid").css("display","flex");
		$(".weather-alert-card").css("display","none");
		$("#addGeoFence").css("display","block");
		$("#previous-screen").css("display","none");
		$("#chloromap-id").css("display","block");
	}

	onRowClicked(params) {
		if(params.colDef.headerName == "Event Status")
		{
			// console.log("IN onRowClicked, params = ",params);
			selectedRowData = params.data;
		}
		else{
			console.log("Other cells");
		}
	  }
	  disableevent(){
	}



	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';

		if(this.props.match.path == "/sndweatheralerts")
		{
			screenpage='snd weather alerts';
		}
		else if(this.props.match.path == "/prtweatheralerts")
		{
			screenpage='prt weather alerts';
		}
		else if(this.props.match.path == "/allweatheralerts")
		{
			screenpage='all weather alerts';
		}
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	handleFilteronLoad(){
    	// console.log("filter clicked");
		// e.preventDefault();
		
		this.setState({
			loadshow:'show-m',
		});
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC'
		}
		
		var transcode = "ALL";
		
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:this.state.dept_code,
			transporter_code:"ALL",
			status:"0",
			trip_type : trip_type
		}
		// console.log(formdata);
		var rlatitude="20.73568784876339";
		var rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
			console.log(JSON.parse(response.data.apiData.body))

			if(markers.length > 0)
			{
				this.setState({
					weatherData: response.data.weatherData,
					// defaultradius:formdata.radius,
					// truckscoordinates:markers,
					// onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
					// mwidth:"col-xl-12 col-lg-12",
					// rowData:markers,
					// radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
				});
				// redirectURL.post("/weather/getPlants").then((rsp)=>{
				// 	this.setState({
				// 		plantLocations : rsp.data,
				// 	});
				// 	console.log(rsp.data);
				// 	this.renderMap();
				// })
				
				
			}
			else{
				this.setState({
					// show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
	}
	handleFilter(){
    	// console.log("filter clicked");
		// e.preventDefault();
		
		this.setState({
			loadshow:'show-m'
		});
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC'
		}
		
		var transcode = "ALL";
		
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:this.state.dept_code,
			transporter_code:"ALL",
			status:"0",
			trip_type : trip_type
		}
		// console.log(formdata);
		var rlatitude="20.73568784876339";
		var rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
			console.log(JSON.parse(response.data.apiData.body))

			if(markers.length > 0)
			{
				this.setState({
					weatherData: response.data.weatherData,
					defaultradius:formdata.radius,
					truckscoordinates:markers,
					onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:formdata.radius,
					//loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				setTimeout(() => {
					this.setState({
						loadshow:"show-n",
						overlay:"show-n",
					})
				}, 10000)
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
						plantLocations : rsp.data,
					});
					this.renderMap();
				})
				
				
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
	}
	setTripType = triptype =>{
		this.setState(
			{ triptype },
			() => {
				console.log(`Option selected:`, this.state.triptype)
				this.handleFilter()
				setTimeout(() => {
					this.setState({
						loadshow:"show-n",
						overlay:"show-n",
					})
				}, 10000)
			}
		  );
	}
	onClickShowTrucks = async () =>{
		// console.log("Here again ", this.state.showtruckopts)
		await this.setState({
			setshow:!this.state.setshow
		})
		
		await this.onLOadShowTrucks();	
	}
	onLOadShowTrucks(){
		if(this.state.setshow == true)
		{
			this.setState({
				showtruckopts:"show-m",
				loadshow:"show-m",
				overlay:"show-m",
			})
			this.handleFilter();
			setTimeout(() => {
				this.setState({
					loadshow:"show-n",
					overlay:"show-n",
				})
			}, 10000)
		}
		else
		{
			this.setState({
				showtruckopts:"show-n",
				weatherData: this.state.weatherData,
				defaultradius:this.state.defaultradius,
				plantLocations : [],
				truckscoordinates:[]
			})
			this.renderMap();
		}
	}
	
	onClickWeatherForecast(e){
	
		this.setState({displayWeatherForecast: !this.state.displayWeatherForecast});
		// weather forecast
		if(!this.state.displayWeatherForecast)
		{
			var weatherMarkers = [];
			this.state.weatherData.map(function(params)
			{            
				if(params.address.includes("NA") == false && params.address != "/")
				{
					// bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
					var url="";
					var iconName = "";
					
					if(params.description.includes("Thunderstorm") == true){
						url = require("../../assets/icons/thunderstorm.png")
					}
					else if(params.description.includes("Heavy Rain and Strong Winds") == true){
						url = require("../../assets/icons/wind.png")
					}
					else if(params.description.includes("Heavy Rain") == true){
						url = require("../../assets/icons/Heavyrain.png")
					}
					else if(params.description.includes("Cyclone") == true){
						url = require("../../assets/icons/cyclone.png")
					}
					else if(params.description.includes("Hailstroms") == true){
						url = require("../../assets/icons/hailstorm.png")
					}
					else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
						url = require("../../assets/icons/sandstorm.png")
					}
					else
					{
					
					}
					var iconName = {
						url: url, // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0,0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};
					
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(params.latitude,params.longitude),
						map: map,
						icon: iconName,
					// title : ""+params.address+","+params.forecast_date+","+params.description
					});
					var infowindow = new window.google.maps.InfoWindow({
						content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
					});
					window.google.maps.event.addListener(marker, 'mouseover', function() {
						infowindow.open(map,marker);
					});
					window.google.maps.event.addListener(marker, 'mouseout', function() {
						infowindow.close();
					});
					marker.setVisible(true)
					weatherMarkers.push({
						"marker": marker,
						"desc": params.description,
						"url": url
					})
				}
			});
			this.setState({
				weatherMarkers: weatherMarkers
			})
		}
		else{
			if(this.state.weatherMarkers.length){
				this.state.weatherMarkers.map((e)=>{
					e.marker.setMap(null);
				})
				this.setState({
					weatherMarkers: []
				})
			}
		}
	}

	
	onClickFilterWeather(clickedType)
	{
		var weatherMarkers = this.state.weatherMarkers;
		if(clickedType == 1){
			weatherMarkers.map((e)=>{
				e.marker.setVisible(true);
			})
		}
		else if(clickedType == 2)
		{
			weatherMarkers.map((e)=>{
				if (e.desc == "Heavy Rain")
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 3)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Winds"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 4)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Thunderstorm"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 5)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Hailstrom"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 6)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Cyclone"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 7)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Sandstorm"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
	}

	onCellClicked(cell)
	{
		if(cell.colDef.field == "alt_routes")
		{
			if(cell.data.alternate_routes != undefined)
			{
				var gridData = eval(cell.data.alternate_routes);
				var alt_routes_counter = this.state.alt_routes_counter;
				alt_routes_counter.total = gridData.length;
				const reducer = (accumulator, curr) => accumulator + curr;
				var totalCities = [];
				var alternateRoutes = [];
				gridData.map((e)=>{
					totalCities.push(e.consignee_city)
					alternateRoutes = alternateRoutes.concat(e.alternate_routes)
				})
				alt_routes_counter.totalCities = [...new Set(totalCities)].length;
				alt_routes_counter.alternateRoutes = gridData.filter(e => eval(e.alternate_routes).length > 0).length;
				alt_routes_counter.alternateRoutesNa = gridData.filter(e => eval(e.alternate_routes).length == 0).length;
				
				this.setState({
					alternateRoutesRowData: eval(cell.data.alternate_routes),
					defaultAlternateRoutesRowData: eval(cell.data.alternate_routes),
					alt_routes_counter: alt_routes_counter,
					sliderRouteTranslate : "slider-translate-90p",
					selectedRow: cell.data,
					overly: "show-m"
				})
			}
		}
	}

	onCellImpactRoutesClicked(cell)
	{
		if(cell.colDef.field == "consolidated_routes")
		{
			var impactRoutes = cell.data.impacted_routes;
			var alternateRoutes = cell.data.alternate_routes;
			
			redirectURL.post("/dashboard/getAlternateRoutesForWeatherAlerts", {"impactRoutes": impactRoutes, "alternateRoutes": alternateRoutes}).then((resp)=>{
				var consolidatedRowData = [];
				resp.data.impact_routes.map((e, index)=>{
					e.route_no = "Impact Route - "+(index+1).toString()
					consolidatedRowData.push(e)
				})
				resp.data.alternate_routes.map((e, index)=>{
					e.route_no = "Alternate Route - "+(index+1).toString()
					consolidatedRowData.push(e)
				})
				this.setState({
					// sliderRouteTranslate : "slider-translate-90p",
					impacted_routes: resp.data.impact_routes,
					alternate_routes: resp.data.alternate_routes,
					showAlternateRouteMap: true,
					loadshow:'show-m',
					overly:'show-m',
					availableRoutes: resp.data,
					showConsolidatedRoutes: true,
					consolidatedRowData: consolidatedRowData
				})
				this.renderMapWeather()
			})
		}
	}

	onClickCounter(counterType)
	{
		if(counterType == 1)
		{
			this.setState({
				alternateRoutesRowData: this.state.defaultAlternateRoutesRowData,
			})
		}
		else if(counterType == 2)
		{
			this.setState({
				alternateRoutesRowData: this.state.defaultAlternateRoutesRowData.filter(e=> e.impacted_consignments > 0)
			})
		}
		else if(counterType == 3)
		{
			this.setState({
				alternateRoutesRowData: this.state.defaultAlternateRoutesRowData.filter(e => eval(e.alternate_routes).length > 0)
			})
		}
		else if(counterType == 4)
		{
			this.setState({
				alternateRoutesRowData: this.state.defaultAlternateRoutesRowData.filter(e => eval(e.alternate_routes).length == 0)
			})
		}
		else if(counterType === "onTripTruck"){			
			let onTripTruckList = []
			this.state.trucksGridData.filter(e => {
				if(e.request_type === "On Trip" ){
					onTripTruckList.push(e)
				}
			})
			this.setState({
				trucksdata : onTripTruckList
			})			
		}
		else if(counterType === "emptyTruck"){	
			let emptyTruckList = []
			this.state.trucksGridData.filter(e =>{
				if(e.request_type === "Empty"){
					emptyTruckList.push(e)
				}
			})
			this.setState({
				trucksdata : emptyTruckList
			})
		}
	}
	onShowAlternateRouteMap(base_route)
	{
		redirectURL.post("/dashboard/getRoutesForWeatherAlerts", {"route_id": base_route}).then((resp)=>{
			console.log(resp.data, "alternate_route")
			this.setState({
				// sliderRouteTranslate : "slider-translate-90p",
				showAlternateRouteMap: true,
				loadshow:'show-m',
				overly:'show-m',
				availableRoutes: resp.data
			})
			this.renderMapWeather()
		})
	}

	onShowImpactRoutes(base_route)
	{
		redirectURL.post("/dashboard/getRoutesForWeatherAlerts", {"route_id": base_route}).then((resp)=>{
			console.log(resp.data, "impact route")

			this.setState({
				// sliderRouteTranslate : "slider-translate-90p",
				showAlternateRouteMap: true,
				loadshow:'show-m',
				overly:'show-m',
				availableRoutes: resp.data
			})
			this.renderMapWeather()
		})
	}

	onClickShowOrHidePaths(cell)
	{
		routePaths.map((e)=>{
			if(e._id == cell.data._id)
			{
				e.line.setVisible(cell.node.selected)
			}
		})
		
	}


	onClickChangeRoute(route_type)
	{
		if(route_type == 1)
		{
			var dealerImpactedRoutes = this.state.impacted_routes.filter((e)=> e.for_code == this.state.selectedForCode.value);
			dealerImpactedRoutes = dealerImpactedRoutes.sort(function (a, b) {
				return a.no_of_trips_travelled - b.no_of_trips_travelled;
			});
			dealerImpactedRoutes = dealerImpactedRoutes.slice(0,3)
			this.setState({
				availableRoutes: dealerImpactedRoutes,
				alternateRoutesRowData: dealerImpactedRoutes,
				activeImpactRoute: true
			})
		}
		else
		{
			var impactedConsigners = []
			this.state.alternateRoutesRowData.map((e)=>{
				impactedConsigners.push(e.consigner_code)
			})
			impactedConsigners = [...new Set(impactedConsigners)]
			var dealerAlternateRoutes = this.state.alternate_routes.filter((e)=> e.for_code == this.state.selectedForCode.value && impactedConsigners.includes(e.consigner_code));
			dealerAlternateRoutes = dealerAlternateRoutes.sort(function (a, b) {
				return a.no_of_trips_travelled - b.no_of_trips_travelled;
			});
			dealerAlternateRoutes = dealerAlternateRoutes.slice(0,3)
			if(dealerAlternateRoutes.length)
			{
				this.setState({
					availableRoutes: dealerAlternateRoutes,
					alternateRoutesRowData: dealerAlternateRoutes,
					activeImpactRoute: false
				})
				this.renderMapWeather()
			}
			else
			{
				this.setState({
					basicTitle: "No Alternate Routes Available",
					basicType: "warning",
					show: true
				})
			}
			
		}
	}

	onClickCloseRouteModal()
	{
		this.setState({
			showAlternateRouteMap: false,
			showConsolidatedRoutes: false
		})
	}

	tabOneClicked = () =>{
		const {rowData} = this.state
		let tabOneRowData = []
		rowData.filter(e => {
			if(e.event_status === 1){
				tabOneRowData.push(e)
			}
		})

		this.setState({			
			showTabOne: true,
			showTabTwo: false, 
			activeTabOne: "activet", 
			activeTabTwo: "",
			rowGridData: tabOneRowData})
	}

	tabTwoClicked =() =>{
		const {rowData} = this.state
		let tabTwoRowData = []
		rowData.filter(e => {
			if(e.event_status === 0){
				tabTwoRowData.push(e)
			}
		})

		this.setState({
			showTabOne: false,
			showTabTwo: true, 
			activeTabOne: "", 
			activeTabTwo: "activet",
			rowGridData:tabTwoRowData })
	}

	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		if(this.state.redirectpage == 'yes')
		{
			return <Redirect to={'/manage/geofences'} push={true} />
		}
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'550px'
		}
		const wstyles={
			"paddingTop": '12px',
			"paddingRight": '29px',
			"paddingBottom": '2px',
			display : "none"
		}
    	const { dept_code } = this.state;
		const { geo_type } = this.state;
		var hideEdit=false;
		if(this.state.dept_code == "ALL")
		{
			hideEdit=true;
		}
		const columnwithDefs=[
			{
				field:'_id',
				  headerName:'',
				  colId:"_id",
				  //pinned:"left",
				   width: 72,
				   hide : hideEdit,
			  	cellRenderer:(params) => {
					let basestring = params.data._id+","+params.data.dept_code;
					let encryptedstring = window.btoa(basestring);
				  var htmloption = '<a href="/editweatherevent/'+encryptedstring+'/'+this.state.editpageType+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
				  return htmloption;
			  } 				
		  },
		  {
			headerName: "",
			field: "alt_routes",
			colId:"alt_routes",
			filter: true,
			width:120,
			resizable: true,
			cellRenderer: (params)=>{
				if(params.data.alternate_routes != undefined)
				{
					if(eval(params.data.alternate_routes).length)
					{
						return '<button class="btn btn-warning" style="padding:0px">Impact Routes</button>'
					}
					else
					{
						return "No Impact Routes"
					}
				}
				else
				{
					if(params.data.event_status == 1)
					{
						return "<span style='background:cornflowerblue;padding:1px;color:white'>In Progress</span>"
					}
					else
					{
						return ""
					}
				}
			}
		  },
		//   {
		// 	headerName: "",
		// 	field: "alternate_routes",
		// 	colId:"alternate_routes",
		// 	filter: true,
		// 	width:60,
		// 	resizable: true,
		// 	cellRenderer: (params)=>{
		// 		if(params.data.alternate_routes != undefined)
		// 		{
		// 			if(params.data.alternate_routes.length)
		// 			{
		// 				return "<i class='icofont icofont-map-pins f20'></i>"
		// 			}
		// 			return ""
		// 		}
		// 		else{
		// 			return ""
		// 		}
		// 	}
		//   },
		//   {
		// 	headerName: "For Codes",
		// 	field: "affected_for_codes",
		// 	colId:"affected_for_codes",
		// 	filter: true,
		// 	width:130,
		// 	resizable: true,
		//   },
		  
		  {
			headerName: "View",
			field: "_id",
			colId:"_id",
			filter: true,
			width:80,
			resizable: true,
			cellRenderer:'weatheralerts'
			},
			{
                headerName: "Department",
                field: "dept_code",
				filter: true,
				width:120,
				resizable: true,
				colId:"dept_code",
				valueGetter : function(params){
					if(params.data.dept_code === "SNDG")
					{
						return "Sales and Dispatch";
					}
					else if(params.data.dept_code === "LOG-PRT")
					{
						return "Spare Parts";
					}
					else if(params.data.dept_code === "LOG-TNP")
					{
						return "Train and Productions";
					}
					else{
						return "Supply Chain";
					}
				}
            },
			{
                headerName: "Event Name",
				field: "event",
				colId:"event",
				filter: true,
				width:280,
                resizable: true,
            },
			
            {
                headerName: "Start Alert",
                width:120,
				field: "start_alert",
				colId:"start_alert",
				resizable: true,
				valueGetter : function(params){
					//console.log(params.data.start_alert);
					return getHyphenDDMMMYYYYHHMM(params.data.start_alert);
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
			},
			{
                headerName: "End Alert",
				field: "end_alert",
				colId:"end_alert",
				width:120,
                filter: true,
				resizable: true,
				valueGetter : function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.end_alert);
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
            {
                headerName: "Notify via Email",
				width:140,
				colId:"notify_via_email",
                field: "notify_via_email",
                resizable: true,
			},
			{
                headerName: "Notify via Email (cc)",
				field: "notify_via_email_cc",
				colId:"notify_via_email_cc",
				width:140,
                filter: true,
                resizable: true,
            },
            {
				headerName: "Notify Duration",
				field : "notify_duration",
				colId : "notify_duration",
                width:140,
                resizable: true,
			},
			{
                headerName: "Carrier Type",
				field: "carrier_type",
				colId : "carrier_type",
				width:140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Notify Transporter",
                width:120,
                field: "notify_transporter",
                colId: "notify_transporter",
                resizable: true,
			},
            {
                headerName: "Notify SPOC",
                width:100,
				field: "notify_spoc",
				colId : "notify_spoc",
				resizable: true,
				valueGetter:function(params){
					if(typeof params.data.notify_spoc == 'undefined' || params.data.notify_spoc == '')
					{
						return "No";
					}
					else{
						return params.data.notify_spoc;
					}
				}
			},
			{
                headerName: "User Email",
				width:140,
                field: "user_email",
				colId : "user_email",
                resizable: true,
			},
			{
                headerName: "Event Status",
				width:140,
                field: "event_status",
				colId : "event_status",
				resizable: true,
				valueGetter : function(params){
					if(params.data.event_status == 1 ){
						return "Enabled";
					}
					else{
						return "Disabled";
					}
				}
			}
			
		]
		var showInvoice = (this.state.dept_code == "SNDG" || this.state.dept_code == "LOG-TNP")?false:true;
		var showGateout = (this.state.dept_code == "LOG-PRT")?false:true;
		var truckscolumnwithDefs = [
			{
				headerName:"Truck No",
				field:"truck_no",
				width:100,
				filter:true
			},
			{
				headerName:"Invoice No",
				field:"invoice_no",
				width:100,
				filter:true
			},
			{
				headerName:"Transporter Name",
				field:"transporter_name",
				width:100,
				filter:true
			},
			{
				headerName:"Consigner Code",
				field:"consigner_code",
				width:100,
				filter:true
			},
			{
				headerName:"Consignee Name",
				field:"consignee_name",
				width:100,
				filter:true
			},
			{
				headerName:"Consignee City",
				field:"consignee_city",
				width:100,
				filter:true
			},
			{
				headerName:"Last Know Area",
				field:"area",
				width:100,
				filter:true
			},
			{
				headerName:"Last Know State",
				field:"state",
				width:100,
				filter:true
			},
			{
				headerName:"Last Packet Time",
				field:"timestamp",
				width:100,
				filter:true
			},
			{
				headerName:"Latitude",
				field:"latitude",
				width:100,
				filter:true
			},
			{
				headerName:"Longitude",
				field:"longitude",
				width:100,
				filter:true
			},
			{
				headerName:"Truck Movement",
				field:"truck_movement",
				width:170,
				filter:true
			},
			{
				headerName : "Invoice Time",
				field : "invoice_time",
				width : 150,
				filter : true,
				hide : showInvoice,
				valueGetter : function(params)
				{
					if(params.data.invoice_time != "" && params.data.invoice_time != undefined
					&& params.data.invoice_time != "NA")
					{
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// }, 
			},
			{
				headerName : "Gate Out Time",
				field : "gate_out_time",
				width : 150,
				filter : true,
				hide : showGateout,
				valueGetter : function(params)
				{
					if(params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
					{
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// }, 
			}
			,{
				headerName: "Trip Type",
				field: "request_type",
				width:100,
				filter:true
			}
			

		]
        const altRouteCols = [
			{
				headerName : "",
				field : "consolidated_routes",
				width : 60,
				filter : true,
				resizable: true,
				cellRenderer: (params)=>{
					return "<i class='icofont icofont-map-pins f20'></i>"
				}
			},
			{
				headerName : "Consigner Code",
				field : "consigner_code",
				width : 120,
				filter : true,
				resizable: true,
				valueGetter: (params)=>{
					try{
						return mapPlants[params.data.consigner_code]
					}
					catch(e){return params.data.consigner_code}
				}
			},
			{
				headerName : "Zone",
				field : "zone_name",
				width : 120,
				filter : true,
				resizable: true,
			},
			
			{
				headerName : "Consignee City",
				field : "consignee_city",
				width : 120,
				filter : true,
				resizable: true
			},
			{
				headerName : "Impact Routes",
				field : "impacted_routes",
				width : 260,
				filter : true,
				resizable: true,
				cellRenderer:'impactRoutes'
			},
			{
				headerName : "Active Invoices",
				field : "impacted_invoices",
				colId: "impacted_invoices",
				width : 120,
				filter : true,
				resizable: true,
				valueGetter: (params)=>{
					if(params.data.impacted_invoices)
					{
						return "Yes"
					}
					else
					{
						return "No"
					}
				}
			},
			{
				headerName : "Active Trucks",
				field : "total_trucks",
				width : 100,
				filter : true,
				resizable: true,
			},
			{
				headerName : "Active Invoices",
				field : "impacted_invoices",
				width : 100,
				filter : true,
				resizable: true,
			},
			{
				headerName : "Alternate Routes",
				field : "alternate_routes",
				width : 260,
				filter : true,
				resizable: true,
				cellRenderer: "alternateRoutes"
			},
		]
		
		const consRouteCols = [
			{
				headerName : "",
				field : "show_route",
				width : 60,
				resizable: true,
				checkboxSelection: true,
				headerCheckboxSelection: true
			},
			{
				headerName : "Routes",
				field : "route_no",
				width : 140,
				resizable: true,
			},
			{
				headerName : "Average Distance (KM)",
				field : "ref_route_avg_google_distance",
				width : 140,
				resizable: true,
			},
		]

        return (
            <div className="container-fluid">
				<SweetAlert
				show={this.state.show}
				type={this.state.basicType}
				title={this.state.basicTitle}
				onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<button className={"btn btn-default "+this.state.activeTabOne} style={{marginLeft: "1em"}} onClick={this.tabOneClicked}> Enabled</button>
                <button className={"btn btn-default "+this.state.activeTabTwo} onClick={this.tabTwoClicked}>Disabled</button>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span> Emergency Alerts</span>
									{/* <button className="btn btn-success pull-right cs-btn" style={{color:"white"}} id="chloromap-id" onClick={this.showChloroMap.bind(this)}> <i className="icon-plus"></i> Map</button> */}
									{/* <button className="btn btn-success pull-right cs-btn" style={{color:"white"}} id="chloromap-id" onClick={this.showChloroMap.bind(this)}> <i className="icon-plus"></i> Map</button> */}
									<button className="btn btn-success pull-right cs-btn" id="addGeoFence" onClick={this.changeScreen.bind(this)} style={{color:"white"}}> <i className="icon-plus"></i> Add Alert Event</button>
									<button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen.bind(this)} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>
									{/* <span className="layoutbtns pull-right">
										<button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span> */}
								</h5>
								   
				   			</div>
				   			<div className="row weather-alert-card" style={wstyles}>
								<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
										<div className="form-group"> 
                                            <label className="c-lbl">Event Name *:</label>
                                            <input type="textarea" name="event" id="" onChangeCapture={this.checkHandler}  autoComplete="off" value={this.state.event} className="form-control" placeholder="Event Name"  required />
                                        </div>
										{(this.state.dept_code == "ALL")?
											<div className="form-group">
												<label>Select Truck No: </label>
												<Select 
												placeholder={"Select Department"}
												closeMenuOnSelect={true}
												isMulti={true}
												onChange={this.saveDeptState.bind(this)} 
												className={"border-radius-0"}
												style={{borderRadius:"0px"}}
												options={this.selectDept()} />
											</div>
										:""}
										<div className="row">
											<div className="col-sm-6">
												<div className="form-group">
													<label className="c-lbl">Start Alert (From) *:</label>
													<input type="text" name="startAlert"  onBlur={this.startAlertFormat} autoComplete="off" className="form-control datetimepicker_mask" required/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="form-group">
													<label className="c-lbl">End Alert (To) *:</label>
													<input type="text" name="endAlert" onBlur={this.endAlertFormat} id="end-alert-id" autoComplete="off" className="form-control datetimepicker_mask" required/>
												</div>
											</div>
										</div>
                                        <div className="form-group"> 
                                            <label className="c-lbl">Notify via Email (To) *:</label>
                                            <input type="textarea" name="notifyfirstto" id="notifyFirst" onChangeCapture={this.checkHandler}  autoComplete="off" value={this.state.notifyfirstto} className="form-control" placeholder=""  required />
                                        </div>
                                        <div className="form-group"> 
                                            <label className="c-lbl">Notify via Email (cc) *:</label>
                                            <input type="textarea" name="notifyfirstcc" id="notifyFirstcc" onChangeCapture={this.checkHandler} autoComplete="off" value={this.state.notifyfirstcc} className="form-control" placeholder=""  />
                                        </div>
										<div className="row">
											<div className="col-sm-6">
												<div className="form-group"> 
													<label className="c-lbl">Alert Duration (Hours) *:</label>
													<input type="number" id="alert-duration-id" name="notifyduration" min="1" autoComplete="off" onChange={this.checkHandler} value={this.state.notifyduration} className="form-control" placeholder=""  required />
												</div>
											</div>
										  	<div className="col-sm-6">
												<div className="form-group">
													<label className="c-lbl">Carrier Type *:</label>
													<Select onChange={this.changeHandler.bind(this)} style={{borderRadius:"0px"}} options={carrieroptions} required />
												</div>
											</div>
										</div>
                                        <div className="form-group">
					               			<label className="c-lbl">Notify Transporter : </label>
					               			&nbsp;&nbsp;<input type="checkbox" name="notifyTransporter" id="notifyTransporter" />
					               	   	</div>
                                        <div className="form-group">
					               			<label className="c-lbl">Notify SPOC : </label>
					               			&nbsp;&nbsp;<input type="checkbox" name="notifySpoc" id="notifySpoc" />
					               	   	</div>
										<div className="form-group">
					               			<label className="c-lbl">Notify when trucks move out of Geofence : </label>
					               			&nbsp;&nbsp;<input type="checkbox" name="notifyTrucks" id="notifyTrucks" />
					               	   	</div>
										
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveevent">Save</button>
					               			<a href="#" className="btn btn-info cs-btn" onClick={this.disableevent.bind(this)}>Disable Event</a>
					               		</div>
									</div>
									
				           		</form>
				           		<div className="col-xl-8 col-lg-8">
								
									{this.state.erroMessage == '' ? '':
											<div className="alert alert-danger"></div>
									}
									  {/* <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" /> */}
									{(this.state.dept_code != "" && this.state.dept_code != "ALL")?
									<div className="row">
										<div className="col-xl-2 col-lg-2">
											<input type="checkbox" className=" mt-10p" onClick={this.onClickShowTrucks.bind(this)} /> Show Trucks
										</div>
										<div className={"col-xl-5 col-lg-5 mb-20p "+(this.state.showtruckopts)}>										
											<Select placeholder="Select Trip Type"
												isMulti={false}
												value={this.state.triptype}
												onChange={this.setTripType.bind(this)} 
												options={(this.state.deptcode == "LOG-SC")?[{"value":1,"label":"ALL"}]:
												[{"value":1,"label":"ALL"},{"value":2,"label":"On Trip"},{"value":5,"label":"Waiting at Dealer"},{"value":3,"label":"Empty / Non-MSIL trip"}]
												} 
											required/>
										</div>
										<div className="col-xl-5 col-lg-5">
											<input type="checkbox" className=" mt-10p" onChange={this.onClickWeatherForecast.bind(this)} name="showweathers" id="showweathers" /> Show Today Weather Forecast
											
										</div>
									</div>
								
									:""}
									
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
									{(this.state.displayWeatherForecast)?
									<div className="col-sm-12 col-xs-12 col-md-12 map-legends row" style={{ marginTop: "10px",marginLeft: "10px"}}>
										{/* <div className="col-xl-12 col-lg-12"> */}
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/snowy.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 1)}></img> <figcaption>ALL</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/Heavyrain.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 2)}></img> <figcaption>Heavy Rain</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/wind.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 3)}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/thunderstorm.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 4)}></img><figcaption> Thunderstorm </figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/hailstorm.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 5)}></img>&nbsp;<figcaption> Hailstorm </figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require("../../assets/icons/cyclone.png")} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 6)}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
											</div>
											<div className="col-sm-2 col-xs-2 col-md-2">
												<img src={require('../../assets/icons/sandstorm.png')} style={{cursor: "pointer"}} onClick={this.onClickFilterWeather.bind(this, 7)}></img><figcaption> Sandstorm </figcaption>
											</div>
										{/* </div> */}
									</div>
									:""}
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
							<div className="weather-grid">
								
								<div id="myGrid" style={{width:"100%",height:"600px",padding:"14px"}} className="ag-theme-balham">    
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowGridData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										onGridReady={this.onGridReady1}
										onGridState={this.onGridState1}
										statusBar={this.state.statusBar}
										// sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										rowSelection={"single"}
										frameworkComponents={this.state.frameworkComponents}
										// detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										// rowClassRules={this.state.rowClassRules}
										onCellClicked={this.onCellClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										// suppressRowClickSelection={true}
										// rowMultiSelectWithClick={true}
									/>
								</div>
							</div>
		           		</div>
	           		</div>
                </div>
				
				{this.state.sliderRouteTranslate != ''?

					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
						 <div className="slide-r-title">
							<h4>
										Impact & Alternate Routes
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div className="crm-numbers pb-0">
								<div className="row">
									{/* <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,1)}>
										<span className="f13"><i className="fa fa-bars f24 text-primary"></i><br /> Total</span>
										<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.alt_routes_counter.total != '')?this.state.alt_routes_counter.total:0}/></span></h4>
										
									</div> */}
									<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,5)}>
										<span className="f13"><i className="icofont icofont-truck f24 redfont"></i><br /> Total Cities</span>
										<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.alt_routes_counter.totalCities != '')?this.state.alt_routes_counter.totalCities:0}/></span></h4>
									</div>
									<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,3)}>
										<span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br />Cities with alternate routes</span>
										<h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.alt_routes_counter.alternateRoutes != '')?this.state.alt_routes_counter.alternateRoutes:0}/></span></h4>
										
									</div>
									<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,4)}>
										<span className="f13"><i className="icofont icofont-map-pins f24 txt-danger"></i><br /> Cities with alternate routes NA</span>
										<h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.alt_routes_counter.alternateRoutesNa != '')?this.state.alt_routes_counter.alternateRoutesNa:0}/></span></h4>
									
									</div>
									
								</div>
							</div>
							<div id="myGrid" className="" style={{width:"100%",height:"600px",padding:"14px", marginTop: "1em"}} className="ag-theme-balham">    
								<AgGridReact
									modules={this.state.modules}
									columnDefs={altRouteCols}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.alternateRoutesRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									onGridReady={this.onGridAltRouteReady}
									onGridState={this.onGridAltRouteState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									rowSelection={"multiple"}
									// frameworkComponents={this.state.frameworkComponents}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									onCellClicked={this.onCellImpactRoutesClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									suppressRowClickSelection={true}
									frameworkComponents={{alternateRoutes: AlternateRoutes, impactRoutes: ImpactRoutes}}

								/>
							</div>
							 
						</div>
					</div>
				:""	
				}

				{/* Alternate route map */}
				{(this.state.showAlternateRouteMap)?
					<Modal open={this.state.showAlternateRouteMap} onClose={this.onClickCloseRouteModal.bind(this)} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
							{(this.state.consolidatedRowData.length)?
								<h5 className="model-title">{mapPlants[this.state.consolidatedRowData[0].consigner_code]} - {this.state.consolidatedRowData[0].consignee_city}</h5>
							:
								<h5 className="model-title">Route Map</h5>
							}
							{(this.state.showConsolidatedRoutes)?
								<div className="col-xl-12 col-lg-12 mt-20p row">
									{/* <ul style={{display: "flex"}} className="col-xl-12 col-lg-12 col-sm-12 col-md-12">
										<li style={{listStyleType: "none", "marginRight": '1em'}}>Consigner</li>
										<li style={{listStyleType: "none"}}>Consignee City</li>
									</ul>
									<ul style={{display: "flex"}} className="col-xl-12 col-lg-12 col-sm-12 col-md-12">
										<li style={{listStyleType: "none", "marginRight": "1em"}}>{this.state.consolidatedRowData[0].consigner_code}</li>
										<li style={{listStyleType: "none"}}>{this.state.consolidatedRowData[0].consignee_city}</li>
									</ul> */}
									<div id="myGrid"  style={{width:"100%",height:"600px",padding:"14px", marginTop: "1em"}}
									 className="col-xl-4 col-lg-4 col-md-4 col-sm-4 ag-theme-balham">    
										<AgGridReact
											modules={this.state.modules}
											columnDefs={consRouteCols}
											defaultColDef={this.state.defaultColDef}
											rowData={this.state.consolidatedRowData}
											onGridReady={this.onGridConsRouteReady}
											onGridState={this.onGridConsRouteState}
											rowSelection={"multiple"}
											onRowSelected={this.onClickShowOrHidePaths.bind(this)}
											gridOptions={{
												context: { componentParent: this }
											}}

										/>
									</div>
									<div id="mapweather" className="col-xl-8 col-lg-8 col-md-8 col-sm-8" style={{width:"100%",height:"600px"}}></div>
								</div>
							:
								<div className="col-xl-12 col-lg-12 mt-20p">
									<button style={{float:"right", padding: "3px"}} className="btn btn-info">Average Distance - {this.state.availableRoutes[0].ref_route_avg_google_distance} (KMS)</button>
									<div id="mapweather" style={{width:"100%",height:"600px"}}></div>
								</div>
							}
							
						</div>
					</Modal>
				:""}

				<div className={"slide-r "+(this.state.slidetranslate)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Weather Trucks Data
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
									
						</h4>
					</div>
					<div className="card">
						<div className="card-body" id="counterCardBody">
							<div className="crm-numbers pb-0">
								<div className="row">
									<div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this,"onTripTruck")}>
										<span className="f13"><img src={require("../../assets/icons/truck_4_24.png")} alt="onTripTruck" /><br /> On Trip</span>
										<h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.onTripCount != '')?this.state.onTripCount:0}/></span></h4>
										
									</div>
									<div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"emptyTruck")}>
										<span className="f13"><img src={require("../../assets/icons/truck_1_24.png")} alt="emptyTruck" /><br /> Empty Trip</span>
										<h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.emptyTripCount != '')?this.state.emptyTripCount:0}/></span></h4>										
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>						
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
							<div id="myGrid" style={{width:"100%",height:"400px",padding:"14px"}} className="ag-theme-balham">    
								<AgGridReact
									modules={this.state.modules}
									columnDefs={truckscolumnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.trucksdata}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									rowSelection={this.state.rowSelection}
									frameworkComponents={this.state.frameworkComponents}
									// detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									// rowClassRules={this.state.rowClassRules}
									// onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									suppressRowClickSelection={true}
								/>
							</div>
							
						</div>
					</div>
					
				</div>
				<div className="col-xl-12 col-lg-12">
                    
                </div>

				<div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
									
			
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Emergency Alerts</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This feature is available for SND and PRT departments. With this utility one can create alert for set of location where there is geographical disturbance (cyclone, heavy rains, Lockdowns etc) or any other anomaly for which MSIL requires report on regular interval time interval.
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
							<div className="col-xl-12 col-lg-12">
							For creating Emergency alerts following steps needs to be followed
							</div>
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										1. Set the event Name 
									</li>
									<li>
										2. Select the duration for which alert needs to be generated
									</li>
									<li>
										3. List of users which report needs to be sent ( main user is mandatory, CC is optional)
									</li>
									<li>
										4. Report duration in terms of hours required by user
									</li>
									<li>
										5. Type of vehicles required i.e in transit, empty or both
									</li>
									<li> 
										6. Whether transporters needs to be notified (optional)
									</li>
									<li>
										7. Notify SPOC (optional)
									</li>
								</ul>
							</div>
							<div className="col-xl-12 col-lg-12">
								Click on save button and the alert will be created. Report will be sent as per the duration selected by user to the mail ids mentioned while creating the alert.
							</div>
						</div>
					</div>
				</Modal>
			
			</div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadScriptWeather(url){
	var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}


    function loadDateTimeScript(){
        // alert("timeDate");
        $('.datetimepicker_mask').datetimepicker({
            mask:'39-19-9999 29:59',
            format:'d-m-Y H:i',
        });
        $('.datetimepicker_date').datetimepicker({
            mask:'39-19-9999',
            format:'d-m-Y',
            timepicker:false
        });
        var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
        //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
    }

$(document).ready(function(){
    $(document).on("keyup","#notifyFirst",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val());
        }

	});
	$(document).on("keyup","#notifyFirstcc",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val());
        }

	});
	$(document).on("keyup","#alert-duration-id",function(){
		if($(this).val() < 1)
		{
			$(this).val("");
		}
	})

	
});
// $(document).ready(function(){
// 	$("#addGeoFence").click(function(){
// 		alert("clickeed");
// 		$(".weather-grid").css("display","none");
// 		$(".weather-alert-card").css("display","flex");
// 		$(this).css("display","none");
// 	});
// })
	// $("#addGeoFence").click(function(){
	// 	$(".weather-grid").css("display","none");
	// 	$(".weather-alert-card").css("display","flex");
	// 	$(this).css("display","none");
	// });
// });

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}


function getPolygonCoords()
{
	// console.log("function ", geoFencingAreaArr);
	if(geoFencingAreaArr.length > 0)
	{
		coordinates = [];
		for(var g=0; g<geoFencingAreaArr.length;g++)
		{

			var len = geoFencingAreaArr[g].getPath().getLength();
			console.log(len);
			var htmlStr = "";
			var newcoordinates=[];
			for (var i = 0; i < len; i++) {
				// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
				// coordinates.push()
				var strlatlng = geoFencingAreaArr[g].getPath().getAt(i).toUrlValue(5);
				var spliting = strlatlng.split(",");
				var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
				newcoordinates.push(latlngd);
			// htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
			//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
			//htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
			}
			coordinates.push(newcoordinates)
		}
		console.log(coordinates);
	}
	// var len = geoFencingAreaArr.getPath().getLength();
	// console.log(len);
	// var htmlStr = "";
	// var newcoordinates=[];
	// for (var i = 0; i < len; i++) {
	// 	// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	// 	// coordinates.push()
	// 	var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
	// 	var spliting = strlatlng.split(",");
	// 	var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
	// 	newcoordinates.push(latlngd);
	// // htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
	// //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	// //htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
	// }
	// coordinates.push(newcoordinates)
	// console.log("htmlStr", coordinates);
	
}

