import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ImportsCoilsCountersComponent extends Component {
    constructor(props){
    	super(props);
    	this.state={
            records:[],
            allRowDatacount:0,
            totalCoilsCount:0,
            coilInfoReceivedCount:0,
            arrivedAtIndianPortCount:0,
            mundraCount:0,
            deendayalCount:0,
            insideKandlaWarehouseCount:0,
    		inTransitToIcdCount:0,
            insideIcdCount:0,            
            inTransitToMsilCount:0,
            insideMsilCount:0,
            msiltovendor:0,
            vendortomsp:0
    	}
    
    }
    componentWillReceiveProps(newprops){
       this.setState({
            allRowDatacount:newprops.allRowDatacount,
            totalCoilsCount:newprops.totalCoilsCount,
            coilInfoReceivedCount:newprops.coilInfoReceivedCount,
            arrivedAtIndianPortCount:newprops.arrivedAtIndianPortCount,
            mundraCount:newprops.mundraCount,
            deendayalCount:newprops.deendayalCount,
            insideKandlaWarehouseCount:newprops.insideKandlaWarehouseCount,
            inTransitToIcdCount:newprops.inTransitToIcdCount,
            insideIcdCount:newprops.insideIcdCount,
            inTransitToMsilCount:newprops.inTransitToMsilCount,
            insideMsilCount:newprops.insideMsilCount,
            msiltovendor:newprops.msiltovendor,
            vendortomsp:newprops.vendortomsp
       })
    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                               
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13">
                                        <i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i>
                                        <br /> All<br /><br />
                                    </span>
                                    <h4 className="txt-info f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.totalCoilsCount != '')?this.state.totalCoilsCount:0}/>
                                        </span>
                                    </h4>
                                </div> 
                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        <i className="icofont icofont-ship  f22 txt-primary-dark"></i>
                                        <br /> High Seas<br /><br />
                                    </span>
                                    <h4 className="txt-primary f46">
                                        <span className="counter">
                                            <CountUp end= {(this.state.coilInfoReceivedCount) ? this.state.coilInfoReceivedCount : 0}/>
                                        </span>
                                    </h4>
                                </div>
                                <div className="col cpointer">
                                    <span className="f13" onClick={this.onClickCounter.bind(this,2)}>
                                        <i className="icofont icofont-ship-alt f25 txt-secondary-dark"></i>
                                        <br /> Arrived at Indian Port
                                    </span>
                                    <h4 className="txt-secondary f46" onClick={this.onClickCounter.bind(this,2)}>
                                        <span className="counter">
                                            <CountUp end= {(this.state.arrivedAtIndianPortCount) ? this.state.arrivedAtIndianPortCount:0}/>
                                        </span>
                                    </h4>
                                    <div className="row" style={{marginTop:"10%"}}>
                                        <div className="col-xl-6 col-lg-6" onClick={this.onClickCounter.bind(this,14)} title="Mundra">
                                            <span className="f13">
                                                M
                                            </span>
                                            <h5 className="txt-info sub-cntrs">
                                                <span className="counter">
                                                    <CountUp end= {(this.state.mundraCount) ? this.state.mundraCount:0}/>
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="col-xl-6 col-lg-6" style={{borderLeft:"1px solid #ddd"}} onClick={this.onClickCounter.bind(this,15)} title="Deendayal">
                                            <span className="f13">
                                                D
                                            </span>
                                            <h5 className="txt-pink-medium sub-cntrs">
                                                <span className="counter">
                                                    <CountUp end= {(this.state.deendayalCount) ? this.state.deendayalCount:0}/>
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13">
                                        <i className="icofont icofont-ui-home f22 txt-info-medium"></i>
                                        <br /> Inside Kandla WH<br /><br />
                                    </span>
                                    <h4 className="txt-pink-medium f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.insideKandlaWarehouseCount) ? this.state.insideKandlaWarehouseCount : 0}/>
                                        </span>
                                    </h4>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt f24 txt-pink-light"></i>
                                        <br /> In transit to ICD<br /><br />
                                    </span>
                                    <h4 className="txt-pink-medium f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.inTransitToIcdCount) ? this.state.inTransitToIcdCount : 0}/>
                                        </span>
                                    </h4>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,5)}>
                                    <span className="f13">
                                        <i className="icofont icofont-ui-home f22 txt-info-medium"></i>
                                        <br />Inside ICD<br /><br />
                                    </span>
                                    <h4 className="txt-info-dark f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.insideIcdCount) ? this.state.insideIcdCount : 0}/>
                                        </span>
                                    </h4>
                                </div>

                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,6)}>
                                    <span className="f13">
                                        <i className="icofont icofont-van-alt f24 txt-success"></i>
                                        <br /> In transit to MSIL <br /><br />
                                    </span>
                                    <h4 className="txt-success f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.inTransitToMsilCount) ? this.state.inTransitToMsilCount : 0}/>
                                        </span>
                                    </h4>
                                </div>
                               
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,7)}>
                                    <span className="f13">
                                        <i className="icofont icofont-ui-home txt-info f24"></i>
                                        <br /> Inside MSIL<br /><br />
                                    </span>
                                    <h4 className="txt-info f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.insideMsilCount != '') ? this.state.insideMsilCount : 0}/>
                                        </span>
                                    </h4>
                                </div>
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,8)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt txt-warning f24"></i>
                                        <br /> MSIL To Vendor/MSP<br /><br />
                                    </span>
                                    <h4 className="txt-warning f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.msiltovendor != '') ? this.state.msiltovendor : 0}/>
                                        </span>
                                    </h4>
                                </div>
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,9)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt txt-primary f24"></i>
                                        <br /> Vendor to MSP<br /><br />
                                    </span>
                                    <h4 className="txt-primary f46">
                                        <span className="counter">
                                            <CountUp end={(this.state.vendortomsp != '') ? this.state.vendortomsp : 0}/>
                                        </span>
                                    </h4>
                                </div>

                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






