'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import DamageColumnChart from "./damageAnalysisColumnChart";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;


export default class damageanalysis extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        holidayEvents : [],
        maxNodes: [],
        telRoutes: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        invoice_month : "",
        transportercode:{
            value:"all",
            label:"All"
        },
        transporter_code:"all",
        tottalValues : [],
        pinnedBottomRowData: [],
        defStateData : [],
        defStateCategories : [],
        perDefStateData : [],
        perDefStateCategories : [],
        defClusterData : [],
        defClusterCategories : [],
        defConsigneeStateData : [],
        defConsigneeStateCategories : [],
        defConsigneeCityData : [],
        defConsigneeCityCategories : [],
        defConsigneeData : [],
        defConsigneeCategories : [],
        defPaymentsData : [],
        defPaymentCategories : [],
        defPaymentsUndamagedData : [],
        defPaymentUndamagedCategories : [],
    }
}

componentDidMount(){
    this.onloadPageData();
}

onloadPageData(){
    var params = {}
    console.log(params,"params")
    redirectURL.post("/consignments/damageAnalysis",params).then((response)=>{
        var rowData = response.data;
        var defStateData = [];
        var defStateCategories = [];
        // Defects w.r.t State
        var dmgpct_state = JSON.parse(rowData.dmgpct_state);
        console.log(dmgpct_state,"dmgpct_state");
        dmgpct_state.map(function(k){
            defStateData.push({
                "name": k.state,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defStateCategories.push(k.state)
        });
        // Percentage of defects reached a state
        var perDefStateData = [];
        var perDefStateCategories = [];
        var dmgshare_state = JSON.parse(rowData.dmgshare_state);
        console.log(dmgshare_state,"dmgshare_state");
        dmgshare_state.map(function(k){
            perDefStateData.push({
                "name": k.state,
                "showTooltip": 1,
                "y": parseFloat(k.count.toFixed(2)),
            })
            perDefStateCategories.push(k.state)
        });
        // Defects w.r.t cluster
        var defClusterData = [];
        var defClusterCategories = [];
        var dmgshare_cluster = JSON.parse(rowData.dmgshare_cluster);
        console.log(dmgshare_cluster,"dmgshare_cluster");
        dmgshare_cluster.map(function(k){
            defClusterData.push({
                "name": k.cluster,
                "showTooltip": 1,
                "y": parseFloat(k.count.toFixed(2)),
            })
            defClusterCategories.push(k.state)
        });
        // Defects w.r.t consignee state
        var defConsigneeStateData = [];
        var defConsigneeStateCategories = [];
        var dmgshare_csstate = JSON.parse(rowData.dmgshare_csstate);
        console.log(dmgshare_csstate,"dmgshare_csstate");
        dmgshare_csstate.map(function(k){
            defConsigneeStateData.push({
                "name": k.consignee_state,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defConsigneeStateCategories.push(k.state)
        });
        // Defects w.r.t Consignee city
        var defConsigneeCityData = [];
        var defConsigneeCityCategories = [];
        var dmgshare_cscity = JSON.parse(rowData.dmgshare_cscity);
        console.log(dmgshare_cscity,"dmgshare_cscity");
        dmgshare_cscity.map(function(k){
            defConsigneeCityData.push({
                "name": k.consignee_city,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defConsigneeCityCategories.push(k.state)
        });
        // Defects w.r.t consignee name
        var defConsigneeData = [];
        var defConsigneeCategories = [];
        var dmgshare_csname = JSON.parse(rowData.dmgshare_csname);
        console.log(dmgshare_csname,"dmgshare_csname");
        dmgshare_csname.map(function(k){
            defConsigneeData.push({
                "name": k.consignee_name,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defConsigneeCategories.push(k.state)
        });
        // Defects w.r.t total payments
        var defPaymentsData = [];
        var defPaymentCategories = [];
        var fr_ratio_damage = JSON.parse(rowData.fr_ratio_damage);
        console.log(fr_ratio_damage,"fr_ratio_damage");
        fr_ratio_damage.map(function(k){
            defPaymentsData.push({
                "name": k.payment_share,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defPaymentCategories.push(k.state)
        });
        // Defects w.r.t total payments undamaged data
        var defPaymentsUndamagedData = [];
        var defPaymentUndamagedCategories = [];
        var fr_nodamage = JSON.parse(rowData.fr_nodamage);
        console.log(fr_nodamage,"fr_nodamage");
        fr_nodamage.map(function(k){
            defPaymentsUndamagedData.push({
                "name": k.payment_share,
                "showTooltip": 1,
                "y": parseFloat(k.percentage.toFixed(2)),
            })
            defPaymentUndamagedCategories.push(k.state)
        });
        this.setState({
            defStateData : defStateData,
            defStateCategories : defStateCategories,
            perDefStateData : perDefStateData,
            perDefStateCategories : perDefStateCategories,
            defClusterData : defClusterData,
            defClusterCategories : defClusterCategories,
            defConsigneeStateData : defConsigneeStateData,
            defConsigneeStateCategories : defConsigneeStateCategories,
            defConsigneeCityData : defConsigneeCityData,
            defConsigneeCityCategories : defConsigneeCityCategories,
            defConsigneeData : defConsigneeData,
            defConsigneeCategories : defConsigneeCategories,
            defPaymentsData : defPaymentsData,
            defPaymentCategories : defPaymentCategories,
            defPaymentsUndamagedData : defPaymentsUndamagedData,
            defPaymentUndamagedCategories : defPaymentUndamagedCategories,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
}
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}
closeAlert = () => {
    this.setState({
        show: false
    });
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
  render() {
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div className="card-header">
                <h5>
                    <i className="icofont icofont-dashboard-web cus-i"></i> <span>Damage Analysis</span>
                </h5>
            </div>
            <div className="card-body row col-xl-12 col-lg-12">
                {/* Defects w.r.t State */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="pie"
                        title={"Defects w.r.t State"}
                        subtitle={""}
                        context={this}
                        series={this.state.defStateData}
                        categories={this.state.defStateCategories}
                        seriesName=""
                        yaxistitle=""
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Percentage of defects reached a state */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="column"
                        title={"Percentage of defects reached a state"}
                        subtitle={""}
                        context={this}
                        series={this.state.perDefStateData}
                        categories={this.state.perDefStateCategories}
                        seriesName=""
                        yaxistitle="Percentage of defects reached a state"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Defects w.r.t cluster */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="pie"
                        title={"Defects w.r.t cluster"}
                        subtitle={""}
                        context={this}
                        series={this.state.defClusterData}
                        categories={this.state.defClusterCategories}
                        seriesName=""
                        yaxistitle="Defects w.r.t cluster"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Defects w.r.t consignee state */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="pie"
                        title={"Defects w.r.t consignee state"}
                        subtitle={""}
                        context={this}
                        series={this.state.defConsigneeStateData}
                        categories={this.state.defConsigneeStateCategories}
                        seriesName=""
                        yaxistitle="Defects w.r.t consignee state"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Defects w.r.t consignee city */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="column"
                        title={"Defects w.r.t consignee city"}
                        subtitle={""}
                        context={this}
                        series={this.state.defConsigneeCityData}
                        categories={this.state.defConsigneeCityCategories}
                        seriesName=""
                        yaxistitle="Defects w.r.t consignee city"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Defects w.r.t consignee name */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="column"
                        title={"Defects w.r.t consignee name"}
                        subtitle={""}
                        context={this}
                        series={this.state.defConsigneeData}
                        categories={this.state.defConsigneeCategories}
                        seriesName=""
                        yaxistitle="Defects w.r.t consignee name"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Percentage of total payments (Damaged vins) */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="pie"
                        title={"Percentage of total payments (Damaged vins)"}
                        subtitle={""}
                        context={this}
                        series={this.state.defPaymentsData}
                        categories={this.state.defPaymentCategories}
                        seriesName=""
                        yaxistitle="Percentage of total payments (Damaged vins)"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
                {/* Percentage of total payments (Undamaged vins) */}
                <div className="col-xl-6">
                    <div>
                        <DamageColumnChart
                        chartType="pie"
                        title={"Percentage of total payments (Undamaged vins)"}
                        subtitle={""}
                        context={this}
                        series={this.state.defPaymentsUndamagedData}
                        categories={this.state.defPaymentUndamagedCategories}
                        seriesName=""
                        yaxistitle="Percentage of total payments (Undamaged vins)"
                        drilldownseries={[]}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}
