import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import UpdateItem from './updatetransporteritem';
import DeleteItem from './deleteItem';

import axios from 'axios';

import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class MLLDealers extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: null,

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            consignee_code:"",
            consignee_name:"",
            consignee_city:"",
            consignee_state:"",
            consignee_state_code:"",
            consignee_zone:"",
            lat:"",
            lon:""
		};
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/mllsndtrucks":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/mllprttrucks":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/mlltnptrucks":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/mllsctrucks":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
            departmentCode:currentDepartmentCode,
            loadshow:"show-n",
            overly:"show-n"
		});
        this.onLoadData();
		
       
	};
    onLoadData(){
        redirectURL.post('/consignments/mlldealerslist')    
		  .then((response) => {
            var records = response.data.records;
            var recordsarr= []
            if(records.length > 0)
            {
                records.map((item) => {
                    // var latlng = JSON.parse(item.plant_coordinates)
                    // console.log("latlng",latlng)
                    recordsarr.push({
                        "_id" : item._id,
                        "consignee_code" : item.consignee_code,
                        "consignee_name" : item.consignee_name,
                        "consignee_city" : item.consignee_city,
                        "consignee_state" : item.consignee_state,
                        "consignee_state_code" : item.consignee_state_code,
                        "consignee_zone" : item.consignee_zone,
                        "lat" : item.lat,
                        "lon" : item.lon
                    })
                })
            }
            console.log("recordsarr ", recordsarr)
            this.setState({
                rowData:recordsarr
            });
		  })
		  .catch(function (error) {
		    console.log(error);
        });
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    updateRowHandler(cell)
	{
         if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Trucks";
			if (currentDepartmentTitle) {
				pageTitle = "Trucks" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        // var params = {
        //     truck_no:this.state.truck_no,
        //     truck_type:this.state.trucktype.value,
        //     transporter_code:this.state.transporter.value,
        //     dept_code:this.state.deptcode.value,
        // }
		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/consignments/updatemlldealer", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }

    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Plants";
			if (currentDepartmentTitle) {
				pageTitle = "Plants" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        var params = {
            "consignee_code" : this.state.consignee_code,
            "consignee_name" : this.state.consignee_name,
            "consignee_city" : this.state.consignee_city,
            "consignee_state" : this.state.consignee_state,
            "consignee_state_code" : this.state.consignee_state_code,
            "consignee_zone" : this.state.consignee_zone,
            "lat" : this.state.lat,
            "lon" : this.state.lon
        }
		var fdata = {
	    		rownode:params
	    }
	    redirectURL.post("/consignments/updatemlldealer", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added item',
                        basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                        
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
        
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRso:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Dealers";
			if (currentDepartmentTitle) {
				pageTitle = "Dealers" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/consignments/deletemlldealer", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
	  	        {
	  	          headerName: "consignee Code",
	  	          field: "consignee_code",
	  	          width: 140,
                  editable:true,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Consignee Name",
                  field: "consignee_name",
				  width: 150,
				  editable : true,
                  filter: "agSetColumnFilter"	    	          
                },
                
		        {
                    headerName: "Consignee City",
                    field: "consignee_city",
                    width: 150,
                    editable : true,
                    filter: "agSetColumnFilter",
                    // valueGetter:function(params){
                    //     try{
                    //         var coords = JSON.parse(params.data.plant_coordinates);
                    //         return coords.lat
                    //     }
                    //     catch(e){
                    //         return "";
                    //     }
                    // }

                },
		        {
                    headerName: "Consignee State",
                    field: "consignee_state",
                    width: 200,
                    editable : true,
                    filter: "agSetColumnFilter",
                    // valueGetter:function(params){
                    //     try{
                    //         var coords = JSON.parse(params.data.plant_coordinates);
                    //         return coords.lng
                    //     }
                    //     catch(e){
                    //         return "";
                    //     }
                    // }

                },
		        {
                    headerName: "Consignee State Code",
                    field: "consignee_state_code",
                    width: 120,
                    editable : true,
                    filter: "agSetColumnFilter",
                    // cellEditor: 'agSelectCellEditor',   
                    // cellEditorParams: {
                    //     values: ["SNDG","LOG-PRT","LOG-TNP", "LOG-SC"],
                    // }	    	          
				},
				
		        {
                    headerName: "Consignee Zone",
                    field: "consignee_zone",
                    width: 150,
                    editable : true,
                    filter: "agSetColumnFilter"	    	          
                },
				
		        {
                    headerName: "Latitude",
                    field: "lat",
                    width: 150,
                    editable : true,
                    filter: "agSetColumnFilter"	    	          
                },
				
		        {
                    headerName: "Longitude",
                    field: "lon",
                    width: 150,
                    editable : true,
                    filter: "agSetColumnFilter"	    	          
                },
		        {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'updateItem',
                    editable:false
		        },
		        {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'deleteItem',
                    editable:false
		        }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Dealers {this.state.currentDepartmentTitle} </span>
                                       <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Dealer
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee Code</label>
							    <input type="text" placeholder="Consignee Code" value={this.state.consignee_code} onChange={this.changeHandler.bind(this)} id="consignee_code" name="consignee_code" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee Name</label>
							    <input type="text" placeholder="Consignee Name" value={this.state.plant_name} onChange={this.changeHandler.bind(this)} id="consignee_name" name="consignee_name" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Latitude</label>
							    <input type="text" placeholder="Latitude" value={this.state.lat} onChange={this.changeHandler.bind(this)} id="lat" name="lat" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Longitude</label>
							    <input type="text" placeholder="Longitude" value={this.state.lon} onChange={this.changeHandler.bind(this)} id="lon" name="lon" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee City</label>
							    <input type="text" placeholder="City" value={this.state.consignee_city} onChange={this.changeHandler.bind(this)} id="consignee_city" name="consignee_city" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee State</label>
							    <input type="text" placeholder="Consignee State" value={this.state.consignee_state} onChange={this.changeHandler.bind(this)} id="consignee_state" name="consignee_state" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee State Code</label>
							    <input type="text" placeholder="Consignee State Code" value={this.state.consignee_state_code} onChange={this.changeHandler.bind(this)} id="consignee_state_code" name="consignee_state_code" className="form-control" required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consignee Zone</label>
							    <input type="text" placeholder="Consignee Zone" value={this.state.consignee_zone} onChange={this.changeHandler.bind(this)} id="consignee_zone" name="consignee_zone" className="form-control" required />
							</div>

							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}