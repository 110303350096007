'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import CSVFileValidator from 'csv-file-validator';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");

export default class PlanningStatus extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Empty Truck Vicinity",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
                UpdateButton:UpdateButton,
                UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            last_approval_date: "",
			showPlant:'show-n',
			showAddr:'show-n',
            request_type:'',
            gridHeaderName: "",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rowData : [],
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            recordsLength:1,
            sliderbulkupload : "",
            csvcontent: "",
            rowClassRules : {
                "colorForDangerLight" : function(params){
                    if(params.data.plant_name == "Total")
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            screenView: "",
            input_funds_received: "",
            input_funds_available: ""
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA
        if(this.props.match.path == "/sndplanningstatusmgmt")
        {
            var userType="management";
        }
        if(this.props.match.path == "/sndplanningstatus")
        {
            var userType="user";
        }
        var screenView = "";
        if(this.props.match.path == "/mgmtplanningstatus")
        {
            var screenView = "Mobile";
            var userType="management";
        }
        this.setState({
            userType:userType,
            screenView: screenView
        });	
        this.sndPlanningStatus(userType);
    }
    onClickShowBulkUpload()
    {
        this.setState({
            sliderbulkupload : "slider-translate-40p",
            input_funds_received: "",
            input_funds_available: "",
            csvcontent: [],
            overly : "show-m"
        })
    }
    sndPlanningStatus(userType)
    {
        var params = {
            "userType" : userType,
        }
        redirectURL.post("/consignments/getsndPlanningStatus",params).then((response)=>{
            var stockData = response.data.records;
            stockData.map(function(e, index){
                if(index != 0){delete e['funds_available']; delete e['funds_received'] }
            })
            var keyslist = Object.keys(stockData[0]);
            var total_dict = {};
            keyslist.map(function(key){
                if(key != "plant_name" && key != "plant_code" && key != "approved_by" && key != "approval_done_at" 
                    && key != "approver_email" && key != "funds_available" && key != "funds_received")
                {
                    var temp = 0;
                    stockData.map(function(e){
                        temp = temp + e[key]
                    });
                    total_dict[key] = temp
                }
            })
            total_dict['plant_name'] = "Total";
            total_dict['funds_available'] = "total-"+stockData[0]['funds_available'].toString()
            total_dict['funds_received'] = "total-"+stockData[0]['funds_received'].toString()
            console.log(total_dict)
            stockData.push(total_dict)
            if(userType == "user")
            {
                this.setState({
                    gridHeaderName: "Last Data Updated On  "+getHyphenDDMMMYYYYHHMM(stockData[0]['report_generated_at'])
                })
            }
            this.setState({
                stockData : stockData,
                last_approval_date: stockData[0]['approval_done_at'],
                recordsLength : response.data.records.length
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
			sliderbulkupload: ""
		})
	}
	
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
    };
    
    updateLmStates(params)
    {
        params['userid'] = localStorage.getItem('userid');
        params['email'] = localStorage.getItem('email');
        params['username'] = localStorage.getItem('username');
        redirectURL.post("/consignments/updateRakeUnloadingTerminals",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Updated Successfully.",
                    basicType : "success",
                    show : true
                })
                this.railunloadingterminals(this.state.userType);		        
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to update.",
                    basicType : "warning",
                    show : true
                })
            }
        })       
    }

    approveAllData()
    {
        var params = {};
        params['userid'] = localStorage.getItem('username');
        params['email'] = localStorage.getItem('email');
        params['username'] = localStorage.getItem('username');
        redirectURL.post("/consignments/approvePlanningStatusData",params).then((response)=>{
            console.log(response);
            if(response.data.message == "Success")
            {
                this.setState({
                    basicTitle:"Approved.",
                    basicType : "success",
                    show : true
                })
                window.location.reload();
            }
            else
            {
                this.setState({
                    basicTitle:"Failed to approve.",
                    basicType : "warning",
                    show : true
                })
            }
        }) 
    }
    updateAllRows(){
        let rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        console.log(rowData);
        var originalRowData = rowData;

        var check = originalRowData.map(function(e, index){
            if(index != (originalRowData.length - 1))
            {
                if(e.funds_available == undefined){
                    e['funds_available'] = parseInt(originalRowData[0]['funds_available'])
                }
                if(e.funds_received == undefined){
                    e['funds_received'] = parseInt(originalRowData[0]['funds_received'])
                }
                var updatedata = e;
                var flag = 0;
                
                for(var key in updatedata)
                {
                    if(key != "plant_name" && key != "plant_code" && key != "report_generated_at" && key != "approved_by"
                        && key != "approval_done_at" && key != "approver_email")
                    {
                        if(updatedata[key] == "" || updatedata[key] == undefined || updatedata[key] == null)
                        {
                            updatedata[key] = 0;
                        }
                        if(isNaN(parseInt(updatedata[key])) == false)
                        {
                            updatedata[key] = parseInt(updatedata[key])
                        }
                        else{
                            flag = 1;
                            break;
                        }
                    }
                }
                
                // if(flag == 0)
                // {
                    redirectURL.post("/consignments/updatePlanningStatusPlantData",{formdata: updatedata}).then((response)=>{
                //         this.setState({
                //             basicType : "success",
                //             basicTitle: "Updated Successfully",
                //             show: true
                //         })
                //         window.location.reload();
                    }).catch((e)=>console.log(e))
        
                // }
                // else
                // {
                //     this.setState({
                //         basicType : "warning",
                //         basicTitle: "Invalid Values Entered",
                //         show: true
                //     })
                // }
            }
            
        })
        Promise.all([check]).then(()=>{
                this.setState({
                    basicType : "success",
                    basicTitle: "Updated Successfully",
                    show: true
                })
                window.location.reload();
        })
        
    }
    onCellClicked(cell){
        if(cell.colDef.field == "update" && cell.data.plant_name != "Total"){
            var originalRowData = this.state.stockData;
            if(cell.data.funds_available == undefined){
                cell.data['funds_available'] = parseInt(originalRowData[0]['funds_available'])
            }
            if(cell.data.funds_received == undefined){
                cell.data['funds_received'] = parseInt(originalRowData[0]['funds_received'])
            }
            var updatedata = cell.data;
            var flag = 0;
            console.log(updatedata);
            for(var key in updatedata)
            {
                if(key != "plant_name" && key != "plant_code" && key != "report_generated_at" && key != "approved_by"
                    && key != "approval_done_at" && key != "approver_email")
                {
                    if(updatedata[key] == "" || updatedata[key] == undefined || updatedata[key] == null)
                    {
                        updatedata[key] = 0;
                    }
                    if(isNaN(parseInt(updatedata[key])) == false)
                    {
                        updatedata[key] = parseInt(updatedata[key])
                    }
                    else{
                        flag = 1;
                        break;
                    }
                }
            }
            console.log(updatedata);
            
            console.log(flag,"flag");
            if(flag == 0)
            {
                redirectURL.post("/consignments/updatePlanningStatusPlantData",{formdata: updatedata}).then((response)=>{
                    this.setState({
                        basicType : "success",
                        basicTitle: "Updated Successfully",
                        show: true
                    })
                    window.location.reload();
                }).catch((e)=>console.log(e))

            }
            else
            {
                this.setState({
                    basicType : "warning",
                    basicTitle: "Invalid Values Entered",
                    show: true
                })
            }
            
        }
    }
    changeFileHandler = async (e) => {
        const config = {
            headers: [
                { 
                    name: 'plant_name',
                    inputName: 'plant_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'stock',
                    inputName: 'stock',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'q_hold_stock',
                    inputName: 'q_hold_stock',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'dispatchable_stock',
                    inputName: 'dispatchable_stock',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'retrieval',
                    inputName: 'retrieval',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
                { 
                    name: 'vehicles_planned',
                    inputName: 'vehicles_planned',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'vehicles_invoiced',
                    inputName: 'vehicles_invoiced',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'vehicles_exit',
                    inputName: 'vehicles_exit',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'opening_pending_exit',
                    inputName: 'opening_pending_exit',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'current_pending_exit',
                    inputName: 'current_pending_exit',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
            this.setState({
                csvcontent: csvData.data
            });
        })
        .catch(err => {})
    
        this.setState({
            file: e.target.files[0]
        });
    
        // if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        // {
          
        // }
        // else{
        //     e.target.value = null;
        //     this.setState({
        //         uploadFile:'',
        //         file:"",
        //         show: true, 
        //         basicType:'danger', 
        //         basicTitle:'Please upload file having extensions .csv only.',
        //     });
        // }
        
    }
    uploadBulkForceFormHandler(event)
    {
        event.preventDefault();
        var funds_available = this.state.input_funds_available;
        var funds_received = this.state.input_funds_received
        var check = this.state.csvcontent.map(function(updatedata){
            updatedata['funds_available'] = funds_available;
            updatedata['funds_received'] = funds_received;
            for(var key in updatedata)
            {
                if(key != "plant_name" && key != "plant_code" && key != "report_generated_at" && key != "approved_by"
                    && key != "approval_done_at" && key != "approver_email")
                {
                    if(updatedata[key] == "" || updatedata[key] == undefined || updatedata[key] == null)
                    {
                        updatedata[key] = 0;
                    }
                    if(isNaN(parseInt(updatedata[key])) == false)
                    {
                        updatedata[key] = parseInt(updatedata[key])
                    }
                    
                }
            }
            console.log(updatedata)
            redirectURL.post("/consignments/bulkUpdatePlanningStatusPlantData",{formdata: updatedata}).then((response)=>{
    
            }).catch((e)=>console.log(e))
        });
        Promise.all([check]).then(()=>{
                this.setState({
                    basicType : "success",
                    basicTitle: "Updated Successfully",
                    show: true
                })
                window.location.reload();
        })
    }
    render(){
        if(this.state.userType == "management")
        {
            var showTransporter = true;
            var editField = false;
        }
        else
        {
            var showTransporter = false;
            var editField = true;
        }
        const rowLength = this.state.stockData.length;
		var columnwithDefs= [
            // {
            //     headerName: "update",
            //     field: "update",
            //     width: 130,
            //     cellRenderer: function(params){
            //         if(params.data.plant_name != "Total")
            //         {
            //             return "<button class='btn btn-success' style='padding:0px 25px 5px 27px'>update</button>"
            //         }
            //         else
            //         {
            //             return ""
            //         }
            //     },
            //     hide: showTransporter
            // },
			{
                headerName : "Total",
                headerClass:["cellstylegridB","cellTopHeader"],
                children : [
                    {
                        headerName : "",
                        headerClass:["cellstylegridB","cellTopHeader"],
                        children : [
                            {
                                headerName: "Location",
                                field: "plant_name",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable:false,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["cellstylegridB","cellBorder"]
                                    }else{return ""}
                                }
                            },
                        ]
                    },
                    {
                        headerName : "Status Today Morning",
                        headerClass:["cellstylegridY","cellTopSubHeader"],
                        cellStyle: {textAlign: 'center'},
                            children : [
                            {
                                headerName: "Stock",
                                field: "stock",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["cellstylegridY","cellBorder"]
                                    }else{return ""}
                                }
                            },
                            {
                                headerName: "Q Hold",
                                field: "q_hold_stock",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["cellstylegridY","cellBorder"]
                                    }else{return ""}
                                }
                            },
                            {
                                headerName: "Dispatchable Stock",
                                field: "dispatchable_stock",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["cellstylegridY","cellBorder"]
                                    }else{return ""}
                                }
                            },                            
                            {
                                headerName: "Funds Available",
                                field: "funds_available",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable:editField,
                                sortable:true,
                                enableCellChangeFlash: true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["cellstylegridY","pstBg"]
                                    }else{return "textAlignCenter"}
                                },
                                valueGetter: function(params){
                                    if(params.data.funds_available != undefined)
                                    {
                                        var funds_available = params.data.funds_available.toString();
                                    }
                                    else
                                    {
                                        var funds_available = params.data.funds_available
                                    }
                                    if(funds_available != undefined)
                                    {
                                        if(funds_available.includes("total"))
                                        {
                                            return funds_available.split("-")[1]
                                        }
                                        else
                                        {
                                            return funds_available
                                        }
                                    }
                                    else
                                    {
                                        return ""
                                    }
                                },
                                rowSpan: function(params){
                                    var check = true;
                                    if(params.data.funds_available != undefined)
                                    {
                                        check = params.data.funds_available.toString().includes("total");
                                    }
                                    else{check = false}
                                    if(params.data.funds_available != undefined &&  check == false)
                                    {
                                        return rowLength-1;
                                    }else{return 0}
                                }
                            },
                        ]
                    },
                    {
                        headerName : this.state.gridHeaderName,
                        headerClass:["cellstylegridB","cellTopSubHeader"],
                            children : [
                            {
                                headerName: "Funds Received",
                                field: "funds_received",
                                width: 120,
                                filter: true,
                                resizable: true,
                                enableCellChangeFlash: true,
                                editable:editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["bglightblue","pstBg"]
                                    }else{return "textAlignCenter"}
                                },
                                valueGetter: function(params){
                                    if(params.data.funds_received != undefined)
                                    {
                                        var funds_received = params.data.funds_received.toString();
                                    }
                                    else
                                    {
                                        var funds_received = params.data.funds_received
                                    }
                                    if(funds_received != undefined)
                                    {
                                        if(funds_received.includes("total"))
                                        {
                                            return funds_received.split("-")[1]
                                        }
                                        else
                                        {
                                            return funds_received
                                        }
                                    }
                                    else
                                    {
                                        return ""
                                    }
                                },
                                rowSpan: function(params){
                                    var check = true;
                                    if(params.data.funds_received != undefined)
                                    {
                                        check = params.data.funds_received.toString().includes("total");
                                    }
                                    else{check = false}
                                    if(params.data.funds_received != undefined && check == false)
                                    {
                                        return rowLength-1;
                                    }else{return 0}
                                }
                            },
                            {
                                headerName: "Retrieval",
                                field: "retrieval",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                            {
                                headerName: "Vehicles Planned",
                                field: "vehicles_planned",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                            {
                                headerName: "Vehicles Invoiced",
                                field: "vehicles_invoiced",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                            {
                                headerName: "Vehicles Exit",
                                field: "vehicles_exit",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                            {
                                headerName: "Opening Pending Exit",
                                field: "opening_pending_exit",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                            {
                                headerName: "Current Pending Exit",
                                field: "current_pending_exit",
                                width: 120,
                                filter: true,
                                resizable: true,
                                editable: editField,
                                sortable:true,
                                cellClass: function(params){
                                    if(params.data.plant_name != "Total")
                                    {
                                        return ["","cellBorder"]
                                    }else{return ""}
                                },
                            },
                        ], 
                    }               
                ],                
			},	
		];
        return (
            
    		<div className="container-fluid" style={(this.state.screenView == "Mobile")?{marginTop:"2em"}:{}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <div className="card col-xl-12">
                <div className="card-header">
                    <h5>
                        <i className="icon-map cus-i"></i> <span>Planning Status</span>
                        {(this.state.screenView == "Mobile")?"":
                        <span>
                            {(this.state.userType == "management") ?                         
                                <a href="/managementdashboard" className="btn btn-danger" style={{float:"right",marginLeft:"2%"}}>Back</a> 
                                :        
                                <button className="btn btn-success" style={{float:"right"}} onClick={this.approveAllData.bind(this)}>Approve All</button>
                                }
                        </span>
                        }
                        
                    </h5>
                </div>
                <div className="card-body col-xl-12 col-lg-12">
                        <span className="" style={{color:"grey"}}>Last Approved at : {getHyphenDDMMMYYYYHHMM(this.state.last_approval_date)}</span>
                        {(this.state.userType == "management")?
                            ""
                        :
                        <span>
                            <button className="btn btn-info" style={{float:"right"}} onClick={this.onClickShowBulkUpload.bind(this)}>Bulk Upload</button>
                            <button className="btn btn-success" style={{float:"right"}} onClick={this.updateAllRows.bind(this)}>Update</button>
                        </span>}  
                    {(this.state.stockData.length > 0)?
                    <div id="myGrid" style={(this.state.screenView == "Mobile")?{width:"100%",height:"400px", marginTop:"7em", marginBottom:"1em"}
                                            :{width:"100%",height:"400px", marginTop:"3em", marginBottom:"1em"}} className={"ag-theme-balham"}>
                        
                        <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.stockData}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus= {true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        rowClassRules={this.state.rowClassRules}
                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                        gridOptions={{
                            context: { componentParent: this },
                            getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                                  return { 'font-weight': 'bold','font-size':'16px' };
                                }
                            },
                            suppressRowTransform: true,
                        }}
                        // components={this.state.components}
                        enableRangeSelection= {true}
                        // onCellClicked={this.onCellClicked.bind(this)}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                        />
                    </div>
                    :""}
                    <span>
                        <b>Note :</b><br/>
                        <b>*</b> Retrieval, Vehicles Exit, Opening Pending Exit data is considered from approved reporting day 06:30 AM <br />
                        <b>*</b> Vehicles Planned, Vehicles Invoiced data is considered from approved reporting day 01:40 AM
                    </span>
                </div>
                
            </div>
            <div className={"slide-r "+(this.state.sliderbulkupload)} style={{overflow:"hidden"}}>
            <h3 className="subH">Upload Batch File</h3>
                    
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" style={{marginBottom: "4em"}} className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>
                        <div className="form-group mt-20p">
                            <label className="">Upload File *</label> 
                            <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">Funds Available *</label> 
                            <input type="number" min="0" onChange={(e)=>{this.setState({input_funds_available: parseInt(e.target.value)})}}  className="form-control" required  />
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">Funds Received *</label> 
                            <input type="number" min="0" onChange={(e)=>{this.setState({input_funds_received: parseInt(e.target.value)})}}  className="form-control" required  />
                        </div>
                        <button type="submit" className="btn btn-info">Submit</button>
                    </form>
                    <div className="form-group">
                        <p style={{color:"#ff0000"}}>* Note : All fields related to the form are mandatory and cannot be empty</p>
                    </div>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/bulk_planning_status.csv')} target="_blank">Sample Template</a>
                    </div>
                    <div className="form-group">
                        {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                    
                    </div>		
                </div>
            </div>
        </div>
            
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function rowdSpan(params) {
    if (params.data.loading_point) {
      return 11;
    } else {
      return 1;
    }
  }