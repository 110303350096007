import React, { Component, useDebugValue } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CommentActions from "../layouts/commentsComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import DeleteItem from './deleteItem';

import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');

export default class TransitReturnsBiDashboard  extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents : {
              consignmentforceclose:Consignmentforceclose,
              consignmentActions:ConsignmentActions,
              UpdateButton:UpdateButton,
              MarkDeliver : MarkDeliver,
              commentActions : CommentActions,
              deleteItem: DeleteItem,
            },
            basicTitle:'',
            reasonforceclose:"",
            bulkslide:"",
            file:"",
            basicType:"default",
            loadshow:'show-n',
            forceCloseRowNode : "",
            forceclosedata:"",
            csvcontent:"",
            sliderForceCloseTranslate:"",
            overly:'',
            rowData:[],
            sliderRouteTranslate:"",
            rownode:"",
            rowSelection:'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
                statusBar: {
            statusPanels: [
              {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left"
              },
              {
              statusPanel: "agTotalRowCountComponent",
              align: "center"
              },
              { statusPanel: "agFilteredRowCountComponent" },
              { statusPanel: "agSelectedRowCountComponent" },
              { statusPanel: "agAggregationComponent" }
            ]
                },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
              toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
              ]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            markDeliverRowNode : "",
            markDeliverData:"",
            sliderMarkDeliverTranslate:"",                
            
        }
    }

    componentDidMount(){
        this.getTransitBiDetails();

    }

    getTransitBiDetails =() =>{
        redirectURL.post("/consignments/gettransitbidetails",{}).then((response)=>{
            let data = response.data
            console.log("userdata", data)
            this.setState({
                rowData: data,
                overly:"show-n",
                loadshow:"show-n",
            })
        })
    }
    // changeScreen = () =>{
    //     this.setState({
    //         overly:"show-m",
    //         loadshow:"show-m",
    //         bulkslide : "slider-translate-30p",
    //     })	
	// }
    previousscreen = () =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",
        })
    }
    onClickHideAll = ()=>{
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",        
        });        
    }
       onCellUpdateData = (params) =>{
        console.log("editfield", params)
        if(params.colDef.field === 'items'){
            
        }

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",        
        });        
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }


    deleteRowHandler = (params) =>{
        let rowData = this.state.rowData
        const rowFiltered = rowData.filter(m => m._id !== params._id);
        this.setState({ rowData :  rowFiltered});
        console.log("rowf", rowFiltered)
        let par = {
            _id: params._id
        }
        const confirmBox = window.confirm(
            "Do you really want to delete the record ?"
          )
          if (confirmBox === true) {            
              redirectURL.post("/consignments/deletetransitbidetails",par).then((response) =>{
                  let data = response.data
                  // console.log("dataresponse", data)
                  if (data.status === "success"){
                      this.setState({
                          basicType:"success",
                          basicTitle : "Successfully Deleted",
                          show : true,
                          // overly:"show-n",
                          // loadshow:"show-n",
                      })
                  }else{
                      this.setState({
                          show: true,
                          basicTitle:'Something went wrong',
                          basicType:"danger",
                      });
                  }      
              })
          }

    }
    render(){
        const columnwithDefs= [
            {
                headerName: "Edit",
                field: "_id",
                width: "70",
                colId: "_id",
                editable: false,
                cellRenderer: function(params)
                {
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    return '<a href="/edittransitreturns/'+encryptedstring+'/" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>'; 
                }
            },       
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,
                cellRenderer:'deleteItem',
                editable:false,
                // hide: true
            },
            {
                headerName: "View",
                field: "_id",                
                filter: true,
                width:100,
                hide: true,               
            },
              {
                headerName: "Rtgp No",
                field: "rtgp_no",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Stock Yard",
                field: "stkyrd",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Rtgp Date",
                field: "rtgp_dt",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,
                 valueGetter: function(params){
                    try{
                        if(params.data.rtgp_dt !=='' && params.data.rtgp_dt !==undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.rtgp_dt);
                        }
                    }
                    catch(e){
                    }
                }     
            },
            {
                headerName: "Month and Year of Return",
                field: "month_and_year_of_return",                
                filter: true,
                editable: false,
                width:120,
                resizable: true,
                valueGetter: function(params){
                    try{
                        if(params.data.month_and_year_of_return !=='' && params.data.month_and_year_of_return !==undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.month_and_year_of_return);
                        }
                    }
                    catch(e){
                    }
                }             
            },
            {
                headerName: "Financial Year of Return",
                field: "financial_year_of_return",                
                filter: true,
                editable: false,
                width:120,
                resizable: true,                            
            },
            {
                headerName: "Bveh",
                field: "bveh",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Model",
                field: "model",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Prefix",
                field: "prefix",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Chassis No",
                field: "chass_no",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Vin",
                field: "vin",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "cate",
                field: "cate",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Source",
                field: "source",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Invoice Amount",
                field: "invc_amt",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Dept Code",
                field: "dept",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Trtr",
                field: "trtr",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Trtr Name",
                field: "trtr_name",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Trtp",
                field: "trtp",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Regn",
                field: "regn",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Invoice Date",
                field: "invoice_date",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,
                valueGetter: function(params){
                    try{
                        if(params.data.invoice_date !=' ' && params.data.invoice_date !=undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        }
                    }
                    catch(e){
                    }
                }           
            },
            
            {
                headerName: "Acc Date",
                field: "acc_date",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,
                valueGetter: function(params){
                    try{
                        if(params.data.acc_date !=' ' && params.data.acc_date !=undefined)
                        {
                            return getHyphenDDMMYYYY(params.data.acc_date);
                        }
                    }
                    catch(e){
                    }
                }             
            },
            {
                headerName: "Acc Time",
                field: "acc_time",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Acc Place",
                field: "acc_place",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Acc State",
                field: "acc_state",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Region",
                field: "region",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Reason",
                field: "reason",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Acdt Detail",
                field: "acdt_detail",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Resp Tpt Code",
                field: "resp_tpt_code",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "Responsible Transporter",
                field: "responsible_transporter",                
                filter: true,
                editable: false,
                width:140,
                resizable: true,               
            },
            {
                headerName: "For Code",
                field: "for_code",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },
            {
                headerName: "For Desc",
                field: "for_desc",                
                filter: true,
                editable: false,
                width:100,
                resizable: true,               
            },            
            
        ]
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
	             </SweetAlert>
                 {this.state.alert}
                 <div className="row">
                     <div className=" col-xl-12 col-lg-12">
                         <div className="card">
                         <div className="card-header space-between">
                             <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span>Transit Returns Bi DashBoard</span>
                             </h5>
                            <div>
                            <a href="/addnewtransitreturns">
                             <button className="btn btn-success pull-right cs-btn" id="addUserItem" onClick={this.changeScreen} style={{color:"white"}}> <i className="icon-plus"></i> Add Item</button>
                            </a>
                                <button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>
                            </div>                        
                        </div>
                        <div id="tptUserItemGrid" className="card-body col-sm-12">                        
									<div id="myGrid" style={{ height: "580px",width: "100%",}}  className="ag-theme-balham">
										<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										//getRowHeight={this.state.getRowHeight}
										onCellEditingStopped={this.onCellUpdateData.bind(this)}
														
										rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
										// suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										// overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
										/>
									</div>								
                        </div>
                         </div>
                     </div>

                 </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }

}
