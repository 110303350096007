
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ImpactRoutes = (props) => {
    
    const impactRouteOne = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.impacted_routes)[0]);
    };
    const impactRouteTwo = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.impacted_routes)[1]);
    };
    const impactRouteThree = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowImpactRoutes(eval(props.data.impacted_routes)[2]);
    };

    return (
        <div className="button-group">
            {(eval(props.data.impacted_routes).length >= 1)?
                <button className="btn btn-warning" style={{padding:"0px", marginRight: "2px"}} onClick={impactRouteOne}>Route-1</button>
            :""}
            {(eval(props.data.impacted_routes).length >= 2)?
                <button className="btn btn-warning" style={{padding:"0px", marginRight: "2px"}} onClick={impactRouteTwo}>Route-2</button>
            :""}
            {(eval(props.data.impacted_routes).length >= 3)?
                <button className="btn btn-warning" style={{padding:"0px", marginRight: "2px"}} onClick={impactRouteThree}>Route-3</button>
            :""}
       
        </div>
    );
};

export default ImpactRoutes;
